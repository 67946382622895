import React from 'react';
import Skeleton from '@mui/material/Skeleton';


function DiscussionSkeleton({ size = 1 }) {
    return (
        <div>
            {[...Array(size)].map((_, index) => (
                <div key={index} className="flex row items-center gap-3 mb-5 mt-5">
                    <Skeleton variant="circular" width={95} height={95} /> {/* user image skeleton */}
                    <div className="border-b-2 pb-3 w-full">
                        <Skeleton variant="rectangular" width={120} height={16} className="mb-1" />
                        <Skeleton variant="rectangular" width={80} height={12} className="mb-1" />
                        <Skeleton variant="rectangular" width={50} height={10} />
                    </div>
                </div>
            ))}
        </div>
    );
};

export default DiscussionSkeleton;