import React, { useEffect, useState, FormEvent, ChangeEvent } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import InputField from "../../../components/AllDashboardPagesComponents/InputField";
import SelectField from "../../../components/AllDashboardPagesComponents/SelectField";
import TextareaField from "../../../components/AllDashboardPagesComponents/TextareaField";
import facebook from "../../../assets/img/facebook.svg";
import Instagram from "../../../assets/img/Instagram.svg";
import LinkedIn from "../../../assets/img/LinkedIn.svg";
import WhatsApp from "../../../assets/img/WhatsApp.svg";
import moment from "moment";
import { SingleValue } from 'react-select';
import Select from 'react-select';
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import {
  getRole,
  validateNumber,
  validateText,
  validateTextAndSpace,
} from "../../../config/Helpers/helpers";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import { RootState } from "../../../config/Store/store";

import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import httpRequest from "../../../config/Helpers/httpRequest";


interface IUserDetails {
  firstName: string;
  lastName: string;
  roleCategory: string;
  dateofbirth: string;
  gender: string;
  phonenumber: string;
  address: string;
  suburb: string;
  state: string;
  postCode: string;
}

interface ISocialLinks {
  facebookUrl: string;
  instagramUrl: string;
  linkedinUrl: string;
  whatsappUrl: string;
}
interface IApiResponse {
  message?: string;
  documents?: [] | object | string;

  response: OptionType;

}
type OptionType = {
  value: string;
  label: string;
  suburb: string;
  state: string;
  postcode: string; // Updated to match the user state
};
const ProfileUserDetailEdit: React.FC = () => {
  const dispatch = useDispatch();
  const { personalData } = useSelector((store: RootState) => store.userProfile); // get user data
  const categoryOptions = [
    { value: "", label: "Select" },
    { value: "support worker", label: "Support worker" },
    { value: "support coordinator", label: "Support coordinator" },
    { value: "plan manager", label: "Plan manager" },
    { value: "allied health", label: "Allied health" },
  ];
  const decoded = getRole()
  const UserRole = decoded?.role;

  // const { UserRole } = getRole(); // get user Role

  const [userDetailLoader, setUserDetailLoader] = useState(false); // loader for user detail update
  const [userDetails, setUserDetails] = useState({
    // user detail
    firstName: "",
    lastName: "",
    roleCategory: "",
    dateofbirth: "",
    gender: "",
    phonenumber: "",
    address: "",
    suburb: "",
    state: "",
    postCode: "",
  });

  let {
    firstName,
    lastName,
    roleCategory,
    dateofbirth,
    gender,
    phonenumber,
    address,
    suburb,
    state,
    postCode,
  } = userDetails;

  const handleName = (key: keyof IUserDetails, value: string) => {
    // change first & last name
    if (value === "") {
      setUserDetails({ ...userDetails, [key]: "" });
    } else if (validateText(value)) {
      setUserDetails({ ...userDetails, [key]: value });
    }
  };

  // const handleName = (key: keyof IUserDetails, value: string) => {
  //   // Convert the input value to lowercase
  //   const lowercaseValue = value.toLowerCase();
    
  //   // Change first & last name
  //   if (lowercaseValue === "") {
  //     setUserDetails({ ...userDetails, [key]: "" });
  //   } else if (validateText(lowercaseValue)) {
  //     setUserDetails({ ...userDetails, [key]: lowercaseValue });
  //   }
  // };
  
  const handlePhone = (value: string) => {
    // change phonenumber
    if (value === "") {
      setUserDetails({ ...userDetails, phonenumber: "" });
    } else if (validateNumber(value) && value.length < 11) {
      setUserDetails({ ...userDetails, phonenumber: value });
    }
  };

  const handleState = (value: string) => {
    // change state
    if (value === "") {
      setUserDetails({ ...userDetails, state: "" });
    } else if (validateTextAndSpace(value)) {
      setUserDetails({ ...userDetails, state: value });
    }
  };
  const isValidUrl = (url: string): boolean => {
    return url.startsWith("https://");
  };
  const handleChangePostCode = (value: string) => {
    // change postcode
    if (value === "") {
      setUserDetails({ ...userDetails, postCode: "" });
    } else if (value.length > 4 || !validateNumber(value)) {
      return;
    } else {
      setUserDetails({ ...userDetails, postCode: value });
    }
  };

  const [headlineLoader, setHeadlineLoader] = useState(false); // loader for headline update
  const [profileSummary, setProfileSummary] = useState<string>(""); // profile summary / headline
  const [suburbOptions, setSuburbOptions] = useState<OptionType[]>([]);
  const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  const [socialLinksLoader, setSocialLinksLoader] = useState(false); // loader for social links update
  const [socialLinks, setSocialLinks] = useState<ISocialLinks>({
    facebookUrl: "",
    instagramUrl: "",
    linkedinUrl: "",
    whatsappUrl: "",
  });

  const { facebookUrl, instagramUrl, linkedinUrl, whatsappUrl } = socialLinks;

  const handleChangeSummary = (text: string) => {
    if (text.length > 100) {
      return;
    } else {
      setProfileSummary(text);
    }
  }; // summary change function

  // Functionality: API call for for update user data
  const handleSubmitUserDetail = async (e: FormEvent) => {
 
    e.preventDefault();
    setUserDetailLoader(true);

    const birthDate = new Date(userDetails.dateofbirth);
    const today = new Date();
    const age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    const isValidAge = age > 15 || (age === 15 && monthDifference > 0) || (age === 15 && monthDifference === 0 && today.getDate() >= birthDate.getDate());
  
    if (!isValidAge) {
      toast("You must be at least 15 years old.", { type: "error" });
      setUserDetailLoader(false);
      return; // Stop form submission
    }
    
    const userDetailsToSubmit = {
      ...userDetails,
      firstName: userDetails.firstName.toLowerCase(),
      lastName: userDetails.lastName.toLowerCase(),
    };


    const { res, err } = await httpRequest<IApiResponse>({ method: "put", path: `/api/user/account-details`, params: userDetailsToSubmit });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err.message, { type: "error" }); // api error
    }
    setUserDetailLoader(false);
  };

  // Functionality: API call for for update social links
  const handleSubmitSocialLinks = async (e: FormEvent) => {
    e.preventDefault();
    if (
      (facebookUrl && !isValidUrl(facebookUrl)) ||
      (instagramUrl && !isValidUrl(instagramUrl)) ||
      (linkedinUrl && !isValidUrl(linkedinUrl)) ||
      (whatsappUrl && !isValidUrl(whatsappUrl))
    ) {
      toast("Please enter valid URLs starting with https://", { type: "error" });
      return;
    }
    setSocialLinksLoader(true);
    const { res, err } = await httpRequest<IApiResponse>({ method: "put", path: `/api/user/social-links`, params: socialLinks });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setSocialLinksLoader(false);
  };

  // Functionality: API call for for update profile summary
  const handleSubmitHeadline = async (e: FormEvent) => {
    e.preventDefault();
    setHeadlineLoader(true);
    if (!profileSummary) {
      toast("Please enter headline", { type: "error" });
    }
    const { res, err } = await httpRequest<IApiResponse>({ method: "patch", path: `/api/user/summary`, params: { summary: profileSummary } });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setHeadlineLoader(false);
  };

  useEffect(() => {
    if (personalData) {
      setUserDetails({
        // user detail
        firstName: personalData?.firstName || "",
        lastName: personalData?.lastName || "",
        roleCategory: personalData?.roleCategory || "",
        dateofbirth: personalData?.dateofbirth || "",
        gender: personalData?.gender || "",
        phonenumber: personalData?.phonenumber || "",
        address: personalData?.address || "",
        suburb: personalData?.suburb || "",
        state: personalData?.state || "",
        postCode: personalData?.postCode || "",
      });
      setProfileSummary(personalData?.profileSummary || "");
      setSocialLinks({
        linkedinUrl: personalData?.socialLinks?.linkedin || "",
        facebookUrl: personalData?.socialLinks?.facebook || "",
        instagramUrl: personalData?.socialLinks?.instagram || "",
        whatsappUrl: personalData?.socialLinks?.whatsApp || "",
      });
      setSelectedOption({
        value: personalData.suburb ?? "",
        label: `${personalData.suburb ?? ""}, ${personalData.state ?? ""}, ${personalData.postCode ?? ""}`,
        suburb: personalData.suburb ?? "",
        state: personalData.state ?? "",
        postcode: personalData.postCode ?? "",
      });

    }
  }, [personalData]);
  useEffect(() => {
    if (personalData && personalData.suburb) {
      fetchLocations(personalData.suburb);
    }
  }, [personalData]);
  
  const fetchLocations = async (query: String) => {
    const queryString = `search=${userDetails.postCode || ""}&suburb=${query || ""}&state=${userDetails.state || ""}`;

    try {
      const response = await axios.get<IApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/postcodes/all?${queryString}`);

      if (response.data) {
        // Assuming response.data.documents could be an array or a single object
        if (Array.isArray(response.data.documents)) {
          // Handle case when documents is an array
          setSuburbOptions(response.data.documents);
          setStateOptions(response.data.documents);

          if (response.data.documents.length > 0) {
            const firstOption = response.data.documents[0];
            setSelectedOption(firstOption);
          } else {
            setSelectedOption(null); // Handle empty array
          }
        } else if (typeof response.data.documents === 'object' && response.data.documents !== null) {
          // Handle case when documents is a single object
          setSelectedOption(response.data.documents as OptionType);
        } else {
          setSelectedOption(null); // Handle other cases (e.g., string)
        }
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setSelectedOption(null); // Handle error case
    }
  };
  return (
    <>
      <HeaderGlobal />
      <div className="container-1480  justify-between h-3vw gap-3 lg:flex md:flex block">
        <SidebarGobal />
        <div className="w-full mt-2 lg:mt-0 md:mt-0">
          <PreviewProfile title="Edit profile" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
            <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
              Bio
            </h4>
            <form onSubmit={handleSubmitUserDetail}>
              <InputField
                type="text"
                name="email"
                labelTop="First name"
                // value={firstName.charAt(0).toUpperCase() + firstName.slice(1)}
                value={firstName}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleName("firstName", e.target.value)}
                required
              />
              <InputField
                type="text"
                name="email"
                labelTop="Last name"
                value={lastName.charAt(0).toUpperCase() + lastName.slice(1)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleName("lastName", e.target.value)}
                required
              />
              {UserRole === "provider" && (
                <SelectField
                  labelTop="Category "
                  options={categoryOptions}
                  value={roleCategory}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setUserDetails({
                      ...userDetails,
                      roleCategory: e.target.value,
                    })
                  }
                  required
                />
              )}
              <InputField
                type="date"
                name="email"

                max={new Date().toISOString().split("T")[0]}
                labelTop="Date of birth"
                value={dateofbirth?.split("T")[0] || ""}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setUserDetails({
                    ...userDetails,
                    dateofbirth: e.target.value,
                  })
                }
                required
              />
              <SelectField
                labelTop="Gender"
                options={[
                  { value: "", label: "Select" },
                  { value: "male", label: "Male" },
                  { value: "female", label: "Female" },
                  { value: "other", label: "Other" },
                ]}
                value={gender}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setUserDetails({ ...userDetails, gender: e.target.value })
                }
                required
              />
              <InputField
                type="text"
                name="email"
                labelTop="Contact number"
                value={phonenumber}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handlePhone(e.target.value)}
                required
              />
              <InputField
                type="text"
                name="email"
                labelTop="Location"
                value={address}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setUserDetails({ ...userDetails, address: e.target.value })
                }
                required
              />

              <div className="grid col-grid-profile  col-grid-profile-2 pb-2">
                {/* <InputField
                  type="text"
                  name="email"
                  labelTop="suburb"
                  value={suburb}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => handleName("suburb", e.target.value)}
                  required
                /> */}
                <div>
                  <label className="form-control font-size-15px font-Poppins-Medium">Suburb</label>
                  <Select
                    className="global-select global-select-2  mt-1"
                    value={
                      suburbOptions
                        .filter(option => option.suburb === userDetails.suburb)
                        .map(option => ({
                          value: option.suburb, // Use suburb as the unique value key
                          label: option.suburb, // Display only the suburb in the input after selection
                          suburb: option.suburb,
                          state: option.state,
                          postcode: option.postcode,
                        }))[0] || userDetails.suburb // Select the first matching suburb or null
                    }
                    onChange={(selectedOption) => {
                      if (selectedOption) {
                        const selectedSuburb = suburbOptions.find(option => option.suburb === selectedOption.value);
                        if (selectedSuburb) {
                          setUserDetails({
                            ...userDetails,
                            suburb: selectedSuburb.suburb,
                            state: selectedSuburb.state,
                            postCode: selectedSuburb.postcode,
                          });
                        }
                      } else {
                        // Clear the fields if the user clears the selection
                        setUserDetails({
                          ...userDetails,
                          suburb: '',
                          state: '',
                          postCode: '',
                        });
                      }
                    }}
                    onInputChange={(inputValue) => {
                      // Call API to fetch suburbs based on the input value
                      fetchLocations(inputValue);
                    }}
                    isClearable={true} // Allow the user to clear the selection
                    options={suburbOptions.map((item) => ({
                      value: item.suburb, // Use suburb as the unique value key for dropdown
                      label: `${item.suburb}, ${item.state}, ${item.postcode}`, // Display suburb, state, and postcode in dropdown options
                      suburb: item.suburb,
                      state: item.state,
                      postcode: item.postcode,
                    }))}
                    placeholder="Suburb"
                    styles={{
                      container: (provided) => ({
                        ...provided,
                        width: '100%', // Set the width to 100%

                      }),
                      control: (provided) => ({
                        ...provided,
                        width: '100%', // Ensure control width is also 100%
                        borderRadius: '6px', // Set the border radius to 12px
                        borderColor: '#00a297', // Set the border color if needed
                        boxShadow: 'none', // Remove default shadow if needed
                        '&:hover': {
                          borderColor: '#00a297', // Set border color on hover
                        }
                      }),
                      singleValue: (provided) => ({
                        ...provided,

                        color: '#004440"', // Set the text color for selected option
                        fontWeight: '600' // Make the text bold for selected option

                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        color: 'rgba(0, 69, 64, 0.44)', // Set the text color for placeholder
                        fontWeight: '600' // Make the placeholder text bold
                      }),
                      input: (provided) => ({
                        ...provided,
                        color: 'rgba(0, 69, 64, 0.44)', // Set the text color for input text
                        fontWeight: '600' // Make the input text bold
                      }),
                      menu: (provided) => ({
                        ...provided,
                        width: '100%', // Ensure menu width is also 100%
                        borderRadius: '6px' // Set the border radius for the menu
                      }),
                      menuList: (provided) => ({
                        ...provided,
                        fontSize: '12px',
                        borderRadius: '6px' // Ensure menu items have the same border radius
                      })
                    }}
                  />




                </div>
                <InputField
                  type="text"

                  labelTop="State"
                  value={state}
                  ReadOnly
                // onChange={(e: ChangeEvent<HTMLInputElement>) => handleState(e.target.value)}

                />
                <InputField
                  type="text"
                  labelTop="Post code"
                  value={postCode}
                  ReadOnly
                // onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangePostCode(e.target.value)}

                />
              </div>
              <button
                disabled={userDetailLoader}
                type="submit"
                className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Update{" "}
                {userDetailLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
            <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
              Headline
            </h4>
            <form onSubmit={handleSubmitHeadline}>
              <TextareaField
                currentLength={profileSummary?.length || 0}
                isLength={100}
                labelTop="Headline"
                rows={3}
                value={profileSummary}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeSummary(e.target.value)}
              />
              <button
                disabled={headlineLoader}
                type="submit"
                className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Update{" "}
                {headlineLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-7 py-3 px-6 ">
            <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green pb-3">
              Social links
            </h4>
            <form onSubmit={handleSubmitSocialLinks}>
              <InputField
                type="url"
                labelImageSrc={facebook} // Path to the image
                label="Facebook"
                name="email"
                placeholder="https://"
                value={facebookUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSocialLinks({
                    ...socialLinks,
                    facebookUrl: e.target.value,
                  })
                }
              />{" "}
              {/* facebook */}
              <InputField
                type="url"
                labelImageSrc={Instagram} // Path to the image
                label="Instagram"
                name="email"
                placeholder="https://"
                value={instagramUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSocialLinks({
                    ...socialLinks,
                    instagramUrl: e.target.value,
                  })
                }
              />{" "}
              {/* Instagram */}
              <InputField
                type="url"
                labelImageSrc={LinkedIn} // Path to the image
                label="LinkedIn"
                name="email"
                placeholder="https://"
                value={linkedinUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSocialLinks({
                    ...socialLinks,
                    linkedinUrl: e.target.value,
                  })
                }
              />{" "}
              {/* linkedin */}
              <InputField
                type="url"
                labelImageSrc={WhatsApp} // Path to the image
                label="WhatsApp"
                placeholder="https://"
                name="email"
                value={whatsappUrl}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSocialLinks({
                    ...socialLinks,
                    whatsappUrl: e.target.value,
                  })
                }
              />{" "}
              {/* whatsapp */}
              <button
                disabled={socialLinksLoader}
                type="submit"
                className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Update{" "}
                {socialLinksLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProfileUserDetailEdit;