import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faStar } from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  getTimeDifference,
  getRole,
} from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import httpRequest from "../../../config/Helpers/httpRequest";
import Skeleton from '@mui/material/Skeleton';

const ReviewProfile = ({ unpaidModalShow }) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // const { userId = false } = getRole(); // loged in user id
  const decoded = getRole();
  const userId = decoded?.userId;

  const { uid = false } = useParams(); // search user id

  const [isLoader, setIsLoader] = useState(false);
  const [allReview, setAllReview] = useState([]);

  // Functionality: API call for get review of user
  useEffect(() => {
    (async () => {
      setIsLoader(true);
      const { res, err } = await httpRequest({ path: `/api/review/${uid ? uid : userId}?page=1&limit=5&reviewSide=published` });
      if (res) {
        setAllReview(res?.documents);
      } else {
        toast(err?.message, { type: "error" });
      }
      setIsLoader(false);
    })();
  }, []);

  return (
    <div className="shadow-lg bg-white rounded-xl mb-2 py-3  px-6 reviewprofile-card">
      <div className="flex justify-between items-center pb-3">
        <h4 class="font-size-20px font-Poppins-SemiBold theme-color-green ">
          Reviews
        </h4>
      </div>
      {unpaidModalShow ?

        (
          <>
            <div className="cover-img relative">
              <Skeleton variant="rounded" className="w-[55.5vw] mb-2 mt-2" style={{ borderRadius: 13 }} height={15} />
              <Skeleton variant="rounded" className="w-[55.5vw] mb-2" style={{ borderRadius: 13 }} height={15} />
              <Skeleton variant="rounded" className="w-[55.5vw] mb-2" style={{ borderRadius: 13 }} height={15} />
            </div>

          </>

        )
        :
        (

          allReview.length > 0
            ?
            <>
              <Slider {...settings}>
                {allReview?.map((item, index) => (
                  <div key={index}>
                    <div
                      className="flex shadow-lg  rounded-xl flex items-center justify-center"
                      style={{ backgroundColor: "#FFFCF1" }}
                    >
                      <div className=" p-5 rounded-l-2xl  text-center w-1/4">
                        <img
                          className="review-profile-img rounded-full mx-auto object-cover"
                          src={`${item?.profileImageUrl}`}
                          // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                          alt=""
                        />
                        <h3 className="font-size-17px font-Poppins-Medium theme-color-green capitalize">
                          {`${item?.firstName || ""} ${item?.lastName || ""
                            }`}
                        </h3>
                        <p className="font-size-15px font-Poppins-Regular theme-grey-type capitalize">
                          {item?.role || ""}
                        </p>
                      </div>
                      <div className=" w-3/4 p-5 rounded-r-2xl ">
                        <div className="flex justify-between items-center mb-5">
                          <div className="text-yellow-500">
                            {[1, 2, 3, 4, 5].map((v) => (
                              <FontAwesomeIcon
                                key={v}
                                fontSize="20px"
                                icon={faStar}
                                style={{
                                  color: item?.jobRating >= v ? "#FF9B29" : "#b8b8b8",
                                }}
                              />
                            ))}
                          </div>
                          <span className="font-size-13px font-Poppins-Regular theme-grey-type">
                            {getTimeDifference(item?.createdAt)}
                          </span>
                        </div>
                        <p className="font-size-15px font-Poppins-Regular theme-grey-type">
                          {item?.reviewMessage || ""}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
              <hr className="my-2" color="#707070" />
              <div className="flex justify-center items-center">
                <Link
                  to={`/reviews/${uid ? uid : userId}/published`}
                  className="font-size-15px theme-grey-type  font-Poppins-Medium flex gap-2 items-center on-hover px-2"
                >
                  Show all reviews
                  <FontAwesomeIcon icon={faArrowRight} />{" "}
                </Link>
              </div>
            </>
            :
            <p>No review</p>
        )}

    </div>
  );
};

export default ReviewProfile;