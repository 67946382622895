import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,

} from "@fortawesome/free-solid-svg-icons";
interface EmailSentProps {
  email: string;
}

interface ApiResponse {
  message?: string;
}

interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
  };
}

function EmailSent({ email }: EmailSentProps) {
  // Adjusted state types to be consistent with the setter functions
  const [isSuccess, setIsSuccess] = useState<string | null>(null);
  const [isError, setIsError] = useState<string | null>(null);

  // Functionality: API call for resend OTP
  const resendOtp = async (email: string, setIsSuccess: React.Dispatch<React.SetStateAction<string | null>>,
    setIsError: React.Dispatch<React.SetStateAction<string | null>>) => {
    try {
      const response = await axios.post<ApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/user/resendLink`, { email });
      console.log(response, "responseresponse")
      if (response.status) { // Check for a successful status code
        setIsSuccess(response.data.message || "Success");
      } else {
        setIsError("Unexpected response status");
      }
    } catch (error) {
      const err = error as ErrorResponse;
      setIsError(err?.response?.data?.message || 'ERROR');
    }
  };

  const handleResendOtp = async () => {
    await resendOtp(email, setIsSuccess, setIsError);
  };

  useEffect(() => {
    if (isError || isSuccess) {
      const timer = setTimeout(() => {
        setIsError(null); // Use null to reset the state
        setIsSuccess(null); // Use null to reset the state
      }, 5000);

      // Clean up the timer if the component unmounts or dependencies change
      return () => clearTimeout(timer);
    }
  }, [isError, isSuccess]);

  return (
    <div className="free-verification-main">
      <h3 className="capitalize font-size-login-32px font-Montserrat-Bold theme-color-green free-verification">Verification</h3>
      <span className="font-Poppins-Regular font-size-login-16px text-[#FFE175]">An email has been sent to your registered email address.</span>
      <p className="font-size-login-16px text-white font-Montserrat-Medium text-center payment-form-ord-sum-para lg:w-[50vw] md:w-[50vw] w-full">To complete your registration, please verify your email address. We've sent a  verification link to your inbox. Click on the link to activate your account and log in.</p>
      <button onClick={handleResendOtp} className="border-radius-6px font-size-login-16px font-Poppins-SemiBold input-btns-height-40px flex items-center justify-center text-white mx-auto" type="submit">Resend verification email</button>
      {isError &&
        <p style={{ color: 'red' }}>{isError}</p>
      }
      {isSuccess &&
        <p style={{ color: 'white' }}>{isSuccess}</p>
      }
      <Link to="/login-web" className="bg-white theme-color-green border-radius-20px font-size-login-16px font-Poppins-SemiBold gap-2 login-btn-home-banner input-btns-height-40px flex items-center justify-center" type="submit">

        Continue
        <FontAwesomeIcon size="lg" icon={faArrowRight} />
      </Link>
    </div>
  );
}

export default EmailSent;
