import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationDot,
    faVenus,
    faClock,
    faCalendar
} from "@fortawesome/free-solid-svg-icons";
import 'react-calendar/dist/Calendar.css';
import { Skeleton } from "@mui/material";
import { calculateAge, convertTo12Hour, getRole, getTimeDifference } from "../../../config/Helpers/helpers";
import JobRequestAndHistory from "../../JobsComponents/JobRequestAndHistory";
import moment from "moment";

function JobDetail({ loader = false, item = {}, side = "" }) {
    const decoded = getRole();

    const userId = decoded?.userId;
    const [filterdData, setFilterdData] = useState("applicantDetails");
    console.log(filterdData, "filterdData")
    useEffect(() => {
        if (item.applicantDetails?._id === userId) {
            console.log()
            console.log("")
            setFilterdData("jobCreatorDetails");
        } else {
            setFilterdData("applicantDetails");
        }
    }, [item, userId]);
    const [logUpdated, setLogUpdated] = useState(false);

    const checkPreferences = (user) => {
        if (!user) return '-'; // Return an empty string if preferences are not provided

        const { preferencemale, preferencefemale, preferencenopet, preferencenonsmoker } = user;

        // Check for specific combinations and return the corresponding HTML string
        if (!preferencemale && !preferencefemale && !preferencenopet && !preferencenonsmoker) {
            return '-';
        } else {
            let result = '';

            if (preferencemale && preferencefemale) {
                result += 'Male & Female';
            } else if (preferencemale) {
                result += 'Only Male';
            } else if (preferencefemale) {
                result += 'Only Female';
            }

            if (preferencenopet) {
                if (result !== '') result += ', ';
                result += 'No Pets';
            }

            if (preferencenonsmoker) {
                if (result !== '') result += ', ';
                result += 'Non Smoker';
            }
            return result;
        }
    };

    return (
        <>
            <div className="admin-job-review-stat">
                {loader ?
                    <>
                        <Skeleton variant="rounded" className="w-full mb-3" height={330} />
                        <div className="mb-3 flex justify-between gap-1">
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                        </div>
                    </>
                    :
                    <>
                        {item ?
                            <>
                                <div className="admin-preview-head flex items-center justify-end">
                                    <p className="font-Poppins-Medium font-size-12px text-[#ffffff] pr-2">Posted {getTimeDifference(item?.createdAt) || ""}</p>
                                </div>

                                <div className="admin-job-preview-main ">
                                    <div className="admin-preview-job">
                                        <img src={`${item?.[filterdData]?.profileImageUrl}`} alt="" />
                                        {/* <img src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.[filterdData]?.profileImageUrl}`} alt="" /> */}
                                        <div className="text-center jobee-detail">
                                            <h2 className="font-Poppins-Medium font-size-22px text-[#00443f]">{item.service.servicename ?? "-"}</h2>
                                            <p className="font-Poppins-SemiBold font-size-15px text-[#000000]">                          {filterdData === "jobCreatorDetails" ? "Posted by" : "Assigned to"}
                                            </p>
                                            <p className="font-Poppins-SemiBold font-size-15px text-[#00443f] capitalize">{`${item?.[filterdData]?.firstName || ""} ${item?.[filterdData]?.lastName || ""}`}</p>
                                            <p className="font-Poppins-SemiBold font-size-15px text-[#000000] capitalize-first-letter">{item?.[filterdData]?.roleCategory || "-"}</p>

                                            {item?.[filterdData]?.dateofbirth &&
                                                <p className="font-Poppins-SemiBold font-size-12px text-[#000000] mt-2 ">{calculateAge(item?.[filterdData]?.dateofbirth) || ""} Years old {item?.[filterdData]?.gender || "-"}</p>

                                            }                                        </div>
                                    </div>

                                    <div className="job-preview-content text-[#646464] flex items-center align-center ">

                                        <div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faLocationDot} />
                                                <p>{`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faVenus} />
                                                <p>{checkPreferences(item?.[filterdData])}</p>
                                            </div>
                                            <div className="mt-5 mb-5">
                                                <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                    <FontAwesomeIcon icon={faClock} />
                                                    <p>
                                                        {
                                                            item?.repeatCycle == 1 ? "One-off"
                                                                : item?.repeatCycle == 2 ? "Daily"
                                                                    : item?.repeatCycle == 3 ? "Weekly"
                                                                        : item?.repeatCycle == 4 ? "Monthly"
                                                                            : "Custom"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>Start date: {moment(item?.startDate).format("DD-MM-YYYY") || ""}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>End date: {moment(item?.endDate).format("DD-MM-YYYY") || ""}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>Start time: {convertTo12Hour((item?.startTime || ""))}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>End time: {convertTo12Hour((item?.endTime || ""))}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="theme-grey-type job-preview-content">
                                        <div className="flex">
                                            <div className="">
                                                {/* {item?.allServices?.map((v, index) => ( */}
                                                <h2 className="font-Poppins-Medium text-black font-size-15px capitalize">
                                                    {item?.service?.servicename}
                                                </h2>
                                                {/* ))} */}
                                            </div>

                                        </div>
                                        <div className="">
                                            <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
                                                {item?.description || ""}
                                            </p>
                                        </div>
                                        <span style={{ color: "#004540", fontSize: 16 }}>
                                        </span>
                                        <div className="flex">
                                            <div className="job-pre-perhour-adminside"><Link className=" text-white font-Poppins-Regular font-size-14px">$ {item?.budget || 0}</Link></div>
                                        </div>
                                    </div>
                                </div>
                                <JobRequestAndHistory
                                    opponentData={side === "creator" ? item?.jobCreatorDetails : item?.jobCreatorDetails}
                                    jobId={item?.jobId}
                                    logUpdated={logUpdated}
                                    setLogUpdated={setLogUpdated}
                                />
                            </>
                            :
                            <p>No jobs</p>
                        }
                    </>
                }
            </div>
        </>
    );
};

export default JobDetail;