/**
 * Loginrecovery Component
 * 
 * This component handles the recovery or forgot password functionality.
 * Users can enter their email to receive a recovery code.
 * 
 * File Name: RecoveryPassword.js.jsx
 * Author: Developer
 * Date: 2024-04-05
 */

import React, { useState } from "react";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import Header from "../../components/Header";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useDispatch } from "react-redux";
import { storeTwoFactor } from "../../config/Store/Reducers/authSlice";
import httpRequest from "../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * RecoveryPassword Component
 * 
 * Renders the RecoveryPassword page where users can request a password recovery email.
 */
function Loginrecovery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  /**
   * handleSubmit
   * 
   * Handles the form submission for password recovery.
   * @param {Object} event - The form submission event.
   */
  // Functionality: API call recovery / forgot password
  const handleSubmit = async (event: React.FormEvent) => {

    event.preventDefault();
    setIsLoader(true);
    const { res, err } = await httpRequest({ method: "post", path: "/api/user/send-otp", params: { email } })
    if (res) {
      const currentTimestamp = Date.now();
      const fiveMinutesInMilliseconds = 2 * 60 * 1000; // 5 minutes in milliseconds

      const futureTimestamp = currentTimestamp + fiveMinutesInMilliseconds;
      dispatch(
        storeTwoFactor({
          time: futureTimestamp,
          email,
          admin: false,
          keepMeloggedIn: false // Add this property with a boolean value
        })
      );
      navigate("/recovery-verification");
      // setEmail("");
    } else {
      toast(err.message, { type: "error" })
    }
    setIsLoader(false);
  };

  return (
    <div>
      <section className="login-page-parent new-reg-parent relative h-screen  overflow-clip">
        <Header />
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10  bg-texture-banner"
          src={"https://ndisync.backslashwebs.com/new-register.jpg"}

          alt=""
        />
        <div className="container-custom h-full relative z-20 flex flex-col justify-center items-center gap-1 ">
          <div className="flex flex-col justify-center items-center gap-10">
            <div className="reg-upper-detail flex flex-col justify-center items-center ">
              <h2 className="font-Poppins-Medium text-[#FFFCF1] font-size-30px">Reset password
              </h2>

              <h4 className="text-[#FFE175] font-Poppins-Medium font-size-25px text-center">Enter your email address, and we will send you a <br />
                recovery code.</h4>


            </div>
            <div className=" login-box-body-parent login-box-body-parent-2 login-box-body-parent-3 flex flex-col ">


              <form onSubmit={handleSubmit} className="py-5 ">

                <div className="relative mb-3">
                  <input
                    className="home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px  outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                    placeholder="Email address"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>


                <button
                  type="submit"
                  className="w-full mt-5 text-white theme-bg-green font-size-login-18px font-Poppins-SemiBold login-page-btn flex items-center justify-center gap-2 border-radius-20px"
                  disabled={isLoader}
                  style={{ backgroundColor: "#009e92" }}
                >
                  Send request
                  <FontAwesomeIcon size="lg" icon={faArrowRight} />

                  {isLoader && (
                    <CircularProgress
                      style={{ color: "#fff" }}
                      className="btn__loader"
                    />
                  )}
                </button>


              </form>

            </div>
          </div>
          <div className="flex items-center justify-center gap-1 mt-6">
            <span className="font-Poppins-Regular font-size-login-16px text-white">
              Already have ndisync.ai account?
            </span>
            <Link to="/login-web" className="font-Poppins-SemiBold font-size-login-16px text-white">
              Login
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loginrecovery;