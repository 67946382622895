import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import AdminJobDetails from "../../components/JobsComponents/AdminJobDetails";
import httpRequest from "../../config/Helpers/httpRequest";

interface ApiResponse {
  document: [];
}
const DisputeJobDetails: React.FC = () => {
  const navigate = useNavigate();
  let { jobId } = useLocation()?.state || false;

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [jobDetails, setJobDetails] = useState([]);

  // Functionality: API call for get dispute Job details
  const fetchJobDetails = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      // path: `/api/dispute/overview/${jobId}`,
      path: `/api/job/overview/${jobId}`,
    });
    if (res) {
      setJobDetails(res?.document);
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (!jobId) {
      navigate(-1);
    } else {
      fetchJobDetails();
    }
  }, [jobId]);

  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          <AdminHeader />
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <h2 className="font-size-36px font-Poppins-Medium">Disputes</h2>
              <AdminJobDetails
                loader={isLoader}
                item={jobDetails}
                activeTab={"onboard"}
                setBool={() => { }}
                setActiveTab={() => { }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DisputeJobDetails;
