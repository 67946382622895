import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faVenus,
  faClock,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import Skeleton from '@mui/material/Skeleton';
import { calculateAge, convertTo12Hour, getTimeDifference } from "../../config/Helpers/helpers";
import ApplyJobsButton from "../JobsComponents/ApplyJobsButton";
import ShortListJobButton from "../JobsComponents/ShortListJobButton";
import moment from "moment";


interface CreatorDetails {
  firstName: string;
  lastName: string;
  roleCategory: string;
  dateofbirth: string;
  gender: string;
  profileImageUrl: string;
  preferencemale: boolean;
  preferencefemale: boolean;
  preferencenopet: boolean;
  preferencenonsmoker: boolean;
}

interface Service {
  _id: string;
  servicename: string;
}

interface JobItem {
  _id: string;
  serviceName: string;
  suburb: string;
  state: string;
  postCode: string;
  repeatCycle: number;
  startDate: string;
  startTime: string;
  endTime: string;
  endDate: string;
  description: string;
  budget: number;
  createdAt: string;
  creatorDetails: CreatorDetails;
  allServices: Service[];
}

interface AdminActiveJobsProps {
  activeTab: string;
  item: JobItem | null;
  loader: boolean;
  setActiveTab: (tab: string) => void;
}

const AdminActiveJobs: React.FC<AdminActiveJobsProps> = ({ activeTab, item, loader, setActiveTab }) => {
  const checkPreferences = (user: CreatorDetails | null): string => {
    if (!user) return '-'; // Return an empty string if preferences are not provided

    const { preferencemale, preferencefemale, preferencenopet, preferencenonsmoker } = user;

    // Check for specific combinations and return the corresponding HTML string
    if (!preferencemale && !preferencefemale && !preferencenopet && !preferencenonsmoker) {
      return '-';
    } else {
      let result = '';

      if (preferencemale && preferencefemale) {
        result += 'Male & Female';
      } else if (preferencemale) {
        result += 'Only Male';
      } else if (preferencefemale) {
        result += 'Only Female';
      }

      if (preferencenopet) {
        if (result !== '') result += ', ';
        result += 'No Pets';
      }

      if (preferencenonsmoker) {
        if (result !== '') result += ', ';
        result += 'Non Smoker';
      }
      return result;
    }
  };

  return (
    <div className="admin-job-review-stat">
      {loader
        ?
        <div>
          <Skeleton variant="rounded" className="w-full" style={{ borderRadius: 20 }} height={200} />
        </div>
        :
        <>
          {item ?
            <>
              <div className="admin-preview-head flex items-center justify-end">
                <p className="font-Poppins-Medium font-size-12px text-[#ffffff] pr-2 " >{getTimeDifference(item?.createdAt) || ""}</p>
              </div>

              <div className="admin-job-preview-main ">
                <div className="admin-preview-job">
                  <img src={`${item?.creatorDetails?.profileImageUrl}`} alt="" />
                  {/* <img src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.creatorDetails?.profileImageUrl}`} alt="" /> */}
                  <div className="text-center jobee-detail">
                    <h2 className="font-Poppins-Medium font-size-22px text-[#00443f]">  {item.serviceName ?? "-"}
                    </h2>
                    <p className="font-Poppins-SemiBold font-size-12px text-[#000000]">Posted by</p>
                    <p className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">{`${item?.creatorDetails?.firstName || ""} ${item?.creatorDetails?.lastName || ""}`}</p>
                    <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize-first-letter">{item?.creatorDetails?.roleCategory || "-"}</p>
                    <div className="flex flex-col">
                      {item?.creatorDetails?.dateofbirth &&
                        <p className="font-Poppins-SemiBold font-size-12px text-[#000000]">{calculateAge(item?.creatorDetails?.dateofbirth)} Years old</p>

                      }

                      <p className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">{item?.creatorDetails?.gender || ""}</p>
                    </div>
                  </div>
                </div>

                <div className="job-preview-content">
                  <div className="person-history-detail">
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faLocationDot} />
                      <p>{`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}</p>
                    </div>
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faVenus} />
                      <p>{checkPreferences(item?.creatorDetails)}</p>
                    </div>
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faClock} />
                      <p>
                        {
                          item?.repeatCycle == 1 ? "One-off"
                            : item?.repeatCycle == 2 ? "Daily"
                              : item?.repeatCycle == 3 ? "Weekly"
                                : item?.repeatCycle == 4 ? "Monthly"
                                  : "Custom"
                        }
                      </p>
                    </div>
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faCalendar} />
                      <p>Start date: {moment(item?.startDate).format("DD-MM-YYYY") || ""}</p>
                    </div>
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faCalendar} />
                      <p>End date: {moment(item?.endDate).format("DD-MM-YYYY") || ""}</p>
                    </div>
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faCalendar} />
                      <p>Start time: {convertTo12Hour((item?.startTime || ""))}</p>
                    </div>
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faCalendar} />
                      <p>End time: {convertTo12Hour((item?.endTime || ""))}</p>
                    </div>
                  </div>
                </div>
                <div className="theme-grey-type job-preview-content">
                  <div className="flex">
                    <div className="">
                      {/* {item?.allServices?.map((item, index) => ( */}
                      <h2 className="font-Poppins-Medium text-black font-size-15px">
                        {item?.serviceName}
                      </h2>
                      {/* ))} */}
                    </div>
                  </div>
                  <div className="">
                    <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
                      {item?.description || ""}
                    </p>
                  </div>
                  <div className="flex ">
                    <div className="job-pre-perhour-adminside">
                      <span className=" text-white font-Poppins-Regular font-size-14px">$ {item?.budget || 0}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex items-center justify-center mt-5">
                {
                  activeTab === "opened" ||
                    activeTab === "shortlisted"
                    ?
                    <ApplyJobsButton
                      id={item?._id}
                      successFunction={() => setActiveTab("applied")}
                    />
                    : null
                }
                {
                  activeTab === "opened" ||
                    activeTab === "shortlisted"
                    ?
                    <ShortListJobButton
                      id={item?._id}
                      successFunction={() => setActiveTab("shortlisted")}
                      activeTab={activeTab}
                    />
                    : null
                }

                {activeTab === "applied" &&
                  <button disabled className='inactive-job-tab font-size-15px font-Poppins-Bold'>Applied</button>
                }
              </div>
            </>
            :
            <p className="text-center font-size-15px font-Poppins-SemiBold theme-color-green">No {
              activeTab === "opened" ? "opened"
                : activeTab === "shortlisted" ? "shortlisted"
                  : "applied"
            } jobs</p>
          }
        </>
      }
    </div>
  )
}

export default AdminActiveJobs