import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import httpRequest from '../../../config/Helpers/httpRequest';
import { toast } from 'react-toastify';
import AdminHeader from '../../../components/AdminComponents/AdminHeader';
import PostCreatorSideBar from '../../../components/NewsFeeds/PostCreatorSideBar';
import { CircularProgress } from '@mui/material';
import DefaultProfileImage from "../../../assets/img/default_profile_image.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas, faMessage, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { postTimeDifference } from '../../../config/Helpers/helpers';
import ActionsReports from './ActionsReports';
import { useNavigate } from 'react-router-dom';
interface ReportDetails {
    reportStatus?: string;
    reportedCategory?: string;
    reportedType?: string;
    [key: string]: any; // Additional properties can be added as needed
}

interface Comment {
    _id?: string;
    firstName?: string;
    lastName?: string;
    profileImageUrl?: string;
    [key: string]: any;
}
interface Document {
    profileImageUrl?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    connections?: number;
    createdAt?: string;
    caption?: string;
    attachments?: { url: string }[];
    allReacts?: number;
    allComments?: number;
    [key: string]: any; // Additional properties can be added as needed
}
interface ApiResponse {
    reportDetails?: ReportDetails;
    result?: {
        document?: Document[];
        thread?: any[];
        comment?: any[];
    };
}
const PostReportPage: React.FC = () => {
    const { rid } = useParams<{ rid: string }>(); // Get route parameter `rid` with its type
    const navigate = useNavigate();
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [reportDetails, setReportDetails] = useState<ReportDetails>({});
    const [document, setDocument] = useState<Document>({});
    const [thread, setThread] = useState<any[]>([]);
    const [comment, setComment] = useState<Comment>([]);
    console.log(comment, "commentcomment")

    const fetchDetails = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/report/view/${rid}` });
        if (res) {
            let { reportDetails, result } = res;
            setReportDetails(reportDetails || {});
            setDocument(result?.document?.[0] || {});
            setThread(result?.thread || []);
            setComment(result?.comment || []);

        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchDetails();
    }, [rid]);
    const CommentCaption = ({ text = "" }) => {
        const [loaderMore, setLoaderMore] = useState(false);

        return (
            <>
                <p key={Math.random()} className="font-size-13px  font-Poppins-Regular">
                    {loaderMore ? text : text.slice(0, 300)}
                </p>
                {text.length > 300 && (
                    <button
                        className="on-hover-underline font-size-15px theme-grey-type  font-Poppins-Regular"
                        onClick={() => setLoaderMore((prev) => !prev)}
                    >
                        {loaderMore ? "...less" : "... more"}
                    </button>
                )}
            </>
        );
    };
    return (
        <>
            <AdminHeader />
            {isLoader
                ?
                <div className="full_page_loader">
                    <CircularProgress style={{ color: "#fff" }} />
                </div>
                :
                <div className="container-1480 flex justify-between h-3vw">
                    {/* <PostCreatorSideBar
                        data={document}
                    /> */}
                    <div className="px-3" style={{ flex: 1 }}>
                        <div className="shadow-lg bg-white rounded-xl mb-2 py-3 relative">
                            <div className="flex gap-2 items-center justify-between px-2 px-3">
                                <div className="flex gap-2 items-center">
                                    <div className="crete-feed-user-img flex ">
                                        <img
                                            width={100}
                                            loading="lazy"
                                            src={`${document?.profileImageUrl}`}
                                            // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${document?.profileImageUrl}`}
                                            onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                                            alt="user"
                                            className="rounded-full"
                                        />
                                    </div>{" "}
                                    {/* post creator image */}
                                    <div className="sidebar-gobal-user-name ">
                                        <Link
                                            to={`/public-profile/${document?.userId}/view`}
                                            className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize"
                                        >
                                            {" "}
                                            {`${document?.firstName || ""} ${document?.lastName || ""}`}
                                        </Link>{" "}
                                        {/* post creator name */}
                                        <p className="font-size-15px  theme-color-green font-Poppins-Regular  capitalize">
                                            {document?.connections || 0} Connections
                                        </p>{" "}
                                        {/* post creator connection count */}
                                        <p className="font-size-15px theme-grey-type  font-Poppins-Regular  ">
                                            {postTimeDifference(document?.createdAt || "")}{" "}
                                            <FontAwesomeIcon icon={faEarthAmericas} />
                                        </p>{" "}
                                        {/* post time differnce */}
                                    </div>

                                </div>
                                <button onClick={() => navigate("/report")}>
                                    <img
                                        className="mb-2 cursor-pointer h-[2vw]"
                                        src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
                                        alt=""
                                    />
                                </button>
                            </div>
                            <div className="px-3 pt-3">
                                <p className="font-size-17px theme-grey-type font-Poppins-Regular">
                                    {document?.caption || ""}
                                </p>
                            </div>
                            {document?.attachments && document?.attachments?.length > 0 && (
                                <div className=" pt-3">
                                    <img
                                        src={`${document?.attachments[0]?.url}`}
                                        // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${document?.attachments[0]?.url}`}
                                        className="w-full"
                                        alt=""
                                    />
                                </div>
                            )}
                            <ul className="flex justify-center gap-2 py-2">
                                <li className="total-like flex gap-1  items-center">
                                    <span style={{ backgroundColor: document?.allReacts && document?.allReacts > 0 ? "#0073b1" : "#707070" }}>
                                        <FontAwesomeIcon
                                            color="white"
                                            icon={faThumbsUp}
                                            flip="horizontal"
                                        />
                                    </span> {/* if post reacts is exist then active color apply */}
                                    <div className="font-size-15px theme-grey-type  font-Poppins-Medium">
                                        {document?.allReacts || ""}{" "}
                                    </div> {/* post reacts count */}
                                </li>
                                <li className="total-like flex gap-1  items-center">
                                    <FontAwesomeIcon icon={faMessage} color="#707070" />
                                    <div className="font-size-15px theme-grey-type font-Poppins-Medium" style={{ cursor: "context-menu" }}>
                                        {document?.allComments || 0} comments
                                    </div> {/* post comment count */}
                                </li>

                            </ul>
                            {reportDetails?.contentType !== "post" &&
                                <div className="w-full mb-5 flex gap-2  contain-comment-box">
                                    <div className="crete-feed-user-img flex items-start">
                                        <img
                                            width={100}
                                            loading="lazy"
                                            src={`${comment[0]?.profileImageUrl}`}
                                            // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${comment[0]?.profileImageUrl}`}
                                            alt="user"
                                            className="rounded-full"
                                        />
                                        {/* comment owner image */}
                                    </div>
                                    <div
                                        className="shadow-lg rounded-xl w-full feed-cmnt"
                                        style={{ backgroundColor: "#70707017" }}
                                    >
                                        <div className="flex gap-3 items-start relative">
                                            <div className="grid">
                                                <div className="">
                                                    <Link
                                                        to={`/public-profile/${comment[0]?.userId}/view`}
                                                        className="font-size-13px font-Poppins-SemiBold capitalize"
                                                    >
                                                        {`${comment[0]?.firstName || ""} ${comment[0]?.lastName || ""}`}
                                                        {/* <span className="theme-color-green"> Connect</span> */}
                                                    </Link>
                                                    {/* comment[0] owner name */}
                                                    <p className="font-size-13px theme-grey-type  font-Poppins-Regular capitalize">
                                                        {comment[0]?.role || ""}
                                                    </p>
                                                    {/* comment[0] owner role */}
                                                </div>
                                                <div className="absolute right-0 flex items-center gap-2">
                                                    <p className="font-size-13px theme-grey-type  font-Poppins-Regular  text-end">
                                                        {postTimeDifference(comment[0]?.createdAt || "")}
                                                    </p>

                                                </div>
                                                <div className="mt-1">
                                                    <CommentCaption text={comment[0]?.caption} />
                                                    {/* comment caption */}
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }


                            {/* {(reportDetails?.reportStatus !== "resolved" && reportDetails?.reportedCategory === "post" && reportDetails?.contentType === "post")
                                && */}
                            <ActionsReports
                                rid={rid}
                            />
                            {/* } */}
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default PostReportPage;