import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVenusMars } from "@fortawesome/free-solid-svg-icons";
interface SelectSmallProps {
  currentValue?: string;
  setGender?: (value: string) => void;
}

export default function SelectSmall({ currentValue = "", setGender = () => { } }: SelectSmallProps) {

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;  // Cast the value to string
    setGender(value);
  };
  const customPlaceholder = () => (
    <div className="filter-placeholder" style={{ display: "flex", alignItems: "center", opacity: 1, color: "#00443f" }}>
      <FontAwesomeIcon icon={faVenusMars} style={{ marginRight: 5 }} />
      <span className="font-Poppins-Regular">Availability</span>
    </div>
  );
  // const handleChange = (event) => {
  //   let value = event.target.value;
  //   if (value === 'All') {
  //     setGender("");
  //   }
  //   else {
  //     setGender(value);
  //   }
  // };

  return (
    <div className="availability">
      <FormControl sx={{ m: 1, minWidth: 120, backgoundColor: '#ffffff', fontFamily: 'Poppins' }} size="small">
        <InputLabel id="demo-select-small-label flex flex-row items-center" sx={{ fontFamily: 'Poppins', fontSize: '15px', display: "flex", flexDirection: "row", alignItems: "center" }}>
          <div className="filter-placeholder" style={{ display: "flex", flexDirection: "row", alignItems: "center", opacity: 1, color: "#00443f" }}>

            <FontAwesomeIcon icon={faVenusMars} style={{ marginRight: 5 }} />
          </div>
          <span className='font-Poppins-Regular'>
            Gender
          </span>
        </InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={currentValue}
          label="Gender"
          onChange={handleChange}
        >
          <MenuItem value="" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>All</MenuItem>
          <MenuItem value="male" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Male</MenuItem>
          <MenuItem value="female" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Female</MenuItem>
          <MenuItem value="other" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Other</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};