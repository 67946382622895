/**
 * AllApplicantsCard Component
 * Displays details of an applicant for a job, including profile image, name, category,
 * location, and actions (shortlist, compare, view profile, view application, accept, decline).
 * Handles API calls for shortlisting, accepting, and declining applicants.
 * File: AllApplicantsCard.js
 * Author: Developer
 * Date: 29-04-24
 */

import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faUserGroup, } from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate, useParams } from "react-router-dom";
import ApplyJobDisplay from "../JobsComponents/ApplyJobDisplay";
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import CancellationModal from "../AdminComponents/AdminModals/CancellationModal"
import httpRequest from '../../config/Helpers/httpRequest';
import { CalculateCancellationRate, calculateRating } from '../../config/Helpers/helpers';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import CompareIcon from "../../assets/img/compare-icon.svg"
interface Applicant {
    _id?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    roleCategory?: string;
    profileImageUrl?: string;
    suburb?: string;
    state?: string;
    postCode?: string;
    applicationMessage?: string;
    applicationFiles?: any[];
    [key: string]: any;
}
interface ApplyJobDisplayProps {
    data: ApplicationViewState;
    setData: React.Dispatch<React.SetStateAction<ApplicationViewState | null>>;
}
interface AllApplicantsCardProps {
    item?: Applicant;
    index?: number;
    activeTab?: string;
    setActiveTab?: (tab: string) => void;
    setBool?: (value: boolean | ((prev: boolean) => boolean)) => void;
}
interface ApiResponse {
    message: string;
}
interface ApplicationViewState {
    msg: string;
    files: any[]; // Adjust the type of `files` based on your data structure
}
interface documents {
    message: string;
    files: any[];
    [key: string]: any;
}
interface ApplicationResponse {
    document: documents;
    files: any[];
}

const AllApplicantsCard: React.FC<AllApplicantsCardProps> = ({
    item = {},
    index = 0,
    activeTab = "",
    setActiveTab = () => { },
    setBool = () => { }
}) => {
    console.log(activeTab, "activeTabactiveTab")

    const { jid } = useParams();
    const navigate = useNavigate();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const [viewApplication, setViewApplication] = useState<ApplicationViewState | null>(null);
    const [shortListLoader, setShortListLoader] = useState(false);
    const [declineLoader, setDeclineLoader] = useState(false);
    const [acceptId, setAcceptId] = useState<string | null>(null);
    const [declineId, setDeclineId] = useState<string | null>(null);

    const [acceptLoader, setAcceptLoader] = useState(false);
    const [applicationLoading, setApplicationLoading] = useState(false);

    const handelCompare = (UID: string | undefined) => {
        navigate(`/participant-compare`, { state: { id: UID, type: "provider", jobCompare: jid } });
    };

    // Functionality: API call for applicant shortlist
    const handleShortList = async (uId: string) => {
        setShortListLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "put", path: `/api/job/shortlist/${uId}`, params: {} });

        if (res) {
            console.log(res)
            toast(res?.message || "Success", { type: "success" });
            setActiveTab("shortlisted");

        } else {
            toast(err?.message, { type: "error" });
        }
        setShortListLoader(false);
    };

    // Functionality: API call to accept an applicant who has been hired for a job
    const handleAcceptApplicant = async () => {
        setAcceptLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "put", path: `/api/job/accept/${acceptId}`, params: {} });
        if (res) {
            setAcceptId(null);
            toast(res?.message || "Success", { type: "success" });
            navigate("/manage-jobs");
        } else {
            toast(err?.message, { type: "error" });
        }
        setAcceptLoader(false);
    }

    // Functionality: API call to declined an applicant
    const declineApplicant = async () => {
        setDeclineLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "put", path: `/api/job/decline/${declineId}`, params: {} });
        if (res) {
            setDeclineId(null);
            toast(res?.message || "Success", { type: "success" });
            setBool(prev => !prev);
        } else {
            toast(err?.message, { type: "error" });
        }
        setDeclineLoader(false);
    };
    const handleViewApplication = async (applicationId: string | undefined) => {
        if (!applicationId) return;

        setApplicationLoading(true);
        const { res, err } = await httpRequest<ApplicationResponse>({ method: "get", path: `/api/job/view/application/${applicationId}`, params: {} });

        if (res) {
            setViewApplication({ msg: res?.document?.message, files: res?.document?.files });
            setIsModalVisible(true);  // Show modal after data is fetched
        } else {
            toast(err?.message, { type: "error" });
        }
        setApplicationLoading(false);
    };

    return (
        <>
            {isModalVisible && viewApplication &&
                <ApplyJobDisplay
                    data={viewApplication}
                    setData={() => setIsModalVisible(false)}  // Hide the modal on close
                />
            }
            {declineId &&

                <CancellationModal
                    onClose={() => setDeclineId(null)}
                    messagedecline="Are you sure you want to decline this job application?"
                    leftBtnText="No"
                    rightBtnText="yes"
                    loader={declineLoader}
                    conFirmFunction={declineApplicant}
                />

            }
            {acceptId &&
                <div className='accept-appication-modal'>
                    <CancellationModal
                        onClose={() => setAcceptId(null)}
                        messagedecline="You are about to accept this job application"
                        leftBtnText="Go Back "
                        rightBtnText="Accept"
                        loader={acceptLoader}
                        conFirmFunction={handleAcceptApplicant}
                        status="accept"
                    />
                </div>
            }
            <div key={index} className="all-applicants">
                <div className='flex justify-between'>
                    {activeTab === "applied"
                        ?
                        <span></span>
                        :
                        <button disabled className="flex flex-row gap-1 items-center justify-end applicants-compare-btns bg-[#FFFCF1]">
                            <span className='font-Poppins-SemiBold font-size-12px text-[#00443f]'>Shortlisted</span>
                        </button>
                    }
                    <button onClick={() => handelCompare(item?.userId)} className="flex flex-row gap-1 items-center justify-end applicants-compare-btns">
                        {/* <FontAwesomeIcon icon={faUserGroup} /> */}
                        <img src={CompareIcon} alt="" />
                        <span className='font-Poppins-SemiBold font-size-12px text-[#00443f]'>Compare</span>
                    </button>
                </div>
                <div className="all-applicants-head flex flex-row justify-center mt-[-8px]">
                    <img src={`${item?.profileImageUrl}`} />
                    {/* <img src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`} /> */}

                </div>
                <div>
                    <div className='text-center mb-2 mt-2'>
                        <h2 className="font-Poppins-Medium mt-1 font-size-15px capitalize">
                            {`${item?.firstName || ""} ${item?.lastName || ""}`}
                        </h2>
                        <p className=" leading-3 font-size-13px font-Poppins-Medium text-[#b5b5b5] capitalize-first-letter">
                            {item?.roleCategory || ""}
                        </p>
                    </div>
                    <div className="applicant-address flex justify-center flex-row items-center gap-2 mb-2">

                        <p className="theme-color-para font-size-10px font-Poppins-Medium">
                            {`${item?.noOfConnections}`} Connection
                        </p>

                    </div>
                    <div className="text-center">
                        <div className="applicant-address flex justify-center flex-row items-center gap-2 mb-2">
                            <FontAwesomeIcon icon={faLocationDot} />
                            <p className="theme-color-para font-size-14px font-Poppins-Regular">
                                {`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}
                            </p>
                        </div>
                        <div className="applicant-address flex justify-center flex-row items-center gap-2 mb-2">

                            <p className="theme-color-para font-size-10px font-Poppins-Medium">
                                {`${item?.completedJobs}`} Jobs completed
                            </p>

                        </div>
                        <div className="applicant-address flex justify-center flex-row items-center gap-2 mb-2">

                            <p className="theme-color-para font-size-10px font-Poppins-Medium">
                                {/* {`${item?.cancelledJobs /  }`} Jobs completed */}
                                {CalculateCancellationRate(item?.cancelledJobs, item?.completedJobs)} Cancellation rate
                            </p>
                        </div>
                        <div className="text-yellow-400 flex justify-center gap-1 ">
                            {[1, 2, 3, 4, 5].map((v) => (
                                <FontAwesomeIcon
                                    key={v}
                                    fontSize="20px"
                                    icon={faStar}

                                    color="#FF9B29"
                                    className={
                                        calculateRating(
                                            item?.totalRatings,
                                            item?.totalReviews
                                        ) >= v
                                            ? "active"
                                            : "nonActiveStar"
                                    }
                                />
                            ))}
                        </div>

                        <div className="applicant-address flex justify-center flex-row items-center gap-2 mb-2 mt-2">
                            <p className="theme-color-para font-size-10px font-Poppins-Medium">
                                {`${item?.totalReviews}`} Reviews
                            </p>
                        </div>
                        <div className='flex justify-center mb-2 mt-1'>
                            <Link to={`/public-profile/${item?.userId}/view`} className="flex flex-row gap-1 items-center justify-center applicants-compare-btns">
                                <span className='font-Poppins-SemiBold font-size-12px text-[#00443f]'>View profile</span>
                            </Link>
                        </div>
                        <div className="flex flex-row gap-2 justify-center">
                            <button onClick={() => handleViewApplication(item?._id)} className="flex flex-row gap-2 justify-center applicants-view">
                                <span className="font-size-14px font-Poppins-Regular text-[#de0000]">View application</span>
                            </button>
                        </div>
                        {activeTab !== "declined" &&
                            <div className="admin-box-app-dec">
                                {activeTab === "applied"
                                    ?
                                    <button disabled={shortListLoader} onClick={() => handleShortList(item?._id || "")} className="font-Poppins-SemiBold theme-bg-green font-size-12px flex flex-row gap-2 items-center justify-center">
                                        Shortlist {shortListLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}
                                    </button>
                                    :
                                    <button disabled={shortListLoader} onClick={() => setAcceptId(item?._id || null)} className="font-Poppins-SemiBold theme-bg-green font-size-12px flex flex-row gap-2 items-center justify-center">
                                        Accept {shortListLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}
                                    </button>
                                }
                                <button onClick={() => setDeclineId(item?._id || null)} className="font-Poppins-SemiBold bg-[#DF0000] font-size-12px">
                                    Decline
                                </button>
                            </div>
                        }

                    </div>
                </div>
            </div >
        </>
    );
};

export default AllApplicantsCard;