/**
 * ReportOptions Component
  * Handles displaying a modal for reporting posts with various options and
 * makes an API call to submit the report.
 * File: ReportOptions.js
 * Author: Developer
 * Date: 04-07-24
 */

import React, { useEffect, useState } from 'react';
import closeIcon from "../../assets/img/closeIcon.svg";
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
import { CircularProgress } from '@mui/material';
import { removePostById, resetAllPosts } from '../../config/Store/Reducers/newsFeedSlice';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from '../../config/Store/store';
import { useNavigate } from 'react-router-dom';
interface ReportOptionsProps {
    id?: string | undefined;  // `id` should be a string if provided
    onClose?: () => void;  // `onClose` is a function that can be called when closing the modal
    type?: string;  // `type` should be a string if provided
    category?: string;  // `category` should be a string if provided
}
interface ApiResponse {
    message: string;
    [key: string]: any;
}
// Define the state type for the component
interface ReportState {
    reportedId: string;
    subject: string;
    reportedCategory: string;
}

const PostDelete: React.FC<ReportOptionsProps> = ({
    id = '',
    onClose = () => { },
    type = "",
    category = ""
}) => {
    const navigate = useNavigate();

    const activeClass = {
        backgroundColor: "#004540",
        color: "#fff"
    }; // Styles for active state of selected options
    const { allPosts } = useSelector((store: RootState) => store.feed)
    let options = ['harassment', 'fraud or scam', 'spam', 'misinformation', 'hateful speech', 'threats or violence', 'self-harm', 'graphic content', 'sexual content', 'fake account', 'hacked account', 'child exploitation', 'illegal services', 'infringement'
    ]; // Array of options corresponding to backend enum for reporting reasons
    const dispatch = useDispatch();
    const [isLoader, setIsLoader] = useState<boolean>(false); // State for loader indication during API requests
    const [state, setState] = useState<ReportState>({
        reportedId: '',
        subject: '',
        reportedCategory: ''
    }); // State object representing data for API request body


    const handleClose = () => {
        setState({
            reportedId: '',
            // reportedType: '',
            subject: '',
            reportedCategory: ''
        });
        onClose();
    }; // Function to reset state and close the report modal

    // Functionality: Handles API call to submit report
    const handleSubmit = async () => {
        let apiPath = "";
        if (category === "comment") {
            apiPath = `/api/social/${state?.reportedId}`
        }
        else if (category === "discussion") {
            apiPath = `/api/discussion/${state?.reportedId}`

        }
        else {
            apiPath = `/api/posts/${state?.reportedId}`
        }
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "delete", path: apiPath, params: {} });
        if (res) {
            toast(res?.message || "Success", { type: "success" });

            handleClose();
            dispatch(removePostById(state.reportedId)); // Dispatch the action to remove the post by ID
            if (category === "discussion") {
                navigate("/discussions")
            }
            // dispatch(resetAllPosts());

        } else if (err) {
            toast(err?.message, { type: "error" });
        } else { }
        setIsLoader(false);

    };

    useEffect(() => {
        if (id) {
            setState(prev => ({
                ...prev,
                reportedId: id,
                contentType: type,
                reportedCategory: category
            }));
        };
    }, [id]); // Effect to update state on component mount or when id changes

    return (
        <div className="ovrlayModal modal">
            <div className="createpost-modal absolute z-20">
                <button
                    className="absolute -top-5 font-size-24px"
                    style={{ right: "-10px" }}
                    onClick={handleClose}
                >
                    <img src={closeIcon} alt="" />
                </button>{" "}
                {/* close post modal */}
                <div className="mb-3">
                    <h1 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
                        Delete this {category === "comment" ? "comment" : category === "post" ? "post" : "discussion"}
                    </h1>
                    <hr className="mt-3" />
                </div>
                <p className="font-size-16px font-Poppins-Medium theme-color-green">
                    Are you sure you want to delete this {category === "comment" ? "comment" : category === "post" ? "post" : "discussion"} ?{" "}
                </p>
                {/* <div className="flex gap-2 mt-3 flex-wrap">
                    {options.map(v => (
                        <button onClick={() => setState(prev => ({ ...prev, subject: v }))} key={v} className={`font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn capitalize`} style={state?.subject === v ? activeClass : {}}>
                            {v}
                        </button>
                    ))}
                </div> */}
                <hr className="mt-3" />
                <div className="flex justify-end gap-2 mt-3">
                    <button
                        className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                        style={{ color: "#fff" }}
                        onClick={handleClose}
                    >
                        Back
                    </button>
                    <button
                        className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                        style={{ color: "#fff" }}
                        onClick={handleSubmit}
                        disabled={isLoader}
                    >
                        Delete {isLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PostDelete;