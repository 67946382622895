import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import ndisailogo from "../../assets/img/ndisailogo.svg";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { RootState } from "../../config/Store/store";
import { getRole } from "../../config/Helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import {
  setValue,
  setNumber,
  setCurrentPage,
  setData,
  setLoader,
} from "../../config/Store/Reducers/HeaderSearchSlice";
import httpRequest from "../../config/Helpers/httpRequest";
import Isverified from "../../assets/img/isMember.svg"
import IsFivityVerfied from "../../assets/img/50member.svg"


interface Paginated {
  totalItems: number;
  // Add other properties as needed
}

interface SearchResponse {
  paginated: Paginated;
  numberOfResults: number;
  documents: any[]; // Replace `any` with the specific type if you know it
  promotions: any[]; // Replace `any` with the specific type if you know it
}
function HeaderSearch() {
  const decoded = getRole();
  const UID = decoded?.userId || "";
  const userRole = decoded?.role;
  // let UID = getRole()?.userId || "";
  // let userRole = getRole()?.UserRole;

  const dispatch = useDispatch();

  const {
    value,
    numberOfResults,
    isLoader,
    data,
    currentPage,
    perPage,
    sort,
    bool,
  } = useSelector((store: RootState) => store.header);
  const isFirstRender = useRef(true); // for check a componentDidMount
  const timeoutIdRef = useRef<number | null>(null);
  const [isFocused, setIsFocused] = useState(false);

  /**
   * This function is used to make API call for user search (Header search).
   * It has two parameters:
   *  1. text: The text to search
   *  2. _time: Delay time before making API call
   *  3. _page: The page number to fetch in the API call
   *
   * Functionality: API call for user search (Header search)
   * @param {string} text - The text to search
   * @param {number} _time - Delay time before making API call
   * @param {number} _page - The page number to fetch in the API call
   */
  const handleSearch = async (text: string, _time: number, _page: number) => {
    // Regular expression to check if the text contains only alphabets and spaces
    const textRegex = /^[A-Za-z\s]*$/;

    // If the text does not match the regex, return
    if (!text.match(textRegex)) {
      return;
    }

    // If the text is not empty
    if (text) {
      // Update the current page, search value, and show loader
      dispatch(setCurrentPage(_page));
      dispatch(setValue(text));
      dispatch(setLoader(true));

      // Clear previous timer
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }

      // Set new timer
      timeoutIdRef.current = window.setTimeout(async () => {
        const { res, err } = await httpRequest<SearchResponse>({ path: `/api/search/profile?query=${text}&page=${currentPage}&pageSize=${perPage}&sort=${sort}` });
        if (res) {
          let count = res?.paginated?.totalItems || 0;
          dispatch(setNumber(count));

          let combinedConnection = [...(res?.documents || []), ...(res?.promotions || [])];
          combinedConnection = combinedConnection.sort((a, b) => {
            if (a.promotionId && !b.promotionId) return -1;
            if (!a.promotionId && b.promotionId) return 1;
            return 0;
          });


          // dispatch(setData(res?.documents || []));
          dispatch(setData(combinedConnection));

        } else {
          toast(err?.message, { type: "error" }); // api error
        }
        dispatch(setLoader(false));
      }, _time);
    } else {
      // If the text is empty, reset the search parameters
      dispatch(setCurrentPage(1));
      dispatch(setValue(""));
      dispatch(setNumber(0));
      dispatch(setData([]));
    }
  };

  useEffect(() => {
    if (isFirstRender.current) {
      // Skip the first render not call handleSearch function
      isFirstRender.current = false;
      return;
    }
    if (value) {
      handleSearch(value, 0, currentPage);
    }
  }, [currentPage, sort, bool]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event: MouseEvent) => {
    const target = event.target as HTMLElement | null;

    if (target && !target.closest(".header-focused-box-parent")) {
      setIsFocused(false);
    }
  };

  //

  const [isActive, setIsActive] = useState(false);

  const handleIconClick = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <div className="admin-searchInp flex relative gap-8 ">
        <div className="flex items-center justify-center ">
          <Link to={`${userRole === "superAdmin" ? "/admin" : "/feed"}`}>
            <img src={ndisailogo} className="" alt="" />
          </Link>
        </div>
        <div className="desktop-only">
          <div className="relative">
            <input
              type="search"
              placeholder="Search"
              value={value}
              onFocus={() => setIsFocused(true)}
              onDoubleClick={() => setIsFocused(true)}
              onSelect={() => setIsFocused(true)}
              onChange={(e) => handleSearch(e.target.value, 500, 1)}
            />
            <FontAwesomeIcon
              className="absolute"
              icon={faMagnifyingGlass}
              onClick={handleIconClick}
            />
          </div>
          {/* {isFocused && ( */}
          {isFocused && value && (
            <div className="absolute header-focused-box-parent w-full">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <span
                    className="font-size-18px font-Poppins-Regular leading-none"
                    style={{ color: "#A8A8A8" }}
                  >
                    People
                  </span>
                  <span
                    className="px-3 py-1 font-size-15px font-Poppins-Regular leading-none"
                    style={{
                      borderRadius: "40px",
                      backgroundColor: "#DBDBDB",
                      color: "#A8A8A8",
                    }}
                  >
                    {numberOfResults}
                  </span>
                </div>
                {numberOfResults > 0 && (
                  <Link
                    to="/user-search"
                    className="font-size-18px font-Poppins-Medium header-sel leading-none theme-color-green "
                  >
                    See all
                  </Link>
                )}
                {/* <Link to="/user-search"  className="font-size-18px font-Poppins-Medium header-sel leading-none">
                            See All
                        </Link> */}
                {/* <Link to="/user-search" className="font-size-18px font-Poppins-Medium header-sel leading-none">
                            See All
                        </Link> */}
              </div>
              {isLoader ? (
                <div
                  className="search-motion mt-3"
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="custom-loader">
                    <div className="loader"></div>
                  </div>
                </div>
              ) : data && data.length > 0 ? (
                data.slice(0, 4).map((item, index) => (
                  <Link
                    to={`/public-profile/${item?._id}/view`}
                    onClick={() => setIsFocused(false)}
                    key={index}
                    className="flex flex-col gap-3 py-3"
                  >
                    <div className="header-focused-box-after-search flex items-center gap-2 relative">
                      {UID === item?._id && (
                        <span className="self_search">You</span>
                      )}
                      <div className="relative">
                        <img
                          className="header-focused-box-after-search-img"
                          src={`${item?.profileImageUrl}`}
                          // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                          alt=""
                        />
                        {/* <span className="inline-block online-status-header-focused-box absolute right-0 bottom-4"></span> */}
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex flex-row items-center gap-2">
                          <span
                            className="font-size-18px font-Poppins-Medium leading-none capitalize"
                            style={{ color: "#7E7C7C" }}
                          >
                            {`${item?.firstName || ""} ${item?.lastName || ""}`}
                          </span>
                          {!item?.freeUser &&
                            <img src={item.isFounder ? IsFivityVerfied : Isverified} alt="crown" className="w-[1.4vw]" />

                          }

                        </div>
                        <span
                          className="font-size-18px font-Poppins-Regular leading-none"
                          style={{ color: "#D4D4D4" }}
                        >
                          {item?.providerCategory || item?.role || ""}
                        </span>
                      </div>
                    </div>
                  </Link>
                ))
              ) : (
                <p className="no-result-found  font-Poppins-Medium">
                  No results found
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className={`mobile-only   ${isActive ? "active-class" : ""}`}>
        <div className="">
          <input
            type="search"
            placeholder="Search"
            value={value}
            onFocus={() => setIsFocused(true)}
            onDoubleClick={() => setIsFocused(true)}
            onSelect={() => setIsFocused(true)}
            onChange={(e) => handleSearch(e.target.value, 500, 1)}
          />
          <FontAwesomeIcon
            className="absolute"
            icon={faMagnifyingGlass}
            onClick={handleIconClick}
          />
        </div>
        {/* {isFocused && ( */}
        {isFocused && value && (
          <div className="absolute header-focused-box-parent w-full">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <span
                  className="font-size-18px font-Poppins-Regular leading-none"
                  style={{ color: "#A8A8A8" }}
                >
                  People
                </span>
                <span
                  className="px-3 py-1 font-size-15px font-Poppins-Regular leading-none"
                  style={{
                    borderRadius: "40px",
                    backgroundColor: "#DBDBDB",
                    color: "#A8A8A8",
                  }}
                >
                  {numberOfResults}
                </span>
              </div>
              {numberOfResults > 0 && (
                <Link
                  to="/user-search"
                  className="font-size-18px font-Poppins-Medium header-sel leading-none  theme-color-green "
                >
                  See all
                </Link>
              )}
              {/* <Link to="/user-search"  className="font-size-18px font-Poppins-Medium header-sel leading-none">
                            See All
                        </Link> */}
              {/* <Link to="/user-search" className="font-size-18px font-Poppins-Medium header-sel leading-none">
                            See All
                        </Link> */}
            </div>
            {isLoader ? (
              <div
                className="search-motion mt-3"
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div className="custom-loader">
                  <div className="loader"></div>
                </div>
              </div>
            ) : data && data.length > 0 ? (
              data.slice(0, 4).map((item, index) => (
                <Link
                  to={`/public-profile/${item?._id}/view`}
                  onClick={() => setIsFocused(false)}
                  key={index}
                  className="flex flex-col gap-3 py-3"
                >
                  <div className="header-focused-box-after-search flex items-center gap-2 relative">
                    {UID === item?._id && (
                      <span className="self_search">You</span>
                    )}
                    <div className="relative">
                      <img
                        className="header-focused-box-after-search-img"
                        src={`${item?.profileImageUrl}`}
                        // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                        alt=""
                      />
                      {/* <span className="inline-block online-status-header-focused-box absolute right-0 bottom-4"></span> */}
                    </div>
                    <div className="flex flex-col gap-2">
                      <span
                        className="font-size-18px font-Poppins-Medium leading-none capitalize"
                        style={{ color: "#7E7C7C" }}
                      >
                        {`${item?.firstName || ""} ${item?.lastName || ""}`}
                      </span>
                      <span
                        className="font-size-18px font-Poppins-Regular leading-none"
                        style={{ color: "#D4D4D4" }}
                      >
                        {item?.providerCategory || item?.role || ""}
                      </span>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className="no-result-found  font-Poppins-Medium">
                No results found
              </p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderSearch;