import React, { useState } from 'react';

import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { Link, useNavigate } from 'react-router-dom';
import { postTimeDifference, truncateString } from '../../config/Helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas, faArrowRight, faX } from '@fortawesome/free-solid-svg-icons';
import ModalIcon from "../../assets/img/modal-icon.svg"
import ModalMap from "../../assets/img/modal-map.svg"
import Checkboxicon from "../../assets/img/checkboxicon.svg"
interface UnpaidModalProps {
    onClose: (isOpen: boolean) => void;
    error: string;
    profile: boolean;
}
const UnpaidModal: React.FC<UnpaidModalProps> = ({ onClose, error, profile }) => {
    const navigate = useNavigate();


    const handleClose = () => {
        if (profile) {
            navigate(-1);
        }
        onClose(false);
    };
    return (
        <div className="post__modal">
            <div className=' unpaid_modal_inner'>
                <button
                    // onClick={() => onClose(false)}
                    onClick={handleClose}
                    className="absolute"
                    style={{ top: 10, right: 10 }}
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className='flex justify-center my-3'>
                    <img className='unpaid-modal-icon' src={ModalIcon} alt="" />
                </div>
                <div className='flex justify-between flex-col inner-body-unpaid'>
                    <div className='flex flex-col '>
                        <div className=' flex justify-center '>
                            <h2 className='font-size-30px font-Poppins-Regular text-[#444444]'>You have reached your free user limit!</h2>
                            <img className='absolute modal-map' src={ModalMap} alt="" />
                        </div>
                        <div className='flex flex-row gap-2 items-center justify-center my-7'>
                            <img src={Checkboxicon} alt="" />
                            <span className="font-size-15px font-Poppins-Medium text-[#444444]" >{error}</span>
                        </div>
                    </div>

                    <div className="flex   justify-center z-10">
                        <Link to="/subscription" className='feeds-btn  bg-[#009E92] text-nowrap  font-size-18px font-Poppins-SemiBold text-[#FFFFFF] cursor-pointer flex flex-row gap-2'>Upgrade to premium <FontAwesomeIcon icon={faArrowRight} /></Link>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default UnpaidModal;