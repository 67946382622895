import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faX, faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import wetalked from "../../assets/img/wetalked.svg";
import voicemail from "../../assets/img/voicemail.svg";
import noanswer from "../../assets/img/no-answer.svg";
import httpRequest from "../../config/Helpers/httpRequest";
interface ApiResponse {
  message: string;
  comment: string;
  [key: string]: any;
}
interface ShowNumberProps {
  onClose: () => void;
  data: {
    firstName?: string;
    lastName?: string;
    phonenumber?: string;
  };
  jobId: string;
  successFunction: (comment: string) => void;
}

const ShowNumber: React.FC<ShowNumberProps> = ({ onClose, data, jobId, successFunction }) => {

  const dummyArr = [
    { message: "No answer", icon: noanswer },
    { message: "Left voicemail", icon: voicemail },
    { message: "We talked", icon: wetalked },
  ];

  const handleCopyNumber = async (text: string) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast("Copied to clipboard.", { type: "success" });
    } catch (err) {
      toast("Something went wrong.", { type: "error" });
    }
  };

  // Functionality: API call "CALL" for job history log
  const handleCall = async (message: string) => {
    const { res, err } = await httpRequest<ApiResponse>({
      method: "post",
      path: "/api/job/add-comment",
      params: {
        jobId: jobId,
        title: "call",
        message: message,
      }
    });
    if (res) {
      onClose();
      successFunction(res?.comment);
      toast(res?.message || "Success", { type: "success" });
    } else {
      toast(err?.message, { type: "error" });
    }
  };
  return (
    <>
      <div className="modal">
        <div className="Cancellation-modal-content relative">
          <button onClick={onClose} className="absolute job-pre-edit">
            <FontAwesomeIcon
              className="x-icon-admin-opr"
              icon={faX}
              style={{ color: "#ffffff" }}
            />
          </button>
          <h2 className="font-Poppins-SemiBold font-size-30px  text-[#464646] capitalize">
            Call {`${data?.firstName || ""} ${data?.lastName || "-"}`}
          </h2>
          <div className="flex flex-row items-center justify-center gap-3 mt-3 mb-3">
            <span className="font-Poppins-SemiBold  text-[#00443f]">
              <FontAwesomeIcon icon={faPhone} />
            </span>
            <span className="font-Poppins-SemiBold font-size-20px text-[#00443f]">
              {data?.phonenumber || "-"}
            </span>
            <span
              onClick={() => handleCopyNumber(`${data?.phonenumber}`)}
              role="button"
              className="font-Poppins-SemiBold  text-[#00443f]"
            >
              <FontAwesomeIcon icon={faCopy} />
            </span>
          </div>
          <span className="font-Poppins-Medium font-size-16px text-[#202020] mt-2 mb-2">
            Update your progress
          </span>

          <div className="cancellation-envolpe-main gap-3  mt-3 mb-3">
            {dummyArr.map((item, index) => (
              <div
                key={index}
                onClick={() => handleCall(item?.message)}
                role="button"
                className="flex flex-col justify-center items-center"
              >
                <img className="admin-see-num" src={item?.icon} />
                <span className="font-Poppins-Medium font-size-15px  text-[#ef6277]">
                  {item?.message}
                </span>
              </div>
            ))}
          </div>
          <div className="didnt-line mt-2 mb-2"></div>
          <button
            onClick={onClose}
            className="font-Poppins-Regular font-size-17px text-[#707070]"
          >
            Didn't call
          </button>
        </div>
      </div>
    </>
  );
};

export default ShowNumber;