import React, { useState, useEffect } from "react";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import Header from "../../components/Header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import dayjs, { Dayjs } from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import DemoVideo from "../../components/WatchDemoVideo/DemoVideo";
import bookingconfirm from "../../assets/img/bookingconfirm.svg";
import moment from "moment";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../config/Helpers/httpRequest";
interface AvailableSlot {
  available: number[]; // Adjust this type according to your data structure
  err?: any;
  [key: string]: any;
}
/**
 * ScheduleMeeting component for scheduling appointments.
 */
const ScheduleMeeting = () => {
  const [availableSlots, setAvailableSlots] = useState<number[]>([]); // Specify the type as an array of numbers

  const [step, setStep] = useState<number>(1);
  const [submitLoader, setsubmitLoader] = useState<boolean>(false);
  const [email, setEmail] = useState<string>("");
  const [phonenumber, setPhonenumber] = useState<string>("");
  const [appointmentDate, setAppointmentDate] = useState<Dayjs | null>(null); // Use Dayjs type from dayjs
  const [slot, setSlot] = useState<number | "">(""); // To handle both number and empty string
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  /**
  * Functionality: Fetch available slots for a given date.
  * @param {string} _date - Date in ISO format
  */
  const fetchAvailAbleSlots = async (_date: string) => {
    const { res, err } = await httpRequest<AvailableSlot>({ path: `/api/marketing/availableSlot/${_date}` });
    if (res) {
      setAvailableSlots(res?.available || []); // Set the response data in the state   
    } else {
      toast(err?.message, { type: "error" })
    }
  };

  const handleDateChange = (_date: Dayjs | null) => {
    if (_date) {
      setAppointmentDate(_date);
      fetchAvailAbleSlots(_date.toISOString()); // Assuming fetchAvailAbleSlots takes an ISO string
    }
  };

  const shouldDisableDate = (date: Dayjs) => {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Remove time part for accurate comparison
    return date.isBefore(today);
  };

  /**
   * Functionality: Handle form submission appointment.
   * @param {Event} event - Form submission event
   */
  const handlesubmit = async (event: React.FormEvent) => {
    setsubmitLoader(true);
    event.preventDefault();
    const { res, err } = await httpRequest({
      method: "post", path: "/api/marketing/get-appointment", params: {
        email,
        phonenumber,
        appointmentDate,
        slot,
        firstName,
        lastName,
        message,
      }
    });
    if (res) {
      fetchAvailAbleSlots(appointmentDate?.toISOString() || "");
      setStep(3);
    } else {
      toast(err?.message, { type: "error" })
    }
    setsubmitLoader(false);
  };

  const AppointmentSchedule = {
    1: "12:00",
    2: "12:30",
    3: "13:00",
    4: "13:30",
    5: "14:00",
    6: "14:30",
  };

  useEffect(() => {
    const currentDate = dayjs().add(1, "day"); // Add one day to the current date
    setAppointmentDate(currentDate);
    fetchAvailAbleSlots(currentDate.toISOString()); // Assuming fetchAvailAbleSlots takes an ISO string
  }, []); // Empty dependency array to run this effect only once

  return (
    <>
      <section className="login-page-parent relative h-screen theme-bg-cream-white overflow-clip">
        <Header />
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10 opacity-30 bg-texture-banner"
          src={"https://edgie.backslashwebs.com/imgs/banner-bg-texture.png"}
          alt=""
        />
        <div className="container-custom h-full relative z-20 flex items-center">
          <div
            className="login-paid-plan-main-content-parent relative flex items-end justify-center w-full theme-bg-green banner-box-border-radius"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <img
              decoding="async"
              className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 opacity-20 z-10 w-4/6"
              src={"https://edgie.backslashwebs.com/imgs/banner-bg-map.png"}
              alt=""
            />

            <div className={`demo-mean-screen demo-mean-screen-2 ${step === 3 || step === 2 ? 'demo-mean-screen-step-2' : ''}`}>
              <DemoVideo  isModalVedioRender={true}/>
              <div className=" z-10 relative freedemo-size">
                <h3
                  style={{ height: "40px" }}
                  className="h-5	  font-Poppins-SemiBold font-size-login-36px text-white leading-none text-center lg-main-head flex items-center justify-center"
                >
                  Get a free 1:1 demo
                </h3>
                {step === 1 ? (
                  <>
                    <div className="schedule-meeting-container mb-3">
                      <div
                        className="h-full flex flex-col justify-center inner-schedule-meeting"
                        style={{
                          backgroundColor: "#009e92",
                          borderRadius: "20px 0 0 20px",
                        }}
                      >
                        <h4 className="font-size-login-16px font-Poppins-Medium text-white text-center">
                          Find a time for meeting
                        </h4>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DateCalendar
                            value={appointmentDate}
                            onChange={handleDateChange}
                            shouldDisableDate={shouldDisableDate} // Disable past and current dates
                          />
                        </LocalizationProvider>
                      </div>
                      <div
                        className="theme-bg-cream-white h-full flex flex-col justify-center"
                        style={{ borderRadius: "0 20px 20px 0" }}
                      >
                        <h4 className="font-size-login-16px font-Poppins-Medium theme-color-green  text-center">
                          Meeting duration
                        </h4>
                        <button className="duration-set ">30 Minutes</button>
                        <h4 className="my-2 font-size-login-16px font-Poppins-Medium theme-color-green  text-center">
                          What time works best ?
                        </h4>
                        <div className="flex  gap-2 justify-center mb-2 showtime-res">
                          <p className="font-size-login-16px font-Poppins-Regular theme-color-green">
                            Showing times for
                          </p>
                          <span className="font-size-login-16px font-Poppins-SemiBold theme-color-green">
                            {/* {moment(appointmentDate?.$d).format("MMMM D, YYYY")}
                             */}
                            {moment(appointmentDate?.toDate()).format("MMMM D, YYYY")}

                          </span>
                        </div>
                        <h4 className="font-size-login-16px font-Poppins-Medium theme-color-green  text-center">
                          <FontAwesomeIcon icon={faEarthAmericas} />{" "}
                          Sydney/Australia
                        </h4>
                        <div className="duration-container">
                          {availableSlots.map((data, index) => (
                            <button
                              key={index}
                              className={`duration-set ${slot === index + 1 ? "active" : ""
                                }`}
                              onClick={() => setSlot(index + 1)}
                            >
                              {AppointmentSchedule[data as keyof typeof AppointmentSchedule] || ""}
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                    <button
                      onClick={() => setStep(2)}
                      disabled={!slot}
                      className=" bg-white theme-color-green border-radius-6px font-size-login-16px font-Poppins-SemiBold login-btn-home-banner input-btns-height-40px flex items-center justify-center"
                    >
                      Next
                    </button>
                  </>
                ) : step === 2 ? (
                  <form onSubmit={handlesubmit}>
                    <div className="w-full theme-bg-cream-white z-10 relative rounded-3xl	h-full p-5 custom-h-meeting-screen-1">
                      <div className="pb-3">
                        <p className="font-size-20px font-Poppins-SemiBold theme-color-green">
                          Your Information
                        </p>
                        <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                          {/* {moment(appointmentDate?.$d).format(
                            "dddd, MMMM D, YYYY"
                          )} */}
                          {moment(appointmentDate?.toDate()).format("MMMM D, YYYY")}

                          ,
                          {AppointmentSchedule[slot as keyof typeof AppointmentSchedule] || ""}
                        </p>
                      </div>
                      <div className="flex  gap-3 ">
                        <input
                          type="text"
                          placeholder="First Name"
                          className="shadow-lg home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px bg-white outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                          value={firstName}
                          onChange={(e) => setFirstName(e.target.value)}
                          required
                        />
                        <input
                          type="text"
                          placeholder="Last Name"
                          className="shadow-lg mb-3 home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px bg-white outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                          value={lastName}
                          onChange={(e) => setLastName(e.target.value)}
                          required
                        />
                      </div>

                      <input
                        type="email"
                        placeholder="Email Address"
                        className=" shadow-lg mb-3 home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px bg-white outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                      />
                      <input
                        type="number"
                        placeholder="Phone Number"
                        className="shadow-lg mb-3 home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px bg-white outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                        value={phonenumber}
                        onChange={(e) => {
                          const inputPhoneNumber = e.target.value.replace(
                            /\D/g,
                            ""
                          ); // Remove non-numeric characters
                          if (inputPhoneNumber.length <= 10) {
                            // Ensure length is up to 10 digits
                            setPhonenumber(inputPhoneNumber);
                          }
                        }}
                        required
                      />

                      <div className="mb-3">
                        <textarea
                          placeholder="Message"
                          className="txt-area-h shadow-lg pt-2 home-banner-input input-placeholder-green h-full  w-full border-radius-12px bg-white outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          required
                          style={{ height: "100px" }}
                        ></textarea>
                      </div>
                      <div className="flex justify-between">
                        <button
                          className=" theme-color-green border-radius-6px font-size-13px font-Poppins-SemiBold items-center input-btns-height-40px btn-w flex p-2 items-center justify-center gap-1 loader-btn"
                          onClick={() => setStep(1)}
                          style={{
                            background: "transparent",
                            border: "1px solid #009e92",
                            color: "black",
                          }}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} /> Back
                        </button>
                        <button
                          type="submit"
                          className=" bg-white theme-color-green border-radius-6px font-size-13px font-Poppins-SemiBold  btn-w login-btn-home-banner input-btns-height-40px flex items-center justify-center gap-1 loader-btn"
                          disabled={submitLoader}
                          style={{ margin: "0" }}
                        >
                          Confirm{" "}
                          {submitLoader && (
                            <CircularProgress
                              style={{ width: 14, height: 14, color: "#fff" }}
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <div className="w-full theme-bg-cream-white z-10 flex items-center justify-center gap-3 flex-col relative rounded-3xl	h-full home-nids-video-border-radius-2 home-nids-video-border-radius-res">
                    <img src={bookingconfirm} alt="" />
                    <h4 className="font-size-login-29px font-Poppins-SemiBold theme-color-green text-center">
                      Booking Confirmed
                    </h4>
                    <p className="font-size-login-16px font-Poppins-Medium theme-color-green text-center">
                      You're booked with ndisync. <br /> An invitation has been
                      emailed to you.
                    </p>
                    <h4 className="font-size-login-29px font-Poppins-SemiBold theme-color-green text-center">
                      {/* {moment(appointmentDate?.$d).format("MMMM D, YYYY")} */}
                      {moment(appointmentDate?.toDate()).format("MMMM D, YYYY")}

                      <br /> {AppointmentSchedule[slot as keyof typeof AppointmentSchedule] || ""}
                    </h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ScheduleMeeting;