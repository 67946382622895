import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip, faStar, faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { timeSplit, dateSplit, getRole, splitMessage } from '../../config/Helpers/helpers';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import AnySpecificReason from '../AllDashboardPagesComponents/AnySpecificReason';
import ViewAttachment from "../AllDashboardPagesComponents/ViewAttachment";
import DeleteReview from '../JobReviewComponents/DeleteReview';
import EditReview from '../JobReviewComponents/EditReview';
import httpRequest from '../../config/Helpers/httpRequest';
// MUI

interface CustomTabPanelProps {
    children?: React.ReactNode;
    value: number;
    index: number;
}

interface JobRequestAndHistoryProps {
    opponentData: any; // Replace 'any' with a more specific type if possible
    jobId: string;
    logUpdated: boolean;
    setLogUpdated: React.Dispatch<React.SetStateAction<boolean>>;
}

interface HistoryLogItem {
    _id: string;
    createdAt: string;
    firstName: string;
    lastName: string;
    message: string;
    userMessage?: string;
    systemMessage?: string;
    status?: string;
    logCategory?: string;
    userId?: string;
    title?: string;
    documents: documents;
    [key: string]: any;
}
interface documents {
    _id: string;

    [key: string]: any;
}
interface ApiResponse {
    documents: HistoryLogItem[];
    message: string;
}
function CustomTabPanel({ children, value, index, ...other }: CustomTabPanelProps) {
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

const JobRequestAndHistory: React.FC<JobRequestAndHistoryProps> = ({ opponentData, jobId, logUpdated, setLogUpdated }) => {
    // setLogUpdated for recall api set update this state
    // MUI TABS
    const location = useLocation();
    const [currenTab, setCurrentTab] = useState<number>(0);
    const [activeTab, setActiveTab] = useState<string>(location?.pathname === "/dispute-jobs" ? "jobLog" : "jobRequestLog");
    const [historyLogs, setHistoryLogs] = useState<HistoryLogItem[]>([]);
    const [fetchLoader, setFetchLoader] = useState<boolean>(false);
    const [approveLoader, setApproveLoader] = useState<string | false>(false);
    const [reasonModal, setReasonModal] = useState<boolean>(false);
    const [reasonText, setReasonText] = useState<string>("");
    const [declineRequestId, setDeclineRequestId] = useState<string | false>(false);
    const [declineLoader, setDeclineLoader] = useState<string | boolean>(false);
    const [viewAttach, setViewAttach] = useState<string | false>(false);
    const viewAttachClose = () => {
        setViewAttach(false)
    }
    const viewAttachOpen = (id: string) => {
        setViewAttach(id)
    }
    const handleChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setCurrentTab(newValue);
        if (newValue == 0) {
            setActiveTab("jobRequestLog");
        }
        else if (newValue == 1) {
            setActiveTab("jobLog");
        }
        else { }
    };
    useEffect(() => {
        if (location?.pathname === "/dispute-jobs") {
            // setActiveTab("jobLog");
            setCurrentTab(1)
        }
        else {
            setActiveTab("jobRequestLog");
            setCurrentTab(0)
        }
    }, [])

    const decoded = getRole();
    const userId = decoded?.userId;
    const userRole = decoded?.role;
    // let userId = getRole()?.userId;
    // let userRole = getRole()?.UserRole;

    // Functionality: API call for get history & requests
    const fetch = async () => {
        setFetchLoader(true);
        // const { res, err } = await httpRequest({ path: `/api/${activeTab}/getAll/${jobId}` });
        // const path = activeTab === "jobLog" ? `/api/job/logs/${jobId}` : `/api/job/request/log/${jobId}`
        const path = activeTab === "jobLog" ? `/api/job/logs/${jobId}` : `/api/job/request/log/${jobId}`
        console.log(activeTab, "activeTabactiveTab")
        const { res, err } = await httpRequest<ApiResponse>({ path: path });
        if (res) {
            setHistoryLogs(res?.documents);
        } else {
            toast(err?.message, { type: "error" });
        }
        setFetchLoader(false);
    };

    // for approve request
    // Functionality: API call Job request accept like a (completed, reshcedular)
    const handleApproveRequest = async (rId: string) => {
        setApproveLoader(rId);
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: `/api/job/request/accept`, params: { jobId: jobId, requestId: rId } });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            setLogUpdated(prev => !prev);
        } else {
            toast(err?.message, { type: "error" });
        }
        setApproveLoader(false);
    };

    const handleDecline = (rId: string) => {
        setReasonModal(true);
        setDeclineRequestId(rId);
    };

    // for decline request
    // Functionality: API call for job decline (complete request, reschdular  request)
    const handleDeclineRequest = async () => {
        if (!reasonText) {
            toast("Please specify the reason", { type: "error" });
            return;
        }
        setDeclineLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: `/api/job/request/decline`, params: { jobId: jobId, reason: reasonText, requestId: declineRequestId } });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            setLogUpdated(prev => !prev);
            setDeclineRequestId(false);
            setReasonText("");
            setReasonModal(false);
        } else {
            toast(err?.message, { type: "error" });
        }
        setDeclineLoader(false);
    };

    const viewAttachment = (files: string[]) => {
        if (!files || files.length === 0) {
            return ""; // Return empty string if no files
        }

        // Construct the URL using the first file in the array
        const filePath = `${files[0]}`;
        // const filePath = `${process.env.REACT_APP_BASE_URL_IMAGE}${files[0]}`;
        return filePath;
    };

    useEffect(() => {
        fetch();
    }, [activeTab, logUpdated, jobId]);
    return (
        <>
            <div className="mui-tab-selected">
                <Box sx={{ width: '100%' }}>



                    {location.pathname === "/dispute-jobs" ?

                        <>
                            <Tabs
                                sx={{
                                    '& .Mui-selected': {
                                        color: '#00443f',
                                    }
                                }}
                                TabIndicatorProps={{
                                    style: { backgroundColor: '#00443f' }
                                }}
                                value={currenTab} onChange={handleChangeTab} aria-label="basic tabs example">
                                <div className={`${location.pathname === "/dispute-jobs" ? "hidden" : ""}  `}>
                                    < Tab sx={{ color: '#00443f', }} label="Requests" />
                                </div>
                                <Tab sx={{ color: '#00443f', }} label="History" />








                            </Tabs>
                        </>
                        :

                        <>
                            <Tabs
                                sx={{
                                    '& .Mui-selected': {
                                        color: '#00443f',
                                    }
                                }}
                                TabIndicatorProps={{
                                    style: { backgroundColor: '#00443f' }
                                }}
                                value={currenTab} onChange={handleChangeTab} aria-label="basic tabs example">

                                < Tab sx={{ color: '#00443f', }} label="Requests" />

                                <Tab sx={{ color: '#00443f', }} label="History" />





                            </Tabs>
                        </>
                    }
                    <CustomTabPanel value={currenTab} index={0}>
                        <div className="">
                            {fetchLoader ?
                                <div className='text-center'>
                                    <CircularProgress style={{ color: "#004540" }} />
                                </div>
                                :
                                <>
                                    {historyLogs && historyLogs.length > 0
                                        ?
                                        historyLogs.map((item, index) => (
                                            <div key={index} className="active-job-request-handler">
                                                <div className=" relative"><FontAwesomeIcon className="calender-icon-job-request" icon={faCalendarDays} />
                                                    {historyLogs.length > index + 1 &&
                                                        <div className="green-line"></div>
                                                    }
                                                </div>
                                                <div>
                                                    <div className="flex justify-center">
                                                        <h2 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">{dateSplit(item?.createdAt)}</h2>
                                                    </div>
                                                    <div className="flex flex-row  items-center justify-between request-specific mt-3 mb-3">
                                                        <div>
                                                            <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f] capitalize">{`${item?.firstName || ""} ${item?.lastName}`}</h4>
                                                            <p className="font-size-15px font-Poppins-Regular">{item?.systemMessage || ""}</p>
                                                            {item?.userMessage &&
                                                                <div className='flex flex-col gap-2  flex-wrap uploaded-app-files mt-2'>
                                                                    <p className="font-size-15px font-Poppins-Regular resch-reason">{item?.userMessage || ""}</p>
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="flex items-center gap-4">
                                                            {item?.status === "requested" && userRole !== "superAdmin" ?
                                                                <div>
                                                                    {item?.userId === userId
                                                                        ?
                                                                        <div className="admin-box-approval flex gap-3">
                                                                            <span className="font-Poppins-Medium  font-size-15px"><i> Waiting for approval .....</i></span>
                                                                        </div>
                                                                        :
                                                                        <div className="admin-box-approval flex gap-3">
                                                                            <button className="font-Poppins-SemiBold theme-bg-green font-size-12px flex flex-row items-center gap-2"
                                                                                disabled={approveLoader == item?._id}
                                                                                onClick={() => handleApproveRequest(item?._id)}>Approve {approveLoader == item?._id &&
                                                                                    <CircularProgress style={{ width: 10, height: 10, color: "#fff" }} />
                                                                                }</button>
                                                                            <button className="font-Poppins-SemiBold bg-[#DF0000] font-size-12px flex flex-row items-center gap-2"
                                                                                disabled={declineLoader == item?._id}
                                                                                onClick={() => handleDecline(item?._id)}
                                                                            >Decline {declineLoader == item?._id &&
                                                                                <CircularProgress style={{ width: 10, height: 10, color: "#fff" }} />
                                                                                }</button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                : null
                                                            }
                                                            <div>
                                                                <p className="font-size-14px font-Poppins-Regular">{timeSplit(item?.createdAt)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <p>No request</p>
                                    }
                                </>
                            }
                        </div>
                    </CustomTabPanel>
                    <CustomTabPanel value={currenTab} index={1}>

                        <div className="">
                            {fetchLoader ?
                                <div className='text-center'>
                                    <CircularProgress style={{ color: "#004540" }} />
                                </div>
                                :
                                <>

                                    {historyLogs && historyLogs.length > 0
                                        ?

                                        historyLogs.map((item, index) => (

                                            <div key={index} className="active-job-request-handler-2">
                                                {item?.logCategory === "info" ?
                                                    <div className="w-[90%]">
                                                        <div className="flex justify-center">
                                                            <h2 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">{dateSplit(item?.createdAt)}</h2>
                                                        </div>
                                                        <div className=" request-specific  mt-3 mb-3">
                                                            <div>
                                                                <div className="flex flex-row justify-between items-center mb-1">
                                                                    <div className="flex flex-col gap-1">
                                                                        <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f] capitalize">{`${item?.firstName || ""} ${item?.lastName}`}</h4>

                                                                        <p className="font-size-15px font-Poppins-Regular">{item?.message}</p>

                                                                    </div>
                                                                    <div className="flex flex-row items-center gap-4 justify-end">
                                                                        <div className='flex flex-col gap-2  flex-wrap '>

                                                                        </div>
                                                                        <div>
                                                                            <p className="font-size-14px font-Poppins-Regular">{timeSplit(item?.createdAt)}</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    : item?.logCategory === "reason" ?
                                                        <div className="w-[90%]">
                                                            <div className="flex justify-center">
                                                                <h2 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">{dateSplit(item?.createdAt)}</h2>
                                                            </div>
                                                            <div className=" request-specific  mt-3 mb-3">
                                                                <div>
                                                                    <div className="flex flex-row justify-between items-center mb-1">
                                                                        <div className="flex flex-col gap-1">
                                                                            <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f] capitalize">{`${item?.firstName || ""} ${item?.lastName}`}</h4>
                                                                            <p className="font-size-15px font-Poppins-Regular capitalize-first-letter">{item?.title || ""} </p>

                                                                        </div>
                                                                        <div className="flex flex-row items-center gap-4 justify-end">
                                                                            <div className='flex flex-col gap-2  flex-wrap '>

                                                                            </div>
                                                                            <div>
                                                                                <p className="font-size-14px font-Poppins-Regular">{timeSplit(item?.createdAt)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <p className="font-size-15px font-Poppins-Regular resch-reason">{item?.message || ""} {item?.userMessage}</p>
                                                            </div>
                                                        </div>

                                                        : item?.logCategory === "dispute" ?
                                                            <div className="w-[90%]">
                                                                <div className="flex justify-center">
                                                                    <h2 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">{dateSplit(item?.createdAt)}</h2>
                                                                </div>
                                                                <div className=" request-specific  mt-3 mb-3">
                                                                    <div>
                                                                        <div className="flex flex-row justify-between items-center mb-1">
                                                                            <div className="flex flex-col gap-1">
                                                                                <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f] capitalize">{`${item?.firstName || "-"} ${item?.lastName || "-"}`}</h4>
                                                                                <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">Dispute</h4>

                                                                                <h4 className="font-size-15px font-Poppins-SemiBold text-[#000000] capitalize-first-letter">{item?.title}</h4>
                                                                            </div>
                                                                            <div className="flex flex-row items-center gap-4 justify-end">
                                                                                <div className='flex flex-col gap-2  flex-wrap '>
                                                                                    <button
                                                                                        onClick={() => viewAttachOpen(item?._id)}
                                                                                        className="flex items-center gap-2">
                                                                                        <FontAwesomeIcon icon={faPaperclip} />
                                                                                        <span className="font-size-15px font-Poppins-Medium"><i> View attachments </i> </span>
                                                                                    </button>
                                                                                    {viewAttach === item._id && (
                                                                                        <ViewAttachment
                                                                                            filePath={viewAttachment(item.files)}
                                                                                            viewAttachClose={viewAttachClose}
                                                                                        />
                                                                                    )}
                                                                                </div>
                                                                                <div>
                                                                                    <p className="font-size-14px font-Poppins-Regular">{timeSplit(item?.createdAt)}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="font-size-15px font-Poppins-Regular">{(item?.message)}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : item?.logCategory === "rating" ?
                                                                <div className="w-[90%]">
                                                                    <div className="flex justify-center">
                                                                        <h2 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">{dateSplit(item?.createdAt)}</h2>
                                                                    </div>
                                                                    <div className=" request-specific mt-3 mb-3">
                                                                        <div>
                                                                            <div className="flex flex-row justify-between">
                                                                                <div className="flex flex-row gap-4">
                                                                                    <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f] capitalize">{`${item?.firstName || ""} ${item?.lastName}`}</h4>
                                                                                    <div className="flex gap-3  mb-5">
                                                                                        <div className="text-yellow-500 history-review-icon">
                                                                                            {[1, 2, 3, 4, 5].map(v => {
                                                                                                console.log('Rating:', item?.rating, 'Current v:', v);  // Debugging log
                                                                                                return (
                                                                                                    <FontAwesomeIcon
                                                                                                        size="lg"
                                                                                                        key={v}
                                                                                                        icon={faStar}
                                                                                                        className={Number(item?.rating) >= v ? "active fs__12" : "nonActiveStar fs__12"}
                                                                                                    />
                                                                                                );
                                                                                            })}
                                                                                        </div>
                                                                                        <span className="font-size-14px">{item?.rating || 0} Star Ratings</span>


                                                                                    </div>

                                                                                </div>

                                                                                <div className="flex  gap-4">
                                                                                    <div className="admin-box-approval flex gap-3 review-modal-btn">
                                                                                        {item?.userId === userId
                                                                                            ?
                                                                                            <EditReview
                                                                                                userData={opponentData}
                                                                                                buttonClass="font-Poppins-SemiBold theme-bg-green font-size-12px"
                                                                                                rating={item?.rating || 0} // Convert to number
                                                                                                message={item?.message || ""}
                                                                                                jobId={jobId}
                                                                                                onSuccessFunctions={() => setLogUpdated(prev => !prev)}
                                                                                            />
                                                                                            :
                                                                                            null
                                                                                        }
                                                                                        {item?.userId === userId || userRole=== "admin" || userRole === "superAdmin"
                                                                                            ?
                                                                                            <DeleteReview
                                                                                                onSuccessFunctions={() => setLogUpdated(prev => !prev)}
                                                                                                uId={item?.userId}
                                                                                                jobId={jobId}
                                                                                                buttonClass="font-Poppins-SemiBold bg-[#DF0000] font-size-12px"
                                                                                            />
                                                                                            :
                                                                                            <></>
                                                                                        }
                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="font-size-14px font-Poppins-Regular">{timeSplit(item?.createdAt)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <p className='font-size-15px font-Poppins-Regular resch-reason'> {item?.message || ""}</p>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                :
                                                                <div className="w-[90%]">
                                                                    <div className="flex justify-center">
                                                                        <h2 className="font-size-15px font-Poppins-SemiBold text-[#00443f]">{dateSplit(item?.createdAt)}</h2>
                                                                    </div>
                                                                    <div className=" request-specific  mt-3 mb-3">
                                                                        <div>
                                                                            <div className="flex flex-row justify-between items-center mb-1">
                                                                                <div className="flex flex-col gap-1">
                                                                                    <h4 className="font-size-15px font-Poppins-SemiBold text-[#00443f] capitalize">{`${item?.firstName || ""} ${item?.lastName}`}</h4>
                                                                                    <p className="font-size-15px font-Poppins-Regular capitalize-first-letter">{item?.title || ""}</p>

                                                                                </div>
                                                                                <div className="flex flex-row items-center gap-4 justify-end">
                                                                                    <div className='flex flex-col gap-2  flex-wrap '>

                                                                                    </div>
                                                                                    <div>
                                                                                        <p className="font-size-14px font-Poppins-Regular">{timeSplit(item?.createdAt)}</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <p className="font-size-15px font-Poppins-Regular resch-reason">{item?.message || ""}</p>
                                                                    </div>
                                                                </div>
                                                }
                                            </div>
                                        ))
                                        :
                                        <p>No History</p>
                                    }
                                </>
                            }
                        </div>
                    </CustomTabPanel>
                </Box>
            </div>
            {reasonModal &&
                <AnySpecificReason
                    text="Please specify the reason"
                    leftBtnText="Go back"
                    rightBtnText="Decline"
                    onClose={() => setReasonModal(false)}
                    onDelete={handleDeclineRequest}
                    state={reasonText}
                    setState={setReasonText}
                    loader={declineLoader}
                />
            }
        </>
    );
};

export default JobRequestAndHistory;