/**
 * AdminBillingDetail Component
 * Manages user invoices with display and download functionality.
 * File: AdminBillingDetail.js
 * Author: Developer
 * Date: 28-03-24
 */

import React, { useState, useEffect } from "react";
// import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { Link, useParams } from "react-router-dom";

import axios from "axios";
import { getCookie } from "../../config/Helpers/helpers";
import Skeleton from "@mui/material/Skeleton";
import moment from "moment";
interface ApiResponse {
  invoices: {
    data: Subscription[];
  };
  [key: string]: any;
}

interface Subscription {
  number?: string;
  created?: number;
  amount_paid?: number;
  invoice_pdf?: string;
  lines?: {
    data: Array<{
      metadata?: {
        title?: string;
      };
    }>;
  };
}
const AdminBillingDetail: React.FC = () => {

  const { email } = useParams<{ email: string }>(); // Get email parameter from URL using useParams hook

  const [isLoader, setIsLoader] = useState<boolean>(false); // Loading state
  const [isError, setIsError] = useState<string | boolean>(false); // Error state
  const [allSubscription, setAllSubscription] = useState<Subscription[]>([]); // State for storing all subscriptions
console.log(allSubscription , "asdasdasdasd"
)
  const token = getCookie(); // Assuming getCookie is defined elsewhere

  // const config = {
  //   headers: {
  //     Authorization: `bearer ${token}`, // Authorization header with bearer token
  //   },
  // };

  // Function to download invoice given PDF URL and name
  const downloadInvoice = async (pdfUrl: string, pdfName: string) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", `${pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Functionality: API call Effect hook to fetch all subscriptions by user email
  useEffect(() => {
    (async () => {
      setIsLoader(true);
      try {
        const response = await axios.get<ApiResponse>(
          `${process.env.REACT_APP_BASE_URL}/api/admin/subscription/invoices/${email}`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }

        );
        setAllSubscription(response.data?.invoices?.data || []); // Set fetched subscriptions to state
      } catch (err: any) {
        setIsError(err?.response?.data?.message || "ERROR"); // Set error message if API call fails
      }
      setIsLoader(false);
    })();
  }, [email]); // 
  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          {/* <AdminHeader /> */}
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <div className="flex items-center justify-between">
                <div>
                  <h2 className="font-size-36px font-Poppins-Medium">
                    Paid users
                  </h2>
                  <div className="flex justify-between"></div>
                  {isError && <p style={{ color: "red" }}>{isError}</p>}
                </div>
                <div>
                  <Link to="/admin-paid-users">
                    <img
                      className="Back-Icon"
                      src={
                        "https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
                      }
                    />
                  </Link>
                </div>
              </div>
              <div className="main-admin-Assessment theme-bg-lightblue">
                <div className="main-Admin-status-action">
                  <ul
                    style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
                    className="Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                  >
                    {["Product", "Invoice #", "Date", "Amount", "Download"].map(
                      (item) => (
                        <li key={item} className="text-white">
                          {item}
                        </li>
                      )
                    )}
                  </ul>
                </div>
                {isLoader ? (
                  <>
                    <Skeleton
                      variant="rounded"
                      className="w-full mt-5"
                      height={60}
                    />
                    <Skeleton
                      variant="rounded"
                      className="w-full mt-5"
                      height={60}
                    />
                  </>
                ) : allSubscription && allSubscription.length > 0 ? (
                  allSubscription.map((item, index) => (
                    <div key={index} className="main-Admin-status-content mt-5">
                      <ul
                        style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
                        className="Admin-status-content-Role text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                      >
                        <li>
                          ndisync <br />{" "}
                          {item?.lines?.data[0]?.metadata?.title === "monthly"
                            ? "Monthly" : "Anually" }

                            {/* // : item?.lines?.data[0]?.metadata?.title === "yearly"
                            //   ? "Annually"
                            //   : ""}{" "} */}
                          <br /> Plan
                        </li>
                        <li>{item?.number || "-"}</li>
                        <li>
                          {item?.created ? moment.unix(item.created).format("MMM DD, YYYY") : "-"}

                        </li>
                        <li>${(item?.amount_paid ?? 0) / 100 || "0"}</li>
                        <li className="flex justify-center">
                          <button
                            onClick={() =>
                              item?.invoice_pdf && item?.number ?
                                downloadInvoice(item.invoice_pdf, item.number) :
                                undefined
                            }
                          >
                            <img
                              className="admin-download-icon cursor-pointer"
                              src={
                                "https://edgie.backslashwebs.com/imgs/admin-file-download.png"
                              }
                            />
                          </button>
                        </li>
                      </ul>
                    </div>
                  ))
                ) : (
                  <div className="main-Admin-status-content mt-5">
                    <p className="text-center">No subscription</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminBillingDetail;
