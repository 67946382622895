import React, { useEffect, useState } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import AddressSocialDetail from "../../../components/AllDashboardPagesComponents/Profile/AddressSocialDetail";
import ProfileUserDetailDashboard from "../../../components/AllDashboardPagesComponents/Profile/ProfileUserDetailDashboard";
import Aboutme from "../../../components/AllDashboardPagesComponents/Profile/Aboutme";
import Availability from "../../../components/AllDashboardPagesComponents/Profile/Availability";
import WorkHistory from "../../../components/AllDashboardPagesComponents/Profile/WorkHistory";
import EducationTraining from "../../../components/AllDashboardPagesComponents/Profile/EducationTraining";
import ServicesProfile from "../../../components/AllDashboardPagesComponents/Profile/ServicesProfile";
import HourlyRate from "../../../components/AllDashboardPagesComponents/Profile/HourlyRate";
import WorkLocation from "../../../components/AllDashboardPagesComponents/Profile/WorkLocation";
import PreferencesInterest from "../../../components/AllDashboardPagesComponents/Profile/PreferencesInterest";
import ReviewProfile from "../../../components/AllDashboardPagesComponents/Profile/ReviewProfile";

import { getRole } from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { setPublicProfile } from "../../../config/Store/Reducers/userProfile";
import PostActivity from "../../../components/AllDashboardPagesComponents/Profile/PostActivity";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";
interface IUserProfileState {
  userProfile: {
    publicData: {
      role: string;
      [key: string]: any; // Other properties can be added as needed
    };
  };
}

interface IHttpResponse {
  bool: boolean;
  setBool: React.Dispatch<React.SetStateAction<boolean>>;
  unpaidModalShow: boolean;
  res: SetPublicProfilePayload;
  err: any;
}
interface SetPublicProfilePayload {
  profile: any;
  services: any;
  connectionStatus: any;
  // add other properties as needed
}
const PublicProfile: React.FC = () => {
  const { publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const dispatch = useDispatch();
  const decoded = getRole();
  const userId = decoded?.userId;
  const UserRole = decoded?.role;
  // const { userId, UserRole } = getRole();

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [bool, setBool] = useState<boolean>(false); // state for api call
  const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)
  const [unpaidModalShow, setUnpaidModalShow] = useState(false);

  useEffect(() => {
    setScreenWidth(window.innerWidth)
  }, [window.innerWidth])

  // Functionality: API call for get public profile data for public profile
  // useEffect(() => {
  //   (async () => {
  //     setIsLoader(true);
  //     const { res, err } = await httpRequest<IHttpResponse>({
  //       path: `/api/search/view/${userId}`,
  //     });
  //     if (res) {
  //       // dispatch(setPublicProfile(res?.documents));
  //     } else {
  //       toast(err?.message, { type: "error" }); // api error
  //     }
  //     setIsLoader(false);
  //   })();
  // }, [bool]);

  return (
    <>

      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw md:flex lg:flex">
        <div className="w-full md:w-3/4 lg:w-3/4">
          <ProfileUserDetailDashboard
            bool={bool}
            setBool={setBool}
            unpaidModalShow={isLoader} // Replace with the correct value for `unpaidModalShow`
          />
          {screenWidth < 450 && (

            <AddressSocialDetail
              unpaidModalShow={false}
              userId={userId}

            />
          )}
          <Aboutme />

          {/* <Aboutme userId={userId} /> */}
          <PostActivity uId={userId} />
          {UserRole === "provider" || UserRole === "provider" ? (
            <>
              <Availability />
              <WorkHistory />
              <EducationTraining /> {/*only for provider*/}
              <ServicesProfile /> {/*only for provider*/}
              <HourlyRate />
              <WorkLocation />  {/*only for provider*/}
            </>
          ) : null}
          <PreferencesInterest />
          <ReviewProfile unpaidModalShow={unpaidModalShow} />
        </div>
        {screenWidth > 450 && (

          <AddressSocialDetail
            unpaidModalShow={false}
            userId={userId}

          />
        )}

      </div>
    </>
  );
}

export default PublicProfile;
