import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

// Import slices
import authSlice from "./Reducers/authSlice";
import userProfileSlice from "./Reducers/userProfile";
import userSlice from "./Reducers/userSlice";
import HeaderSearchSlice from "./Reducers/HeaderSearchSlice";
import allServicesSlice from "./Reducers/allServicesSlice";
import notificationsSlice from "./Reducers/notificationsSlice";
import messagesSlice from "./Reducers/messagesSlice";
import connectionsSlice from "./Reducers/connectionsSlice";
import newsFeedSlice from "./Reducers/newsFeedSlice";
import DiscussionSlice from "./Reducers/DiscussionSlice";
import postActivitySlice from "./Reducers/postActivitySlice";

const persistConfig = {
  key: 'token',
  storage,
};

const persistAuthSlice = persistReducer(persistConfig, authSlice);

const store = configureStore({
  reducer: {
    auth: persistAuthSlice,
    // user: userSlice,
    userProfile: userProfileSlice,
    header: HeaderSearchSlice,
    allServices: allServicesSlice,
    notification: notificationsSlice,
    message: messagesSlice,
    connection: connectionsSlice,
    feed: newsFeedSlice,
    discussion: DiscussionSlice,
    postActivity: postActivitySlice
  }
});

const persistor = persistStore(store);

export { store, persistor };
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
