import React, { useState, useRef, useEffect, FormEvent } from 'react';

import { useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";
import { toast } from 'react-toastify';
import emojismile from "../../assets/img/emoji-smile.svg";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";
import closeIcon from "../../assets/img/closeIcon.svg";
import DefaultProfileImage from "../../assets/img/default_profile_image.png"
import httpRequest from '../../config/Helpers/httpRequest';
import { RootState } from '../../config/Store/store';
type EmojiObject = {
    native: string;
    // add other properties you might use from the emoji object
};
interface comment {
    caption: string;
    firstName: string;
    lastName: string;
    refId: string;
    [key: string]: any;
}
interface ApiResponse {
    comment: comment;

}
interface AddCommentProps {
    refId?: string;
    successFunction?: (newComment: any) => void;
    commentId?: string | boolean;
    isCancelButton?: boolean;
    cancelComment?: () => void;
    feedType?: string;
    isFocus?: boolean;
    setIsFocus?: (focus: boolean) => void;
}



const AddComment: React.FC<AddCommentProps> = ({
    refId = "",
    successFunction = () => { },
    commentId = false,
    isCancelButton = false,
    cancelComment = () => { },
    feedType = "",
    isFocus = false,
    setIsFocus = () => { }
}) => {
    const { personalData } = useSelector((store: RootState) => store.userProfile);

    const emojiPickerRef = useRef<HTMLDivElement | null>(null); // for emoji reference
    const inputRef = useRef<HTMLInputElement | null>(null); // for input ref

    const [commentLoader, setCommentLoader] = useState<boolean>(false); // for add comment loader
    const [commentCaption, setCommentCaption] = useState<string>(""); // for comment caption
    const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);// for emoji picker show & hide
    // Functionality: API call for add comment on discussion
    const addCommentOnDiscussion = async (e: FormEvent) => {
        e.preventDefault();
        setCommentLoader(true); // show loader
        let body;
        if (commentId) {
            body = { refId: refId, feedType, commentId, caption: commentCaption }
        } else {
            body = { refId: refId, feedType, caption: commentCaption }
        }
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: `/api/social/comment`, params: body });
        if (res) {
            let newComment = res?.comment;
            successFunction(newComment); // add on top on comment list
            setCommentCaption("");
        } else {
            toast(err?.message, { type: "error" }); // api error
        }
        setCommentLoader(false); // hide loader
    };


    // UI CODE

    const handleClickOutside = (event: MouseEvent) => {
        if (
            emojiPickerRef.current &&
            !emojiPickerRef.current.contains(event.target as Node)
        ) {
            setShowEmojiPicker(false);
        }
    };

    const handleFocusFalse = (event: MouseEvent) => {
        if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
            setIsFocus(false);
        }
    };

    useEffect(() => {
        if (isFocus) {
            // Check if inputRef is defined and focus it
            if (inputRef.current) {
                inputRef.current.focus();
            }
        }
    }, [isFocus])

    useEffect(() => {
        document.addEventListener('mousedown', handleFocusFalse);
        return () => {
            document.removeEventListener('mousedown', handleFocusFalse);
        };
    }, []);

    // Add event listener when the component mounts
    useEffect(() => {
        if (showEmojiPicker) {
            document.addEventListener("mousedown", handleClickOutside);
        } else {
            document.removeEventListener("mousedown", handleClickOutside);
        }

        // Clean up event listener when the component unmounts
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showEmojiPicker]); // Add showEmojiPicker to the dependency array
    return (
        <>
            <form onSubmit={addCommentOnDiscussion}>
                <div className="discusion-comments flex gap-3 justify-between items-center mt-5 mb-3 relative">
                    <div className="crete-feed-user-img">
                        <img
                            src={`${personalData?.profileImageUrl}`}
                            // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${personalData?.profileImageUrl}`}
                            alt=""
                            className="rounded-full"
                            onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                        /> {/* loged in user image */}
                    </div>
                    <input
                        ref={inputRef}
                        type="text"
                        placeholder={commentId ? "Reply..." : "Add a comment..."}
                        className="w-full font-size-15px theme-color-green font-Poppins-Regula"
                        value={commentCaption}
                        onChange={(e) => setCommentCaption(e.target.value)}
                        required
                    />
                    <img
                        src={emojismile}
                        alt="emojismile"
                        style={{ width: "1.5vw" }}
                        onClick={() => setShowEmojiPicker(true)}
                    />
                    {isCancelButton &&
                        <button onClick={cancelComment} type="button" className=" font-Poppins-Medium  font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn feeds-btn" style={{ border: "1px solid" }}>Cancel</button>
                    }
                    <button
                        type="submit"
                        disabled={commentLoader}
                        style={{ color: "white" }}
                        className="theme-bg-green  font-Poppins-Medium  font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn feeds-btn"
                    > {commentId ? "Reply" : "Comment"} {commentLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}</button>
                    {showEmojiPicker && (
                        <div className="absolute top-0 z-20	 right-0 emoji-section">
                            <div ref={emojiPickerRef}>
                                <Picker
                                    data={data}
                                    previewPosition="none"
                                    onEmojiSelect={(e: EmojiObject) => {  // Explicitly typing 'e' as BaseEmoji
                                        setCommentCaption((prev) => prev + e.native);
                                        setShowEmojiPicker(false);
                                    }}
                                    style={{ zIndex: 99999 }}
                                />
                            </div>
                            <img
                                role="button"
                                src={closeIcon}
                                alt=""
                                onClick={() => setShowEmojiPicker(false)}
                                className="absolute top-0 right-0"
                                width={"20px"}
                                style={{ zIndex: 99999 }}
                            />
                        </div>
                    )}


                </div>

            </form>
            {/* Render emoji picker */}
        </>
    );
};

export default AddComment;