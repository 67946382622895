import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

import { getRole, postTimeDifference } from "../../config/Helpers/helpers";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import AddComment from "./AddComment";
import { useDispatch } from "react-redux";
import { addNewComment } from "../../config/Store/Reducers/DiscussionSlice";
import qs from "qs";
import { updateCommentCounter } from "../../config/Store/Reducers/newsFeedSlice";
import { updateActivityCommentCounter } from "../../config/Store/Reducers/postActivitySlice";
import CommentOnReport from "../ReportComponents/CommentOnReport";
import httpRequest from "../../config/Helpers/httpRequest";
import PostDelete from "../ReportComponents/PostDelete";

interface DisplayCommentsProps {
  refId?: string;
  feedType?: string;
  allComments?: CommentItem[];
  setAllComments?: React.Dispatch<React.SetStateAction<CommentItem[]>>;
  activityPage?: boolean;
  updateActivityCounter?: (event: any) => void;
  totalComments?: number;
  detailPage?: boolean;
  isShow?: boolean;
}

// Define the type for a single comment item
interface CommentItem {
  _id: string;
  profileImageUrl: string;
  userId: string;
  firstName: string;
  lastName: string;
  role: string;
  caption: string;
  createdAt: string;
  reactionStatus: boolean;
  reactions: number;
  reply: number;
  [key: string]: any;
}
interface Paginated {
  totalItems: number;
  totalPages: number;
  [key: string]: any;
}
interface ApiResponse {
  documents: CommentItem[];
  paginated: Paginated;
}
const DisplayComments: React.FC<DisplayCommentsProps> = ({
  refId = "",
  feedType = "",
  allComments = [],
  setAllComments = () => { },
  activityPage = false,
  updateActivityCounter = () => { },
  totalComments = 1,
  detailPage = false,
  isShow = false,
}) => {
  const decoded = getRole();
  const userId = decoded?.userId;
  const UserRole = decoded?.role;
  const dispatch = useDispatch();
  const [showComments, setShowComments] = useState<boolean>(detailPage); // for comment show
  const [loader, setLoader] = useState<boolean>(false); // for get comment loader top level comment
  const [totalPages, setTotalPages] = useState<number>(1); // for total pages of comment
  const [queryParams, setQueryParams] = useState<{ page: number; limit: number }>({
    page: detailPage ? 1 : 0,
    limit: 10,
  }); // query params for get comment APIs

  const [commentId, setCommentId] = useState<string | false>(false); // for comment id
  const [innerComment, setInnerComment] = useState<CommentItem[]>([]); // for inner comment
  const [innerCommentLoader, setInnerCommentLoader] = useState<boolean>(false); // for fetch inner comment loader
  const [deletePost, setDeletePost] = useState<string | false>(false);

  const handleDeletePost = (id: string) => {
    setDeletePost(id);
  };
  /**
   * Handles the addition of a new comment to the feed based on the feed type.
   * If the feed type is 'discussion', dispatches an action to add a new comment
   * using the discussion slice. Otherwise, dispatches the action using the
   * news feed slice.
   *
   * @param {Object} event - The event object containing the new comment data.
   */
  const handleAddNewComment = (event: any) => {
    // Update inner comment state with the new comment
    setInnerComment((prev) => [...prev, event]);

    // Update data array to increment reply count for the relevant comment
    let newList = allComments.map((newObj) => {
      if (newObj?._id === event?.commentId) {
        return {
          ...newObj,
          reply: newObj?.reply + 1,
        };
      }
      return newObj;
    });
    setAllComments(newList);

    // Dispatch action to add new comment depends on feedtype & activity page
    if (activityPage && feedType === "discussion") {
      updateActivityCounter(event);
    } else if (activityPage && feedType === "post") {
      dispatch(updateActivityCommentCounter(event?.refId));
    } else if (!activityPage && feedType === "discussion") {
      dispatch(addNewComment());
    } else if (!activityPage && feedType === "post") {
      dispatch(updateCommentCounter(event?.refId));
    }
  };
  // const [category,setCategory]=useState<string>("comment")
  // Functionality: API call for get reply of comment discussions
  const handleReplySection = async (id: string, count: number) => {

    setInnerComment([]);
    setCommentId(id);
    if (count) {
      setInnerCommentLoader(true); // show loader
      const { res, err } = await httpRequest<ApiResponse>({
        path: `/api/social/comments/${refId}?commentId=${id}&page=1&limit=${count}`,
      });
      if (res) {
        // let data = res?.documents || [];
        setInnerComment(res?.documents); // set total pages for pagination

      } else {
        toast(err?.message, { type: "error" }); // api error
      }
      setInnerCommentLoader(false); // hide loader
    }
  };

  // Functionality: API call to toggle the reaction on a discussion comment
  const handleToogleReaction = async (id: string, childReaction: boolean = false) => {
    let cList = childReaction ? innerComment : allComments;
    let newList = cList.map((newObj) => {
      if (newObj?._id == id) {
        return {
          ...newObj,
          reactionStatus: !newObj.reactionStatus,
          reactions: newObj.reactionStatus
            ? newObj?.reactions - 1
            : newObj?.reactions + 1,
        };
      }
      return newObj;
    });
    childReaction ? setInnerComment(newList) : setAllComments(newList);
    const { res, err } = await httpRequest<ApiResponse>({
      method: "post",
      path: `/api/social/like`,
      params: { commentId: id, refId: refId, feedType, reactionType: "like" },
    });
    if (res) {
    } else {
      setAllComments(allComments);
      toast(err?.message, { type: "error" }); // api error
    }
  };

  // Functionality: API call for get comment discussions
  const fetchComment = async () => {
    setLoader(true); // show loader
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/social/comments/${refId}?${qs.stringify(queryParams)}`,
    });
    if (res) {
      let data = res?.documents || [];
      // setAllComments(res?.documents || [])
      setAllComments((prev) => {
        const newUniqueComments = data.filter(
          (newItem) =>
            !prev.some((existingItem) => existingItem._id === newItem._id)
        );

        return [...prev, ...newUniqueComments];
      }); // set total pages for pagination
      setTotalPages(res?.paginated?.totalPages); //set total comment in local state
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setLoader(false); // hide loader
  };

  const handleLoadMore = () => {
    setShowComments(true);
    setQueryParams((prev) => ({ ...prev, page: prev?.page + 1 }));
  };

  const CommentCaption = ({ text = "" }) => {
    const [loaderMore, setLoaderMore] = useState(false);

    return (
      <>
        <p key={Math.random()} className="font-size-13px  font-Poppins-Regular">
          {loaderMore ? text : text.slice(0, 300)}
        </p>
        {text.length > 300 && (
          <button
            className="on-hover-underline font-size-15px theme-grey-type  font-Poppins-Regular"
            onClick={() => setLoaderMore((prev) => !prev)}
          >
            {loaderMore ? "...less" : "... more"}
          </button>
        )}
      </>
    );
  };

  useEffect(() => {
    if (isShow) {
      setShowComments(isShow);
      setQueryParams((prev) => ({ ...prev, page: 1 }));
    }
  }, [isShow]);

  useEffect(() => {
    if (!showComments) {
      if (totalComments > allComments[0]?.reply && totalComments > 1) {
        setTotalPages(2);
      } else {
        setTotalPages(0);
      }
    }
  }, [allComments]);

  useEffect(() => {
    if (showComments && refId) {
      fetchComment();
    }
  }, [refId, queryParams, showComments]);

  return (
    <div className="">
      {/* <span className="Font-size-15px font-Poppins-Regular">Replies</span> */}
      {allComments && allComments.length > 0
        ? allComments.map((item, index) => (
          <div key={index}>
            <div className="w-full mb-5 flex gap-2  contain-comment-box">
              <div className="crete-feed-user-img flex items-start">
                <img
                  width={100}
                  loading="lazy"
                  src={`${item?.profileImageUrl}`}
                  // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                  alt="user"
                  className="rounded-full"
                />
                {/* comment owner image */}
              </div>
              <div
                className="shadow-lg rounded-xl w-full feed-cmnt"
                style={{ backgroundColor: "#70707017" }}
              >
                <div className="flex gap-3 items-start relative">
                  <div className="grid">
                    <div className="">
                      <Link
                        to={`/public-profile/${item?.userId}/view`}
                        className="font-size-13px font-Poppins-SemiBold capitalize"
                      >
                        {`${item?.firstName || ""} ${item?.lastName || ""}`}
                        {/* <span className="theme-color-green"> Connect</span> */}
                      </Link>
                      {/* comment owner name */}
                      <p className="font-size-13px theme-grey-type  font-Poppins-Regular capitalize-first-letter">
                        {item?.roleCategory || ""}
                      </p>
                      {/* comment owner role */}
                    </div>
                    <div className="absolute right-0 flex items-center gap-2">
                      <p className="font-size-13px theme-grey-type  font-Poppins-Regular  text-end">
                        {postTimeDifference(item?.createdAt)}
                      </p>
                      {/* comment create time */}

                      {/* {item?.userId === userId ? (
                        <PostDelete
                          id={item._id || ""}
                          // category={feedType}
                          // type="comment"
                          // id={deletePost}  // Convert to boolean
                          onClose={() => setDeletePost(false)}
                          type="comment"
                          category="post"
                        />
                      )
                        :
                        ( */}
                      {(UserRole === "participant" || UserRole === "provider") && (

                        <CommentOnReport
                          id={item?._id}
                          category={feedType}
                          // category={category}
                          type="comment"
                          userIdComment={item?.userId}
                          postId={item?.refId}
                        />
                      )}
                      {/* )
                      } */}

                    </div>
                    <div className="mt-1">
                      <CommentCaption text={item?.caption} />
                      {/* comment caption */}
                    </div>
                    <div className=" pt-1 flex gap-2 comment-like-div">
                      <div className="font-size-13px font-Poppins-SemiBold capitalize flex gap-1 items-center on-hover">
                        <button
                          onClick={() => handleToogleReaction(item?._id)}
                          style={{
                            color: item?.reactionStatus ? "#2196F3" : "#000",
                          }}
                        >
                          Like
                        </button>
                        <div className="total-like flex gap-1 items-center">
                          <span
                            style={{
                              backgroundColor: item?.reactionStatus
                                ? "#2196F3"
                                : "#000",
                            }}
                          >
                            <FontAwesomeIcon
                              color="white"
                              icon={faThumbsUp}
                              flip="horizontal"
                            />
                          </span>
                          {item?.reactions || 0}
                        </div>
                      </div>
                      <span className="font-size-13px font-Poppins-SemiBold capitalize on-hover">
                        | {item?.reply || " "}{" "}
                        <button
                          onClick={() =>
                            handleReplySection(item?._id, item?.reply)
                          }
                          disabled={commentId === item?._id}
                        >
                          Reply
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {commentId === item?._id && (
              <>
                <div style={{ paddingLeft: "4vw" }}>
                  {innerCommentLoader && (
                    <div className="text-center">
                      <CircularProgress
                        style={{ width: 20, height: 20, color: "#004c47" }}
                      />
                    </div>
                  )}
                  {innerComment.map((v, i) => (
                    <div key={i} className="w-full mb-3 flex gap-2 mt-12">
                      <div className="crete-feed-user-img flex items-start">
                        <img
                          width={100}
                          loading="lazy"
                          src={`${v?.profileImageUrl}`}
                          // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${v?.profileImageUrl}`}
                          alt="user"
                          className="rounded-full"
                        />
                        {/* comment owner image */}
                      </div>
                      <div
                        className="shadow-lg rounded-xl p-3 w-full"
                        style={{ backgroundColor: "rgb(112 112 112 / 25%)" }}
                      >
                        <div className="flex gap-3 items-start relative">
                          <div className="grid">
                            <div className="">
                              <Link
                                to={`/public-profile/${v?.userId}/view`}
                                className="font-size-13px font-Poppins-SemiBold capitalize"
                              >
                                {`${v?.firstName || ""} ${v?.lastName || ""}`}
                                {/* <span className="theme-color-green"> Connect</span> */}
                              </Link>
                              {/* comment owner name */}
                              <p className="font-size-13px theme-grey-type  font-Poppins-Regular capitalize">
                                {v?.role || v?.roleCategory || ""}
                              </p>
                              {/* comment owner role */}
                            </div>
                            <div className="absolute right-0 flex items-center gap-2">
                              <p className="font-size-13px theme-grey-type  font-Poppins-Regular  text-end">
                                {postTimeDifference(v?.createdAt)}
                              </p>
                              {/* comment create time */}
                              {(UserRole === "participant" || UserRole === "provider") && (


                                <CommentOnReport
                                  id={v?._id}
                                  category={feedType}
                                  type="thread"
                                  userIdComment={v?.userId}
                                  postId={v?.refId}
                                />
                              )}
                            </div>
                            <div className="mt-3">
                              <p className="font-size-13px  font-Poppins-Regular">
                                {v?.caption || ""}
                              </p>
                              {/* comment caption */}
                            </div>
                            <div className=" pt-1 flex gap-2">
                              <div className="font-size-13px font-Poppins-SemiBold capitalize flex gap-1 items-center">
                                <button
                                  onClick={() =>
                                    handleToogleReaction(v?._id, true)
                                  }
                                  style={{
                                    color: v?.reactionStatus
                                      ? "#2196F3"
                                      : "#000",
                                  }}
                                >
                                  Like
                                </button>{" "}
                                .
                                <div className="total-like flex gap-1 items-center">
                                  <span
                                    style={{
                                      backgroundColor: v?.reactionStatus
                                        ? "#2196F3"
                                        : "#000",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      color="white"
                                      icon={faThumbsUp}
                                      flip="horizontal"
                                    />
                                  </span>
                                  {v?.reactions || 0}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}




                  {(UserRole === "participant" || UserRole === "provider") && (

                    <>

                      <AddComment
                        refId={refId}
                        successFunction={handleAddNewComment}
                        commentId={commentId || ""}
                        isCancelButton={true}
                        cancelComment={() => {
                          setCommentId(false);
                          setInnerComment([]);
                        }}
                        feedType={feedType}
                      />
                    </>)}

                </div>

              </>
            )}
          </div>
        ))
        : ""}
      {loader && (
        <div className="text-center">
          <CircularProgress
            style={{ width: 20, height: 20, color: "#004c47" }}
          />
        </div>
      )}
      {totalPages > queryParams?.page && (
        <div onClick={handleLoadMore} className="flex justify-start">
          <button className="font-size-16px on-hover-underline">
            Load more comments
          </button>
        </div>
      )}
    </div>
  );
}

export default DisplayComments;
