/**
 * DiscussionActivity Component
 *
 * This component fetches and displays user activity in the form of discussions and comments.
 * It includes a header, sidebars, and an infinite scroll for loading activity data.
 *
 * File Name: DiscussionActivity.jsx
 * Author: Developer
 * Date: 2024-06-15
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../components/AllDashboardPagesComponents/SidebarGobal";
import RightSidebarFeed from "../../components/NewsFeeds/RightSidebarFeed";

import { useParams, Link } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "qs";
import { CircularProgress } from "@mui/material";
import {
  postTimeDifference,
  truncateString,
} from "../../config/Helpers/helpers";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityCommentSection from "./ActivityCommentSection";
import httpRequest from "../../config/Helpers/httpRequest";
import AddressSocialDetail from "../../components/AllDashboardPagesComponents/Profile/AddressSocialDetail";

/**
 * DiscussionActivity component displays user activity in discussions and comments.
 */
function DiscussionActivity() {
  let { uId } = useParams(); // Fetching user ID from URL parameters

  const LoaderComponents = () => {
    // full page loader
    return (
      <div className="full_page_loader">
        <CircularProgress style={{ color: "#fff" }} />
      </div>
    );
  };

  const CaptionComponent = ({ text = "" }) => {
    // post caption component
    const [isFullText, setIsFullText] = useState(false);
    return (
      <div
        className="grid items-end see-less-funct"
        style={{ gridTemplateColumns: "36vw 6vw" }}
      >
        {isFullText ? (
          <p className="font-size-17px theme-grey-type font-Poppins-Regular">
            {text || ""}
          </p>
        ) : (
          <p className="font-size-17px theme-grey-type font-Poppins-Regular">
            {truncateString(text, 240)}
          </p>
        )}
        {text?.length > 240 && (
          <div className="flex justify-center ">
            <button
              onClick={() => setIsFullText(!isFullText)}
              className="font-size-17px theme-grey-type  font-Poppins-Medium "
            >
              ...see {isFullText ? "less" : "more"}
            </button>
          </div>
        )}
      </div>
    );
  };

  const [category, setCategory] = useState("posts"); // for category toogle
  const [data, setData] = useState([]); // for data
  const [isLoader, setIsLoader] = useState(false); // for API loader
  const [totalPages, setTotalPages] = useState(1); // total pages for pagination
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 5,
  }); // query params for APIs

  // Functionality: API call for get user activity post & comment
  const fetchData = async () => {
    setIsLoader(true);
    // const { res, err } = await httpRequest({
    //   path: `/api/discussion/activity/${category}/${uId}?${qs.stringify(
    //     queryParams
    //   )}`,
    // });
    let ApiPath = ""
    if (category === "posts") {
      ApiPath = `/api/discussion/activity-discussions/${uId}?${qs.stringify(
        queryParams
      )}`
    }
    if (category === "comments") {
      ApiPath = `/api/social/activity/discussion/${uId}?${qs.stringify(queryParams)}`
    }
    const { res, err } = await httpRequest({
      path: ApiPath
    });
    if (res) {
      let discussionsList = res?.documents || [];
      setData((prev) => [...prev, ...discussionsList]); // set data for display
      setTotalPages(res?.paginated?.totalPages || 1); // for inifinite scroll or pagination
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  // Initial data fetch on component mount or dependency change
  useEffect(() => {
    fetchData();
  }, [uId, category, queryParams]);

  /**
   * Component rendering
   */
  return (
    <>
      <HeaderGlobal />
      {isLoader && <LoaderComponents />}
      <div className="container-1480 block justify-between h-3vw lg:flex md:flex gap-3">
        <SidebarGobal />
        <div className="news-feed-post">
          <div className="shadow-lg bg-white rounded-xl mb-2 py-3 px-6 flex justify-between items-center">
            <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
              Activity
            </h4>
            <div className="flex gap-2">
              <button
                onClick={() => {
                  setData([]);
                  setQueryParams((prev) => ({ ...prev, page: 1 }));
                  setCategory("posts");
                }}
                disabled={category === "posts"}
                style={{ color: category === "posts" ? "#fff" : "#004540" }}
                className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  ${category === "posts" ? "theme-bg-green" : ""
                  }`}
              >
                Discussion
              </button>
              <button
                onClick={() => {
                  setData([]);
                  setQueryParams((prev) => ({ ...prev, page: 1 }));
                  setCategory("comments");
                }}
                disabled={category === "comments"}
                style={{ color: category === "comments" ? "#fff" : "#004540" }}
                className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  ${category === "comments" ? "theme-bg-green" : ""
                  }`}
              >
                Comments
              </button>
            </div>
            {/* change type discussions & comment */}
          </div>
          <InfiniteScroll
            dataLength={data.length}
            next={() =>
              setQueryParams((prev) => ({ ...prev, page: prev.page + 1 }))
            }
            hasMore={queryParams.page < totalPages}
            loader={isLoader && <LoaderComponents />}
            endMessage={""}
          >
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <div
                  key={index}
                  className="shadow-lg bg-white rounded-xl mb-2 py-3 relative"
                >
                  {/* {category === "comments" && (
                    <>
                      <div className="flex gap-2 items-center border-bottom commeted-show px-3 pb-2">
                        <div className="crete-feed-user-img flex ">
                          <img
                            width={100}
                            loading="lazy"
                            src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.comments?.profileImageUrl}`}
                            onError={(e) =>
                              (e.target.src = DefaultProfileImage)
                            }
                            alt="user"
                            className="rounded-full"
                          />
                        </div>{" "}
                        <Link
                          to={`/public-profile/${item?.userId}/view`}
                          className="font-size-15px font-Poppins-SemiBold theme-color-green capitalize"
                        >
                          {" "}
                          {`${item?.comments?.firstName || ""} ${item?.comments?.lastName || ""
                            }`}
                        </Link>{" "}
                        <p class="font-size-12px theme-grey-type  font-Poppins-Medium  ">
                          commented on this
                        </p>
                      </div>
                      <hr />
                    </>
                  )} */}
                  <div className="flex gap-2 items-center justify-between px-2 px-3">
                    <div className="flex gap-2 items-center">
                      <div className="crete-feed-user-img flex ">
                        <img
                          width={100}
                          loading="lazy"
                          src={`${item?.profileImageUrl}`}
                          // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                          onError={(e) => (e.target.src = DefaultProfileImage)}
                          alt="user"
                          className="rounded-full"
                        />
                      </div>{" "}
                      {/* post creator image */}
                      <div className="sidebar-gobal-user-name ">
                        <Link
                          to={`/public-profile/${item?.userId}/view`}
                          className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize"
                        >
                          {" "}
                          {`${item?.firstName || ""} ${item?.lastName || ""}`}
                        </Link>{" "}
                        {/* post creator name */}
                        <p className="font-size-15px  theme-color-green font-Poppins-Regular  capitalize">
                          {item?.connections || 0} Connections
                        </p>{" "}
                        {/* post creator connection count */}
                        <p className="font-size-15px theme-grey-type  font-Poppins-Regular  ">
                          {postTimeDifference(item?.createdAt)}{" "}
                          <FontAwesomeIcon icon={faEarthAmericas} />
                        </p>{" "}
                        {/* post time differnce */}
                      </div>
                    </div>
                  </div>
                  <div className="px-3 pt-3">
                    <Link
                      to={`/discussion/${item?._id}/view`}
                      className="font-size-17px theme-color-green font-Poppins-Regular mb-2 capitalize"
                      style={{ fontWeight: "700" }}
                    >
                      {item?.title}
                    </Link>
                    <CaptionComponent text={item?.description} />

                    <ActivityCommentSection
                      item={item}
                      data={data}
                      setData={setData}
                      category={category}
                    />
                  </div>
                </div>
              ))
            ) : (
              <p>No Post</p>
            )}
          </InfiniteScroll>
        </div>
        {/* <RightSidebarFeed /> */}
        <AddressSocialDetail
            unpaidModalShow={false}
            userId={uId}

          />
      </div>
    </>
  );
}

export default DiscussionActivity;
