import React from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import { getRole } from "../../config/Helpers/helpers";
import SchedulerCalender from "../../components/JobsComponents/SchedulerCalender";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";

const Scheduler: React.FC = () => {
  // const ROLE = getRole()?.UserRole || false;
  const decoded = getRole();
  const ROLE = decoded?.role;
  return (
    <>
      <div className="">
        {ROLE === "operator" ? <AdminHeader /> : <HeaderGlobal />} {/* Show the admin header if the user is an admin; otherwise, show the customer header */}
        <div className=" " style={{ margin: "3.7vw" }}>
          <div className="schedular-parent-top-padding">
            <SchedulerCalender />
          </div>
        </div>
      </div>
    </>
  );
};

export default Scheduler;