import React, { useState, useEffect } from 'react';
import httpRequest from '../../config/Helpers/httpRequest';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';

interface DataPoint {
    label: string;  // Changed to a generic 'label' for flexibility
    Participants: number;
    Providers: number;
    [key: string]: any;
}
interface IApiResponse {
    documents: {
        _id: string;
        participantCount: number;
        providerCount: number;
        [key: string]: any;

    }[];
    totalUsers: number;
    [key: string]: any;

}
const MyLineChart: React.FC = () => {
    const [data, setData] = useState<DataPoint[]>([]);
    const [totalUser, setTotalUser] = useState<number>(0);
    const [query, setQuery] = useState<string>("month"); // Default to "month"
    const [state, setState] = useState<string>("");

    const handleCheckboxChange = (value: string) => {
        setQuery(value);
    };
    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setState(selectedValue);
        // You can add additional logic here if needed
        console.log('Selected state:', selectedValue);
    };

    const fetchUserGraph = async () => {
        const { res, err } = await httpRequest<IApiResponse>({
            method: "get",
            path: `/api/admin/user/users-graph?state=${state}&groupBy=${query}`
        });
        if (res) {
            let completeLabels: string[] = [];
            let labelDataMap: { [key: string]: DataPoint } = {};

            // Generate the complete set of labels
            if (query === 'week') {
                completeLabels = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
            } else if (query === 'month') {
                completeLabels = Array.from({ length: 31 }, (_, i) => (i + 1).toString()); // "1" to "31"
            } else if (query === 'year') {
                completeLabels = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
            }

            // Populate the labelDataMap with API data
            res.documents.forEach(doc => {
                const idNumber = parseInt(doc._id, 10);
                let label = '';

                if (query === 'week') {
                    label = completeLabels[idNumber - 1];
                } else if (query === 'month') {
                    label = idNumber.toString();
                } else if (query === 'year') {
                    label = completeLabels[idNumber - 1];
                }

                labelDataMap[label] = {
                    label,
                    Participants: doc.participantCount,
                    Providers: doc.providerCount
                };
            });

            // Merge the API data with the full set of labels
            const transformedData = completeLabels.map(label => {
                return labelDataMap[label] || {
                    label,
                    Participants: 0,
                    Providers: 0
                };
            });

            setData(transformedData);
            setTotalUser(res.totalUsers);
        } else {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchUserGraph();
    }, [query, state]);  // Re-fetch data when 'query' changes

    return (
        <>
            <div className="total-user-graph bg-white">
                <div>
                    <div className=' flex justify-center flex-row gap-4'>
                        <div className="flex gap-2 text-[white#000000] font-Poppins-Medium font-size-13px items-center">
                            <div className="legend-color" style={{ backgroundColor: "rgb(1, 89, 88)" }} />
                            Providers
                        </div>
                        <div className="flex gap-2 text-[white#000000] font-Poppins-Medium font-size-13px items-center">
                            <div className="legend-color" style={{ backgroundColor: "rgb(15 194 192)" }} />
                            Participant
                        </div>

                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div className="total-providers-count font-Poppins-Regular font-size-14px">
                            Total users: <strong className="font-Poppins-SemiBold text-[#003E3A] font-size-16px">{totalUser}</strong>
                        </div>
                        <div className="flex gap-4 my-2 ">
                            <label className='font-size-12px font-Poppins-Regular flex flex-row gap-1 text-nowrap'>
                                <input type="checkbox"
                                    checked={query === "week"}
                                    onChange={() => handleCheckboxChange("week")}
                                />
                                This week
                            </label>
                            <label className='font-size-12px font-Poppins-Regular flex flex-row gap-1 text-nowrap'>
                                <input type="checkbox"
                                    checked={query === "month"}
                                    onChange={() => handleCheckboxChange("month")}

                                    defaultChecked />This month
                            </label>
                            <label className='font-size-12px font-Poppins-Regular flex flex-row gap-1 text-nowrap'>
                                <input type="checkbox"
                                    checked={query === "year"}
                                    onChange={() => handleCheckboxChange("year")}
                                />This year
                            </label>
                        </div>
                        <div>
                            <select id="filter" className="select-option-amdin font-Poppins-Medium font-size-13px"
                                onChange={handleStateChange}
                            >
                                <option className="font-Poppins-Medium font-size-13px" value="">All states</option>
                                <option className="font-Poppins-Medium font-size-13px" value="QLD">Queensland</option>
                                <option className="font-Poppins-Medium font-size-13px" value="TAS">Tasmania</option>
                                <option className="font-Poppins-Medium font-size-13px" value="NSW">New South Wales</option>
                                <option className="font-Poppins-Medium font-size-13px" value="VIC">Victoria</option>
                                <option className="font-Poppins-Medium font-size-13px" value="WA">Western Australia</option>
                                <option className="font-Poppins-Medium font-size-13px" value="SA">South Australia</option>
                                <option className="font-Poppins-Medium font-size-13px" value="NT">Northern Territory</option>
                                <option className="font-Poppins-Medium font-size-13px" value="ACT">Australian Capital Territory</option>

                            </select>
                        </div>
                    </div>
                </div>
                <div className="my-line-cart-graph">
                    <ResponsiveContainer width="100%" height="100%">
                        <LineChart data={data}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis
                                dataKey="label"
                                tick={{ fontSize: 14 }}  // Change font size here
                            />
                            <YAxis
                                tick={{ fontSize: 14 }}  // Change font size here
                            />
                            <Tooltip
                                contentStyle={{ fontSize: 14 }}  // Change font size here
                            />
                            <Legend
                                wrapperStyle={{ fontSize: 14 }}  // Change font size here
                            />
                            <Line type="linear" dataKey="Participants" stroke="#008a80" strokeWidth={1} />
                            <Line type="linear" dataKey="Providers" stroke="#004d00" strokeWidth={1} />
                        </LineChart>
                    </ResponsiveContainer>
                </div>
            </div>

        </>
    );
};

export default MyLineChart;
