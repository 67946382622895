import React, { useState, useEffect } from "react";
import { setCookie, validateEmail } from "../../config/Helpers/helpers";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import {
  storeTwoFactor,
  storeToken,
  setUpgradeUser,
  removeUpgradeUser,
} from "../../config/Store/Reducers/authSlice";
import { CircularProgress } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEyeSlash,
  faEye,
  faCircleXmark,
  faTimesCircle,
  faX,
  faArrowRight
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
  };
}
interface UpgradeResponse {
  data: {
    userDetails: object;
  }[];
}
interface LoginResponse {
  token?: string;
  twoFactor?: boolean;
  timeExpires?: string;
  data?: object;
}
function LoginForms() {
  const location = useLocation();
  const { pathname } = location;
  const isAdmin = pathname === process.env.REACT_APP_ADMIN_LOGIN ? true : false;
  console.log(isAdmin, "isAdminisAdmin")
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // show password
  const [showPassword, setShowPassword] = useState(false);
  // ================= login end
  //  input password field state

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  //  input password field state

  // CheckBox State
  const [isError, setIsError] = useState<string | boolean | undefined>(false); // for error
  const [isLoader, setIsLoader] = useState(false); // for loader
  const [keepMeloggedIn, setKeepMeloggedIn] = useState(false); // for keepMeloggedIn
  const [email, setEmail] = useState("");
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  // Error Modal State
  const [errorModalIsOpen, setErrorModalIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  // Function to handle 429 error
  const handle429Error = (err: ErrorResponse | string) => {
    let message = "Too Many Requests"; // Default error message

    // Handle the case where err is an AxiosError
    if (typeof err === 'object' && 'response' in err) {
      const axiosErr = err as ErrorResponse;
      message = axiosErr.response?.data?.message || message;
    } else if (typeof err === 'string') {
      message = err; // If err is a string, use it directly as the message
    }

    setErrorMessage(message);
    setErrorModalIsOpen(true); // Open the error modal
  };



  // Function to close the error modal
  const handleCloseErrorModal = () => {
    setErrorModalIsOpen(false);
  };

  // Functionality: API call for upgrade (free user after 3 login upgrade account)
  const handleUpgrade = async () => {
    setIsLoader(true);
    try {
      const response = await axios.get<UpgradeResponse>(
        `${process.env.REACT_APP_BASE_URL}/api/user/upgradeAccount?emailId=${email}`
      );
      if (response.status === 200) {
        let data = response.data?.data[0]?.userDetails;
        // dispatch(setUpgradeUser(data));
        navigate("/paid-register");
      }
    } catch (err: any) {
      setIsError(err.response?.data?.message || "ERROR");
      toast(err.response?.data?.message, {
        type: "error",
      });
    }
    setIsLoader(false);
  };
  // Functionality: API call for login
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!email && !isAdmin) {
      toast("Please enter email", {
        type: "error",
      });
    } else if (!validateEmail(email) && !isAdmin) {
      toast("Please enter a valid email address.", {
        type: "error",
      });
    } else if (!username && isAdmin) {
      toast("Please enter user name", {
        type: "error",
      });
    } else if (!password) {
      toast("Please enter Password", {
        type: "error",
      });
    } else {
      setIsLoader(true); // show loader
      try {
        let requestBody;
        if (isAdmin) {
          requestBody = {
            userName: username, // Assuming userName is a variable containing the user's name
            password,
            keepMeloggedIn,
          };
        } else {
          requestBody = {
            email,
            password,
            keepMeloggedIn,
          };
        }
        const response = await axios.post<LoginResponse>(
          `${process.env.REACT_APP_BASE_URL}/api/${isAdmin ? "operator/login" : "user/login"
          }`,
          requestBody
        );
        if (response.status) {
          console.log("TOKEN", response?.data);
          let token = response?.data?.token || false;
          if (token) {
            console.log("tokentoken", token)
            dispatch(storeToken(token));
            setCookie(token); // set token in cookie
            const payload = token.split(".");
            const payloadValue = JSON.parse(atob(payload[1]));
            console.log("payloadValue", payloadValue);
            // dispatch(removeUpgradeUser());
            if (
              payloadValue?.role === "provider" ||
              payloadValue?.role === "participant"
            ) {
              // navigate("/feed");
              navigate("/feed");
            } else {
              navigate("/admin");
            }
          } else if (isAdmin && response) {
            const currentTimestamp = Date.now();
            const fiveMinutesInMilliseconds = 2 * 60 * 1000; // 1 minute in milliseconds
            const futureTimestamp = currentTimestamp + fiveMinutesInMilliseconds;
            dispatch(
              storeTwoFactor({
                time: futureTimestamp,
                email: username,
                admin: true,
                keepMeloggedIn,
              })
            );
            navigate("/recovery-verification");
          }
          setEmail("");
          setUserName("");
          setPassword("");
        }
      } catch (err: any) {
        console.log(err, "error")
        if (err?.response?.status === 429) {
          handle429Error(err); // Handle 429 error
          setIsLoader(false);
          return;
        }
        if (err?.response?.status === 422) {
          console.log(err?.response)
          toast(err?.response?.data?.error?.email, {
            type: "error",
          });
          setIsLoader(false);

          return;
        }


        toast(err?.response?.data?.message, {
          type: "error",
        });
      }
      setIsLoader(false); // hide loader
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="flex flex-col gap-3">
        {isAdmin ? (
          <div className="relative">
            <input
              className="home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px  outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
              type="text"
              placeholder="Username"
              value={username}
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>
        ) : (
          <div className="relative">
            <input
              className="home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px  outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
              type="text"
              id="loginEmail"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        )}
        <div className="relative">
          <input
            className="home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px  outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
            placeholder="Password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FontAwesomeIcon
            icon={showPassword ? faEye : faEyeSlash}
            className="input-field-eye absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
            onClick={togglePasswordVisibility}
          />
        </div>
      </div>
      {isError && <p style={{ color: "red" }}>{isError}</p>}
      <div className="flex items-center justify-between my-3">
        <div className="flex items-center gap-2">
          <label className="checkbox-container flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="checkbox-input hidden"
              name="keepMeloggedIn"
              id=""
              checked={keepMeloggedIn}
              onChange={() => setKeepMeloggedIn((prev) => !prev)}
            />

            <span className={`checkbox-custom relative ${keepMeloggedIn ? "bg-[#FFE175]" : ""}`}></span>
          </label>
          <span className="font-size-login-16px font-Poppins-Regular text-white">
            Remember me
          </span>
        </div>
        <Link
          className="text-[#FFE175] font-size-login-16px font-Poppins-Regular user-forget"
          to="/recovery-password"
        >
          Forgotten password?
        </Link>
      </div>

      <button
        type="submit"
        className="mt-5 w-full text-white font-size-login-18px font-Poppins-SemiBold login-page-btn flex items-center justify-center gap-2 border-radius-20px theme-bg-green-light"
        disabled={isLoader}

      >
        Login
        <FontAwesomeIcon size="lg" icon={faArrowRight} />
        {isLoader && (
          <CircularProgress
            style={{ color: "#fff" }}
            className="btn__loader"
          />
        )}
      </button>


      {errorModalIsOpen && (
        <div className="modal modal-2">
          <div className="edit-modal-content relative">
            <button
              onClick={handleCloseErrorModal}
              className="absolute job-pre-edit"
            >
              <FontAwesomeIcon
                className="x-icon-admin-opr"
                icon={faX}
                style={{ color: "#ffffff" }}
              />
            </button>
            <h4 className="uppercase font-Poppins-SemiBold font-size-20px text-center subs-modal-head-2">
              Please upgrade your account to premium
            </h4>
            <ul className="mb-5 mt-5  font-Poppins-Regular font-size-15px free-upt-modal">
              <li>
                {" "}
                Your account has been removed as per our policies for free
                users.
              </li>
              <li> Free user's can login only three times.</li>
              <li> Each login session consist of 30 mins of activity.</li>
            </ul>
            <button
              disabled={isLoader}
              onClick={handleUpgrade}
              className=" bg-white theme-color-green border-radius-6px font-size-login-16px font-Poppins-SemiBold login-btn-home-banner input-btns-height-40px flex items-center justify-center reg-btn-modal"
            >
              Register{" "}
              {isLoader && (
                <CircularProgress
                  style={{ color: "#fff" }}
                  className="btn__loader"
                />
              )}
            </button>
          </div>
        </div>
      )}
    </form>
  );
}

export default LoginForms;
