import React from "react";
import LoginComponent from "../../components/LoginComponents/LoginComponent";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";

const Freeloginpage = () => {
  return (
    <>
      <LoginComponent />
    </>
  );
};

export default Freeloginpage;
