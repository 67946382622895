import React from "react";
import { Link } from "react-router-dom";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function paymentsuccess() {
  const handleDoneClick = () => {
    window.location.href = "/feed";  // Navigate to /feed and trigger a hard page reload
  };

  return (

    <div className="flex flex-col items-center gap-4 mx-auto text-center absolute bg-[#F4F2EE] payment-success-modal createpost-modal absolute z-20" >
      <button
        onClick={handleDoneClick}
        className="absolute right-0 -top-5 edituser-close-icon font-size-24px"

      >
        <FontAwesomeIcon icon={faTimesCircle} />
      </button>
      <img src="https://ndisync.backslashwebs.com/Icon.png" alt="" />
      <h3 className="font-size-login-36px font-Montserrat-SemiBold  ">
        Payment successful
      </h3>
      <div className="flex flex-col">
        <p className="font-size-login-16px font-Montserrat-Regular rounded-lg">
          Your payment has been successfully completed! Thank you for your
          purchase. A confirmation email has been sent to your
          registered email address.
          If you have any questions or concerns, please feel free to contact
          us.{" "}
        </p>
      </div>

      <div className="flex items-center justify-center">
        <button
          onClick={handleDoneClick}
          type="submit"
          className="btn-w font-size-18px  font-Poppins-Medium feeds-btn mt-3 theme-bg-green text-white"
        >
          Done
        </button>
      </div>
    </div>
  );
}

export default paymentsuccess;
