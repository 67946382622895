/**
 * SettingTab Component
 * Renders the tabs for settings including changing password, email, and notification updates.
 * 
 * File: SettingTab.js
 * Author: Developer
 * Date: 28-03-24
 */

import React from 'react';
import ChangePassword from "../../../pages/AllDashboardPages/SettingComponent/ChangePassword";
import ChangeEmail from "../../../pages/AllDashboardPages/SettingComponent/ChangeEmail";
import NotificationsUpdate from "../../../pages/AllDashboardPages/SettingComponent/NotificationsUpdate";

const SettingTab = () => {
    return (
        <div className="mt-2">
            <ChangePassword /> {/* Component to change the user's password */}
            <ChangeEmail />
            <NotificationsUpdate />
        </div>
    );
};

export default SettingTab;