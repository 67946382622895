import { createSlice } from "@reduxjs/toolkit";

const postActivitySlice = createSlice({
    name: "NEWS_FEED",
    initialState: {
        allPosts: [],
    },
    reducers: {
        resetActivityPosts: (state) => {
            state.allPosts = []; // reset all posts
        },
        setActivityPosts: (state, { payload }) => {

            state.allPosts = [...state.allPosts, ...payload]; // concat previous & new array

        },
        toogleActivityLikedPost: (state, { payload = {} }) => { // toogle like status
            let { postId, status } = payload;
            let updateAllPosts = state.allPosts.map(newObj => {
                if (newObj?._id === postId) {
                    return {
                        ...newObj,
                        reactionStatus: status, // status change
                        allReacts: status ? newObj?.allReacts + 1 : newObj?.allReacts - 1, // update reaction count
                    };
                }
                return newObj;
            });
            state.allPosts = updateAllPosts;
        },
        /**
 * Updates the comment count for a specific post within the state.
 * Increments the 'allComments' count of the post identified by 'payload'
 * in the 'allPosts' array of the state.
 *
 * @param {Object} state - The current state object containing allPosts array.
 * @param {Object} payload - The identifier (typically _id) of the post to update.
 */
        updateActivityCommentCounter: (state, { payload }) => {
            // Create a new list of posts with updated comment count
            let newList = state.allPosts.map(newObj => {
                if (newObj?._id === payload) {
                    return {
                        ...newObj,
                        allComments: newObj?.allComments + 1,
                    }
                }
                return newObj;
            });

            // Update the state with the new list of posts
            state.allPosts = newList;
        },
    },
});

export const { resetActivityPosts, setActivityPosts, toogleActivityLikedPost, updateActivityCommentCounter } = postActivitySlice.actions;
export default postActivitySlice.reducer;