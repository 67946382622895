import React, { Component } from "react";
import PropTypes from "prop-types";
import Select, { components, Props as SelectProps, MultiValue, SingleValue, ActionMeta } from "react-select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons";
type OptionType = {
  value: string;
  label: string;
};

interface MySelectProps extends Omit<SelectProps<OptionType>, 'onChange'> {
  currentDays: string[];
  onChange: (selectedDays: string[]) => void;
}

const Option = (props: any) => (
  <div>
    <components.Option {...props} style={{ fontFamily: "Poppins-Regular", fontSize: "15px" }}>
      <input style={{ fontFamily: "Poppins-Regular", accentColor: "#00443f" }} type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label style={{ fontFamily: "Poppins-Regular", fontSize: "15px" }}>{props.label}</label>
    </components.Option>
  </div>
);
class MySelect extends Component<MySelectProps> {
  static propTypes = {
    onChange: PropTypes.func.isRequired // Renamed to onChange
  };
  handleChange = (newValue: MultiValue<OptionType> | SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    const selectedValues = (newValue as MultiValue<OptionType>).map(option => option.value);
    this.props.onChange(selectedValues);
  };
  render() {
    const { currentDays, onChange = () => { }, ...otherProps } = this.props;

    // Hardcoded options for Monday to Sunday
    const options = [
      { value: "monday", label: "Monday" },
      { value: "tuesday", label: "Tuesday" },
      { value: "wednesday", label: "Wednesday" },
      { value: "thursday", label: "Thursday" },
      { value: "friday", label: "Friday" },
      { value: "saturday", label: "Saturday" },
      { value: "sunday", label: "Sunday" }
    ];

    // Custom placeholder component with FontAwesome icon and text
    const customPlaceholder = () => (
      <div className="filter-placeholder" style={{ display: "flex", alignItems: "center", opacity: 1, color: "#00443f" }}>
        <FontAwesomeIcon icon={faCalendarCheck} style={{ marginRight: 5 }} />
        <span className="font-Poppins-Regular">Availability</span>
      </div>
    );

    // Custom MultiValue component to limit displayed selections
    const MultiValue = (props: any) => {
      const { data, selectProps } = props;
      const { value } = selectProps;

      if (value.length > 3) {
        if (value.indexOf(data) < 3) {
          return (
            <components.MultiValue {...props}>
              <span>{data.label}</span>
            </components.MultiValue>
          );
        } else if (value.indexOf(data) === 3) {
          return (
            <components.MultiValue {...props}>
              <span>...</span>
            </components.MultiValue>
          );
        } else {
          return null; // Hide values beyond the third selected option
        }
      } else {
        return (
          <components.MultiValue {...props}>
            <span>{data.label}</span>
          </components.MultiValue>
        );
      }
    };

    return (
      <div className="availability">
        <Select
          isMulti
          closeMenuOnSelect={false}
          components={{ Option, MultiValue }}
          options={options}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          value={options.filter(option => currentDays.includes(option.value))}
          // onChange={(e) => onChange(e.map(v => v.value))} // Changed to use onChange prop
          onChange={this.handleChange}

          placeholder={customPlaceholder()}
          {...otherProps}
        />
      </div>
    );
  };
};

export default MySelect;