/**
 * NotificationsUpdate Component
 * 
 * This component manages user notification settings.
 * Users can update their preferences for various notification types.
 * 
 * File Name: NotificationsUpdate.js
 * Author: Developer
 * Date: 12/04/24
 */

import React, { useState, useEffect, FormEvent } from "react";

import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import httpRequest from "../../../config/Helpers/httpRequest";

/**
 * NotificationsUpdate component for managing user notification settings.
 */
interface IApiResponse {
  message: string;
  settings: {
    newMessage: boolean;
    announcements: boolean;
    jobPosting: boolean;
    statusUpdate: boolean;
    connectionRequests: boolean;
    connectionAcceptance: boolean;
    upcomingAppointment: boolean;
    changedAppointments: boolean;
    promotions: boolean;
    feature: boolean;
    feedbackRequests: boolean;
    renewal: boolean;
    paymentTransactions: boolean;
  };
}
function NotificationsUpdate() {
  const [updLoader, setUpdLoader] = useState(false);
  const [allnotifications, setAllNotifications] = useState({
    newMessage: false,
    announcements: false,
    jobPosting: false,
    statusUpdate: false,
    connectionRequests: false,
    connectionAcceptance: false,
    upcomingAppointment: false,
    changedAppointments: false,
    promotions: false,
    feature: false,
    feedbackRequests: false,
    renewal: false,
    paymentTransactions: false,
  });

  /**
   * Functionality: Handles form submission to update notification settings.
   * @param {Event} event - Form submission event
   */
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    setUpdLoader(true);
    const { res, err } = await httpRequest<IApiResponse>({ method: "put", path: "/api/notification-settings/update", params: allnotifications })
    if (res) {
      toast(res?.message, { type: "success" });
    } else {
      toast(err?.message, { type: "error" });
    }
    setUpdLoader(false);
  };

  /**
  * Functionality: Fetches current notification settings on component mount.
  */
  useEffect(() => {
    (async () => {
      const { res, err } = await httpRequest<IApiResponse>({ path: "/api/notification-settings" });
      if (res) {
        const data = res?.settings;
        setAllNotifications({
          newMessage: data?.newMessage,
          announcements: data?.announcements,
          jobPosting: data?.jobPosting,
          statusUpdate: data?.statusUpdate,
          connectionRequests: data?.connectionRequests,
          connectionAcceptance: data?.connectionAcceptance,
          upcomingAppointment: data?.upcomingAppointment,
          changedAppointments: data?.changedAppointments,
          promotions: data?.promotions,
          feature: data?.feature,
          feedbackRequests: data?.feedbackRequests,
          renewal: data?.renewal,
          paymentTransactions: data?.paymentTransactions,
        });
      } else {
        toast(err?.message, { type: "error" });
      }
    })();
  }, []);

  return (
    <>
      <div className="shadow-lg bg-white rounded-none lg:rounded-xl  flex flex-col gap-2 mb-2 py-3 px-6 ">
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green mb-3">
          Notifications
        </h3>
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green leading-none mb-3 mt-4">
          <i>Email notifications</i>
        </h3>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            New message received
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.newMessage}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                newMessage: !allnotifications.newMessage,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Important updates and announcements from the platform
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.announcements}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                announcements: !allnotifications.announcements,
              })
            }
          />
        </div>
        <h3 className="font-size-20px font-Poppins-SemiBold  theme-color-green leading-none mb-3 mt-4">
          <i> Job-related notifications</i>
        </h3>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            New job postings matching user preferences or skills
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.jobPosting}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                jobPosting: !allnotifications.jobPosting,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Job application status updates (e.g., application received,
            application reviewed)
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.statusUpdate}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                statusUpdate: !allnotifications.statusUpdate,
              })
            }
          />
        </div>
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green leading-none mbs-3 mt-4">
          <i>  Networking and connection notifications</i>
        </h3>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Connection requests from other users within the platform
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.connectionRequests}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                connectionRequests: !allnotifications.connectionRequests,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Acceptance of connection requests
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.connectionAcceptance}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                connectionAcceptance: !allnotifications.connectionAcceptance,
              })
            }
          />
        </div>
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green leading-none mbs-3 mt-4">
          <i> Calendar and scheduling notifications</i>
        </h3>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Upcoming appointments or shifts
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.upcomingAppointment}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                upcomingAppointment: !allnotifications.upcomingAppointment,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Changes to scheduled appointments or shifts
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.changedAppointments}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                changedAppointments: !allnotifications.changedAppointments,
              })
            }
          />
        </div>
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green leading-none mbs-3 mt-4">
          <i> Promotional and platform updates</i>
        </h3>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Promotional offers or discounts on highlighted profiles or job
            postings
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.promotions}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                promotions: !allnotifications.promotions,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Platform feature updates or enhancements
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.feature}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                feature: !allnotifications.feature,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Feedback requests or surveys to improve platform experience
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.feedbackRequests}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                feedbackRequests: !allnotifications.feedbackRequests,
              })
            }
          />
        </div>
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green leading-none mbs-3 mt-4">
          <i> Subscription and billing notifications</i>
        </h3>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Renewal reminders for subscription memberships
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.renewal}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                renewal: !allnotifications.renewal,
              })
            }
          />
        </div>
        <div className="flex justify-between">
          <p className="font-size-15px font-Poppins-Medium w-[87%] text-black">
            Notifications for successful payment transactions or invoices
          </p>
          <input
            className="individiual-checkbox individiualinput-slider"
            type="checkbox"
            checked={allnotifications.paymentTransactions}
            onChange={() =>
              setAllNotifications({
                ...allnotifications,
                paymentTransactions: !allnotifications.paymentTransactions,
              })
            }
          />
        </div>
        <div>
          <button
            className="mt-3 theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
            type="submit"
            onClick={handleSubmit}
            disabled={updLoader}
            style={{ color: "white" }}
          >
            Update
            {updLoader && (
              <CircularProgress
                style={{ color: "#fff" }}
                className="btn__loader"
              />
            )}
          </button>
        </div>
      </div>
    </>
  );
};

export default NotificationsUpdate;