import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";

interface ConfirmationModalProps {
  onClose?: () => void;
  title?: string;
  isOpen?: boolean;
  status?: "success" | "cancel";
  loader?: boolean;
  confirmed?: () => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  onClose = () => {},
  title = "",
  isOpen = false,
  status = "success",
  loader = false,
  confirmed = () => {},
}) => {
  return (
    <div className="modal" style={{ display: isOpen ? "block" : "none" }}>
      <div className="Cancellation-modal-content relative">
        <button onClick={onClose} className="absolute job-pre-edit">
          <FontAwesomeIcon
            className="x-icon-admin-opr"
            icon={faX}
            style={{ color: "#ffffff" }}
          />
        </button>
        <div className="cancellation-envolpe-main">
          <div className="cancellation-envolpe">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </div>
        <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">
          {title}
        </h2>
        <div className="flex cancel-job justify-center gap-2 mt-4">
          <button
            onClick={onClose}
            className={`font-size-17px font-Poppins-Medium ${
              status === "cancel" ? "cancel-cond" : ""
            }`}
          >
            No
          </button>
          <button
            disabled={loader}
            onClick={confirmed}
            className={`font-size-17px font-Poppins-Medium ${
              status === "success" ? "success-cond" : ""
            }`}
          >
            Yes{" "}
            {loader && (
              <CircularProgress
                style={{ width: 16, height: 16, color: "#fff" }}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
