/**
 * JobsBoard Component
 * Manages job postings, including creation, viewing, and filtering of job posts.
 * Handles API calls for job retrieval and service filtering, along with modal handling for job creation.
 * File: JobsBoard.jsx
 * Author: Developer
 * Date: 24-04-24
 */

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import JobPreview from "../../components/AdminComponents/JobPreview";
import InReviewCards from '../../components/AllDashboardPagesComponents/InReviewCards';
import CreateJobsModal from "../../components/JobsComponents/CreateJobsModal";
import { useDispatch } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import { toast } from 'react-toastify';
import { Skeleton } from "@mui/material";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import qs from "qs";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import httpRequest from "../../config/Helpers/httpRequest";
import UnpaidModal from "../../components/Modal/UnpaidModal";


interface Service {
    _id: string;
    servicename?: string;
}

interface Job {
    _id: string;
    services: Service[];
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    state: string;
    postCode: string;
    repeatCycle: number;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    budget: string;
    description: string;
    allServices?: Service[];
    jobStatus?: string;
    createdAt?: string;
    // customInterval?: number; // Optional property
    numberOfApplicants?: number;
    [key: string]: any; // Additional properties
}

interface PostJob {
    _id?: string | undefined;
    services: Service[];
    contactNumber: string;
    streetAddress: string;
    suburb: string;
    state: string;
    postCode: string;
    repeatCycle: number;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    budget: string;
    description: string;
    allServices?: Service[];
    jobStatus?: string;
    createdAt?: string | Date | undefined;
    // customInterval: number;
}

interface QueryParams {
    status: string;
    page: number;
    pageSize: number;
    sort: string;
}
interface ApiResponse {
    documents: [];
    [key: string]: any;
}
function AdminPaidUsers() {
    const { state = {}, search = "" } = useLocation();

    const dispatch = useDispatch();

    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [jobsLoader, setJobsLoader] = useState<boolean>(false);
    const [createModalShow, setCreateModalShow] = useState<boolean>(state?.isOpen || false);
    const [successModal, setSuccessModal] = useState<boolean>(false);
    const [jobs, setJobs] = useState<any[]>([]);
    const [selectedJobs, setSelectedJobs] = useState<any>(null);
    const [UnpaidModalShow, setUnpaidModalShow] = useState<boolean>(false);
    const [bool, setBool] = useState<boolean>(false);

    const [postJob, setPostJob] = useState<PostJob>({
        _id: "",
        services: [], // Ensure this is an array of `Service` objects
        contactNumber: "",
        streetAddress: "",
        suburb: "",
        state: "",
        postCode: "",
        repeatCycle: 1,
        startDate: "",
        startTime: "",
        endDate: "",
        endTime: "",
        budget: "",
        description: "",
        // customInterval: 0,
    });
    const [queryParams, setQueryParams] = useState<QueryParams>({
        status: search === "opened" ? "active" : "pending", // if in url search query exists then component mounts on opened tab
        page: 1,
        pageSize: 6,
        sort: "desc"
    }); // query params for API

    // Functionality: Fetch jobs from the API based on query parameters
    const fetchJobs = async () => {
        setJobsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job?${qs.stringify(queryParams)}` });
        if (res) {
            setJobs(res?.documents)
            // let count = res?.jobs[0]?.metadata[0]?.total || 0;
            setTotalPages(res?.paginated?.totalPages || 1);
        } else {
            toast(err?.message, { type: "error" });
        }
        setJobsLoader(false);
    }



    useEffect(() => {
        (async () => {
            setIsLoader(true);
            const { res, err }: { res?: ApiResponse; err?: string } = await httpRequest({
                path: "/api/services/all",
            });

            console.log("API Response:", res); // Log the API response

            if (res && res.documents) {
                dispatch(setAllServices(res.documents)); // Directly dispatch documents without flattening for now
            } else {
                toast(err || "An error occurred", { type: "error" });
            }

            setIsLoader(false);
        })();
    }, [dispatch]);

    // Trigger job fetching whenever query parameters or boolean state change

    useEffect(() => {
        fetchJobs();
    }, [queryParams, bool]);

    return (
        <div>
            {createModalShow &&
                <CreateJobsModal
                    hideModal={setCreateModalShow}
                    isEdit={false}
                    postJob={postJob}
                    setPostJob={setPostJob}
                    setSuccessModal={() => { setSuccessModal(true); setBool(prev => !prev) }}
                />
            }
            {successModal &&
                <div className="modal">
                    <div className="approved-modal-content relative">
                        <button
                            onClick={() => setSuccessModal(false)}
                            className="absolute job-pre-edit"
                        >
                            <FontAwesomeIcon
                                className="x-icon-admin-opr"
                                icon={faX}
                                style={{ color: "#ffffff" }}
                            />
                        </button>
                        {/* <img className='briefcase-icon mt-4 mb-2' src='https://solidificatiosolution.backslashwebs.com/briefcase-icon.png' /> */}
                        <img className='briefcase-icon mt-4 mb-2' src='https://solidificatiosolution.backslashwebs.com/solidificatiosolution%2Fbriefcase-icon.png' />
                        <h2 className="font-size-25px font-Poppins-SemiBold text-[#0d0d0d]">Job post request has been sent
                        </h2>
                        <p className="text-[#2F2F31] my-3 font-size-15px font-Poppins-Regular text-[#0d0d0d]">Your request to post this job has been sent for the review,
                            it will reflect in your account in 24 - 48 hours once admin
                            approve this request.</p>
                        <div className="flex approved-modal decline-modal justify-center gap-2">
                        </div>
                    </div>
                </div>
            }
            <div className="">
                <HeaderGlobal />
                <div className="container-1480 h-3vw">
                    <div className="Admin-main-operator-parent">
                        <div className="px-4 lg:px-0">
                            <h2 className="font-size-36px font-Poppins-Medium">
                                My jobs
                            </h2>

                            <div className="Main-AdminUsers Main-Admin-paidUsers operator-main-admin mt-2 lg:mt-6 flex-col-reverse lg:flex-row flex justify-between lg:items-center items-start">
                                <div className="active-jobs-tabs-2">
                                    <button className={queryParams.status === "pending" ? ' font-size-15px font-Poppins-Regular jobs-apply-btn active' : 'inactive-job-tab font-size-15px font-Poppins-Regular'} onClick={() => setQueryParams({ ...queryParams, page: 1, status: "pending" })}> In review</button>
                                    <button className={queryParams.status === "active" ? ' font-size-15px font-Poppins-Regular jobs-apply-btn active' : 'inactive-job-tab font-size-15px font-Poppins-Regular'} onClick={() => setQueryParams({ ...queryParams, page: 1, status: "active" })}> Opened</button>
                                </div>
                                <div>
                                    <button
                                        onClick={() => setCreateModalShow(true)}
                                        className="jobs-apply-btn jobs-apply-btn-2 flex flex-row items-center justify-center gap-2 font-size-15px font-Poppins-Regular w-[10vw]"><span className="icon-plus-handler"><FontAwesomeIcon className="post-job-icon" icon={faPlus} /> </span> Post a job</button>
                                </div>
                            </div>
                            <div className="mt-8">
                                <div className="admin-job-req">
                                    <div className="theme-bg-lightblue admin-support-worker-boxes">
                                        <div className="admin-Cleaning-main">
                                            {jobsLoader ?
                                                <>
                                                    {Array.from({ length: 6 }, (_, index) => (
                                                        <Skeleton key={index} variant="rounded" width={215} height={225} />
                                                    ))}
                                                </>
                                                :
                                                jobs && jobs.length > 0 ?
                                                    jobs.map((item, index) => (
                                                        <InReviewCards
                                                            index={index}
                                                            item={item}
                                                            setSelectedJobs={setSelectedJobs}
                                                        />
                                                    ))
                                                    :
                                                    <p>{queryParams.status === "Pending" ? 'No results found' : 'No results found'}</p>
                                            }
                                        </div>
                                        <PaginationComponet
                                            currentPage={queryParams.page}
                                            total={totalPages}
                                            setCurrentPage={(e) => setQueryParams({ ...queryParams, page: e })}
                                        />
                                    </div>
                                    <div className="admin-job-preview">
                                        <h2 className="font-Poppins-Medium font-size-25px">
                                            Job preview
                                        </h2>
                                        {selectedJobs ?
                                            <JobPreview item={selectedJobs} />
                                            :
                                            <div style={{
                                                backgroundColor: '#eeeeee',
                                                width: '100%',
                                                marginTop: 10,
                                                borderRadius: 10,
                                                height: 500
                                            }}>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminPaidUsers;