// SecondaryProtectedRoutes.js
import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../Helpers/helpers";

interface AdminProtectedRoutes {
    isAdmin: boolean;
    setIsAdmin: React.Dispatch<React.SetStateAction<boolean>>; // Correct type for setState function
    token: string;
}

const AdminProtectedRoutes: React.FC<AdminProtectedRoutes> = (
    {
        isAdmin, setIsAdmin, token
    }
) => {

    const [isLoader, setIsLoader] = useState<boolean>(true);
    useEffect(() => {
        const checkAuthentication = async () => {
            let value = getCookie();
            if (value) {
                const payload = value.split(".");
                const payloadValue = JSON.parse(atob(payload[1]));

                if (payloadValue.role === "superAdmin" || payloadValue.role === "admin" || payloadValue.role === "editor") {
                    setIsAdmin(true);
                }
                else {
                    setIsAdmin(false);
                }
            }
            else {
                setIsAdmin(false); // Ensure isAdmin is false if there's no token
            }
            setIsLoader(false);
        };
        checkAuthentication();
    }, []);

    if (isLoader) {
        return  <div></div>;
    }

    return (token && isAdmin) ? (
        <Outlet />
    ) : (
        <Navigate to="/admin-login" />
    );
}

export default AdminProtectedRoutes;