import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
interface SelectSmallProps {
    currentValue?: string;
    setRatingSort?: (value: string) => void;
}

export default function SelectSmall({ currentValue = "", setRatingSort = () => { } }: SelectSmallProps) {

    const handleChange = (event: SelectChangeEvent) => {
        const value = event.target.value as string;  // Cast the value to string
        setRatingSort(value);
    };
    // const handleChange = (event) => {
    //   let value = event.target.value;
    //   if (value === 'All') {
    //     setRatingSort("");
    //   }
    //   else {
    //     setRatingSort(value);
    //   }
    // };

    return (
        <div className="availability">
            <FormControl sx={{ m: 1, minWidth: 120, backgoundColor: '#ffffff', fontFamily: 'Poppins' }} size="small">
                <InputLabel id="demo-select-small-label flex flex-row items-center" sx={{ fontFamily: 'Poppins', fontSize: '15px', display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div className="filter-placeholder" style={{ display: "flex", flexDirection: "row", alignItems: "center", opacity: 1, color: "#00443f" }}>

                        <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} />
                    </div>
                    <span className='font-Poppins-Regular'>

                        Rating
                    </span>

                </InputLabel>
                <Select
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={currentValue}
                    label="Rating"
                    onChange={handleChange}
                >
                    <MenuItem value="" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>All</MenuItem>
                    <MenuItem value="1" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>One star</MenuItem>
                    <MenuItem value="2" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Two star</MenuItem>
                    <MenuItem value="3" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Three star</MenuItem>
                    <MenuItem value="4" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Four star</MenuItem>
                    <MenuItem value="5" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Five star</MenuItem>

                </Select>
            </FormControl>
        </div>
    );
};