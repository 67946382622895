import React, { useState } from "react";
import edit from "../../../assets/img/awesome-edit.svg";
import { useSelector } from "react-redux";
import { truncateString } from "../../../config/Helpers/helpers";
import { Link } from "react-router-dom";
import { getRole } from "../../../config/Helpers/helpers";
import { RootState } from "../../../config/Store/store";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
import { useLocation } from "react-router-dom";
function Aboutme() {
  const location = useLocation()
  const decoded = getRole();
  const userId = decoded?.userId;
  // const { userId = "" } = getRole();
  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);

  const [isFullText, setIsFullText] = useState(false); // toogle state see more or see less
  return (
    <div className="shadow-lg rounded-none bg-white lg:rounded-xl mb-2 pb-3 px-6 ">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
          About me
        </h4>
        {userId === ShowData?._id && (
          <Link to="/about-me-edit" className="mt-5 edit-pro-icon ">
            <img src={edit} alt="" />
          </Link>
        )}
      </div>
      <div>
        {isFullText ? (
          <>
            <p className="font-size-17px theme-grey-type  font-Poppins-Regular">
              {ShowData?.profileDescription || ""}
            </p>{" "}
            {/* full text */}
          </>
        ) : (
          <>
            <p className="font-size-17px theme-grey-type  font-Poppins-Regular">
              {truncateString(ShowData?.profileDescription, 400)}
            </p>{" "}
            {/* truncate text */}
          </>
        )}
        {ShowData?.profileDescription?.length > 400 && (
          <div className="flex justify-end">
            <button
              onClick={() => setIsFullText(!isFullText)}
              className="font-size-15px theme-grey-type  font-Poppins-Regular"
            >
              ...see {isFullText ? "less" : "more"}
            </button>
          </div>
        )}

        {" "}
        {/* see more button */}
      </div>
    </div>
  );
}

export default Aboutme;
