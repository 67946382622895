import React, { useState, useEffect } from 'react';
import closeIcon from "../../assets/img/closeIcon.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faThumbsUp } from "@fortawesome/free-solid-svg-icons";

import axios from 'axios';
import { toast } from "react-toastify";
import { getCookie } from '../../config/Helpers/helpers';
import { CircularProgress } from '@mui/material';
interface AllLikesModalProps {
    show: boolean | { id: string; total: number };
    setShow: (value: boolean | { id: string; total: number }) => void;
}
interface Paginated {
    totalItems: number;
    [key: string]: any;
}
interface Documents {
    firstName?: string;
    lastName?: string;
    [key: string]: any;
}

interface ApiResponse {
    documents: Documents[]; // Update to an array type
    paginated: Paginated;
}
interface Data {
    documents?: Documents;

    firstName?: string;
    lastName?: string;
    [key: string]: any;

}

const AllLikesModal: React.FC<AllLikesModalProps> = ({ show = false, setShow }) => {

    let token = getCookie(); // Retrieve token from cookie
    let config = { // API configuration object header
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    const [isLoader, setIsLoader] = useState(false); // for API loader
    const [data, setData] = useState<Documents[]>([]); // Correct type to represent an array of 'data' objects
    const [totalLikes, setTotalLikes] = useState(0); // for total likes

    // Functionality: API call for get all likes for post
    const fetchAllLikes = async () => {
        setIsLoader(true); // loader show
        try {
            if (typeof show === 'object') {  // Type guard to ensure 'show' is an object
                const response = await axios.get<ApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/social/likes/${show.id}?page=1&limit=${show.total}`, config);
                console.log(response, "responseresponseresponse")
                setData(response?.data?.documents || []); // set total likes data
                setTotalLikes(response?.data?.paginated?.totalItems || 0); // set total likes count

            }

        } catch (err: any) {
            toast(err?.response?.data || err?.response?.message || "ERROR", {
                type: "error",
            }); // error set
        }
        setIsLoader(false); // loader hide
    };

    useEffect(() => {
        if (show) {
            fetchAllLikes();
        } else {
            setData([]);
            setTotalLikes(0);
        }
    }, [show]);
    return (
        <div className="ovrlayModal modal" style={{ display: show ? "block" : "none" }}>
            <div className="createpost-modal absolute z-20">
                <button
                    className="absolute -top-5 font-size-24px"
                    style={{ right: "-10px" }}
                    onClick={() => setShow(false)}
                >
                    <img src={closeIcon} alt="" />
                </button>{" "}
                {/* close post modal */}
                <div>
                    <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green  gap-2 capitalize mb-3">
                        Likes
                    </h4>
                    <p className="font-size-16px font-Poppins-Medium all-like-count ">
                        All {totalLikes || 0}
                    </p>
                    <hr color="#707070" className="" />
                </div>
                <div className="create-post-text-area mt-4 mb-4">
                    {isLoader &&
                        <div className="text-center">
                            <CircularProgress style={{ width: 20, height: 20, color: "#004540" }} />
                        </div>
                    }
                    {data.map((item: Data, index: number) => (
                        <div key={index} className="flex gap-2 items-center mb-3 items-center">
                            <div className="crete-feed-user-img flex relative">
                                <img
                                    width={100}
                                    loading="lazy"
                                    src={`${item?.profileImageUrl}`}
                                    // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                                    alt="user"
                                    className="rounded-full"
                                    style={{ width: 50, height: 50 }}
                                />
                                <div className="total-like flex gap-1 absolute  items-center" style={{ right: "-5px", bottom: "4px" }}>
                                    <span style={{ backgroundColor: "#2196F3" }}>
                                        <FontAwesomeIcon
                                            color="white"
                                            icon={faThumbsUp}
                                            flip="horizontal"
                                        />
                                    </span>
                                </div>
                            </div>{" "}
                            {/* post creator image */}
                            <div className="sidebar-gobal-user-name ">
                                <div className="flex gap-1 items-center">
                                    <p className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                                        {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                    </p>{/* user name */}
                                    <span className="font-size-12px theme-grey-type  font-Poppins-Regular capitalize">
                                        {item?.roleCategory || ""}
                                    </span>{/* user role */}
                                </div>
                                {/* <p className="font-size-15px theme-grey-type font-Poppins-Regular">{item?.profileSummary || ""}</p>user summary */}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AllLikesModal;