/**
 * AppliedJobs Component
 * 
 * This component manages and displays the jobs applied for by a user, allowing them to view details,
 * filter results, and switch between different job statuses (Active, Completed, Cancelled).
 * 
 * File Name: AppliedJobs.js
 * Author: Developer
 * Date: 05-02-34
 */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import AppliedJobsLeftBar from "../../components/JobsComponents/AppliedJobsLeftBar";
import AppliedJobDetails from "../../components/JobsComponents/AppliedJobDetails";
import qs from "qs";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import httpRequest from "../../config/Helpers/httpRequest";
import { RootState } from "../../config/Store/store";
/**
 * AppliedJobs Component
 * 
 * Manages and displays the list of jobs applied for by the user.
 */


interface QueryParams {
  page: number;
  limit: number;
  sort: string;
  date: string;
  location: string;
  serviceId: string[];
}
interface ApiResponseServices {
  documents: [];
}
interface TabOption {
  title: string;
  key: string;
  dotClass: string;
}
interface paginated {
  totalPages: number;
}
interface ApiResponse {
  documents: [];
  paginated: paginated;
}

const AppliedJobs: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<string>("onboard"); // for active tab
  const [totalPages, setTotalPages] = useState<number>(1); // total pages all pages count
  const [jobsLoader, setJobsLoader] = useState<boolean>(false); // for fetch jobs loader
  const [jobsDisplay, setJobsDisplay] = useState<any[]>([]); // for jobs Display
  const [selectedJobs, setSelectedJobs] = useState<any | false>(false);

  // Options for job status tabs
  let tabOptions = [
    { title: "Active", key: "onboard", dotClass: "dot-act" },
    { title: "Completed", key: "completed", dotClass: "dot-comp" },
    { title: "Cancelled", key: "cancelled", dotClass: "dot-cancel" },
  ];

  // query parameters for fetching jobs
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 10,
    sort: "desc",
    date: "",
    location: "",
    serviceId: [],
  }); // query params for API


  // Functionality: API call for get all applied Jobs Provider
  const fetchJobs = async () => {
    setJobsLoader(true);
    const queryString = qs.stringify(queryParams, { arrayFormat: "comma" });
    let path = "";

    if (activeTab === "onboard") {
      path = `/api/job/manage/contractor/onboard?${queryString}`;
    } else if (activeTab === "completed") {
      path = `/api/job/manage/contractor/completed?${queryString}`;
    } else {
      path = `/api/job/manage/contractor/cancelled?${queryString}`;
    }
    const { res, err } = await httpRequest<ApiResponse>({
      path: path
    })
    if (res) {
      setJobsDisplay((prevJobs) => [
        ...prevJobs,
        ...res?.documents,
      ]);
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" })
    }
    setJobsLoader(false);
  };

  /**
 * handlePageChange
 * 
 * Handles page changes for job pagination.
 * 
 * @param {number} e - The new page number.
 */
  const handlePageChange = (e: number) => {
    setQueryParams({
      ...queryParams,
      page: e,
    });
  }; // page change

  /**
  * changeTab
  * 
  * Changes the active tab and resets the job display and query parameters.
  * 
  * @param {string} e - The key of the new active tab.
  */
  const changeTab = (e: string) => {
    setJobsDisplay([]);
    setActiveTab(e);
    setQueryParams({
      ...queryParams,
      page: 1,
      date: "",
      location: "",
      serviceId: [],
    });
  }; 
  // / tab change

  /**
  * handleFilterSubmit
  * 
  * Handles the submission of filter criteria and updates the job list.
  * 
  * @param {object} obj - The filter criteria.
  */
  const handleFilterSubmit = (obj: { date: string; location: string; service: string[] }) => {
    setJobsDisplay([]);
    let { date, location, service } = obj;
    setQueryParams({
      ...queryParams,
      page: 1,
      date: date,
      location: location,
      serviceId: service,
    });
  }; // filter submit

  /**
  * handleFilterClear
  * 
  * Clears the filter criteria and resets the job list.
  */
  const handleFilterClear = () => {
    setJobsDisplay([]);
    setQueryParams({
      ...queryParams,
      page: 1,
      date: "",
      location: "",
      serviceId: [],
    });
  }; // filter clear

  // Functionality: all available services for filtering jobs when the component mounts
  useEffect(() => {
    (async () => {
      const { res, err }: { res?: ApiResponseServices; err?: string } = await httpRequest({
        path: "/api/services/all",
      });


      if (res && res?.documents) {
        dispatch(setAllServices(res?.documents) || []); // Directly dispatch documents without flattening for now
      } else {
        toast(err || "An error occurred", { type: "error" });
      }

    })();
  }, []);


  useEffect(() => {
    if (jobsDisplay.length > 0) {
      setSelectedJobs(jobsDisplay[0]);
    } else {
      setSelectedJobs(false);
    }
  }, [jobsDisplay]); // set selected jobs for right side

  useEffect(() => {
    fetchJobs();
  }, [queryParams, activeTab]); // also active tab change then api call

  return (
    <div className="">
      <HeaderGlobal />
      <div className="container-1480 h-3vw">
        <div className="Admin-main-operator-parent">
          <div className="lg:px-0 px-4">
            <h2 className="font-size-36px font-Poppins-Medium">Manage bookings
            </h2>
            <div className="flex flex-col  items-end lg:flex-row justify-end gap-2">
              <Link
                to="/manage-jobs"
                className="inactive-job-tab inactive-job-tab-res font-size-15px font-Poppins-Regular  h-[4.9vh] flex justify-center items-center"
              >
                Posted jobs
              </Link>
              <button
                disabled
                className="font-size-15px inactive-job-tab-res font-Poppins-Regular jobs-apply-btn active  h-[4.9vh] flex justify-center items-center"
              >
                {" "}
                Applied jobs
              </button>
            </div>
            <div className="mt-8">
              <div>
                <div className="admin-active-job-handler">
                  <div>
                    <div className="active-jobs-tabs">
                      {tabOptions.map((item) => (
                        <button
                          key={item.key}
                          className={
                            activeTab === item.key
                              ? "font-size-15px font-Poppins-Regular active"
                              : " font-size-15px font-Poppins-Regular"
                          }
                          onClick={() => changeTab(item.key)}
                        >
                          <span className={item.dotClass}></span> {item.title}
                        </button>
                      ))}
                    </div>
                    <div className="active-jobs-content">
                      <div className="admin-job-active">
                        <AppliedJobsLeftBar
                          loader={jobsLoader}
                          data={jobsDisplay}
                          activeTab={activeTab}
                          currentPage={queryParams.page}
                          totalPages={totalPages}
                          pageChange={handlePageChange}
                          setSelectedJobs={setSelectedJobs}
                          selectedJobs={selectedJobs || []}
                          clearFilter={handleFilterClear}
                          submit={handleFilterSubmit}
                        />
                        <div className="">
                          <AppliedJobDetails
                            loader={jobsLoader}
                            item={selectedJobs}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppliedJobs;