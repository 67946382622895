import React, { useState, useEffect } from "react";
import closeIcon from "../../assets/img/closeIcon.png";
import aibtn from "../../assets/img/aibtn.svg";

import axios from "axios";
import { getCookie } from "../../config/Helpers/helpers";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import RewriteWithAi from "../AskAi/RewriteWithAi";
import ReWriteLoader from "../AskAi/ReWriteLoader";
interface CreateDiscussionModalProps {
  isOpen: boolean;
  onClose: () => void;
  isEdit?: {
    dId: string;
    title: string;
    description: string;
  };
}
interface ApiResponse {
  message: string;
  [key: string]: any;
}

const CreateDiscussionModal: React.FC<CreateDiscussionModalProps> = ({
  isOpen = false,
  onClose = () => { },
  isEdit = undefined,
}) => {
  const navigate = useNavigate();
  let token = getCookie(); // Retrieve token from cookie
  let config = {
    // API configuration object header
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const [title, setTitle] = useState<string>(""); // for discussion title
  const [description, setDescription] = useState<string>(""); // for discussion description
  const [isLoader, setIsLoader] = useState<boolean>(false); // for API loader
  const [aILoader, setAiLoader] = useState<boolean>(false);

  // Functionality: API call for create New discussion
  const handleSubmit = async () => {
    if (!title) {
      // check title is not empty
      toast("Please enter discussion title", { type: "error" });
    } else if (!description) {
      // check description is not empty
      toast("Please enter discussion description", { type: "error" });
    } else {
      setIsLoader(true); // show loader
      try {
        let body;
        let method: "post" | "put" = isEdit ? "put" : "post";
        if (isEdit) {
          body = { title, description, discussionId: isEdit?.dId };
        } else {
          body = { title, description };
        }
        const response = await axios[method]<ApiResponse>(
          `${process.env.REACT_APP_BASE_URL}/api/discussion/${isEdit ? "update" : "create"
          }`,
          body,
          config
        );
        if (response?.status) {
          setTitle(""); // empty title
          setDescription(""); // empty description
          onClose(); // close modal after success

          toast(response?.data?.message || "SUCCESS", { type: "success" }); // success message display
          if (isEdit) {
            navigate("/discussions");
          }
        }
      } catch (err: any) {
        toast(err?.response?.data || err?.response?.message || "ERROR", {
          type: "error",
        }); // if error then display the error
      }
      setIsLoader(false); // hide loader
    }
  };

  useEffect(() => {
    if (isEdit) {
      setTitle(isEdit?.title);
      setDescription(isEdit?.description);
    }
  }, [isEdit]);

  return (
    <div
      className="ovrlayModal modal"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="createpost-modal absolute z-20">
        <button
          disabled={isLoader}
          className="absolute -top-5 font-size-24px"
          style={{ right: "-10px" }}
          onClick={onClose}
        >
          <img src={closeIcon} alt="" />
        </button>
        {/* close discussion modal */}
        <div>
          <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green text-center gap-2 ">
            {isEdit ? "Edit" : "New"} discussion
          </h4>
          <hr color="#707070" className="mt-3" />
        </div>
        <div className="create-post-text-area ">
          {/* title input */}
          <input
            type="text"
            placeholder="Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className=" create-disscussion-text mt-5"
          />
          {/* description input */}
          {aILoader ? (
            <ReWriteLoader />
          ) : (
            <textarea
              className="w-full create-disscussion-text"
              placeholder="Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          )}
          {/* post captions */}
        </div>
        <hr color="#707070" className="mt-3" />
        <div className="relative">
          <div className="flex justify-between  mt-3 gap-2">
            <RewriteWithAi
              text={description}
              setText={setDescription}
              setLoader={setAiLoader}
            />
            {/* craete button */}
            <button
              disabled={isLoader}
              onClick={handleSubmit}
              className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
              style={{ color: "white" }}
            >
              {isEdit ? "Update" : "Post"}{" "}
              {isLoader && (
                <CircularProgress
                  style={{ width: 14, height: 14, color: "#fff" }}
                />
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateDiscussionModal;
