/**
 * DisputeJobs Component
 * Manages disputed jobs for both providers and contractors, allowing users to view
 * and filter through disputed jobs based on their status (pending, ongoing, resolved).
 *
 * File: DisputeJobs.jsx
 * Author: Developer
 * Date: 06/04/24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";

import { getRole } from "../../config/Helpers/helpers";
import SideBar from "../../components/JobsComponents/DisputeUserSide/SideBar";
import JobDetail from "../../components/JobsComponents/DisputeUserSide/JobDetail";
import { useDispatch } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import { toast } from "react-toastify";
import qs from "qs";
import httpRequest from "../../config/Helpers/httpRequest";


interface QueryParams {
  page: number;
  limit: number;
  sort: string;
  date: string;
  location: string;
  serviceId: string[];
  status: string;
}
interface ApiResponseServices {
  documents: [];
}
interface TabOption {
  title: string;
  key: string;
  dotClass: string;
}
interface paginated {
  totalPages: number;
}
interface ApiResponse {
  documents: [];
  paginated: paginated;
}

const DisputeJobs: React.FC = () => {
  const dispatch = useDispatch();
  let tabOptions = [
    { title: "Pending", key: "pending", dotClass: "dot-pending-dis" },
    { title: "Ongoing", key: "ongoing", dotClass: "dot-comp" },
    { title: "Resolved", key: "resolved", dotClass: "dot-completed" },
  ];
  const decoded = getRole();
  const ROLE = decoded?.role;
  // const ROLE = getRole()?.UserRole || false;

  const [jobSide, setJobSide] = useState("creator"); // for porvider creater or contractor
  const [totalPages, setTotalPages] = useState(1); // total pages all pages count
  const [selectedJobs, setSelectedJobs] = useState<any | false>(false);

  const [queryParams, setQueryParams] = useState<QueryParams>({
    status: "pending",
    page: 1,
    limit: 10,
    sort: "desc",
    date: "",
    location: "",
    serviceId: []
  }); // query params for API

  const [isLoader, setIsLoader] = useState(false);
  const [data, setData] = useState([]);

  // Function to change job side (creator or contractor)
  const changeJobSide = (e: string) => {
    setData([]);
    setJobSide(e);
    setQueryParams({
      ...queryParams,
      status: "pending",
      page: 1,
      date: "",
      location: "",
      serviceId: []
    });
  }; // tab job side

  // Function to change tab (pending, ongoing, resolved)
  const changeTab = (e: string) => {
    setData([]);
    setQueryParams({
      ...queryParams,
      status: e,
      page: 1,
      date: "",
      location: "",
      serviceId: []
    });
  }; // tab change

  // Function to handle page change
  const handlePageChange = (e: number) => {
    setQueryParams({
      ...queryParams,
      page: e
    });
  }; // page change

  // Function to clear filters
  const handleFilterClear = () => {
    setData([]);
    setQueryParams({
      ...queryParams,
      page: 1,
      date: "",
      location: "",
      serviceId: []
    });
  }; // filter clear

  // Function to submit filters
  const handleFilterSubmit = (obj: { date: string; location: string; service: string[] }) => {
    setData([]);
    let { date, location, service } = obj;
    setQueryParams({
      ...queryParams,
      page: 1,
      date: date,
      location: location,
      serviceId: service
    });
  }; // filter submit

  // Functionality: API call disputed jobs for contractor & creator
  const fetchJobs = async () => {
    setIsLoader(true);
    const queryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job/dispute?jobSide=${jobSide}&${queryString}` });
    // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job/dispute` });
    if (res) {
      // setData(prevJobs => [...prevJobs, ...res?.documents]);
      setData((prevJobs) => [...prevJobs, ...res?.documents]);
      // let count = res?.paginated?.totalPages || 1;
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" })
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (data.length > 0) {
      setSelectedJobs(data[0]);
    }
    else {
      setSelectedJobs(false);
    }
  }, [data]); // set selected jobs for right side

  // Functionality: API call get all services for filter
  useEffect(() => {
    (async () => {
      const { res, err }: { res?: ApiResponseServices; err?: string } = await httpRequest({
        path: "/api/services/all",
      });


      if (res && res?.documents) {
        dispatch(setAllServices(res?.documents) || []); // Directly dispatch documents without flattening for now
      } else {
        toast(err || "An error occurred", { type: "error" });
      }

    })();
  }, []);


  useEffect(() => {
    fetchJobs();
  }, [queryParams, jobSide]); // also active tab change then api call

  return (
    <div className="">
      <HeaderGlobal />
      <div className="container-1480 h-3vw">
        <div className=" Admin-main-operator-parent">
          <div className="">
            <h2 className="font-size-36px font-Poppins-Medium">Disputed jobs</h2>
            <div className="flex justify-between">
            </div>
            {ROLE === "provider" &&
              <div className=" flex flex-row justify-end gap-2">
                <button onClick={() => changeJobSide("creator")} className={`${jobSide === "creator" ? "font-size-15px font-Poppins-Regular jobs-apply-btn active" : "inactive-job-tab font-size-15px font-Poppins-Regular active h-[4.9vh] flex justify-center items-center"}`}>Posted jobs</button>
                <button onClick={() => changeJobSide("contractor")} className={`${jobSide === "contractor" ? "font-size-15px font-Poppins-Regular jobs-apply-btn active" : "inactive-job-tab font-size-15px font-Poppins-Regular active   h-[4.9vh] flex justify-center items-center"}`}>Applied jobs</button>
              </div>
            }
            <div className="mt-8">
              <div className="">
                <div className="admin-active-job-handler">
                  <div>
                    <div className="active-jobs-tabs">
                      {tabOptions.map(item => (
                        <button key={item.key} className={queryParams.status === item.key ? 'font-size-15px font-Poppins-Regular active' : ' font-size-15px font-Poppins-Regular'} onClick={() => changeTab(item.key)}><span className={item.dotClass}></span> {item.title}</button>
                      ))}
                    </div>
                    <div className="active-jobs-content">
                      <div className=" admin-job-active">
                        <SideBar
                          loader={isLoader}
                          data={data}
                          activeTab={queryParams.status}
                          currentPage={queryParams.page}
                          totalPages={totalPages}
                          pageChange={handlePageChange}
                          setSelectedJobs={setSelectedJobs}
                          selectedJobs={selectedJobs || []}
                          clearFilter={handleFilterClear}
                          submit={handleFilterSubmit}
                        />
                        <div className="">
                          <JobDetail
                            loader={isLoader}
                            item={selectedJobs}
                            side={jobSide}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputeJobs;