import { createSlice } from "@reduxjs/toolkit";

const newsFeedSlice = createSlice({
    name: "NEWS_FEED",
    initialState: {
        isLoader: false,
        isError: false,
        allPosts: [],
    },
    reducers: {
        resetAllPosts: (state) => {
            state.allPosts = []; // reset all posts
        },
        setAllPosts: (state, { payload }) => {
            state.allPosts = [...state.allPosts, ...payload]; // concat previous & new array
        },
        createNewPost: (state, { payload }) => {
            state.allPosts = [payload, ...state.allPosts]; // add top on create new post
        },
        toogleLikedPost: (state, { payload = {} }) => { // toogle like status
            let { postId, status } = payload;
            let updateAllPosts = state.allPosts.map(newObj => {
                if (newObj?._id === postId) {
                    return {
                        ...newObj,
                        reactionStatus: status, // status change
                        allReacts: status ? newObj?.allReacts + 1 : newObj?.allReacts - 1, // update reaction count
                    };
                }
                return newObj;
            });
            state.allPosts = updateAllPosts;
        },
       

        /**
         * Updates the comment count for a specific post within the state.
         * Increments the 'allComments' count of the post identified by 'payload'
         * in the 'allPosts' array of the state.
         *
         * @param {Object} state - The current state object containing allPosts array.
         * @param {Object} payload - The identifier (typically _id) of the post to update.
         */
        updateCommentCounter: (state, { payload }) => {
            // Create a new list of posts with updated comment count
            let newList = state.allPosts.map(newObj => {
                if (newObj?._id === payload) {
                    return {
                        ...newObj,
                        allComments: newObj?.allComments + 1,
                    }
                }
                return newObj;
            });

            // Update the state with the new list of posts
            state.allPosts = newList;
        },
        removePostById: (state, { payload }) => {
            
            state.allPosts = state.allPosts.filter(post => post._id !== payload);
            console.log("payload", state.allPosts)
        },
    },
});

export const { resetAllPosts, setAllPosts, createNewPost, toogleLikedPost, updateCommentCounter,removePostById } = newsFeedSlice.actions;
export default newsFeedSlice.reducer;