import React from "react";

function PageNotFound() {
  return (
    <div className="not-found">
      <h1 className="font-Poppins-Medium">404 page not found</h1>
      <a href={`${process.env.REACT_APP_BASE_WEBSITE}`} className="">
        Go to home
      </a>
    </div>
  );
}

export default PageNotFound;
