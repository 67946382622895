/**
 * ActivityLog Component
 * Displays user activity logs for various options such as posts, discussions, profile, etc.
 * Allows pagination and filters based on activity types.
 *
 * File: ActivityLog.jsx
 * Author: Developer
 * Date: 15/05/24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import DefaultProfileImage from "../../../assets/img/default_profile_image.png";

import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import qs from "qs";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import httpRequest from "../../../config/Helpers/httpRequest";
import moment from "moment";
interface IActivityLogProps { }

interface IActivity {
  log: string;
  createdAt: string;
}

interface IPersonalData {
  profileImageUrl: string;

  firstName: string;
  lastName: string;


}

interface IUserProfileState {
  userProfile: {
    personalData: IPersonalData;
  };
}

interface IApiResponse {
  documents: IActivity[];
  paginated: {
    totalItems?: number;
    totalPages: number;
  };
}
const ActivityLog: React.FC<IActivityLogProps> = () => {
  const { personalData } = useSelector((store: IUserProfileState) => store.userProfile);

  const activityAllOptions = ["posts", "discussions", "profile", "jobs", "connections", "messages", "search", "subscriptions"];

  const [isLoader, setIsLoader] = useState<boolean>(false); // loader
  const [allActivity, setAllActivity] = useState<IActivity[]>([]); // array of activity logs
  const [totalPage, setTotalPages] = useState<number>(1); // total pages for pagination
  const [queryParams, setQueryParams] = useState<{ q: string; page: number; pageSize: number }>({
    q: "posts",
    page: 1,
    pageSize: 10,
  }); // query parameters for API

  // Functionality: fetch user activity log
  const fetchActivityLog = async () => {
    setIsLoader(true);
    const queryString = qs.stringify(queryParams);

    const url = `/api/activity-log/${queryParams.q}?page=${queryParams.page}&limit=${queryParams.pageSize}`;

    const { res, err } = await httpRequest<IApiResponse>({ path: url });
    if (res) {
      setAllActivity(res?.documents || []);
      let count = res?.paginated?.totalPages || 1;

      setTotalPages(count);
    } else {
      toast(err?.message, { type: "error" })
    }
    setIsLoader(false);
  };

  useEffect(() => {
    fetchActivityLog();
  }, [queryParams]) // also query change then call fetchActivityLog functions

  return (
    <>
      {isLoader &&
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      }
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex ">
        <SidebarGobal />
        <div className="w-full">
          <div className="remove-btn">
            <PreviewProfile title="Activity log" />
          </div>
          <div className="shadow-lg bg-white rounded-lg mb-2 py-3 px-6 ">
            <div className="flex gap-2 flex-wrap justify-center">
              {activityAllOptions.map(item => (
                <button onClick={() => setQueryParams(prev => ({ ...prev, page: 1, q: item }))} key={item} className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn feeds-btn-2  flex items-center gap-1 hover:text-white hover:bg-[#00443F] capitalize ${queryParams.q === item ? "is-active-log" : ""}`}>
                  {item}
                </button>
              ))}
            </div>

            <div className="mt-4 text-center font-size-18px theme-color-green font-Poppins-SemiBold ">
              {allActivity && allActivity.length > 0 ?
                allActivity.map((item, index) => (
                  <div key={index} className="flex items-center justify-between gap-2 w-full mt-2">
                    <div className="flex items-center gap-2">
                      {/* <img
                        className="connection-except-img"
                        src={`${process.env.REACT_APP_BASE_URL}${personalData?.profileImageUrl}`}
                        onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                          e.currentTarget.src = DefaultprofileImageUrl;
                        }}
                        alt=""
                      /> */}
                      <img
                        src={`${personalData?.profileImageUrl}?t=${Date.now()}`}
                        // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${personalData?.profileImageUrl}?t=${Date.now()}`}
                        onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                        alt=""
                        className="rounded-full connection-except-img"
                      />
                      <div className="flex gap-2">
                        <span className="font-size-18px theme-color-green font-Poppins-SemiBold capitalize">
                          {`${personalData?.firstName || ""} ${personalData?.lastName || ""}`}
                        </span>
                        <span className="font-size-18px text-zinc-500 font-Poppins-Medium">
                          {item?.log || ""}
                        </span>
                      </div>
                    </div>
                    <span className="font-size-18px text-zinc-500 font-Poppins-Medium">
                      {moment(item.createdAt).format("DD-MM-YYYY") || "-"}
                    </span>
                  </div>
                ))
                : 'No activity'
              }
            </div>
            <PaginationComponet total={totalPage} setCurrentPage={(e) => setQueryParams({ ...queryParams, page: e })} currentPage={queryParams.page} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivityLog;