import React, { useState, useRef, useEffect, MouseEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsis, faLink } from '@fortawesome/free-solid-svg-icons';
import ReportOptions from './ReportOptions';
import { getRole, handleCopyLinkPost } from '../../config/Helpers/helpers';
import PostDelete from './PostDelete';
import ReportICon from "../../assets/img/report-icon.svg"
import { useLocation } from 'react-router-dom';

interface CommentOnReportProps {
    id?: string | false;
    category?: string;
    type?: string;
    userIdComment?: string;
    postId?: any;
}

const CommentOnReport: React.FC<CommentOnReportProps> = ({
    id = false,
    category = "post",
    type = "comment",
    userIdComment = "",
    postId = ""
}) => {
    const location = useLocation();
    const discussionPage = location.pathname.includes("discussion");
    const decoded = getRole();


    const userId = decoded?.userId;
    const postMenus = useRef<HTMLDivElement | null>(null);
    const [feedControlMenu, setFeedControlMenu] = useState<string | false>(""); // for post menu
    const [reportModal, setReportModal] = useState<string | false>(false);
    const [deletePost, setDeletePost] = useState<string | false>(false);
    useEffect(() => {
        setFeedControlMenu("");
    }, [id])

    const handleDeletePost = (id: string | false) => {
        setDeletePost(id);
    };
    const handleClickOutside = (event: MouseEvent) => {
        if (postMenus.current && !postMenus.current?.contains(event.target as Node)) {
            setFeedControlMenu("");
        }
    }; // click the outside of post menu, menu box is hide

    useEffect(() => {
        const handleClickOutsideEventListener = handleClickOutside as unknown as EventListener;

        // Add event listener for 'mousedown' events
        document.addEventListener("mousedown", handleClickOutsideEventListener);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideEventListener);
        };
    }, []); // for the refercen of post menu

    return (
        <div>
            <FontAwesomeIcon
                className="cursor-pointer"
                icon={faEllipsis}
                onClick={() => setFeedControlMenu(id)}
            />





            {feedControlMenu === id ? (
                <div
                    className="shadow-lg bg-white rounded-xl absolute right-0 top-5 w-[14vw]"
                    // style={{ minWidth: 100 }}
                    ref={postMenus}
                >
                    <ul className="py-3 ">
                        { }
                        {/* {userIdComment === userId ? (

                            <>
                                <li
                                    className="font-size-15px theme-grey-type  font-Poppins-SemiBold flex gap-1 items-center"
                                >
                                    <button
                                        onClick={() => handleDeletePost(id)}
                                    >
                                        <FontAwesomeIcon icon={faFlagCheckered} /> Delete comment
                                    </button>
                                </li>
                            </>
                        )
                            :
                            ( */}
                        <li
                            className="font-size-15px theme-grey-type  font-Poppins-SemiBold flex gap-1 items-center   py-1 "
                        >
                            {userIdComment !== userId ? (
                                <>
                                    <div className='flex flex-col gap-2'>

                                        {!discussionPage &&
                                            <button onClick={() => handleCopyLinkPost(postId)} className="flex gap-1 items-center hover-dash-btn px-3">
                                                <FontAwesomeIcon icon={faLink} />

                                                <span>Copy link to comment</span>
                                            </button>
                                        }
                                        <button
                                            className='flex flex-row items-center gap-1 hover-dash-btn px-3'
                                            onClick={() => setReportModal(id)}
                                        >
                                            <img src={ReportICon} alt="report-icon" />

                                            Report Comment
                                        </button>


                                    </div>
                                </>
                            )
                                :
                                (
                                    <>
                                        <div className='flex flex-col gap-2'>
                                            {!discussionPage &&

                                                <button onClick={() => handleCopyLinkPost(postId)} className="flex gap-1 items-center hover-dash-btn px-3">
                                                    <FontAwesomeIcon icon={faLink} />

                                                    <span>Copy link to comment</span>
                                                </button>
                                            }
                                            <button
                                                className='flex flex-row items-center gap-1 hover-dash-btn px-3'

                                                onClick={() => handleDeletePost(id)}
                                            >
                                                <img src={ReportICon} alt="report-icon" />


                                                Delete comment
                                            </button>

                                        </div>
                                    </>
                                )}
                        </li>
                        {/* ) */}

                        {/* } */}

                    </ul>
                </div>
            )
                :
                (
                    null
                )

            }
            {deletePost && (
                <PostDelete
                    id={deletePost}  // Convert to boolean
                    onClose={() => setDeletePost(false)}
                    type="post"
                    category="comment"
                />
            )}
            {reportModal &&
                <ReportOptions
                    id={reportModal}
                    onClose={() => setReportModal(false)}
                    type={type}
                    category={category}
                />
            }
        </div>
    );
};

export default CommentOnReport;