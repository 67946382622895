/**
 * ManageJobsTabs Component
 *
 * This component manages tabs for displaying and managing different job statuses (Active, Completed, Cancelled).
 * It includes functionality to fetch jobs based on tab selection, apply filters, and display job details.
 *
 * @file ManageJobsTabs.js
 * @component ManageJobsTabs
 * @description Renders tabs for Active, Completed, and Cancelled jobs. Manages job data display,
 *              pagination, filters, and job details display on the right side.
 *
 * @author Developer
 * @date 05-02-34
 */

import React, { useState, useEffect } from "react";
import MangeJobsLeftBar from "../JobsComponents/MangeJobsLeftBar";
import ActiveSpecificJobs from "../AllDashboardPagesComponents/ActiveSpecificJobs";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import qs from "qs";
import httpRequest from "../../config/Helpers/httpRequest";


interface QueryParams {
  page: number;
  limit: number;
  sort: string;
  date: string;
  location: string;
  serviceId: string[];
}
interface ApiResponseServices {
  documents: [];
}
interface TabOption {
  title: string;
  key: string;
  dotClass: string;
}
interface paginated {
  totalPages: number;
}
interface ApiResponse {
  documents: [];
  paginated: paginated;
}
const ManageJobsTabs: React.FC = () => {

  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState<string>("onboard"); // for active tab
  const [totalPages, setTotalPages] = useState<number>(1); // total pages all pages count
  const [jobsLoader, setJobsLoader] = useState<boolean>(false); // for fetch jobs loader
  const [jobsDisplay, setJobsDisplay] = useState<any[]>([]); // for jobs Display
  const [selectedJobs, setSelectedJobs] = useState<any | false>(false);

  const tabOptions: TabOption[] = [
    { title: "Active", key: "onboard", dotClass: "dot-act" },
    { title: "Completed", key: "completed", dotClass: "dot-comp" },
    { title: "Cancelled", key: "cancelled", dotClass: "dot-cancel" },
  ];

  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 10,
    sort: "desc",
    date: "",
    location: "",
    serviceId: [],
  }); // query params for API

  // Functionality: API call encompasses all jobs where applicants have been hired, including those onboarded, completed, and cancelled by the job creator/poster.
  const fetchJobs = async () => {
    setJobsLoader(true);
    const queryString = qs.stringify(queryParams, { arrayFormat: "comma" });

    let path = "";

    if (activeTab === "onboard") {
      path = `/api/job/manage/creator/onboard?${queryString}`;
    } else if (activeTab === "completed") {
      path = `/api/job/manage/creator/completed?${queryString}`;
    } else {
      path = `/api/job/manage/creator/cancelled?${queryString}`;
    }
    const { res, err } = await httpRequest<ApiResponse>({
      // path: `/api/manage/Job/creator/${activeTab}?${queryString}`,
      path: path
    });
    if (res) {
      setJobsDisplay((prevJobs) => [
        ...prevJobs,
        ...res?.documents,
      ]);
      // setJobsDisplay(res?.documents);
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" });
    }
    setJobsLoader(false);
  };

  const handlePageChange = (e: number) => {
    setQueryParams({
      ...queryParams,
      page: e,
    });
  }; // page change

  const changeTab = (e: string) => {
    setJobsDisplay([]);
    setActiveTab(e);
    setQueryParams({
      ...queryParams,
      page: 1,
      date: "",
      location: "",
      serviceId: [],
    });
  }; // tab change


  const handleFilterSubmit = (obj: { date: string; location: string; service: string[] }) => {
    setJobsDisplay([]);
    let { date, location, service } = obj;
    setQueryParams({
      ...queryParams,
      page: 1,
      date: date,
      location: location,
      serviceId: service,
    });
  }; // filter submit

  const handleFilterClear = () => {
    setJobsDisplay([]);
    setQueryParams({
      ...queryParams,
      page: 1,
      date: "",
      location: "",
      serviceId: [],
    });
  }; // filter clear

  // Functionality: API call get all services for filter

  useEffect(() => {
    (async () => {
      const { res, err }: { res?: ApiResponseServices; err?: any } = await httpRequest({
        path: "/api/services/all",
      });


      if (res && res?.documents) {
        dispatch(setAllServices(res?.documents) || []); // Directly dispatch documents without flattening for now
      } else {
        toast(err?.message, { type: "error" });
      }

    })();
  }, []);





  useEffect(() => {
    if (jobsDisplay.length > 0) {
      setSelectedJobs(jobsDisplay[0]);
    } else {
      setSelectedJobs(false);
    }
  }, [jobsDisplay]); // set selected jobs for right side

  useEffect(() => {
    fetchJobs();
  }, [queryParams, activeTab]); // also active tab change then api call

  return (
    <>
      <div>
        <div className="active-jobs-tabs">
          {tabOptions.map((item) => (
            <button
              key={item.key}
              className={
                activeTab === item.key
                  ? "font-size-15px font-Poppins-Regular active"
                  : " font-size-15px font-Poppins-Regular"
              }
              onClick={() => changeTab(item.key)}
            >
              <span className={item.dotClass}></span> {item.title}
            </button>
          ))}
        </div>
        <div className="active-jobs-content">
          <div className=" admin-job-active">
            <MangeJobsLeftBar
              loader={jobsLoader}
              data={jobsDisplay}
              activeTab={activeTab}
              currentPage={queryParams.page}
              totalPages={totalPages}
              pageChange={handlePageChange}
              setSelectedJobs={setSelectedJobs}
              selectedJobs={selectedJobs || []}
              clearFilter={handleFilterClear}
              submit={handleFilterSubmit}
            />
            <div className="">
              <ActiveSpecificJobs
                loader={jobsLoader}
                item={selectedJobs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ManageJobsTabs;
