import React from 'react'
import GreenTick from "../../../assets/img/greentick.svg"
interface FreemuimPremuimProps {
    onClose: () => void; // This defines the onClose prop as a function with no parameters and no return value
}

const FreemuimPremuim: React.FC<FreemuimPremuimProps> = ({ onClose }) => {
    const Freemium: string[] = [
        "Can connect with up to 20 users per month",
        "Can send up to 10 messages per month to any unique user.",
        "Can view up to 50 profiles per month.",
        "Can compare up to 5 profiles per day.",
        "Can post up to 3 jobs per month. ",
        "Can apply to up to 5 jobs per month. ",
        "Access to basic scheduling features. ",
        "Can see who viewed their profile, limited to 5 profiles per month.",

    ];

    const Premium: string[] = [
        "Unlimited connections per month. ",
        "Unlimited messages to any unique user.",
        "Unlimited profile views.",
        "Unlimited profile comparisons.",
        "Unlimited job postings.",
        "Unlimited job applications.",
        "Access to advanced scheduling features.",
        "View all profile viewers with detailed analytics.",

    ];
    return (
        <div className='mb-5 mt-2 grid gap-5 w-3/4  justify-center premuimfremuim' >

            <div className="rounded-s-md bg-white">
                <div className='bg-[#00645D] flex justify-center items-center h-[6vh] rounded-t-md' >
                    <h2 className='font-size-25px font-Poppins-SemiBold text-[#ffffff] capitalize'>Freemium</h2>
                </div>
                <div className='bg-white px-5'>

                    {Freemium.map((content, index) => (
                        <div key={index} className='pt-5 flex gap-2 items-center h-14'>
                            <img src={GreenTick} alt="Green Tick" className='w-5' />
                            <span className='font-size-18px font-Poppins-Medium text-black'>{content}</span>
                        </div>
                    ))}

                    <div className='flex gap-3 mt-7 justify-center' >
                        {/* <button className="theme-bg-green  font-Poppins-Medium border-2  font-size-16px px-5 py-1 inline-table rounded-3xl  w-fit text-white" > Monthly </button> */}
                        {/* <button className="theme-bg-green  font-Poppins-Medium border-2  font-size-16px px-5 py-1 inline-table rounded-3xl  w-fit text-white" >Yearly</button> */}
                        <button className="bg-[#dbdbdb]  font-Poppins-Medium border-2  font-size-16px px-5 py-1 inline-table rounded-3xl  w-fit text-white" >Current plan</button>

                    </div>

                    <div className='py-4'>
                        <p className='font-size-15px font-Poppins-Medium text-center'>*Terms & conditions apply</p>
                    </div>
                </div>



            </div>


            <div className="rounded-s-md bg-white">
                <div className='bg-[#00645D] flex justify-center items-center h-[6vh] rounded-t-md' >
                    <h2 className='font-size-25px font-Poppins-SemiBold text-[#ffffff] capitalize'>Freemium</h2>
                </div>
                <div className='bg-white px-5'>

                    {Premium.map((content, index) => (
                        <div key={index} className='pt-5 flex gap-2 items-center h-14'>
                            <img src={GreenTick} alt="Green Tick" className='w-5' />
                            <span className='font-size-18px font-Poppins-Medium text-black'>{content}</span>
                        </div>
                    ))}

                    <div className='flex gap-3 mt-7 justify-center' >
                        <button
                            onClick={onClose}
                            className="theme-bg-green  font-Poppins-Medium border-2  font-size-16px px-5 py-1 inline-table rounded-3xl  w-fit text-white" > Monthly </button>
                        <button
                            onClick={onClose}
                            className="theme-bg-green  font-Poppins-Medium border-2  font-size-16px px-5 py-1 inline-table rounded-3xl  w-fit text-white" >Yearly</button>

                    </div>

                    <div className='py-4'>
                        <p className='font-size-15px font-Poppins-Medium text-center'>*Terms & conditions apply</p>
                    </div>
                </div>



            </div>

        </div>
    )
}

export default FreemuimPremuim