import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";

interface LogOutResponse {
  success: boolean;
  // Add other fields if necessary
}

interface ErrorResponse {
  message: string;
  status: number;
  // Add other fields if necessary
}

interface AuthState {
  token: string;
  role: string;
  towFactorEmail: string;
  twoFactorDuration: string | number;
  admin: boolean;
  premium: PlanData | null;
  upgrade: UserData | null;
  keepMeloggedIn: boolean;
}

// Initial state
const initialState: AuthState = {
  token: '',
  role: '',
  towFactorEmail: '',
  twoFactorDuration: '',
  admin: false,
  premium: null,
  upgrade: null,
  keepMeloggedIn: false
};
interface UserData {
  email: string | undefined;
  firstName: string | undefined;
  lastName: string | undefined;
  address: string | undefined;
  suburb: string | undefined;
  state: string | undefined;
  postCode: string | undefined;
  phonenumber: string | undefined;
  role: string | undefined;
  password: string | undefined;
}

interface PlanData {
  id: string;
  subscriptionName: string;
  price: number;
  save: string | number;
}
// Thunk action
export const logOutUser = createAsyncThunk<LogOutResponse, string, { rejectValue: ErrorResponse }>(
  "LOGOUTUSER",
  async (token: string, { rejectWithValue }) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/user/logout`, config);
      return response.data as LogOutResponse;
    } catch (err: any) {
      if (err.response) {
        const errorResponse: ErrorResponse = {
          message: err.response.data.message || 'Unknown error',
          status: err.response.status
        };
        return rejectWithValue(errorResponse);
      }
      const errorResponse: ErrorResponse = {
        message: err.message,
        status: 500
      };
      return rejectWithValue(errorResponse);
    }
  }
);

// Slice
const authSlice = createSlice({
  name: "AUTH",
  initialState,
  reducers: {
    storeToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    removeToken: (state) => {
      state.token = '';
    },
    storeRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
    storeTwoFactor: (
      state,
      action: PayloadAction<{ time: number | string | undefined; email: string; admin: boolean; keepMeloggedIn: boolean }>
    ) => {
      const { time, email, admin, keepMeloggedIn } = action.payload;
      state.towFactorEmail = email;
      state.twoFactorDuration = time || '';
      state.admin = admin;
      state.keepMeloggedIn = keepMeloggedIn;
    },
    updateTwoFactorTime: (state) => {
      if (typeof state.twoFactorDuration === 'number') {
        state.twoFactorDuration--;
      }
    },
    removeTwoFactor: (state) => {
      state.towFactorEmail = '';
      state.twoFactorDuration = '';
      state.admin = false;
      state.keepMeloggedIn = false;
    },
   
    premiumPlans: (state, action: PayloadAction<PlanData>) => {
      state.premium = action.payload;
    },
    resetPremiumPlans: (state) => {
      state.premium = null;
    },
    setUpgradeUser: (state, action: PayloadAction<UserData>) => {
      state.upgrade = action.payload;
    },
    removeUpgradeUser: (state) => {
      state.upgrade = null;
    },
  },
});

export const {
  storeToken,
  removeToken,
  storeRole,
  storeTwoFactor,
  removeTwoFactor,
  updateTwoFactorTime,
  premiumPlans,
  resetPremiumPlans,
  setUpgradeUser,
  removeUpgradeUser
} = authSlice.actions;
export default authSlice.reducer;
