import React, { useState, useEffect, ChangeEvent } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import InputField from "../../../components/AllDashboardPagesComponents/InputField";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { getRole } from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import httpRequest from "../../../config/Helpers/httpRequest";
import moment from "moment";
import { RootState } from "../../../config/Store/store";

interface EducationItem {
  course: string;
  institution: string;
  startDate: string;
  endDate: string;
  [key: string]: any;
}

interface ApiResponse {
  message: string;
}

const emptyObj: EducationItem = {
  course: "",
  institution: "",
  startDate: "",
  endDate: "",
};

function EducationTrainingEdit() {
  const dispatch = useDispatch();
  const decoded = getRole();
  const UserRole = decoded?.role;

  const [dateOfBirth, setDateOfBirth] = useState<string>("");
  const { personalData } = useSelector((store: RootState) => store.userProfile);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [myEducations, setMyEducations] = useState<EducationItem[]>([]);

  const addEntry = () => {
    setMyEducations([...myEducations, emptyObj]);
  };

  const removeEntry = (index: number) => {
    const updatedEducations = [...myEducations];
    updatedEducations.splice(index, 1);
    setMyEducations(updatedEducations);
  };

  const handleChange = (key: keyof EducationItem, index: number, e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const updatedEducations = myEducations.map((education, i) => {
      if (i === index) {
        const updatedEducation = { ...education, [key]: value };
        if (key === "startDate" && moment(value).isBefore(moment(dateOfBirth))) {
          toast("Start date cannot be before your date of birth", { type: "error" });
          return education; // Return the original object if validation fails
        }
        return updatedEducation;
      }
      return education;
    });
    setMyEducations(updatedEducations);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const currentDate = moment(); // Get the current date once for comparison

    // Validate dates before processing the submission
    for (const edu of myEducations) {

      if (moment(edu.startDate).isBefore(moment(dateOfBirth))) {
        toast("Education dates cannot be before your date of birth", { type: "error" });
        return;
      }

      // Check if endDate is before startDate
      if (edu.endDate && moment(edu.startDate).isAfter(moment(edu.endDate))) {
        toast("Start date cannot be greater than end date", { type: "error" });
        return;
      }

      // Check if endDate is greater than the current date
      if (edu.endDate && moment(edu.endDate).isAfter(currentDate)) {
        toast("End date cannot be greater than the current date", { type: "error" });
        return;
      }
      if (moment(edu.endDate).isSame(edu.startDate)) {
        toast("Start date and end date cannot be the same.", { type: "error" });
        return;
      }

    }

    // Remove the `_id` field from each education item
    const sanitizedEducations = myEducations.map(({ course, institution, startDate, endDate }) => ({
      course,
      institution,
      startDate,
      endDate,
    }));
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      method: "put",
      path: `/api/provider/education`,
      params: { education: sanitizedEducations },
    });
    if (res) {
      dispatch(updateProfile());
      toast(res.message || "SUCCESS", { type: "success" });
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (personalData) {
      const education = personalData?.education || [];
      setDateOfBirth(personalData?.dateofbirth || "");
      if (education.length === 0) {
        setMyEducations([emptyObj]);
      } else {
        setMyEducations(education);
      }
    }
  }, [personalData]);

  return (
    <>
      <HeaderGlobal />
      {/* <div className="container-1480 flex justify-between h-3vw gap-3"> */}
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title="Education and training" />

          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6">
            <div>
              <p className="font-size-15px font-Poppins-Regular theme-color-green flex mb-3">
                If you have undergone education and training including degrees,
                courses, diplomas and other qualifications, enter them below.
              </p>
              <form onSubmit={handleSubmit}>
                {myEducations.length > 0 ? (
                  myEducations.map((item, index) => (
                    <div key={index}>
                      <InputField
                        type="text"
                        name="institution"
                        labelTop="Institution"
                        value={item.institution}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange("institution", index, e)
                        }
                        required
                      />
                      <InputField
                        type="text"
                        name="course"
                        labelTop="Degree/course"
                        value={item.course}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange("course", index, e)
                        }
                        required
                      />
                      <div className="grid half-grid">
                        <InputField
                          type="date"
                          name="startDate"
                          labelTop="Start date"
                          value={item.startDate?.split("T")[0] || ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange("startDate", index, e)
                          }
                          required
                        />
                        <InputField
                          type="date"
                          name="endDate"
                          labelTop="End date"
                          value={item.endDate?.split("T")[0] || ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange("endDate", index, e)
                          }
                          required
                        />
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="button"
                          onClick={() => removeEntry(index)}
                          className="flex gap-2 items-center font-size-15px font-Poppins-Medium theme-color-green"
                        >
                          <FontAwesomeIcon icon={faTrash} color="" /> Delete
                          education
                        </button>
                      </div>
                      <hr className="mt-4"/>
                    </div>
                  ))
                ) : (
                  <p>No education</p>
                )}
                <button
                  disabled={isLoader}
                  type="submit"
                  className="mt-3 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                  style={{ color: "white" }}
                >
                  Save and continue{" "}
                  {isLoader && (
                    <CircularProgress
                      style={{ width: 14, height: 14, color: "#fff" }}
                    />
                  )}
                </button>
              </form>
              <button
                onClick={addEntry}
                className="font-size-15px font-Poppins-Medium theme-color-green flex mt-5"
              >
                + Add another course/degree
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EducationTrainingEdit;
