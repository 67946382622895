import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faLocationDot,
    faVenus,
    faClock,
    faCalendar,
    faEnvelope
} from "@fortawesome/free-solid-svg-icons";

import { Skeleton } from "@mui/material";
import { convertTo12Hour, getTimeDifference, jobStartDate, jobStartDateWithTime } from "../../config/Helpers/helpers";
import { toast } from 'react-toastify';
import AdminShowNumbers from '../AdminComponents/AdminShowNumbers';
import ShowNumber from "../AllDashboardPagesComponents/ShowNumber";
import JobRequestAndHistory from "./JobRequestAndHistory";
import JobCompleteRequest from "./JobCompleteRequest";
import AddCommentJob from "./AddCommentJob";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../config/Helpers/httpRequest";
import moment from "moment";
interface AdminJobDetailsProps {
    loader: boolean;
    item: any; // Replace 'any' with the specific type if available
    activeTab: string;
    setBool: React.Dispatch<React.SetStateAction<boolean>>;
    setActiveTab: React.Dispatch<React.SetStateAction<string>>;
}
interface ApiResponse {
    message: string;
}
// Define additional types if possible
interface UserDetails {
    firstName: string;
    lastName: string;
    profileImageUrl: string;
    roleCategory?: string;
    [key: string]: any;

}

interface JobDetails {
    _id: string;
    createdAt: string;
    jobCreatorDetails: UserDetails;
    jobCreatorRole: string;
    applicantDetails: UserDetails;
    allServices: Array<{ servicename: string }>;
    suburb: string;
    state: string;
    postCode: string;
    repeatCycle: number;
    startDate: string;
    endDate: string;
    description: string;
    budget: number;
    jobApplicationUpdateTime: string;
    jobDisputeId?: string;
    jobDisputeStatus?: string;
    [key: string]: any;
}
const AdminJobDetails: React.FC<AdminJobDetailsProps> = ({
    loader,
    item,
    activeTab,
    setBool,
    setActiveTab,
}) => {
    const location = useLocation();
    let { pathname } = location;
    const naviagte = useNavigate();
    const [showNumber, setShowNumber] = useState<boolean>(false);
    const [logUpdated, setLogUpdated] = useState<boolean>(false);
    const [callModal, setCallModal] = useState<string | boolean>(false);
    const [commentModal, setCommentModal] = useState<boolean>(false);
    const checkPreferences = (user: any): string => {
        if (!user) return '-'; // Return an empty string if preferences are not provided

        const { preferencemale, preferencefemale, preferencenopet, preferencenonsmoker } = user;

        // Check for specific combinations and return the corresponding HTML string
        if (!preferencemale && !preferencefemale && !preferencenopet && !preferencenonsmoker) {
            return '-';
        } else {
            let result = '';

            if (preferencemale && preferencefemale) {
                result += 'Male & Female';
            } else if (preferencemale) {
                result += 'Only Male';
            } else if (preferencefemale) {
                result += 'Only Female';
            }

            if (preferencenopet) {
                if (result !== '') result += ', ';
                result += 'No Pets';
            }

            if (preferencenonsmoker) {
                if (result !== '') result += ', ';
                result += 'Non Smoker';
            }
            return result;
        }
    };

    const handleCallResponseSuccess = (msg: String) => {
        setLogUpdated(prev => !prev);
    };

    const [statusChangeLoader, setStatusChangeLoader] = useState<string | boolean>(false);

    // Functionality: API call for dispute status change like a (pending, ongoing & resolved)
    const disputeStatusChange = async (status: string) => {
        setStatusChangeLoader(status);
        // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/dispute/updateDispute/${item?.jobDisputeId}/${status}` });
        const { res, err } = await httpRequest<ApiResponse>({ method: "put", path: `/api/admin/job/dispute/update-status`, params: { disputeId: item?.jobDisputeId, status } });

        if (res) {
            naviagte("/disputes-job");
            toast(res?.message || "Success", { type: "success" });
        } else {
            toast(err?.message, { type: "error" });
        }
        setStatusChangeLoader(false);
    };

    return (
        <>
            <div className="admin-job-review-stat">
                {loader ?
                    <>
                        <Skeleton variant="rounded" className="w-full mb-3" height={330} />
                        <div className="mb-3 flex justify-between gap-1">
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                            <Skeleton variant="rounded" width={150} height={50} />
                        </div>
                    </>
                    :
                    <>
                        {item ?
                            <>
                                <div className="admin-preview-head flex items-center justify-end">
                                    <p className="font-Poppins-Medium font-size-12px text-[#ffffff] pr-2">Posted {getTimeDifference(item?.createdAt) || ""}</p>
                                </div>

                                <div className="admin-job-preview-main ">
                                    <div className="admin-preview-job">
                                        <img src={`${item?.jobCreatorDetails?.profileImageUrl}`} alt="" />
                                        {/* <img src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.jobCreatorDetails?.profileImageUrl}`} alt="" /> */}
                                        <div className="text-center jobee-detail">
                                            <h2 className="font-Poppins-Medium font-size-22px text-[#00443f]">                                                    {item?.service?.servicename}
                                            </h2>
                                            <p className="font-Poppins-SemiBold font-size-12px text-[#000000]">Posted by</p>
                                            <p className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">{`${item?.jobCreatorDetails?.firstName || ""} ${item?.jobCreatorDetails?.lastName || ""}`}</p>
                                            <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize-first-letter">{item?.jobCreatorRole === "provider" ? item?.jobCreatorDetails?.roleCategory : item?.jobCreatorRole}</p>

                                            <p className="font-Poppins-SemiBold font-size-12px text-[#000000]">Assigned to</p>
                                            <p className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">{`${item?.applicantDetails?.firstName || ""} ${item?.applicantDetails?.lastName || ""}`}</p>
                                            <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize-first-letter">{item?.jobCreatorRole === "provider" ? item?.applicantDetails?.roleCategory : item?.jobCreatorRole}</p>
                                        </div>
                                    </div>

                                    <div className="job-preview-content text-[#646464] flex items-center align-center ">

                                        <div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faLocationDot} />
                                                <p>{`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faVenus} />
                                                <p>{checkPreferences(item?.jobCreatorDetails)}</p>
                                            </div>
                                            <div className="mt-5 mb-5">
                                                <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                    <FontAwesomeIcon icon={faClock} />
                                                    <p>
                                                        {
                                                            item?.repeatCycle == 1 ? "One-off"
                                                                : item?.repeatCycle == 2 ? "Daily"
                                                                    : item?.repeatCycle == 3 ? "Weekly"
                                                                        : item?.repeatCycle == 4 ? "Monthly"
                                                                            : "Custom"
                                                        }
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>Start date: {moment(item?.startDate).format("DD-MM-YYYY") || ""}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>End date: {moment(item?.endDate).format("DD-MM-YYYY") || ""}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>Start time: {convertTo12Hour((item?.startTime || ""))}</p>
                                            </div>
                                            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                                                <FontAwesomeIcon icon={faCalendar} />
                                                <p>End time: {convertTo12Hour((item?.endTime || ""))}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="theme-grey-type job-preview-content">
                                        <div className="flex">
                                            <div className="">
                                                {/* {item?.allServices?.map((v, index) => ( */}
                                                <h2 className="font-Poppins-Medium text-black font-size-15px capitalize">
                                                    {item?.service?.servicename}
                                                </h2>
                                                {/* ))} */}
                                            </div>

                                        </div>
                                        <div className="">
                                            <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
                                                {item?.description || ""}
                                            </p>
                                        </div>
                                        <span style={{ color: "#004540", fontSize: 16 }}>
                                            {
                                                activeTab === "onboard" ?
                                                    // jobStartDate(item?.startDate) || ""
                                                    jobStartDateWithTime(item?.startDate || "", item?.startTime)
                                                    : activeTab === "completed" ?
                                                        `This job is completed ${getTimeDifference(item?.jobApplicationUpdateTime) || ""}`
                                                        : activeTab === "cancelled" ?
                                                            `This job is cancelled ${getTimeDifference(item?.jobApplicationUpdateTime) || ""}`
                                                            : ""}
                                        </span>
                                        <div className="flex">
                                            <div className="job-pre-perhour-adminside"><button className=" text-white font-Poppins-Regular font-size-14px">$ {item?.budget || 0}</button></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-center gap-4">
                                    {activeTab === "onboard"
                                        ?
                                        <>
                                            <button onClick={() => setShowNumber(true)} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Show number</button>

                                            <button onClick={() => setCommentModal(true)} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"><FontAwesomeIcon icon={faEnvelope} />Comment</button>

                                            <JobCompleteRequest
                                                jobId={item?._id}
                                                completedFucntion={() => handleCallResponseSuccess}
                                                btnClass={"text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"}
                                            />
                                        </>
                                        :
                                        <>
                                            <button onClick={() => setCallModal("applicant")} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Show Applicant Number</button>
                                            <button onClick={() => setCallModal("poster")} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Show Poster Number</button>
                                        </>
                                    }

                                    {pathname === "/disputes-job-detail" &&
                                        <>
                                            {item?.jobDisputeStatus !== "pending" &&
                                                <button onClick={() => disputeStatusChange("pending")} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Pending {statusChangeLoader === "pending" && <CircularProgress style={{ width: 18, height: 18, color: "#fff" }} />}</button>
                                            }
                                            {item?.jobDisputeStatus !== "ongoing" &&
                                                <button onClick={() => disputeStatusChange("ongoing")} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Ongoing {statusChangeLoader === "ongoing" && <CircularProgress style={{ width: 18, height: 18, color: "#fff" }} />}</button>
                                            }
                                            {item?.jobDisputeStatus !== "resolved" &&
                                                <button onClick={() => disputeStatusChange("resolved")} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Resolved {statusChangeLoader === "resolved" && <CircularProgress style={{ width: 18, height: 18, color: "#fff" }} />}</button>
                                            }
                                        </>
                                    }
                                </div>
                                <JobRequestAndHistory
                                    opponentData={""}
                                    jobId={item?._id}
                                    logUpdated={logUpdated}
                                    setLogUpdated={setLogUpdated}
                                />
                            </>
                            :
                            <p className="text-center font-size-15px font-Poppins-SemiBold theme-color-green">No jobs</p>
                        }
                    </>
                }

            </div >
            {showNumber &&
                <AdminShowNumbers
                    creator={item?.jobCreatorDetails}
                    applicant={item?.applicantDetails}
                    onClose={() => setShowNumber(false)}
                    jobId={item?._id}
                    successFunction={handleCallResponseSuccess}
                />}
            {callModal &&
                <ShowNumber
                    onClose={() => setCallModal(false)}
                    data={callModal === "applicant" ? item?.applicantDetails : item?.jobCreatorDetails}
                    jobId={item?._id}
                    successFunction={handleCallResponseSuccess}
                />
            }
            {commentModal &&
                <AddCommentJob
                    onClose={() => setCommentModal(false)}
                    jobId={item?._id}
                    successFunction={handleCallResponseSuccess}
                />
            }
        </>
    );
};

export default AdminJobDetails;