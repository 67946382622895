// /**
//  * Loginrecoveryverification Component
//  * 
//  * This component handles the verification of OTP for password recovery.
//  * Users can enter the OTP received via email to verify their identity.
//  * 
//  * File Name: Loginrecoveryverification.js
//  * Author: Developer
//  * Date: 2024-04-05
//  */

// import React, { useState, useEffect } from "react";
// import { useNavigate } from "react-router-dom";
// import VerificationInput from "react-verification-input";
// import "../../assets/css/loginpages.css";
// import "../../assets/css/loginpagesresponsive.css";
// import Header from "../../components/Header";

// import { useSelector, useDispatch } from "react-redux";
// import {
//   updateTwoFactorTime,
//   removeTwoFactor,
//   storeToken,
// } from "../../config/Store/Reducers/authSlice";
// import { CircularProgress } from "@mui/material";
// import { setCookie } from "../../config/Helpers/helpers";
// import httpRequest from "../../config/Helpers/httpRequest";
// import { toast } from "react-toastify";
// import { RootState } from "../../config/Store/store";

// /**
//  * Loginrecoveryverification Component
//  * 
//  * Renders the OTP verification page for recovering user accounts.
//  */
// function Loginrecoveryverification () {
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [remainingTime, setRemainingTime] = useState(""); // State to hold the remaining time
//   const [OTP, setOTP] = useState(""); // otp state
//   const [isLoader, setIsLoader] = useState(false);
//   const { towFactorEmail, twoFactorDuration, admin, keepMeloggedIn } =
//     useSelector((store: RootState) => store.auth);

//   // Functionality: API call to factor authentication / verify OTP
//   const handleSubmit = async () => {
//     if (OTP && OTP.length === 4) {
//       setIsLoader(true);
//       let requestBody;
//       if (admin) {
//         requestBody = {
//           username: towFactorEmail, // Assuming userName is a variable containing the user's name
//           otp: OTP,
//           keepMeloggedIn,
//         };
//       } else {
//         requestBody = {
//           email: towFactorEmail,
//           otp: OTP,
//         };
//       }
//       const { res, err } = await httpRequest({ method: "post", path: `/api/user/${admin ? "twoFactorAuthentication" : "verify-otp"}`, params: requestBody });

//       if (res) {
//         const { token } = res; // Extract token from response
//         if (!admin) {
//           navigate("/confirm-password", {
//             state: { email: towFactorEmail, otp: OTP },
//           });
//         }

//         if (typeof token === 'string') {
//           dispatch(storeToken(token));
//           setCookie(token); // set token in cookie
//           // Type assertion to ensure TypeScript knows token is a string
//           const payload = (token as string).split(".");
//           const payloadValue = JSON.parse(atob(payload[1]));

//           // navigate depends on role need review
//           if (payloadValue?.isSuperAdmin) {
//             navigate("/admin");
//           }
//           setTimeout(() => {
//             dispatch(removeTwoFactor());
//           }, 2000);
//         } else {
//           toast(err?.message, { type: "error" })
//         }
//         setIsLoader(false);
//       }
//     }
//   };

//   const formatTime = (expirationTimestamp: string | number | Date) => {
//     // Convert current timestamp to Date object
//     const currentTime = new Date().getTime(); // Get the current time in milliseconds

//     // Convert expiration timestamp to Date object
//     const expirationTime = new Date(expirationTimestamp).getTime(); // Get the expiration time in milliseconds

//     // Calculate remaining time in seconds
//     const remainingTimeInSeconds = Math.floor((expirationTime - currentTime) / 1000);

//     // Return or use the remaining time as needed

//     if (remainingTimeInSeconds <= 0) {
//       dispatch(removeTwoFactor());
//       return "00:00"; // If expired, return "00:00"
//     }

//     const minutes = Math.floor(remainingTimeInSeconds / 60) % 60; // Calculate remaining minutes
//     const seconds = remainingTimeInSeconds % 60; // Calculate remaining seconds
//     return `${minutes.toString().padStart(2, "0")}:${seconds
//       .toString()
//       .padStart(2, "0")}`; // Format as mm:ss
//   };

//   useEffect(() => {
//     const intervalId = setInterval(() => {
//       dispatch(updateTwoFactorTime());
//     }, 1000);

//     return () => clearInterval(intervalId);
//   }, []);

//   useEffect(() => {
//     if (twoFactorDuration) {
//       setRemainingTime(formatTime(twoFactorDuration)); // Update remaining time
//     } else {
//       navigate(-1);
//     }
//   }, [twoFactorDuration]);

//   return (
//     <div>
//       <section className="homebanner relative h-screen theme-bg-cream-white">
//         <Header />
//         <img
//           decoding="async"
//           className="w-full h-full absolute top-0 left-0 z-10 opacity-30 bg-texture-banner"
//           src={"https://edgie.backslashwebs.com/imgs/banner-bg-texture.png"}
//           alt=""
//         />
//         <div className="container-custom h-full relative z-20 flex justify-center items-center">
//           <div className=" home-banner-main-content-parent-padding theme-bg-green w-1/2 h-fit lg-dashboard">
//             <img
//               decoding="async"
//               className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 opacity-20 z-10 w-2/6	"
//               src={"https://edgie.backslashwebs.com/imgs/banner-bg-map.png"}
//               alt=""
//             />
//             <div className=" home-banner-main-content-inner-right flex flex-col login-recovery-main relative z-20">
//               <div className="flex flex-col gap-3">
//                 <div className="">
//                   <h4 className="font-Poppins-SemiBold font-size-login-36px text-white leading-none text-center lg-main-head ">
//                     <i>Verification</i>
//                   </h4>
//                 </div>
//                 <div className="verification-code">
//                   <VerificationInput
//                     length={4}
//                     validChars="0-9"
//                     placeholder="0"
//                     value={OTP}
//                     onChange={(e) => setOTP(e)}
//                   />

//                   <div className="flex items-center gap-1">
//                     <span className="font-size-login-16px font-Poppins-Regular text-white mt-2 inline-block">
//                       {remainingTime}
//                     </span>
//                     <button className="cursor-pointer font-size-login-16px font-Poppins-SemiBold text-white mt-2 inline-block">
//                       Resend code
//                     </button>
//                   </div>
//                 </div>
//               </div>

//               <div className="login-btn-home-banner-parent flex items-center justify-center">
//                 <button
//                   onClick={handleSubmit}
//                   className=" theme-bg-light-seagreen text-white border-radius-6px font-size-login-16px font-Poppins-SemiBold login-btn-home-banner input-btns-height-40px flex items-center justify-center"
//                   disabled={!OTP || isLoader}
//                 >
//                   Verify{" "}
//                   {isLoader && (
//                     <CircularProgress
//                       style={{ color: "#fff" }}
//                       className="btn__loader"
//                     />
//                   )}
//                 </button>
//               </div>
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// export default Loginrecoveryverification;

/**
 * Loginrecoveryverification Component
 * 
 * Renders the OTP verification page for recovering user accounts.
 */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import VerificationInput from "react-verification-input";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import Header from "../../components/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,

} from "@fortawesome/free-solid-svg-icons";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTwoFactorTime,
  removeTwoFactor,
  storeToken,
  storeTwoFactor
} from "../../config/Store/Reducers/authSlice";
import { CircularProgress } from "@mui/material";
import { setCookie } from "../../config/Helpers/helpers";
import httpRequest from "../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import { RootState } from "../../config/Store/store";
import { error } from "console";
interface AuthResponse {
  token: string;
  // Add other properties if needed
}
function Loginrecoveryverification() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [remainingTime, setRemainingTime] = useState("");
  const [OTP, setOTP] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [canResend, setCanResend] = useState(false);
  const { towFactorEmail, twoFactorDuration, admin, keepMeloggedIn } =
    useSelector((store: RootState) => store.auth);
  const emailForResend = towFactorEmail
  const handleSubmit = async () => {
    if (OTP && OTP.length === 4) {
      setIsLoader(true);
      let requestBody;
      if (admin) {
        requestBody = {
          userName: towFactorEmail,
          otp: OTP,
          // keepMeloggedIn,
        };
      } else {
        requestBody = {
          email: towFactorEmail,
          otp: OTP,
        };
      }
      const { res, err } = await httpRequest<AuthResponse>({ method: "post", path: `/api/${admin ? "operator/two-fa" : "user/verify-otp"}`, params: requestBody });

      if (res) {
        const { token } = res;
        if (!admin) {
          navigate("/confirm-password", {
            state: { email: towFactorEmail, otp: OTP },
          });
        }

        if (typeof token === 'string') {
          dispatch(storeToken(token));
          setCookie(token);
          const payload = (token as string).split(".");
          const payloadValue = JSON.parse(atob(payload[1]));
          console.log(payloadValue, "payloadValuepayloadValue")
          if (
            payloadValue?.role === "provider" ||
            payloadValue?.role === "participant"
          ) {
            // navigate("/feed");
            navigate("/feed");
          } else {
            navigate("/admin");
          }
          dispatch(removeTwoFactor());

          // setTimeout(() => {
          //   dispatch(removeTwoFactor());
          // }, 2000);
          setIsLoader(false);

        }
        setIsLoader(false);
      }
      else {
        toast(err?.message?.message, { type: "error" });

        console.log(err?.message?.message, "error")
        setIsLoader(false);
        if (err?.response?.status === 422) {
          toast(err?.message, { type: "error" });
          return
        }

      }
    };
  }

  // const handleResend = async () => {


  //   setIsLoader(true);
  //   const { res, err } = await httpRequest({ method: "post", path: "/api/user/send-otp", params: { email: emailForResend } });

  //   if (res) {
  //     const currentTimestamp = Date.now();
  //     const fiveMinutesInMilliseconds = 2 * 60 * 1000; // 1 minute in milliseconds
  //     const futureTimestamp = currentTimestamp + fiveMinutesInMilliseconds;
  //     dispatch(
  //       storeTwoFactor({
  //         time: futureTimestamp,
  //         email: emailForResend,
  //         admin: false,
  //         keepMeloggedIn: false,
  //       })
  //     );
  //     toast.success("OTP sent successfully!");
  //   } else {
  //     toast.error(`Error: ${err}`);
  //   }
  //   setIsLoader(false);
  // };
  const handleResend = async () => {
    setIsLoader(true);

    // Determine the path and request body based on the user's role
    const path = admin ? "/api/operator/send-otp" : "/api/user/send-otp";
    const params = admin
      ? { userName: emailForResend }  // Use userName key if admin
      : { email: emailForResend };     // Use email key if not admin

    const { res, err } = await httpRequest({ method: "post", path, params });

    if (res) {
      const currentTimestamp = Date.now();
      const fiveMinutesInMilliseconds = 2 * 60 * 1000; // 2 minutes in milliseconds
      const futureTimestamp = currentTimestamp + fiveMinutesInMilliseconds;
      dispatch(
        storeTwoFactor({
          time: futureTimestamp,
          email: emailForResend,
          admin, // retain the current admin state
          keepMeloggedIn: false,
        })
      );
      toast.success("OTP sent successfully!");
    } else {
      toast.error(`Error: ${err}`);
    }

    setIsLoader(false);
  };

  const formatTime = (expirationTimestamp: string | number | Date) => {
    const currentTime = new Date().getTime();
    const expirationTime = new Date(expirationTimestamp).getTime();
    const remainingTimeInSeconds = Math.floor((expirationTime - currentTime) / 1000);

    if (remainingTimeInSeconds <= 0) {
      setCanResend(true);
      // dispatch(removeTwoFactor());
      return "00:00";
    }

    const minutes = Math.floor(remainingTimeInSeconds / 60) % 60;
    const seconds = remainingTimeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(updateTwoFactorTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (twoFactorDuration) {
      setRemainingTime(formatTime(twoFactorDuration));
    } else {
      // navigate(-1);
    }
  }, [twoFactorDuration]);
  return (
    <div>
      <section className="login-page-parent new-reg-parent relative h-screen  overflow-clip">
        <Header />
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10  bg-texture-banner"
          src={"https://ndisync.backslashwebs.com/new-register.jpg"}

          alt=""
        />
        <div className="container-custom h-full relative z-20 flex flex-col justify-center items-center gap-14 reg-comp">



          <div className="w-full flex justify-center flex-col items-center">

            <h2 className="font-Poppins-Medium text-[#FFFCF1] font-size-35px mb-4">Verification</h2>


            <div className="login-box-body-parent login-box-body-parent-2 flex flex-col">
              <div className="flex flex-col gap-3">

                <div className="verification-code">
                  <VerificationInput
                    length={4}
                    validChars="0-9"
                    placeholder="0"
                    value={OTP}
                    onChange={(e) => setOTP(e)}
                  />
                  <div className="flex items-center gap-1 justify-center">
                    <span className="font-size-login-16px font-Poppins-Regular text-white mt-2 inline-block">
                      {remainingTime}
                    </span>
                    <button
                      onClick={handleResend}
                      className="cursor-pointer font-size-login-16px font-Poppins-SemiBold text-[#FFE175] mt-2 inline-block underline"
                      disabled={!canResend}
                    >
                      resend code
                    </button>
                  </div>
                </div>
              </div>
              <div className="login-btn-home-banner-parent flex items-center justify-center">
                <button
                  onClick={handleSubmit}
                  className="mt-5 w-full text-white font-size-login-18px font-Poppins-SemiBold login-page-btn flex items-center justify-center gap-2 border-radius-20px theme-bg-green-light"
                  disabled={!OTP || isLoader}
                >
                  Verify
                  <FontAwesomeIcon size="lg" icon={faArrowRight} />

                  {isLoader && (
                    <CircularProgress
                      style={{ color: "#fff" }}
                      className="btn__loader"
                    />
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loginrecoveryverification;
