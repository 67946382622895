import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SuccessModalProps {
  message: string;
  setIsModal: (value: boolean) => void;
  setMsg: (value: string) => void;
}

const SuccessModal: React.FC<SuccessModalProps> = ({
  message,
  setIsModal,
  setMsg,
}) => {
  const handleWindowSuccessClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if ((event.target as HTMLDivElement).className === "ovrlayModal") {
      setIsModal(false);
      setMsg("");
    }
  };

  return (
    <div>
      <div className="ovrlayModal modal" onClick={handleWindowSuccessClick}>
        <div className="DeleteModal absolute z-20">
          <div className="relative">
            <button
              className="absolute right-0 -top-5 edituser-close-icon font-size-24px"
              onClick={() => setIsModal(false)}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button>
            <h2 className="font-size-36px font-poppins-semibold text-center">
              Successfully
            </h2>
            <p className="text-center font-size-18px mt-4 delete-user-responsive">
              {message}
            </p>
            <div className="flex justify-center mt-5 delete-user-btn-responsive">
              <button
                className="border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn"
                onClick={() => setIsModal(false)}
              >
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessModal;
