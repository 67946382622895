/**
 * ChangePassword Component
 * Handles the functionality for changing the user's password with three inputs:
 * current password, new password, and confirm new password.
 *
 * File: ChangePassword.js
 * Author: Developer
 * Date: 06/04/24
 */

import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { validatePassword, getCookie, logOutWithOutApi } from "../../../config/Helpers/helpers";
import httpRequest from "../../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
interface UpdatePassword {
  oldPassword: string;
  newPassword: string;
}
interface ApiResponse {
  message: string;
}
const ChangePassword: React.FC = () => {
  // State variables for showing/hiding password fields
  const [currentPasswordShow, setCurrentPasswordShow] = useState<boolean>(false);
  const [newPasswordShow, setNewPasswordShow] = useState<boolean>(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState<boolean>(false);

  // State variables for form inputs and API responses
  const [isSuccess, setIsSuccess] = useState<string | boolean>(false);
  const [isError, setIsError] = useState<string | boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [cnfrmNew, setCnfrmNew] = useState<string>("");
  const [updatePassword, setUpdatePassword] = useState<UpdatePassword>({
    oldPassword: "",
    newPassword: "",
  });
  const navigate = useNavigate()
  // Functionality: API call to change the password
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      !cnfrmNew ||
      !updatePassword.oldPassword ||
      !updatePassword.newPassword
    ) {
      toast("All fields are required", { type: "error" });
    } else if (!validatePassword(updatePassword.newPassword)) {
      toast(
        "password should be atleast 8 characters long, 1 uppercase, 1 lowercase, 1 digits and 1 special character"
        , { type: "error" });
    } else if (updatePassword.oldPassword === updatePassword.newPassword) {
      toast("The new password cannot be the same as the old password.", { type: "error" });
    } else if (updatePassword.newPassword !== cnfrmNew) {
      toast("The new password and the confirmed password do not match.", { type: "error" });
    } else {
      setIsLoader(true); // show loader
      const { res, err } = await httpRequest<ApiResponse>({ method: "put", path: "/api/user/update-password", params: updatePassword });
      if (res) {
        // if Password Updated Successfully
        setIsSuccess(res?.message); // set success message
        setUpdatePassword({
          oldPassword: "",
          newPassword: "",
        }); // reset state
        setCnfrmNew(""); // reset state  
        logOutWithOutApi();
        toast(res?.message, { type: "success" });
        setTimeout(() => {
          navigate("/login-web");
        }, 4000);

      } else {
        console.log(err, "asdasdasdasdasdad")
        toast(err?.message, { type: "error" })
      }
      setIsLoader(false); // hide loader
    }
  };

  useEffect(() => {
    if (isError || isSuccess) {
      setTimeout(() => {
        setIsError(false);
        setIsSuccess(false);
      }, 5000);
    }
  }, [isError, isSuccess]); // Effect to clear success/error messages after 5 seconds

  return (
    <div>
      <div className="shadow-lg bg-white rounded-none lg:rounded-xl  flex flex-col gap-2 mb-2 py-3 px-6">
        <h3 className="font-size-20px font-Poppins-SemiBold theme-color-green mb-3">
          Change password
        </h3>
        <form onSubmit={handleSubmit} className="flex flex-col gap-2">
          {/* Input for current password */}
          <div className="flex flex-col gap-1">
            <label className="font-size-15px font-Poppins-Medium">
              Current password
            </label>
            <div className="relative custom-input">
              <input
                className="w-full"
                type={currentPasswordShow ? "text" : "password"}
                name="currentPass"
                value={updatePassword.oldPassword}
                onChange={(e) =>
                  setUpdatePassword({
                    ...updatePassword,
                    oldPassword: e.target.value,
                  })
                }
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                onClick={() => setCurrentPasswordShow(!currentPasswordShow)}
              >
                <FontAwesomeIcon
                  icon={currentPasswordShow ? faEye : faEyeSlash}
                />
              </button>
            </div>
          </div>
          {/* Input for new password */}
          <div className="flex flex-col gap-1">
            <label className="font-size-15px font-Poppins-Medium">
              New password
            </label>
            <div className="relative  custom-input">
              <input
                className="w-full"
                type={newPasswordShow ? "text" : "password"}
                name="newPass"
                value={updatePassword.newPassword}
                onChange={(e) =>
                  setUpdatePassword({
                    ...updatePassword,
                    newPassword: e.target.value,
                  })
                }
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                onClick={() => setNewPasswordShow(!newPasswordShow)}
              >
                <FontAwesomeIcon icon={newPasswordShow ? faEye : faEyeSlash} />
              </button>
            </div>
          </div>
          {/* Input for confirming new password */}
          <div className="flex flex-col gap-1">
            <label className="font-size-15px font-Poppins-Medium">
              Confirm password
            </label>
            <div className="relative  custom-input">
              <input
                className="w-full"
                type={confirmPasswordShow ? "text" : "password"}
                name="confirmPass"
                value={cnfrmNew}
                onChange={(e) => setCnfrmNew(e.target.value)}
              />
              <button
                type="button"
                className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
              >
                <FontAwesomeIcon
                  icon={confirmPasswordShow ? faEye : faEyeSlash}
                />
              </button>
            </div>
          </div>
          {/* Error and success messages */}
          {isError && <p style={{ color: "red" }}>{isError}</p>}
          {isSuccess && <p style={{ color: "green" }}>{isSuccess}</p>}
          <div>
            <button
              className="mt-3 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
              type="submit"
              disabled={isLoader}
              style={{ color: "white" }}
            >
              Update
              {isLoader && (
                <CircularProgress
                  style={{ color: "#fff" }}
                  className="btn__loader"
                />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
