import React from "react";
import { CircularProgress } from "@mui/material";
interface AdminDeleteModal {
  deleteModalClose: () => void;
  handleClose: (value: boolean) => void;
  setDeleteId: (value: boolean) => void;
  deleteFunction: () => void;
  loader: boolean
}
const AdminDeleteModal: React.FC<AdminDeleteModal> = ({ deleteModalClose, handleClose, setDeleteId, deleteFunction, loader }) => {
  return (
    <div>
      <div className="overlay">
        <div className="DeleteModal absolute z-20">
          <div className="relative">
            {/* <button
              className="absolute right-0 -top-5 edituser-close-icon font-size-24px"
              onClick={() => handleClose(false)}
            >
              <FontAwesomeIcon icon={faTimesCircle} />
            </button> */}
            <h2 className="font-size-36px font-Poppins-Medium text-center">
              Delete operator
            </h2>
            <p className="text-center font-size-18px mt-4 font-Poppins-Regular">
              Are you sure want to delete?
            </p>
            <div className="flex justify-center mt-10 delete-user-btn-responsive">
              <button
                className=" border-2 font-Poppins-Medium font-size-17px px-5 py-1 inline-table mr-5 rounded-3xl deleteModal-btn"
                onClick={deleteFunction}
              >
                Delete
                {loader && <CircularProgress style={{ color: '#004540' }} className="btn__loader" />}
              </button>
              <button
                className=" border-2 font-Poppins-Medium font-size-17px px-5 py-1 inline-table rounded-3xl deleteModal-btn"
                onClick={() => { handleClose(false); setDeleteId(false) }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* {isSucsModal && ()} */}
      {/* <SuccessModal
        sucsMsg={sucsMsg}
        setSucsMsg={setSucsMsg}
        setIsSucsModal={setIsSucsModal}
      /> */}
    </div>
  );
};

export default AdminDeleteModal;
