import React, { useState } from 'react';
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { Link } from 'react-router-dom';
import { postTimeDifference, truncateString } from '../../config/Helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEarthAmericas, faX } from '@fortawesome/free-solid-svg-icons';
import PostCommentSection from './PostCommentSection';

function PostModal({ item = {}, onClose = () => { } }) {
    const CaptionComponent = ({ text = "" }) => {
        // post caption component
        const [isFullText, setIsFullText] = useState(false);
        return (
            <div>
                {isFullText ? (
                    <p className="font-size-17px theme-grey-type font-Poppins-Regular">
                        {text || ""}
                    </p>
                ) : (
                    <p className="font-size-17px theme-grey-type font-Poppins-Regular">
                        {truncateString(text, 600)}
                    </p>
                )}
                {text?.length > 240 && (
                    <div className="flex justify-end">
                        <button
                            onClick={() => setIsFullText(!isFullText)}
                            className="font-size-15px theme-grey-type  font-Poppins-Regular"
                        >
                            ...see {isFullText ? "less" : "more"}
                        </button>
                    </div>
                )}
            </div>
        );
    };
    return (
        <div className="post__modal">
            <div className='post__modal__inner'>
                <button
                    onClick={() => onClose(false)}
                    className="absolute"
                    style={{ top: 10, right: 10 }}
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className="my__row">
                    <div className="col__md__8 px-3">
                        <div className="img__container">
                            <div>
                                
                                <img
                                    src={`${item?.attachments[0]?.url}`}
                                    // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.attachments[0]?.url}`}
                                    alt=""
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col__md__4">
                        <div className="flex gap-2 items-center p-3">
                            <div className="crete-feed-user-img flex ">
                                <img
                                    width={100}
                                    loading="lazy"
                                    src={`${process.env.REACT_APP_BASE_URL}${item?.profileImageUrl}`}
                                    onError={(e) => (e.target.src = DefaultProfileImage)}
                                    alt="user"
                                    className="rounded-full"
                                />
                            </div>{" "}
                            {/* post creator image */}
                            <div className="sidebar-gobal-user-name ">
                                <Link
                                    to={`/public-profile/${item?.userId}/view`}
                                    className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize"
                                >
                                    {" "}
                                    {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                </Link>{" "}
                                {/* post creator name */}
                                <p className="font-size-15px  theme-color-green font-Poppins-Regular  capitalize">
                                    {item?.connections || 0} Connections
                                </p>{" "}
                                {/* post creator connection count */}
                                <p className="font-size-15px theme-grey-type  font-Poppins-Regular  ">
                                    {postTimeDifference(item?.createdAt)}{" "}
                                    <FontAwesomeIcon icon={faEarthAmericas} />
                                </p>{" "}
                                {/* post time differnce */}
                            </div>
                        </div>
                        <div className="post__content px-3 pb-3">
                            <CaptionComponent text={item?.caption} />
                            <div className="relative  px-2">
                                <PostCommentSection commentData={item} key={item?._id} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostModal;