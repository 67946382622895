import React, { useState, useEffect } from 'react';
import AdminSidebar from '../../../components/AdminComponents/AdminSidebar';
import AdminHeader from '../../../components/AdminComponents/AdminHeader';
import { CircularProgress } from '@mui/material';
import PaginationComponet from '../../../components/PaginationComponet/PaginationComponet';
import httpRequest from '../../../config/Helpers/httpRequest';
import { Link, useParams, useNavigate } from 'react-router-dom';
import qs from "qs";
import { toast } from 'react-toastify';
import moment from 'moment';

interface Report {
    userId?: string;
    firstName?: string;
    lastName?: string;
    subject?: string;
    createdAt?: string;
    [key: string]: any; // Add any other properties if necessary
}

interface ApiResponse {
    documents: Report[];
    paginated: {
        totalPages: number;
    };
}

const ReportsById: React.FC = () => {
    const subject_style: React.CSSProperties = {
        backgroundColor: "#00443f",
        display: "inline-block",
        padding: "4px 12px",
        borderRadius: "1.5rem",
        color: "#fff",
        textTransform: "capitalize"
    };

    const navigate = useNavigate();

    const { rid } = useParams<{ rid: string }>();

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [displayReports, setDisplayReports] = useState<Report[]>([]);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [queryParams, setQueryParams] = useState({
        page: 1,
        limit: 10
    });

    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e,
        }));
    }; // Handler for changing pages in pagination

    // Functionality: API call to fetch reports from the server
    const fetchReportsById = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/report/detail/${rid}?${qs.stringify(queryParams)}` });
        if (res) {
            setDisplayReports(res?.documents || []);
            setTotalPages(res?.paginated?.totalPages || 1);
        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };

    // Fetch reports on component mount and when queryParams change
    useEffect(() => {
        fetchReportsById();
    }, [queryParams]);

    return (
        <div>
            <div className="flex font-poppins-regular">
                <AdminSidebar />
                <div className="w-4/5 ml-auto">
                    <AdminHeader />
                    <div className="pt-24 Admin-main-operator-parent">
                        <div className="pb-6 pl-6 pr-10">
                            <div className="flex justify-between items-center">
                                <h2 className="font-size-36px font-Poppins-Medium">Report</h2>
                                <button onClick={() => navigate(-1)}>
                                    <img src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png" alt="back" />
                                </button>
                            </div>
                            <div className="main-admin-Assessment theme-bg-lightblue">
                                <div className="tab-content">
                                    <div className="main-Admin-status-action">
                                        <ul
                                            style={{ gridTemplateColumns: "33% 33% 34%" }}
                                            className="Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                                        >
                                            <li className="text-white">NAME</li>
                                            <li className="text-white">SUBJECT</li>
                                            <li className="text-white">REPORT DATE</li>
                                        </ul>
                                    </div>
                                    {isLoader ?
                                        <div className="main-Admin-status-content mt-5 text-center">
                                            <CircularProgress style={{ color: '#00443f', width: 20, height: 20 }} />
                                        </div>
                                        :
                                        displayReports && displayReports.length > 0 ?
                                            <>
                                                {displayReports.map((item, index) => (
                                                    <div key={index} className="main-Admin-status-content mt-5">
                                                        <ul
                                                            style={{ gridTemplateColumns: "33% 33% 34%" }}
                                                            className="Admin-status-content-Role font-Poppins-Regular font-size-18px"
                                                        >
                                                            <li className="flex justify-center capitalize">
                                                                <Link to={`/public-profile/${item?.userId}/view`} className="underline">
                                                                    {`${item?.firstName || ""} ${item?.lastName || ""}`}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <span style={subject_style}>{item?.subject || "-"}</span>
                                                            </li>
                                                            <li>{moment(item?.createdAt).format('DD-MM-YYYY') || '-'}</li>
                                                        </ul>
                                                    </div>
                                                ))}
                                                <PaginationComponet
                                                    total={totalPages}
                                                    setCurrentPage={handlePageChange}
                                                    currentPage={queryParams?.page}
                                                />
                                            </>
                                            :
                                            <div className="main-Admin-status-content mt-5">
                                                <p className="text-center">No Reports</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReportsById;