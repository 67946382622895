import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faXmark, faLocationDot, faX } from '@fortawesome/free-solid-svg-icons';
import { getRole } from '../../config/Helpers/helpers';
import httpRequest from '../../config/Helpers/httpRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import PromotionStripe from "./PromotionStripe";
import Select, { ActionMeta, SingleValue, MultiValue } from "react-select";
import GreenTick from "../../assets/img/greentick.svg"
import { RootState } from '../../config/Store/store';
import { useSelector } from "react-redux";
import { setAllServices } from '../../config/Store/Reducers/allServicesSlice';
import { useDispatch } from "react-redux";


interface OptionType {
    value: string;
    label: string;
    [key: string]: any;
}
interface ApiResponseServices {
    documents: [];
}
interface Audience {
    _id: string;
    title: string;
    // Add other properties if needed
}
interface ServiceOption {
    value: string;
    label: string;
}
interface ApiResponse {
    message: string;
    documents: [];

    [key: string]: any;
}
interface PromoteModalProps {
    onClose: () => void;
    refType: string;
    refId: string;
    isEdit?: boolean;
    audId?: string;
}
interface AudienceDocument {
    _id: string;
    userId: string;
    title: string;
    role: string;
    ageMax: number;
    ageMin: number;
    gender: string;
    rating: number;
    cancellationRate: number;
    objective: string;
    suburb: string[];
    serviceId: string[];
    createdAt: string;
    updatedAt: string;
    __v: number;
    [key: string]: any;
}
interface Promotion {
    _id: string;
    title: string;
    offerAmount: number;
    priceId: string;
    details: string[];
    maxCount: number;
    type: string;
    __v: number;
    createdAt: string; // Consider using Date type if you handle dates as Date objects
    updatedAt: string; // Consider using Date type if you handle dates as Date objects
    [key: string]: any;
}
interface PromotionApiResponse {
    documents: Promotion[];
    [key: string]: any;
}
const PromoteModal: React.FC<PromoteModalProps> = ({ onClose, refType, refId, isEdit = false, audId = "" }) => {
    const dispatch = useDispatch();
    const { ALLSERVICES } = useSelector((store: RootState) => store.allServices);
    const decoded = getRole();
    const [value, setValue] = useState<number>(0); // Default value
    // const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    //     setValue(parseInt(event.target.value, 10)); // Update the state
    // };
    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = parseInt(event.target.value, 10);
        setValue(newValue); // Update the local slider value state
        handleAudienceChange('cancellationRate', newValue); // Update the audience cancellation rate
    };

    const [selectedServices, setSelectedServices] = useState<MultiValue<OptionType>>([]);
    const [options, setOptions] = useState<OptionType[]>([]);
    const [isLoader, setIsLoader] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [myServices, setMyServices] = useState<string[]>([]);
    const [currentStep, setCurrentStep] = useState(1);
    const [secretKey, setSecretKey] = useState<string>("");
    const [subscriptionId, setSubscriptionId] = useState<string>("");
    const [serviceOptions, setServiceOptions] = useState<ServiceOption[]>([]);
    const [savedAudience, setSavedAudience] = useState<Audience[]>([]);
    const [audienceById, setAudienceById] = useState<string>("")
    const [selectedAudienceId, setSelectedAudienceId] = useState<string | null>(audId || null);

    const [audience, setAudience] = useState({
        title: "",
        role: "participant",
        ageMin: 16,
        ageMax: 63,
        gender: "men",
        rating: 1,
        cancellationRate: 0,
        objective: "connects",
        suburb: [] as string[],
        serviceId: [] as string[]
    });
    const handleAudienceChange = (key: string, value: any) => {
        setAudience((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };
    const handleSuburbChange = (event: MultiValue<OptionType>) => {
        // Ensure all selected suburbs are updated correctly in the state
        const selectedSuburbs = event.map((v) => v.value.toLowerCase());

        setSelectedServices(event);  // Store the selected options in state

        setAudience((prevState) => ({
            ...prevState,
            suburb: selectedSuburbs,  // Properly update the suburb array in audience state
        }));
    };

    const handleSaveAudience = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (!audience.title.trim()) {
            toast.error("Title must not be empty");
            return;
        }

        // if (!audience.rating) {
        //     toast.error("Rating must be selected");
        //     return;
        // }

        // if (audience.suburb.length === 0) {
        //     toast.error("At least one suburb must be selected");
        //     return;
        // }

        // if (audience.serviceId.length === 0) {
        //     toast.error("At least one service must be selected");
        //     return;
        // }

        try {
            setIsLoader(true);
            let apiPath = ""
            if (selectedAudienceId) {

                apiPath = `/api/ad/audience/${selectedAudienceId}`

            }
            else {
                apiPath = '/api/ad/audience'
            }
            const { res, err } = await httpRequest<ApiResponse>({
                method: selectedAudienceId ? 'put' : 'post',
                // path: '/api/ad/audience',
                path: apiPath,
                params: audience,
            });

            if (res) {
                toast.success('Audience saved successfully!');
                setAudienceById(res?.document?._id || selectedAudienceId || "");
                setSelectedAudienceId(res?.document?._id || selectedAudienceId || "");

            }
            // else if (res?.documents) {
            //     toast.success('Audience saved successfully!');
            //     setAudienceById(res?.documents?._id || "");
            //     setSelectedAudienceId(res?.documents?._id || "");
            // }

            else {
                toast.error(err?.message, { type: "error" });
            }
        } catch (error) {
            console.error('Error saving audience:', error);
            toast.error('Something went wrong!');
        } finally {
            setIsLoader(false);
        }
    };
    const handleStepTwo = () => {
        if (selectedAudienceId) {
            setCurrentStep(2)
        }
        else {
            toast("Use a saved audience or create a new audience in order to continue.", { type: "error" })
        }
    }
    const handleStepThree = () => {
        if (promotionId) {
            setCurrentStep(3)
        }
        else {
            toast("Please select a advertisement package in order to continue.", { type: "error" })
        }
    }
    const handleBackStep = () => {
        setCurrentStep((prev) => prev - 1);
    };
    const handleServiceChange = (event: MultiValue<ServiceOption>) => {
        const tempArr = event?.map((v) => v?.value); // Extract the values (service IDs)
        setAudience((prevState) => ({
            ...prevState,
            serviceId: tempArr, // Update serviceId in the audience state
        }));
    };
    useEffect(() => {
        if (ALLSERVICES) {
            let tempArr: ServiceOption[] = ALLSERVICES.flatMap((item) =>
                item?.documents
                    .filter((v) => v?.servicename) // Filter out items with undefined label
                    .map((v) => ({
                        value: v?._id,
                        label: v?.servicename as string, // Cast to string since we filtered out undefined
                    }))
            );
            setServiceOptions(tempArr);
        }
    }, [ALLSERVICES]);
    const fetchSavedAudience = async () => {
        const { res, err } = await httpRequest<ApiResponse>({
            path: '/api/ad/audience',
        });
        if (res) {
            setSavedAudience(res?.documents);
        }
        else {
            toast(err?.message, { type: "error" });
        }
    }
    useEffect(() => {
        fetchSavedAudience()
    }, [])

    const fetchSavedAudienceById = async () => {
        if (!selectedAudienceId) {
            setAudience({
                title: "",
                role: "participant",
                ageMin: 16,
                ageMax: 63,
                gender: "all",
                rating: 1,
                cancellationRate: 0,
                objective: "connects",
                suburb: [],
                serviceId: [],
            });
            return
        } // Prevent the function from running if there's no selected audience ID

        const { res, err } = await httpRequest<AudienceDocument>({
            path: `/api/ad/audience/${selectedAudienceId}`,
        });
        if (res && Array.isArray(res.documents) && res.documents.length > 0) {
            // setAudienceById(res?.documents);
            const audienceData = res.documents[0]; // Only access the first document if it exists
            setAudienceById(audienceData?._id || "");
            // Update the audience state with the response data
            setAudience({
                title: audienceData?.title || "",
                role: audienceData?.role || "participant",
                ageMin: audienceData?.ageMin || 16,
                ageMax: audienceData?.ageMax || 63,
                gender: audienceData?.gender || "all",
                rating: audienceData?.rating || 5,
                cancellationRate: audienceData?.cancellationRate || 0,
                objective: audienceData?.objective || "connections",
                suburb: audienceData?.suburb || [],
                serviceId: audienceData?.serviceId || [],
            });

            // If you want to set suburbs in myServices or selectedServices as well
            setMyServices(audienceData?.suburb || []);
            setSelectedServices(
                audienceData?.suburb.map((sub: string) => ({
                    label: sub,
                    value: sub
                }))
            );

        }
        else {
            toast(err?.message, { type: "error" });
        }
    }
    useEffect(() => {
        fetchSavedAudienceById()
    }, [selectedAudienceId, audienceById])

    const fetchLocations = async (post: string) => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({
            path: `/api/postcodes/all?suburb=${post}`,
        });
        console.log(res, "res")
        if (res) {
            setOptions(res?.documents);
        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        const debounceTimer = setTimeout(() => {
            if (inputValue) {
                fetchLocations(inputValue);
            }
        }, 1500);

        return () => clearTimeout(debounceTimer);
    }, [inputValue]);

    useEffect(() => {
        (async () => {
            const { res, err }: { res?: ApiResponseServices; err?: any } = await httpRequest({
                path: "/api/services/all",
            });


            if (res && res?.documents) {
                dispatch(setAllServices(res?.documents) || []); // Directly dispatch documents without flattening for now
            } else {
                toast(err?.message, { type: "error" });
            }

        })();
    }, []);
    const handleInputChange = (value: string) => {
        setInputValue(value);
    }

    const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        // Ensure the selected services are added to `myServices`
        if (selectedServices && selectedServices.length > 0) {

            const suburbRegex = /^(.*?)\s(?:NSW|VIC|QLD|NT|ACT|WA|TAS|SA)/;

            const newServices = selectedServices.map((service) => {
                const match = service.label.match(suburbRegex);
                return match ? match[1] : service.label;  // If match, return the suburb name, otherwise return full label
            });
            const nonDuplicateServices = newServices.filter((serviceLabel) => !myServices.includes(serviceLabel));
            if (nonDuplicateServices.length > 0) {
                const updatedServices = [...myServices, ...nonDuplicateServices];
                setMyServices(updatedServices);
                setAudience((prevState) => ({
                    ...prevState,
                    suburb: updatedServices,  // Populate `suburb` with all services from `myServices`
                }));

                toast.success("Suburbs added successfully!");
            } else {
                toast.info("All selected suburbs are already added.");
            }

            // Clear selected services after adding
            setSelectedServices([]);
        } else {
            toast.error("Please select a suburb before adding.");
        }
    };


    const handleRemove = (index = 0) => {
        const updatedServices = [...myServices];
        updatedServices.splice(index, 1);
        setMyServices(updatedServices);
    };

    // step2 Fetching promotions
    const [fetchPromotions, setFecthPromotions] = useState<Promotion[]>([]);
    const [promotionId, setPromotionId] = useState<string>("")
    useEffect(() => {
        const fetchOffers = async () => {
            const { res, err } = await httpRequest<PromotionApiResponse>({
                // path: '/api/ad/promotions'
                path: `/api/ad/promotions?offerType=${audience?.objective}`
            })
            if (res) {
                setFecthPromotions(res?.documents)
            }
            else {
                toast(err?.message || "Error Retreving ", { type: "error" })
            }
        }
        if (currentStep === 2) {
            fetchOffers();

        }

    }, [currentStep])

    const handleCreateIntent = async () => {
        let payload = {
            promotionOfferId: promotionId,
            audienceId: audienceById,
            refId: refId,
            refType: refType
        }
        const { res, err } = await httpRequest<ApiResponse>({
            method: 'post',
            path: '/api/promotions/create-intent',
            params: payload

        })
        if (res) {
            console.log(res, "secret")
            setSecretKey(res?.clientSecret)
            setCurrentStep(3)
        }
        else {

        }
    }

    const optionss = [
        { value: 1, label: <><FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 1 Star</> },
        { value: 2, label: <><FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 2 Stars</> },
        { value: 3, label: <><FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 3 Stars</> },
        { value: 4, label: <><FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 4 Stars</> },
        { value: 5, label: <><FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 5 Stars</> }
    ];

    return (
        <div className="post__modal promote_modal">
            <div className={`promote_Modal_inner  ${currentStep === 2 ? "promote_Modal_second-step" : ""}`} >
                <button
                    // onClick={() => onClose(false)}\
                    onClick={
                        () => {
                            onClose();
                            setSelectedAudienceId("")
                        }

                    }
                    className="absolute"
                    style={{ top: -10, right: -10 }}
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                {currentStep === 1 ? (
                    <div>
                        {/* <div className='px-20 '> */}
                        <div className=''>
                            <h2 className='font-Poppins-SemiBold font-size-25px '>Audience</h2>
                        </div>
                        <div>
                            <p className='font-size-15px font-Poppins-Regular mt-1 mb-2 '>Define who you want to see your ads.</p>
                        </div>
                        <div className='flex flex-row gap-4 items-center pb-2 '>
                            <div>
                                <span className={`font-size-15px font-Poppins-SemiBold mt-4  ${selectedAudienceId ? " " : "underline-green"}`}>Create new audience</span>

                            </div>
                            <div className={`${selectedAudienceId ? "underline-green " : ""}`}>
                                <select
                                    className={`bg-[#FCFCFC] outline-0 font-size-15px font-Poppins-Regular `}
                                    value={selectedAudienceId || ""}
                                    onChange={(e) => setSelectedAudienceId(e.target.value)}
                                >
                                    {isEdit ? null : <option className='font-size-15px font-Poppins-Regular' value="">use a save audience</option>

                                    }


                                    {savedAudience && savedAudience?.length > 0 && savedAudience?.map((item, index) => (

                                        <option key={index} className='font-size-15px font-Poppins-Regular' value={item._id}>{item.title}</option>
                                    ))}

                                </select>
                            </div>

                        </div>
                        <form onSubmit={handleSaveAudience}>
                            <div className="form-upper-details p-4 max-h-[42vh] overflow-y-auto">
                                <div>
                                    <div className="flex flex-col gap-2">
                                        <span className='font-size-15px font-Poppins-Medium'>Audience name</span>
                                        <input
                                            value={audience.title}
                                            onChange={(e) => handleAudienceChange('title', e.target.value)}
                                            type="text" placeholder='' className='bg-[#F4F8FB] p-3 promote-inputs w-[86%] font-size-15px font-Poppins-Medium' />
                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-2">
                                        <span className='font-size-15px font-Poppins-Medium promote-inputs-checbox-parent'>User type</span>
                                        <div className=' '>


                                            <div className="boxed flex flex-row gap-3 items-center">
                                                <input
                                                    type="radio"
                                                    id="participant"
                                                    name="role"
                                                    value="participant"
                                                    checked={audience.role === 'participant'}
                                                    onChange={() => handleAudienceChange('role', 'participant')}
                                                />
                                                <label className='font-size-13px font-Poppins-Medium flex justify-center items-center' htmlFor="participant">Participant</label>

                                                <input
                                                    type="radio"
                                                    id="provider"
                                                    name="role"
                                                    value="provider"
                                                    checked={audience.role === 'provider'}
                                                    onChange={() => handleAudienceChange('role', 'provider')}
                                                />
                                                <label className='font-size-13px font-Poppins-Medium flex justify-center items-center' htmlFor="provider">Provider </label>

                                                <input
                                                    type="radio"
                                                    id="both"
                                                    name="role"
                                                    value="both"
                                                    checked={audience.role === 'both'}
                                                    onChange={() => handleAudienceChange('role', 'both')}
                                                />
                                                <label className='font-size-13px font-Poppins-Medium flex justify-center items-center' htmlFor="both">Both</label>

                                            </div>



                                        </div>
                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-2">
                                        <span className='font-size-15px font-Poppins-Medium'>Location</span>
                                        <span className='font-size-13px font-Poppins-Regular'>Select suburbs you are want to show your ad.</span>
                                        <div className='flex flex-row flex-wrap items-center justify-between'>

                                            <div className="flex w-full custom-input gap-3 multi-select-location">
                                                <Select
                                                    isMulti
                                                    value={selectedServices}  // Bind to the selected services/suburbs
                                                    options={options.map((option) => ({
                                                        value: option.suburb,
                                                        label: `${option.suburb} ${option.state} `,
                                                    }))}
                                                    onChange={handleSuburbChange}  // Handle multiple selections
                                                    inputValue={inputValue}
                                                    onInputChange={handleInputChange}
                                                    isLoading={isLoader}
                                                    styles={{
                                                        control: (provided, state) => ({
                                                            ...provided,
                                                            backgroundColor: state.isFocused ? '#087BCA08' : '#087BCA08', // Focused background
                                                            borderColor: state.isFocused ? '#ccc' : '#ccc',     // Focused border color
                                                            boxShadow: state.isFocused ? '0 0 0 1px #087BCA08' : 'none',
                                                            '&:hover': {
                                                                borderColor: '#ccc',   // Border color on hover
                                                            },
                                                        }),
                                                        option: (provided, state) => ({
                                                            ...provided,
                                                            backgroundColor: state.isSelected ? '#087BCA08' : '#fff',
                                                            color: state.isSelected ? '#fff' : '#333',
                                                            '&:hover': {
                                                                backgroundColor: '#087BCA08',
                                                                color: '#000',
                                                            },
                                                        }),
                                                        multiValue: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: '#087BCA08',
                                                            color: '#000',
                                                        }),
                                                        multiValueLabel: (provided) => ({
                                                            ...provided,
                                                            color: '#000',
                                                        }),
                                                        multiValueRemove: (provided) => ({
                                                            ...provided,
                                                            color: '#000',
                                                            '&:hover': {
                                                                backgroundColor: '#087BCA08',
                                                                color: '#000',
                                                            },
                                                        }),
                                                        dropdownIndicator: (provided) => ({
                                                            ...provided,
                                                            color: '#087BCA08',
                                                            '&:hover': {
                                                                color: '#087BCA08',
                                                            },
                                                        }),
                                                        indicatorSeparator: (provided) => ({
                                                            ...provided,
                                                            backgroundColor: '#087BCA08',
                                                        }),
                                                        clearIndicator: (provided) => ({
                                                            ...provided,
                                                            color: '#087BCA08',
                                                            '&:hover': {
                                                                color: '#087BCA08',
                                                            },
                                                        }),
                                                        menu: (provided) => ({
                                                            ...provided,
                                                            backgroundcolor: '#000',
                                                        }),
                                                    }}
                                                />

                                                <button
                                                    onClick={handleAdd}
                                                    className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                                                    style={{ color: "white" }}
                                                >
                                                    Add
                                                </button>
                                            </div>
                                            <div className="flex gap-2 items-center flex-wrap">
                                                {myServices.length > 0
                                                    ? myServices.map((item, index) => (
                                                        <p
                                                            key={index}
                                                            className="mt-5 feeds-btn font-size-20px w-fit	font-Poppins-Medium theme-grey-type gap-2"
                                                            style={{
                                                                backgroundColor: "rgb(112 112 112 / 23%)",
                                                                color: "#464646",
                                                                border: "none",
                                                            }}
                                                        >
                                                            <FontAwesomeIcon icon={faLocationDot} color="#464646" />
                                                            {item || ""}
                                                            <FontAwesomeIcon
                                                                role="button"
                                                                onClick={() => handleRemove(index)}
                                                                icon={faXmark}
                                                                color="#464646"
                                                            />
                                                        </p>
                                                    ))
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-2">
                                        <span className='font-size-15px font-Poppins-Medium'>Age</span>
                                        <div className='flex flex-row gap-3 items-center'>

                                            {/* <select
                                                className='border boder-[#000] rounded-md p-2'
                                                value={audience.ageMin}
                                                onChange={(e) => handleAudienceChange("ageMin", parseInt(e.target.value))}
                                            >
                                                <option value="16">16</option>
                                                <option value="18">18</option>
                                                <option value="20">20</option>
                                            </select> */}

                                            <input
                                                value={audience.ageMin}
                                                onChange={(e) => handleAudienceChange('ageMin', e.target.value)}
                                                type="text" placeholder='' className='bg-[#F4F8FB] p-3 promote-inputs w-[86%] font-size-15px font-Poppins-Medium' />
                                            <input
                                                value={audience.ageMax}
                                                onChange={(e) => handleAudienceChange('ageMax', e.target.value)}
                                                type="text" placeholder='' className='bg-[#F4F8FB] p-3 promote-inputs w-[86%] font-size-15px font-Poppins-Medium' />

                                            {/* <select
                                                className='border boder-[#000] rounded-md p-2'
                                                value={audience.ageMax}
                                                onChange={(e) => handleAudienceChange("ageMax", parseInt(e.target.value))}
                                            >
                                                <option value="63">63</option>
                                                <option value="65">65</option>
                                                <option value="70">70</option>
                                            </select> */}
                                        </div>
                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-2">
                                        <span className='font-size-15px font-Poppins-Medium'>Gender</span>
                                        <div className="boxed flex flex-row gap-3 items-center">
                                            <input
                                                type="radio"
                                                id="all"
                                                name="gender"
                                                value="all"
                                                checked={audience.gender === 'all'}
                                                onChange={() => handleAudienceChange('gender', 'all')}
                                            />
                                            <label className='flex justify-center items-center font-size-13px font-Poppins-Medium ' htmlFor="all">All</label>

                                            <input
                                                type="radio"
                                                id="men"
                                                name="gender"
                                                value="male"
                                                checked={audience.gender === 'male'}
                                                onChange={() => handleAudienceChange('gender', 'male')}
                                            />
                                            <label className=' flex justify-center items-center font-size-13px font-Poppins-Medium' htmlFor="men">Men </label>

                                            <input
                                                type="radio"
                                                id="women"
                                                name="gender"
                                                value="female"
                                                checked={audience.gender === 'female'}
                                                onChange={() => handleAudienceChange('gender', 'female')}
                                            />
                                            <label className='font-size-13px font-Poppins-Medium flex justify-center items-center' htmlFor="women">Women </label>



                                        </div>

                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>

                                <div>
                                    <div className="flex flex-col gap-2 w-[86%]">
                                        <span className='font-size-15px font-Poppins-Medium'>Services</span>
                                        <span className='font-size-13px font-Poppins-Regular'>Select services people are most looking for.</span>
                                        <Select
                                            isMulti
                                            value={serviceOptions.filter((option) => audience.serviceId.includes(option.value))}
                                            onChange={handleServiceChange}
                                            options={serviceOptions}
                                            styles={{
                                                control: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isFocused ? '#087BCA08' : '#087BCA08', // Focused background
                                                    borderColor: state.isFocused ? '#ccc' : '#ccc',     // Focused border color
                                                    boxShadow: state.isFocused ? '0 0 0 1px #087BCA08' : 'none',
                                                    '&:hover': {
                                                        borderColor: '#ccc',   // Border color on hover
                                                    },
                                                }),
                                                option: (provided, state) => ({
                                                    ...provided,
                                                    backgroundColor: state.isSelected ? '#087BCA08' : '#fff',
                                                    color: state.isSelected ? '#fff' : '#333',
                                                    '&:hover': {
                                                        backgroundColor: '#087BCA08',
                                                        color: '#000',
                                                    },
                                                }),
                                                multiValue: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: '#087BCA08',
                                                    color: '#000',
                                                }),
                                                multiValueLabel: (provided) => ({
                                                    ...provided,
                                                    color: '#000',
                                                }),
                                                multiValueRemove: (provided) => ({
                                                    ...provided,
                                                    color: '#000',
                                                    '&:hover': {
                                                        backgroundColor: '#087BCA08',
                                                        color: '#000',
                                                    },
                                                }),
                                                dropdownIndicator: (provided) => ({
                                                    ...provided,
                                                    color: '#087BCA08',
                                                    '&:hover': {
                                                        color: '#087BCA08',
                                                    },
                                                }),
                                                indicatorSeparator: (provided) => ({
                                                    ...provided,
                                                    backgroundColor: '#087BCA08',
                                                }),
                                                clearIndicator: (provided) => ({
                                                    ...provided,
                                                    color: '#087BCA08',
                                                    '&:hover': {
                                                        color: '#087BCA08',
                                                    },
                                                }),
                                                menu: (provided) => ({
                                                    ...provided,
                                                    backgroundcolor: '#000',
                                                }),
                                            }}

                                        />

                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-2">
                                        <span className='font-size-15px font-Poppins-Medium'>Rating</span>
                                        <span className='font-size-13px font-Poppins-Regular'>Refine your audience by rating.</span>
                                        <select
                                            value={audience.rating}
                                            onChange={(e) => handleAudienceChange("rating", parseInt(e.target.value))}
                                            className="bg-[#F4F8FB] p-3 promote-inputs w-[86%] font-size-15px font-Poppins-Medium"
                                        >
                                            <option value="">Select Rating</option>
                                            <option value="1"> <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 1 Star</option>
                                            <option value="2">  <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 2 Stars</option>
                                            <option value="3"> <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 3 Stars</option>
                                            <option value="4">  <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 4 Stars</option>
                                            <option value="5">  <FontAwesomeIcon icon={faStar} style={{ marginRight: 5 }} /> 5 Stars</option>
                                        </select>

                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>
                                <div>
                                    <div className="flex flex-col gap-2 range-color">
                                        <span className='font-size-15px font-Poppins-Medium'>Cancellation rate</span>
                                        <span className='font-size-13px font-Poppins-Regular'>Refine your audience by job cancellation rate.</span>

                                        {/* <input
                                            type="range"
                                            min="0"
                                            max="100"
                                            step="1"
                                            value={audience.cancellationRate}
                                            onChange={(e) => handleAudienceChange('cancellationRate', parseInt(e.target.value))}
                                            className="bg-[#00443f] w-[86%] promote-inputs"
                                        /> */}

                                        <div className="slider-container">
                                            <span>0%</span>
                                            <div className="slider-wrapper">
                                                <input
                                                    type="range"
                                                    min="0"
                                                    max="100"
                                                    value={audience.cancellationRate} // Bind to audience.cancellationRate
                                                    className="slider"
                                                    onChange={handleSliderChange}
                                                />
                                                <div className="slider-value"
                                                    style={{ left: `${value}%`, transform: `translateX(-${value}%)` }}

                                                >{value}%</div>
                                            </div>
                                            <span>100%</span>
                                        </div>
                                        {/* <span>{audience.cancellationRate}%</span> */}
                                    </div>
                                    <div className='dashed-underline my-4'></div>
                                </div>
                            </div>
                            {isEdit ?

                                null
                                :
                                <>
                                    <div className='my-2'>
                                        <h2 className='font-Poppins-SemiBold font-size-25px '>Objective</h2>
                                        <p className='font-size-15px font-Poppins-Regular'>Select your ad's primary goal to drive the best results for your campaign.</p>
                                    </div>

                                    <div className="form-upper-details p-4 max-h-[42vh] overflow-y-auto">
                                        <span className='font-size-15px font-Poppins-Medium'>Select</span>
                                        <div className="flex flex-row gap-2 items-center">
                                            <input
                                                type="radio"
                                                id="connects"
                                                name="objective"
                                                value="connects"
                                                checked={audience.objective === 'connects'}
                                                onChange={() => handleAudienceChange('objective', 'connects')}
                                            />
                                            <span className='font-size-15px font-Poppins-Medium'>Connections</span>
                                        </div>
                                        <div className="flex flex-row gap-2 items-center">
                                            <input
                                                type="radio"
                                                id="views"
                                                name="objective"
                                                value="views"
                                                checked={audience.objective === 'views'}
                                                onChange={() => handleAudienceChange('objective', 'views')}
                                            />
                                            <span className='font-size-15px font-Poppins-Medium'>Views</span>
                                        </div>
                                    </div>
                                </>
                            }

                            <div className="flex justify-end">
                                <button type='submit'
                                    className=' font-size-18px font-Poppins-Medium  theme-color-green  feeds-btn mt-3 w-fit hover:text-white hover:bg-[#00443F]'>{selectedAudienceId ? 'Update Audience' : 'Save this audience'} </button>
                            </div>


                        </form >
                        {isEdit ?
                            (
                                null
                            )
                            :
                            (
                                <div>
                                    <button

                                        // onClick={() => setCurrentStep(2)}
                                        onClick={handleStepTwo}


                                        className='mt-3 flex flex-row justify-center items-center theme-bg-green font-size-18px font-Poppins-Medium border-2  px-5 py-1 inline-table rounded-3xl w-[8vw] loader-btn text-[#ffffff]'>Next
                                        {/* {isLoader && (
                                                <CircularProgress
                                                    style={{ color: "#fff" }}
                                                    className="btn__loader"
                                                />
                                            )} */}
                                    </button>
                                </div>
                            )
                        }
                    </div>
                )
                    :
                    currentStep === 2 ?
                        (
                            <div>
                                <div className=''>
                                    <h2 className='font-Poppins-SemiBold font-size-25px text-[#0D0D0D]'>Advertisement Package</h2>
                                </div>
                                <div>
                                    <p className='font-size-15px font-Poppins-Regular mt-1'>Select your advertisement package</p>
                                </div>
                                <div className="form-upper-details  overflow-y-auto  adv-packages grid items-end p-10 " style={{ gridTemplateColumns: "33% 33% 33%" }}>
                                    {fetchPromotions && fetchPromotions.length > 0 ?
                                        fetchPromotions.map((item: any, index: number) => (
                                            <div key={index} className={`'rounded-s-md  ' ${index == 1 ? "package-shadow z-10" : ""}`}>
                                                <div className='bg-[#00645D] flex justify-center items-center h-[6vh] rounded-t-md' >
                                                    <h2 className='font-size-25px font-Poppins-SemiBold text-[#ffffff] capitalize'>{item?.title}</h2>
                                                </div>
                                                <div className='bg-[#F4F2EE] flex flex-col justify-center items-center'>

                                                    <div className='mt-8'>
                                                        <span className='font-size-35px font-Poppins-SemiBold text-[#004540]'>${item?.offerAmount}</span>
                                                    </div>

                                                    <div className={`'flex flex-row flex-nowrap gap-3 items-center mb-14  ${index == 1 ? "mb-20 " : ""} `}>
                                                        <img src={GreenTick} alt="" />
                                                        <span className='font-size-24px font-Poppins-Medium'>{item?.details}</span>
                                                    </div>
                                                    <div>
                                                        <button
                                                            onClick={() => setPromotionId(item?._id)}
                                                            className={`mt-3 flex flex-row justify-center items-center theme-bg-green font-Poppins-Medium border-2 font-size-16px px-5 py-1 inline-table rounded-3xl w-[8vw] loader-btn text-[#ffffff] ${promotionId === item?._id ? 'selected-package' : ''}`}>{promotionId === item?._id ? 'Selected' : 'Select'}</button>
                                                    </div>
                                                    <div className='my-4'>
                                                        <p className='font-size-15px font-Poppins-Medium'>*Terms & conditions apply</p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))



                                        :
                                        (
                                            <p>No promotions found</p>
                                        )
                                    }


                                </div>
                                <div className='flex flex-row gap-2 justify-end'>
                                    <button
                                        className='font-size-18px font-Poppins-Medium  theme-color-green  feeds-btn mt-3 w-fit hover:text-white hover:bg-[#00443F]'
                                        onClick={() => setCurrentStep(1)}

                                    >
                                        Back
                                    </button>
                                    <button
                                        className='mt-3 flex flex-row justify-center items-center theme-bg-green font-size-18px font-Poppins-Medium border-2  px-5   rounded-3xl w-[8vw] loader-btn text-[#ffffff]'
                                        // onClick={() => setCurrentStep(3)}
                                        onClick={handleStepThree}
                                    // onClick={handleCreateIntent}

                                    >Next</button>

                                </div>
                            </div>
                        )
                        :
                        (
                            <>
                                <PromotionStripe
                                    secretKey={secretKey}
                                    promotionId={promotionId}
                                    audienceById={audienceById}
                                    refId={refId}
                                    refType={refType}
                                    onClose={onClose}
                                    setCurrentStep={setCurrentStep}
                                // setCurentStep={setCurentStep}
                                />
                            </>
                        )
                }
            </div>
        </div >
    );
};

export default PromoteModal;