import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import httpRequest from "../../config/Helpers/httpRequest";
interface AddCommentJobProps {
  onClose: () => void;
  jobId: string;
  successFunction: (comment: any) => void;
}
interface ApiResponse {
  message: string;
  comment: string;
  [key: string]: any;
}

const AddCommentJob: React.FC<AddCommentJobProps> = ({ onClose, jobId, successFunction }) => {
  const [commentMessage, setCommentMessage] = useState<string>("");
  const [commentLoader, setCommentLoader] = useState<boolean>(false);

  // Functionality: API call for Add comment on job History log
  const handleComment = async () => {
    if (!commentMessage) {
      toast("Please enter comment.", { type: "error" });
      return;
    }
    setCommentLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      method: "post", path: "/api/job/add-comment", params: {
        jobId: jobId,
        title: "comment",
        message: commentMessage,
      }
    });
    if (res) {
      onClose();
      setCommentMessage("");
      successFunction(res?.comment);
      toast(res?.message || "Success", { type: "success" });
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setCommentLoader(false);
  };
  return (
    <div className="modal">
      <div className="Cancellation-modal-content relative">
        <button onClick={onClose} className="absolute job-pre-edit">
          <FontAwesomeIcon
            className="x-icon-admin-opr"
            icon={faX}
            style={{ color: "#ffffff" }}
          />
        </button>
        <h2 className="font-Poppins-SemiBold font-size-30px text-[#464646] mb-2">
          Add comment
        </h2>
        <div>
          <textarea
            className="font-Poppins-Regular font-size-15px p-3 date-text-detail-active"
            value={commentMessage}
            onChange={(e) => setCommentMessage(e.target.value)}
            placeholder="Add comment"
          />
        </div>
        <div className="flex cancellation-mdl-btn justify-center gap-2 mt-4">
          <button
            disabled={commentLoader}
            onClick={handleComment}
            className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
          >
            Add{" "}
            {commentLoader && (
              <CircularProgress
                style={{ width: 14, height: 14, color: "#fff" }}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddCommentJob;