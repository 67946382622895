import React from "react";
import edit from "../../../assets/img/awesome-edit.svg";
import { useSelector } from "react-redux";
import { monthYear } from "../../../config/Helpers/helpers";
import { Link } from "react-router-dom";
import { getRole } from "../../../config/Helpers/helpers";
import { RootState } from "../../../config/Store/store";
import { useLocation } from "react-router-dom";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
interface EducationItem {
  institution?: string;
  course?: string;
  startDate?: string;
  endDate?: string;
}

interface personalData {
  _id?: string;
  education?: EducationItem[];
}

function EducationTraining() {
  const location = useLocation();
  const decoded = getRole();
  const userId = decoded?.userId;
  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);

  return (
    <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
          Education & training
        </h4>
        {userId === ShowData?._id && (


          <Link to="/education-training-edit" className="mt-5 edit-pro-icon">
            <img src={edit} alt="Edit" />
          </Link>
        )}
      </div>
      <div>
        <ul>
          {ShowData?.education && ShowData.education.length > 0 ? (
            ShowData.education.map((item: EducationItem, index: number) => (
              <li key={index} className="pb-5">
                <div>
                  <h4 className="font-size-20px font-Poppins-Medium theme-color-green capitalize">
                    {item?.institution || ""}
                  </h4>
                  <p className="font-size-18px font-Poppins-Medium theme-grey-type capitalize">
                    {item?.course || ""}
                  </p>
                  <p className="font-size-17px font-Poppins-Regular theme-grey-type">
                    {monthYear(item?.startDate) || ""} - {monthYear(item?.endDate) || ""}
                  </p>
                </div>
                <hr />
              </li>
            ))
          ) : (
            <li>No education & training</li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default EducationTraining;
