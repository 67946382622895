import React, { useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import url from "../../config/url";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faPlay } from "@fortawesome/free-solid-svg-icons";
// import BannerVideoComponent from "../../components/LoginComponents/BannerVideoComponent";
import Header from "../../components/Header";
import LoginForms from "../../components/LoginComponents/LoginForms";
import { useDispatch } from "react-redux";
import { removeUpgradeUser } from "../../config/Store/Reducers/authSlice";
import { getCookie } from "../../config/Helpers/helpers";

const LoginWeb = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // const BannerBgTexture = "imgs/banner-bg-texture.png";

  const token = getCookie() || false;

  const navigateToPaid = () => {
    // dispatch(removeUpgradeUser());
    navigate("/paid-register");
    navigate("/free-register");
  };
  const playbutton = "imgs/play-button-video.png";
  const videosec = "https://ndisync.backslashwebs.com/NDISYNCDEMO2.mp4";
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };


  const handleImageClick = () => {
    const image = document.querySelector(".play-img-home-about") as HTMLElement;
    image.style.display = "none";
  };

  const handleVideo = () => {
    const video = videoRef.current;
    if (video) {
      if (!video.paused) {
        const image = document.querySelector(".play-img-home-about");
        // image.style.display = "block";
        video.pause();
      } else {
        video.play();
        const image = document.querySelector(".play-img-home-about");
        // image.style.display = "none";
      }
    }
  };


  return (
    <section className="login-page-parent new-reg-parent relative h-screen  overflow-clip">
      <Header />
      <img
        decoding="async"
        className="w-full h-full absolute top-0 left-0 z-10  bg-texture-banner"
        src={"https://ndisync.backslashwebs.com/new-register.jpg"}

        alt=""
      />

      <div className="container-custom h-full relative z-20 flex flex-col justify-center items-center gap-14 reg-comp">

        <div className="reg-upper-detail flex flex-col justify-center items-center">
        <h2 className="font-Poppins-Medium text-[#FFFCF1] font-size-30px">Login to your account 
        </h2>
        <div>
    
          <Link to="/free-register" className="text-[#FFE175] font-Poppins-Medium font-size-25px underline">or create a new account</Link>
        </div>
      </div>
          <div className="login-box-body-parent login-box-body-parent-2 flex flex-col">
            <div className=" home-banner-main-content-inner-right flex flex-col login-web">


              <LoginForms />

        
            </div>

        </div>
      </div>
    </section>
  );
};

export default LoginWeb;
