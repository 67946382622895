/**
 * PreviewProfile Component
 * Displays a preview section with a title and a link to the public profile page.
 *
 * Props:
 * - title (string): The title to display in the preview section. Default is an empty string.
 *
 * File: PreviewProfile.js
 * Author: Developer
 * Date: 30-5-24
 */

import React from "react";
import { Link } from "react-router-dom";
interface PreviewProfileProps {
  title?: string;
}
const PreviewProfile: React.FC<PreviewProfileProps> = ({ title = "" }) => {
  return (
    <div className="shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex justify-between items-center">
      <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
        {title}
      </h4>{" "}
      {/* Title */}
      <Link
        to="/Publicprofile"
        className="font-size-18px theme-color-green font-Poppins-Medium feeds-btn  hover:text-white hover:bg-[#00443F]"
      >
        Preview profile
      </Link>{" "}
      {/* Link to Public Profile */}
    </div>
  );
}

export default PreviewProfile;
