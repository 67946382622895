import React from "react";
import edit from "../../../assets/img/awesome-edit.svg";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRole } from "../../../config/Helpers/helpers";
import { RootState } from "../../../config/Store/store";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
import { useLocation } from "react-router-dom";
function HourlyRate() {
  const location = useLocation();
  // const { userId = "" } = getRole();
  const decoded = getRole();
  const userId = decoded?.userId || "";
  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);

  return (
    <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
          Hourly rates
        </h4>
        {userId === ShowData?._id && (
          <Link to="/hourly-rates-edit" className="mt-2 edit-pro-icon ">
            <img src={edit} alt="" />
          </Link>
        )}
      </div>
      <div>
        <ul className="grid mb-3" style={{ gridTemplateColumns: "1fr 1fr 1fr" }}>
          <li className=" ">
            <div>
              <h4 className="font-size-20px font-Poppins-Medium theme-color-green">
                Weekdays
              </h4>
              <p className="font-size-20px font-Poppins-Medium theme-grey-type">
                {ShowData?.rates?.weekday
                  ? `$${ShowData?.rates?.weekday || 0}/hour`
                  : "-"}
              </p>
            </div>
          </li>{" "}
          {/* weekdays */}
          <li className="">
            <div>
              <h4 className="font-size-20px font-Poppins-Medium theme-color-green">
                Saturday
              </h4>
              <p className="font-size-20px font-Poppins-Medium theme-grey-type">
                {ShowData?.rates?.saturday
                  ? `$${ShowData?.rates?.saturday || 0}/hour`
                  : "-"}
              </p>
            </div>
          </li>{" "}
          {/* saturday */}
          <li className=" ">
            <div>
              <h4 className="font-size-20px font-Poppins-Medium theme-color-green">
                Sunday
              </h4>
              <p className="font-size-20px font-Poppins-Medium theme-grey-type">
                {ShowData?.rates?.sunday
                  ? `$${ShowData?.rates?.sunday || 0}/hour`
                  : "-"}
              </p>
            </div>
          </li>{" "}
          {/* sunday */}
        </ul>
      </div>
    </div>
  );
}

export default HourlyRate;
