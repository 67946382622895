/**
 * ChangeEmail Component
 * Handles the functionality for changing the user's email with three inputs:
 * current email, new email, and confirm new email.
 *
 * File: ChangeEmail.js
 * Author: Developer
 * Date: 06/04/24
 */

import React, { useState, FormEvent, ChangeEvent } from "react";
import { CircularProgress } from "@mui/material";
import {
  validateEmail,
  logOutWithOutApi,
} from "../../../config/Helpers/helpers";
import { useNavigate } from "react-router-dom";
import httpRequest from "../../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
interface ApiResponse {
  message: string;
}
const ChangeEmail: React.FC = () => {
  // Initialize useDispatch and useNavigate hooks
  const navigate = useNavigate();

  // State variables for form inputs and API responses
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [cnfrmNew, setCnfrmNew] = useState<string>("");
  const [updateEmail, setUpdateEmail] = useState<{ currentEmail: string; newEmail: string }>({
    currentEmail: "",
    newEmail: "",
  });

  // Functionality: API call to change the email
  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();
    if (!cnfrmNew || !updateEmail.currentEmail || !updateEmail.newEmail) {
      toast("All fields are required", { type: "error" });
    } else if (
      !validateEmail(updateEmail.currentEmail) ||
      !validateEmail(updateEmail.newEmail)
    ) {
      toast("Please enter a valid email address", { type: "error" });
    } else if (updateEmail.currentEmail === updateEmail.newEmail) {
      toast("The new email cannot be the same as the old email.", {
        type: "error",
      });
    } else if (updateEmail.newEmail.toLowerCase() !== cnfrmNew.toLowerCase()) {
      toast("The new email and the confirmed email do not match.", {
        type: "error",
      });
    } else {
      setIsLoader(true);
      const { res, err } = await httpRequest<ApiResponse>({
        method: "put",
        path: `/api/user/update-email`,
        params: updateEmail,
      });
      if (res) {
        toast(res?.message || "Email changed", { type: "success" });
        setUpdateEmail({
          currentEmail: "",
          newEmail: "",
        }); // reset state
        setCnfrmNew(""); // reset state
        logOutWithOutApi();
        navigate("/login-web");
      } else {
        if (err?.status === 409) {
          toast(err?.message, { type: "error" });
          setIsLoader(false);

          return
        }
        toast(err?.message, { type: "error" });
      }
      setIsLoader(false);
    }
  };

  return (
    <div>
      <div className="shadow-lg bg-white rounded-none lg:rounded-xl  flex flex-col gap-2 mb-2 py-3 px-6 ">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green mb-3">
          Change email
        </h4>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col gap-2 custom-input"
        >
          {/* Input for current email */}
          <div className="flex flex-col gap-1">
            <label className=" font-size-15px  font-Poppins-Medium">
              Current email
            </label>
            <input
              className="w-full"
              type="text"
              name="currentEmail"
              id=""
              value={updateEmail.currentEmail}
              onChange={(e) =>
                setUpdateEmail({ ...updateEmail, currentEmail: e.target.value })
              }
            />
          </div>
          {/* Input for new email */}
          <div className="flex flex-col gap-1 ">
            <label className=" font-size-15px  font-Poppins-Medium">
              New email
            </label>
            <input
              className="w-full"
              type="text"
              name="newEmail"
              id=""
              value={updateEmail.newEmail}
              onChange={(e) =>
                setUpdateEmail({ ...updateEmail, newEmail: e.target.value })
              }
            />
          </div>
          {/* Input for confirming new email */}
          <div className="flex flex-col gap-1">
            <label className=" font-size-15px  font-Poppins-Medium">
              Confirm email
            </label>
            <input
              className="w-full"
              type="text"
              name="confirmEmail"
              id=""
              value={cnfrmNew}
              onChange={(e) => setCnfrmNew(e.target.value)}
            />
          </div>
          <div>
            <button
              className="mt-3 theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn  "
              type="submit"
              disabled={isLoader}
              style={{ color: "white" }}
            >
              Update
              {isLoader && (
                <CircularProgress
                  style={{ color: "#fff" }}
                  className="btn__loader"
                />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangeEmail;
