import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import { Link } from "react-router-dom";
import { MenuItem, Select } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import CreateDiscussionModal from "../../components/Discussions/CreateDiscussionModal";

import qs from "qs";
import { postTimeDifference } from "../../config/Helpers/helpers";
import { toast } from "react-toastify";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import DiscussionSkeleton from "../../components/Skeleton/DiscussionSkeleton";
import httpRequest from "../../config/Helpers/httpRequest";
interface ApiResponse {
  documents: Documents[];
  paginated: Paginated;
}
interface Paginated {
  totalPages: number;
  [key: string]: any;
}
interface Documents {
  title: string;
  description: string;
  status: string;
  firstName: string;
  lastName: string;
  role: string;
  [key: string]: any;
}
function Discussions() {
  const [postModal, setPostModal] = useState(false); // for create discussion modal show & hide
  const [discusstionType, setDiscusstionType] = useState<string>("public"); // for filter public & my discussion
  const [displayDiscussions, setDisplayDiscussions] = useState<Documents[]>([]); // for all discussions display
  const [isLoader, setIsLoader] = useState<boolean>(false); // for API loader
  const [totalPages, setTotalPages] = useState<number>(1); // for pagination total pages
  const [tempValue, setTempValue] = useState(""); // for temporary search state value
  const [isLoadComponent, setIsLoadComponent] = useState<boolean>(false);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    search: "",
  }); // query params for APIs

  const changeType = (e: string) => {
    // for change discussion type
    setQueryParams((prev) => ({
      ...prev,
      page: 1,
    }));
    setDiscusstionType(e);
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    // for search query
    let val = e.target.value;
    if (val !== " ") {
      setTempValue(val);
    }
  };

  // Functionality: API call for get all discussions
  const fetchAllDiscussions = async () => {
    setIsLoader(true); // show loader
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/discussion?discussionType=${discusstionType}&${qs.stringify(queryParams)}` });
    if (res) {
      setDisplayDiscussions(res?.documents || []); // set data
      setTotalPages(res?.paginated?.totalPages || 1); // set total page for pagination count
      setIsLoadComponent(true);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false); // hide loader
  };

  useEffect(() => {
    if (isLoadComponent) {
      const debounceTimer = setTimeout(() => {
        setQueryParams((prev) => ({ ...prev, search: tempValue, page: 1 }));
      }, 1000);

      return () => clearTimeout(debounceTimer);
    }
  }, [tempValue]);

  useEffect(() => {
    fetchAllDiscussions();
  }, [queryParams]);

  return (
    <>
      <HeaderGlobal />
      <div className="container-1480  h-3vw shadow-lg bg-white rounded-xl mb-2 pb-3 px-6 ">
        <h2 className="font-size-35px font-Poppins-SemiBold theme-color-green capitalize pt-4">
          Discussions
        </h2>
        <p className="font-size-15px  theme-color-green font-Poppins-Regular ">
          Ask questions, find support, and connect with the community.
        </p>
        <div className="flex mt-3">
          <button
            className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-18px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
            style={{ color: "white" }}
            onClick={() => setPostModal(true)}
          >
            New discussion
          </button>
        </div>
        <div className="flex justify-between items-end mt-3">
          <div className="flex gap-4 recently-connections items-center">
            <p
              className="font-size-15px theme-grey-type  font-Poppins-Regular  text-center "
              style={{ width: "4rem" }}
            >
              Sort by:
            </p>
            <Select
              value={discusstionType}
              onChange={(e) => changeType(e.target.value)}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
            >
              <MenuItem value="public">Public discussion</MenuItem>
              <MenuItem value="my">My discussion</MenuItem>
            </Select>
          </div>
          {/* top or recent */}
          <div className="relative paid-provider-connection-ipt ">
            <FontAwesomeIcon icon={faMagnifyingGlass} />
            <input
              className="font-Poppins-SemiBold font-size-14px"
              placeholder="Search"
              type="search"
              value={tempValue}
              onChange={handleSearch}
            />
          </div>{" "}
          {/* search filter by name */}
        </div>
        <hr className="mt-2" />

        {/* user discussion start */}
        {isLoader ? (
          <DiscussionSkeleton size={queryParams.limit} />
        ) : displayDiscussions && displayDiscussions?.length > 0 ? (
          displayDiscussions.map((item, index) => (
            <div key={index} className="flex row items-center gap-3 mb-5 mt-5">
              <img
                src={`${item?.profileImageUrl}`}
                // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                className="connections-img"
                alt={item?.firstName || ""}
              />
              {/* user image */}
              <div className="border-b-2 pb-3 flex item-center justify-between w-full items-center">
                <Link to={`/discussion/${item?._id}/view`} className="">
                  <h4 className="font-size-20px font-Poppins-Medium theme-color-green flex gap-3 items-center">
                    {item?.title || ""}
                  </h4>{" "}
                  {/* discussion title */}
                  <h4 className="font-size-17px font-Poppins-Regular theme-color-green flex gap-3 items-center capitalize">
                    {`${item?.firstName || ""} ${item?.lastName || ""}`}{" "}
                    {/* discussion owner name */}
                    <span className="font-size-15px font-Poppins-Regular theme-grey-type">
                      {item?.roleCategory || "-"} {/* discussion owner role */}
                    </span>
                  </h4>
                  <div>
                    <span className="font-size-15px font-Poppins-Regular theme-grey-type">
                      {item?.allComments || 0} Replies
                    </span>
                    <span className="font-size-15px font-Poppins-Regular theme-grey-type">
                      {" "}
                      . {postTimeDifference(item?.createdAt)}
                    </span>
                  </div>
                </Link>
              </div>
            </div>
          ))
        ) : (
          <p>No discussions</p>
        )}
        {/* user discussion end */}
        <PaginationComponet
          currentPage={queryParams.page}
          total={totalPages}
          setCurrentPage={(e) =>
            setQueryParams((prev) => ({ ...prev, page: e }))
          }
        />
      </div>

      {/* discussion create modal */}
      <CreateDiscussionModal
        isOpen={postModal}
        onClose={() => setPostModal(false)}
      />
    </>
  );
};

export default Discussions;