/**
 * FeedActivity Component
 *
 * This component fetches and displays user activity in the form of posts and comments.
 * It includes a header, sidebars, and an infinite scroll for loading activity data.
 *
 * File Name: FeedActivity.jsx
 * Author: Developer
 * Date: 2024-06-15
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../components/AllDashboardPagesComponents/SidebarGobal";
import RightSidebarFeed from "../../components/NewsFeeds/RightSidebarFeed";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "qs";
import { CircularProgress } from "@mui/material";
import InfiniteScroll from "react-infinite-scroll-component";
import PostContainer from "../../components/NewsFeeds/PostContainer";
import { useSelector, useDispatch } from "react-redux";
import {
  resetActivityPosts,
  setActivityPosts,
} from "../../config/Store/Reducers/postActivitySlice";
import httpRequest from "../../config/Helpers/httpRequest";
import { RootState } from "../../config/Store/store";
import AddressSocialDetail from "../../components/AllDashboardPagesComponents/Profile/AddressSocialDetail";
interface QueryParams {
  page: number;
  limit: number;
}
interface Documents {
  lastName: string;
  firstName: string;
  [key: string]: any;
}
interface Paginated {
  totalPages: number;
  [key: string]: any;
}
interface ApiResponse {
  documents: Documents[];
  paginated: Paginated;
}
// Define types for Redux state

interface FeedActivityProps { }
/**
 * FeedActivity Component
 *
 * Displays user activity, including posts and comments, with infinite scroll functionality.
 */
const FeedActivity: React.FC<FeedActivityProps> = () => {
  const dispatch = useDispatch();
  // const { allPosts } = useSelector((store: RootState) => store.postActivitySlice);
  const { allPosts } = useSelector((store: RootState) => store.postActivity);


  let { uId } = useParams<{ uId: string }>(); // Extracts the user ID from the URL parameters

  /**
   * LoaderComponents
   *
   * Renders a full page loader.
   */
  const LoaderComponents: React.FC = () => (
    // full page loader
    // return (
    <div className="full_page_loader">
      <CircularProgress style={{ color: "#fff" }} />
    </div>
    // );
  )

  const [category, setCategory] = useState<string>("posts"); // State for toggling between posts and comments
  const [isLoader, setIsLoader] = useState<boolean>(false); // State for managing the loading indicator
  const [totalPages, setTotalPages] = useState<number>(1); // State for storing total pages count
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 10,
  }); // State for storing query parameters for API calls

  // Functionality: Fetches user activity (posts or comments) from the API.
  const fetchData = async () => {
    setIsLoader(true);
    // const { res, err } = await httpRequest({
    //   path: `/api/news-feed/activity/${category}/${uId}?${qs.stringify(
    //     queryParams
    //   )}`,
    // });
    let path = "";
    if (category === "posts") {
      path = `/api/posts/activity/${uId}?${qs.stringify(queryParams)}`;

    }
    else {

      path = `/api/social/activity/post/${uId}?${qs.stringify(queryParams)}`
    }

    // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/posts/activity/${uId}?${qs.stringify(queryParams)}` });
    const { res, err } = await httpRequest<ApiResponse>({ path: path });

    if (res) {
      let discussionsList = res?.documents || [];

      dispatch(setActivityPosts(discussionsList));
      setTotalPages(res?.paginated?.totalPages || 1); // Sets total pages for infinite scroll or pagination
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  // Resets activity posts when the component mounts
  useEffect(() => {
    dispatch(resetActivityPosts());
  }, []);

  // Fetches activity data when the component mounts or when the user ID, category, or query parameters change
  useEffect(() => {
    fetchData();
  }, [uId, category, queryParams]);

  return (
    <>
      <HeaderGlobal />
      {isLoader && <LoaderComponents />}
      <div className="container-1480 block justify-between h-3vw lg:flex md:flex">
        <SidebarGobal />
        <div className="news-feed-post">
          <div className="shadow-lg bg-white rounded-xl mb-2 py-3 px-6 flex justify-between items-center">
            <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
              Activity
            </h4>
            <div className="flex gap-2">
              <button
                onClick={() => {
                  dispatch(resetActivityPosts());
                  setQueryParams((prev) => ({ ...prev, page: 1 }));
                  setCategory("posts");
                }}
                disabled={category === "posts"}
                style={{ color: category === "posts" ? "#fff" : "#004540" }}
                className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn feeds-btn-2  ${category === "posts" ? "theme-bg-green" : ""
                  }`}
              >
                Posts
              </button>
              <button
                onClick={() => {
                  dispatch(resetActivityPosts());
                  setQueryParams((prev) => ({ ...prev, page: 1 }));
                  setCategory("comments");
                }}
                disabled={category === "comments"}
                style={{ color: category === "comments" ? "#fff" : "#004540" }}
                className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  ${category === "comments" ? "theme-bg-green" : ""
                  }`}
              >
                Comments
              </button>
            </div>
            {/* change type discussions & comment */}
          </div>
          <InfiniteScroll
            dataLength={allPosts.length}
            next={() =>
              setQueryParams((prev) => ({ ...prev, page: prev.page + 1 }))
            }
            hasMore={queryParams.page < totalPages}
            loader={isLoader && <LoaderComponents />}
            endMessage={""}
          >
            {allPosts && allPosts.length > 0 ? (
              allPosts.map((item: any, index: number) => (
                <PostContainer
                  item={item}
                  index={index}
                  activityPage={true}
                  category={category}
                />
              ))
            ) : (
              <p>No Post</p>
            )}
          </InfiniteScroll>
        </div>
        {/* <RightSidebarFeed /> */}
        <AddressSocialDetail
            unpaidModalShow={false}
            userId={uId}

          />
      </div>
    </>
  );
}

export default FeedActivity;
