import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';

export const fetchRecentMessages = createAsyncThunk(
    'messages/fetchRecentMessages',
    // '/api/chat/all',
    async (token) => {
        let config = {
            headers: {
                Authorization: `bearer ${token}`
            },
        };
        try {
            // const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/message/conversations`, config);
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/chat/all`, config);
            // return response.data;
            return response?.data?.documents;

        }
        catch (err) {
            throw err;
        }
    },
);

const messagesSlice = createSlice({
    name: "MESSAGES",
    initialState: {
        isLoader: false,
        isError: false,
        recentMessages: [],
        unReadMessages: 0,
    },
    reducers: {
        updateUnReadMessages: (state) => {
            state.unReadMessages = state.unReadMessages + 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchRecentMessages.pending, (state) => {
                state.isLoader = true;
            })
            .addCase(fetchRecentMessages.fulfilled, (state, { payload }) => {
                console.log(payload, "payload")
                let { allConversations = [], unreadCount = 0 } = payload;
                state.isLoader = false;
                state.recentMessages = allConversations;
                state.unReadMessages = unreadCount;
            })
            .addCase(fetchRecentMessages.rejected, (state, { error }) => {
                state.isLoader = false;
                state.isError = error?.message || "ERROR";
            });
    },
});

export const { updateUnReadMessages } = messagesSlice.actions;
export default messagesSlice.reducer;