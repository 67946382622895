import React, { useState } from 'react';

import JobCancelModal from "../JobsComponents/JobCancelModal";
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
interface DeleteReviewProps {
    extraStyle?: React.CSSProperties; // Optional object for CSS styles
    buttonClass?: string; // Optional string for button class names
    uId?: string; // Optional string for user ID
    jobId?: string; // Optional string for job ID
    onSuccessFunctions?: () => void; // Optional function for success callback
}
interface ApiResponse {
    message: string;
    [key: string]: any;
}

// Convert the DeleteReview component to TypeScript
const DeleteReview: React.FC<DeleteReviewProps> = ({
    extraStyle = {},
    buttonClass = "",
    uId = "",
    jobId = "",
    onSuccessFunctions = () => { }
}) => {
    const [deleteConfrim, setDeleteConfirmModal] = useState(false); // state for comfirmation of delete review
    const [reviewDeleteLoader, setReviewDeleteLoader] = useState(false); // state for review delete loader

    // Functionality: API call to delete a review on behalf of a user ID and job ID
    const deleteReview = async () => {
        setReviewDeleteLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "delete", path: `/api/review/delete/${jobId}`, });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            onSuccessFunctions();
            setDeleteConfirmModal(false);
        } else {
            if (err?.status === 400) {

                toast(err?.message?.message, { type: "error" }); // api error
                setReviewDeleteLoader(false);

                return

            }
            console.log(err?.message?.message, "asdasdas")
            toast(err?.message, { type: "error" }); // api error
        }
        setReviewDeleteLoader(false);
    };
    return (
        <>
            <button style={extraStyle} onClick={() => setDeleteConfirmModal(true)} className={buttonClass}>Delete</button>
            {deleteConfrim &&
                <JobCancelModal
                    onClose={() => setDeleteConfirmModal(false)}
                    confirmCancel={deleteReview}
                    status="cancel"
                    title="Are you sure you want to delete this review?"
                    loader={reviewDeleteLoader}
                />
            }
        </>
    );
};

export default DeleteReview;