/**
 * DiscussionsRequest Component
 *
 * File: DiscussionsRequest.jsx
 * Component Purpose: This component manages and displays a list of discussion requests for admin. It allows the admin to approve or decline discussions and view detailed information in a modal.
 *
 * Author: Developer
 * Date: 07-06-24
 */

import React, { useState, useEffect } from "react";
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../../components/AdminComponents/AdminSidebar";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import closeIcon from "../../../assets/img/closeIcon.png";

import qs from "qs";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { truncateString } from "../../../config/Helpers/helpers";
import moment from "moment";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import httpRequest from "../../../config/Helpers/httpRequest";


interface Discussion {
  _id: string;
  title: string;
  description: string;
  firstName: string;
  lastName: string;
  role: string;
  createdAt: string;
}

interface ApiResponse {
  documents: Discussion[];
  paginated: {
    totalPages: number;
  };
  message: string;
  [key: string]: any;
}
interface StatusLoader {
  newStatus: string;
  id: string;
}

function DiscussionsRequest() {
  const statusList: string[] = ["approved", "pending", "declined"]; // status list for button status list change

  const [isLoader, setIsLoader] = useState<boolean>(false); // for API loader
  const [statusLoader, setStatusLoader] = useState<StatusLoader | boolean>(false); // for status change loader
  const [postModal, setPostModal] = useState<{ title: string; description: string } | false>(false); // for display modal
  const [displayDiscussions, setDisplayDiscussions] = useState<Discussion[]>([]); // for all discussions display
  const [totalPages, setTotalPages] = useState<number>(1); // for pagination total pages
  const [status, setStatus] = useState<string>("approved"); // for API discussion status [approved, pending, declined]
  const [queryParams, setQueryParams] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 10,
  }); // query params for APIs

  // Handle status change
  const handleChangeStatus = (e: string) => {
    setStatus(e);
    setQueryParams((prev) => ({
      ...prev,
      page: 1,
    }));
  };

  // Set modal state for displaying detailed discussion
  const setForModalState = (title: string, description: string) => {
    setPostModal({ title, description });
  };

  // Functionality: API call for change discussion status
  const statusChange = async (newStatus: string, id: string) => {
    setStatusLoader({ newStatus, id }); // show loader
    const { res, err } = await httpRequest<ApiResponse>({
      method: "put",
      path: "/api/admin/discussion/update-status",
      params: { discussionId: id, status: newStatus },
    });
    if (res) {
      toast(res?.message || "SUCCESS", { type: "success" }); // success message
      setStatus(newStatus);
      setQueryParams((prev) => ({
        ...prev,
        page: 1,
      }));
    } else {
      toast(err?.message, { type: "error" }); // if error then display the error
    }
    setStatusLoader(false); // hide loader
  };

  // Functionality: API call for get all discussions by status only for admin
  const fetchDiscussions = async () => {
    setIsLoader(true); // show loader
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/admin/discussion?status=${status}&${qs.stringify(queryParams)}`,
    });
    if (res) {
      setDisplayDiscussions(res?.documents || []); // set data
      setTotalPages(res?.paginated?.totalPages || 1); // set total page for pagination count
    } else {
      toast(err?.message, { type: "error" }); // if error then display the error
    }
    setIsLoader(false); // hide loader
  };

  useEffect(() => {
    fetchDiscussions();
  }, [queryParams, status]);
  const capitalizeFirstLetter = (item: string) => {
    return item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
};
  return (
    <div className="flex font-poppins-regular">
      <AdminSidebar />
      {isLoader && (
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      )}
      <div className="w-4/5 ml-auto">
        <AdminHeader />
        <div className="pt-24 Admin-main-operator-parent">
          <div className=" pl-6 pr-10">
            <h2 className="font-size-36px font-Poppins-Medium">Discussions</h2>
            <p> <span className="font-size-16px font-Poppins-Bold pb-5">Dashboard /</span> <span className="font-Poppins-Medium"> Discussions</span></p>

            <div className="">
              <div className="flex gap-2 my-5">
                {statusList.map((item) => (
                  <button
                    disabled={item === status}
                    onClick={() => handleChangeStatus(item)}
                    key={item}
                    className={` font-size-18px font-Poppins-Medium discussion-btn-admin ${item === status
                      ? "theme-bg-green text-white"
                      : "theme-color-green"
                      }`}
                  >
                    {capitalizeFirstLetter(item)} discussions
                  </button>
                ))}
              </div>
              <div className="main-Admin-status-action">
                <ul
                  style={{ gridTemplateColumns: "25% 23% 21% 21% 10%" }}
                  className="Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                >
                  <li className="text-white text-left">Title</li>
                  <li className="text-white">Posted by</li>
                  <li className="text-white">Created date</li>
                  <li className="text-white">Status</li>
                  <li className="text-white text-end">Action</li>
                </ul>
              </div>
              {displayDiscussions && displayDiscussions.length > 0 ? (
                displayDiscussions.map((item, index) => (
                  <div key={index} className="main-Admin-status-content mt-5">
                    <ul
                      style={{ gridTemplateColumns: "25% 23% 21% 21% 10%" }}
                      className="Admin-status-content-Role text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                    >
                      <li className="text-left">
                        {truncateString(item?.title, 50) || ""}
                      </li>

                      <li className="capitalize flex flex-row gap-2 items-center">
                        <span className=" ">

                          {`${item?.firstName || ""} ${item?.lastName || ""}`}
                        </span >

                        <span className="font-size-15px theme-grey-type block">
                          {item?.role || ""}
                        </span>
                      </li>
                      <li>{moment(item?.createdAt).format("DD-MM-YYYY")}</li>
                      <li className="flex justify-center">
                        {(status === "pending" || status === "declined") && (
                          <button
                            disabled={!!statusLoader}
                            className="font-size-14px font-Poppins-Medium discussion-btn-admin theme-bg-green text-white"
                            onClick={() => statusChange("approved", item?._id)}
                          >
                            {typeof statusLoader === "object" && statusLoader.newStatus === "approved" &&
                              statusLoader?.id === item?._id ? (
                              <CircularProgress
                                style={{ width: 14, height: 14, color: "#fff" }}
                              />
                            ) : (
                              "Approve"
                            )}
                          </button>
                        )}
                        {(status === "pending" || status === "approved") && (
                          <button
                            disabled={!!statusLoader}
                            className="font-size-14px font-Poppins-Medium discussion-btn-admin bg-[#DF0000] text-white"
                            onClick={() => statusChange("declined", item?._id)}
                          >
                            {typeof statusLoader === "object" && statusLoader.newStatus === "declined" &&
                              statusLoader?.id === item?._id ? (
                              <CircularProgress
                                style={{ width: 14, height: 14, color: "#fff" }}
                              />
                            ) : (
                              "Decline"
                            )}
                          </button>
                        )}
                      </li>
                      <li className="flex justify-end">
                        <button
                          onClick={() =>
                            setForModalState(item?.title, item?.description)
                          }
                        >
                          <FontAwesomeIcon icon={faEye} />
                        </button>
                      </li>
                    </ul>
                  </div>
                ))
              ) : (
                <div className="main-Admin-status-content mt-5">
                  <p className="text-center">No Items</p>
                </div>
              )}
              {/* pagination component */}
              <PaginationComponet
                currentPage={queryParams.page}
                total={totalPages}
                setCurrentPage={(e) =>
                  setQueryParams((prev) => ({ ...prev, page: e }))
                }
              />
            </div>
          </div>
        </div>
      </div>

      {/* discussion crete modal */}
      {postModal && (
        <div className="ovrlayModal modal">
          <div className="createpost-modal absolute z-20">
            <button
              className="absolute -top-5 font-size-24px"
              style={{ right: "-10px" }}
              onClick={() => setPostModal(false)}
            >
              <img src={closeIcon} className="close-icon-discussion" alt="" />
            </button>{" "}
            {/* close post modal */}
            <div>
              <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green text-center gap-2 capitalize">
                Discussion
              </h4>
              <hr color="#707070" className="mt-3" />
            </div>
            <div className="create-post-text-area mt-4 mb-4">
              <h4 className="font-size-20px font-Poppins-SemiBold  capitalize mb-4">
                {postModal?.title || ""}
              </h4>
              <p className="font-size-17px theme-grey-type font-Poppins-Regular">
                {postModal?.description || ""}
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DiscussionsRequest;
