/**
 * PublicJobs Component
 * 
 * Component for managing public job listings based on different tabs (Opened, Shortlisted, Applied).
 * Allows users to view and filter jobs, switch between tabs, and display job details.
 * 
 * @file PublicJobs.js
 * @component PublicJobs
 * @description Renders a job board with tabs for different job statuses (Opened, Shortlisted, Applied).
 *              Provides functionality to fetch jobs based on tab selection, apply filters, and display job details.
 * 
 * @author [Your Name]
 * @date 08-07-24
 */

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import JobCurrentStatus from "../../components/AllDashboardPagesComponents/JobCurrentStatus";
import AdminActiveJobsSpecific from "../../components/AdminComponents/AdminActiveJobsSpecific";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice"
import { useDispatch } from "react-redux"
import { toast } from 'react-toastify';
import qs from "qs";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import httpRequest from "../../config/Helpers/httpRequest";
interface Job {
    _id: string;
    services: string[];
    allServices: string[];
    contactNumber: string;
    streetAddress: string;
    suburb:string;
    state: string;
    postCode: string;
    repeatCycle: number;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    budget: string;
    description: string;
    jobStatus: string;
    createdAt: string;
    numberOfApplicants?: number;
}

interface QueryParams {
    page: number;
    limit: number;
    // sort: string;
    date: string;
    location: string;
    serviceIds: string;  // Update this to string
}
interface paginated {
    totalPages: number;
    [key: string]: any
}
interface ApiResponse {
    documents: []
    paginated: paginated;
    [key: string]: any;

}
const PublicJobs = () => {
    const location = useLocation();
    const { state = {} } = location;
    const dispatch = useDispatch();

    const [activeTab, setActiveTab] = useState<string>(state?.tab || "opened");
    const [jobsLoader, setJobsLoader] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(1);

    const [allJobs, setAllJobs] = useState([]); // all opened jobs
    const [selectedJobs, setSelectedJobs] = useState<any>(null);

    let tabOptions = [
        { title: "Opened", key: "opened", dotClass: "dot-act" },
        { title: "Shortlisted", key: "shortlisted", dotClass: "dot-comp" },
        { title: "Applied", key: "applied", dotClass: "dot-completed" },
    ];

    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: 1,
        limit: 10,
        // sort: "desc",
        date: "",
        location: "",
        serviceIds
            : ""
    }); // query params for API

    // Functionality: API call for all public jobs with status (open)
    const fetchActiveJobs = async () => {
        setJobsLoader(true);
        const queryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job/public?${queryString}` });
        if (res) {
            // setAllJobs(res?.documents)
            setAllJobs(prevJobs => [...prevJobs, ...res?.documents]);

            setTotalPages(res?.paginated?.totalPages || 1);


        } else {
            toast(err?.message, { type: "error" });
        }
        setJobsLoader(false);
    };

    // Functionality: API call for all public jobs with status (shortlisted)
    const fetchShortListedJobs = async () => {
        setJobsLoader(true);
        const queryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job/shortlisted?${queryString}` });
        if (res) {
            setAllJobs(prevJobs => [...prevJobs, ...res?.documents]);

            setTotalPages(res?.paginated?.totalPages || 1);
            // setAllJobs(prevJobs => [...prevJobs, ...res?.jobs[0]?.data]);
            // let count = res?.jobs[0]?.metadata[0]?.total || 0;
            // setTotalPages(Math.ceil(count / queryParams.limit));
        } else {
            toast(err?.message, { type: "error" });
        }
        setJobsLoader(false);
    };

    // Functionality: API call for all public jobs with status (applied)
    const fetchAppliedJobs = async () => {
        setJobsLoader(true);
        const queryString = qs.stringify(queryParams, { arrayFormat: 'comma' });
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job/applied?${queryString}` });
        if (res) {
            setAllJobs(prevJobs => [...prevJobs, ...res?.documents]);

            setTotalPages(res?.paginated?.totalPages || 1);
            // setAllJobs(prevJobs => [...prevJobs, ...res?.jobs[0]?.data]);
            // let count = res?.jobs[0]?.metadata[0]?.total || 0;
            // setTotalPages(Math.ceil(count / queryParams.limit));
        } else {
            toast(err?.message, { type: "error" });
        }
        setJobsLoader(false);
    };

    const handlePageChange = (page: number) => {
        setQueryParams({
            ...queryParams,
            page
        });
    }; // page change

    const changeTab = async (tab: string) => {
        await setAllJobs([]);
        setActiveTab(tab);
        setQueryParams({
            ...queryParams,
            page: 1,
            date: "",
            location: "",
            serviceIds: ""
        });
    }; // tab change

    const handleFilterSubmit = (obj: { date: string; location: string; service: string[] }) => {
        setAllJobs([]);
        const { date, location, service } = obj;
        const formattedDate = date ? new Date(date).toISOString().split('T')[0] : "";
        const servicesString = service.join(',');

        setQueryParams({
            ...queryParams,
            page: 1,
            date: formattedDate,
            location: location,
            serviceIds: servicesString
        });
    };
    const handleFilterClear = () => {
        setAllJobs([]);
        setQueryParams({
            ...queryParams,
            page: 1,
            date: "",
            location: "",
            serviceIds: ""
        });
    }; // filter clear

    useEffect(() => {
        (async () => {
            const { res, err }: { res?: ApiResponse; err?: string } = await httpRequest({
                path: "/api/services/all",
            });


            if (res && res.documents) {
                dispatch(setAllServices(res.documents)); // Directly dispatch documents without flattening for now
            } else {
                toast(err || "An error occurred", { type: "error" });
            }

        })();
    }, [dispatch]);
    useEffect(() => {
        if (allJobs.length > 0) {
            setSelectedJobs(allJobs[0]);
        }
        else {
            setSelectedJobs(null);
        }
    }, [allJobs]); // set selected jobs for right side


    useEffect(() => {
        if (activeTab === "opened") {
            fetchActiveJobs();
        }
        else if (activeTab === "shortlisted") {
            fetchShortListedJobs();
        }
        else {
            fetchAppliedJobs();
        }
    }, [queryParams, activeTab]);

    return (
        <div className="">
            <HeaderGlobal />
            <div className="container-1480 h-3vw">
                <div className=" Admin-main-operator-parent">

                    <h2 className="font-size-36px font-Poppins-Medium"> Job board</h2>

                    <div className="mt-8">
                        <div>
                            <div className="admin-active-job-handler">
                                <div>
                                    <div className="active-jobs-tabs">
                                        {tabOptions.map(item => (
                                            <button key={item.key} className={activeTab === item.key ? 'font-size-15px font-Poppins-Regular active' : ' font-size-15px font-Poppins-Regular'} onClick={() => changeTab(item.key)}><span className={item.dotClass}></span> {item.title}</button>
                                        ))}
                                    </div>
                                    <div className="active-jobs-content">
                                        <div className="admin-job-active">
                                            <JobCurrentStatus
                                                jobs={allJobs}
                                                totalPages={totalPages}
                                                currentPage={queryParams.page}
                                                setCurrentPage={handlePageChange}
                                                loader={jobsLoader}
                                                setSelectedJobs={setSelectedJobs}
                                                selectedJobs={selectedJobs || []}
                                                activeTab={activeTab}
                                                setActiveTab={changeTab}
                                                clearFilter={handleFilterClear}
                                                submit={handleFilterSubmit}
                                            />
                                            <div className="">
                                                <AdminActiveJobsSpecific
                                                    loader={jobsLoader}
                                                    item={selectedJobs}
                                                    activeTab={activeTab}
                                                    setActiveTab={changeTab}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PublicJobs;