import React, { FC } from 'react'; // Ensure FC is imported

import { toast } from "react-toastify";
import { getCookie } from "../../config/Helpers/helpers";
import axios from "axios";
import aibtn from "../../assets/img/aibtn.svg";

interface RewriteWithAiProps {
    text?: string;
    setText: (text: string) => void;
    setLoader: (loading: boolean) => void;
    isProfile?: boolean;
    extrastyle?: React.CSSProperties;
}

interface ApiResponse {
    paraphrased?: string;
}

const RewriteWithAi: FC<RewriteWithAiProps> = ({
    text = "",
    setText,
    setLoader,
    isProfile = false,
    extrastyle = {},
}) => {

    let token = getCookie(); // Retrieve token from cookie
    let config = { // API configuration object header
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    // Functionality: API call for AI response rewrite with AI
    const sendFeedBack = async () => {
        if (!text) {
            toast("Please enter your prompt", { type: "error" });
            return;
        }
        setLoader(true);
        try {
            const response = await axios.post<ApiResponse>(`${process.env.REACT_APP_CHATBOT}/write/${isProfile ? "rewrite_profile_ndisync_api" : "rewrite_ndisync_api"}/`, { query: text }, config);
            console.log("ttttt", response?.data?.paraphrased);
            setText(response?.data?.paraphrased || "");
        } catch (err: any) {
            console.log("ERROR", err);
            toast(err.response?.data?.message || err.response?.data?.error || "Something went wrong.", { type: "error" });
        }
        setLoader(false);
    };
    return (
        <button
            type="button"
            className="flex gap-2 font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn"
            onClick={sendFeedBack}
            style={extrastyle}
        >
            <img src={aibtn} alt="" /> Rewrite with AI
        </button>
    );
};

export default RewriteWithAi;