import React, { useState, useEffect } from "react";
import PaymentSummary from '../../components/PaymentFormComponents/PaymentSummary';
import PaymentRegisterForm from '../../components/PaymentFormComponents/PaymentRegisterForm';
import Stripecheck from '../../components/PaymentFormComponents/Stripecheck';
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import Paymentsuccess from "../../components/PaymentFormComponents/Paymentsuccess";
import { getCookie } from "../../config/Helpers/helpers";
import { RootState } from '../../config/Store/store';
interface User {
  subscriptionId?: string;
  firstName: string;
  lastName: string;
  email: string;
  address: string;
  suburb:string;
  state: string;
  postCode: string;
  phonenumber: string;
  role: string;
  password: string;
}

const Paymentform: React.FC = () => {
  const { premium, upgrade } = useSelector((store: RootState) => store.auth);
  const { personalData } = useSelector((store: RootState) => store.userProfile);
  const token = getCookie() || false;
  const [step, setStep] = useState(1);

  const [secretKey, setSecretKey] = useState("");
  const [subscriptionId, setSubscriptionId] = useState("");

  const [user, setUser] = useState<User>({ // form state
    subscriptionId: premium?.id,
    firstName: "",
    lastName: "",
    email: "",
    address: "",
    suburb: "",
    state: "",
    postCode: "",
    phonenumber: "",
    role: "",
    password: "",
  });

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const BannerBgMapImg = "imgs/banner-bg-map.png";

  useEffect(() => {
    if (token) {
      setUser({
        subscriptionId: premium?.id,
        firstName: personalData?.firstName || "",
        lastName: personalData?.lastName || "",
        email: personalData?.email || "",
        address: personalData?.address || "",
        suburb: personalData?.suburb || "",
        state: personalData?.state || "",
        postCode: personalData?.postCode || "",
        phonenumber: personalData?.phonenumber || "",
        role: personalData?.role || "",
        password: personalData?.password || "",
      });
      nextStep();
    }
  }, [token]);
  useEffect(() => {
    if (upgrade) {
      setUser({
        subscriptionId: premium?.id,
        firstName: upgrade?.firstName || "",
        lastName: upgrade?.lastName || "",
        email: upgrade?.email || "",
        address: upgrade?.address || "",
        suburb: upgrade?.suburb || "",
        state: upgrade?.state || "",
        postCode: upgrade?.postCode || "",
        phonenumber: upgrade?.phonenumber || "",
        role: upgrade?.role || "",
        password: upgrade?.password || "",
      });
    }
  }, [upgrade]);

  console.log(premium, "subscriptionIdsubscriptionIdsubscriptionId")
  return (
    <section className="w-full relative">
      {/* <Header /> */}
      {/* <img
        decoding="async"
        className="w-full h-full absolute top-0 left-0 z-10 opacity-30 bg-texture-banner"
        src={'https://edgie.backslashwebs.com/imgs/banner-bg-texture.png'}
        alt=""
      /> */}
      <div className="container-custom-3 h-full relative flex items-center">
        {/* <img
          decoding="async"
          className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 opacity-20 z-10 w-4/6"
          src={'https://edgie.backslashwebs.com/imgs/banner-bg-map.png'}
          alt=""
        /> */}
        <div className="payment-form-page-content-parent w-full bg-white rounded-lg relative z-20 shadow-lg ">
          {/* <div className="payment-form-progress-bar relative">
            <div className="payment-form-dashed-border">
              <img
                className="mx-auto"
                src={'https://edgie.backslashwebs.com/imgs/dashed-border-img.png'}
                alt=""
              />
            </div>
            <span
              className={`step-payment-form step-payment-form-one absolute w-max flex flex-col items-center ${step > 0 ? "step-payment-form-active" : ""
                }`}
            >
              <span className="numbered-circle flex items-center justify-center leading-none font-Poppins-SemiBold">
                1
              </span>
              <span className="font-Poppins-SemiBold font-size-login-18px text-white">
                Account
              </span>
            </span>
            <span
              className={`step-payment-form step-payment-form-two absolute w-max flex flex-col items-center ${step > 1 ? "step-payment-form-active" : ""
                }`}
            >
              <span className="numbered-circle flex items-center justify-center leading-none font-Poppins-SemiBold">
                2
              </span>
              <span className="font-Poppins-SemiBold font-size-login-18px text-white">
                Payment
              </span>
            </span>
            <span
              className={`step-payment-form step-payment-form-three absolute w-max flex flex-col items-center ${step > 2 ? "step-payment-form-active" : ""
                }`}
            >
              <span className="numbered-circle flex items-center justify-center leading-none font-Poppins-SemiBold">
                3
              </span>
              <span className="font-Poppins-SemiBold font-size-login-18px text-white">
                Confirmation
              </span>
            </span>
          </div> */}
          <div className="">
            {/* {step === 1 && (
              <>
                <div className="payment-form-page-content-left flex flex-col">
                  <PaymentRegisterForm
                    setSecretKey={setSecretKey}
                    nextStep={nextStep}
                    user={user}
                    setUser={setUser}
                    setSubscriptionId={setSubscriptionId}
                  />
                </div>
                <PaymentSummary />
              </>
            )} */}
            {step === 2 && (
              <>
  
                  <Stripecheck
                    secretKey={secretKey}
                    nextStep={nextStep}
                    subscriptionId={subscriptionId}
                    setSubscriptionId={setSubscriptionId}
                  />
    

                {/* <PaymentSummary /> */}
              </>
            )}
            {step === 3 && (
              <>
                <Paymentsuccess />
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Paymentform;

