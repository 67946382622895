import React, { useState, useEffect } from "react";
import {
  faArrowRight,
  faMessage,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import {
  postTimeDifference,
  truncateString,
} from "../../../config/Helpers/helpers";
import qs from "qs";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../../config/Helpers/httpRequest";

interface PostActivityProps {
  uId?: string;
}

// Define the types for the data item
interface DataItem {
  _id: string;
  firstName?: string;
  lastName?: string;
  createdAt?: string;
  attachments?: { url: string }[];
  caption?: string;
  allReacts?: number;
  allComments?: number;
  title?: string;
  description?: string;
  [key: string]: any;
}
interface ApiResponse {
  documents: DataItem[];
}
const PostActivity: React.FC<PostActivityProps> = ({ uId = "" }) => {

  const [category, setCategory] = useState<string>("news-feed"); // for category toggle
  const [data, setData] = useState<DataItem[]>([]); // for data
  const [isLoader, setIsLoader] = useState<boolean>(false); // for API loader
  const [queryParams, setQueryParams] = useState<{ page: number; limit: number }>({
    page: 1,
    limit: 3,
  }); // query params for APIs

  // Functionality: API call for get user activity post & discussions
  useEffect(() => {
    (async () => {
      setIsLoader(true);
      let path = "";
      if (category === "news-feed") {
        path = `/api/posts/activity/${uId}?${qs.stringify(queryParams)}`;
      }
      else {
        path = `/api/discussion/activity-discussions/${uId}?${qs.stringify(queryParams)}`;
      }
      // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/posts/activity/${uId}?${qs.stringify(queryParams)}` });
      const { res, err } = await httpRequest<ApiResponse>({ path: path });
      if (res) {
        setData(res?.documents || []);
      } else {
        toast(err?.message, { type: "error" }); // api error
      }
      setIsLoader(false);
    })();
  }, [uId, category]);

  return (
    <>
      <div className="shadow-lg bg-white rounded-xl mb-2 py-3 px-6  ">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
          Activity
        </h4>

        <div className="flex gap-3 items-center">
          <button
            onClick={() => {
              setData([]);
              setCategory("news-feed");
            }}
            disabled={category === "news-feed"}
            style={{ color: category === "news-feed" ? "#fff" : "#004540" }}
            className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn feeds-btn-2 mt-3 ${category === "news-feed" ? "theme-bg-green" : ""
              }`}
          >
            Post
          </button>
          <button
            onClick={() => {
              setData([]);
              setCategory("discussion");
            }}
            disabled={category === "discussion"}
            style={{ color: category === "discussion" ? "#fff" : "#004540" }}
            className={`btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn mt-3 ${category === "discussion" ? "theme-bg-green" : ""
              }`}
          >
            Discussion
          </button>
        </div>
        {/* toggle category */}

        {isLoader && (
          <div className="mt-3 text-center">
            <CircularProgress
              style={{ width: 20, height: 20, color: "#004540" }}
            />
          </div>
        )}

        {data && data.length > 0 ? (
          data.map((item, index) =>
            category === "news-feed" ? (
              <div key={index}>
                <div className="pt-4 pb-2">
                  <div className="flex gap-1">
                    <h4 className="font-size-18px font-Poppins-SemiBold theme-grey-type capitalize">
                      {`${item?.firstName || ""} ${item?.lastName || ""}`}
                    </h4>
                    <span className="font-size-18px font-Poppins-Regular theme-grey-type">
                      {" "}
                      post this . {postTimeDifference(item?.createdAt || "")}
                    </span>
                  </div>
                  <div
                    className={`flex gap-2 py-1 Activity-Post-view`}
                    style={{
                      gridTemplateColumns: item?.attachments && item?.attachments[0]
                        ? "15% 75%"
                        : "90%",
                      height: item?.attachments && item?.attachments[0] ? "130px" : "auto",
                    }}
                  >
                    {item?.attachments && item?.attachments[0] && (
                      <img
                        src={`${item?.attachments[0]?.url}`}
                        // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.attachments[0]?.url}`}
                        alt=""
                      />
                    )}
                    <p className="font-size-18px font-Poppins-Regular theme-grey-type">
                      {truncateString(item?.caption, 270)}
                    </p>
                  </div>
                  <ul className="flex gap-2 py-1">
                    <li className="total-like flex gap-1  items-center">
                      <span style={{ backgroundColor: "#707070" }}>
                        <FontAwesomeIcon
                          color="white"
                          icon={faThumbsUp}
                          flip="horizontal"
                        />
                      </span>{" "}
                      {/* if post reacts is exist then active color apply */}
                      <button className="font-size-15px theme-grey-type  font-Poppins-Medium">
                        {item?.allReacts || ""}
                      </button>{" "}
                      {/* post reacts count */}
                    </li>
                    <li className="total-like flex gap-1  items-center">
                      <FontAwesomeIcon icon={faMessage} color="#707070" />
                      <button className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
                        {item?.allComments || 0} comments
                      </button>{" "}
                      {/* post comment count */}
                    </li>
                  </ul>
                </div>
                <hr color="#707070" />
              </div>
            ) : (
              <div key={index}>
                <div className="pt-4 pb-2">
                  <Link
                    to={`/discussion/${item?._id}/view`}
                    className="font-size-20px font-Poppins-SemiBold theme-color-green"
                  >
                    {item?.title || ""}
                  </Link>
                  <p className="font-size-17px theme-grey-type font-Poppins-Regular">
                    {truncateString(item?.description, 270)}
                  </p>
                </div>
                <hr color="#707070" />
              </div>
            )
          )
        ) : (
          <p>No {category === "news-feed" ? "Post" : "Discussion"}</p>
        )}

        <div className="flex justify-center items-center pt-3">
          <Link
            to={
              category === "news-feed"
                ? `/${uId}/feed-activity`
                : `/${uId}/discussion-activity`
            }
            className="font-size-15px theme-grey-type  font-Poppins-Medium flex gap-2 items-center on-hover"
          >
            Show all activity
            <FontAwesomeIcon icon={faArrowRight} />{" "}
          </Link>
        </div>
      </div>
    </>
  );
};

export default PostActivity;