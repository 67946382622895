import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./index.css";
import "../src/assets/css/Integrate.css"; // Import additional styles
import "../src/assets/css/style.css";
import "../src/assets/css/admin.css";
import "../src/assets/css/adminresponsive.css";
import "../src/assets/css/paidprovider.css";
import "../src/assets/css/paidproviderresponsive.css";
import "../src/assets/css/freeprovider.css";
import "../src/assets/css/alldashboardpages.css";
import "../src/assets/css/participant.css";
import "../src/assets/css/scheduler.css";
import "../src/assets/css/responsive.css";
import "../src/assets/css/freeproviderresponsive.css";
import "../src/assets/css/alldashboardpagesresponsive.css";
import "../src/assets/css/participantresponsive.css";
import "../src/assets/css/schedulerresponsive.css";
import { ToastOptions } from 'react-toastify';

import PageNotFound from "./components/Error/PageNotFound"; // Import Error page component

/* Admin Dashboard */
import AdminDashboard from "./pages/AdminPages/Admin";
import AdminOperators from "./pages/AdminPages/AdminOperators";
import AdminPaidUsers from "./pages/AdminPages/AdminPaidUsers";
import AdminBillingDetail from "./pages/AdminPages/AdminBillingDetail";
import AdminTrialUsers from "./pages/AdminPages/AdminTrialUsers";
import AllJobs from "./pages/AdminPages/AllJobs";
import AdminActiveJob from "./pages/AdminPages/AdminActiveJob";
// import ManageSubcription from "./pages/AdminPages/ManageSubcription";
// import ManageSpecificSubscription from "./pages/AdminPages/ManageSpecificSubscription";
import DisputesJob from "./pages/AdminPages/Disputesjobs";
import DisputeJobDetails from "./pages/AdminPages/DisputeJobDetails";
import AllAds from "./pages/AdminPages/AdManager/AllAds"
import AdsInsightSpecific from "./pages/AdminPages/AdManager/AdsInsight"
import BillingDetails from "./pages/AdminPages/AdManager/BillingDetails"
/* Admin Dashboard */

/* Paid Provider Dashboard*/
import UserSearch from "./components/AllDashboardPagesComponents/userSearch";
import PublicJobs from "./pages/PaidProviderPages/PublicJobs";
import AppliedJobs from "./pages/PaidProviderPages/AppliedJobs";
/* Paid Provider Dashboard*/

/* Paid Participants Dashboard*/
import ParticipantCompare from "./pages/ParticipantPages/ParticipantCompare/ParticipantCompare";
/* Paid Participants Dashboard*/

/* Login Pages*/
import FreeLoginPage from "./pages/LoginPages/freeloginpage";
import PaidLoginPage from "./pages/LoginPages/loginpaidplan";
import PaymentForm from "./pages/LoginPages/paymentform";
import DashboardLogin from "./pages/LoginPages/logindashboard";
import RecoveryPassword from "./pages/LoginPages/RecoveryPassword";
import EmailVerification from "./pages/LoginPages/loginrecoveryverification";
import ConfirmPassword from "./pages/LoginPages/ConfirmPassword";
import Loginweb from "./pages/LoginPages/LoginWeb";
// import Demo from "./pages/demo";
/* Login Pages*/

/* All Dashboard Same Pages*/
import Scheduler from "./pages/AllDashboardPages/Scheduler";
import JobsBoard from "./pages/AllDashboardPages/JobsBoard";
import ManageJobs from "./pages/AllDashboardPages/ManageJobs";
import JobsApplication from "./pages/AllDashboardPages/JobsApplication"
import ProfileViewer from "./components/AllDashboardPagesComponents/ProfileViewer";
import AdsManager from "./pages/AllDashboardPages/AdsManager/AdsManager"
import BillPayment from "./pages/AllDashboardPages/AdsManager/BillingDetailsSpecific"

/* All Dashboard Same Pages*/

import { getCookie, getRole } from "./config/Helpers/helpers"; // Helper functions
import { useDispatch, useSelector } from "react-redux"; // Redux hooks for state management
import { setPersonalProfile, SetPersonalProfilePayload, PersonalDataType } from "./config/Store/Reducers/userProfile"; // Redux action
import { setNotifications, SetNotificationsPayload, toogleNotificationLoader } from "./config/Store/Reducers/notificationsSlice"; // Redux actions for notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// for protected routes
import AdminProtectedRoutes from "./config/ProtectedRoutes/AdminProtectedRoutes";
import ParticipantProviderAdmin from "./config/ProtectedRoutes/ParticipantProviderAdmin";
import ProviderProtectedRoutes from "./config/ProtectedRoutes/ProviderProtectedRoutes";
import ParticipantAndProvider from "./config/ProtectedRoutes/ParticpantAndProvider";
import NewsFeed from "./pages/NewsFeed/newsFeed";
import PublicProfile from "./pages/AllDashboardPages/PublicProfile/PublicProfile";
import ProfileUserDetailEdit from "./pages/AllDashboardPages/PublicProfile/ProfileUserDetailEdit";
import AboutEdit from "./pages/AllDashboardPages/PublicProfile/AboutEdit";
import AvailabilityEdit from "./pages/AllDashboardPages/PublicProfile/AvailabilityEdit";
import WorkHistoryEdit from "./pages/AllDashboardPages/PublicProfile/WorkHistoryEdit";
import EducationTrainingEdit from "./pages/AllDashboardPages/PublicProfile/EducationTrainingEdit";
import HourlyRatesEdit from "./components/AllDashboardPagesComponents/Profile/HourlyRatesEdit";
import ServicesEdit from "./pages/AllDashboardPages/PublicProfile/ServicesEdit";
import PreferencesEdit from "./pages/AllDashboardPages/PublicProfile/PreferencesEdit";
import WorkLocationEdit from "./pages/AllDashboardPages/PublicProfile/WorkLocationEdit";
import AllReview from "./components/AllDashboardPagesComponents/Profile/AllReview";
import Setting from "./pages/AllDashboardPages/PublicProfile/Setting";
// import ProfileVerification from "./pages/AllDashboardPages/PublicProfile/ProfileVerification";
import Privacy from "./pages/AllDashboardPages/PublicProfile/Privacy";
import UserNotification from "./pages/AllDashboardPages/PublicProfile/UserNotification";
import ActivityLog from "./pages/AllDashboardPages/PublicProfile/ActivityLog";
import UserProfile from "./pages/AllDashboardPages/UserProfile/userProfile";
// import UserProfileForAdmin from "./pages/AllDashboardPages/UserProfile/UserProfileForAdmin";
import Connections from "./pages/AllDashboardPages/Connections/Connections";
import Subscription from "./components/AllDashboardPagesComponents/Subscription/Subscription";
import DisputeJobs from "./pages/AllDashboardPages/DisputeJobs";
// for protected routes

import { initializeChatSocket, initializeSocket, turnOffSocket, turnOnSocket } from "./config/Sockets/socket"
// import { initializeChatSocket, initializeSocket, turnOffSocket, turnOnSocket } from "./config/Sockets/socket.js"
import SearchUser from "./pages/AllDashboardPages/SearchUser/SearchUser";
import ManageReviews from "./components/AllDashboardPagesComponents/Reviews/ManageReviews";
import { fetchRecentMessages } from "./config/Store/Reducers/messagesSlice";
import Discussions from "./pages/Discussions/Discussions";
import DiscussionView from "./pages/Discussions/DiscussionView";
import DiscussionsRequest from "./pages/AdminPages/Discussions/DiscussionsRequest";
import FeedActivity from "./pages/NewsFeed/FeedActivity";
import FeedDetail from "./pages/NewsFeed/FeedDetail";
import DiscussionActivity from "./pages/Discussions/DiscussionActivity";
import FreeDemo from "./pages/DemoVideo/FreeDemo";
import ScheduleMeeting from "./pages/DemoVideo/ScheduleMeeting";
import httpRequest from "./config/Helpers/httpRequest";
import AllReports from "./pages/AdminPages/Reports/AllReports";
import JobDetails from "./pages/AllDashboardPages/JobDetails";
import ReportsById from "./pages/AdminPages/Reports/ReportsById";
import PostReportPage from "./pages/AdminPages/Reports/PostReportPage";
import { RootState } from "./config/Store/store";
import Cookies from "js-cookie";

interface IApiResponse {
  documents: PersonalDataType;
  services: Array<string>;
  profileCompletionPercentage: number;
  subscriptionDetails: object;


}
interface documents {
  imageUrl: string;
  message: string;
  notificationRoute: string;
  read: boolean;
  routeId: string;
  title: string;
  [key: string]: any;
}
interface NotificationApiResponse {
  documents: Notification[];
  unreadCount: number;
  totalCount: number;
  [key: string]: any;
}
function App() {

  const location = useLocation();
  const dispatch = useDispatch(); // Redux dispatch function
  const navigate = useNavigate();
  const decoded = getRole();
  let checkRole = decoded?.role || false; // Get user role from helpers
  
  useEffect(() => {
    if ((checkRole === "admin" || checkRole === "superAdmin" || checkRole === "editor") && (location.pathname === "/login-web" || location.pathname === "/admin-login" || location.pathname === "/free-register")) {
      navigate("/admin")
    }
    else if ((checkRole === "participant" || checkRole === "provider") && (location.pathname === "/login-web" || location.pathname === "/admin-login" || location.pathname === "/free-register")) {
      navigate("/feed")
    }
  }, [])

  const { updateProfile, personalData } = useSelector((store: RootState) => store.userProfile); // Select user profile state from Redux store
  const { token } = useSelector((store: RootState) => store.auth); // Select authentication token from Redux store
  const { currentPage, pageSize } = useSelector((store: RootState) => store.notification); // Select current page and page size from notification state
  // for route protect
  const [isProvider, setIsProvider] = useState<boolean>(false);
  const [isParticipant, setIsParticipant] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  // // for route protect
  let cookieToken = getCookie(); // Get cookie token using helper function

  // Functionality: API call get notifications of logedin user
  const fetchNotifications = async () => {
    dispatch(toogleNotificationLoader(true)); // Toggle loader state for notifications
    // const { res, err } = await httpRequest({ path: `/api/notifications/all?page=${currentPage}&pageSize=${pageSize}` });
    const { res, err } = await httpRequest<NotificationApiResponse>({ path: `/api/notifications/all?page=${currentPage}&limit=${pageSize}` });


    if (res) {
      const payload: SetNotificationsPayload = {
        unreadCount: res.unreadCount || 0, // Ensure this matches the response structure
        Notification: res?.documents, // Ensure res.documents is an array of Notification
        totalCount: res?.paginated?.totalItems,
        totalPages: res?.paginated?.totalPages || 1,// Ensure this matches the response structure
      };
      dispatch(setNotifications(payload));
      // Dispatch action to set notifications in Redux store
    } else {
      console.error("ERROR", err); // Log error if API request fails
    }
    dispatch(toogleNotificationLoader(false)); // Toggle loader state for notifications

  }
  // Effect: Fetch recent messages and notifications on component mount or when currentPage changes
  useEffect(() => {
    if (token || cookieToken) {
      // dispatch(fetchRecentMessages(token || cookieToken)); // Dispatch action to fetch recent messages
      // dispatch(fetchRecentMessages(token || cookieToken)); // Dispatch action to fetch recent messages
      if (checkRole === "provider" || checkRole === "participant") {
        fetchNotifications(); // Call fetchNotifications function to fetch notifications

      }
    }
  }, [cookieToken, currentPage]); // Dependency array: currentPage and cookieToken

  // Functionality: API call Fetch personal profile data of logged-in user on updateProfile, token, or cookieToken changes
  useEffect(() => {
    if ((token || cookieToken) && (checkRole === "provider" || checkRole === "participant")) {
      (async () => {
        const UserRole = decoded?.role;
        const userId = decoded?.userId;

        // let { UserRole, userId } = getRole();
        const { res, err } = await httpRequest<IApiResponse>({ path: `/api/user/my-profile` });


        if (res) {

          const expirationTime = decoded?.exp! * 1000; // Convert to milliseconds
          const domain = process.env.REACT_APP_DOMAIN;
          const cookieOptionsCross = {
            expires: new Date(expirationTime),
            path: '/',
            domain: process.env.REACT_APP_DOMAIN!, // Add domain here
          };
          // localStorage.setItem(`${domain}_email`, res?.documents?.email || '');
          // localStorage.setItem(`${domain}_fullName`, res?.documents?.fullName || '');
          // localStorage.setItem(`${domain}_firstName`, res?.documents?.firstName || '');
          // localStorage.setItem(`${domain}_lastName`, res?.documents?.lastName || '');
          Cookies.set(`_email`, res?.documents?.email || '', cookieOptionsCross) // Expires in 7 days
          // Cookies.set(`_NDISfullName`, res?.documents?.fullName || '',cookieOptionsCross)
          Cookies.set(`_firstName`, res?.documents?.firstName || '', cookieOptionsCross)
          Cookies.set(`_lastName`, res?.documents?.lastName || '', cookieOptionsCross)
          Cookies.set(`_profileImageUrl`, res?.documents?.profileImageUrl || '', cookieOptionsCross)
          Cookies.set(`_firstTimeLoggedInUser`, "firstTime" || '', cookieOptionsCross)
          const payload: SetPersonalProfilePayload = {
            userProfile: res?.documents,
            services: res?.documents?.services,
            profileCompletionPercentage: res?.profileCompletionPercentage,
            subscriptionDetails: res?.subscriptionDetails,
          };
          dispatch(setPersonalProfile(payload));
          // dispatch(setPersonalProfile(res?.documents as SetPersonalProfilePayload));
        } else {
          console.error("ERROR", err); // Log error if API request fails
        }
      })();
    }
  }, [updateProfile, token, cookieToken]);

  // Function to handle socket initialization
  const initializeSockets = async () => {
    await initializeSocket(dispatch);
    // await initializeChatSocket(dispatch);
    await turnOnSocket();
  };

  // Function to handle socket disconnection
  const disconnectSockets = async () => {
    await turnOffSocket();
  };

  useEffect(() => {
    const manageSocketConnections = async () => {

      if (token || cookieToken) {
        await disconnectSockets();
        await initializeSockets();
      } else {
        await turnOffSocket();
      }


    };

    manageSocketConnections();

    return () => {
      // Clean up socket connections when component unmounts (if needed)
      turnOffSocket();
    };
  }, [token || cookieToken]);
  return (
    <>
      <ToastContainer
        autoClose={2000}
        position="top-right"
      // Render the custom toast component
      />
      {/* <Router> */}
      <Routes>
        {/* LOGIN PAGES ROUTES public pages */}
        <Route path="/admin-login" element={<DashboardLogin />} /> {/*Admin Login*/}
        <Route path="/free-register" element={<FreeLoginPage />} />
        <Route path="/login-web" element={<Loginweb />} />
        <Route path="/recovery-password" element={<RecoveryPassword />} />
        <Route path="/recovery-verification" element={<EmailVerification />} />
        <Route path="/confirm-password" element={<ConfirmPassword />} />
        {/* <Route path="/paid-register" element={<PaidLoginPage />} /> */}
        <Route path="/payment-form" element={<PaymentForm />} />
        {/* <Route path="/demo" element={<Demo />} />  */}
        <Route path="/free-demo" element={<FreeDemo />} />
        <Route path="/schedule-meeting" element={<ScheduleMeeting />} />

        {/* LOGIN PAGES ROUTES public pages */}
        <Route path="*" element={<PageNotFound />} />

        {/* admin protected routes */}
        <Route
          element={
            <AdminProtectedRoutes
              isAdmin={isAdmin}
              setIsAdmin={setIsAdmin}
              token={token}
            />
          }
        >

          <Route path="/admin" element={<AdminDashboard />} />
          <Route path="/discussions-request" element={<DiscussionsRequest />} />
          <Route path="/admin-operators" element={<AdminOperators />} />
          <Route path="/admin-trial-users" element={<AdminTrialUsers />} />
          <Route path="/admin-paid-users" element={<AdminPaidUsers />} />
          <Route path="/user-billing-detail/:email/view" element={<AdminBillingDetail />} />
          {/* <Route path="/Manage-Subcription" element={<ManageSubcription />} />  */}
          {/* <Route path="/Manage-Specific-Subscription/:UID" element={<ManageSpecificSubscription />} />  */}
          <Route path="/disputes-job" element={<DisputesJob />} />
          <Route path="/disputes-job-detail" element={<DisputeJobDetails />} />
          <Route path="/report" element={<AllReports />} />
          <Route path="/reports/:rid/view" element={<ReportsById />} />
          <Route path="/reports/:rid/post" element={<PostReportPage />} />
          <Route path="/all-jobs" element={<AllJobs />} />
          <Route path="/admin-active-jobs" element={<AdminActiveJob />} />
          <Route path="/all-ads" element={<AllAds />} />
          <Route path="/insight/:insightId/details" element={<AdsInsightSpecific />} />
          <Route path="/billing-detail/:insightId/details" element={<BillingDetails />} />
        </Route>
        {/* admin protected routes */}

        {/* protected routes for participant & provider */}
        <Route
          element={
            <ParticipantAndProvider
              isParticipant={isParticipant}
              setIsParticipant={setIsParticipant}
              isProvider={isProvider}
              setIsProvider={setIsProvider}
              token={token}
            />
          }
        >
          <Route path="/manage-jobs" element={<ManageJobs />} />
          <Route path="/jobs-board" element={<JobsBoard />} />
          <Route path="/jobs-application/:jid/view-applicant" element={<JobsApplication />} />
          <Route path="/manage-reviews" element={<ManageReviews />} />
          <Route path="/job-details/:jid/view" element={<JobDetails />} />
          <Route path="/dispute-jobs" element={<DisputeJobs />} />
          <Route path="/subscription" element={<Subscription />} />
          <Route path="/connections" element={<Connections />} />
          <Route path="/activity" element={<ActivityLog />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/setting" element={<Setting />} />
          <Route path="/user-profile-edit" element={<ProfileUserDetailEdit />} />
          <Route path="/about-me-edit" element={<AboutEdit />} />
          <Route path="/preferences-edit" element={<PreferencesEdit />} />
          <Route path="/reviews/:uid/published" element={<AllReview />} />
          <Route path="/feed" element={<NewsFeed />} />
          <Route path="/profile-viewers" element={<ProfileViewer />} />
          <Route path="/ads-manager" element={<AdsManager />} />
          <Route path="/bill-payment" element={<BillPayment />} />
        </Route>
        {/* protected routes for participant & provider */}

        {/* protected routes for participant & provider & admin */}
        <Route
          element={
            <ParticipantProviderAdmin
              isParticipant={isParticipant}
              setIsParticipant={setIsParticipant}
              isProvider={isProvider}
              setIsProvider={setIsProvider}
              isAdmin={isAdmin}
              setIsAdmin={setIsAdmin}
              token={token}
            />
          }
        >
          <Route path="/user-search" element={<UserSearch />} />
          <Route path="/public-profile/:uid/view" element={<UserProfile />} />
          {/* <Route path="/UserProfileForAdmin/:uid/view" element={<UserProfileForAdmin />} /> */}
          <Route path="/notification" element={<UserNotification />} />
          <Route path="/scheduler" element={<Scheduler />} />
          <Route path="/participant-compare" element={<ParticipantCompare />} />
          <Route path="/Publicprofile" element={<PublicProfile />} />
          <Route path="/feed-detail/:id/view" element={<FeedDetail />} />
          <Route path="/discussions" element={<Discussions />} />
          <Route path="/discussion/:dId/view" element={<DiscussionView />} />
          <Route path="/:uId/feed-activity" element={<FeedActivity />} />
          <Route path="/:uId/discussion-activity" element={<DiscussionActivity />} />
          <Route path="/search-user" element={<SearchUser />} />
        </Route>
        {/* protected routes for participant & provider & admin */}

        {/* provider protected routes */}
        <Route
          element={
            <ProviderProtectedRoutes
              isProvider={isProvider}
              setIsProvider={setIsProvider}
              token={token}
            />
          }
        >
          <Route path="/public-jobs" element={<PublicJobs />} />
          <Route path="/applied-jobs" element={<AppliedJobs />} />
          <Route path="/availability-edit" element={<AvailabilityEdit />} />
          <Route path="/work-history-edit" element={<WorkHistoryEdit />} />
          <Route path="/education-training-edit" element={<EducationTrainingEdit />} />
          <Route path="/hourly-rates-edit" element={<HourlyRatesEdit />} />
          <Route path="/services-edit" element={<ServicesEdit />} />
          <Route path="/work-location-edit" element={<WorkLocationEdit />} />
        </Route>
        {/* provider protected routes */}

        {/* News Feed routes */}
        {/* <Route path="/verification" element={<ProfileVerification />} /> */}
      </Routes>
      {/* </Router> */}
    </>
  );
}

export default App;