/**
 * AdminTrialUsers Component
 * Displays trial users with filtering by name and user type (participant & provider), pagination support, and navigation to user profiles on name click.
 * File: AdminTrialUsers.js
 * Author: Developer
 * Date: 03-04-24
 */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../../components/AdminComponents/AdminSidebar";
import { Link, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisVertical,
    faPencil,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import httpRequest from "../../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import Isverified from "../../../assets/img/isMember.svg"
import ViewPostModal from "./Modals/ViewPostModal";
import { useNavigate } from "react-router-dom";
import arrowback from "../../../assets/img/arrowback.svg";


interface User {

    _id: string;
    firstName: string;
    lastName: string;
    email: string;

    role: string;
    verification: string;
    status: string;
    createdAt: string;
    [key: string]: any;
}
interface Paginated {
    totalItems: number;
}
interface ApiResponse {
    totalResults: number;
    paginated: Paginated;

    documents: User[];
}
interface QueryParams {
    member: string;
    role: string;
    name: string;
    page: number;
    pageSize: number;
    sort: string;
}
const AdsInsight: React.FC = () => {
    const { insightId } = useParams<{ insightId: string }>();
    const navigate = useNavigate();

    const [isComponentLoad, setIsComponentLoad] = useState<boolean>(false); // for check component load or not
    const [bool, setBool] = useState<boolean>(false); // State for toggle updates

    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [trialUsers, setTrialUsers] = useState<User[]>([]); // State for trial users list
    console.log(trialUsers, "trialUserstrialUsers")
    const [temporary, setTempValue] = useState<string>(""); // State for temporary filter value
    const [totalPage, setTotalPage] = useState<number>(1); // State for total number of pages
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
    const [selectedPostId, setSelectedPostId] = useState<string>("");
    const [queryParams, setQueryParams] = useState<QueryParams>({
        member: "",
        role: "provider",
        name: "",
        page: 1,
        pageSize: 10,
        sort: "desc",
    }); // State for query parameters used in API callsState for query parameters used in API calls
    const handleViewPost = (postId: string) => {
        setSelectedPostId(postId);  // Set the post ID to the clicked post
        setIsModalOpen(true);       // Open the modal
    };

    // Handle close modal
    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedPostId(""); // Reset the post ID
    };

    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/ad/insight/${insightId}` });
        if (res) {

            setTrialUsers(res?.documents); // Set trial users data from API response
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (isComponentLoad) {
            const debounceTimer = setTimeout(() => {
                setQueryParams({ ...queryParams, name: temporary, page: 1 });
            }, 1500);
            return () => clearTimeout(debounceTimer);
        }
    }, [temporary]);

    // Effect hook to fetch data when query params change
    useEffect(() => {
        fetch();
        setIsComponentLoad(true);
    }, []);


    return (
        <div>
            <div className="flex font-poppins-regular">
                <AdminSidebar />
                <div className="w-4/5 ml-auto">
                    <AdminHeader />
                    <div className="pt-24 Admin-main-operator-parent">
                        <div className="pb-6 pl-6 pr-10">
                            <div className="flex flex-row justify-between items-center">
                                <div>
                                    <h2 className="font-size-36px font-Poppins-Medium">
                                        Insights
                                    </h2>
                                    <p> <span className="font-size-16px font-Poppins-Bold">Dashboard /</span> <span className="font-Poppins-Medium"> Insights</span></p>
                                </div>
                                <img onClick={() => navigate(-1)} className="cursor-pointer notifi-back h-[3vh]" src={arrowback} alt="" width={25} />
                            </div>
                            <div className="Main-AdminUsers operator-main-admin mt-6 flex justify-between items-center">

                            </div>
                            <div className="main-admin-Assessment theme-bg-lightblue">
                                <div className="">
                                    <ul
                                        style={{
                                            gridTemplateColumns: "14% 8% 13% 11% 9% 13% 14% 11% 7%",
                                        }}
                                        className="outer-detail-border main-Admin-status-action Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                                    >
                                        {[
                                            "Promotion name",
                                            "Type",
                                            "Packages",
                                            "Connections",
                                            "Views",
                                            "Engagment rate",
                                            "Objective achieved",
                                            "Date created",
                                            "Preview",
                                            // "No of Logins",

                                        ].map((item) => (
                                            <li className="text-white" key={item}>
                                                {item || ""}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {isLoader ? (
                                    <div className="main-Admin-status-content mt-5 text-center">
                                        <CircularProgress style={{ color: "#00443f" }} />
                                    </div>
                                ) : trialUsers && trialUsers.length > 0 ? (
                                    trialUsers.map((item, index) => (
                                        <div key={index} className=" pt-5">
                                            <ul
                                                style={{
                                                    gridTemplateColumns: "14% 8% 13% 11% 9% 13% 14% 11% 7%",
                                                }}
                                                className="under-detail-border Admin-status-content-Role main-Admin-status-content text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                                            >
                                                <li className="capitalize">

                                                    {item?.title}

                                                </li>
                                                <li>
                                                    <span>
                                                        {item?.refType}

                                                    </span>
                                                </li>
                                                <li >
                                                    <span className="txt-turncate capitalize">  {item?.promotionTitle}</span>

                                                </li>
                                                <li className="" >
                                                    {item?.connects}
                                                </li>
                                                {/* <li>{item?.totalLogins}</li> */}
                                                <li>
                                                    {item?.views}

                                                </li>
                                                <li>
                                                    {item?.connects !== 0 ?

                                                        ((item?.connects) / (item?.views) * 100).toFixed(2)
                                                        :
                                                        0

                                                    }%

                                                </li>
                                                <li>
                                                    {item.objective === "views" ?
                                                        (
                                                            <span>
                                                                {((item?.views) / (item?.maxCount) * 100).toFixed(2)}%
                                                            </span>
                                                        )

                                                        :
                                                        (
                                                            <span>
                                                                {((item?.connects) / (item?.maxCount) * 100).toFixed(2)}%
                                                            </span>
                                                        )
                                                    }
                                                </li>
                                                <li className="">
                                                    {moment(item?.createdAt).format("DD-MM-YYYY")}
                                                </li>
                                                <li className="">
                                                    {item?.refType === "post" ?
                                                        <button
                                                            onClick={() => handleViewPost(item?.refId)}
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />

                                                        </button>

                                                        :
                                                        <Link
                                                            to={`/public-profile/${item?.refId}/view`}
                                                        >
                                                            <FontAwesomeIcon icon={faEye} />

                                                        </Link>

                                                    }

                                                </li>
                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <div className="main-Admin-status-content mt-5">
                                        <p className="text-center">No insights found</p>
                                    </div>
                                )}
                                <PaginationComponet
                                    total={totalPage}
                                    setCurrentPage={(e) =>
                                        setQueryParams({ ...queryParams, page: e })
                                    }
                                    currentPage={queryParams.page}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewPostModal

                isOpen={isModalOpen}
                postId={selectedPostId}
                onClose={handleCloseModal}

            />
        </div>
    );
};

export default AdsInsight;