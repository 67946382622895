import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import adminHeaderSearch from "../../assets/img/Admin-header-search-logo.png";
import AdminUser from "../../assets/img/admin-user-img.png";
import Adminheaderask from "../../assets/img/Admin-Header-ask-btn.png";
import { getRole } from "../../config/Helpers/helpers";
import HeaderSearch from "../../components/SearchComponents/HeaderSearch";
import { useSelector } from "react-redux";
import AskAIcomp from "../AskAi/AskAIcomp";
const AdminHeader = () => {
  const [dragModal, setDragModal] = useState(false);
  const handleOpen = () => {
    setDragModal(true);
  };
  const handleClose = () => {
    setDragModal(false);
  };
  let data = getRole() || false;
  
  const { unReadNotifications } = useSelector((store) => store.notification);
  return (
    <section className="admin-header globalheader fixed z-10 flex justify-center ">
      <div className="container-1480 header-glo-set   fixed top-0 z-10 w-full">
        <div className="flex gap-6 items-center">
          <HeaderSearch />
          <div onClick={handleOpen}>
            <Link className="admin-header-ask-btn font-size-20px font-Poppins-SemiBold">
              <img src={Adminheaderask} alt="" />
              Ask ndis
            </Link>
          </div>
          {dragModal && <AskAIcomp handleClose={handleClose} />}
        </div>
        <div className="flex gap-4 items-center">
          <Link
            to="/notifications"
            className="header-notifi text-white relative text-center"
          >
            {unReadNotifications ? (
              <div className="msg-count-main">
                <span className="msg-count-specific">
                  {unReadNotifications}
                </span>
              </div>
            ) : null}
            <FontAwesomeIcon className="header-notifi-icon" icon={faBell} />
            <span className="font-Poppins-Medium font-size-15px block opacity-0 header-notifi-text">
              Notifications
            </span>
          </Link>
          <div className="Admin-user-header">
            <div className="admin-header-user-img">
              <img src={AdminUser} alt="" />
            </div>
            <div className="">
              <h4 className="font-size-20px font-Poppins-SemiBold text-white">
                {!data?.fName && !data?.lName
                  ? "Admin"
                  : `${data?.fName} ${data?.lName}`}
              </h4>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminHeader;
