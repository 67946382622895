import React, { useEffect, useState } from "react";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { toast } from "react-toastify";
import httpRequest from "../../config/Helpers/httpRequest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  CalculateCancellationRate,
  calculateRating,
} from "../../config/Helpers/helpers";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import ConnectionStatusButton from "../ConnectionsComponents/ConnectionStatusButton";
import { setPersonalProfile } from "../../config/Store/Reducers/userProfile";
import Isverified from "../../assets/img/isMember.svg"
import IsFivityVerfied from "../../assets/img/50member.svg"

import VerficationSheild from "../../assets/img/verified-shield.png"


import { getRole } from "../../config/Helpers/helpers";
function DiscussionSidebar({ fetchLoggedInUserData }) {
  const [localData, setLocalData] = useState({});

  const dispatch = useDispatch();
  const { data = {} } = useSelector((store) => store.discussion);
  const { personalData } = useSelector(
    (store) => store.userProfile
  );
  console.log(personalData, "personalDatapersonalDatapersonalData")
  const decodedToken = getRole()
  useEffect(() => {
    const fetchSidebarData = async () => {
      if (fetchLoggedInUserData) {
        if (decodedToken) {
          const userId = decodedToken?.userId;
          if (userId) {
            const { res, err } = await httpRequest({
              path: `/api/${decodedToken?.UserRole}/api/user/my-profile`,
            });
            if (res) {
              const payload = {
                userProfile: res?.documents,
                services: res?.documents?.services,
                profileCompletionPercentage: res?.profileCompletionPercentage,
                subscriptionDetails: res?.subscriptionDetails,
              };
              dispatch(setPersonalProfile(payload));
            } else {
              toast(err?.message, { type: "error" });
            }
          }
        }

      }
    };

    if (fetchLoggedInUserData && !personalData) {
      fetchSidebarData();
    }
  }, [fetchLoggedInUserData, dispatch, personalData]);

  const displayData = fetchLoggedInUserData ? personalData : data;
  console.log(displayData, "personal profile")

  return (
    <div className="sidebar-gobal-user-details pb-2">
      <div className="sidebar-gobal-user-img">
        {fetchLoggedInUserData ? (
          <img
            src={`${displayData?.profileImageUrl}`}
            // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${displayData?.profileImageUrl}`}
            onError={(e) => (e.target.src = DefaultProfileImage)}
            alt={displayData?.firstName || ""}
            className="mb-2"
          />
        )
          :

          (
            <img
              src={`${displayData?.profileImageUrl}`}
              // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${displayData?.profileImageUrl}`}
              onError={(e) => (e.target.src = DefaultProfileImage)}
              alt={displayData?.firstName || ""}
              className="mb-2"
            />
          )
        }


      </div>{" "}
      {/* image */}
      <div className="sidebar-gobal-user-rating flex justify-center items-center">
        <div className="text-yellow-400 flex gap-1 ">
          {[1, 2, 3, 4, 5].map((v) => (
            <FontAwesomeIcon
              key={v}
              fontSize="20px"
              icon={faStar}
              color="#FF9B29"
              className={
                calculateRating(displayData?.totalRatings, displayData?.totalReviews) >= v
                  ? "active"
                  : "nonActiveStar"
              }
            />
          ))}
        </div>
      </div>{" "}
      {/* rating star */}
      <div className="sidebar-gobal-user-name text-center">
        {fetchLoggedInUserData ?




          (
            <>
              <div className="flex flex-row gap-2 flex-nowrap justify-center">
                <Link
                  to={`/public-profile/${displayData?._id}/view`}
                  className="font-size-20px font-Poppins-SemiBold theme-color-green text-center capitalize"
                >
                  {`${displayData?.firstName || ""} ${displayData?.lastName || ""}`}
                </Link>
                {displayData?.freeUser === false && (
                  <img className="discussion-sidebar-icon" src={displayData?.isFounder ? IsFivityVerfied : Isverified} alt="Verified-Icon" />

                )}
              </div>
            </>
          )

          :

          (
            <>
              <div className="flex flex-row gap-2 flex-nowrap justify-center">

                <Link
                  to={`/public-profile/${displayData?.userId}/view`}
                  className="font-size-20px font-Poppins-SemiBold theme-color-green text-center capitalize"
                >
                  {`${displayData?.firstName || ""} ${displayData?.lastName || ""}`}
                </Link>
                {displayData?.freeUser === false && (
                  <img className="discussion-sidebar-icon" src={displayData?.isFounder ? IsFivityVerfied : Isverified} alt="Verified-Icon" />

                )}
              </div>
            </>
          )
        }

        <div className="flex  justify-center">
          {displayData?.verification === "deactivated" ? (
            <>
              <div className="flex flex-row gap-2 items-center">
                {/* <button className="font-size-15px font-Poppins-Medium bg-[#FFB2B2] text-[#980000] non-verify-btn cursor-default">
                                Non-Verified
                              </button> */}
                <button className="font-size-15px font-Poppins-Medium bg-[#FFB2B2] text-[#980000] non-verify-btn cursor-default">
                  Non-Verified
                </button>
              </div>
            </>
          ) :

            displayData?.verification === "active" ? (
              <button className="font-size-15px font-Poppins-Medium verified-btn bg-[#B2D8D5] text-[#007E76] flex flex-row gap-2 items-center">
                Verified
              </button>
            ) : null

          }
        </div>




        <p className="font-size-15px  theme-color-green font-Poppins-Regular text-center capitalize-first-letter">
          {displayData?.roleCategory || ""}
        </p>
        {/* role */}
        <p className="font-size-15px theme-grey-type  font-Poppins-Regular  text-center">
          {displayData?.profileSummary || ""}
        </p>
        {/* profile summary */}
      </div>
      <hr className="my-3" />
      <div className="sidebar-gobal-user-jobs-status px-2">
        <div className="flex justify-between items-center">
          <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
            Jobs completed
          </p>
          <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
            {displayData?.completedJobs || 0}
          </p>
        </div>
        {/* completed jobs */}
        <div className="flex justify-between items-center">
          <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
            Connections
          </p>
          <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
            {displayData?.connections || 0}
          </p>
        </div>
        {/* connection length */}
        <div className="flex justify-between items-center">
          <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
            Cancellation rate
          </p>
          <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
            {CalculateCancellationRate(
              displayData?.cancelledJobs,
              displayData?.completedJobs
            )}
          </p>
        </div>
        {/* cancellation rate */}
        <div className="flex justify-between items-center">
          <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
            Reviews
          </p>
          <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
            {displayData?.totalReviews || 0}
          </p>
          {/* Reviews */}
        </div>
        <div className="mt-4 flex justify-center discussion-remove-connect">
          <ConnectionStatusButton
            status={data?.connectionStatus}
            uId={data?.userId}
            showMessageButton={false}
          />
        </div>
      </div>
    </div>
  );
}

export default DiscussionSidebar;
