// import { io } from "socket.io-client";
// import { getCookie } from "../Helpers/helpers";
// import { addNewNotifications } from "../Store/Reducers/notificationsSlice";
// // import { updateUnReadMessages } from "../Store/Reducers/messagesSlice";
// import { changeConnectionStatus } from "../Store/Reducers/connectionsSlice";

// const SOCKET_URL = process.env.REACT_APP_SOCKET_URL; // server url
// const SOCKET_URL_CHAT = process.env.REACT_APP_CHATAPP_BACKEND; // server url

// let socket: Socket | null = null;

// let chatSocket: Socket | null = null;

// let previousNotificationId = "";

// // Function to initialize the socket connection
// const initializeSocket = (dispatch) => {
//     return new Promise((resolve, reject) => {
//         socket = io.connect(SOCKET_URL, {
//             autoConnect: false,
//             auth: {
//                 token: getCookie()
//             },
//             pingInterval: 10000, // how often to ping (in milliseconds).
//             pingTimeout: 5000 // time after which the connection is considered timed-out (in milliseconds).
//         }); // socket dashboard

//         // Add event listeners for socket connection
//         socket.on("connect", () => {
//             console.log("Connected to server:");
//             console.log("SOCKET_URL", SOCKET_URL);
//             console.log("SOCKET_URL_CHAT", SOCKET_URL_CHAT);
//             resolve();
//         });

//         // Add event listeners for notifications
//         socket.on("notification", (data) => {
//             console.log("notification", data);
//             if (previousNotificationId !== data?._id) {
//                 console.log("event==>notification", data)
//                 dispatch(addNewNotifications(data));
//                 previousNotificationId = data?._id;
//             };
//         });

//         // Add event listeners for socket disconnect
//         socket.on("disconnect", () => {
//             console.log("Disconnected from server");
//         });

//         // Add event listeners for connectionUpdate
//         socket.on("connectionUpdate", (data) => {
//             console.log("event==>connectionUpdate", data)
//             dispatch(changeConnectionStatus(data));
//         });

//         // Connect the socket
//         socket.connect();
//     });
// };

// // Function to initialize the socket connection
// const initializeChatSocket = (dispatch) => {
//     return new Promise((resolve, reject) => {
//         chatSocket = io.connect(SOCKET_URL_CHAT, {
//             autoConnect: false,
//             auth: {
//                 token: getCookie()
//             },
//             pingInterval: 10000, // how often to ping (in milliseconds).
//             pingTimeout: 5000 // time after which the connection is considered timed-out (in milliseconds).
//         }); // socket chat app

//         chatSocket.on("connect", () => {
//             console.log("Connected to chat server:");
//             resolve();
//         });

//         chatSocket.on("receive-message", (data) => {
//             console.log("event==>receive-message", data)
//             dispatch(updateUnReadMessages());
//         });

//         chatSocket.on("notification", (data) => {
//             console.log("notification==>notification", data)
//             // dispatch(updateUnReadMessages());
//         });

//         chatSocket.on("disconnect", () => {
//             console.log("Disconnected from chat server");
//         });

//         chatSocket.connect();
//     });
// };

// // Function to turn on the socket connection
// const turnOnSocket = () => {
//     console.log("CONNECT FOR BOTH SERVER");
//     return Promise.all([socket.connect(), chatSocket.connect()]);
// };

// // Function to turn off the socket connection
// const turnOffSocket = () => {
//     console.log("DISCONNECT FOR BOTH SERVER");
//     if (socket) {
//         socket.disconnect();
//     }
//     if (chatSocket) {
//         chatSocket.disconnect();
//     }
// };

// export { initializeSocket, initializeChatSocket, turnOnSocket, turnOffSocket };

import { io, ManagerOptions, SocketOptions, Socket } from "socket.io-client";
import { Dispatch } from "redux";
import { getCookie } from "../Helpers/helpers";
import { addNewNotifications } from "../Store/Reducers/notificationsSlice";
// import { updateUnReadMessages } from "../Store/Reducers/messagesSlice";
import { changeConnectionStatus } from "../Store/Reducers/connectionsSlice";

// const SOCKET_URL = process.env.REACT_APP_SOCKET_URL as string; // server URL
const token = getCookie();
const SOCKET_URL = `${process.env.REACT_APP_SOCKET_URL}?token=${token}` as string; // server URL
const SOCKET_URL_CHAT = process.env.REACT_APP_CHATAPP_BACKEND as string; // server URL

const getLatestToken = () => getCookie();



let socket: Socket | null = null;
let chatSocket: Socket | null = null;

let previousNotificationId = "";

// Common options for both sockets
const commonOptions: Partial<ManagerOptions & SocketOptions> = {
    auth: {
        token: token,
    },
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    timeout: 20000,
    autoConnect: false,
    transports: ['websocket'],  // Ensures socket.io only uses WebSocket
    // Custom headers to simulate ping options (if needed)
    extraHeaders: {
        'X-Ping-Interval': '10000',
        'X-Ping-Timeout': '5000',
    },
};

// Function to initialize the socket connection
const initializeSocket = (dispatch: Dispatch): Promise<void> => {
    return new Promise((resolve) => {
        const latestToken = getLatestToken();
        const SOCKET_URL = `${process.env.REACT_APP_SOCKET_URL}?token=${latestToken}`;

        const commonOptions: Partial<ManagerOptions & SocketOptions> = {
            auth: {
                token: latestToken,
            },
            // other options...
        };
        socket = io(SOCKET_URL, commonOptions);

        // return new Promise((resolve) => {
        //     socket = io(SOCKET_URL, commonOptions);


        // Add event listeners for socket connection
        socket.on("connect", () => {
            console.log("Connected to server:");
            console.log("SOCKET_URL", SOCKET_URL);
            console.log("SOCKET_URL_CHAT", SOCKET_URL_CHAT);
            resolve();
        });

        socket.on("notification", (data: any) => {
            console.log("on\n", data);
            if (previousNotificationId !== data?._id) {
                console.log("event==>notification", data);
                dispatch(addNewNotifications(data));
                previousNotificationId = data?._id;
            }
        });
        socket.on("connectionUpdate", (data: any) => {
            console.log("event==>connectionUpdate", data);
            dispatch(changeConnectionStatus(data));
        });
        // Add event listeners for socket disconnect
        socket.on("disconnect", () => {
            console.log("Disconnected from server");
        });

        // Add event listeners for connectionUpdate


        // Connect the socket
        socket.connect();
    });
};

// Function to initialize the chat socket connection
const initializeChatSocket = (dispatch: Dispatch): Promise<void> => {
    return new Promise((resolve) => {
        chatSocket = io(SOCKET_URL_CHAT, commonOptions);

        // Add event listeners for chat connection
        chatSocket.on("connect", () => {
            console.log("Connected to chat server:");
            resolve();
        });

        chatSocket.on("receive-message", (data: any) => {
            console.log("event==>receive-message", data);
            // dispatch(updateUnReadMessages());
        });

        chatSocket.on("notification", (data: any) => {
            console.log("notification==>notification", data);
            // dispatch(updateUnReadMessages());
        });

        chatSocket.on("disconnect", () => {
            console.log("Disconnected from chat server");
        });

        chatSocket.connect();
    });
};

// Function to turn on the socket connection
const turnOnSocket = (): Promise<void[]> => {
    console.log("CONNECT FOR BOTH SERVER>>>>", SOCKET_URL);

    return Promise.all([
        new Promise<void>((resolve) => {
            if (socket) {
                socket.connect();
                resolve();
            } else {
                resolve();
            }
        }),
        // new Promise<void>((resolve) => {
        //     if (chatSocket) {
        //         chatSocket.connect();
        //         resolve();
        //     } else {
        //         resolve();
        //     }
        // })
    ]);
};

// Function to turn off the socket connection
const turnOffSocket = (): void => {
    console.log("DISCONNECT FOR BOTH SERVER");
    if (socket) {
        socket.disconnect();
    }
    if (chatSocket) {
        chatSocket.disconnect();
    }
};

export { initializeSocket, initializeChatSocket, turnOnSocket, turnOffSocket };
