// import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   jobStartDate,
//   calculateAge,
//   formatDate,
//   getTimeDifference,
// } from "../../config/Helpers/helpers";
// import {
//   faCalendar,
//   faLocationDot,
//   faBriefcase,
//   faMagnifyingGlass,
//   faVenus,
//   faClock,
// } from "@fortawesome/free-solid-svg-icons";
// import DefaultProfileImage from "../../assets/img/default_profile_image.png";
// import JobCancelButton from "./JobCancelButton";
// import JobCompleteRequest from "./JobCompleteRequest";
// import StartJobDispute from "./StartJobDispute";
// import JobRescheduleModal from "./JobRescheduleModal";

// function SchedulerBody({
//   view,
//   weeks,
//   value,
//   onChange,
//   data,
//   jobSide,
//   start,
//   end,
//   setBool,
// }) {
//   const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   const [isHovered, setIsHovered] = useState(false);
//   const [disputeModal, setDisputeModal] = useState(false); // for dispute modal show & hide
//   const [rescheduleModal, setRescheduleModal] = useState(false); // for reschedule modal show & hide
//   // const handleHover = (value) => {
//   //   setIsHovered(value);
//   // };
//   const handleHover = (event, value) => {
//     const tooltip = event.currentTarget.querySelector(".onhover-schd");
//     if (tooltip) {
//       const tooltipRect = tooltip.getBoundingClientRect();
//       const viewportWidth = window.innerWidth;
//       const viewportHeight = window.innerHeight;

//       // Adjust horizontal position
//       if (tooltipRect.right > viewportWidth) {
//         tooltip.style.left = `-${tooltipRect.width}px`;
//       } else {
//         tooltip.style.left = "100%";
//       }

//       // Adjust vertical position
//       if (tooltipRect.bottom > viewportHeight) {
//         tooltip.style.top = `-${tooltipRect.height}px`;
//       } else {
//         tooltip.style.top = "100%";
//       }

//       tooltip.style.display = "block";
//     }
//     setIsHovered(value);
//   };

//   const handleHoverLeave = (event) => {
//     const tooltip = event.currentTarget.querySelector(".onhover-schd");
//     if (tooltip) {
//       tooltip.style.display = "none";
//     }
//     setIsHovered(false);
//   };
//   const checkPreferences = (user) => {
//     if (!user) return "-"; // Return an empty string if preferences are not provided

//     const {
//       preferencemale,
//       preferencefemale,
//       preferencenopet,
//       preferencenonsmoker,
//     } = user;

//     // Check for specific combinations and return the corresponding HTML string
//     if (
//       !preferencemale &&
//       !preferencefemale &&
//       !preferencenopet &&
//       !preferencenonsmoker
//     ) {
//       return "-";
//     } else {
//       let result = "";

//       if (preferencemale && preferencefemale) {
//         result += "Male & Female";
//       } else if (preferencemale) {
//         result += "Only Male";
//       } else if (preferencefemale) {
//         result += "Only Female";
//       }

//       if (preferencenopet) {
//         if (result !== "") result += ", ";
//         result += "No Pets";
//       }

//       if (preferencenonsmoker) {
//         if (result !== "") result += ", ";
//         result += "Non Smoker";
//       }
//       return result;
//     }
//   };

//   const createColumns = (items = []) => {
//     const columns = [];
//     let currentDate = new Date(start); // Start date
//     const endDate = new Date(end); // End date

//     // Loop until currentDate is less than or equal to endDate
//     while (currentDate <= endDate) {
//       // Find the first item where currentDate matches the startDate
//       const matchingItem = items.find((item) => {
//         const startDate = new Date(item.startDate);
//         return (
//           currentDate.getDate() === startDate.getDate() &&
//           currentDate.getMonth() === startDate.getMonth() &&
//           currentDate.getFullYear() === startDate.getFullYear()
//         );
//       });

//       // If a matching item is found, include it in the columns
//       if (matchingItem) {
//         columns.push(
//           <td key={`td-${currentDate.getTime()}`}>
//             <div
//               className={`event ${matchingItem?.status === "cancelled"
//                   ? "cancelled-Box"
//                   : matchingItem?.status === "onboard"
//                     ? "Active-Box"
//                     : matchingItem?.status === "completed"
//                       ? "Complete-Box"
//                       : matchingItem?.status === "disputed"
//                         ? "Disputed-Box"
//                         : ""
//                 } ${isHovered ? "hovered-class" : ""} ${view === "monthly" && "monthly__grid"
//                 }`}
//               onMouseEnter={(e) => handleHover(e, true)}
//               onMouseLeave={(e) => handleHoverLeave(e)}
//             >
//               <div className="Shedular-main">
//                 <div className="UserName-calen">
//                   <div>
//                     <h6 className="capitalize">
//                       {jobSide === "creator" ? (
//                         <>
//                           {matchingItem?.applicantDetails?.firstName || ""}{" "}
//                           {matchingItem?.applicantDetails?.lastName || ""}
//                         </>
//                       ) : (
//                         <>
//                           {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
//                           {matchingItem?.jobCreatorDetails?.lastName || ""}
//                         </>
//                       )}
//                     </h6>
//                   </div>
//                   <div>
//                     <span className="job__status capitalize">
//                       {matchingItem?.status === "onboard"
//                         ? "Active"
//                         : matchingItem?.status}
//                     </span>
//                   </div>
//                 </div>
//                 <p className="job__time__start__end">{`${matchingItem?.startTime || ""
//                   } : ${matchingItem?.endTime || ""}`}</p>
//                 <div className="UserName-calen2">
//                   <p>{matchingItem?.allServices[0]?.servicename || ""}</p>
//                   <p>${matchingItem?.budget || 0}</p>
//                 </div>
//               </div>
//               <div className="onhover-schd onhover-schd-clen">
//                 <div className="admin-job-preview-main-calender ">
//                   <div className="upper-schd-box">
//                     <div className="og-job-date flex items-center gap-2">
//                       <FontAwesomeIcon icon={faBriefcase} />
//                       <span className="font-Poppins-SemiBold font-size-15px">{`This job will start in ${jobStartDate(matchingItem?.startDate) || ""
//                         }`}</span>
//                     </div>
//                     <p className="font-Poppins-Regular font-size-15px mt-2 mb-2">
//                       Use Filters to widen or narrow your Search Result
//                     </p>
//                   </div>

//                   <div className="admin-preview-job mt-2">
//                     <div className="">
//                       <h2 className="font-Poppins-SemiBold font-size-18px text-[#00443f]">
//                         {matchingItem?.allServices[0]?.servicename || ""}
//                       </h2>
//                       <p className="font-Poppins-Medium font-size-15px text-[#646464]">
//                         {jobSide === "creator" ? (
//                           <>
//                             {matchingItem?.applicantDetails?.firstName || ""}{" "}
//                             {matchingItem?.applicantDetails?.lastName || ""}
//                           </>
//                         ) : (
//                           <>
//                             {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
//                             {matchingItem?.jobCreatorDetails?.lastName || ""}
//                           </>
//                         )}
//                       </p>
//                       <p className="font-Poppins-Medium font-size-15px text-[#646464]">
//                         {jobSide === "creator"
//                           ? calculateAge(
//                             matchingItem?.applicantDetails?.dateofbirth
//                           )
//                           : calculateAge(
//                             matchingItem?.jobCreatorDetails?.dateofbirth
//                           )}
//                         Year old
//                       </p>
//                       <p className="font-Poppins-Medium font-size-15px text-[#646464] capitalize">
//                         {jobSide === "creator"
//                           ? matchingItem?.applicantDetails?.gender
//                           : matchingItem?.jobCreatorDetails?.gender}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="underline-gray-1"></div>

//                   <div className="  text-[#646464] sch-box font-awesome-color">
//                     <div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faLocationDot} />
//                         <p>{`${matchingitem?.suburb || ""}, ${matchingItem?.state || ""
//                           }, ${matchingItem?.postCode || ""}`}</p>
//                       </div>
//                       {/* <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                                                 <FontAwesomeIcon icon={faBars} />
//                                                 <p>Light housework</p>
//                                             </div> */}
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon className="" icon={faVenus} />
//                         <p>
//                           {jobSide === "creator"
//                             ? checkPreferences(matchingItem?.applicantDetails)
//                             : checkPreferences(matchingItem?.jobCreatorDetails)}
//                         </p>
//                       </div>
//                     </div>

//                     <div className="font-awesome-color">
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faClock} />
//                         <p>
//                           {matchingItem?.repeatCycle == 1
//                             ? "One-off"
//                             : matchingItem?.repeatCycle == 2
//                               ? "Daily"
//                               : matchingItem?.repeatCycle == 3
//                                 ? "Weekly"
//                                 : matchingItem?.repeatCycle == 4
//                                   ? "Monthly"
//                                   : "Custom"}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faCalendar} />
//                         <p>
//                           Start date:{" "}
//                           {formatDate(matchingItem?.startDate) || ""}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faCalendar} />
//                         <p>
//                           End date: {formatDate(matchingItem?.endDate) || ""}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="underline-gray-1"></div>

//                   <div className="  text-[#646464]  sch-box">
//                     <div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           Posted by{" "}
//                           <span className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">
//                             {" "}
//                             {`${matchingItem?.jobCreatorDetails?.firstName || ""
//                               } ${matchingItem?.jobCreatorDetails?.lastName || ""
//                               }`}
//                           </span>
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px capitalize">
//                           {matchingItem?.jobCreatorRole || ""}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px capitalize">
//                           {getTimeDifference(matchingItem?.createdAt) || ""}
//                         </p>
//                       </div>
//                     </div>

//                     <div className="">
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           Assigned to{" "}
//                           <span className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">
//                             {" "}
//                             {`${matchingItem?.applicantDetails?.firstName || ""
//                               } ${matchingItem?.applicantDetails?.lastName || ""
//                               }`}
//                           </span>
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px capitalize">
//                           {matchingItem?.applicantDetails?.roleCategory ||
//                             ""}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         {/* <p className="font-Poppins-Medium font-size-15px">5 days ago</p> */}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="theme-grey-type ">
//                     <div className="flex mt-2">
//                       <div className=" " style={{ backgroundColor: "#ffffff" }}>
//                         <h2 className="font-Poppins-SemiBold text-[#0c0c0c] font-size-15px">
//                           Service details
//                         </h2>
//                         {/* <div className="flex items-center gap-2 font-Poppins-Regular font-size-16px p-4">
//                                                     <FontAwesomeIcon size="2xl" icon={faBars} />
//                                                     <p>Light housework</p>
//                                                 </div> */}
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="font-Poppins-Regular font-size-12px mt-2 mb-2 capitalize">
//                         {matchingItem?.description || ""}
//                       </p>
//                     </div>
//                   </div>
//                   {matchingItem?.status === "onboard" && (
//                     <div className="relative flex justify-evenly gap-2">
//                       <button
//                         onClick={() => setRescheduleModal(matchingItem)}
//                         class="status-job-change"
//                         style={{ backgroundColor: "orange" }}
//                       >
//                         Reschedule
//                       </button>
//                       <JobCompleteRequest
//                         jobId={matchingItem?._id}
//                         completedFucntion={() => setBool((prev) => !prev)}
//                         btnClass={"status-job-change"}
//                         extraStyle={{ backgroundColor: "green" }}
//                       />
//                       <JobCancelButton
//                         jobId={matchingItem?._id}
//                         setActiveTab={() => setBool((prev) => !prev)}
//                         btnClass={"status-job-change"}
//                         extraStyle={{ backgroundColor: "red" }}
//                       />
//                       <button
//                         onClick={() => setDisputeModal(matchingItem?._id)}
//                         class="status-job-change"
//                         style={{ backgroundColor: "#d7d714" }}
//                       >
//                         Dispute
//                       </button>
//                     </div>
//                   )}
//                 </div>
//               </div>
//             </div>
//           </td>
//         );
//       } else {
//         // If no matching item is found, render an empty <td>
//         columns.push(<td key={`td-${currentDate.getTime()}`}></td>);
//       }

//       // Increment currentDate by 1 day
//       currentDate.setDate(currentDate.getDate() + 1);
//     }
//     return columns;
//   };

//   return (
//     <>
//       {rescheduleModal && (
//         <JobRescheduleModal
//           onClose={() => setRescheduleModal(false)}
//           jobId={rescheduleModal?._id}
//           startDate={rescheduleModal?.startDate}
//           startTime={rescheduleModal?.startTime}
//           endDate={rescheduleModal?.endDate}
//           endTime={rescheduleModal?.endTime}
//           successFunction={() => { }}
//         />
//       )}
//       {disputeModal && (
//         <StartJobDispute
//           onClose={() => setDisputeModal(false)}
//           jobId={disputeModal}
//           successFunction={() => setBool((prev) => !prev)}
//         />
//       )}
//       <div className="calendar">
//         <table
//           className={`${view === "monthly" ? "monthly-callender" : "weekly-calender"
//             }`}
//         >
//           <thead>
//             <tr>
//               <th>
//                 <div className="flex flex-row gap-2 items-center justify-center text-center calender-search">
//                   <FontAwesomeIcon
//                     icon={faMagnifyingGlass}
//                     className="clender-search"
//                   />
//                   <input
//                     type="search"
//                     placeholder="Search by name"
//                     className="clender-search font-size-12px font-Poppins-SemiBold"
//                     value={value}
//                     onChange={(e) => onChange(e.target.value)}
//                   />
//                 </div>
//               </th>
//               {view === "monthly" &&
//                 weeks[0]?.events?.map((day, dayIndex) => (
//                   <th key={dayIndex}>{day?.date?.getDate()}</th>
//                 ))}
//               {view === "weekly" &&
//                 weeks[1]?.events?.map((day, index) => (
//                   <th key={index}>
//                     {dayNames[day?.date?.getDay()] + " " + day?.date?.getDate()}
//                   </th>
//                 ))}
//               {view === "daily" && (
//                 <>
//                   {weeks.hoursInDay.map((hour, index) => (
//                     <th key={index} style={{ width: "60px" }}>
//                       {hour.toString().padStart(2, "0")}:00
//                     </th>
//                   ))}
//                 </>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {view !== "daily" &&
//               data.map((item, index) => (
//                 <tr key={index}>
//                   <td>
//                     <div className="flex flex-row items-center gap-2 pl-2">
//                       <div>
//                         <img
//                           className="cl-img"
//                           src={
//                             jobSide === "creator"
//                               ? `${process.env.REACT_APP_BASE_URL}${item[0]?.applicantDetails?.profileImageUrl}`
//                               : `${process.env.REACT_APP_BASE_URL}${item[0]?.jobCreatorDetails?.profileImageUrl}`
//                           }
//                           onError={(e) => (e.target.src = DefaultProfileImage)}
//                         />
//                       </div>{" "}
//                       {/* user image */}
//                       <div className="flex flex-col">
//                         <span className="font-size-15px font-Poppins-SemiBold cl-main-name capitalize text-left">
//                           {jobSide === "creator" ? (
//                             <>
//                               {item[0]?.applicantDetails?.firstName || ""}{" "}
//                               {item[0]?.applicantDetails?.lastName || ""}
//                             </>
//                           ) : (
//                             <>
//                               {item[0]?.jobCreatorDetails?.firstName || ""}{" "}
//                               {item[0]?.jobCreatorDetails?.lastName || ""}
//                             </>
//                           )}
//                         </span>{" "}
//                         {/* user name */}
//                         <span className="font-size-9px font-Poppins-Regular capitalize text-left">
//                           {jobSide === "creator"
//                             ? item[0]?.applicantDetails?.roleCategory
//                             : item[0]?.jobCreatorRole}
//                         </span>
//                       </div>
//                     </div>
//                   </td>
//                   {createColumns(item)}
//                 </tr>
//               ))}
//             {/* Print empty rows to ensure a minimum row count. The minimum row count is currently set to 10, but you can change this number as needed. */}
//             {Array.from({ length: Math.max(0, 10 - data.length) }).map(
//               (_, index) => (
//                 <tr key={data.length + index}>
//                   <td style={{ height: 70 }}></td>
//                   {createColumns([])}
//                 </tr>
//               )
//             )}
//           </tbody>
//         </table>
//       </div>
//     </>
//   );
// }

// export default SchedulerBody;


import React, { useState, MouseEvent, useEffect } from "react";
import { usePopper } from 'react-popper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  jobStartDate,
  calculateAge,
  formatDate,
  getTimeDifference,
  convertTo12Hour,
} from "../../config/Helpers/helpers";
import {
  faCalendar,
  faLocationDot,
  faBriefcase,
  faMagnifyingGlass,
  faVenus,
  faClock,
} from "@fortawesome/free-solid-svg-icons";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import JobCancelButton from "./JobCancelButton";
import JobCompleteRequest from "./JobCompleteRequest";
import StartJobDispute from "./StartJobDispute";
import JobRescheduleModal from "./JobRescheduleModal";
import { Boundary } from '@popperjs/core';
interface RescheduleModalData {
  _id: string;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
}

interface SchedulerBodyProps {
  view: string;
  // weeks: { events: { date: Date; events: Event[] }[] }[];
  weeks: ( // Update weeks to include hoursInDay
    { events: { date: Date; events: Event[] }[] } |
    { hoursInDay: number[] }
  )[];
  value: string;

  onChange: (value: string) => void;
  data: any[];
  jobSide: "creator" | "contractor";
  start: string;
  end: string;
  setBool: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Event {
  date: Date;
  // Define other properties of Event if needed
}

interface Item {
  startDate: string;
  status: string;
  startTime: string;
  endTime: string;
  service: {
    servicename: string;
    [key: string]: any;
  };
  budget: number;
  applicantDetails?: {
    firstName: string;
    lastName: string;
    profileImageUrl: string;
    dateofbirth: string;
    gender: string;
    roleCategory: string;
    [key: string]: any;
  };
  jobCreatorDetails?: {
    firstName: string;
    lastName: string;
    profileImageUrl: string;
    dateofbirth: string;
    gender: string;
    roleCategory: string;
    [key: string]: any;
  };
  jobCreatorRole?: string;
  repeatCycle?: number;
  endDate?: string;
  description?: string;
  createdAt?: string;
  _id?: string;
  [key: string]: any;
}
interface UserPreferences {
  preferencemale?: boolean;
  preferencefemale?: boolean;
  preferencenopet?: boolean;
  preferencenonsmoker?: boolean;
}

interface Tooltip {
  show: boolean;
  content: any | null;
  reference: Element | null;
}
interface Week {
  events: { date: Date; events: Event[] }[];
  hoursInDay?: number[];  // Add this line to include hoursInDay optionally
}

// Or, if the `weeks` variable can sometimes have a different structure:
type WeekWithHours = { hoursInDay: number[] } | { events: { date: Date; events: Event[] }[] };
interface TooltipPopperProps {
  referenceElement: Element | null;  // Assuming this is an HTML element or null
  matchingItem: Item | null;         // Assuming Item is defined earlier
  jobSide: "creator" | "contractor"; // Based on your previous usage
  setRescheduleModal: React.Dispatch<React.SetStateAction<any>>;
  setBool: React.Dispatch<React.SetStateAction<boolean>>;
  setDisputeModal: React.Dispatch<React.SetStateAction<any>>;
}
const SchedulerBody: React.FC<SchedulerBodyProps> = ({
  view,
  weeks,
  value,
  onChange,
  data,
  jobSide,
  start,
  end,
  setBool,
}) => {

  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [isHovered, setIsHovered] = useState(false);
  const [disputeModal, setDisputeModal] = useState(false); // for dispute modal show & hide
  // const [rescheduleModal, setRescheduleModal] = useState([]); // for reschedule modal show & hide
  const [rescheduleModal, setRescheduleModal] = useState<RescheduleModalData | null>(null);

  // const [tooltip, setTooltip] = useState({ show: false, content: null, reference: null });
  const [tooltip, setTooltip] = useState<{ show: boolean; content: any; reference: HTMLDivElement | null }>({
    show: false,
    content: null,
    reference: null,
  });



  const handleMouseEnter = (e: MouseEvent<HTMLDivElement>, matchingItem: any) => {
    setTooltip({ show: true, content: matchingItem, reference: e.currentTarget });
  };

  const handleMouseLeave = (e: MouseEvent<HTMLDivElement>) => {
    setTooltip({ show: false, content: null, reference: null });
  };

  const isDateInCurrentWeek = (date: Date): boolean => {
    const now = new Date();
    const startOfWeek = new Date(now.setDate(now.getDate() - now.getDay()));
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return date >= startOfWeek && date <= endOfWeek;
  };
  const createColumns = (items: Item[] = []) => {  // Explicitly typing `items` as an array of `Item`
    if (!Array.isArray(items)) {
      items = [items]; // Wrap it in an array if it's a single object
    }
    const columns = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      const matchingItem = items.find((item) => {
        const startDate = new Date(item.startDate);
        return (
          currentDate.getDate() === startDate.getDate() &&
          currentDate.getMonth() === startDate.getMonth() &&
          currentDate.getFullYear() === startDate.getFullYear()
        );
      });

      if (matchingItem) {

        columns.push(
          <td key={`td-${currentDate.getTime()}`}>
            <div
              className={`event ${matchingItem?.status === "cancelled"
                ? "cancelled-Box"
                : matchingItem?.status === "onboard"
                  ? "Active-Box"
                  : matchingItem?.status === "completed"
                    ? "Compelete-Box"
                    : matchingItem?.status === "disputed"
                      ? "Disputed-Box"
                      : ""
                } ${isHovered ? "hovered-class" : ""} ${view === "monthly" && "monthly__grid"
                }`}
              onMouseEnter={(e) => handleMouseEnter(e, matchingItem)}
              onMouseLeave={(e) => handleMouseLeave(e)}
            >
              <div className="Shedular-main">
                <div className="UserName-calen">
                  <div>
                    <h6 className="capitalize">
                      {jobSide === "creator" ? (
                        <>
                          {matchingItem?.applicantDetails?.firstName || ""}{" "}
                          {matchingItem?.applicantDetails?.lastName || ""}
                        </>
                      ) : (
                        <>
                          {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
                          {matchingItem?.jobCreatorDetails?.lastName || ""}
                        </>
                      )}
                    </h6>
                  </div>
                  <div>
                    <span className="job__status capitalize">
                      {matchingItem?.status === "onboard"
                        ? "Active"
                        : matchingItem?.status}
                    </span>
                  </div>
                </div>
                <p className="job__time__start__end">{`${ convertTo12Hour(matchingItem?.startTime) || ""} : ${convertTo12Hour(matchingItem?.endTime) || ""}`}</p>
                <div className="UserName-calen2">
                  <p>{matchingItem?.service?.servicename || ""}</p>
                  <p>${matchingItem?.budget || 0}</p>
                </div>
              </div>
              {tooltip.show && tooltip.reference === tooltip.reference && (
                <TooltipPopper
                  referenceElement={tooltip.reference}
                  matchingItem={tooltip.content}
                  jobSide={jobSide}
                  setRescheduleModal={setRescheduleModal}
                  setBool={setBool}
                  setDisputeModal={setDisputeModal}
                />
              )}
            </div>
          </td>
        );
      } else {
        columns.push(<td key={`td-${currentDate.getTime()}`}></td>);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return columns;
  };

  // const createColumns = (items: Item[] = []) => {
  //   if (!Array.isArray(items)) {
  //     items = [items]; // Wrap it in an array if it's a single object
  //   }
  //   const columns = [];
  //   let currentDate = new Date(start);
  //   const endDate = new Date(end);

  //   while (currentDate <= endDate) {
  //     const matchingItems = items.filter((item) => {
  //       const itemStartDate = new Date(item.startDate);
  //       const itemEndDate = item.endDate ? new Date(item.endDate) : itemStartDate; // Default to start date if end date not provided
  //       return (
  //         currentDate >= itemStartDate &&
  //         currentDate <= itemEndDate
  //       );
  //     });

  //     if (matchingItems.length > 0) {
  //       matchingItems.forEach((matchingItem) => {
  //         columns.push(
  //           <td key={`td-${currentDate.getTime()}-${matchingItem._id}`}>
  //             <div
  //               className={`event ${matchingItem.status === "cancelled"
  //                 ? "cancelled-Box"
  //                 : matchingItem.status === "onboard"
  //                   ? "Active-Box"
  //                   : matchingItem.status === "completed"
  //                     ? "Compelete-Box"
  //                     : matchingItem.status === "disputed"
  //                       ? "Disputed-Box"
  //                       : ""
  //                 } ${isHovered ? "hovered-class" : ""} ${view === "monthly" && "monthly__grid"
  //                 }`}
  //               onMouseEnter={(e) => handleMouseEnter(e, matchingItem)}
  //               onMouseLeave={(e) => handleMouseLeave(e)}
  //             >
  //               <div className="Shedular-main">
  //                 <div className="UserName-calen">
  //                   <div>
  //                     <h6 className="capitalize">
  //                       {jobSide === "creator" ? (
  //                         <>
  //                           {matchingItem?.applicantDetails?.firstName || ""}{" "}
  //                           {matchingItem?.applicantDetails?.lastName || ""}
  //                         </>
  //                       ) : (
  //                         <>
  //                           {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
  //                           {matchingItem?.jobCreatorDetails?.lastName || ""}
  //                         </>
  //                       )}
  //                     </h6>
  //                   </div>
  //                   <div>
  //                     <span className="job__status capitalize">
  //                       {matchingItem?.status === "onboard"
  //                         ? "Active"
  //                         : matchingItem?.status}
  //                     </span>
  //                   </div>
  //                 </div>
  //                 <p className="job__time__start__end">{`${matchingItem?.startTime || ""} : ${matchingItem?.endTime || ""}`}</p>
  //                 <div className="UserName-calen2">
  //                   <p>{matchingItem?.service?.servicename || ""}</p>
  //                   <p>${matchingItem?.budget || 0}</p>
  //                 </div>
  //               </div>
  //               {tooltip.show && tooltip.reference === tooltip.reference && (
  //                 <TooltipPopper
  //                   referenceElement={tooltip.reference}
  //                   matchingItem={tooltip.content}
  //                   jobSide={jobSide}
  //                   setRescheduleModal={setRescheduleModal}
  //                   setBool={setBool}
  //                   setDisputeModal={setDisputeModal}
  //                 />
  //               )}
  //             </div>
  //           </td>
  //         );
  //       });
  //     } else {
  //       columns.push(<td key={`td-${currentDate.getTime()}`}></td>);
  //     }

  //     currentDate.setDate(currentDate.getDate() + 1);
  //   }

  //   return columns;
  // };
  useEffect(() => {
    if (rescheduleModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // Restore scrolling
    }

    // Cleanup function to ensure scrolling is restored when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [rescheduleModal]);

  return (
    <>
      {rescheduleModal && (
        <JobRescheduleModal
          onClose={() => setRescheduleModal(null)} // Change to set it back to null or false
          jobId={rescheduleModal?._id || ""}
          startDate={rescheduleModal?.startDate || ""}
          startTime={rescheduleModal?.startTime || ""}
          endDate={rescheduleModal?.endDate || ""}
          endTime={rescheduleModal?.endTime || ""}
          successFunction={() => { }}
        />
      )}
      {disputeModal && (
        <StartJobDispute
          onClose={() => setDisputeModal(false)}
          jobId={disputeModal || false}
          successFunction={() => setBool((prev) => !prev)}
        />
      )}
      <div className="calendar">
        <table className={`${view === "monthly" ? "monthly-callender" : "weekly-calender"}`}>
          <thead>
            <tr>
              <th>
                <div className="flex flex-row gap-2 items-center justify-center text-center calender-search">
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="clender-search" />
                  <input
                    type="search"
                    placeholder="Search by name"
                    className="clender-search font-size-12px font-Poppins-SemiBold"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
              </th>
              {view === "monthly" &&
                'events' in weeks[0] && // Type narrowing to check if 'events' exists

                weeks[0].events.map((day, dayIndex: number) => (
                  <th key={dayIndex}>{day?.date?.getDate()}</th>
                ))}
              {view === "weekly" &&
                'events' in weeks[0] && // Type narrowing to check if 'events' exists

                weeks[0].events.map((day, index: number) => (
                  <th key={index}>
                    {dayNames[day?.date?.getDay()] + " " + day?.date?.getDate()}
                  </th>
                ))}
              {view === "daily" &&
                'hoursInDay' in weeks[0] && weeks[0].hoursInDay && ( // Type narrowing to check if 'hoursInDay' exists
                  <>
                    {weeks[0].hoursInDay.map((hour: number, index: number) => (
                      <th key={index} style={{ width: "60px" }}>
                        {hour.toString().padStart(2, "0")}:00
                      </th>
                    ))}
                  </>
                )}
            </tr>
          </thead>
          <tbody>
            {view !== "daily" &&
              data.map((item, index) => (
                // data
                // .filter((item) => item.some(() => isDateInCurrentWeek(new Date(job.startDate))))
                // .map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="flex flex-row items-center gap-2 pl-2">
                      <div>
                        <img
                          className="cl-img"
                          src={
                            jobSide === "creator"
                              ? `${item?.applicantDetails?.profileImageUrl}`
                              // ? `${process.env.REACT_APP_BASE_URL_IMAGE}${item?.applicantDetails?.profileImageUrl}`
                              : `${item?.jobCreatorDetails?.profileImageUrl}`
                              // : `${process.env.REACT_APP_BASE_URL_IMAGE}${item?.jobCreatorDetails?.profileImageUrl}`
                          }

                          onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                        />
                      </div>{" "}
                      <div className="flex flex-col">
                        <span className="font-size-15px font-Poppins-SemiBold cl-main-name capitalize text-left">
                          {jobSide === "creator" ? (
                            <>
                              {item?.applicantDetails?.firstName || ""}{" "}
                              {item?.applicantDetails?.lastName || ""}
                            </>
                          ) : (
                            <>
                              {item?.jobCreatorDetails?.firstName || ""}{" "}
                              {item?.jobCreatorDetails?.lastName || ""}
                            </>
                          )}
                        </span>{" "}
                        <span className="font-size-9px font-Poppins-Regular capitalize-first-letter text-left">
                          {jobSide === "creator"
                            ? item?.applicantDetails?.roleCategory
                            : item?.jobCreatorRole}
                        </span>
                      </div>
                    </div>
                  </td>
                  {createColumns(item)}
                </tr>
              ))}
            {Array.from({ length: Math.max(0, 10 - data.length) }).map((_, index) => (
              <tr key={data.length + index}>
                <td style={{ height: 70 }}></td>
                {createColumns([])}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SchedulerBody;

const TooltipPopper: React.FC<TooltipPopperProps> = ({
  referenceElement,
  matchingItem,
  jobSide,
  setRescheduleModal,
  setBool,
  setDisputeModal,
}) => {
  // const [popperElement, setPopperElement] = useState(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);


  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport' as Boundary, // Explicitly cast 'viewport' to Boundary
          padding: 5,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [220, 0],
        },
      },
    ],
  });

  const checkPreferences = (user: UserPreferences | { [key: string]: any } | null): string => {
    if (!user) return "-";

    const {
      preferencemale,
      preferencefemale,
      preferencenopet,
      preferencenonsmoker,
    } = user;

    if (
      !preferencemale &&
      !preferencefemale &&
      !preferencenopet &&
      !preferencenonsmoker
    ) {
      return "-";
    } else {
      let result = "";

      if (preferencemale && preferencefemale) {
        result += "Male & Female";
      } else if (preferencemale) {
        result += "Only Male";
      } else if (preferencefemale) {
        result += "Only Female";
      }

      if (preferencenopet) {
        if (result !== "") result += ", ";
        result += "No Pets";
      }

      if (preferencenonsmoker) {
        if (result !== "") result += ", ";
        result += "Non Smoker";
      }
      return result;
    }
  };

  return (
    <div
      // ref={setPopperElement}
      ref={(el) => setPopperElement(el)}

      style={{ ...styles.popper, zIndex: 9999 }}
      {...attributes.popper}
      className="onhover-schd onhover-schd-clen"
    >
      <div className="admin-job-preview-main-calender ">
        <div className="upper-schd-box">
          <div className="og-job-date flex items-center gap-2">
            <FontAwesomeIcon icon={faBriefcase} />
            {matchingItem?.status === "completed" ? (
              <span className="font-Poppins-SemiBold font-size-15px">{`This job has been completed`}</span>

            )

              :
              matchingItem?.status === "cancelled" ? (
                <>
                  <div className="flex flex-row ">
                    <span className="font-Poppins-SemiBold font-size-15px">This job has been cancelled by</span>
                    <span className="font-Poppins-SemiBold font-size-15px capitalize"> {matchingItem?.cancelledBy === matchingItem?.applicantDetails?._id ? matchingItem?.applicantDetails?.firstName + " " + matchingItem?.applicantDetails?.lastName : matchingItem?.jobCreatorDetails?.firstName + " " + matchingItem?.jobCreatorDetails?.lastName}</span>
                  </div>
                </>
              )
                :
                matchingItem?.status === "disputed" ? (
                  <span className="font-Poppins-SemiBold font-size-15px">{`This job has been disputed`}</span>

                )
                  :
                  (
                    <span className="font-Poppins-SemiBold font-size-15px">{`This job  ${jobStartDate(matchingItem?.startDate || "") || ""}`}</span>

                  )
            }
          </div>
          <p className="font-Poppins-Regular font-size-15px mt-2 mb-2">
            Use filters to widen or narrow your Search Result
          </p>
        </div>
        <div className="admin-preview-job mt-2">
          <div className="">
            <h2 className="font-Poppins-SemiBold font-size-18px text-[#00443f] text-left">
              {matchingItem?.service.servicename || ""}
            </h2>
            <p className="font-Poppins-Medium font-size-15px text-[#646464] capitalize">
              {jobSide === "creator" ? (
                <>
                  {matchingItem?.applicantDetails?.firstName || ""}{" "}
                  {matchingItem?.applicantDetails?.lastName || ""}
                </>
              ) : (
                <>
                  {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
                  {matchingItem?.jobCreatorDetails?.lastName || ""}
                </>
              )}
            </p>
            {matchingItem?.applicantDetails?.dateofbirth &&
              <p className="font-Poppins-Medium font-size-15px text-[#646464]">
                {jobSide === "creator"
                  ? calculateAge(
                    matchingItem?.applicantDetails?.dateofbirth || "-"
                  )
                  : calculateAge(
                    matchingItem?.jobCreatorDetails?.dateofbirth || "-"
                  )} Year old

              </p>

            }

            <p className="font-Poppins-Medium font-size-15px text-[#646464] capitalize">
              {jobSide === "creator"
                ? matchingItem?.applicantDetails?.gender
                : matchingItem?.jobCreatorDetails?.gender}
            </p>
          </div>
        </div>
        <div className="underline-gray-1"></div>

        <div className="  text-[#646464] sch-box font-awesome-color">
          <div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faLocationDot} />
              <p>{`${matchingItem?.suburb || ""}, ${matchingItem?.state || ""
                }, ${matchingItem?.postCode || ""}`}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon className="" icon={faVenus} />
              <p>
                {jobSide === "creator"
                  ? checkPreferences(matchingItem?.applicantDetails as UserPreferences)
                  : checkPreferences(matchingItem?.jobCreatorDetails as UserPreferences)}
              </p>
            </div>
          </div>

          <div className="font-awesome-color">
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faClock} />
              <p>
                {matchingItem?.repeatCycle == 1
                  ? "One-off"
                  : matchingItem?.repeatCycle == 2
                    ? "Daily"
                    : matchingItem?.repeatCycle == 3
                      ? "Weekly"
                      : matchingItem?.repeatCycle == 4
                        ? "Monthly"
                        : "Custom"}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>
                Start date:{" "}
                {formatDate(matchingItem?.startDate || "")}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>
                End date: {formatDate(matchingItem?.endDate || "")}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>Start time: {convertTo12Hour((matchingItem?.startTime || ""))}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>End time: {convertTo12Hour((matchingItem?.endTime || ""))}</p>
            </div>
          </div>
        </div>
        <div className="underline-gray-1"></div>

        <div className="  text-[#646464]  sch-box">
          <div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px">
                Posted by{" "}
                <span className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">
                  {" "}
                  {`${matchingItem?.jobCreatorDetails?.firstName || ""
                    } ${matchingItem?.jobCreatorDetails?.lastName || ""
                    }`}
                </span>
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px capitalize">
                {matchingItem?.jobCreatorRole || ""}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px ">
                {getTimeDifference(matchingItem?.createdAt || "")}
              </p>
            </div>
          </div>

          <div className="">
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px">
                Assigned to{" "}
              </p>
              <span className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">
                {" "}
                {`${matchingItem?.applicantDetails?.firstName || ""
                  } ${matchingItem?.applicantDetails?.lastName || ""
                  }`}
              </span>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px capitalize-first-letter">
                {matchingItem?.applicantDetails?.roleCategory ||
                  ""}
              </p>
            </div>
          </div>
        </div>

        <div className="theme-grey-type ">
          <div className="flex mt-2">
            <div className=" " style={{ backgroundColor: "#ffffff" }}>
              <h2 className="font-Poppins-SemiBold text-[#0c0c0c] font-size-15px">
                Service details
              </h2>
            </div>
          </div>
          <div className="">
            <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
              {matchingItem?.description || ""}
            </p>
          </div>
        </div>

        {matchingItem?.status === "onboard" && (
          <div className="relative flex justify-evenly gap-2 schedular-modals">
            <button
              onClick={() => setRescheduleModal(matchingItem)}
              className="status-job-change"
              style={{ backgroundColor: "orange" }}
            >
              Reschedule
            </button>

            <JobCompleteRequest
              jobId={matchingItem?._id}
              completedFucntion={() => setBool((prev) => !prev)}
              btnClass={"status-job-change"}
              extraStyle={{ backgroundColor: "green" }}
            />
            <JobCancelButton
              jobId={matchingItem?._id}
              setActiveTab={() => setBool((prev) => !prev)}
              btnClass={"status-job-change"}
              extraStyle={{ backgroundColor: "red" }}
            />
            <button
              onClick={() => setDisputeModal(matchingItem?._id)}
              className="status-job-change"
              style={{ backgroundColor: "#d7d714" }}
            >
              Dispute
            </button>
          </div>
        )}
      </div>
    </div >
  );
};
