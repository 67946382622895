import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faLocationDot,
  faCalendarDays,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getTimeDifference, getCookie } from "../../config/Helpers/helpers";
import Skeleton from '@mui/material/Skeleton';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import moment from 'moment';
import axios from 'axios';
import { faX, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import CreateJobsModal from '../JobsComponents/CreateJobsModal';



interface Service {
  _id: string;
  servicename?: string;
}
export interface Job {
  _id?: any;
  services: Service[];
  contactNumber: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  repeatCycle: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  budget: string;
  description: string;
  allServices?: Service[];
  jobStatus?: string;
  createdAt?: any;
  // customInterval?: number; // Optional property
  numberOfApplicants?: number;
  [key: string]: any; // Additional properties
}

interface PostJob {
  _id?: any;
  services: Service[];
  contactNumber: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  repeatCycle: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  budget: string;
  description: string;
  allServices?: Service[];
  jobStatus?: string;
  createdAt?: any;
  // customInterval: number;
}
interface message {
  message: string;
}
interface DeleteApiResponse {
  response: message;
  [key: string]: any;
}
interface ApiResponse {
  message: string;
}
interface JobRequestCardsProps {
  data: Job[];
  loader: boolean;
  setSelectedJobs: (job: Job) => void;
  setBool: React.Dispatch<React.SetStateAction<boolean>>;
}
const JobRequestCards: React.FC<JobRequestCardsProps> = ({ data, loader, setSelectedJobs, setBool }) => {
  // 04-25-24
  const token = getCookie();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`
    },
  };
  const [approveModal, setApproveModal] = useState<string | false>(false);
  const [approveLoader, setApproveLoader] = useState<boolean>(false);
  const [declineModal, setDeclineModal] = useState<boolean>(false);
  const [declineReasonModal, setDeclineReasonModal] = useState<boolean>(false);
  const [declineLoader, setDeclineLoader] = useState<boolean>(false);
  const [declineId, setDeclineId] = useState<string | null>(null);
  const [declineReason, setDeclineReason] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<string | false>(false);
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);
  const [restoreModal, setRestoreModal] = useState<string | false>(false);
  const [restoreLoader, setRestoreLoader] = useState<boolean>(false);
  const [isEditModal, setIsEditModal] = useState<boolean>(false);
  const [postJob, setPostJob] = useState<Job>({
    _id: "",
    services: [],
    contactNumber: "",
    streetAddress: "",
    suburb: "",
    state: "",
    postCode: "",
    repeatCycle: 1,
    startDate: "",
    startTime: "",
    endDate: "",
    endTime: "",
    budget: "",
    description: "",
    allServices: [],
    jobStatus: "",
    createdAt: ""
  });

  // const handleEdit = (item: Job) => {
  //   console.log(
  //     item, "adasdsadasdasddasdasasdasd"
  //   )
  //   setIsEditModal(true);
  //   setPostJob({
  //     _id: item?._id,
  //     services: item?.allServices || [],
  //     contactNumber: item?.contactNumber || "",
  //     streetAddress: item?.streetAddress || "",
  //     city: item?.suburb || "",
  //     state: item?.state || "",
  //     postCode: item?.postCode || "",
  //     repeatCycle: item?.repeatCycle || 1,
  //     startDate: item?.startDate || "",
  //     startTime: item?.startTime || "",
  //     endDate: item?.endDate || "",
  //     endTime: item?.endTime || "",
  //     budget: item?.budget || "",
  //     description: item?.description || "",
  //     allServices: item?.allServices || [],
  //     jobStatus: item?.status || "",
  //     createdAt: item?.createdAt || "",
  //     customInterval: item?.customInterval || 0, // Set this to a default or existing value

  //   });
  // };
  const handleEdit = (item: Job) => {

    const selectedServices = [{
      _id: item.serviceId,  // Assuming serviceId is the ID
      servicename: item.serviceName  // Assuming serviceName is the name of the service
    }];

    setIsEditModal(true);
    setPostJob({
      _id: item._id || "",
      services: selectedServices,  // Set the found service objects
      contactNumber: item.contactNumber || "",
      streetAddress: item.streetAddress || "",
      suburb: item.suburb || "",
      state: item.state || "",
      postCode: item.postCode || "",
      repeatCycle: item.repeatCycle || 1,
      startDate: item.startDate || "",
      startTime: item.startTime || "",
      endDate: item.endDate || "",
      endTime: item.endTime || "",
      budget: item.budget || "",
      description: item.description || "",
      // customInterval: item.customInterval || 0,
      allServices: item.allServices || [],  // Include this
      jobStatus: item.status || "",      // Include this
      createdAt: item.createdAt || "",      // Include this
    });
  };


  const handleApproval = (id: string) => {
    setApproveModal(id)
  };

  // Functionality: API call for job request approved Admin
  const approvedApiCall = async () => {
    setApproveLoader(true);
    try {
      const response = await axios.put(`${process.env.REACT_APP_BASE_URL}/api/admin/job/approve`,
        { jobId: approveModal }, config);
      if (response.status == 200) {
        setApproveModal(false);
        // toast(response?.data?.message || "Success", { type: "success" });
        setBool(prev => !prev);
      }
    }
    catch (err: any) {
      toast(
        err.response?.data?.message || err.response?.data?.error || "Error",
        { type: "error" });
    }
    setApproveLoader(false);
  };

  const handleDecline = (id: string) => {
    setDeclineId(id);
    setDeclineModal(true);
  };

  const declineReasonModalShow = () => {
    setDeclineModal(false);
    setDeclineReasonModal(true);
  }

  // Functionality: API call for job request declined Admin
  const declineApiCall = async () => {
    if (declineReason && declineReason.length > 50) {
      toast("Reason for decline cannot exceed 50 characters.", { type: "error" });
      return;
    }
    if (declineReason) {
      setDeclineLoader(true);
      try {
        const response = await axios.put<ApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/admin/job/decline`,
          { jobId: declineId, message: declineReason }, config);
        if (response.status == 200) {
          setDeclineReasonModal(false);
          setDeclineId(null);
          setDeclineReason("");
          toast(response?.data?.message || "Success", { type: "success" });
          setBool(prev => !prev);
        }
      }
      catch (err: any) {
        toast(
          err.response?.data?.message || err.response?.data?.error || "Error",
          { type: "error" });
      }
      setDeclineLoader(false);
    }
    else {
      toast("Please specify the reason.", { type: "error" });
    }
  };

  const handleTrash = (id: string) => {
    setDeleteModal(id)
  };

  // Functionality: API call for job delete Admin
  const deleteApiCall = async () => {
    setDeleteLoader(true);
    try {
      const response = await axios.delete<DeleteApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/admin/job/delete/${deleteModal}`, config);
      if (response.status == 200) {
        setDeleteModal(false);
        toast(response?.data?.message || "Success", { type: "success" });
        setBool(prev => !prev);
      }
    }
    catch (err: any) {
      toast(
        err.response?.data?.message || err.response?.data?.error || "Error",
        { type: "error" });
    }
    setDeleteLoader(false);
  };

  const handleRestore = (id: string) => {
    setRestoreModal(id);
  };

  // Functionality: API call for job restore from declined to pending
  const restoreApiCall = async () => {
    setRestoreLoader(true);
    try {
      const response = await axios.put<DeleteApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/admin/job/restore`,
        { jobId: restoreModal }, config);
      if (response.status == 200) {
        setRestoreModal(false);
        toast(response?.data?.message || "Success", { type: "success" });
        setBool(prev => !prev);
      }
    }
    catch (err: any) {
      toast(
        err.response?.data?.message || err.response?.data?.error || "Error",
        { type: "error" });
    }
    setRestoreLoader(false);
  };
  return (
    <div className="admin-Cleaning-main">
      {isEditModal &&
        <CreateJobsModal
          hideModal={setIsEditModal}
          isEdit={true}
          postJob={postJob}
          setPostJob={setPostJob}
          setSuccessModal={() => setBool(prev => !prev)}
        />
      }
      {approveModal &&
        <div className="modal">
          <div className="approved-modal-content">
            <h2 className="font-size-36px font-Poppins-Medium">Approve job</h2>
            <p className="text-[#2F2F31] my-3 font-size-18px font-Poppins-Regular">Are you sure you want to approve this job?</p>
            <div className="flex approved-modal justify-center gap-2">
              <button disabled={approveLoader} className="font-size-17px font-Poppins-Medium flex flex-row items-center gap-2" onClick={approvedApiCall}>Approve {approveLoader && <CircularProgress style={{ width: 16, height: 16 }} />}</button>
              <button className="font-size-17px font-Poppins-Medium" onClick={() => setApproveModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      }
      {declineModal &&
        <div className="modal">
          <div className="approved-modal-content">
            <h2 className="font-size-36px font-Poppins-Medium">Decline job</h2>
            <p className="text-[#2F2F31] my-3 font-size-18px font-Poppins-Regular">Are you sure want to decline this job?</p>
            <div className="flex approved-modal decline-modal justify-center gap-2">
              <button className="font-size-17px font-Poppins-Medium" onClick={declineReasonModalShow}>Decline</button>
              <button className="font-size-17px font-Poppins-Medium" onClick={() => setDeclineModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      }
      {declineReasonModal &&
        <div className="modal">
          <div className="Cancellation-modal-content relative">
            <button
              onClick={() => setDeclineReasonModal(false)}
              className="absolute job-pre-edit"
            >
              <FontAwesomeIcon
                className="x-icon-admin-opr"
                icon={faX}
                style={{ color: "#ffffff" }}
              />
            </button>
            <div className="cancellation-envolpe-main">
              <div className="cancellation-envolpe">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </div>
            <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">Please specify the reason of <br /> cancellation</h2>
            <textarea value={declineReason} onChange={(e) => setDeclineReason(e.target.value)} className="resize-none canellation-textarea font-size-15px theme-color-para font-Poppins-Regular"></textarea>
            <div className="flex cancellation-mdl-btn justify-center gap-2">
              <button onClick={declineApiCall} disabled={declineLoader}
                className="font-size-17px font-Poppins-Medium flex flex-row items-center gap-2">Submit  {declineLoader && <CircularProgress style={{ width: 16, height: 16 }} />}</button>
              <button className="font-size-17px font-Poppins-Medium" onClick={() => setDeclineReasonModal(false)}
              >Cancel</button>
            </div>
          </div>
        </div>
      }
      {deleteModal &&
        <div className="modal">
          <div className="approved-modal-content">
            <h2 className="font-size-36px font-Poppins-Medium">Trash job</h2>
            <p className="text-[#2F2F31] my-3 font-size-18px font-Poppins-Regular">Are you sure want to trash this job?</p>
            <div className="flex approved-modal justify-center gap-2">
              <button disabled={deleteLoader} className="font-size-17px font-Poppins-Medium" onClick={deleteApiCall}>Trash {deleteLoader && <CircularProgress style={{ width: 16, height: 16 }} />}</button>
              <button className="font-size-17px font-Poppins-Medium" onClick={() => setDeleteModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      }
      {restoreModal &&
        <div className="modal">
          <div className="approved-modal-content">
            <h2 className="font-size-36px font-Poppins-Medium">Restore job</h2>
            <p className="text-[#2F2F31] my-3 font-size-18px font-Poppins-Regular">Are you sure want to restore this job?</p>
            <div className="flex approved-modal justify-center gap-2">
              <button disabled={restoreLoader} className="font-size-17px font-Poppins-Medium" onClick={restoreApiCall}>Restore {restoreLoader && <CircularProgress style={{ width: 16, height: 16 }} />}</button>
              <button className="font-size-17px font-Poppins-Medium" onClick={() => setRestoreModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      }
      {loader ?
        <>
          {Array.from({ length: 6 }, (_, index) => (
            <Skeleton key={index} variant="rounded" width={215} height={225} />
          ))}
        </>
        :
        data && data.length > 0 ?
          data.map((item, index) => (
            <div key={index} className="admin-Cleaning-box1">
              <div role="button" onClick={() => setSelectedJobs(item)}>
                <div className={`${item?.status === "pending" ? 'admin-box-pending-btn'
                  : item?.status === "active" ? 'admin-box-aprroved-btn'
                    : item?.status === "declined" ? 'admin-box-declined-btn'
                      : ""
                  }`}>
                  <span className="font-Poppins-Medium font-size-14px capitalize">
                    {item?.status || "-"}
                  </span>
                </div>
                <h2 className="font-Poppins-Medium mt-2 font-size-20px job-card-name">
                  {item?.serviceName || ""}
                  {/* {item?.allServices?.length > 1 ? ", " : ""} */}
                  {/* {item?.allServices?.servicename || ""} */}
                  {/* <span style={{ fontSize: 10, fontWeight: 'normal' }}>
                    {item?.allServices?.length > 2 ? " & others " : ""}
                  </span> */}
                </h2>
                <p className="theme-color-para leading-3 font-size-12px font-Poppins-Regular">
                  {getTimeDifference(item?.createdAt) || ""}
                </p>
                <div className="admin-box-inner-main">
                  <div className="admin-box-weekly-cal">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p className="theme-color-para font-size-14px font-Poppins-Regular">
                      {
                        item?.repeatCycle == 1 ? "One-off"
                          : item?.repeatCycle == 2 ? "Daily"
                            : item?.repeatCycle == 3 ? "Weekly"
                              : item?.repeatCycle == 4 ? "Monthly"
                                : "Custom"
                      }
                    </p>
                  </div>
                  <div className="admin-box-weekly-cal">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <p className="theme-color-para font-size-14px font-Poppins-Regular">
                      {`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}
                    </p>
                  </div>
                  <div className="flex calen-job-req gap-8">
                    <div className="admin-box-weekly-cal admin-box-weekly-date">
                      <FontAwesomeIcon icon={faCalendarDays} />
                      <p className="theme-color-para font-size-11px font-Poppins-Regular">
                        Start date: {moment(item?.startDate).format("DD-MM-YYYY") || '-'}
                      </p>
                    </div>
                    <div className="admin-box-weekly-cal admin-box-weekly-date">
                      <FontAwesomeIcon icon={faCalendarDays} />
                      <p className="theme-color-para font-size-11px font-Poppins-Regular">
                        End date: {moment(item?.endDate).format("DD-MM-YYYY") || '-'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="job-req-min-height job-req-min-height-box1">
                  <p className="theme-color-para font-size-16px font-Poppins-Regular">
                    Rate :{" "}
                    <span className="font-Poppins-SemiBold theme-color-green">
                      ${item?.budget || "-"}
                    </span>
                    <span className="font-size-10px"></span>
                  </p>
                </div>
              </div>
              {
                item?.status === "pending" 
                &&
                 moment().isBefore(moment(item?.endDate))
                 ?
                  <div className="admin-box-app-dec">
                    <button
                      onClick={() => handleApproval(item?._id)}
                      className="font-Poppins-SemiBold theme-bg-green font-size-12px"
                    >
                      Approve
                    </button>
                    <button
                      onClick={() => handleDecline(item?._id)}
                      className="font-Poppins-SemiBold bg-[#DF0000] font-size-12px"
                    >
                      Decline
                    </button>
                  </div>
                  : item?.status === "active" ?
                    <div className="admin-box-app-dec">
                      {/* <button
                        onClick={() => handleEdit(item)}
                        className="font-Poppins-SemiBold items-center justify-center flex gap-2 theme-bg-green font-size-12px"
                        // disabled={item?.numberOfApplicants || 0}
                        title={item?.numberOfApplicants ? "This job post has applicant therefore it can not be edited." : ""}
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                        Edit
                      </button> */}
                      <button
                        onClick={() => handleTrash(item?._id)}
                        className="font-Poppins-SemiBold items-center justify-center flex gap-2 bg-[#DF0000] font-size-12px"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                        Delete
                      </button>
                    </div>
                    : item?.status === "declined" ?
                      <div className="admin-box-app-dec">
                        {/* <button
                          onClick={() => handleRestore(item?._id)}
                          className="font-Poppins-SemiBold theme-bg-green font-size-12px"
                        >
                          Restore
                        </button> */}
                        <button
                          onClick={() => handleTrash(item?._id)}
                          className="font-Poppins-SemiBold bg-[#DF0000] font-size-12px"
                        >
                          Trash
                        </button>
                      </div>
                      :
                      <div className="admin-box-app-dec">
                        {/* <button
                        onClick={() => handleRestore(item?._id)}
                        className="font-Poppins-SemiBold theme-bg-green font-size-12px"
                      >
                        Restore
                      </button> */}
                        <button
                          onClick={() => handleTrash(item?._id)}
                          className="font-Poppins-SemiBold bg-[#DF0000] font-size-12px"
                        >
                          Trash
                        </button>
                      </div>
              }
            </div>
          ))
          :
          <p className='font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center justify-center mb-2'>No jobs</p>
      }
    </div>
  )
}

export default JobRequestCards