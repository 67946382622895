import React, { useState, useEffect } from "react";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import { faLocationDot, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import { toast } from "react-toastify";
import Select, { ActionMeta, SingleValue } from "react-select";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";
interface ApiResponse {
  message: string;
  documents: [];
  [key: string]: any;
}
interface OptionType {
  value: string;
  label: string;
  [key: string]: any;
}

interface UserData {
  workLocation?: string[];
}

interface PersonalData {
  workLocation?: string[];
}
const WorkLocationEdit: React.FC = () => {
  const dispatch = useDispatch();
  const { personalData } = useSelector((store: RootState) => store.userProfile) as { personalData?: PersonalData };

  const [editLoader, setIsEditLoader] = useState(false); // loader for edit locations
  const [isLoader, setIsLoader] = useState(false); // loader for fetch locations
  const [selectedServices, setSelectedServices] = useState<SingleValue<OptionType>>(null);
  const [myServices, setMyServices] = useState<string[]>([]);
  const [options, setOptions] = useState<OptionType[]>([]);
  const [inputValue, setInputValue] = useState("");

  // remove
  const handleRemove = (index = 0) => {
    const updatedServices = [...myServices];
    updatedServices.splice(index, 1);
    setMyServices(updatedServices);
  };

  // Functionality: API call for for update work locations
  const handleSubmit = async () => {
    if (!myServices.length) {
      toast("Please add atleast one location", { type: "error" });
    }
    setIsEditLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      method: "put",
      path: "/api/provider/work-location",
      params: { workLocation: myServices },
    });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err.message, { type: "error" });
    }
    setIsEditLoader(false);
  };

  // Functionality: API call for search location api
  const fetchLocations = async (post: string) => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/postcodes/all?search=${post}`,
    });
    console.log(res, "res")
    if (res) {
      setOptions(res?.documents);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };
  // if (res) {
  //   const options = res.documents.map((doc: { _id: string; suburb: string; state: string; postcode: string }) => ({
  //     value: doc._id,
  //     label: `${doc.suburb} ${doc.state} ${doc.postcode}`,
  //   }));
  //   setOptions(options);
  // } else {
  //   toast(err?.message, { type: "error" });
  // }
  //   setIsLoader(false);
  // };

  // const handleAdd = () => {
  //   if (Object.keys(selectedServices).length > 0) {
  //     setMyServices([...myServices, selectedServices.label]);
  //     setSelectedServices({});
  //   }
  // }; // handle add location in local state
  const handleAdd = () => {

    if (selectedServices) {
      const serviceLabel = selectedServices.label;

      if (!myServices.includes(serviceLabel)) {
        setMyServices([...myServices, serviceLabel]);
      } else {
        toast('This location is already added', { type: 'info' });
      }
      setSelectedServices(null);
    }
  };
  const handleInputChange = (value: string) => {
    if (/^\d{0,4}$/.test(value)) {
      // Check if the input is a number and max length of 4
      setInputValue(value);
    }
  }; // handle search input change

  const handleChange = (event: SingleValue<OptionType>, actionMeta: ActionMeta<OptionType>) => {
    setSelectedServices(event);
  }; // dropdown input change

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (inputValue) {
        fetchLocations(inputValue);
      }
    }, 1500);

    return () => clearTimeout(debounceTimer);
  }, [inputValue]);

  useEffect(() => {
    if (personalData?.workLocation) {
      setMyServices(personalData?.workLocation);
    }
  }, [personalData]);
  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title="Work locations" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
            <div>
              <p className="font-size-15px font-Poppins-Regular theme-color-green flex mb-3">
                Add as many suburbs or postcodes you are willing to travel to
                for work. We recommend at least 3.
              </p>
              <label
                htmlFor=""
                className="pb-1 font-size-15px theme-grey-type  font-Poppins-Medium  "
                style={{ width: "100%", display: "flex" }}
              >
                Enter a post code.
              </label>
              <div className="flex w-full custom-input gap-3 multi-select-location">
                <Select
                  value={selectedServices}
                  options={options.map((option) => ({
                    value: option._id,
                    label: `${option.suburb} ${option.state} ${option.postcode}`,
                  }))}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  isLoading={isLoader}
                />
                {/* <Select
                  value={selectedServices}
                  options={options}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  onChange={handleChange}
                  isLoading={isLoader}
                /> */}
                <button
                  onClick={handleAdd}
                  className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                  style={{ color: "white" }}
                >
                  Add
                </button>
              </div>
              <div className="flex gap-2 items-center flex-wrap">
                {myServices.length > 0
                  ? myServices.map((item, index) => (
                    <p
                      key={index}
                      className="mt-5 feeds-btn font-size-20px w-fit	font-Poppins-Medium theme-grey-type gap-2"
                      style={{
                        backgroundColor: "rgb(112 112 112 / 23%)",
                        color: "#464646",
                        border: "none",
                      }}
                    >
                      <FontAwesomeIcon icon={faLocationDot} color="#464646" />
                      {item || ""}
                      <FontAwesomeIcon
                        role="button"
                        onClick={() => handleRemove(index)}
                        icon={faXmark}
                        color="#464646"
                      />
                    </p>
                  ))
                  : null}
              </div>
              <button
                onClick={handleSubmit}
                disabled={editLoader}
                type="button"
                className="mt-8 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Save and continue{" "}
                {editLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkLocationEdit;
