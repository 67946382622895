/**
 * AdminOperators Component
 * CRUD Operators.
 * File: AdminOperators.js
 * Author: Developer
 * Date: 03-07-24
 */

import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import {
  faEllipsisVertical,
  faPencil,
  faTrash,
  faX,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import DeleteModal from "../../components/AdminComponents/AdminDeleteModal";
// import SuccessModal from "../../components/Modal/SuccessModal";
import moment from "moment";
import qs from "qs";
import { toast } from "react-toastify";

import {
  validateText,
  validateEmail,
  validatePassword,
  validateNumber,
  getRole,
} from "../../config/Helpers/helpers";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import httpRequest from "../../config/Helpers/httpRequest";
interface Operator {
  operatorId?: string;
  _id?: string;
  firstName: string;
  lastName: string;
  userName: string;
  password?: string;
  email: string;
  phonenumber: string;
  adminType: string;
  isSuperAdmin: boolean;
  module1read: boolean;
  module1write: boolean;
  module2read: boolean;
  module2write: boolean;
  module3read: boolean;
  module3write: boolean;
  module4read: boolean;
  module4write: boolean;
  createdAt?: string;
  lastLogin?: string[];
  totalResults?: number;
  [key: string]: any;
}
interface Paginated {
  totalItems: number;
  totalPages: number;
}
interface QueryParams {
  adminType: string;
  name: string;
  page: number;
  pageSize: number;
  sort: string;
}
interface ApiResponse {
  paginated: Paginated;
  totalResults: { count: number }[];

  documents: Operator[];
}
function AdminOperators() {
  const decoded = getRole()
  const userId = decoded?.userId;
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [DropDown, setDropDown] = useState<number | boolean>(false);
  const [isComponentLoad, setIsComponentLoad] = useState<boolean>(false);

  // Function to handle dropdown for edit/delete options
  const handleDropDown = (index: number) => {
    if (DropDown === index) {
      setDropDown(false);
    } else {
      setDropDown(index);
    }
  };

  const [isError, setIsError] = useState<string | boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | undefined | boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isSucsModal, setIsSucsModal] = useState<boolean>(false);
  const [bool, setBool] = useState<boolean>(false);
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [allOperator, setAllOperator] = useState<Operator[]>([]);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordSecond, setShowPasswordSecond] = useState<boolean>(false);
  const [addOperator, setAddOperator] = useState<Operator>({
    firstName: "",
    lastName: "",
    userName: "",
    password: "",
    email: "",
    phonenumber: "",
    adminType: "",
    isSuperAdmin: false,
    module1read: false,
    module1write: false,
    module2read: false,
    module2write: false,
    module3read: false,
    module3write: false,
    module4read: false,
    module4write: false,
  });

  const clearState = () => {
    setAddOperator({
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      phonenumber: "",
      adminType: "",
      isSuperAdmin: false,
      module1read: false,
      module1write: false,
      module2read: false,
      module2write: false,
      module3read: false,
      module3write: false,
      module4read: false,
      module4write: false,
    });
  };

  const openModal = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setConfirmPassword("");
    clearState();
    setIsEdit(false);
  };

  // input changes
  const handleName = (key: keyof Operator, value: string) => {
    // change first & last name
    if (value === "") {
      setAddOperator({ ...addOperator, [key]: "" });
    } else if (validateText(value)) {
      setAddOperator({ ...addOperator, [key]: value });
    }
  };
  const handleChanges = (key: keyof Operator, value: string) => {
    setAddOperator({ ...addOperator, [key]: value });
  };
  const handlePhone = (value: string) => {
    if (value === "") {
      setAddOperator({ ...addOperator, phonenumber: value });
    } else if (validateNumber(value) && value.length < 11) {
      setAddOperator({ ...addOperator, phonenumber: value });
    }
  };
  // input changes

  // privilages changes
  // const handlePrivChange = (module: keyof Operator["privileges"], type: string) => {
  //   setAddOperator((prevState) => {
  //     const newPrivileges = { ...prevState.privileges };
  //     const currentPrivilege = newPrivileges[module];

  //     if (newPrivileges[module] === 3) {
  //       newPrivileges[module] = type === "read" ? 2 : 1;
  //     } else {
  //       if (type === "read") {
  //         newPrivileges[module] =
  //           currentPrivilege === 0 || currentPrivilege === 2 ? 1 : 0;
  //       } else if (type === "write") {
  //         newPrivileges[module] =
  //           currentPrivilege === 0 ? 2 : currentPrivilege === 1 ? 3 : 0;
  //       }

  //       // Check if both read and write privileges are selected, set to 3 (both read and write)
  //       if (newPrivileges[module] === 1 && prevState.privileges[module] === 2) {
  //         newPrivileges[module] = 3;
  //       }
  //     }

  //     return {
  //       ...prevState,
  //       privileges: newPrivileges,
  //     };
  //   });
  // };
  const handlePrivChange = (key: keyof Operator) => {
    setAddOperator((prevState) => ({
      ...prevState,
      [key]: !prevState[key],
    }));
  };
  // privilages changes

  // Function to handle edit of an operator
  const handleEdit = (item: Operator) => {
    setDropDown(false);
    setIsEdit(true);
    openModal();
    console.log(item, `  edit`);
    // Set operator data for editing
    setAddOperator({
      operatorId: item?._id,
      firstName: item?.firstName,
      lastName: item?.lastName,
      userName: item?.userName,
      email: item?.email,
      phonenumber: item?.phonenumber,
      adminType: item?.adminType,
      isSuperAdmin: item?.isSuperAdmin ?? false,
      module1read: item?.module1read ?? false,
      module1write: item?.module1write ?? false,
      module2read: item?.module2read ?? false,
      module2write: item?.module2write ?? false,
      module3read: item?.module3read ?? false,
      module3write: item?.module3write ?? false,
      module4read: item?.module4read ?? false,
      module4write: item?.module4write ?? false,
    });
  };

  // delete
  const handleDeleteModal = (id: string | undefined) => {
    setDropDown(false);
    setDeleteModal(true);
    setDeleteId(id);
  };

  // delete api integrate
  // Functionality: API call for delete operator ADMIN
  const handleConfirmDelete = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest({ method: "put", path: `/api/operator/deactivate/${deleteId}`, params: {} });
    if (res) {
      setBool(!bool);
      setDeleteId(false);
      setDeleteModal(false);
      setIsSucsModal(true);
      setSuccessMsg("Opeator deleted successfully");
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };
  const usernamePattern = /^[a-zA-Z0-9_]+$/;

  // Functionality: API call to handle submission of operator data
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    // Validation checks
    if (!addOperator.firstName) {
      setIsError("Please enter first name.");
    } else if (!addOperator.lastName) {
      setIsError("Please enter last name.");
    } else if (!addOperator.userName) {
      setIsError("Please enter user name.");
    } else if (addOperator?.userName && !usernamePattern.test(addOperator.userName)) {
      setIsError("User name can only contain letters, numbers, and underscores.");
    }
    else if (!addOperator.email) {
      setIsError("Please enter email.");
    } else if (!validateEmail(addOperator.email)) {
      setIsError("Please enter a valid email address.");
    } else if (!isEdit && !validatePassword(addOperator.password || "")) {
      setIsError(
        "password should be atleast 8 characters long, 1 uppercase, 1 lowercase, 1 digits and 1 special character"
      );
    } else if (!isEdit && addOperator.password !== confirmPassword) {
      setIsError("Password & confirm password not matched.");
    }
    else if (!addOperator.phonenumber) {
      setIsError("Please enter phone number.");
    }
    else if (addOperator.phonenumber && addOperator.phonenumber.length < 10) {
      setIsError("Please enter a valid contact number.");
    }

    else if (!addOperator.adminType) {
      setIsError("Please select role.");
    } else if (
      !addOperator.module1read &&
      !addOperator.module1write &&
      !addOperator.module2read &&
      !addOperator.module2write &&
      !addOperator.module3read &&
      !addOperator.module3write &&
      !addOperator.module4read &&
      !addOperator.module4write
    ) {
      setIsError("Please check any permission.");
    } else {
      setIsLoader(true);
      const { operatorId, ...operatorData } = addOperator;

      const { res, err } = await httpRequest({
        method: isEdit ? "put" : "post",
        path: `/api/operator/${isEdit ? `update/${addOperator?.operatorId}` : "create"}`,
        params: operatorData
      });

      if (res) {
        setIsSucsModal(true);
        isEdit
          ? toast("Operator Updated Successfully.", { type: "success" })
          : toast("Operator Created Successfully.", { type: "success" });
        setBool(!bool);
        handleModalClose();
      } else {
        console.log(err, "error")
        toast(err?.message, { type: 'error' });
      }
      setIsLoader(false);
    }
  };

  const [temporary, setTempValue] = useState<string>("");
  const [totalPage, setTotalPage] = useState<number>(1);

  const [queryParams, setQueryParams] = useState<QueryParams>({
    adminType: "",
    name: "",
    page: 1,
    pageSize: 10,
    sort: "desc",
  }); // query params for APIs

  // Functionality: API call for get all operator
  const fetch = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/operator/all?${qs.stringify(queryParams)}` });
    if (res) {
      const count = res?.paginated?.totalItems || 0;
      console.log(count, "count");
      setTotalPage(res?.paginated?.totalPages || 1);
      setAllOperator(res?.documents);
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  const handleDebounce = (value: string) => {
    if (value !== " ") {
      setTempValue(value);
    }
  };

  // Effect to fetch operators on component mount
  useEffect(() => {
    fetch();
    setIsComponentLoad(true);
  }, [queryParams, bool]);

  useEffect(() => {
    if (isComponentLoad) {
      const debounceTimer = setTimeout(() => {
        setQueryParams({ ...queryParams, name: temporary, page: 1 });
      }, 1500);

      return () => clearTimeout(debounceTimer);
    }
  }, [temporary]);

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }, [isError]); // error clear in after 5 seconds.

  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          <AdminHeader />
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <h2 className="font-size-36px font-Poppins-Medium">Operators</h2>
              <p> <span className="font-size-16px font-Poppins-Bold">Dashboard /</span> <span className="font-Poppins-Medium"> Operators</span></p>
              <div className="Main-AdminUsers operator-main-admin mt-6 flex justify-between items-center">
                <div className="flex">
                  <input
                    placeholder="Name"
                    className="border-2 font-Poppins-Medium p-3 mr-6 font-size-15px"
                    type="search"
                    value={temporary}
                    onChange={(e) => handleDebounce(e.target.value)}
                  />
                  <div className="select-inner-text relative">
                    <span>Role</span>
                    <select
                      className="border-2 p-3 Main-AdminUsers font-Poppins-Medium mr-10 font-size-15px"

                      onChange={(e) =>
                        setQueryParams({
                          ...queryParams,
                          adminType: e.target.value,
                          page: 1,
                        })
                      }
                    >
                      <option value="">Select</option>
                      <option value="admin">Administrator</option>
                      <option value="editor">Editor</option>
                    </select>
                  </div>
                </div>
                <div className="flex justify-end items-center">
                  <button
                    onClick={openModal}
                    className="Admin-Daily-Search-btn-op  font-size-15px"
                  >
                    <span>+</span> Add operator
                  </button>
                </div>
              </div>

              <div className="mt-5">
                <div className="">
                  <ul
                    style={{ gridTemplateColumns: "20% 25% 15% 13% 16% 11%" }}
                    className="outer-detail-border Admin-status-content-Role font-Poppins-SemiBold font-size-18px main-Admin-status-action"
                  >
                    <li className="text-white">Name</li>
                    <li className="text-white">Email</li>
                    <li className="text-white">Role</li>
                    <li className="text-white">Created date</li>
                    <li className="text-white">Last login</li>
                    <li className="text-white">Action</li>
                  </ul>
                </div>
                {isLoader ? (
                  <div className="main-Admin-status-content mt-5 text-center">
                    <CircularProgress style={{ color: "#00443f" }} />
                  </div>
                ) : allOperator && allOperator.length > 0 ? (
                  allOperator.map((item, index) => (
                    <div key={index} className=" pt-5">
                      <ul
                        style={{
                          gridTemplateColumns: "20% 25% 15% 13% 16% 11%",
                        }}
                        className=" under-detail-border Admin-status-content-Role Admin-status-content-Role-2 main-Admin-status-content text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                      >
                        <li className="flex justify-center capitalize">
                          {item?.fullName}
                        </li>
                        <li>{item?.email || ""}</li>
                        <li
                          className="admin-role-class"

                        >
                          <span
                            className={`${item?.adminType == "administrator "
                              ? "Admin-Operators-green"
                              : "Admin-support-blue-editor"
                              } op-roles font-Poppins-SemiBold`}
                          >
                            {item?.adminType || ""}


                          </span>
                        </li>
                        <li>
                          {moment(item?.createdAt).format("DD-MM-YYYY") ||
                            "-"}
                        </li>
                        <li>
                          {moment(item?.lastLogin).isValid()
                            ? moment(item?.lastLogin).format("DD-MM-YYYY - hh:mm A")
                            : '-'}
                        </li>
                        <li className="relative font-size-30px">
                          <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={faEllipsisVertical}
                            onClick={() => handleDropDown(index)}
                          />
                          <div
                            className={`dropDownElipsis ${DropDown === index ? "DropDownActive" : ""
                              }`}
                          >
                            <div className="flex flex-col items-start">
                              <button
                                className="font-size-15px text-[#2F2F31] font-Poppins-Regular pb-2 aaa"
                                onClick={() => handleEdit(item)}
                              >
                                <FontAwesomeIcon icon={faPencil} /> Edit
                              </button>
                              {userId !== item?._id &&

                                (
                                  <button
                                    className="font-size-15px text-[#2F2F31] font-Poppins-Regular"
                                    onClick={() => handleDeleteModal(item?._id)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} /> Delete
                                  </button>
                                )
                              }

                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))
                ) : (
                  <div className="main-Admin-status-content mt-5">
                    <p className="text-center">No operator</p>
                  </div>
                )}
                <PaginationComponet
                  total={totalPage}
                  setCurrentPage={(e) =>
                    setQueryParams({ ...queryParams, page: e })
                  }
                  currentPage={queryParams.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* =============== ADD OPERATOR MODAL =========== */}
      {modalOpen ? (
        <div className="overlay">
          <div className="addStaffModal absolute z-20">
            <div className="relative">
              <button
                className="absolute paid-provider-add-operator"
                onClick={handleModalClose}
              >
                <FontAwesomeIcon
                  className="x-icon-admin-opr"
                  icon={faX}
                  style={{ color: "#ffffff" }}
                />

                {/* <FontAwesomeIcon icon={faTimesCircle} /> */}
              </button>
              <h2 className="font-size-36px text-center font-Poppins-Medium mb-5">
                {isEdit ? "Edit" : "Add"} operator
              </h2>
              <form onSubmit={handleSubmit}>
                <div className="border-underline">
                  <div className="flex admin-add-operator-mdl mb-5">
                    <div className="w-1/2 mr-2">
                      <div className="addOperator font-Poppins-Regular font-size-18px">
                        <label htmlFor="name">
                          First name  <span className="comp-steric">*</span>
                        </label>
                        <input
                          value={addOperator.firstName}
                          onChange={(e) =>
                            handleName("firstName", e.target.value)
                          }
                          type="text"
                          name="firstName"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="addOperator font-Poppins-Regular font-size-18px">
                        <label htmlFor="lastName">Last name <span className="comp-steric">*</span></label>
                        <input
                          value={addOperator.lastName}
                          onChange={(e) =>
                            handleName("lastName", e.target.value)
                          }
                          type="text"
                          name="lastName"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex admin-add-operator-mdl mb-5">
                    <div className="w-1/2 mr-2">
                      <div className="addOperator font-Poppins-Regular font-size-18px">
                        <label htmlFor="userName">
                          Username  <span className="comp-steric">*</span>
                        </label>
                        <input
                          value={addOperator.userName}
                          onChange={(e) =>
                            handleChanges("userName", e.target.value)
                          }
                          type="text"
                          name="userName"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="addOperator font-Poppins-Regular font-size-18px">
                        <label htmlFor="email">
                          Email    <span className="comp-steric">*</span>
                        </label>
                        <input
                          value={addOperator.email}
                          onChange={(e) =>
                            handleChanges("email", e.target.value)
                          }
                          type="email"
                          name="email"
                        />
                      </div>
                    </div>
                  </div>
                  {!isEdit && (
                    <div className="flex admin-add-operator-mdl mb-5">
                      <div className="w-1/2 mr-2">
                        <div className="addOperator font-Poppins-Regular font-size-18px">
                          <label htmlFor="passWord">Password <span className="comp-steric">*</span></label>
                          <div className="relative">
                            <input
                              value={addOperator.password}
                              type={showPassword ? "text" : "password"}
                              onChange={(e) =>
                                handleChanges("password", e.target.value)
                              }
                              name="password"
                              id="pas"
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                            </button>
                          </div>
                        </div>
                        <button>

                        </button>
                      </div>
                      <div className="w-1/2 ml-2">
                        <div className="addOperator font-Poppins-Regular font-size-18px">
                          <label htmlFor="confirmPassword">
                            Confirm password<span className="comp-steric">*</span>
                          </label>
                          <div className="relative">
                            <input
                              value={confirmPassword}
                              type={showPasswordSecond ? "text" : "password"}

                              onChange={(e) => setConfirmPassword(e.target.value)}
                              name="cPassword"
                              id="pas2"
                            />
                            <button
                              type="button"
                              className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                              onClick={() => setShowPasswordSecond(!showPasswordSecond)}
                            >
                              <FontAwesomeIcon icon={showPasswordSecond ? faEye : faEyeSlash} />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="flex admin-add-operator-mdl mb-5">
                    <div className="w-1/2 mr-2">
                      <div className="addOperator font-Poppins-Regular font-size-18px">
                        <label htmlFor="phone">Phone<span className="comp-steric">*</span></label>
                        <input
                          placeholder="07 1234 5678"
                          value={addOperator.phonenumber}
                          onChange={(e) => handlePhone(e.target.value)}
                          type="text"
                          name="phonenumber"
                          id="phone"
                        />
                      </div>
                    </div>
                    <div className="w-1/2 ml-2">
                      <div className="addOperator font-Poppins-Regular font-size-18px mb-4">
                        <label className="" htmlFor="role">
                          Role<span className="comp-steric">*</span>
                        </label>
                        <select
                          className="role-selection"
                          name="selectedOperatorType"
                          id="role"
                          value={addOperator.adminType}
                          onChange={(e) =>
                            setAddOperator({
                              ...addOperator,
                              adminType: e.target.value,
                            })
                          }
                        >
                          <option value="">Select</option>
                          <option value="admin">Administrator</option>
                          <option value="editor">Editor</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  {isError && <p style={{ color: "red" }}>{isError}</p>}
                </div>
                <div className="ModulePermision border-b-2 ">
                  <div className="modulePermisionGrid p-3 font-size-18px">
                    <h2 className="font-Poppins-SemiBold">Module permission</h2>
                    <h2 className="font-Poppins-SemiBold text-center">View</h2>
                    <h2 className="font-Poppins-SemiBold text-center">Edit</h2>
                  </div>
                  <div className="bd-tb">
                    <div className="bg-[#f2f2f2] modulePermisionGrid p-3 theme-bg-lightGrey module-responsive font-size-16px">
                      <h2>Dashboard</h2>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module1-read"
                          checked={addOperator.module1read}
                          onChange={() => handlePrivChange("module1read")}
                        />
                        <label htmlFor="module1-read"></label>
                      </div>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module1-write"
                          checked={addOperator.module1write}
                          onChange={() => handlePrivChange("module1write")}
                        />
                        <label htmlFor="module1-write"></label>
                      </div>
                    </div>

                    <div className="bg-[#ffffff] modulePermisionGrid p-3 theme-bg-lightGrey module-responsive font-size-16px">
                      <h2>Operators</h2>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module2-read"
                          checked={addOperator.module2read}
                          onChange={() => handlePrivChange("module2read")}
                        />
                        <label htmlFor="module2-read"></label>
                      </div>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module2-write"
                          checked={addOperator.module2write}
                          onChange={() => handlePrivChange("module2write")}
                        />
                        <label htmlFor="module2-write"></label>
                      </div>
                    </div>

                    <div className="bg-[#f2f2f2] modulePermisionGrid p-3 theme-bg-lightGrey module-responsive font-size-16px">
                      <h2>Users</h2>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module3-read"
                          checked={addOperator.module3read}
                          onChange={() => handlePrivChange("module3read")}
                        />
                        <label htmlFor="module3-read"></label>
                      </div>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module3-write"
                          checked={addOperator.module3write}
                          onChange={() => handlePrivChange("module3write")}
                        />
                        <label htmlFor="module3-write"></label>
                      </div>
                    </div>

                    <div className="bg-[#ffffff] modulePermisionGrid p-3 theme-bg-lightGrey module-responsive font-size-16px">
                      <h2>Job requests</h2>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module4-read"
                          checked={addOperator.module4read}
                          onChange={() => handlePrivChange("module4read")}
                        />
                        <label htmlFor="module4-read"></label>
                      </div>
                      <div className="form-group-tick">
                        <input
                          type="checkbox"
                          id="module4-write"
                          checked={addOperator.module4write}
                          onChange={() => handlePrivChange("module4write")}
                        />
                        <label htmlFor="module4-write"></label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="sndInvite text-center">
                  <button
                    type="submit"
                    role="button"
                    className="border-2 mt-6 px-12 py-2 text-white font-Poppins-Medium paid-provider-submit-btn font-size-17px"
                    disabled={isLoader}
                  >
                    Submit
                    {isLoader && (
                      <CircularProgress
                        style={{ color: "#fff" }}
                        className="btn__loader"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* =============== ADD OPERATOR MODAL =========== */}

      {/* Success Modal for showing success messages */}
      {/* {isSucsModal && (
        <SuccessModal
          message={successMsg}
          setIsModal={setIsSucsModal}
          setMsg={setSuccessMsg}
        />
      )} */}

      {/* Delete Modal for confirming deletion of operators */}
      {deleteModal && (
        <DeleteModal
          handleClose={setDeleteModal}
          setDeleteId={setDeleteId}
          deleteFunction={handleConfirmDelete}
          loader={isLoader}
          deleteModalClose={() => setDeleteModal(false)} // Ensure you pass this property

        />
      )}
    </div>
  );
}

export default AdminOperators;
