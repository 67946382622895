// import React, { useEffect, useState, useRef } from "react";
// import interact from '@interactjs/interactjs';
// import restrict from '@interactjs/modifiers/restrict';
// import restrictSize from '@interactjs/modifiers/restrictSize';
// // import interact from 'interactjs';


// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faCircleXmark,
//   faCopy,
//   faThumbsDown,
//   faThumbsUp,
// } from "@fortawesome/free-solid-svg-icons";
// import Adminheaderask from "../../assets/img/Admin-Header-ask-btn.svg";
// import { toast } from "react-toastify";
// import { getCookie, validateText } from "../../config/Helpers/helpers";
// import axios from "axios";
// import { ThreeDots } from "react-loader-spinner";
// import ndisailogoai from "../../assets/img/ndisync-black-logo.png";

// import ndisbtn from "../../assets/img/ndisbtn.svg";
// import { CircularProgress } from "@mui/material";
// import { Link } from "react-router-dom";
// interface ApiResponse {
//   messages?: ChatHistoryItem[]; // Assuming messages is an array of ChatHistoryItem
//   answer: string;
// }
// interface ResizableDivProps {
//   handleClose: () => void;
// }

// interface ChatHistoryItem {
//   user_response?: string;
//   ai_response?: string;
//   feedback_type?: string;
//   id?: string;
// }
// const ResizableDiv: React.FC<ResizableDivProps> = ({ handleClose }) => {
//   const token = getCookie();
//   const [chatAi, setChatAi] = useState<boolean>(false);

//   const contentRef = useRef<HTMLDivElement>(null);
//   const userChatRef = useRef<HTMLDivElement>(null);
//   const outSideRef = useRef<HTMLDivElement>(null);

//   const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);

//   useEffect(() => {
//     function handleClickOutside(event: MouseEvent) {
//       if (outSideRef.current && !outSideRef.current.contains(event.target as Node)) {
//         handleClose();
//       }
//     }

//     document.addEventListener("mousedown", handleClickOutside);
//     return () => {
//       document.removeEventListener("mousedown", handleClickOutside);
//     };
//   }, [outSideRef]);

//   useEffect(() => {
//     const windowWidth = window.innerWidth;

//     const initialWidth = 450;
//     const initialHeight = 730;
//     const minWidth = 450;
//     const minHeight = 600;

//     const maxHeight = 800;
//     const maxWidth = 800;
//     const element = document.querySelector(".resize-drag");

//     // TEMP
//     const applyStyles = () => {
//       const element = document.querySelector(".resize-drag") as HTMLDivElement | null;

//       if (element) { // Add null check here
//         if (windowWidth <= 1920) {
//           element.style.width = "450px";
//           element.style.height = "830px";
//           element.style.right = "50px";
//           element.style.top = "50px";

//           if (windowWidth <= 1600) {
//             element.style.width = "400px";
//             element.style.height = "600px";
//             element.style.right = "50px";
//             element.style.top = "20px";
//           }
//           if (windowWidth <= 1440) {
//             element.style.width = "365px";
//             element.style.height = "550px";
//             element.style.right = "50px";
//             element.style.top = "20px";
//           }
//         }
//       }
//     };
//     applyStyles();
//     const handleResize = () => {
//       setWindowWidth(window.innerWidth);
//     };
//     window.addEventListener("resize", handleResize);

//     // TEMP

//     // element.style.width = `${initialWidth}px`;
//     // element.style.height = `${initialHeight}px`;
//     // element.style.right = "50px";
//     // element.style.top = "100px";

//     // if (element) {
//     //   element.style.width = `${initialWidth}px`;
//     //   element.style.height = `${initialHeight}px`;
//     //   element.style.right = "50px";
//     //   element.style.top = "100px";
//     // }
//     // Apply styles when the component mounts

//     const resizeDraggable = interact(".resize-drag")
//       .draggable({
//         modifiers: [
//           interact.modifiers.restrict({
//             restriction: "parent",
//             elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
//             endOnly: true,
//           }),
//         ],
//         onmove: dragMoveListener,
//       })
//       .resizable({
//         preserveAspectRatio: false,
//         edges: { left: true, right: true, bottom: true, top: true },
//         modifiers: [
//           interact.modifiers.restrictSize({
//             min: { width: minWidth, height: minHeight },
//             max: { width: maxWidth, height: maxHeight },
//           }),
//         ],
//       })
//       .on("resizemove", function (event) {
//         const target = event.target;
//         target.style.width = event.rect.width + "px";
//         target.style.height = event.rect.height + "px";
//       });

//     function dragMoveListener(event: any) {
//       const target = event.target;
//       target.style.webkitTransform = target.style.transform = `translate(${(parseFloat(target.getAttribute("data-x")) || 0) + event.dx
//         }px, ${(parseFloat(target.getAttribute("data-y")) || 0) + event.dy}px)`;

//       target.setAttribute(
//         "data-x",
//         (parseFloat(target.getAttribute("data-x")) || 0) + event.dx
//       );
//       target.setAttribute(
//         "data-y",
//         (parseFloat(target.getAttribute("data-y")) || 0) + event.dy
//       );
//     }

//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [windowWidth]);

//   // 05-03-34
//   const [_prompt, setPromt] = useState("");
//   const [chatLoader, setChatLoader] = useState(false);
//   const [userChatHistory, setUserChatHistory] = useState<ChatHistoryItem[]>([]);
//   const [category, setCategory] = useState("");
//   const [historyLoader, setHistoryLoader] = useState(false);
//   const [showFeedBack, setShowFeedBack] = useState(false);
//   const [isSuccessFeedBack, setIsSuccessFeedBack] = useState(false);
//   const [showOthersOptions, setShowOthersOptions] = useState(false);
//   const [otherFeedback, setOtherFeedBack] = useState("");

//   const feedsBackArray = [
//     "Not factually correct",
//     "Don't like the style",
//     "Shouldn't have used memory",
//     "Didn't follow instructions",
//     "Refused when it shouldn't have",
//     "Being lazy",
//     "Unsafe or problematic",
//   ];

//   let config = {
//     headers: {
//       Authorization: `Bearer ${token}`,
//       "Content-Type": "application/json",
//     },
//   };

//   const changeCategory = async (e: string) => {
//     setCategory(e);
//     setChatAi(true);
//     setHistoryLoader(true);
//     try {
//       const chatHistory = await axios.get<ApiResponse>(
//         `${process.env.REACT_APP_CHATBOT
//         }/api/${e}?page_number=${1}&limit=${10}`,
//         config
//       );
//       console.log("chatHistory", chatHistory.data);
//       setUserChatHistory(chatHistory?.data?.messages || []);
//     } catch (err: any) {
//       toast(
//         err.response?.data?.message ||
//         err.response?.data?.error ||
//         "Something went wrong.",
//         { type: "error" }
//       );
//     }
//     setHistoryLoader(false);
//   };

//   const preDefineQuestion = [
//     { question: "How can I register on ndisync.ai?" },
//     { question: "What services does ndisync.ai offer?" },
//     { question: "How do I post a job on ndisync.ai?" },
//   ];

//   const handleAiChatOpen = (item: { question: string }) => {
//     setChatAi(true);
//     fetch(item?.question);
//   };

//   const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
//     e.preventDefault();
//     if (_prompt) {
//       fetch(_prompt);
//     } else {
//       // toast("Enter", { type: "error" });
//     }
//   };

//   const scrollBottom = () => {
//     if (userChatRef.current) {
//       userChatRef.current.scrollTop = userChatRef.current.scrollHeight + 30;
//     }
//   };

//   const handleCopy = (text: string) => {
//     // for AI response copy
//     try {
//       const textarea = document.createElement("textarea");
//       textarea.value = text;
//       document.body.appendChild(textarea);
//       textarea.select();
//       document.execCommand("copy");
//       document.body.removeChild(textarea);
//       toast("Copied to clipboard.", { type: "success" });
//     } catch (err) {
//       toast("Error copying text: ", { type: "error" });
//     }
//   };

//   // Functionality: API call for AI response feed back
//   const sendFeedBack = async (text: string, message_id?: string) => {
//     try {
//       const response = await axios.post<ApiResponse>(
//         `${process.env.REACT_APP_CHATBOT}/api/submit_feedback/${category}/`,
//         {
//           message_id,
//           feedback: text,
//           feedback_type: "thumbsdown",
//         },
//         config
//       );
//       if (response.status == 200) {
//         let newList = userChatHistory.map((newObject) => {
//           if (newObject?.id === message_id) {
//             return {
//               ...newObject,
//               feedback_type: "thumbsdown",
//             };
//           }
//           return newObject;
//         });

//         setUserChatHistory(newList);
//         setOtherFeedBack("");
//         setShowOthersOptions(false);
//         setIsSuccessFeedBack(true);
//         setTimeout(() => {
//           setShowFeedBack(false);
//           setIsSuccessFeedBack(false);
//         }, 3000);
//       }
//       console.log("ttttt", response.data);
//     } catch (err: any) {
//       console.log("ERROR", err);
//       toast(
//         err.response?.data?.message ||
//         err.response?.data?.error ||
//         "Something went wrong.",
//         { type: "error" }
//       );
//     }
//   };

//   // Functionality: ask question chatbot
//   const fetch = async (text: string) => {
//     setChatLoader(true);
//     setChatAi(true);
//     setPromt("");
//     const updatedUserChat = [...userChatHistory, { user_response: text }];
//     setUserChatHistory(updatedUserChat);
//     scrollBottom();
//     try {
//       const chatResponse = await axios.post<ApiResponse>(
//         `${process.env.REACT_APP_CHATBOT}/api/${category}/`,
//         { query: text },
//         config
//       );
//       console.log("ttttt", chatResponse.data);
//       setUserChatHistory([
//         ...updatedUserChat,
//         { ai_response: chatResponse.data?.answer },
//       ]);
//       scrollBottom();
//     } catch (err: any) {
//       console.log("ERROR", err);
//       toast(
//         err.response?.data?.message ||
//         err.response?.data?.error ||
//         "Something went wrong.",
//         { type: "error" }
//       );
//     }
//     setChatLoader(false);
//   };

//   useEffect(() => {
//     scrollBottom();
//   }, [chatAi, userChatHistory]);

//   return (
//     <>
//       <div className="blurry-bg">
//         <div ref={outSideRef} className="resize-drag">
//           <div
//             ref={contentRef}
//             className="move-modal-body  flex-col justify-between"
//             style={{ overflowY: "auto", maxHeight: "90%" }}
//           >
//             <div
//               ref={contentRef}
//               className="flex justify-between items-center askndis-head"
//             >
//               <div className="flex gap-2 items-center">
//                 <img src={Adminheaderask} alt="" />{" "}
//                 <h3 className=" font-size-20px font-Poppins-SemiBold text-[#ffe175]">
//                   ASK AI
//                 </h3>
//               </div>
//               <div
//                 className="w-fit"
//                 style={{ zIndex: 999999, cursor: "pointer" }}
//                 onClick={handleClose}
//               >
//                 <FontAwesomeIcon icon={faCircleXmark} />
//               </div>
//             </div>
//             {!chatAi ? (
//               <>
//                 <div className="main-ai-detail-handler">
//                   <img
//                     className="mx-auto mt-2 mb-2 green-wand"
//                     src="https://edgie.backslashwebs.com/imgs/green-wand.png"
//                     alt=""
//                   />
//                   <div className="flex justify-center flex-col text-center">
//                     <span className="font-size-25px font-Poppins-SemiBold text-[#00443f]">
//                       Get answers or create solutions with AI
//                     </span>
//                     <p className="font-size-18px font-Poppins-Regular text-[#454545] askai-detail mx-auto">
//                       Get instant answers and assistance with ndisync.ai or ask
//                       NDIS-related questions.
//                     </p>
//                   </div>
//                   <div className="mt-3 flex flex-col justify-between pop-qa">
//                     <div>
//                       <span className="font-size-25px font-Poppins-SemiBold text-[#00443f] ">
//                         Popular questions{" "}
//                       </span>
//                       {preDefineQuestion.map((item, index) => (
//                         <div
//                           key={index}
//                           // onClick={() => handleAiChatOpen(item)}
//                           className="flex gap-2 items-center light-bulb mt-3 mb-2 ai-detail-head"
//                         >
//                           <img src="https://edgie.backslashwebs.com/imgs/lightbuld.png" />{" "}
//                           <span className="font-size-16px font-Poppins-Regular">
//                             {item?.question}
//                           </span>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                   <div className="nd-ai-button mt-3 ">
//                     <p className="font-size-25px font-Poppins-SemiBold text-[#00443f] text-center">
//                       What type of data would you like to search?
//                     </p>
//                     <div className="flex justify-center gap-3 mt-2">
//                       <button onClick={() => changeCategory("qna_ndisync_api")}>
//                         <img src={ndisailogoai} alt="" />
//                       </button>
//                       <button onClick={() => changeCategory("qna_ndis_api")}>
//                         <img src={ndisbtn} alt="" />
//                       </button>
//                     </div>
//                   </div>
//                 </div>
//               </>
//             ) : null}
//             <div className="input-box-main-ai">
//               <form onSubmit={handleSubmit}>
//                 <input
//                   placeholder="Ask, Write or Search Anything..."
//                   className=" font-size-15px  font-Poppins-Regular askai-search"
//                   type="text"
//                   value={_prompt}
//                   onChange={(e) => setPromt(e.target.value)}
//                 />
//                 <button type="submit" className="sendai-btn">
//                   <img
//                     className="inbox-icon"
//                     src="https://edgie.backslashwebs.com/imgs/inbox-icon.png"
//                   />{" "}
//                 </button>
//               </form>
//             </div>
//           </div>

//           {chatAi && (
//             <div
//               ref={userChatRef}
//               style={{ overflowY: "auto", height: "90%" }}
//               className="user-ai-chat-main"
//             >
//               {historyLoader && (
//                 <div className="text-center">
//                   <CircularProgress
//                     style={{ color: "#00776d", width: 20, height: 20 }}
//                   />
//                 </div>
//               )}
//               <div className="flex justify-center">
//                 {category === "qna_ndisync_api" ? (
//                   <img src={ndisailogoai} alt="" />
//                 ) : (
//                   <img src={ndisbtn} alt="" />
//                 )}
//               </div>
//               {userChatHistory && userChatHistory.length > 0
//                 ? userChatHistory.map((item, index) => (
//                   <div key={index}>
//                     {item?.user_response && (
//                       <div className="flex justify-end mb-3">
//                         <span className="text-[#888888] user-chat-left font-size-15px  font-Poppins-Regular">
//                           {item?.user_response || ""}
//                         </span>
//                       </div>
//                     )}
//                     {item?.ai_response && (
//                       <div className="flex justify-start mb-3 flex-col">
//                         <span className="text-[#464646] ai-chat-right w-[90%]  font-size-15px  font-Poppins-Regular">
//                           {item?.ai_response || ""}
//                         </span>
//                         <div className="flex gap-2 mt-1">
//                           <button
//                             onClick={() => {
//                               setShowOthersOptions(false);
//                               setShowFeedBack(item?.id !== undefined);
//                             }}
//                           >
//                             <FontAwesomeIcon
//                               icon={faThumbsDown}
//                               color={
//                                 item?.feedback_type === "thumbsdown"
//                                   ? "#2196f3"
//                                   : "#D1D1D1"
//                               }
//                             />
//                           </button>
//                           <button
//                             onClick={() => handleCopy(item?.ai_response || '')}
//                           >
//                             {" "}
//                             <FontAwesomeIcon icon={faCopy} color="#D1D1D1" />
//                           </button>
//                         </div>
//                         {showFeedBack && item?.id && (
//                           <>
//                             <div className="mt-4 mb-4 border-[1px] border-[#D1D1D1] rounded-md p-2">
//                               <h5 className=" font-Poppins-Medium 	font-size-13px pb-2">
//                                 Tell us more:
//                               </h5>
//                               {showOthersOptions ? (
//                                 <>
//                                   <input
//                                     type="text"
//                                     className="feedback-input border-[1px] border-[#D1D1D1] rounded-md  font-Poppins-Medium p-1 	font-size-13px mt-3"
//                                     placeholder="(Optional) Feel free to add specific details"
//                                     style={{ color: "#C8C8C8" }}
//                                     value={otherFeedback}
//                                     onChange={(e) =>
//                                       setOtherFeedBack(e.target.value)
//                                     }
//                                   />
//                                   <button
//                                     onClick={() =>
//                                       sendFeedBack(otherFeedback, item?.id)
//                                     }
//                                     style={{ color: "white" }}
//                                     className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn mt-4"
//                                   >
//                                     Submit
//                                   </button>
//                                 </>
//                               ) : (
//                                 <div className="flex gap-2 flex-wrap">
//                                   {feedsBackArray.map((v) => (
//                                     <button
//                                       key={v}
//                                       className="border-[1px] border-[#D1D1D1] rounded-md flex justify-center	font-size-13px  font-Poppins-Medium p-1 cusror-pointer"
//                                       style={{ color: "#C8C8C8" }}
//                                       onClick={() =>
//                                         sendFeedBack(v, item?.id)
//                                       }
//                                     >
//                                       {v}
//                                     </button>
//                                   ))}
//                                   <button
//                                     className="border-[1px] border-[#D1D1D1] rounded-md flex justify-center	font-size-13px  font-Poppins-Medium p-1"
//                                     style={{ color: "#C8C8C8" }}
//                                     onClick={() => setShowOthersOptions(true)}
//                                   >
//                                     Other
//                                   </button>
//                                 </div>
//                               )}
//                             </div>
//                             {isSuccessFeedBack && (
//                               <div className="w-[90%] border-[1px] border-[#D1D1D1] rounded-md flex justify-center	  mt-3 py-2 ">
//                                 <p className="font-size-13px theme-grey-type font-Poppins-Medium flex gap-1 text-center">
//                                   <FontAwesomeIcon
//                                     icon={faThumbsUp}
//                                     className="theme-grey-type "
//                                   />
//                                   Thank you for your feedback
//                                 </p>
//                               </div>
//                             )}
//                           </>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 ))
//                 : []}

//               {chatLoader && (
//                 <div className="flex justify-start mb-3 flex-col">
//                   <span
//                     className="text-[#464646] ai-chat-right w-[90%]  font-size-15px  font-Poppins-Regular"
//                     style={{ paddingTop: 12, paddingBottom: 12 }}
//                   >
//                     <ThreeDots
//                       visible={true}
//                       height={10}
//                       width={100}
//                       color="#000"
//                     />
//                   </span>
//                 </div>
//               )}
//             </div>
//           )}
//         </div>
//       </div>
//     </>
//   );
// };

// export default ResizableDiv;
import React, { useEffect, useState, useRef } from "react";
import interact from "interactjs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleXmark,
  faCopy,
  faThumbsDown,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons";
import Adminheaderask from "../../assets/img/Admin-Header-ask-btn.svg";
import { toast } from "react-toastify";
import { getCookie, validateText } from "../../config/Helpers/helpers";
import axios from "axios";
import { ThreeDots } from "react-loader-spinner";
import ndisailogoai from "../../assets/img/ndisync-black-logo.png";

import ndisbtn from "../../assets/img/ndisbtn.svg";
import { CircularProgress } from "@mui/material";
import { Link } from "react-router-dom";
const ResizableDiv = ({ handleClose }) => {
  const token = getCookie();
  const [chatAi, setChatAi] = useState(false);

  const contentRef = useRef(null);
  const userChatRef = useRef(null);
  const outSideRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleClickOutside(event) {
      if (outSideRef.current && !outSideRef.current.contains(event.target)) {
        handleClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outSideRef]);

  useEffect(() => {
    const windowWidth = window.innerWidth;

    const initialWidth = 450;
    const initialHeight = 730;
    const minWidth = 450;
    const minHeight = 600;

    const maxHeight = 800;
    const maxWidth = 800;
    const element = document.querySelector(".resize-drag");

    // TEMP
    const applyStyles = () => {
      if (windowWidth <= 1920) {
        element.style.width = "450px";
        element.style.height = "830px";
        element.style.right = "50px";
        element.style.top = "50px";
        if (windowWidth <= 1600) {
          element.style.width = "400px";
          element.style.height = "600px";
          element.style.right = "50px";
          element.style.top = "20px";
        }
        if (windowWidth <= 1440) {
          element.style.width = "365px";
          element.style.height = "550px";
          element.style.right = "50px";
          element.style.top = "20px";
        }
      } else {
      }
    };
    applyStyles();
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // TEMP

    // element.style.width = `${initialWidth}px`;
    // element.style.height = `${initialHeight}px`;
    // element.style.right = "50px";
    // element.style.top = "100px";

    // Apply styles when the component mounts

    const resizeDraggable = interact(".resize-drag")
      .draggable({
        modifiers: [
          interact.modifiers.restrict({
            restriction: "parent",
            elementRect: { top: 0, left: 0, bottom: 1, right: 1 },
            endOnly: true,
          }),
        ],
        onmove: dragMoveListener,
      })
      .resizable({
        preserveAspectRatio: false,
        edges: { left: true, right: true, bottom: true, top: true },
        modifiers: [
          interact.modifiers.restrictSize({
            min: { width: minWidth, height: minHeight },
            max: { width: maxWidth, height: maxHeight },
          }),
        ],
      })
      .on("resizemove", function (event) {
        const target = event.target;
        target.style.width = event.rect.width + "px";
        target.style.height = event.rect.height + "px";
      });

    function dragMoveListener(event) {
      const target = event.target;
      target.style.webkitTransform = target.style.transform = `translate(${(parseFloat(target.getAttribute("data-x")) || 0) + event.dx
        }px, ${(parseFloat(target.getAttribute("data-y")) || 0) + event.dy}px)`;

      target.setAttribute(
        "data-x",
        (parseFloat(target.getAttribute("data-x")) || 0) + event.dx
      );
      target.setAttribute(
        "data-y",
        (parseFloat(target.getAttribute("data-y")) || 0) + event.dy
      );
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [windowWidth]);

  // 05-03-34
  const [_prompt, setPromt] = useState("");
  const [chatLoader, setChatLoader] = useState(false);
  const [userChatHistory, setUserChatHistory] = useState([]);
  const [category, setCategory] = useState("");
  const [historyLoader, setHistoryLoader] = useState(false);
  const [showFeedBack, setShowFeedBack] = useState(false);
  const [isSuccessFeedBack, setIsSuccessFeedBack] = useState(false);
  const [showOthersOptions, setShowOthersOptions] = useState(false);
  const [otherFeedback, setOtherFeedBack] = useState("");

  const feedsBackArray = [
    "Not factually correct",
    "Don't like the style",
    "Shouldn't have used memory",
    "Didn't follow instructions",
    "Refused when it shouldn't have",
    "Being lazy",
    "Unsafe or problematic",
  ];

  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
  };
console.log(token,"tokentokentoken")
  const changeCategory = async (e) => {
    setCategory(e);
    setChatAi(true);
    setHistoryLoader(true);
    try {
      const chatHistory = await axios.get(
        `${process.env.REACT_APP_CHATBOT
        }/api/${e}?page_number=${1}&limit=${10}`,
        config
     

      );
      console.log("chatHistory", chatHistory.data);
      setUserChatHistory(chatHistory.data?.messages);
    } catch (err) {
      toast(
        err.response?.data?.message ||
        err.response?.data?.error ||
        "Something went wrong.",
        { type: "error" }
      );
    }
    setHistoryLoader(false);
  };

  const preDefineQuestion = [
    { question: "How can I register on ndisync.ai?" },
    { question: "What services does ndisync.ai offer?" },
    { question: "How do I post a job on ndisync.ai?" },
  ];

  const handleAiChatOpen = (item) => {
    setChatAi(true);
    fetch(item?.question);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (_prompt) {
      fetch(_prompt);
    } else {
      // toast("Enter", { type: "error" });
    }
  };

  const scrollBottom = () => {
    if (userChatRef.current) {
      userChatRef.current.scrollTop = userChatRef.current.scrollHeight + 30;
    }
  };

  const handleCopy = (text) => {
    // for AI response copy
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast("Copied to clipboard.", { type: "success" });
    } catch (err) {
      toast("Error copying text: ", { type: "error" });
    }
  };

  // Functionality: API call for AI response feed back
  const sendFeedBack = async (text, message_id) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CHATBOT}/api/submit_feedback/${category}/`,
        {
          message_id,
          feedback: text,
          feedback_type: "thumbsdown",
        },
        config
      );
      if (response.status == 200) {
        let newList = userChatHistory.map((newObject) => {
          if (newObject?.id === message_id) {
            return {
              ...newObject,
              feedback_type: "thumbsdown",
            };
          }
          return newObject;
        });

        setUserChatHistory(newList);
        setOtherFeedBack("");
        setShowOthersOptions(false);
        setIsSuccessFeedBack(true);
        setTimeout(() => {
          setShowFeedBack(false);
          setIsSuccessFeedBack(false);
        }, 3000);
      }
      console.log("ttttt", response.data);
    } catch (err) {
      console.log("ERROR", err);
      toast(
        err.response?.data?.message ||
        err.response?.data?.error ||
        "Something went wrong.",
        { type: "error" }
      );
    }
  };

  // Functionality: ask question chatbot
  const fetch = async (text) => {
    setChatLoader(true);
    setChatAi(true);
    setPromt("");
    const updatedUserChat = [...userChatHistory, { user_response: text }];
    setUserChatHistory(updatedUserChat);
    scrollBottom();
    try {
      const chatResponse = await axios.post(
        `${process.env.REACT_APP_CHATBOT}/api/${category}/`,
        { query: text },
        config
      );
      console.log("ttttt", chatResponse.data);
      setUserChatHistory([
        ...updatedUserChat,
        { ai_response: chatResponse.data?.answer },
      ]);
      scrollBottom();
    } catch (err) {
      console.log("ERROR", err);
      toast(
        err.response?.data?.message ||
        err.response?.data?.error ||
        "Something went wrong.",
        { type: "error" }
      );
    }
    setChatLoader(false);
  };

  useEffect(() => {
    scrollBottom();
  }, [chatAi, userChatHistory]);

  return (
    <>
      <div className="blurry-bg">
        <div ref={outSideRef} className="resize-drag">
          <div
            ref={contentRef}
            className="move-modal-body  flex-col justify-between"
            style={{ overflowY: "auto", maxHeight: "90%" }}
          >
            <div
              ref={contentRef}
              className="flex justify-between items-center askndis-head"
            >
              <div className="flex gap-2 items-center">
                <img src={Adminheaderask} alt="" />{" "}
                <h3 className=" font-size-20px font-Poppins-SemiBold text-[#ffe175]">
                  ASK AI
                </h3>
              </div>
              <div
                className="w-fit"
                style={{ zIndex: 999999, cursor: "pointer" }}
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
              </div>
            </div>
            {!chatAi ? (
              <>
                <div className="main-ai-detail-handler">
                  <img
                    className="mx-auto mt-2 mb-2 green-wand"
                    src="https://edgie.backslashwebs.com/imgs/green-wand.png"
                    alt=""
                  />
                  <div className="flex justify-center flex-col text-center">
                    <span className="font-size-25px font-Poppins-SemiBold text-[#00443f]">
                      Get answers or create solutions with AI
                    </span>
                    <p className="font-size-18px font-Poppins-Regular text-[#454545] askai-detail mx-auto">
                      Get instant answers and assistance with ndisync.ai or ask
                      NDIS-related questions.
                    </p>
                  </div>
                  <div className="mt-3 flex flex-col justify-between pop-qa">
                    <div>
                      <span className="font-size-25px font-Poppins-SemiBold text-[#00443f] ">
                        Popular questions{" "}
                      </span>
                      {preDefineQuestion.map((item, index) => (
                        <div
                          key={index}
                          // onClick={() => handleAiChatOpen(item)}
                          className="flex gap-2 items-center light-bulb mt-3 mb-2 ai-detail-head"
                        >
                          <img src="https://edgie.backslashwebs.com/imgs/lightbuld.png" />{" "}
                          <span className="font-size-16px font-Poppins-Regular">
                            {item?.question}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="nd-ai-button mt-3 ">
                    <p className="font-size-25px font-Poppins-SemiBold text-[#00443f] text-center">
                      What type of data would you like to search?
                    </p>
                    <div className="flex justify-center gap-3 mt-2">
                      <button onClick={() => changeCategory("qna_ndisync_api")}>
                        <img src={ndisailogoai} alt="" />
                      </button>
                      <button onClick={() => changeCategory("qna_ndis_api")}>
                        <img src={ndisbtn} alt="" />
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : null}
            <div className="input-box-main-ai">
              <form onSubmit={handleSubmit}>
                <input
                  placeholder="Ask, Write or Search Anything..."
                  className=" font-size-15px  font-Poppins-Regular askai-search"
                  type="text"
                  value={_prompt}
                  onChange={(e) => setPromt(e.target.value)}
                />
                <button type="submit" className="sendai-btn">
                  <img
                    className="inbox-icon"
                    src="https://edgie.backslashwebs.com/imgs/inbox-icon.png"
                  />{" "}
                </button>
              </form>
            </div>
          </div>

          {chatAi && (
            <div
              ref={userChatRef}
              style={{ overflowY: "auto", height: "90%" }}
              className="user-ai-chat-main"
            >
              {historyLoader && (
                <div className="text-center">
                  <CircularProgress
                    style={{ color: "#00776d", width: 20, height: 20 }}
                  />
                </div>
              )}
              <div className="flex justify-center">
                {category === "qna_ndisync_api" ? (
                  <img src={ndisailogoai} alt="" />
                ) : (
                  <img src={ndisbtn} alt="" />
                )}
              </div>
              {userChatHistory && userChatHistory.length > 0
                ? userChatHistory.map((item, index) => (
                  <div key={index}>
                    {item?.user_response && (
                      <div className="flex justify-end mb-3">
                        <span className="text-[#888888] user-chat-left font-size-15px  font-Poppins-Regular">
                          {item?.user_response || ""}
                        </span>
                      </div>
                    )}
                    {item?.ai_response && (
                      <div className="flex justify-start mb-3 flex-col">
                        <span className="text-[#464646] ai-chat-right w-[90%]  font-size-15px  font-Poppins-Regular">
                          {item?.ai_response || ""}
                        </span>
                        <div className="flex gap-2 mt-1">
                          <button
                            onClick={() => {
                              setShowOthersOptions(false);
                              setShowFeedBack(item?.id);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faThumbsDown}
                              color={
                                item?.feedback_type === "thumbsdown"
                                  ? "#2196f3"
                                  : "#D1D1D1"
                              }
                            />
                          </button>
                          <button
                            onClick={() => handleCopy(item?.ai_response)}
                          >
                            {" "}
                            <FontAwesomeIcon icon={faCopy} color="#D1D1D1" />
                          </button>
                        </div>
                        {showFeedBack === item?.id && (
                          <>
                            <div className="mt-4 mb-4 border-[1px] border-[#D1D1D1] rounded-md p-2">
                              <h5 className=" font-Poppins-Medium 	font-size-13px pb-2">
                                Tell us more:
                              </h5>
                              {showOthersOptions ? (
                                <>
                                  <input
                                    type="text"
                                    className="feedback-input border-[1px] border-[#D1D1D1] rounded-md  font-Poppins-Medium p-1 	font-size-13px mt-3"
                                    placeholder="(Optional) Feel free to add specific details"
                                    style={{ color: "#C8C8C8" }}
                                    value={otherFeedback}
                                    onChange={(e) =>
                                      setOtherFeedBack(e.target.value)
                                    }
                                  />
                                  <button
                                    onClick={() =>
                                      sendFeedBack(otherFeedback, item?.id)
                                    }
                                    style={{ color: "white" }}
                                    className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn mt-4"
                                  >
                                    Submit
                                  </button>
                                </>
                              ) : (
                                <div className="flex gap-2 flex-wrap">
                                  {feedsBackArray.map((v) => (
                                    <button
                                      key={v}
                                      className="border-[1px] border-[#D1D1D1] rounded-md flex justify-center	font-size-13px  font-Poppins-Medium p-1 cusror-pointer"
                                      style={{ color: "#C8C8C8" }}
                                      onClick={() =>
                                        sendFeedBack(v, item?.id)
                                      }
                                    >
                                      {v}
                                    </button>
                                  ))}
                                  <button
                                    className="border-[1px] border-[#D1D1D1] rounded-md flex justify-center	font-size-13px  font-Poppins-Medium p-1"
                                    style={{ color: "#C8C8C8" }}
                                    onClick={() => setShowOthersOptions(true)}
                                  >
                                    Other
                                  </button>
                                </div>
                              )}
                            </div>
                            {isSuccessFeedBack && (
                              <div className="w-[90%] border-[1px] border-[#D1D1D1] rounded-md flex justify-center	  mt-3 py-2 ">
                                <p className="font-size-13px theme-grey-type font-Poppins-Medium flex gap-1 text-center">
                                  <FontAwesomeIcon
                                    icon={faThumbsUp}
                                    className="theme-grey-type "
                                  />
                                  Thank you for your feedback
                                </p>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))
                : []}

              {chatLoader && (
                <div className="flex justify-start mb-3 flex-col">
                  <span
                    className="text-[#464646] ai-chat-right w-[90%]  font-size-15px  font-Poppins-Regular"
                    style={{ paddingTop: 12, paddingBottom: 12 }}
                  >
                    <ThreeDots
                      visible={true}
                      height={10}
                      width={100}
                      color="#000"
                    />
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ResizableDiv;
