import React, { useState } from 'react';
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { Link } from 'react-router-dom';
import { postTimeDifference, truncateString } from '../../config/Helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faArrowRight, faX } from '@fortawesome/free-solid-svg-icons';
import ModalIcon from "../../assets/img/modal-icon.svg"
import ModalMap from "../../assets/img/modal-map.svg"
import Checkboxicon from "../../assets/img/checkboxicon.svg"
import { getRole } from '../../config/Helpers/helpers';
import httpRequest from '../../config/Helpers/httpRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import Thankyou from "../../assets/img/thankyou-icon.svg"
function FeedbackModal({ onClose, error }) {
    const decoded = getRole();
    const userEmail=decoded?.email;
    // const { userEmail } = getRole()
    const [stars, setStars] = useState(0)
    const [message, setMessage] = useState("")
    const [isLoader, setIsLoader] = useState(false)
    const [thanks, setThanks] = useState(false)
    const [feedbacks, setFeedbacks] = useState(true)
    const handleStarClick = (value) => {
        setStars(value);
    };
    const handleSubmit = async (e) => {

        e.preventDefault()
        if (!stars) {
            toast("Please select stars", { type: "error" })
            return
        }
        if (!message) {
            toast("Please enter message", { type: "error" })
            return
        }

        setIsLoader(true)
        const { res, err } = await httpRequest({ path: `/api/marketing/feedback-submit`, method: "post", params: { email: userEmail, message, rating: stars } })
        if (res) {
            toast(res?.message || "Success", { type: "success" });

            setIsLoader(false)
            setFeedbacks(false)
            setThanks(true)
        }
        else {
            setIsLoader(false)
            toast(err?.message, { type: "error" });
        }
    }
    return (
        <div className="post__modal">
            <div className=' unpaid_modal_inner '>
                <button
                    onClick={() => onClose(false)}
                    className="absolute"
                    style={{ top: -10, right: -10 }}
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                {feedbacks &&

                    <div className='px-20'>
                        <div className='flex justify-center my-3'>
                            <img className='unpaid-modal-icon' src="https://edgie.backslashwebs.com/feedback-icon.svg" alt="" />
                        </div>
                        <form onSubmit={handleSubmit} className='flex justify-between flex-col inner-body-unpaid'>
                            <div className='flex flex-col '>
                                <div className=' flex justify-center feedback-map'>
                                    <h2 className='font-size-30px font-Poppins-Medium text-[#444444]'>Submit a feedback</h2>
                                    <img className='absolute feedback-map modal-map' src={ModalMap} alt="" />
                                </div>
                                <div className='flex flex-col gap-2 items-center justify-center mt-2 mb-7'>
                                    <div className="text-yellow-500 z-10">
                                        {[1, 2, 3, 4, 5].map((v) => (
                                            <FontAwesomeIcon
                                                key={v}
                                                fontSize="20px"
                                                icon={faStar}
                                                style={{
                                                    color: stars >= v ? "#FF9B29" : "#b8b8b8",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleStarClick(v)}
                                            />
                                        ))}
                                    </div>
                                    <textarea className='relative feedback_textarea font-size-15px font-Poppins-Regular z-10 p-2' placeholder="Provide your feedback to help us improve your experience."
                                        onChange={(e) => setMessage(e.target.value)}

                                    />
                                </div>
                            </div>

                            <div className="flex   justify-center z-10">
                                <button type='submit' className='feeds-btn btn-height-submit feeds-btn-4 submit-feedback- bg-[#009E92] text-nowrap  font-size-18px font-Poppins-SemiBold text-[#FFFFFF] cursor-pointer flex flex-row gap-2'>Submit <FontAwesomeIcon icon={faArrowRight} />

                                    {isLoader && <CircularProgress style={{ color: '#fff', width: 16, height: 16, marginLeft: 4 }} />}

                                </button>
                            </div>
                        </form>
                    </div>
                }

                {thanks &&

                    <div>
                        <div className='flex justify-center my-3'>
                            <img className='unpaid-modal-icon' src={Thankyou} alt="" />
                        </div>
                        <div className='flex justify-between flex-col inner-body-unpaid'>
                            <div className='flex flex-col '>
                                <div className=' flex justify-center feedback-map'>
                                    <h2 className='font-size-30px font-Poppins-Medium text-[#444444]'>Thank you for your feedback</h2>

                                </div>
                                <div className='flex flex-col gap-2 items-center justify-center mb-4'>

                                    <p className='font-size-15px font-Poppins-Medium'>Your feedback helps us improve and better server you!</p>
                                </div>
                            </div>


                        </div>
                    </div>
                }

            </div>
        </div >
    );
};

export default FeedbackModal;