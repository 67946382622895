/**
 * ApprovedConnection Component
 * 
 * This component renders a button that approves a connection request when clicked.
 * It uses React hooks for state management and displays a loader while the request is being processed.
 * 
 * File Name: ApprovedConnection.jsx
 * Author: Developer
 * Date: 2024-04-12
 */

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import httpRequest from '../../config/Helpers/httpRequest';
import { StartupSnapshot } from 'v8';
interface ApprovedConnectionProps {
    id: string;
    onSuccess: (nextStatus: string) => void;
}
interface ApiResponse {

    message: string;
    nextStatus: string;
    acceptConnection: acceptConnection;
}
interface acceptConnection {
    status: string;
}
/**
 * ApprovedConnection Component
 * 
 * @param {string} id - The ID of the user to approve the connection request for.
 * @param {function} onSuccess - Callback function to be called upon a successful approval.
 */


const ApprovedConnection: React.FC<ApprovedConnectionProps> = ({ id = "", onSuccess = () => { } }) => {
    const [isLoader, setIsLoader] = useState<string | boolean>(false);

    // Functionality: API call for connection request accept
    const handleApproved = async (uId: string) => { // approved function
        setIsLoader(uId);
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/connection/accept", params: { otherId: uId } });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            onSuccess(res?.nextStatus || "");
        } else {
            toast(err?.message, { type: "error" })
        }
        setIsLoader(false);
    };
    return (
        <button
            onClick={() => handleApproved(id)}
            disabled={isLoader === id}
            className="font-size-18px theme-color-green font-Poppins-Medium feeds-btn"
            style={{ color: "white", background: "var(--theme-green)" }}
        >
            Approve {isLoader === id && <CircularProgress style={{ color: '#fff', width: 16, height: 16, marginLeft: 4 }} />}
        </button>
    );
};

export default ApprovedConnection;