/**
 * AdminTrialUsers Component
 * Displays trial users with filtering by name and user type (participant & provider), pagination support, and navigation to user profiles on name click.
 * File: AdminTrialUsers.js
 * Author: Developer
 * Date: 03-04-24
 */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faPencil,
} from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import httpRequest from "../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import Isverified from "../../assets/img/isMember.svg"
import IsFivityVerfied from "../../assets/img/50member.svg"



interface User {

  _id: string;
  firstName: string;
  lastName: string;
  email: string;

  role: string;
  verification: string;
  status: string;
  createdAt: string;
  [key: string]: any;
}
interface Paginated {
  totalItems: number;
}
interface ApiResponse {
  totalResults: number;
  paginated: Paginated;

  documents: User[];
}
interface QueryParams {
  member: string;
  role: string;
  name: string;
  page: number;
  pageSize: number;
  sort: string;
}
const AdminTrialUsers: React.FC = () => {
  const [isComponentLoad, setIsComponentLoad] = useState<boolean>(false); // for check component load or not
  const [bool, setBool] = useState<boolean>(false); // State for toggle updates

  const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
  const [trialUsers, setTrialUsers] = useState<User[]>([]); // State for trial users list

  const [temporary, setTempValue] = useState<string>(""); // State for temporary filter value
  const [totalPage, setTotalPage] = useState<number>(1); // State for total number of pages

  const [queryParams, setQueryParams] = useState<QueryParams>({
    member: "",
    role: "provider",
    name: "",
    page: 1,
    pageSize: 10,
    sort: "desc",
  }); // State for query parameters used in API callsState for query parameters used in API calls
  const [DropDown, setDropDown] = useState<number | null>(null);

  // Function to handle dropdown click
  // const handleDropDownClick = (index: number) => {
  //   if (DropDown === index) {
  //     setDropDown(null);
  //   } else {
  //     setDropDown(index);
  //   }
  // };
  const handleDropDownClick = (index: number, isFreeUser: boolean) => {
    if (DropDown === index) {
      setDropDown(null);
    } else {
      setDropDown(index);
    }
  };
  // Debounce function for filtering
  const handleDebounce = (value: string) => {
    if (value !== " ") {
      setTempValue(value);
    }
  };

  // Functionality: API call for get all trial user Admin
  const fetch = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/user/all?${qs.stringify(queryParams)}` });
    if (res) {
      const count = res?.paginated?.totalItems || 0;
      // Calculate total pages based on page size and total results
      setTotalPage(Math.ceil(count / queryParams.pageSize));
      setTrialUsers(res?.documents); // Set trial users data from API response
    } else {
      toast(err, { type: 'error' });
    }
    setIsLoader(false);
  };

  // Effect hook to update query params when filter changes
  useEffect(() => {
    if (isComponentLoad) {
      const debounceTimer = setTimeout(() => {
        setQueryParams({ ...queryParams, name: temporary, page: 1 });
      }, 1500);
      return () => clearTimeout(debounceTimer);
    }
  }, [temporary]);

  // Effect hook to fetch data when query params change
  useEffect(() => {
    fetch();
    setIsComponentLoad(true);
  }, [bool, queryParams]);
  const statusToogle = async (id: string) => {
    try {
      const { res, err } = await httpRequest<ApiResponse>({
        method: "put",
        path: `/api/admin/user/updateStatus/${id}`,
        params: {}
      });

      if (res) {
        setBool(!bool); // Toggle bool state to trigger useEffect
      } else {
        toast(err.message || 'Error updating status', { type: 'error' });
      }
    } catch (error) {
      toast('An unexpected error occurred', { type: 'error' });
    }
  };

  const verifiedToogle = async (id: string) => {
    const { res, err } = await httpRequest(({ method: "put", path: `/api/admin/user/profileVerification/${id}`, params: {} }));
    if (res) {
      setBool(!bool); // Toggle bool state to trigger useEffect
    } else {
      toast(err, { type: 'error' });
    }
  };

  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          <AdminHeader />
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <div>
                <h2 className="font-size-36px font-Poppins-Medium">
                  Providers
                </h2>
                <p> <span className="font-size-16px font-Poppins-Bold">Dashboard /</span> <span className="font-Poppins-Medium"> Providers</span></p>
              </div>
              <div className="Main-AdminUsers operator-main-admin mt-6 flex justify-between items-center">
                <div className="flex">
                  <input
                    placeholder="Name"
                    className="border-2 font-Poppins-Medium p-3 mr-6 font-size-15px"
                    type="search"
                    value={temporary}
                    onChange={(e) => handleDebounce(e.target.value)}
                  />
                  <div className="select-inner-text relative">
                    <span>Subscription type</span>
                    <select
                      className="border-2 p-3 Main-AdminUsers font-Poppins-Medium mr-10 font-size-15px"
                      onChange={(e) =>
                        setQueryParams({
                          ...queryParams,
                          member: e.target.value,
                          page: 1,
                        })
                      }
                    >
                      <option value="">Select subscription type</option>
                      <option value="false">Freemium</option>
                      <option value="true">Premium</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="mt-5">
                <div className="">
                  <ul
                    style={{
                      gridTemplateColumns: "14% 14% 17% 11% 13% 13% 11% 7%",
                    }}
                    className="outer-detail-border main-Admin-status-action Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                  >
                    {[
                      "Name",
                      "Subscription type",
                      "Email",
                      "Verified status",
                      "Verified update",
                      "Account status",
                      "Member since",
                      "Action",
                      // "No of Logins",

                    ].map((item) => (
                      <li className="text-white" key={item}>
                        {item || ""}
                      </li>
                    ))}
                  </ul>
                </div>
                {isLoader ? (
                  <div className="main-Admin-status-content mt-5 text-center">
                    <CircularProgress style={{ color: "#00443f" }} />
                  </div>
                ) : trialUsers && trialUsers.length > 0 ? (
                  trialUsers.map((item, index) => (
                    <div key={index} className=" pt-5">
                      <ul
                        style={{
                          gridTemplateColumns: "14% 14% 17% 11% 13% 13% 11% 7%",
                        }}
                        className="under-detail-border Admin-status-content-Role main-Admin-status-content text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                      >
                        <li>
                          <Link
                            className="underline flex flex-row gap-2 items-center capitalize"
                            to={`/public-profile/${item?._id}/view`}
                          >
                            {item?.fullName}
                            {item?.freeUser === false &&
                              <img className="discussion-sidebar-icon-2" src={item?.isFounder ? IsFivityVerfied : Isverified} alt="Verified-Icon" />

                            }

                          </Link>
                        </li>
                        <li>
                          <span>{item?.freeUser === true ? "Freemium" : "Premium"}

                          </span>
                        </li>
                        <li >
                          <span className="txt-turncate">  {item?.email}</span>

                        </li>
                        <li className="" >
                          <div>
                            {item?.profileVerified === true ?
                              (
                                <div className="w-max">
                                  <button className="font-size-15px font-Poppins-Medium verified-btn bg-[#B2D8D5] text-[#007E76] flex flex-row gap-2 items-center">
                                    Verified
                                  </button>
                                </div>)

                              :
                              (
                                <div className="w-max">

                                  <button className="font-size-15px font-Poppins-Medium bg-[#FFB2B2] text-[#980000] non-verify-btn cursor-default">
                                    Non-Verified
                                  </button>
                                </div>
                              )

                            }</div>
                        </li>
                        {/* <li>{item?.totalLogins}</li> */}
                        <li>
                          {" "}
                          <div className="Admin-paid-user-on-off-btn">
                            <div>
                              <input
                                onChange={(e) =>
                                  verifiedToogle(
                                    item?._id,
                                  )
                                }
                                checked={item?.profileVerified === true}
                                type="checkbox"
                                id={`verified-checkbox${index}`}
                              />
                              <label htmlFor={`verified-checkbox${index}`}></label>
                            </div>
                          </div>
                        </li>
                        <li>
                          {" "}
                          <div className="Admin-paid-user-on-off-btn">
                            <div>
                              <input
                                onChange={(e) =>
                                  statusToogle(
                                    item?._id)
                                }
                                checked={item?.status === "active"}
                                type="checkbox"
                                id={`status-checkbox${index}`}
                              />
                              <label htmlFor={`status-checkbox${index}`}></label>
                            </div>
                          </div>
                        </li>
                        <li>
                          {moment(item?.createdAt).format("DD-MM-YYYY") ||
                            "-"}
                        </li>
                        <li className="relative font-size-30px">
                          <FontAwesomeIcon
                            className="cursor-pointer"
                            icon={faEllipsisVertical}
                            onClick={() => handleDropDownClick(index, item.freeUser)}
                          />
                          <div
                            className={`dropDownElipsis ${DropDown === index ? "DropDownActive" : ""
                              }`}
                          >
                            <div className="flex flex-col items-start">
                              <Link
                                to={`/public-profile/${item?._id}/view`}
                                className="font-size-15px text-[#2F2F31] flex items-center gap-[1px] font-Poppins-Regular pb-2"
                              >
                                <FontAwesomeIcon icon={faPencil} />
                                View profile
                              </Link>

                              {
                                !item.freeUser &&
                                <Link
                                  to={`/user-billing-detail/${item?.email}/view`}
                                  className="font-size-15px text-[#2F2F31] flex items-center gap-[1px] font-Poppins-Regular"
                                >
                                  <img
                                    className="billing-detail-invoice"
                                    src="https://edgie.backslashwebs.com/imgs/Billing-Detail-invoice.png"
                                    alt="Billing Detail"
                                  />
                                  Billing detail
                                </Link>
                              }



                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  ))
                ) : (
                  <div className="main-Admin-status-content mt-5">
                    <p className="text-center">No providers found</p>
                  </div>
                )}
                <PaginationComponet
                  total={totalPage}
                  setCurrentPage={(e) =>
                    setQueryParams({ ...queryParams, page: e })
                  }
                  currentPage={queryParams.page}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminTrialUsers;