/**
 * ConnectButton Component
 * 
 * This component renders a button that sends a connection request when clicked.
 * It uses React hooks for state management and displays a loader while the request is being processed.
 * 
 * File Name: ConnectButton.js
 * Author: Developer
 * Date: 24-04-12
 */

import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import httpRequest from '../../config/Helpers/httpRequest';
import UnpaidModal from '../Modal/UnpaidModal';
interface ConnectButtonProps {
    id: string;
    onSuccess: (nextStatus: string) => void;
}

interface ApiResponse {
    nextStatus: string;
    status: string;
    message: string;
}

/**
 * ConnectButton Component
 * 
 * @param {string} id - The ID of the user to send a connection request to.
 * @param {function} onSuccess - Callback function to be called upon a successful connection request.
 */
const ConnectButton: React.FC<ConnectButtonProps> = ({ id = "", onSuccess = () => { } }) => {
    const [isLoader, setIsLoader] = useState<string>("");
    const [unpaidModalShow, setUnpaidModalShow] = useState<boolean>(false);
    const [error, setError] = useState<string>("");

    // Functionality: API call for connection request send
    const sendConnectionRequest = async () => {
        setIsLoader(id);
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/connection/send", params: { receiverId: id } })
        if (res) {
            onSuccess(res?.nextStatus || "");
            toast(res?.message || "Success", { type: "success" });
        } else {
            if (err?.status === 402) {
                setUnpaidModalShow(true)
                setError(err?.message)

            }
            toast(err?.message, { type: "error" })
        }
        setIsLoader("");
    };
    return (
        <>
            <button
                onClick={sendConnectionRequest}
                className="feeds-btn flex items-center justify-center  theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn" style={{ color: 'white' }}
            >
                <FontAwesomeIcon icon={faUserPlus} /> Connect
                {isLoader === id && <CircularProgress style={{ color: '#fff', width: 14, height: 14, marginLeft: 6 }} />}
            </button>
            {unpaidModalShow && <UnpaidModal profile={true} error={error} onClose={() => setUnpaidModalShow(false)} />}

        </>
    );
};

export default ConnectButton;