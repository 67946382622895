import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import SchedulerBody from "./SchedulerBody";
import {
  getRole,
  getCookie,
  YEAR_MONTH_DATE,
  validateTextAndSpace,
} from "../../config/Helpers/helpers";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import AdminSchedularBody from "./AdminSchedularBody";
import { RootState } from "../../config/Store/store";
import httpRequest from "../../config/Helpers/httpRequest";
interface Service {
  _id: string;
  servicename?: string;
  documents?: Service[];
}
interface ApiResponseServices {
  documents: [];
}
interface ApiResponse {
  documents: []
}
interface Week {
  teacher: string;
  events: WeekEvent[];
}
interface WeekEvent {
  date: Date;
  events: Event[];
}
interface Event {
  date: Date;
  // other properties if necessary
}


// Define the Teacher type with a name property
interface Teacher {
  name: string; // Add the name property
  events: Event[];
}
const SchedulerCalender = () => {
  const { ALLSERVICES } = useSelector((store: RootState) => store.allServices); // get all services for filter
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = getCookie();
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  // const ROLE = getRole()?.UserRole || false;
  const decoded = getRole();
  const ROLE = decoded?.role;

  const [currentDate, setCurrentDate] = useState<Date>(new Date());
  const [view, setView] = useState<string>("weekly");

  // const teachers: { events: Event[] }[] = [{ events: [] }];
  const teachers: Teacher[] = [
    { name: 'Teacher 1', events: [] },
    { name: 'Teacher 2', events: [] }
  ];

  const generateMonthlyGrid = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const firstDayOfMonth = new Date(year, month, 1);
    const startingDayOfWeek = firstDayOfMonth.getDay();
    const totalDaysInMonth = new Date(year, month + 1, 0).getDate();
    const weeks: Week[] = [];
    teachers.forEach((teacher) => {
      let currentWeek = [];
      for (let day = 1; day <= totalDaysInMonth; day++) {
        const date = new Date(year, month, day);
        const events = teacher.events.filter((event) => {
          return (

            event.date.getDate() === date.getDate() &&
            event.date.getMonth() === date.getMonth() &&
            event.date.getFullYear() === date.getFullYear()
          );
        });
        currentWeek.push({ date, events });
        if (
          currentWeek.length === totalDaysInMonth ||
          day === totalDaysInMonth
        ) {
          weeks.push({ teacher: teacher.name, events: currentWeek });
          currentWeek = [];
        }
      }
    });

    return weeks;
  };

  const generateWeeklyGrid = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();
    const startingDayOfWeek = currentDate.getDay();
    // const weeks = [{ teacher: "", events: [] }];
    const weeks: Week[] = []; // Explicitly type weeks as an array of Week


    teachers.forEach((teacher) => {
      let currentWeek: WeekEvent[] = []; // Explicitly type currentWeek as an array of WeekEvent
      for (let i = 0; i < 7; i++) {
        const date = new Date(year, month, day - startingDayOfWeek + i);
        const events = teacher.events.filter((event) => {
          return (
            event.date.getDate() === date.getDate() &&
            event.date.getMonth() === date.getMonth() &&
            event.date.getFullYear() === date.getFullYear()
          );
        });
        currentWeek.push({ date, events });
      }
      weeks.push({ teacher: teacher.name, events: currentWeek });
    });

    return weeks;
  };



  // let weeks =
  //   view === "monthly"
  //     ? generateMonthlyGrid()
  //     : view === "weekly"
  //       ? generateWeeklyGrid()
  //       : "";
  let weeks =
    view === "monthly"
      ? generateMonthlyGrid()
      : view === "weekly"
        ? generateWeeklyGrid()
        : [];

  // 05/02/24 integration start
  const [jobSide, setJobSide] = useState<"creator" | "contractor">("creator"); // Admin filter participant or provider
  const [serviceDropDown, setServiceDropDown] = useState<boolean>(false); // Services dropdown
  const [servicesLoader, setServicesLoader] = useState<boolean>(false);

  const [isLoader, setIsLoader] = useState<boolean>(false);

  // filter / query
  const [sid, setSid] = useState<string[]>([]); // Services [sid1, sid2, ....]
  const [jobType, setJobType] = useState<string>(""); // JobType 1,2,3,4,5
  const [status, setStatus] = useState<string>(""); // Status "", "onboard", "completed", "cancelled"
  const [startDate, setStartDate] = useState<string>(""); // Start date yyyy-mm-dd
  const [endDate, setEndDate] = useState<string>(""); // End date yyyy-mm-dd
  const [tempName, setTempName] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [bool, setBool] = useState<boolean>(false); // Stte for recall api
  // filter / query

  const [allJobs, setAllJobs] = useState<any[]>([]); // Array of all jobs
  const toggleIcon = () => {
    setServiceDropDown(!serviceDropDown);
  };

  const changeJobSide = (key: "creator" | "contractor") => {
    setJobSide(key);
  };

  const handleJobType = (event: string) => {
    setJobType(event);
  };

  const handleStatusChange = (event: string) => {
    setStatus(event);
  };

  const handleViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    let _value = event.target.value;
    if (_value === "monthly") {
      const newStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      );
      const newEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        0
      );
      setStartDate(YEAR_MONTH_DATE(newStartDate));
      setEndDate(YEAR_MONTH_DATE(newEndDate));
    } else if (_value === "weekly") {
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay()
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay())
      );
      setStartDate(YEAR_MONTH_DATE(startOfWeek));
      setEndDate(YEAR_MONTH_DATE(endOfWeek));
    }
    setView(_value);
  };

  const handleServiceChange = (item: { _id: string }) => {
    let { _id } = item;
    const exists = sid.includes(_id);
    if (exists) {
      // Remove the item if it exists
      const updatedServices = sid.filter((v) => v !== _id);
      setSid(updatedServices);
    } else {
      // Add the item if it doesn't exist
      setSid([...sid, _id]);
    }
  };

  const handlePrev = () => {
    if (view === "monthly") {
      const prevMonthStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() - 1,
        1
      );
      setCurrentDate(prevMonthStartDate);
      const prevMonthEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        0
      );
      setStartDate(YEAR_MONTH_DATE(prevMonthStartDate));
      setEndDate(YEAR_MONTH_DATE(prevMonthEndDate));
    } else if (view === "weekly") {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() - 7
        )
      );
      const prevStartDate = new Date(startDate);
      prevStartDate.setDate(prevStartDate.getDate() - 7);

      const prevEndDate = new Date(endDate);
      prevEndDate.setDate(prevEndDate.getDate() - 7);

      setStartDate(YEAR_MONTH_DATE(prevStartDate));
      setEndDate(YEAR_MONTH_DATE(prevEndDate));
    } else {
    }
  };

  const handleNext = () => {
    if (view === "monthly") {
      const nextMonthStartDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 1,
        1
      );
      setCurrentDate(nextMonthStartDate);
      const nextMonthEndDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth() + 2,
        0
      );
      setStartDate(YEAR_MONTH_DATE(nextMonthStartDate));
      setEndDate(YEAR_MONTH_DATE(nextMonthEndDate));
    } else if (view === "weekly") {
      setCurrentDate(
        new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate() + 7
        )
      );
      const nextStartDate = new Date(startDate);
      nextStartDate.setDate(nextStartDate.getDate() + 7);
      const nextEndDate = new Date(endDate);
      nextEndDate.setDate(nextEndDate.getDate() + 7);
      setStartDate(YEAR_MONTH_DATE(nextStartDate));
      setEndDate(YEAR_MONTH_DATE(nextEndDate));
    } else {
    }
  };

  const getCurrentWeekDates = () => {
    const getDate = new Date();
    const currentDayOfWeek = getDate.getDay();

    const diff = currentDayOfWeek - 0;
    const startDate = new Date(getDate);
    startDate.setDate(startDate.getDate() - diff);

    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + 6);

    setStartDate(YEAR_MONTH_DATE(startDate));
    setEndDate(YEAR_MONTH_DATE(endDate));
  };

  // Functionality: API call for get all Jobs for Scheduler
  const fetchJobs = async () => {
    if (startDate && endDate) {
      setIsLoader(true);
      const formattedStartDate = `${startDate}T00:00:00`;
      const formattedEndDate = `${endDate}T00:00:00`;
      const path = jobSide === "creator" ? "/api/job/schedular/creator" : "/api/job/schedular/contractor";
      try {
        const response = await axios.get<ApiResponse>(
          // `${process.env.REACT_APP_BASE_URL}/api/schedular/all/${jobSide}?serviceIds=${sid}&startDate=${startDate}&endDate=${endDate}&name=${name}&status=${status}&jobType=${jobType}`,
          `${process.env.REACT_APP_BASE_URL}${path}?serviceIds=${sid}&date=${formattedStartDate}&endDate=${formattedEndDate}&name=${name}&status=${status}&jobType=${jobType}&page=1&limit=10`,

          config
        );
        console.log(response?.data?.documents, "resresres")
        setAllJobs(response?.data?.documents);

        // let _data = response.data?.filteredJobs[0]?.data;
        // let _data = response.documents;

        // const groupData = _data?.reduce((groups, obj) => {
        //   console.log(groupData, "groupDatagroupData")
        //   const _id =
        //     jobSide === "creator"
        //       ? obj?.applicantDetails?._id
        //       : obj?.jobCreatorDetails?._id;
        //   if (!groups[_id]) {
        //     groups[_id] = [];
        //   }
        //   groups[_id].push(obj);
        //   return groups;
        // }, {});

        // const result = Object.values(groupData);
        // setAllJobs(result);
        // setAllJobs(response?.documents);

      } catch (err: any) {
        if (err?.status === 444) {
          toast(
            err.message || "Error",
            { type: "error" }
          );
          return
        }
        toast(
          err.response?.data?.message || err.response?.data?.error || "Error",
          { type: "error" }
        );
      }
      setIsLoader(false);
    }
  };

  const handleApplyServicesFilter = () => {
    fetchJobs();
    setServiceDropDown(false);
  };

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null); // Reference for debounce timeout

  const handleNameDebounce = (text: string) => {
    setTempName(text);
    if (
      text &&
      text.length > 0 &&
      text.trim() !== "" &&
      validateTextAndSpace(text)
    ) {
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
      timeoutIdRef.current = setTimeout(() => {
        setName(text);
      }, 500);
    } else {
      setName("");
      setTempName("");
      if (timeoutIdRef.current) {
        clearTimeout(timeoutIdRef.current);
      }
    }
  };

  useEffect(() => {
    fetchJobs();
  }, [jobSide, jobType, status, startDate, endDate, name, bool]);

  useEffect(() => {
    getCurrentWeekDates();
  }, []); // get current date of weeks

  // Functionality: API call for get all services for filter


  useEffect(() => {
    (async () => {
      setServicesLoader(true);
      const { res, err }: { res?: ApiResponseServices; err?: string } = await httpRequest({
        path: "/api/services/all",
      });


      if (res && res?.documents) {
        dispatch(setAllServices(res?.documents) || []); // Directly dispatch documents without flattening for now
      } else {
        toast(err || "An error occurred", { type: "error" });
      }

      setServicesLoader(false);
    })();
  }, []);



  // services dropdown
  const outsideRef = useRef<HTMLDivElement | null>(null); // Reference for outside click

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (outsideRef.current && !outsideRef.current.contains(event.target as Node)) {
        setServiceDropDown(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideRef]);
  // services dropdown

  return (
    <div className="calendar-container">
      {isLoader && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.7)",
            zIndex: 9999,
          }}
        >
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      )}
      <div className="calendar-header flex flex-col relative">
        <div className="grid  items-center clander-handler-head">
          <div className="flex items-center  gap-3 justify-center">
            <button
              className="top-arrows-of-calender flex items-center justify-center bg-white leading-none btn-w"
              onClick={handlePrev}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <h2 className="font-size-18px font-Poppins-SemiBold theme-color-green leading-none text-center capitalize">
              {`${view} ${startDate} / ${endDate}`}
            </h2>
            <button
              className="top-arrows-of-calender flex items-center justify-center bg-white leading-none btn-w"
              onClick={handleNext}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
        <div className="calender-header-grid" ref={outsideRef}>
          <div
            className="calender-services-field w-full height-of-select-input-fields-of-calender"
            ref={outsideRef}
          >
            <p className="font-size-15px font-Poppins-Medium">Services</p>
            <FontAwesomeIcon
              size="sm"
              icon={serviceDropDown ? faChevronUp : faChevronDown}
              onClick={toggleIcon}
            />
          </div>

          {/* job type dropdown */}
          <div className="relative w-full">
            <span className="calender-top-fields-placeholder absolute">
              Job type
            </span>
            <select
              className="font-Poppins-Medium font-size-15px w-full calender-top-field height-of-select-input-fields-of-calender"
              value={jobType}
              onChange={(e) => handleJobType(e.target.value)}
            >
              <option value="">All</option>
              <option value="1">One Off</option>
              <option value="2">Daily</option>
              <option value="3">Weekly</option>
              <option value="4">Monthly</option>

            </select>
          </div>
          {/* job type dropdown */}

          {/* only for admin */}
          {ROLE === "operator" && (
            <div className="flex job-posted-btn-cal flex-row items-center gap-4">
              <button
                className={`job-posted-btn-unActive font-size-15px font-Poppins-SemiBold ${jobSide === "creator" ? "job-posted-btn-active" : ""
                  }`}
                onClick={() => changeJobSide("creator")}
              >
                Job posted
              </button>
              <button
                className={`job-posted-btn-unActive font-size-15px font-Poppins-SemiBold ${jobSide === "contractor" ? "job-posted-btn-active" : ""
                  }`}
                onClick={() => changeJobSide("contractor")}
              >
                Job applied
              </button>
            </div>
          )}
          {/* only for provider */}
          {ROLE === "provider" && (
            <div className="flex job-posted-btn-cal flex-row items-center gap-4">
              <button
                className={`font-size-15px job-posted-btn-unActive font-Poppins-SemiBold ${jobSide === "creator" ? "job-posted-btn-active" : ""
                  }`}
                onClick={() => changeJobSide("creator")}
              >
                Job posted
              </button>
              <button
                className={`font-size-15px job-posted-btn-unActive font-Poppins-SemiBold ${jobSide === "contractor" ? "job-posted-btn-active" : ""
                  }`}
                onClick={() => changeJobSide("contractor")}
              >
                Job applied
              </button>
            </div>
          )}

          {/* job status dropdown */}
          <div className="relative w-full">
            <span className="calender-top-fields-placeholder absolute">
              Job status
            </span>
            <select
              className="font-Poppins-Medium font-size-15px w-full calender-top-field height-of-select-input-fields-of-calender"
              value={status}
              onChange={(e) => handleStatusChange(e.target.value)}
            >
              <option value="">All</option>
              <option value="onboard">Active</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
              <option value="disputed">Disputed</option>
            </select>
          </div>
          {/* job status dropdown */}
          {/* dropdown monthly & weekly */}
          <div>
            <select
              className="height-of-select-input-fields-of-calender calender-top-field-month font-size-15px"
              value={view}
              onChange={handleViewChange}
            >
              <option value="weekly">Weekly</option>
              <option value="monthly">Monthly</option>
            </select>
          </div>
          {/* dropdown monthly & weekly */}

          {serviceDropDown ? (
            <div className="admin-Service-drop-down admin-Service-drop-down-schedular text-[#646464]">
              {servicesLoader ? (
                <div className="text-center mt-2">
                  <CircularProgress
                    style={{ width: 20, height: 20, color: "#004540" }}
                  />
                </div>
              ) : ALLSERVICES && ALLSERVICES.length > 0 ? (
                <div className="socail-comunity-access flex-wrap">
                  <div className="socail-comunity-access-content service-box-1">
                    {ALLSERVICES.map((item, index) => (
                      <div key={index} className="calender-services-drop">
                        <h2 className="font-size-15px font-Poppins-SemiBold ">
                          {item?._id || ""}
                        </h2>
                        {item?.documents?.map((v, i) => (
                          <div key={i} className="admin-Social-Support services-checkbox">
                            <input
                              type="checkbox"
                              id={`skill_${v?._id}`}
                              checked={sid.includes(v?._id)}
                              onChange={() => handleServiceChange(v)}
                            />
                            <label
                              className="font-size-15px font-Poppins-Regular"
                              htmlFor=""
                            >
                              {v?.servicename || ""}
                            </label>
                          </div>
                        ))}
                        {/* <div className="admin-social-border"></div> */}
                      </div>
                    ))}
                  </div>
                  <div className="mt-2 text-end w-full">
                    <button
                      onClick={handleApplyServicesFilter}
                      className="font-size-15px font-Poppins-Regular jobs-apply-btn active"
                    >
                      Apply
                    </button>
                  </div>
                </div>
              ) : (
                <p>No Servvices</p>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      {ROLE === "admin" || ROLE === "superAdmin" ? (
        <>
          <AdminSchedularBody
            view={view}
            weeks={weeks}
            value={tempName}
            onChange={handleNameDebounce}
            data={allJobs}
            jobSide={jobSide}
            start={startDate}
            end={endDate}
          />
        </>
      ) : (
        <SchedulerBody
          view={view}
          weeks={weeks}
          value={tempName}
          onChange={handleNameDebounce}
          data={allJobs}
          jobSide={jobSide}
          start={startDate}
          end={endDate}
          setBool={setBool}
        />
      )}
    </div>
  );
};

export default SchedulerCalender;
