import React, { useState, useEffect } from "react";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { PieChart as MuiPieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import MyLineChart from "../../components/AdminComponents/myLineChart";
import ProvidersChart from "../../components/AdminComponents/ProvidersChart";
import { useDispatch, useSelector } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import UserAnalysis from "../../components/AdminComponents/UserAnalysis";

// import { RootState } from "../../config/Store/store";
import httpRequest from "../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
interface ApiResponse {
  message?: string;
  documents?: Category[];

}
interface ServiceDocument {
  _id: string;
  noOfProviders: number;
  serviceName: string;
  color?: string;
  [key: string]: any;

}

interface IApiResponse {
  documents?: ServiceDocument[];
  totalUsers: number;
  groupBy: string;
  [key: string]: any;

}
interface RootState {
  allServices: { ALLSERVICES: Category[] };
  [key: string]: any;

}
interface ApiResponse {
  message?: string;
  documents?: Category[];

}
interface Category {
  _id: string;
  documents: Service[];
  [key: string]: any;

}

interface DashboardApiResponse {

}
interface Service {
  _id: string;
  servicename?: string;
  documents?: Service[];
  [key: string]: any;
}

const data = [
  { label: 'Plan manager', value: 20, color: '#015958' },
  { label: 'Support coordinator', value: 40, color: '#008f8c' },
  { label: 'Support worker', value: 20, color: '#0caba8' },
  { label: 'Allied health', value: 20, color: '#0fc2c0' },
];
const dataSecondGraph = [
  { label: 'Daily Living and Access', value: 10, color: '#015958' },
  { label: 'Therapy and Assessment', value: 15, color: '#008F8C' },
  { label: 'Community Activities', value: 25, color: '#0CABA8' },
  { label: 'Dressing & Grooming', value: 45, color: '#0FC2C0' },
  { label: 'Assist With Medication', value: 45, color: '#015958' },
  { label: 'Daily Living and Access', value: 45, color: '#008F8C' },
  { label: 'Community Activities', value: 45, color: '#0CABA8' },
  { label: 'Dressing & Grooming', value: 45, color: '#0FC2C0' },
];
const AdminDashboard: React.FC = () => {
  const [selectedServices, setSelectedServices] = useState<string[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [groupBy, setGroupBy] = useState<string>("month");
  const [stateFilter, setStateFilter] = useState<string>("");
  const [serviceData, setServiceData] = useState<ServiceDocument[]>([]);
  const [totalUsers, setTotalUsers] = useState<number>(0);
  const [graphType, setGraphtype] = useState<string>("services");
  const handleGraphTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setGraphtype(event.target.value);
  };
  const handleGroupByChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setGroupBy(event.target.value);
    }
  };
  const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setStateFilter(event.target.value);
  };
  

  useEffect(() => {
    const fetchServiceGraphData = async () => {
      const { res, err } = await httpRequest<IApiResponse>({
        method: 'get',
        path: `/api/admin/user/service-graph/${graphType}?groupBy=${groupBy}&state=${stateFilter}&services=${selectedServices.join(',')}`,
      });

      if (res) {
        setServiceData(res?.documents || []);
        setTotalUsers(res?.totalUsers || 0);
      } else {
        toast(err || "An error occurred", { type: "error" });
      }
    };

    fetchServiceGraphData();
  }, [groupBy, stateFilter, selectedServices, graphType]);


  // last chart
  const { ALLSERVICES } = useSelector((state: RootState) => state.allServices || { ALLSERVICES: [] });
  const dispatch = useDispatch();
  useEffect(() => {
    const fetchAllServices = async () => {


      const { res, err } = await httpRequest<ApiResponse>({
        method: 'get',
        path: "/api/services/all",
      });


      if (res && res.documents) {
        dispatch(setAllServices(res?.documents)); // Directly dispatch documents without flattening for now
      } else {
        toast(err || "An error occurred", { type: "error" });
      }


    }

    fetchAllServices()


  }, [dispatch]);
  // last chart







  const [selectedService, setSelectedService] = useState<string>("");

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedService(event.target.value);
  };
  const handleServiceClick = (serviceId: string) => {
    if (selectedServices.includes(serviceId)) {
      setSelectedServices(selectedServices.filter((id) => id !== serviceId));
    } else {
      setSelectedServices([...selectedServices, serviceId]);
    }
  };



  const toggleSelectAll = () => {
    if (selectAll) {
      setSelectedServices([]);  // Deselect all
    } else {
      // Select all service IDs from all categories
      const allServiceIds = ALLSERVICES.flatMap(category =>
        category?.documents.map(service => service._id)
      );
      setSelectedServices(allServiceIds);  // Select all
    }
    setSelectAll(!selectAll);
  };


  const mapServicesToPieChartData = (services: ServiceDocument[]) => {
    return services.map(service => ({
      label: service?.serviceName || 'Unknown',
      value: service?.noOfProviders || 0,
      color: service?.color || `#${Math.floor(Math.random() * 16777215).toString(16)}`,
    }));
  };

  const pieChartData = serviceData && serviceData.length > 0
    ? mapServicesToPieChartData(serviceData)
    : [{ label: 'No data', value: 1, color: '#ccc' }];


  const limitedServiceData = serviceData.slice(0, 8);
  // const generateColor = () => {
  //   const baseColor = 0x015958;
  //   const variation = Math.floor(Math.random() * 0x050505);
  //   return `#${(baseColor + variation).toString(16).padStart(6, '0')}`;
  // };

  const serviceColorMap: { [key: string]: string } = {};

  const generateColor = (serviceName: string) => {
    if (!serviceColorMap[serviceName]) {
      const baseColor = Math.floor(Math.random() * 16777215).toString(16);
      serviceColorMap[serviceName] = `#${baseColor.padStart(6, '0')}`;
    }
    return serviceColorMap[serviceName];
  };
  // const generateColorss = (numColors: number) => {
  //   const colors = [];
  //   for (let i = 0; i < numColors; i++) {
  //     const hue = (i * 360 / numColors) % 360; // Spread hues evenly
  //     colors.push(`hsl(${hue}, 70%, 50%)`); // Saturation and lightness are set to 70% and 50% respectively
  //   }
  //   return colors;
  // };

  // // Example usage:
  // const colors = generateColorss(50);
  // console.log(colors);

  return (
    <div>
      <AdminSidebar />
      <div className="w-4/5 ml-auto">
        <AdminHeader />

        <div className="pt-24">
          <div className="pb-6 pl-6 pr-10">
            <div className="flex justify-between">
              <MyLineChart />

              <div className="total-provider-graph bg-white">

                <ProvidersChart />
              </div>
            </div>

            <UserAnalysis  />

            <div className="service-provided-graph-box bg-white">
              <div className="services-provided">
                <div className="services-provided-header">
                  <select

                    // value={selectedService}
                    // onChange={handleSelectChange}
                    onChange={(e) => handleGraphTypeChange(e)}
                    style={{
                      padding: "10px",
                      borderRadius: "5px",
                      width: "100%",
                      backgroundColor: "#E3F2F1",
                      border: "1px solid #B5B5B5",
                      outline: "none",
                    }}
                    className="font-Poppins-Medium text-[#003E3A] font-size-13px"
                  >
                    <option className="font-Poppins-Medium text-[#003E3A] font-size-13px" value="services">Provided services</option>
                    <option className="font-Poppins-Medium text-[#003E3A] font-size-13px" value="search">Looking for services</option>
                    <option className="font-Poppins-Medium text-[#003E3A] font-size-13px" value="jobs">Job listing services</option>
                  </select>
                </div>
                <div className="services-provided-dropdown-content">
                  <label className="flex gap-2 font-Poppins-Medium text-[#000000] font-size-13px items-center">
                    <input
                      type="checkbox"
                      checked={selectAll}
                      onChange={toggleSelectAll}  // Updated to match the renamed function
                    // onChange={toggleSelectAll}  // Updated to match the renamed function
                    />
                    Select all
                  </label>
                  <div className="services-provided-list">
                    {ALLSERVICES && ALLSERVICES.map((category) => (
                      <div key={category._id} className="category-group">
                        {category.documents.map((service) => (
                          <div
                            key={service?._id}
                            className={`service-item font-Poppins-Medium font-size-13px ${selectedServices.includes(service._id) ? 'active' : ''}`}
                            onClick={() => handleServiceClick(service._id)}
                          >
                            {service?.servicename}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="total-provider-graph-last">
                <div className="hidelabelGraph" style={styles.textContainer}>
                  {/* <div className="relative">
                    </div> */}
                  <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
                    <span className="graph-circle-jugar"></span>
                    {pieChartData && pieChartData.length > 0 ? (
                      <MuiPieChart
                        key={JSON.stringify(pieChartData)}  // Force re-render on data change

                        series={[
                          {
                            arcLabelMinAngle: 45,
                            data: pieChartData,

                          },
                        ]}
                        sx={{
                          [`& .${pieArcLabelClasses.root}`]: {
                            fill: '#FFFFFF',
                            fontWeight: 'bold',
                            fontSize: '16px',
                          },
                        }}
                      />
                    ) : (
                      <div>No data available</div>
                    )}
                  </div>
                  <div className="pie-cart-contaent">
                    <select id="filter" className="select-option-amdin font-Poppins-Medium font-size-13px" value={stateFilter} onChange={(e) => setStateFilter(e.target.value)}>
                      <option className="font-Poppins-Medium font-size-13px" value="">All states</option>
                      <option className="font-Poppins-Medium font-size-13px" value="QLD">Queensland</option>
                      <option className="font-Poppins-Medium font-size-13px" value="TAS">Tasmania</option>
                      <option className="font-Poppins-Medium font-size-13px" value="NSW">New South Wales</option>
                      <option className="font-Poppins-Medium font-size-13px" value="VIC">Victoria</option>
                      <option className="font-Poppins-Medium font-size-13px" value="WA">Western Australia</option>
                      <option className="font-Poppins-Medium font-size-13px" value="SA">South Australia</option>
                      <option className="font-Poppins-Medium font-size-13px" value="NT">Northern Territory</option>
                      <option className="font-Poppins-Medium font-size-13px" value="ACT">Australian Capital Territory</option>
                    </select>
                    <div className="flex gap-4 mb-4">
                      <label className="flex gap-2 text-[#000000] items-center font-Poppins-Medium font-size-13px">
                        <input
                          type="checkbox"
                          checked={groupBy === "week"}
                          value="week"
                          onChange={handleGroupByChange}
                        />
                        Weekly
                      </label>
                      <label className="flex gap-2 text-[#000000]  items-center font-Poppins-Medium font-size-13px">
                        <input type="checkbox" defaultChecked
                          checked={groupBy === "month"}
                          value="month"
                          onChange={handleGroupByChange}
                        />


                        Monthly
                      </label>
                      <label className="flex gap-2 text-[#000000]  items-center font-Poppins-Medium font-size-13px">
                        <input
                          type="checkbox"
                          checked={groupBy === "year"}
                          value="year"
                          onChange={handleGroupByChange}

                        /> Yearly
                      </label>
                    </div>
                    {pieChartData && pieChartData.length > 0 &&
                      <div className="Daily-Living-and-Access">

                        {pieChartData.map((service, index) => (
                          <div key={`legend-${index}`} className="flex gap-2 text-[#000000] font-Poppins-Medium font-size-13px items-center">
                            <div
                              className="legend-color"
                              style={{ backgroundColor: (service?.color) }}
                            ></div>
                            {service?.label}
                          </div>
                        ))}
                      </div>
                    }

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;

const styles: { [key: string]: React.CSSProperties } = {
  textContainer: {
    display: "flex",
    justifyContent: "space-between",
    height: "100%",
  },
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '20px',
    border: '1px solid #e0e0e0',
    borderRadius: '10px',
    backgroundColor: '#e8f8f5',
  },
  totalProviders: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  legend: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  },
  legendColor: {
    width: '15px',
    height: '20px',
    marginRight: '5px',
    borderRadius: '50%',
  },
};
