/**
 * ActiveJobsTabs Component
 * Component for managing tabs and displaying active, completed, and cancelled jobs in the admin panel.
 * Handles API calls to fetch jobs based on tab selection and filter criteria.
 * File: ActiveJobsTabs.js
 * Author: Developer
 * Date: 05-02-34
 */

import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useDispatch } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import AdminActiveJobsSidebar from "../../components/JobsComponents/AdminActiveJobsSidebar";
import AdminJobDetails from "../../components/JobsComponents/AdminJobDetails";
import httpRequest from '../../config/Helpers/httpRequest';
import qs from "qs";

export interface IJob {
    _id?: String;
    jobCreatorDetails?: {
        _id?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        phonenumber?: string;
        roleCategory?: string;
        profileImageUrl?: string;
        [key: string]: any;
    }
    applicantDetails?: {
        _id?: string;
        firstName?: string;
        lastName?: string;
        email?: string;
        phonenumber?: string;
        roleCategory?: string;
        profileImageUrl?: string;
        [key: string]: any;
    }
    service: {
        _id?: string;
        servicename?: string;
        [key: string]: any;
    }
    jobActivityLog?: {
        _id?: string;
        userId?: string;
        logCategory?: string;
        message?: string;
        title?: string;
        [key: string]: any;
    }
    streetAddress?: string;
    suburb?: string;
    state?: string;
    [key: string]: any;

}
export interface IApiResponseForJobs {
    documents?: IJob[];
    paginated?: {
        totalPages: number;
        [key: string]: any;
    };
    [key: string]: any;

}
export interface IQueryParams {
    page?: number;
    limit?: number;
    serviceIds?: string;
    sort?: String;
    [key: string]: any;

}
export interface IApiResponseForServices {
    [key: string]: any;
}

function ActiveJobsTabs() {
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState<string>("onboard"); // for active tab
    const [totalPages, setTotalPages] = useState<number>(1); // total pages all pages count
    const [jobsLoader, setJobsLoader] = useState<boolean>(false); // for fetch jobs loader
    const [jobsDisplay, setJobsDisplay] = useState<IJob[]>([]); // for jobs Display
    // const [selectedJobs, setSelectedJobs] = useState<IJob[] | false>(false);
    const [selectedJobs, setSelectedJobs] = useState<any>(false); // Updated type to be array of jobs


    // State for query parameters used in API calls
    const [queryParams, setQueryParams] = useState<IQueryParams>({
        page: 1,
        limit: 10,
        sort: "desc",
        location: "",
        serviceIds: "",
        date: ""
    }); // query params for APIs

    // Functionality: API call get all jobs (active, completed & cancelled) with status query Admin
    const fetchJobs = async () => {
        setJobsLoader(true);
        const queryString = qs.stringify(queryParams, { arrayFormat: "comma" });
        let path = "";
        if (activeTab === "onboard") {
            path = `/api/admin/job/manage/admin/onboard?${queryString}`;
        } else if (activeTab === "completed") {
            path = `/api/admin/job/manage/admin/completed?${queryString}`;
        } else {
            path = `/api/admin/job/manage/admin/cancelled?${queryString}`;
        }
        const { res, err } = await httpRequest<IApiResponseForJobs>({ path: path });
        if (res) {
            setJobsDisplay((prevJobs) => [
                ...prevJobs,
                ...res?.documents || [],
            ]);
            setTotalPages(res?.paginated?.totalPages || 1);
        } else {
            toast(err?.message, { type: "error" })
        }
        setJobsLoader(false);
    };

    const handleFilterSubmit = (obj: { date: string; location: string; selectServices: string[] }) => {
        setJobsDisplay([]);
        let { date = "", location = "", selectServices = [] } = obj;
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            location: location,
            serviceIds: selectServices,
            date: date
        }));
    };

    const clearFilter = () => {
        setJobsDisplay([]);
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            location: "",
            serviceIds: "",
            date: ""
        }));
    };

    const recallApi = () => {
        setJobsDisplay([]);
        fetchJobs();
    };

    const handlePageChange = (e: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: e
        }));
    };

    const tabChange = (e: string) => {
        setJobsDisplay([]);
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            location: "",
            serviceIds: "",
            date: ""
        }))
        setActiveTab(e);
    };

    useEffect(() => {
        if (jobsDisplay && jobsDisplay.length > 0) {
            setSelectedJobs(jobsDisplay[0]);
        }
        else {
            setSelectedJobs(false);
        }
    }, [jobsDisplay]);

    useEffect(() => {
        fetchJobs();
    }, [queryParams, activeTab]); // also active tab change then api call

    // Functionality: API call get all services for job filter Admin
    useEffect(() => {
        (async () => {
            const { res, err } = await httpRequest<IApiResponseForServices>({ path: "/api/services/all" });
            if (res) {
                dispatch(setAllServices(res?.documents) || []);
            } else {
                toast(err?.message, { type: "error" });
            }
        })();
    }, []);

    return (
        <>
            <div>
                <div className="active-jobs-tabs">
                    <button className={activeTab === "onboard" ? 'font-Poppins-SemiBold font-size-14px active' : 'font-Poppins-SemiBold font-size-14px'} onClick={() => tabChange("onboard")}><span className="dot-act"></span> Active</button>
                    <button className={activeTab === "completed" ? 'font-Poppins-SemiBold font-size-14px active' : 'font-Poppins-SemiBold font-size-14px'} onClick={() => tabChange("completed")}><span className="dot-comp"></span> Completed</button>
                    <button className={activeTab === "cancelled" ? 'font-Poppins-SemiBold font-size-14px active' : 'font-Poppins-SemiBold font-size-14px'} onClick={() => tabChange("cancelled")}><span className="dot-cancel"></span> Cancelled</button>
                </div>
                <div className="active-jobs-content">
                    <div className=" admin-job-active ">
                        <AdminActiveJobsSidebar
                            loader={jobsLoader}
                            data={jobsDisplay}
                            activeTab={activeTab}
                            currentPage={queryParams?.page || 1}
                            totalPages={totalPages}
                            pageChange={handlePageChange}
                            filterSubmit={handleFilterSubmit}
                            setSelectedJobs={setSelectedJobs}
                            clearFilter={clearFilter}
                        />
                        <div className="">
                            <AdminJobDetails
                                loader={jobsLoader}
                                item={selectedJobs}
                                activeTab={activeTab}
                                setBool={recallApi}
                                setActiveTab={setActiveTab}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ActiveJobsTabs;