// import React from "react";

// const SelectField = React.forwardRef(
//   (
//     {
//       labelTop = "",
//       label = "",
//       error = "",
//       options = [], // Array of options [{label: "", value: ""}]
//       value: propsValue,
//       onChange: propsOnChange,
//       size = "small",
//       ...props
//     },
//     ref
//   ) => {
//     const [stateValue, setStateValue] = React.useState("");
//     const value = propsValue !== undefined ? propsValue : stateValue;
//     const _id = `mySelect`;
//     const onChange = (event) => {
//       if (propsOnChange) {
//         propsOnChange(event);
//       } else {
//         setStateValue(event.target.value);
//       }
//     };
//     const printError = () => {
//       if (error !== "") {
//         return <span style={{ color: "red", fontSize: "12px" }}>{error}</span>;
//       }
//     };
//     return (
//       <div className={`custom-select pb-2`}>
//         {labelTop && (
//           <label htmlFor={_id} className=" font-size-15px  font-Poppins-Medium ">
//             {labelTop}
//           </label>
//         )}
//         <select
//           ref={ref}
//           id={_id}
//           className="form-control w-full mt-1"
//           value={value}
//           onChange={onChange}
//           {...props}
//         >
//           {options.map((option, index) => (
//             <option key={index} value={option.value} className=" font-size-15px  font-Poppins-Medium">
//               {option.label}
//             </option>
//           ))}
//         </select>
//         {printError()}
//       </div>
//     );
//   }
// );

// export default SelectField;
import React, { ChangeEvent, forwardRef } from "react";

interface Option {
  label: string;
  value: string;
}

interface SelectFieldProps {
  labelTop?: string;
  label?: string;
  error?: string;
  options?: Option[]; // Array of options [{label: "", value: ""}]
  value?: string;
  onChange?: (event: ChangeEvent<HTMLSelectElement>) => void;
  size?: string;
  [key: string]: any;
}

const SelectField = forwardRef<HTMLSelectElement, SelectFieldProps>(
  (
    {
      labelTop = "",
      label = "",
      error = "",
      options = [],
      value: propsValue,
      onChange: propsOnChange,
      size = "small",
      ...props
    },
    ref
  ) => {
    const [stateValue, setStateValue] = React.useState<string>("");
    const value = propsValue !== undefined ? propsValue : stateValue;
    const _id = `mySelect`;

    const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
      if (propsOnChange) {
        propsOnChange(event);
      } else {
        setStateValue(event.target.value);
      }
    };

    const printError = () => {
      if (error !== "") {
        return <span style={{ color: "red", fontSize: "12px" }}>{error}</span>;
      }
    };

    return (
      <div className={`custom-select pb-2`}>
        {labelTop && (
          <label htmlFor={_id} className=" font-size-15px font-Poppins-Medium ">
            {labelTop}
          </label>
        )}
        <select
          ref={ref}
          id={_id}
          className="form-control w-full mt-1"
          value={value}
          onChange={onChange}
          {...props}
        >
          {options.map((option, index) => (
            <option key={index} value={option.value} className=" font-size-15px font-Poppins-Medium">
              {option.label}
            </option>
          ))}
        </select>
        {printError()}
      </div>
    );
  }
);

export default SelectField;
