import React,{useState} from "react";
import SidebarGobal from "../SidebarGobal";
import HeaderGlobal from "../../HeaderDashboard/headerGlobal";
import PreviewProfile from "../Profile/PreviewProfile";
import SubscriptionTab from "../../EditProfileComponents/Tabs/SubscriptionTab";
import Paymentsuccess from "../../../components/PaymentFormComponents/Paymentsuccess";
import { useNavigate } from "react-router-dom";

function Subscription() {
  const navigate =useNavigate();

  return (
    <div>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex ">
        <SidebarGobal />
        <div className="w-full">
          {/* <div className="remove-btn">
            <PreviewProfile title="My subscription" />
     

          </div> */}
          <div className="my-subcription ">

            <SubscriptionTab />
            {/* <Paymentsuccess /> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Subscription;
