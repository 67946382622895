import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faX, faDownload } from "@fortawesome/free-solid-svg-icons";
interface ApplyJobDisplayProps {
    data: {
        msg: string;
        files: string | string[]; // files can be a string or an array of strings
    };
    setData: React.Dispatch<React.SetStateAction<boolean>>;
}

const ApplyJobDisplay: React.FC<ApplyJobDisplayProps> = ({ data, setData }) => {
    // for file name split
    const getFileName = (filePath: string): string => {
        const segments = filePath.split("/");
        return segments[segments.length - 1] || filePath;
    };

    // for download file
    const downloadFile = (file: string) => {
        const fileName = getFileName(file);
        const fileUrl = `${file}`;
        // const fileUrl = `${process.env.REACT_APP_BASE_URL_IMAGE}${file}`;
        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            })
            .catch(error => console.error('Download failed:', error));
    };
    const normalizedFiles = data.files ? (Array.isArray(data.files) ? data.files : [data.files]) : [];

    return (
        <div className="modal">
            <div className="viewapp-modal relative">
                <button onClick={() => setData(false)} className="absolute job-pre-edit">
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className="greyline-view-app"></div>
                <div className="relative">
                    <h2 className="font-Poppins-Medium font-size-30px text-[#464646] text-center">View application</h2>
                </div>

                <div className='flex justify-start flex-col mt-4'>
                    <label className="font-Poppins-Regular font-size-14px text-[#646464] text-start mb-1">Message</label>
                    <textarea readOnly value={data?.msg || ""} className='view-app-detail p-3'> </textarea>
                </div>
                <div className='flex flex-row gap-2 items-center flex-wrap uploaded-app-files mt-5'>
                    {normalizedFiles.length > 0 ?
                        normalizedFiles.map((item, index) => (
                            <button onClick={() => downloadFile(item)} key={index} className="flex flex-row gap-2 items-center font-size-15px font-Poppins-Medium">
                                <FontAwesomeIcon icon={faFile} />
                                {getFileName(item)}
                                <FontAwesomeIcon icon={faDownload} />
                            </button>
                        )) : null
                    }
                </div>
            </div>

        </div>
    )
}

export default ApplyJobDisplay;