import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faEye,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import Skeleton from "@mui/material/Skeleton";
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  getCookie,
  getRole,
  logOutWithOutApi,
} from "../../../config/Helpers/helpers";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import qs from "qs";
import PaginationComponet from "../../PaginationComponet/PaginationComponet";
import httpRequest from "../../../config/Helpers/httpRequest";
import NdisyncLogo from "../../../assets/img/ndisync-black-logo.png";
interface IApiResponse {
  blocklist: any[];
  message: string;
  settings: {
    about: number;
    activity: number;
    workHistory: number;
    education: number;
    preference: number;
    location: number;
    contact: number;
    email: number;
    socialLinks: number;
    blockCount: number;
  };
  documents: []
  blockCount?: number;
  paginated: paginated;
  totalResults?: { value: number }[];
}
interface paginated {
  totalPages: number
}
interface IPrivacySettings {
  about: number;
  activity: number;
  workHistory: number;
  education: number;
  preference: number;
  location: number;
  contact: number;
  email: number;
  socialLinks: number;
}
function PrivacyTab() {
  // 12/04/24
  const roleData = getRole();
  const UserRole = roleData?.role;

  let { uid } = useParams<{ uid: string }>();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [showToggle, setShowToogle] = useState<string | false>(false);
  const [blockUserShow, setBlockUsersShow] = useState<boolean>(false);
  const [blockUserCount, setBlockUserCount] = useState<number>(0);
  const [allPrivacy, setAllPrivacy] = useState<IPrivacySettings>({
    about: 0,
    activity: 0,
    workHistory: 0,
    education: 0,
    preference: 0,
    location: 0,
    contact: 0,
    email: 0,
    socialLinks: 0,
  });
  const [deleteLoader, setDeleteLoader] = useState<boolean>(false);


  let dummyArr = [
    // first box
    { key: "about", title: "About me", display: true },
    { key: "activity", title: "Activity", display: true },
    {
      key: "workHistory",
      title: "Work history",
      display: UserRole === "provider" ? true : false,
    },
    {
      key: "education",
      title: "Education & training",
      display: UserRole === "provider" ? true : false,
    },
    { key: "preference", title: "My preferences & interests", display: true },
    // second box
    { key: "location", title: "Location", display: true },
    { key: "contact", title: "Contact no.", display: true },
    { key: "email", title: "Email", display: true },
    { key: "socialLinks", title: "Social links", display: true },
  ]; // dummy array for map privacy items

  // Functionality: API call for updating privacy
  const handleChange = async (key: string, value: number) => {
    setIsLoader(true);
    const { res, err } = await httpRequest<IApiResponse>({ method: "put", path: `/api/privacy-settings/update`, params: { [key]: value } });
    if (res) {
      let data = res?.settings;
      setAllPrivacy({
        about: data?.about || 0,
        activity: data?.activity || 0,
        workHistory: data?.workHistory || 0,
        education: data?.education || 0,
        preference: data?.preference || 0,
        location: data?.location || 0,
        contact: data?.contact || 0,
        email: data?.email || 0,
        socialLinks: data?.socialLinks || 0,
      });
      setShowToogle(false);
      setBlockUsersShow(false);
      toast(res?.message || "Success", { type: "success" });
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setIsLoader(false);
  };

  const [blockListLoader, setBlockListLoader] = useState<boolean>(false);
  const [blockLoader, setBlockLoader] = useState<string | false>(false);
  const [blockList, setBlockList] = useState<any[]>([]);

  const [isDeactivateModal, setIsDeactivateModal] = useState<boolean>(false);
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [deactivateAccount, setDeactivateAccount] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<{ page: number; pageSize: number }>({
    page: 1,
    pageSize: 10,
  });

  const [deactivateLoader, setDeactivateLoader] = useState<boolean>(false);

  const deleteModalClose = () => {
    setPassword("");
    setIsDeactivateModal(false);
  };
  const deactivateModalClose = () => {
    setPassword("");
    setDeactivateAccount(false)
  }
  // const handleDeleteAccount = async () => {
  //   const payload = {
  //     password: password
  //   }
  //   if (!password) {
  //     toast("Please enter your password!", { type: "error" });
  //   } else {
  //     const { res, err } = await httpRequest({
  //       method: "delete",
  //       path: "/api/user/delete-account",
  //       params: { payload },
  //     });
  //     if (res) {
  //       toast(err?.message || "Account Deleted.", { type: "error" });
  //       logOutWithOutApi();
  //     } else {
  //       toast(err?.message, { type: "error" });
  //     }
  //   }
  // };
  const handleDeleteAccount = async () => {
    if (!password) {
      toast("Please enter your password!", { type: "error" });
    } else {
      setDeleteLoader(true);

      try {
        // Use axios.request to send a DELETE request with a body
        const response = await axios.request({
          url: `${process.env.REACT_APP_BASE_URL}/api/user/delete-account`,
          method: 'DELETE',  // Specify the method
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getCookie()}` // Include the token in the Authorization header
          },
          data: { password }  // Use `data` to include the body payload
        });

        // If the response is successful, assume account deletion
        toast("Account Deleted.", { type: "success" });
        logOutWithOutApi(); // Function to log out user
        setDeleteLoader(false);

      } catch (error: any) {
        // Error handling
        const errorMessage = error.response?.data?.message || "An error occurred.";
        toast(errorMessage, { type: "error" });
        setDeleteLoader(false);

      }
      setDeleteLoader(false);

    }
  };
  const handleDeactivateAccount = async () => {
    if (!password) {
      toast("Please enter your password!", { type: "error" });
    } else {
      setDeactivateLoader(true);
      const { res, err } = await httpRequest({
        method: "post",
        path: "/api/user/deactivate-account",
        params: { password },
      });
      if (res) {
        toast(err?.message || "Account deactivated.", { type: "error" });
        logOutWithOutApi();
      } else {
        toast(err?.message, { type: "error" });
      }
      setDeactivateLoader(false);
    }
  };
  // Functionality: API call for get all blocked users
  const showBlockedUserList = async () => {
    setBlockUsersShow(true);
    setBlockListLoader(true);
    const { res, err } = await httpRequest<IApiResponse>({
      path: `/api/block/list?${qs.stringify(queryParams)}`,
    });
    if (res) {
      setBlockList(res?.documents);
      // let count = res?.totalResults?.[0]?.value || 0;
      setTotalPages(res?.paginated?.totalPages || 1);
    } else {
      toast(err?.message, { type: "error" });
    }
    setBlockListLoader(false);
  };

  // Functionality: API call for unblock users
  const handleUnBlock = async (id: string) => {
    setBlockLoader(id);

    const { res, err } = await httpRequest<IApiResponse>({
      method: "post",
      path: "/api/block/remove",
      params: { blockedUserId: id },
    });
    if (res) {
      setBlockUserCount((prev) => prev - 1);
      toast(res?.message || "Success", { type: "success" });
      setShowToogle(false);
      setBlockUsersShow(false);
    } else {
      toast(err?.message, { type: "error" });
    }
    setBlockLoader(false);
  };

  useEffect(() => {
    if (blockUserShow) {
      showBlockedUserList();
    }
  }, [queryParams]);

  // Functionality: API call for get current privacy & blocked user count
  useEffect(() => {
    (async () => {
      setIsLoader(true);
      const { res, err } = await httpRequest<IApiResponse>({
        path: `/api/privacy-settings`,
      });
      if (res) {
        let data = res?.settings;
        setBlockUserCount(data?.blockCount || 0);
        setAllPrivacy({
          about: data?.about || 0,
          activity: data?.activity || 0,
          workHistory: data?.workHistory || 0,
          education: data?.education || 0,
          preference: data?.preference || 0,
          location: data?.location || 0,
          contact: data?.contact || 0,
          email: data?.email || 0,
          socialLinks: data?.socialLinks || 0,
        });
      } else {
        toast(err?.message, { type: "error" });
      }
      setIsLoader(false);
    })();
  }, []);
  return (
    <div className="">
      {isDeactivateModal && (
        <Dialog
          open={true}
          keepMounted
          onClose={deleteModalClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <img className="ndisy-black-logo-delete" src={NdisyncLogo} alt="" />
          <DialogTitle
            className="font-size-20px font-Poppins-SemiBold theme-color-green text-center "
            style={{ fontWeight: "600" }}
          >
            Confirm account deletion
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col delete-account-confirm gap-1">
              {/* <h2 className="">CONFIRM OPTIMA ACCOUNT DELETION</h2> */}
              <p className="font-size-18px font-Poppins-Regular text-black">
                We're sorry to see you leave. By deactivating your account, all
                your data will be permanently deleted.
              </p>
              <p className="font-size-18px font-Poppins-Regular text-black">
                Please confirm by entering your password below. This action
                cannot be reversed.
              </p>
              <label className="font-size-20px font-Poppins-Medium">
                Password:
              </label>
              <div className="relative custom-input">
                <input
                  className="w-full outline-none"
                  type={showPassword ? "text" : "password"}
                  name="currentPass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="modal-delete-submit-btn">
            <button
              className="font-size-20px font-Poppins-Medium flex gap-2 items-center justify-center"
              onClick={handleDeleteAccount}
            >
              Submit
              {deleteLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}

            </button>
            <button
              className="font-size-20px font-Poppins-Medium"
              onClick={deleteModalClose}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      )}
      {deactivateAccount && (
        <Dialog
          open={true}
          keepMounted
          onClose={deactivateModalClose}
          aria-describedby="alert-dialog-slide-description"
        >
          <img className="ndisy-black-logo-delete" src={NdisyncLogo} alt="" />
          <DialogTitle
            className="font-size-20px font-Poppins-SemiBold theme-color-green text-center "
            style={{ fontWeight: "600" }}
          >
            Confirm account deactivation
          </DialogTitle>
          <DialogContent>
            <div className="flex flex-col delete-account-confirm gap-1">
              {/* <h2 className="">CONFIRM OPTIMA ACCOUNT DELETION</h2> */}
              <p className="font-size-18px font-Poppins-Regular text-black">
                If you deactivate your account, we will retain your data for 3 months. If your account remains inactive during this period, it will be permanently deleted. While we will delete all data associated with your account, we will retain your email address due to its association with other activities on the platform, such as job posts and related activities.<br /> You can reactivate your account by logging in again within the 3-month period. Once permanently deleted, you will not be able to use the same email address to register again and will need to sign up with a different email address.
              </p>
              <p className="font-size-18px font-Poppins-Regular text-black mt-1">
                Please confirm by entering your password below.
              </p>
              <label className="font-size-20px font-Poppins-Medium">
                Password:
              </label>
              <div className="relative custom-input">
                <input
                  className="w-full outline-none"
                  type={showPassword ? "text" : "password"}
                  name="currentPass"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  className="absolute inset-y-0 right-0 px-3 flex items-center text-gray-400"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
                </button>
              </div>
            </div>
          </DialogContent>
          <DialogActions className="modal-delete-submit-btn">
            <button
              disabled={deactivateLoader}
              className="font-size-20px font-Poppins-Medium flex gap-2 items-center justify-center"
              onClick={handleDeactivateAccount}
            >
              Submit
              {deactivateLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}
            </button>
            <button
              className="font-size-20px font-Poppins-Medium"
              onClick={deactivateModalClose}
            >
              Cancel
            </button>
          </DialogActions>
        </Dialog>
      )}
      {!blockUserShow && !showToggle && (
        <>
          <div className="shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex gap-5 flex-col">
            {dummyArr
              .slice(0, 5)
              .filter((v) => v.display)
              .map((item, index) => (
                <div key={index} className="flex justify-between">
                  <p className="font-size-20px font-Poppins-Medium text-black">
                    {item?.title}
                  </p>
                  {isLoader ? (
                    <Skeleton variant="rectangular" width={120} height={20} />
                  ) : (
                    <button

                      onClick={() => setShowToogle(item.key)}
                      className="font-size-20px font-Poppins-Medium text-black"
                    >
                      {allPrivacy[item.key as keyof IPrivacySettings] === 0
                        ? "Nobody"
                        : allPrivacy[item.key as keyof IPrivacySettings] === 1
                          ? "My connections"
                          : allPrivacy[item.key as keyof IPrivacySettings] === 2
                            ? "Everyone"
                            : ""}
                      <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                    </button>
                  )}
                </div>
              ))}
          </div>
          <div className="shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex gap-5 flex-col">
            {dummyArr.slice(5, 9).map((item, index) => (
              <div key={index} className="flex justify-between">
                <p className="font-size-20px font-Poppins-Medium text-black">
                  {item?.title}
                </p>
                {isLoader ? (
                  <Skeleton variant="rectangular" width={120} height={20} />
                ) : (
                  <button
                    onClick={() => setShowToogle(item.key)}
                    className="font-size-20px font-Poppins-Medium text-black"
                  >
                    {allPrivacy[item.key as keyof IPrivacySettings] === 0
                      ? "Nobody"
                      : allPrivacy[item.key as keyof IPrivacySettings] === 1
                        ? "My Connections"
                        : allPrivacy[item.key as keyof IPrivacySettings] === 2
                          ? "Everyone"
                          : ""}
                    <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                  </button>
                )}
              </div>
            ))}
          </div>
          {/* block user */}
          <div className="shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex gap-5 flex-col">
            <div className="flex items-center justify-between">
              <p className="font-size-20px font-Poppins-Medium text-black">
                Blocked users
              </p>
              {isLoader ? (
                <Skeleton variant="rectangular" width={120} height={20} />
              ) : (
                <button
                  onClick={showBlockedUserList}
                  disabled={!blockUserCount}
                  className="font-size-20px font-Poppins-Medium text-black"
                >
                  <span>{blockUserCount || 0}</span> Users
                  <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                </button>
              )}
            </div>
          </div>
          {/* deactivate the account */}
          <div className="de-activate-account-sett shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex gap-5 flex-col">
            <h3 className="font-size-20px font-Poppins-Medium text-black">
              Deactivate account
            </h3>
            <p className="font-size-20px font-Poppins-Medium text-black">
              To begin the process of deactivating your account, please click the button below. A confirmation step will be
              required to proceed.
            </p>
            <button className="font-size-18px font-Poppins-Medium" onClick={() => setDeactivateAccount(true)}>
              Deactivate account
            </button>
          </div>
          <div className="delete-account-sett shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex gap-5 flex-col">
            <h3 className="font-size-20px font-Poppins-Medium text-black">
              Delete account
            </h3>
            <p className="font-size-20px font-Poppins-Medium text-black">
            To begin the process of permanently deleting your account, please click the button below. A confirmation step 
            will be required to proceed.
            </p>
            <button className="font-size-18px font-Poppins-Medium" onClick={() => setIsDeactivateModal(true)}>
              Delete account
            </button>
          </div>
          {/* deactivate the account */}
        </>
      )}
      {showToggle && (
        <>
          <div className="flex justify-end relative">
            <img
              onClick={() => setShowToogle(false)}
              className="mb-2 cursor-pointer back-btn-privacy"
              src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
              alt=""
            />
          </div>
          <div className="bg-white rounded flex py-3 px-6 flex-col gap-2 relative">

            <div className="">
              <p className="font-size-20px font-Poppins-Medium text-black flex items-center">
                {isLoader ? (
                  <Skeleton
                    variant="rectangular"
                    className="mb-3"
                    width={120}
                    height={20}
                  />
                ) : (
                  <>
                    <input
                      type="radio"
                      name="same"
                      className="theme-green-accent-color mr-4 h-10 w-5 cursor-pointer"
                      checked={allPrivacy[showToggle as keyof IPrivacySettings] === 2}
                      onChange={() => handleChange(showToggle, 2)}
                    />
                    Everyone
                  </>
                )}
              </p>{" "}
              {/* EVERY ONE key is 2 */}
              <p className="font-size-20px font-Poppins-Medium text-black flex items-center">
                {isLoader ? (
                  <Skeleton
                    variant="rectangular"
                    className="mb-3"
                    width={120}
                    height={20}
                  />
                ) : (
                  <>
                    <input
                      type="radio"
                      name="same"
                      className="theme-green-accent-color mr-4 h-10 w-5 cursor-pointer"
                      checked={allPrivacy[showToggle as keyof IPrivacySettings] === 1}
                      onChange={() => handleChange(showToggle, 1)}
                    />
                    My connections
                  </>
                )}
              </p>{" "}
              {/* MY CONNECTION key is 1 */}
              <p className="font-size-20px font-Poppins-Medium text-black flex items-center">
                {isLoader ? (
                  <Skeleton variant="rectangular" width={120} height={20} />
                ) : (
                  <>
                    <input
                      type="radio"
                      name="same"
                      className="theme-green-accent-color mr-4 h-10 w-5 cursor-pointer"
                      checked={allPrivacy[showToggle as keyof IPrivacySettings] === 0}
                      onChange={() => handleChange(showToggle, 0)}
                    />
                    Nobody
                  </>
                )}
              </p>{" "}
              {/* NOBODY key is 0 */}
            </div>
          </div>
        </>
      )}
      {/* block user pending */}
      {blockUserShow && (
        <>
          {/* <div className="flex justify-end">
            <img
              onClick={() => setBlockUsersShow(false)}
              className="mb-2 cursor-pointer "
              src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
              alt=""
            />
          </div> */}
          <div className="flex justify-end relative">
            <img
              onClick={() => setBlockUsersShow(false)}
              className="mb-2 cursor-pointer back-btn-privacy"
              src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
              alt=""
            />
          </div>
          <div className="bg-white rounded flex py-3 px-6 flex-col gap-2">
            <div className="connection-except-content-parent flex flex-col gap-6">
              {blockListLoader ? (
                <>
                  <Skeleton
                    variant="rounded"
                    className="w-full mb-2"
                    height={50}
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-full mb-2"
                    height={50}
                  />
                </>
              ) : blockList && blockList.length > 0 ? (
                blockList.map((item, index) => (
                  <div
                    key={index}
                    className="flex items-center justify-between"
                  >
                    <div className="flex items-center gap-3">
                      <img
                        className="connection-except-img"
                        src={`${item?.profileImageUrl}`}
                        // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                        alt=""
                      />
                      <div className="flex flex-col gap-1">
                        <span className="font-Poppins-SemiBold font-size-20px leading-none theme-color-green capitalize">
                          {/* {`${item?.firstName || ""} ${item?.lasstName || ""}`} */}
                          {item?.fullName || ""}
                        </span>
                        <span className="font-Poppins-Regular font-size-15px leading-none text-black capitalize">
                          {item?.roleCategory || ""}
                        </span>
                      </div>
                    </div>
                    <button
                      className="font-size-18px theme-color-green font-Poppins-Medium feeds-btn  flex items-center gap-1 hover:text-white hover:bg-[#00443F] cursor-pointer btn-w"
                      disabled={!!blockLoader}
                      onClick={() => handleUnBlock(item?.blockedUserId)}
                    >
                      Unblock{" "}
                      {blockLoader === item?._id && (
                        <CircularProgress
                          style={{
                            color: "#fff",
                            width: 18,
                            height: 18,
                            marginLeft: 6,
                          }}
                        />
                      )}
                    </button>
                  </div>
                ))
              ) : (
                <p>No Block User</p>
              )}

              <PaginationComponet
                currentPage={queryParams.page}
                total={totalPages}
                setCurrentPage={(e) =>
                  setQueryParams({ ...queryParams, page: e })
                }
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default PrivacyTab;