// import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// // Define the types for the state and actions
// interface UserProfileState {
//     updateProfile: boolean;
//     publicData: object | { userData: string };
//     publicServices: Array<string>;
//     personalData: PersonalDataType;
//     personalServices: Array<string>;
//     profileCompletionPercentage: number;
//     connectionStatus: boolean;
//     subscriptionDetails: object;
//     [key: string]: any;
// }

// interface PersonalDataType {
//     userData?: {
//         firstName?: string;
//         lastName?: string;
//         providerCategory?: string;
//         profileSummary?: string;
//     };
//     role?: string;
//     [key: string]: any;
// }

// interface SetPersonalProfilePayload {
//     userProfile: PersonalDataType | PersonalDataType[];
//     services: Array<string>;
//     profileCompletionPercentage: number;
//     subscriptionDetails: object;
//     [key: string]: any;
// }

// interface SetPublicProfilePayload {
//     profile: object | { userData: string };
//     services: Array<string>;
//     connectionStatus: boolean;
//     [key: string]: any;
// }

// const initialState: UserProfileState = {
//     updateProfile: false,
//     publicData: {},
//     publicServices: [],
//     personalData: {},
//     personalServices: [],
//     profileCompletionPercentage: 0,
//     connectionStatus: false,
//     subscriptionDetails: {},
// };
// const userProfileSlice = createSlice({
//     name: 'USER_PROFILE',
//     initialState,
//     reducers: {
//         updateProfile(state) {
//             state.updateProfile = !state.updateProfile;
//         },
//         setPersonalProfile(state, action: PayloadAction<SetPersonalProfilePayload>) {
//             const { userProfile, services, profileCompletionPercentage, subscriptionDetails } = action.payload;
//             state.personalData = userProfile;
//             state.subscriptionDetails = subscriptionDetails;
//             state.personalServices = services;
//             state.profileCompletionPercentage = profileCompletionPercentage;
//         },
//         setPublicProfile(state, action: PayloadAction<SetPublicProfilePayload>) {
//             const { profile, services, connectionStatus } = action.payload;
//             state.publicData = profile;
//             state.publicServices = services;
//             state.connectionStatus = connectionStatus;
//         },
//         removePublicProfile(state) {
//             state.publicData = {};
//             state.publicServices = [];
//             state.connectionStatus = false;
//         },
//         removeAndLogOut(state) {
//             state.publicData = {};
//             state.personalData = {};
//             state.subscriptionDetails = {};
//             state.publicServices = [];
//             state.connectionStatus = false;
//         },
//         updateSubscription(state, action: PayloadAction<object>) {
//             state.subscriptionDetails = action.payload;
//         },
//         updateProfileCompletionPercentage(state, action: PayloadAction<number>) {
//             state.profileCompletionPercentage = action.payload;
//           },
//         userLogOut(state) {
//             state.updateProfile = false;
//             state.publicData = {};
//             state.publicServices = [];
//             state.personalData = {};
//             state.personalServices = [];
//             state.profileCompletionPercentage = 0;
//             state.connectionStatus = false;
//             state.subscriptionDetails = {};
//         },
//     },
// });

// export const { updateProfile, setPublicProfile, removePublicProfile, setPersonalProfile, removeAndLogOut, updateSubscription, userLogOut } = userProfileSlice.actions;
// export default userProfileSlice.reducer;
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// Define the types for the state and actions
interface UserProfileState {
    updateProfile: boolean;
    publicData: PersonalDataType;
    publicServices: Array<string>;
    personalData: PersonalDataType;
    personalServices: Array<string>;
    profileCompletionPercentage: number;
    connectionStatus: string;
    subscriptionDetails: SubscriptionDetails;
    [key: string]: any;
}
export interface SubscriptionDetails {
    cancel_at?: number;
    cancel_at_period_end?: boolean;
    cancellation_details?: {
        comment?: string;
        feedback?: string;
        reason?: string;
    };
    canceled_at?: number;
    collection_method?: string;
    created?: number;
    currency?: string;
    current_period_end?: number;
    current_period_start?: number;
    days_until_due?: number | null;
    status?: string;
    start_date?: number;
    metadata?: {
        customerEmail?: string;
        fullName?: string;
        offerId?: string;
        price?: string;
        title?: string;
        userId?: string;
    };

    [key: string]: any;
}
export interface PersonalDataType {
    userData?: {
        firstName?: string;
        lastName?: string;
        roleCategory?: string;
        dateofbirth?: string;
        gender?: string;
        phoneNumber?: string;
        address?: string;
        city?: string;
        state?: string;
        postCode?: string;
        profileSummary?: string;
        linkedinUrl?: string;
        facebookUrl?: string;
        instagramUrl?: string;
        whatsappUrl?: string;
    };
    userId?: string;
    role?: string;
    email?: string;
    status?: string;
    isVerified?: boolean;
    profileVerified?: boolean;
    freeUser?: boolean;
    address?: string;
    postCode?: string;
    state?: string;
    city?: string;
    phonenumber?: string;
    roleCategory?: string;
    personalitySmoker?: string | null;
    cancelledJobs?: number;
    completedJobs?: number;
    totalReviews?: number;
    totalRatings?: number;
    jobPosted?: number;
    createdAt?: string;
    fullName?: string;
    [key: string]: any;
}
interface IApiResponse {
    documents: PersonalDataType;
    services: Array<string>;
    profileCompletionPercentage: number;
    subscriptionDetails: object;
}

export interface SetPersonalProfilePayload {
    userProfile: PersonalDataType;
    services: Array<string>;
    profileCompletionPercentage: number;
    subscriptionDetails: object;
    [key: string]: any;
}
export interface PublicDataType {
    _id?: string;
    role?: string;


    coverImageUrl?: string;
    profileImage?: string;
    firstName?: string;
    lastName?: string;
    providerCategory?: string;
    profileSummary?: string;

    verification?: string;
    freeUser?: boolean;
    profileVerified?: boolean;
    totalRatings?: number;
    totalReviews?: number;
    connectionsLength?: number;
    cancelledJobs?: number;
    completedJobs?: number;
    [key: string]: any; // Other properties as needed
}
interface SetPublicProfilePayload {
    profile: PublicDataType;
    services: Array<string>;
    connectionStatus: string;
    [key: string]: any;
}
const initialState: UserProfileState = {
    updateProfile: false,
    publicData: {}, // initially an empty object, will be updated to PublicDataType
    publicServices: [],
    personalData: {},
    personalServices: [],
    profileCompletionPercentage: 0,
    connectionStatus: "default",
    subscriptionDetails: {} as SubscriptionDetails, // Type subscriptionDetails correctly
}

const userProfileSlice = createSlice({
    name: 'USER_PROFILE',
    initialState,
    reducers: {
        updateProfile(state) {
            state.updateProfile = !state.updateProfile;
        },
        setPersonalProfile(state, action: PayloadAction<SetPersonalProfilePayload>) {
            const { userProfile, services, profileCompletionPercentage, subscriptionDetails } = action.payload;
            state.personalData = userProfile;
            state.subscriptionDetails = subscriptionDetails;
            state.personalServices = services;
            // state.profileCompletionPercentage = profileCompletionPercentage;
            state.profileCompletionPercentage = profileCompletionPercentage ?? state.profileCompletionPercentage;

        },
        // setPublicProfile(state, action: PayloadAction<SetPublicProfilePayload>) {
        //     const { profile, services, connectionStatus } = action.payload;
        //     state.publicData = profile;
        //     state.publicServices = services;
        //     state.connectionStatus = connectionStatus;
        // },
        setPublicProfile(state, action: PayloadAction<SetPublicProfilePayload>) {
            state.publicData = action.payload.profile; // Ensure this is correctly set
            state.publicServices = action.payload.services;
            state.connectionStatus = action.payload.connectionStatus;
        },

        removePublicProfile(state) {
            state.publicData = {};
            state.publicServices = [];
            state.connectionStatus = "default";
        },
        removeAndLogOut(state) {
            state.publicData = {};
            state.personalData = {};
            state.subscriptionDetails = {} as SubscriptionDetails;
            state.publicServices = [];
            state.connectionStatus = "default";
        },
        updateSubscription(state, action: PayloadAction<SubscriptionDetails>) { // Adjusted type
            state.subscriptionDetails = action.payload;
        },
        userLogOut(state) {
            state.updateProfile = false;
            state.publicData = {};
            state.publicServices = [];
            state.personalData = {};
            state.personalServices = [];
            state.profileCompletionPercentage = 0;
            state.connectionStatus = "default";
            state.subscriptionDetails = {} as SubscriptionDetails;
        },
        updateProfileCompletionPercentage(state, action: PayloadAction<number>) {
            state.profileCompletionPercentage = action.payload;
        },
    },
});

export const {
    updateProfile,
    setPublicProfile,
    removePublicProfile,
    setPersonalProfile,
    removeAndLogOut,
    updateSubscription,
    userLogOut,
    updateProfileCompletionPercentage
} = userProfileSlice.actions;

export default userProfileSlice.reducer;


