/**
 * AllJobs Component
 * Component to manage and display job requests for the admin panel.
 * Provides filtering options for job status, type, and services.
 * File: AllJobs.js
 * Author: Developer
 * Date: 25-04-24
 */

import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import JobPreview from "../../components/AdminComponents/JobPreview";
import JobRequestCards from "../../components/AdminComponents/JobRequestCards";

import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { setAllServices } from "../../config/Store/Reducers/allServicesSlice";
import Skeleton from "@mui/material/Skeleton";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import qs from "qs";
import httpRequest from "../../config/Helpers/httpRequest";
import { RootState } from "../../config/Store/store";
import { Job } from "../../components/AdminComponents/JobRequestCards";
interface Service {
  _id: string;
  servicename?: string;
  documents?: Service[];
}

interface QueryParams {
  status: string;
  jobType: string;
  page: number;
  limit: number;
  serviceIds: string[];
}

// interface Job {
//   _id: string;
//   servicename?: string;
//   [key: string]: any;
// }
interface ApiResponseServices {
  documents: [];
}
interface documents {
  _id: string;
  contactNumber: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  status: string;
  startDate: string;
  endDate: string;
  budget: string;
  description: string;
  serviceName: string;
  fullName: string;
  [key: string]: any
}
interface ApiResponse {
  // Correctly type `res` as an array of `Job` objects
  documents: Job[];
  services: Service[]
  paginated: paginated
}
interface paginated {
  totalPages: number;
  totalItems: number
  [key: string]: number;
}
function AllJobs() {
  // State to manage the visibility of the services dropdown
  const [allServicesDropDown, setAllServicesDropDown] = useState<boolean>(false);
  const [showSocial, setShowSocial] = useState<boolean>(false);

  // Toggle the visibility of the services dropdown
  const toggleIcon = () => {
    setAllServicesDropDown(!allServicesDropDown);
    setShowSocial(false); // Hide social div when clicking admin job req inner
  };

  // Toggle the visibility of the social section
  const toggleSocial = () => {
    setShowSocial(!showSocial);
  };

  const dispatch = useDispatch();
  const { ALLSERVICES } = useSelector((store: RootState) => store.allServices);

  const [totalPages, setTotalPages] = useState<number>(1);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [servicesLoader, setServicesLoader] = useState<boolean>(false);
  const [jobs, setJobs] = useState<Job[]>([]);
  const [selectedJobs, setSelectedJobs] = useState<Job | null>(null);
  const [bool, setBool] = useState<boolean>(false);

  // State for query parameters used in API calls
  const [queryParams, setQueryParams] = useState<QueryParams>({
    status: "",
    jobType: "",
    page: 1,
    limit: 6,
    serviceIds: [],
  });

  // Handle changes in the selected services for filtering
  const handleServiceChange = (item: Service) => {
    let { _id } = item;
    const prevServices = queryParams.serviceIds || [];
    const exists = prevServices.includes(_id);
    if (exists) {
      // Remove the item if it exists
      const updatedServices = prevServices.filter((v) => v !== _id);
      setQueryParams({
        ...queryParams,
        page: 1,
        serviceIds: updatedServices
      });
    } else {
      // Add the item if it doesn't exist
      setQueryParams({
        ...queryParams,
        page: 1,
        serviceIds: [...prevServices, _id],
      });
    }
  };

  // Functionality: API call for get all Jobs (pending, approved & declined)
  const fetch = async () => {
    setIsLoader(true);
    const queryString = qs.stringify(queryParams, { arrayFormat: "comma" });
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/job?${queryString}` });
    if (res && res?.documents) {
      setJobs(res?.documents || []);




      // const count = res?.jobs[0]?.metadata[0]?.total || 0;
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };


  useEffect(() => {
    (async () => {
      setServicesLoader(true);
      const { res, err }: { res?: ApiResponseServices; err?: string } = await httpRequest({
        path: "/api/services/all",
      });


      if (res && res?.documents) {
        dispatch(setAllServices(res?.documents) || []); // Directly dispatch documents without flattening for now
      } else {
        toast(err || "An error occurred", { type: "error" });
      }

      setServicesLoader(false);
    })();
  }, []);


  useEffect(() => {
    fetch();
  }, [queryParams, bool]);

  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          <AdminHeader />
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <h2 className="font-size-36px font-Poppins-Medium">
                Job requests
              </h2>
              <div className="Main-AdminUsers Main-Admin-paidUsers operator-main-admin mt-6 flex justify-between items-center">
                <div className="flex">
                  <div className="admin-job-req-inner" onClick={toggleIcon}>
                    <p className="font-size-15px font-Poppins-Medium">
                      Services
                    </p>{" "}
                    <FontAwesomeIcon
                      icon={allServicesDropDown ? faChevronUp : faChevronDown}
                    />
                  </div>
                  <div className="select-inner-text relative">
                    <span>Job type</span>
                    <select
                      className="border-2 p-3 Main-AdminUsers font-Poppins-Medium mr-10 font-size-15px"
                      // placeholder="User Type"
                      value={queryParams.jobType}
                      onChange={(e) =>
                        setQueryParams({
                          ...queryParams,
                          page: 1,
                          jobType: e.target.value,
                        })
                      }
                    >
                      <option value="">All</option>
                      <option value="1">One Off</option>
                      <option value="2">Daily</option>
                      <option value="3">Weekly</option>
                      <option value="4">Monthly</option>
                      <option value="5">Custom</option>
                    </select>
                  </div>
                  <div className="select-inner-text relative">
                    <span>Job status</span>
                    <select
                      className="border-2 p-3 Main-AdminUsers font-Poppins-Medium mr-10 font-size-15px"
                      // placeholder="User Type"
                      value={queryParams.status}
                      onChange={(e) =>
                        setQueryParams({
                          ...queryParams,
                          page: 1,
                          status: e.target.value,
                        })
                      }
                    >
                      <option value="">Select job status</option>
                      <option value="pending">Pending</option>
                      <option value="active">Approved</option>
                      <option value="declined">Declined</option>
                    </select>
                  </div>
                </div>
              </div>
              {allServicesDropDown ? (
                servicesLoader ? (
                  Array.from({ length: 6 }).map((_, index) => (
                    <Skeleton
                      key={index}
                      variant="rounded"
                      width={210}
                      height={60}
                    />
                  ))
                ) : ALLSERVICES && ALLSERVICES.length > 0 ? (
                  <div
                    className="admin-Service-drop-down text-[#646464]"
                    onClick={toggleSocial}
                  >
                    <div className="socail-comunity-access-jobs">
                      <div className="socail-comunity-access-content service-box-1">
                        {ALLSERVICES.map((item, index) => (
                          <div key={index}>
                            <h2 className="font-size-15px font-Poppins-SemiBold ">
                              {item?._id || ""}
                            </h2>
                            {item?.documents?.map((v, i) => (
                              <div key={i} className="admin-Social-Support">
                                <input
                                  type="checkbox"
                                  id={`skill_${v?._id}`}
                                  checked={queryParams.serviceIds.includes(
                                    v?._id
                                  )}
                                  onChange={() => handleServiceChange(v)}
                                />
                                <label
                                  className="font-size-15px font-Poppins-Regular"
                                  htmlFor=""
                                >
                                  {v?.servicename || ""}
                                </label>
                              </div>
                            ))}
                            {/* <div className="admin-social-border"></div> */}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <p>No services</p>
                )
              ) : (
                ""
              )}
              <div className="mt-8">
                <div className="admin-job-req">
                  <div className=" admin-support-worker-boxes">
                    <JobRequestCards
                      data={jobs}
                      loader={isLoader}
                      setSelectedJobs={setSelectedJobs}
                      setBool={setBool}
                    />
                    <div className="pb-2">
                      <PaginationComponet

                        currentPage={queryParams.page}
                        total={totalPages}
                        setCurrentPage={(e) =>
                          setQueryParams({ ...queryParams, page: e })
                        }
                      />
                    </div>
                  </div>
                  <div className="admin-job-preview">
                    <h2 className="font-Poppins-Medium font-size-25px">
                      Job preview
                    </h2>
                    {selectedJobs ? (
                      <JobPreview item={selectedJobs} />
                    ) : (
                      <div
                        style={{
                          backgroundColor: "#eeeeee",
                          width: "100%",
                          marginTop: 10,
                          borderRadius: 10,
                          height: 500,
                        }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllJobs;