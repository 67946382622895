// import React from "react";

// const TextareaField = React.forwardRef(
//   (
//     {
//       labelTop = "",
//       label = "",
//       error = "",
//       helperText = "",
//       icon,
//       fullWidth = true,
//       value: propsValue,
//       onChange: propsOnChange,
//       rows = 3,
//       currentLength,
//       isLength,
//       ...props
//     },
//     ref
//   ) => {
//     const [stateValue, setStateValue] = React.useState("");
//     const value = propsValue !== undefined ? propsValue : stateValue;
//     const _id = `myTextArea`;
//     const onChange = (event) => {
//       if (propsOnChange) {
//         propsOnChange(event);
//       } else {
//         setStateValue(event.target.value);
//       }
//     };
//     const printError = () => {
//       if (error !== "") {
//         return <span style={{ color: "red", fontSize: "12px" }}>{error}</span>;
//       }
//     };
//     const printHelperText = () => {
//       if (helperText !== "") {
//         return (
//           <span style={{ color: "#6C6A6A", fontWeight: 500, fontSize: "12px" }}>
//             {helperText}
//             {icon}
//           </span>
//         );
//       }
//     };
//     return (
//       <div className={`custom-textarea pb-2`}>
//         {labelTop && (
//           <label htmlFor={_id} className="form-control font-size-15px font-Poppins-Medium">
//             {labelTop}
//           </label>
//         )}
//         <textarea
//           ref={ref}
//           id={_id}
//           className="form-control w-full mt-1"
//           autoComplete="off"
//           value={value}
//           onChange={onChange}
//           rows={rows}
//           {...props}
//         />
//         {isLength && <p className="font-size-15px font-Poppins-Medium text-end">{`${currentLength}/${isLength}`}</p>}
//         {printHelperText()}
//         {printError()}
//       </div>
//     );
//   }
// );

// export default TextareaField;

import React, { ChangeEvent, forwardRef ,memo } from "react";

interface TextareaFieldProps {
  labelTop?: string;
  label?: string;
  error?: string;
  helperText?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  rows?: number;
  currentLength?: number;
  isLength?: number;
  [key: string]: any;
}

const TextareaField = forwardRef<HTMLTextAreaElement, TextareaFieldProps>(
  (
    {
      labelTop = "",
      label = "",
      error = "",
      helperText = "",
      icon,
      fullWidth = true,
      value: propsValue,
      onChange: propsOnChange,
      rows = 3,
      currentLength,
      isLength,
      ...props
    },
    ref
  ) => {
    const [stateValue, setStateValue] = React.useState<string>("");
    const value = propsValue !== undefined ? propsValue : stateValue;
    const _id = `myTextArea`;

    const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      if (propsOnChange) {
        propsOnChange(event);
      } else {
        setStateValue(event.target.value);
      }
    };

    const printError = () => {
      if (error !== "") {
        return <span style={{ color: "red", fontSize: "12px" }}>{error}</span>;
      }
    };

    const printHelperText = () => {
      if (helperText !== "") {
        return (
          <span style={{ color: "#6C6A6A", fontWeight: 500, fontSize: "12px" }}>
            {helperText}
            {icon}
          </span>
        );
      }
    };

    return (
      <div className={`custom-textarea pb-2 relative`}>
        {labelTop && (
          <label htmlFor={_id} className={`form-control font-size-15px font-Poppins-Medium ${labelTop === "You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences." ? "text-[#004540]" : ""}`}>
            {labelTop}
          </label>
        )}
        <textarea
          ref={ref}
          id={_id}
          className="form-control w-full mt-1"
          autoComplete="off"
          value={value}
          onChange={onChange}
          rows={rows}
          {...props}
        />
        {isLength && (
          <p className="font-size-15px font-Poppins-Medium text-end text-[#707070] absolute right-4 bottom-4">{`${currentLength}/${isLength}`}</p>
        )}
        {printHelperText()}
        {printError()}
      </div>
    );
  }
);

export default TextareaField;
