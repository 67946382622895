import React, { useRef, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faEllipsis } from "@fortawesome/free-solid-svg-icons";
import arrowback from "../../assets/img/arrowback.svg";
import { Link } from "react-router-dom";
import { postTimeDifference } from "../../config/Helpers/helpers";
import ReportICon from "../../assets/img/report-icon.svg"

import { getRole } from "../../config/Helpers/helpers";
import DisplayComments from "../PostCommentSection/DisplayComments";
import AddComment from "../PostCommentSection/AddComment";
import CreateDiscussionModal from "./CreateDiscussionModal";
import { useSelector, useDispatch } from "react-redux";
import { addNewComment } from "../../config/Store/Reducers/DiscussionSlice";
import PostDelete from "../ReportComponents/PostDelete";
function Discussionpost({ dId = "" }) {
  const postMenus = useRef(null); // Reference for post menu

  const dispatch = useDispatch();
  const { data = {} } = useSelector(store => store.discussion);
  let userId = getRole()?.userId || "";
  console.log(data, "datadatadata")
  const [editModal, setEditModal] = useState(false);
  const [allComments, setAllComments] = useState([]); // for main comment, parent comment
  const [feedControlMenu, setFeedControlMenu] = useState(""); // State for controlling post menu
  const [deletePost, setDeletePost] = useState(false);

  const handleClickOutside = (event) => {

    if (postMenus.current && !postMenus?.current?.contains(event.target)) {
      setFeedControlMenu("");
    }
  }; // click the outside of post menu, menu box is hide

  useEffect(() => {
    // Add event listener to detect clicks outside the div
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // for the refercen of post menu

  const handleAddNewComment = (event) => {
    setAllComments(prev => ([event, ...prev]));
    dispatch(addNewComment());
  };
  const handleDeletePost = (id) => {
    setDeletePost(id);
  };
  const [discussionDelete, setDiscussionDelete] = useState(false);

  return (
    <div className="bg-white rounded flex p-5 flex-col gap-2">
      <div className="flex justify-between ">
        <div>
          <h4 className="font-size-30px font-Poppins-SemiBold theme-color-green capitalize">{data?.title || ""}</h4>
          <div className="flex flex-row gap-2">
            <span class="font-size-15px font-Poppins-Regular theme-grey-type">
              {postTimeDifference(data?.createdAt)}  ago. {" "}
            </span>
            <span class="font-size-15px font-Poppins-Regular theme-grey-type">
              {data?.allComments || 0} Replies
            </span>


          </div>
        </div>
        <div className="flex flex-row items-center gap-4 relative">
          {(data?.userId === userId) &&
            <div className="flex gap-3 items-center">
              <div onClick={() => setEditModal(true)}>
                <FontAwesomeIcon
                  icon={faPen}
                  className="cursor-pointer font-size-25px"
                  color="#707070"
                />
              </div>
              <div>
                <FontAwesomeIcon
                  icon={faEllipsis}
                  onClick={() => setFeedControlMenu(data?._id)}
                />
              </div>
              <div>
                {feedControlMenu === data?._id && (
                  <div
                    className="shadow-lg bg-white rounded-xl absolute right-3 top-10 w-80"
                    ref={postMenus}
                  >
                    <ul className="py-3 ">


                      {/* {item?.userId === userId && */}
                      <li className="mt-1 font-size-15px theme-grey-type  font-Poppins-SemiBold report-post-feed on-hover-underline px-3 py-1  hover-dash-btn">
                        <button
                          onClick={() => handleDeletePost(data?._id)}
                          className="flex gap-1 items-center"
                        >
                          {/* <FontAwesomeIcon icon={faFlagCheckered} /> */}
                          <img src={ReportICon} alt="report-icon" />
                          <span>Delete discussion</span>     </button>

                      </li>
                      {/* } */}
                    </ul>
                  </div>
                )}
              </div>
              {/* <Link to="/discussions">
              <img class="cursor-pointer notifi-back h-[3.2vh]" src={arrowback} alt="" width={25} />
              </Link> */}
            </div>
          }
          <Link to="/discussions">
            <img class="cursor-pointer notifi-back h-[3vh]" src={arrowback} alt="" width={25} />
          </Link>
        </div>
      </div>
      <div>
        <p className="font-size-17px theme-grey-type font-Poppins-Regular">{data?.description || ""}</p>
      </div>

      <AddComment
        refId={dId}
        successFunction={handleAddNewComment}
        feedType="discussion"
      />
      <DisplayComments
        refId={dId}
        feedType="discussion"
        allComments={allComments}
        setAllComments={setAllComments}
        detailPage={true}
      />
      <CreateDiscussionModal
        isOpen={editModal}
        onClose={() => setEditModal(false)}
        isEdit={{ title: data?.title, description: data?.description, dId: dId }}
      />
        {deletePost && (
          <PostDelete
            id={deletePost}  // Convert to boolean
            onClose={() => setDeletePost(false)}
            type="discussion"
            category="discussion"
          />
        )}
    </div>
  );
};

export default Discussionpost;