/**
 * Component: ActionsReports
 * Purpose: Provides actions for reporting and handling compliance of posts.
 * File Name: ActionsReports.jsx
 * Author: Developer
 * Date: 12-07-24
 */

import React, { useState } from 'react';
import httpRequest from '../../../config/Helpers/httpRequest';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import closeIcon from "../../../assets/img/closeIcon.svg";
import { useNavigate, useLocation } from 'react-router-dom';
interface ActionsReportsProps {
    rid?: string | undefined; // Marked as optional because of default value
  }

  const ActionsReports: React.FC<ActionsReportsProps> = ({ rid = "" }) => {
    const location = useLocation();

    const searchParams = new URLSearchParams(location.search);
    const ReportStatus = searchParams.get('status')
    console.log(ReportStatus, "ReportStatusReportStatusReportStatus")
    const navigate = useNavigate();
    let options: string[] = ['harassment', 'fraud or scam', 'spam', 'misinformation', 'hateful speech', 'threats or violence', 'self-harm', 'graphic content', 'sexual content', 'fake account', 'hacked account', 'child exploitation', 'illegal services', 'infringement'
    ]; // Array of options corresponding to backend enum for reporting reasons

    const activeClass = {
        backgroundColor: "#004540",
        color: "#fff"
    }; // Styles for active state of selected options

    // Styles for action buttons
    const greenBtn: React.CSSProperties = {
        backgroundColor: "#004540",
        padding: "4px 20px",
        fontSize: "15px",
        color: "#fff",
        borderRadius: "4px",
    };

    const redBtn: React.CSSProperties = {
        backgroundColor: "red",
        padding: "4px 20px",
        fontSize: "15px",
        color: "#fff",
        borderRadius: "4px",
    };

    const [complianceLoader, setComplianceLoader] = useState<boolean>(false);
    const [againsLoader, setAgainstLoader] = useState<boolean>(false);
    const [optionsModal, setOptionsModal] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>("");

    // Functionality: API call Marks the post as compliant
    const handleCompliance = async () => {
        setComplianceLoader(true);
        const { res, err } = await httpRequest({ method: "patch", path: `/api/admin/report/compliance-community`, params: { id: rid } });
        if (res) {
            toast("The post has been successfully marked as compliant.", { type: "success" });
            navigate(-1);
        } else {
            toast(err?.message, { type: "error" });
        }
        setComplianceLoader(false);
    };

    // Closes the report options modal
    const handleClose = () => {
        setOptionsModal(false);
        setSubject("");
    };

    // Functionality: API call Submits the report with selected reason
    const handleSubmit = async () => {
        if (!subject) {
            toast("Select a reason", { type: "error" });
        } else {
            setAgainstLoader(true);
            const { res, err } = await httpRequest({ method: "patch", path: `/api/admin/report/against-community`, params: { id: rid, subject } });
            if (res) {
                toast("Content has been removed.", { type: "success" });
                navigate(-1);
            } else {
                toast(err?.message, { type: "error" });
            }
            setAgainstLoader(false);
        }
    };

    return (
        <>
            {ReportStatus !== "resolved" &&
                <div className="flex justify-center gap-2 py-2">
                    <button className="loader-btn" onClick={handleCompliance} style={greenBtn}>Compliant {complianceLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}</button>
                    <button onClick={() => setOptionsModal(true)} style={redBtn}>Against</button>
                </div>
            }

            {optionsModal &&
                <div className="ovrlayModal modal">
                    <div className="createpost-modal absolute z-20">
                        <button
                            className="absolute -top-5 font-size-24px"
                            style={{ right: "-10px" }}
                            onClick={handleClose}
                            disabled={againsLoader}
                        >
                            <img src={closeIcon} alt="" />
                        </button>{" "}
                        {/* close post modal */}
                        <div className="mb-3">
                            <h1 className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                                Reports Options
                            </h1>
                            <hr className="mt-3" />
                        </div>
                        <p className="font-size-16px font-Poppins-Medium theme-color-green">
                            Select a reason{" "}
                        </p>
                        <div className="flex gap-2 mt-3 flex-wrap">
                            {options.map(v => (
                                <button key={v} onClick={() => setSubject(v)} className={`font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn capitalize`} style={subject === v ? activeClass : {}}>
                                    {v}
                                </button>
                            ))}
                        </div>
                        <hr className="mt-3" />
                        <div className="flex justify-end gap-2 mt-3">
                            <button
                                className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                                style={{ color: "#fff" }}
                                onClick={handleClose}
                                disabled={againsLoader}
                            >
                                Back
                            </button>
                            <button
                                className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                                style={{ color: "#fff" }}
                                onClick={handleSubmit}
                                disabled={againsLoader}
                            >
                                Submit report {againsLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}
                            </button>
                        </div>
                    </div>
                </div>
            }
        </>
    );
};

export default ActionsReports;