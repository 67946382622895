import React from "react";
import url from "../../config/url";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import LoginForms from "../../components/LoginComponents/LoginForms";
import Header from "../../components/Header";
function Logindashboard() {
  const BannerBgTexture = "imgs/banner-bg-texture.png";
  const BannerTopLogo = "imgs/banner-logo.png";
  const BannerBgMapImg = "imgs/banner-bg-map.png";

  return (
    <div>
      <section className="login-page-parent new-reg-parent relative h-screen  overflow-clip">
        <Header />
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10  bg-texture-banner"
          src={"https://ndisync.backslashwebs.com/new-register.jpg"}

          alt=""
        />
        <div className="container-custom h-full relative z-20 flex justify-center items-center">
          <div className="w-full flex flex-col justify-center items-center">
            <div className=" mb-4">
              <h4 className="font-Poppins-SemiBold font-size-login-36px text-white leading-none text-center lg-main-head ">
                <i> Welcome admin!</i>
              </h4>
              <h4 className="text-[#FFE175] font-Poppins-Regular font-size-login-29px leading-none text-center">
                Please enter your login details
              </h4>
            </div>
            <div className="login-box-body-parent login-box-body-parent-2 flex flex-col admin-login">


              <div className="flex flex-col gap-3">

                <LoginForms />

              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Logindashboard;
