/**
 * UserNotification Component
 * Manages user notifications with pagination, notification rendering, and API interaction for marking notifications as read.
 * File: UserNotification.jsx
 * Author: Developer
 * Date: 25-04-24
 */

import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setNotificationPage, resetUnreadNotifiation } from "../../../config/Store/Reducers/notificationsSlice";
import { toast } from 'react-toastify';
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../../config/Helpers/httpRequest";
import moment from "moment";
import { useNavigate } from "react-router-dom";
interface NotificationItem {
    notificationRoute: string;
    routeId: string;
    imageUrl?: string;
    title?: string;
    message?: string;
    createdAt: string;
}

// Define the shape of the Redux state slice
interface NotificationState {
    allNotifications: NotificationItem[];
    currentPage: number;
    totalPage: number;
    notificationLoader: boolean;
}

interface RootState {
    notification: NotificationState;
}
function UserNotification() {
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Initialize Redux dispatch function

    // Extract notification-related data from the Redux store
    const { allNotifications, currentPage, totalPage, notificationLoader } = useSelector((state: RootState) => state.notification);

    // Handle page change for pagination
    const handlePageChange = (_page: number) => {
        dispatch(setNotificationPage(_page));
    };

    // Function to determine the route based on notification category
    const navigateToRoute = (item: NotificationItem) => {
        let { notificationRoute, routeId } = item;
        // console.log(notificationRoute, "notificationCategorynotificationCategory")
        switch (notificationRoute) {
            case 'profile':
                return `/public-profile/${routeId}/view`;
            case 'news-feed':
                return `/feed-detail/${routeId}/view`;
            case 'discussion':
                return `/discussion/${routeId}/view`;
            case 'chat':
                return `/${routeId}/chat`;
            case 'job-opened':
                return `/jobs-board?opened`;
            case 'job-in-review':
                return `/jobs-board`;
            case 'job-application':
                return `/jobs-application/${routeId}/view-applicant`;
            case 'job-dispute':
                return `/dispute-jobs`;
            case 'job-detail':
                return `/job-details/${routeId}/view`;
            case 'ads':
                return `/ads-manager`;
            case 'chat':
                // return `${process.env.REACT_APP_CHATAPP}/${routeId}/chat`;
                return undefined;


            case 'profile-viewer':
                return "/profile-viewers"
            default:
                return "/notification";
        };
    };

    // Component to render individual notification items
    const RenderNotifications: React.FC<{ item: NotificationItem; index: number }> = ({ item, index }) => {
        const route = navigateToRoute(item);

        // If the route is for external domain (chat), use window.location.href instead of Link
        if (item.notificationRoute === 'chat') {
            return (
                <div
                    onClick={() => window.location.href = `${process.env.REACT_APP_CHATAPP}/${item.routeId}/chat`}
                    key={index}
                    className="noti-spec-main mb-4 shadow-lg"
                    style={{ cursor: 'pointer' }} // Ensure cursor indicates a clickable element
                >
                    <div className="noti-spec">
                        <img className="" src={`${item?.imageUrl}`} />
                        {/* <img className="" src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.imageUrl}`} /> */}
                        <div className="flex flex-col">
                            <h2 className="font-size-20px font-Poppins-SemiBold capitalize-first-letter">{item?.title || ""}</h2>
                            <p className="font-size-15px font-Poppins-Medium">{item?.message || ""}</p>
                        </div>
                    </div>
                    <div>
                        <span className="font-size-15px font-Poppins-Medium theme-grey-type">
                            {moment(item?.createdAt).isSame(moment(), 'day')
                                ? moment(item?.createdAt).format("hh:mm A")
                                : moment(item?.createdAt).format("DD-MM-YYYY hh:mm A")}
                        </span>
                    </div>
                </div>
            );
        }
        return (
            <Link to={route as string} key={index} className="noti-spec-main mb-4 shadow-lg ">
                <div className="noti-spec">
                    <img className="" src={`${item?.imageUrl}`} />
                    {/* <img className="" src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.imageUrl}`} /> */}
                    <div className="flex  flex-col ">
                        <h2 className="font-size-20px font-Poppins-SemiBold capitalize-first-letter ">{item?.title || ""}</h2>
                        <p className="font-size-15px font-Poppins-Medium  ">{item?.message || ""}</p>
                    </div>
                </div>
                {/* <div> <span className="font-size-15px font-Poppins-Medium theme-grey-type">{moment(item?.createdAt).format("hh:mm A") || ""}</span>   </div> */}
                <div>
                    <span className="font-size-15px font-Poppins-Medium theme-grey-type">
                        {moment(item?.createdAt).isSame(moment(), 'day')
                            ? moment(item?.createdAt).format("hh:mm A") // If today, show time only
                            : moment(item?.createdAt).format("DD-MM-YYYY hh:mm A") // If not today, show date and time
                        }
                    </span>
                </div>
            </Link>
        );
    };

    // Functionality: API call to mark notifications as read
    const fetchNotifications = async () => {
        const { res, err } = await httpRequest({ method: 'post', path: "/api/notifications/mark-as-read", params: {} });
        if (res) {
            dispatch(resetUnreadNotifiation());
        } else {
            toast(err?.message, { type: "error" })
        }
    };

    // Use useEffect to fetch notifications on component mount
    useEffect(() => {
        fetchNotifications();
    }, []);

    return (
        <div>
            <div className="font-poppins-regular">
                <HeaderGlobal />
                <div className="container-1480 h-3vw">
                    <div className=" Admin-main-operator-parent">
                        <div className="">
                            <div className="flex justify-between mb-6">
                                <h2 className="font-size-36px font-Poppins-Medium pl-2 lg:pl-0 ">
                                    Notifications
                                </h2>
                                <button
                                    // to="/feed"
                                    onClick={() => navigate(-1)}
                                >
                                    <img className=" cursor-pointer notifi-back " src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png" alt="" />
                                </button>
                            </div>
                            <div className="">
                                <div className="noti-scroll">
                                    {notificationLoader &&
                                        <div className="text-center">
                                            <CircularProgress style={{ color: "#004440" }} />
                                        </div>
                                    }
                                    {allNotifications && allNotifications.length > 0
                                        ?
                                        allNotifications.map((item, index) => (
                                            <RenderNotifications
                                                item={item}
                                                index={index}
                                            />
                                        ))
                                        :
                                        <p>No Notifications</p>
                                    }
                                </div>
                            </div>
                            <PaginationComponet
                                currentPage={currentPage}
                                total={totalPage}
                                setCurrentPage={handlePageChange}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserNotification;