import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faPowerOff,
  faExclamation,
  faFlagCheckered,
} from "@fortawesome/free-solid-svg-icons";

import dashbaordicon from "../../assets/img/Icon material-dashboard.png";
import adminOperatorsicon from "../../assets/img/Admin-operators.png";
import adminUsericon from "../../assets/img/Admin-user-icon.png";
import adminJobreqicon from "../../assets/img/Admin-job-req-icon.png";
import adminActivejobicon from "../../assets/img/Admin-active-job-req.png";
import Marketing from "../../assets/img/marketing.svg";
import adminServicesicon from "../../assets/img/Admin-Services-icon.png";
import adminShedularicon from "../../assets/img/Admin-Scheduler.png";
import adminlogo from "../../assets/img/ndisailogo.svg";
import adminManageticketicon from "../../assets/img/admin-manage-tickets.png";
import { useLocation } from "react-router-dom"; // Importing useLocation hook
import url from "../../config/url";
import { removeCookie, logOutWithOutApi, getCookie, getRole } from "../../config/Helpers/helpers";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
const AdminSidebar = () => {
  const dispatch = useDispatch();
  const NdrsLogo = "imgs/Ndrs-logo-sidebar.png";
  const location = useLocation();
  const [path, setPath] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const token = getCookie()
  const decoded = getRole()
  const navigate = useNavigate();
  const isAdminRole = decoded?.role;
  // useEffect(() => {
  //   if (!token) {
  //     navigate("/admin-login");
  //   } else if (decoded?.role === "provider" || decoded?.role === "participant") {
  //     navigate(-1);
  //   }
  // }, [token, decoded, navigate]);


  const handleLogOut = async () => {
    toast("Logout successfully", { type: "success" });

    navigate("/admin-login");

    const token = getCookie();

    await logOutWithOutApi();

    removeCookie()

    removeCookie(dispatch);
    // if (!token) {
    //   navigate("/admin-login");
    // }

  }

  // };
  // const handleLogOut = async () => {
  //   await logOutWithOutApi();
  //   const token = getCookie();
  //   if (!token) {
  //     navigate("/admin-login");
  //   }
  //   console.log(token, "getCookiegetCookie");
  // };
  // Update the isOpen state when location changes
  useEffect(() => {
    // Check if the current path contains the dropdown's path
    setIsOpen(
      location.pathname.includes("/admin-paid-users") ||
      location.pathname.includes("/admin-trial-users") ||
      location.pathname.includes("/admin-billing-detail")
    );
  }, [location.pathname]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <div className="sidebar-admin-content-parent fixed">
      <div className="Admin-sidebar-logo">
        <button className="Admin-sidebar-logo-inner">
          <img
            className="Admin-sidebarlogo"
            // src={url.awsPath + NdrsLogo}
            src={adminlogo}
            alt=""
          />
        </button>
      </div>
      <div className="Admin-sider-content">
        <ul>
          <li className={path === "/admin" ? "active" : ""}>
            <Link to="/admin">
              <span>
                <img src={dashbaordicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Dashboard
              </p>
            </Link>
          </li>
          <li className={path === "/admin-operators" ? "active" : ""}>
            <Link to="/admin-operators">
              <span>
                <img src={adminOperatorsicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Operators
              </p>
            </Link>
          </li>
          <li className="dropdown-content-admin">
            <button onClick={toggleDropdown} className="mb-0 flex flex-row items-center gap-2">
              <span>
                <img src={adminUsericon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Users
              </p>
              <FontAwesomeIcon
                className="text-white font-Poppins-Regular font-size-17px"
                icon={faChevronDown}
              />
            </button>
            {isOpen && (
              <div className="dropdown-content">
                <Link
                  to="/admin-trial-users"
                  className={
                    path === "/admin-trial-users"
                      ? "active2"
                      : "/admin-trial-users"
                  }
                >
                  Providers
                </Link>
                <Link
                  to="/admin-paid-users"
                  className={
                    path === "/admin-paid-users" ||
                      path === "/admin-billing-detail"
                      ? "active2"
                      : ""
                  }
                >
                  Participants
                </Link>
              </div>
            )}
          </li>
          <li className={path === "/all-jobs" ? "active" : ""}>
            <Link to="/all-jobs">
              <span>
                <img src={adminJobreqicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Job requests
              </p>
            </Link>
          </li>
          <li className={path === "/admin-active-jobs" ? "active" : ""}>
            <Link to="/admin-active-jobs">
              <span>
                <img src={adminActivejobicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Active jobs
              </p>
            </Link>
          </li>
          <li className={path === "/discussions-request" ? "active" : ""}>
            <Link to="/discussions-request">
              <span>
                <img src={adminManageticketicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Discussions
              </p>
            </Link>
          </li>
          <li className={path === "/disputes-job" ? "active" : ""}>
            <Link to="/disputes-job">
              <span>
                <FontAwesomeIcon
                  className="exclamation-mark"
                  icon={faExclamation}
                />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Disputes
              </p>
            </Link>
          </li>
          {/* <li>
            <Link >
              <span>
                <img src={adminServicesicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Services
              </p>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/scheduler">
              <span>
                <img src={adminShedularicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Scheduler
              </p>
            </Link>
          </li> */}
          {/* <li>
            <Link to="/Manage-Subcription">
              <span>
                <img src={adminManageticketicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Manage Subscriptions
              </p>
            </Link>
          </li> */}

          <li className={path === "/report" ? "active" : ""}>

            <Link to="/report">
              <span>
                <FontAwesomeIcon
                  className="text-white font-Poppins-Regular font-size-17px"
                  icon={faFlagCheckered}
                  style={{ fontSize: 18 }}
                />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Report
              </p>
            </Link>
          </li>

          <li className={path === "/all-ads" ? "active" : ""}>

            <Link to="/all-ads">
              <span>
                <img
                  className="text-white font-Poppins-Regular font-size-17px"
                  src={Marketing}
                  style={{ fontSize: 18 }}
                />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                ndisync Ads
              </p>
            </Link>
          </li>
          <li>
            <a target="_blank" href="https://dashboard.stripe.com/login">
              <span>
                <img src={adminManageticketicon} alt="" />
              </span>
              <p className="text-white font-Poppins-Regular font-size-18px">
                Manage subscriptions
              </p>
            </a>
          </li>
        </ul>
      </div>
      <div className="Admin-sidebar-logout-btn">
        <button onClick={handleLogOut}>
          <FontAwesomeIcon icon={faPowerOff} />
          <p className="font-Poppins-Bold font-size-18px">Logout</p>
        </button>
      </div>
    </div>
  );
};

export default AdminSidebar;
