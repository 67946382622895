import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import ServicesSearch from "../../../components/SearchComponents/ServicesSearch";
import SortBySearch from "../../../components/AllDashboardPagesComponents/SortBySearch";
import WeeklyDaySearch from "../../../components/AllDashboardPagesComponents/WeeklyDaySearch";
import GenderSearch from "../../../components/AllDashboardPagesComponents/GenderSearch";
import AgeGroupSort from "../../../components/AllDashboardPagesComponents/AgeGroupSort";
import RatingSort from "../../../components/AllDashboardPagesComponents/RatingSort";
import { Link, useNavigate } from "react-router-dom";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import qs from "qs";
import {
  CalculateCancellationRate,
  calculateRating,
  getRole,
} from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import DefaultProfileImage from "../../../assets/img/default_profile_image.png";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import ConnectionStatusButton from "../../../components/ConnectionsComponents/ConnectionStatusButton";
import httpRequest from "../../../config/Helpers/httpRequest";
interface QueryParams {
  services: string;
  postcode: string;
  state:string;
  page: number;
  limit: number;
  sort: string;
  gender: string;
  days: string[];
  age: string;
  rating: string;
}
interface User {
  _id: string;
  profileImageUrl?: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  roleCategory?: string;
  connections?: number;
  profileSummary?: string;
  totalRatings?: number;
  totalReviews?: number;
  Jobcompleted?: number;
  jobCancelled?: number;
  connectionStatus?: string;
}

interface ApiResponse {
  documents: User[];
  paginated: {
    totalPages: number;
  };
  message?: string;
}
const SearchUser: React.FC = () => {
  // const UserRole = getRole()?.UserRole || false;
  const decoded = getRole();
  const UserRole = decoded?.role;
  const navigate = useNavigate();
  const [isLoader, setIsLoader] = useState<boolean>(false); // loader for user search
  const [allUsers, setAllUsers] = useState<User[]>([]); // state for all users
  const [totalPages, setTotalPages] = useState<number>(1); // total pages for pagination count
  const [queryParams, setQueryParams] = useState<QueryParams>({
    services: "",
    state:"",
    postcode: "",
    page: 1,
    limit: 10,
    sort: "asc",
    gender: "",
    age: "",
    rating: "",
    days: [],
  }); // query params for APIs

  // Functionality: API call to search for users based on service ID and postcode query
  const searchUser = async () => {
    setIsLoader(true);
    const filteredQueryParams = Object.fromEntries(
      Object.entries(queryParams).filter(([_, value]) => {
        return (
          value !== "" &&
          value !== undefined &&
          !(Array.isArray(value) && value.length === 0)
        );
      }))
    const queryString = qs.stringify(filteredQueryParams, { arrayFormat: "comma" });
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/search/worker?${queryString}` });
    if (res) {

      setAllUsers(res?.documents || []);
      setTotalPages(res?.paginated?.totalPages || 1);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  useEffect(() => {
    searchUser();
  }, [queryParams]);
  return (
    <>
      <HeaderGlobal />
      {isLoader && (
        <>
          <div className="full_page_loader">
            <CircularProgress style={{ color: "#fff" }} />
          </div>
        </>
      )}
      <div className="container-1480 h-3vw">
        <div className="flex justify-between items-center">
          <h2 className="font-size-36px font-Poppins-Medium">Search</h2>
          <button
            onClick={() => navigate(-1)}

          >
            <img
              className="profile-back-icon"
              src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
              alt=""
            />
          </button>
        </div>
        <ServicesSearch
          loader={isLoader}
          onChange={(e) =>
            setQueryParams({
              ...queryParams,
              page: 1,
              postcode: e.postCode || "",
              state: e.state || "",
              services: e.serviceIds.join(",") || "", // Handle multiple service IDs
            })
          }
        />
        {/* search forms */}
        <div className="flex justify-between flex-nowrap	 mt-4">
          <div className="flex items-center ">
            <WeeklyDaySearch
              currentDays={queryParams.days.map(day => String(day))} // Convert string[] to number[]
              onChange={(e) =>
                setQueryParams({ ...queryParams, page: 1, days: e.map(day => day.toString()) }) // Convert number[] back to string[]
              }
            />
            {/* days filter multi select */}
            <GenderSearch
              currentValue={queryParams.gender}
              setGender={(e) =>
                setQueryParams({ ...queryParams, page: 1, gender: e })
              }
            />
            {/* gender filter */}
            <AgeGroupSort
              currentValue={queryParams.age}
              setAgeGroup={(e) =>
                setQueryParams({ ...queryParams, page: 1, age: e })
              }
            />
            <RatingSort
              currentValue={queryParams.rating}
              setRatingSort={(e) =>
                setQueryParams({ ...queryParams, page: 1, rating: e })
              }
            />
          </div>
          {/* <SortBySearch
            sort={queryParams.sort}
            onChange={(e) => setQueryParams({ ...queryParams, sort: e })}
          /> */}
          {/* sort filter */}
        </div>
        {allUsers && allUsers.length > 0 ? (
          allUsers.map((item, index) => (
            <div
              key={index}
              className="flex justify-between shadow-lg p-4 bg-white rounded-xl mt-4"
            >
              <img
                className="EditProfileUserImg"
                src={`${item?.profileImageUrl}?t=${Date.now()}`}
                // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}?t=${Date.now()}`}
                onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                alt="user"
              />{" "}
              {/* profile image */}
              <div className="w-2/5">
                <button>
                  <h3 className="font-size-24px theme-color-green capitalize font-Montserrat-SemiBold flex items-center gap-2">
                    <span>
                      {`${item?.firstName || ""} ${item?.lastName || ""
                        }`}
                    </span>
                  </h3>
                </button>{" "}
                {/* user name */}
                <span className="font-Poppins-Medium font-size-17px capitalize">
                  {/* {item?.roleCategory || ""} */}
                </span>{" "}
                {/* user role */}
                <h4 className="font-size-17px font-Poppins-Medium text-black flex flex-col ">
                  {" "}
                  <span className="font-Poppins-SemiBold theme-color-green capitalize-first-letter ">
                    {item?.roleCategory || 0}
                  </span>{" "}
                  <span className="font-Poppins-SemiBold theme-color-green">
                    {item?.connections || 0}   Connections

                  </span>{" "}
                </h4>{" "}
                {/* connections */}
                <p className="pt-3 font-Poppins-Regular font-size-15px text-gray-500">
                  {item?.profileSummary || ""}
                </p>{" "}
                {/* profile summary */}
              </div>
              <div className="w-2/5 flex justify-end text-end">
                <div className="flex justify-between flex-col">
                  <div className="flex items-center gap-2 justify-end">
                    <div className="text-yellow-400">
                      {[1, 2, 3, 4, 5].map((v) => (
                        <FontAwesomeIcon
                          key={v}
                          fontSize="20px"
                          icon={faStar}
                          style={{
                            color:
                              calculateRating(
                                item?.totalRatings || 0,
                                item?.totalReviews || 0
                              ) >= v
                                ? "#FF9B29"
                                : "#b8b8b8",
                          }}
                        />
                      ))}
                    </div>
                    <span className="text-black font-Poppins-Medium font-size-17px">
                      {item?.totalReviews || 0} Reviews
                    </span>
                  </div>{" "}
                  {/* total reviews & rating star */}
                  <div className="flex items-center gap-1 justify-end">
                    <span className="font-Poppins-SemiBold font-size-17px theme-color-green">
                      {item?.Jobcompleted || 0}
                    </span>
                    <span className="font-Poppins-Medium font-size-17px text-black">
                      Jobs completed
                    </span>
                  </div>{" "}
                  {/* completed jobs */}
                  <div className="flex items-center gap-1 justify-end">
                    <span className="font-Poppins-SemiBold font-size-17px theme-color-green">
                      {CalculateCancellationRate(
                        item?.jobCancelled || 0,
                        item?.Jobcompleted || 0
                      )}
                    </span>
                    <span className="font-Poppins-Medium font-size-17px text-black">
                      Cancellation rate
                    </span>
                  </div>{" "}
                  {/* cancellation rate */}
                  <div className="flex items-end justify-end gap-2">
                    {/* {UserRole !== "operator" ? ( */}
                    <ConnectionStatusButton
                      key={Math.random()}
                      status={item?.connectionStatus}
                      uId={item?._id}
                    />
                    {/* ) : null} */}
                    <Link
                      to={`/public-profile/${item?._id || ""}/view`}
                      className="btn-w feeds-btn flex items-center justify-center mt-3 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                      style={{ color: "#fff" }}
                    >
                      View profile
                    </Link>{" "}
                    {/* view profile */}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p>No results found</p>
        )}
        <PaginationComponet
          currentPage={queryParams.page}
          total={totalPages}
          setCurrentPage={(e) => setQueryParams({ ...queryParams, page: e })}
        />
      </div>
    </>
  );
};

export default SearchUser;