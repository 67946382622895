import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import HeaderSearch from "../SearchComponents/HeaderSearch";
import Adminheaderask from "../../assets/img/Adminheaderask.svg";
import homeicon from "../../assets/img/homeicon.svg";
import discuss from "../../assets/img/discuss.svg";
import Scheduler from "../../assets/img/Scheduler.svg";
import message from "../../assets/img/message.svg";
import bell from "../../assets/img/bell.svg";
import DefaultCoverImage from "../../assets/img/welcome-hudson.png";
import AskAIcomp from "../AskAi/AskAIcomp";
import { useDispatch } from "react-redux";
import { removeCookie, truncateString, isNotEmpty, logOutWithOutApi, getCookie } from "../../config/Helpers/helpers";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { turnOffSocket } from "../../config/Sockets/socket";
import Skeleton from '@mui/material/Skeleton';
import { RootState } from "../../config/Store/store";

interface PersonalData {
  firstName: string;
  lastName: string;
  role: string;
  profileImage?: string;
  [key: string]: any;
}
const HeaderGlobal: React.FC = () => {
  const location = useLocation()
  const IsLocation = (path: string) => {
    return location.pathname === path ? 'active' : '';


  }
  const [isLoading, setIsLoading] = useState(true)
  // Get user data from store
  const { personalData } = useSelector((store: RootState) => store.userProfile);

  // Get notifications from store
  const { unReadNotifications } = useSelector((store: RootState) => store.notification);
  // Get messages from store
  const { recentMessages, unReadMessages } = useSelector(
    (store: RootState) => store.message
  );
  console.log(recentMessages, "recentMessages", unReadMessages, "unReadMessages")
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Ref for modal
  const ref = useRef<HTMLDivElement>(null);
  // State for box visibility
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  // State for box visibility
  const [isBoxVisible1, setIsBoxVisible1] = useState(false);
  // State for modal visibility
  const [dragModal, setDragModal] = useState(false);

  // Function to open modal
  const handleOpen = () => {
    setDragModal(true);
  };
  // Function to close modal
  const handleClose = () => {
    setDragModal(false);
  };

  // Function for logout
  const handleLogOut = () => {
    // Functionality: Remove cookie and turn off socket
    removeCookie(dispatch);
    removeCookie();
    turnOffSocket();
    // Functionality: Navigate to login page
    navigate("/login-web");
  };
  useEffect(() => {
    const token = getCookie();
    if (!token) {
      navigate("/login-web");
    }

  })
  // const handleLogOut = async () => {
  //   await logOutWithOutApi();
  //   const token = getCookie();
  //   if (!token) {
  //     navigate("/login-web");
  //   }
  // };
  // Add event listener for overlay click
  useEffect(() => {
    const handleOverlayClick = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        const delay = (milliseconds: number): Promise<void> => {
          return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };

        const handleAction = async () => {
          await delay(100);
          setIsBoxVisible(false);
        };

        handleAction(); // Call handleAction to start the delay
      }
    };

    document.addEventListener("mousedown", handleOverlayClick);

    return () => {
      document.removeEventListener("mousedown", handleOverlayClick);
    };
  }, []);

  useEffect(() => {
    if (isNotEmpty(personalData)) {
      setIsLoading(false);
    }
  })
  // Function to toggle box visibility
  const toggleBoxVisibility1 = () => {
    setIsBoxVisible1((prev) => !prev);
  };

  // Add event listener for overlay click
  useEffect(() => {
    const handleOverlayClick1 = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        const delay = (milliseconds: number): Promise<void> => {
          return new Promise((resolve) => setTimeout(resolve, milliseconds));
        };

        const handleAction1 = async () => {
          await delay(100);
          setIsBoxVisible1(false);
        };

        handleAction1(); // Call handleAction to start the delay
      }
    };

    document.addEventListener("mousedown", handleOverlayClick1);

    return () => {
      document.removeEventListener("mousedown", handleOverlayClick1);
    };
  }, []);
  const chatAppUrl = process.env.REACT_APP_CHATAPP;

  const handleClick = () => {
    if (chatAppUrl) {
      window.location.href = chatAppUrl;
    } else {
      console.error('Chat app URL is not defined.');
    }
  };
  return (
    <header className="globalheader fixed z-10  mob-header">
      <div className="container-1480 header-glo-set ">
        <div className="flex gap-3 items-center lg:gap-2 md:gap-4">
          <HeaderSearch />
          <div onClick={handleOpen}>
            <button className="admin-header-ask-btn font-size-20px font-Poppins-SemiBold">
              <img src={Adminheaderask} alt="" />
              Ask AI
            </button>
          </div>
          {dragModal && <AskAIcomp handleClose={handleClose} />}
        </div>
        <div className="flex gap-8 items-center  relative ">
          <div className=" flex gap-3 items-center lg:gap-6 md:gap-6">
            <Link
              to="/feed"
              className={` header-notifi text-white relative text-center flex items-center flex-col   global-header-hover ${IsLocation("/feed")}`}
            >
              <img src={homeicon} alt="" />
              <span className="font-Poppins-Medium font-size-12px block  header-notifi-text">
                Home
              </span>
            </Link>
            <Link
              to="/discussions"
              className={`header-notifi text-white relative flex items-center flex-col global-header-hover ${IsLocation("/discussions")}`}
            >
              <img src={discuss} alt="" />
              <span className="font-Poppins-Medium font-size-12px block  header-notifi-text  ">
                Community forum
              </span>
            </Link>
            <Link
              to="/Scheduler"
              className={`header-notifi text-white relative flex items-center flex-col global-header-hover  ${IsLocation("/Scheduler")}`}
            >
              <img src={Scheduler} alt="" />
              <span className="font-Poppins-Medium font-size-12px block  header-notifi-text  ">
                Scheduler
              </span>
            </Link>
            <a
              href={`${process.env.REACT_APP_CHATAPP}`}

              // to={process.env.REACT_APP_CHATAPP}
              onClick={handleClick}
              className={`"header-notifi text-white relative flex items-center flex-col message-noti  global-header-hover`}
            >
              {unReadMessages ? (
                <div className="msg-count-main">
                  <span className="msg-count-specific">{unReadMessages}</span>
                </div>
              ) : null}
              <img src={message} alt="" />
              <span className="font-Poppins-Medium font-size-12px block  header-notifi-text">
                Messages
              </span>
            </a>
            <Link
              to="/notification"
              className="header-notifi text-white relative flex items-center flex-col "
            >
              {unReadNotifications ? (
                <div className="msg-count-main">
                  <span className="msg-count-specific">
                    {unReadNotifications}
                  </span>
                </div>
              ) : null}
              <div className={`global-header-hover flex flex-col items-center  ${IsLocation("/notification")}`}>
                <img src={bell} alt="" className="" />
                <span className="font-Poppins-Medium font-size-12px block header-notifi-text  ">
                  Notifications
                </span>
              </div>
            </Link>
          </div>
          <div className="Admin-user-header  ">
            {isLoading ?
              (
                <Skeleton variant="rounded" className="w-fit mb-2" style={{ borderRadius: 50 }} height={15} />

              )
              :
              (
                <div className="admin-header-user-img flex jsutify-center flex-col items-center ">
                  <img
                    src={`${personalData?.profileImageUrl}?t=${Date.now()}`}
                    // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${personalData?.profileImageUrl}?t=${Date.now()}`}
                    onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                    alt="user"
                  />
                </div>
              )
            }


            <div className="flex flex-col items-baseline">
              <h4 className="font-size-20px font-Poppins-SemiBold text-white"></h4>
              <button
                className="font-Poppins-Regular text-white font-size-12px flex  items-baseline gap-2 justify-center"
                onClick={toggleBoxVisibility1}
              >
                Me <FontAwesomeIcon icon={faSortDown} />
              </button>
            </div>
            {/* edit profile detail */}
            {isBoxVisible1 && (
              <div ref={ref} className="dropdown-profile-view py-3 ">
                <div className="flex gap-2 items-center px-2">
                  <img
                    src={`${personalData?.profileImageUrl}?t=${Date.now()}`}
                    // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${personalData?.profileImageUrl}?t=${Date.now()}`}
                    onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                    alt="user"
                  />
                  <div className="sidebar-gobal-user-name ">
                    <p className="font-size-20px font-Poppins-SemiBold theme-color-green  hover-underline capitalize">
                      {(personalData) ? `${personalData.firstName || ""} ${personalData.lastName || ""}` : ""}
                    </p>
                    <p className="font-size-15px  theme-color-green font-Poppins-Regular  capitalize-first-letter">
                      {personalData ? personalData.roleCategory || personalData.role || "" : ""}
                    </p>
                    <p className="font-size-15px theme-grey-type  font-Poppins-Regular  ">
                      {(personalData) ? truncateString(personalData.profileSummary, 50) : ""}
                    </p>
                  </div>
                </div>
                <div className="px-2">
                  <Link
                    to="/publicprofile"
                    className=" font-size-18px theme-color-green font-Poppins-Medium feeds-btn mt-3 w-full hover:text-white hover:bg-[#00443F]"
                  >
                    View profile
                  </Link>
                </div>
                <hr color="#707070 " className="my-1 lg:my-2" />
                <div className="px-2 ">
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green  capitalize ">
                    Account
                  </p>
                  <ul>
                    <li className="pb-2">
                      <Link
                        to="/setting"
                        className="font-size-15px theme-grey-type font-Poppins-Medium on-hover-bg "
                      >
                        Account settings
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="/privacy"
                        className="font-size-15px theme-grey-type font-Poppins-Medium on-hover-bg "
                      >
                        Privacy settings
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr color="#707070 " className="my-1 lg:my-2" />
                <div className="px-2 ">
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green capitalize ">
                    Manage
                  </p>
                  <ul >
                    <li className="pb-2">
                      <Link
                        to="/activity"
                        className="font-size-15px theme-grey-type font-Poppins-Medium  on-hover-bg"
                      >
                        Activity log
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="/subscription"
                        className="font-size-15px theme-grey-type  font-Poppins-Medium  on-hover-bg  "
                      >
                        Manage subscription
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr color="#707070 " className="my-1 lg:my-2" />
                <div className="px-2 ">
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green capitalize ">
                    Ads
                  </p>
                  <ul >
                    <li className="pb-2">
                      <Link
                        to="/ads-manager"
                        className="font-size-15px theme-grey-type font-Poppins-Medium  on-hover-bg"
                      >
                        Ads manager
                      </Link>
                    </li>
                    <li className="">
                      <Link
                        to="/bill-payment"
                        className="font-size-15px theme-grey-type  font-Poppins-Medium  on-hover-bg  "
                      >
                        Billing & payments
                      </Link>
                    </li>
                  </ul>
                </div>
                <hr color="#707070 " className="my-1 lg:my-2" />

                <div className="px-2">
                  <ul>
                    <li>
                      <button
                        onClick={handleLogOut}
                        className="font-size-15px theme-grey-type font-Poppins-Medium  on-hover-bg"
                      >
                        Sign out
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default HeaderGlobal;
