import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import checkbox from "../../assets/img/paid-provider-mandatory.png";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import menus3 from "../../assets/img/menus-3.svg";
import menus6 from "../../assets/img/menus-6.svg";
import menus8 from "../../assets/img/menus-8.svg";
import menus7 from "../../assets/img/menus-7.svg";
import newone from "../../assets/img/new.svg";
import smalllogo from "../../assets/img/smalllogo.svg";
import closeIcon from "../../assets/img/closeIcon.svg";
import { getRole } from "../../config/Helpers/helpers";
import menus1 from "../../assets/img/menus-1.svg";
import menus2 from "../../assets/img/menus-2.svg";
import menus4 from "../../assets/img/menus-4.svg";
import menus5 from "../../assets/img/menus-5.svg";
import ndisyncblacklogo from "../../assets/img/ndisync-black-logo.png";
import FeedbackModal from "../Modal/FeedbackModal";
// import WelcomeNidsModal from "../Modal/welcomNdisModal";
import NidisModal from "../Modal/NdisModal";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Preimum from "../../assets/img/modal-icon.svg"
import { RootState } from "../../config/Store/store";
import { useSelector } from "react-redux";
import { WindowSharp } from "@mui/icons-material";
import axios from "axios";
interface ResponseData {
  promotionalStatus: boolean;
}
function RightSidebarFeed({ jobSectionHide = false }) {
  const firstUser = Cookies.get("_firstTimeLoggedInUser")
  const { pathname } = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [promotionalStatus, setPromotionalStatus] = useState<boolean>(false);

  // useEffect(() => {
  //   if (promotionalStatus) {
  //     setIsModalOpen(true)
  //   }
  // }, [])

  // Check the cookie and show the modal only for the first-time user


  const { personalData } = useSelector((store: RootState) => store.userProfile)

  useEffect(() => {
    (async () => {
      // setIsLoader(true);
      try {
        const response = await axios.get<ResponseData>(`${process.env.REACT_APP_BASE_URL}/api/subscription/offer`);
        if (personalData?.freeUser) {

          setPromotionalStatus(response?.data?.promotionalStatus)
        }

      }
      catch (err: any) {
        // toast(err?.response?.data || err?.response?.message || "ERROR", { type: "error" });
      }
      // setIsLoader(false);
    })();
    console.log("hehehehehehehehehehe")

  }, [personalData]);
  useEffect(() => {
    if (!firstUser && promotionalStatus) {
      // setIsModalOpen(promotionalStatus);
      Cookies.set("_firstTimeLoggedInUser", "firstTime", { expires: 7 }); // Cookie expires in 7 days
    }
  }, [firstUser]);
  // let UserRole = getRole()?.UserRole || "";
  // let Subscribed = getRole()?.isMember || "";
  const decoded = getRole();
  const UserRole = decoded?.role || "";
  const Subscribed = personalData?.freeUser || false;
  const [loginModal, setLoginModal] = useState(false)
  const [postModal, setPostModal] = useState(false);
  const [feedbackModal, setFeedbackModal] = useState(false)

  const [showDetail, setShowDetail] = useState(true)

  useEffect(() => {
    if (window.innerWidth < 767) {
      setShowDetail(false)
    }
  }, [window.innerWidth])
  // console.log(Subscribed, "UserRole")
  const HandleClickMdal = () => {
    setPostModal(!postModal);
  };
  const HandleClickShowDetail = () => {
    setShowDetail(true);
  };
  const HandleClickShowDetailClose = () => {
    setShowDetail(false);
  };
  return (
    <div className="all-menus">
      {pathname === "/feed" ? (
        <div className="sidebar-gobal-user-details mt-2 py-2 right-bar-option relative" style={{ width: "17.786vw" }}>
          <div className="px-2">
            <Link
              to="/connections"
              className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
            >
              <span className="menus-gloabal-icon">
                <img src={menus1} alt="" />
              </span>
              Manage connections
            </Link>
            <Link
              to="/manage-jobs"
              className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
            >
              <span className="menus-gloabal-icon">
                <img src={menus2} alt="" />
              </span>
              Manage bookings
            </Link>

            <Link
              to="/jobs-board"
              className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
            >
              <span className="menus-gloabal-icon">
                <img src={menus3} alt="" />
              </span>
              Job posts
            </Link>
            {UserRole === "provider" && (
              <Link
                to="/applied-jobs"
                state={{ tab: "applied" }}
                className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
              >
                <span className="menus-gloabal-icon">
                  <img src={menus4} alt="" />
                </span>
                My applied jobs
              </Link>
            )}
            <Link
              to="/dispute-jobs"
              className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center  on-hover-underline"
            >
              <span className="menus-gloabal-icon">
                <img src={menus5} alt="" />
              </span>
              Disputed jobs
            </Link>
          </div>
          <span onClick={HandleClickShowDetail} className={showDetail ? "chevron-arrow-down-non" : "chevron-arrow-down"}><FontAwesomeIcon icon={faChevronDown} /></span>
        </div>
      ) : null}
      {/* <div style={{ width: "17.786vw" }} className="right-sidebar-feed py-4   ">
        <h4 class="font-size-20px font-Poppins-SemiBold theme-color-green text-center px-3">
          What you'll need to get verified
        </h4>
        <div className="px-4">
          <p className="font-size-15px font-Poppins-Medium  flex items-center gap-2 py-2">
            {" "}
            <img src={checkbox} alt="" width={"18px"} />{" "}
            <span> Mandatory infection control training</span>
          </p>
          <p className="font-size-15px font-Poppins-Medium  flex items-center gap-2 py-2">
            {" "}
            <img src={checkbox} alt="" width={"18px"} />{" "}
            <span> COVID-19 vaccination as required by your state</span>
          </p>
          <p className="font-size-15px font-Poppins-Medium  flex items-center gap-2 py-2">
            {" "}
            <img src={checkbox} alt="" width={"18px"} />{" "}
            <span> National police check </span>
          </p>
          <p className="font-size-15px font-Poppins-Medium  flex items-center gap-2 py-2">
            {" "}
            <img src={checkbox} alt="" width={"18px"} />{" "}
            <span>
              {" "}
              Working with children check (if working with children){" "}
            </span>
          </p>
          <p className="font-size-15px font-Poppins-Medium  flex items-center gap-2 py-2">
            {" "}
            <img src={checkbox} alt="" width={"18px"} />{" "}
            <span> Australian business number (ABN)</span>
          </p>

          <p className="font-size-15px font-Poppins-Medium  flex items-center gap-2 py-2">
            {" "}
            <img src={checkbox} alt="" width={"18px"} />{" "}
            <span> Two references</span>
          </p>
        </div>
        <hr className="my-3 " />
        <div className="flex justify-center flex-col items-center">
          <p className="font-size-15px font-Poppins-SemiBold theme-color-green  text-center">
            Get a third party profile verification for $50
          </p>
          <Link
            to=""
            onClick={HandleClickMdal}
            className="hover:text-white hover:bg-[#00443F] font-size-18px theme-color-green font-Poppins-Medium feeds-btn mt-3"
          >
            Get verified
          </Link>
          {postModal && (
            <div className="ovrlayModal modal" onClick={HandleClickMdal}>
              <div className="createpost-modal absolute z-20">
                <button
                  className="absolute -top-5 font-size-24px"
                  style={{ right: "-10px" }}
                  onClick={HandleClickMdal}
                >
                  <img src={closeIcon} alt="" />
                </button>
                <div>
                  <h2 className="theme-color-green get-verification-comming-soon font-Poppins-Bold text-center font-size-40px">
                    Coming soon
                  </h2>
                </div>
              </div>
            </div>
          )}
        </div>
      </div> */}
      {showDetail && !jobSectionHide && (
        <>
          <div
            className="sidebar-gobal-user-details mt-2 py-2 right-bar-option"
            style={{ width: "17.786vw" }}
          >
            <div className="px-2">
              <Link
                to="/manage-reviews"
                className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
              >
                <span className="menus-gloabal-icon">
                  <img src={menus6} alt="" />
                </span>
                Manage reviews
              </Link>{" "}
              {/* manage review */}
              {UserRole === "provider" && (
                <Link
                  to="/public-jobs"
                  state={{ tab: "opened" }}
                  className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
                >
                  <span className="menus-gloabal-icon">
                    <img src={menus3} alt="" />
                  </span>
                  Job board
                </Link>
              )}
              <Link
                to="/search-user"
                className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center mb-2 on-hover-underline"
              >
                <span className="menus-gloabal-icon">
                  <img src={menus7} alt="" />
                </span>
                Find a support provider
              </Link>{" "}
              {/* search workers */}
              <Link
                to="/jobs-board"
                state={{ isOpen: true }}
                className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center on-hover-underline"
              >
                <span className="menus-gloabal-icon">
                  <img src={menus8} alt="" />
                </span>
                Post a job
              </Link>
            </div>
          </div>
          {Subscribed && (
            <div
              className="sidebar-gobal-user-details mt-2 py-2 px-2 on-hover-underline "
              style={{ width: "17.786vw" }}
            >
              <Link
                to="/subscription"
                className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center"
              >
                <span className="menus-gloabal-icon">
                  <img src={Preimum} alt="" />
                </span>
                Upgrade to premium
              </Link>
            </div>

          )}

          <div
            className="sidebar-gobal-user-details mt-2 py-2 px-2 on-hover-underline "
            style={{ width: "17.786vw" }}
          >
            <Link
              to=""
              className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center"
            >
              <span className="menus-gloabal-icon">
                <img src={newone} alt="" />
              </span>
              Get the{" "}
              <img src={ndisyncblacklogo} alt="" style={{ width: "20%" }} />{" "}
              mobile app
            </Link>
          </div>

          <div
            className="sidebar-gobal-user-details mt-2 py-2 px-2 on-hover-underline relative"
            style={{ width: "17.786vw" }}
          >
            <button
              onClick={() => setFeedbackModal(true)}

              className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center "
            >
              <span className="menus-gloabal-icon">
                <img src="https://edgie.backslashwebs.com/feedback-icon.svg" alt="" />
              </span>
              Submit a feedback
            </button>
            <span onClick={HandleClickShowDetailClose} className={showDetail ? "chevron-arrow-down" : "chevron-arrow-down-non"}><FontAwesomeIcon icon={faChevronUp} /></span>
          </div>
          {feedbackModal && <FeedbackModal error={""} onClose={() => setFeedbackModal(false)} />}

          {isModalOpen && <NidisModal onClose={() => setIsModalOpen(false)} />}


        </>
      )}
    </div>
  );
}

export default RightSidebarFeed;
