import React, { useState, useRef, useEffect } from "react";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import Header from "../../components/Header";
import url from "../../config/url";
import ndisailogo from "../../assets/img/ndisailogo.svg";

import { faCalendarDays } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import paidprovider from "../../assets/img/paidprovider-welcome.png";
import { Link } from "react-router-dom";
import DemoVideo from "../../components/WatchDemoVideo/DemoVideo";

const FreeDemo = () => {
  const BannerBgTexture = "imgs/banner-bg-texture.png";
  const BannerBgMapImg = "imgs/banner-bg-map.png";

  return (
    <>
      <section className="login-page-parent relative h-screen theme-bg-cream-white overflow-clip">
        <Header />
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10 opacity-30 bg-texture-banner"
          src={"https://edgie.backslashwebs.com/imgs/banner-bg-texture.png"}
          alt=""
        />
        <div className="container-custom h-full relative z-20 flex items-center mob-cont">
          <div
            className="login-paid-plan-main-content-parent free-demo-main-res relative flex items-end justify-center w-full theme-bg-green banner-box-border-radius"
            style={{ padding: "0px 0px 0px 0px" }}
          >
            <img
              decoding="async"
              className="absolute top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2 opacity-20 z-10 w-4/6"
              src={"https://edgie.backslashwebs.com/imgs/banner-bg-map.png"}
              alt=""
            />

            <div className=" demo-mean-screen  demo-mean-screen-mob">
              <DemoVideo isModalVedioRender={true} />
              <div className="flex flex-col items-center ">
                <h3
                  style={{ height: "40px" }}
                  className="h-5 flex items-center	  font-Poppins-SemiBold font-size-login-36px text-white leading-none text-center lg-main-head z-10"
                >
                  Get a free 1:1 demo
                </h3>

                <div className="w-full theme-bg-cream-white z-10 flex items-center justify-center gap-3 flex-col relative rounded-3xl	h-full">
                  <img
                    src={paidprovider}
                    className="rounded-full	lets-meet-img"
                    alt=""

                  />
                  <h3 className=" font-size-login-32px font-Montserrat-Bold theme-color-green ">
                    Let's meet
                  </h3>
                  <Link
                    to="/schedule-meeting"
                    className="gap-2 bg-white theme-color-green border-radius-6px font-size-login-16px font-Poppins-SemiBold login-btn-home-banner input-btns-height-40px flex items-center justify-center"
                  >
                    <FontAwesomeIcon icon={faCalendarDays} /> Start booking
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FreeDemo;
