import React, { useState, useEffect, FormEvent, ChangeEvent } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import TextareaField from "../../../components/AllDashboardPagesComponents/TextareaField";
import { Link } from "react-router-dom";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { getRole } from "../../../config/Helpers/helpers";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import RewriteWithAi from "../../../components/AskAi/RewriteWithAi";
import ReWriteLoader from "../../../components/AskAi/ReWriteLoader";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";
interface IApiResponse {
  message?: string
}
const AboutEdit: React.FC = () => {
  const dispatch = useDispatch();
  const { personalData } = useSelector((store: RootState) => store.userProfile); // get user data
  const decoded = getRole();
  const UserRole = decoded?.role;

  // const { UserRole } = getRole(); // get user Role

  const [descriptionLoader, setDescriptionLoader] = useState(false); // loader for description update
  const [profileDescription, setProfileDescription] = useState<string>(""); // state profile description
  const [aILoader, setAiLoader] = useState(false); // rewrite with AI loader

  const handleChangeDescription = (text: string) => {
    if (text.length > 800) {
      return;
    } else {
      setProfileDescription(text);
    }
  }; // summary change function

  // Functionality: API call for for update profile description
  const handleSubmitDescription = async (e: FormEvent) => {
    e.preventDefault();
    setDescriptionLoader(true);
    if (!profileDescription) {
      toast("Please add description", { type: "error" }); // api error
      setDescriptionLoader(false);
      return
    }
    const { res, err } = await httpRequest<IApiResponse>({ method: "patch", path: `/api/user/description`, params: { description: profileDescription } });

    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setDescriptionLoader(false);
  };

  useEffect(() => {
    if (personalData) {
      setProfileDescription(personalData?.profileDescription);
    }
  }, [personalData]);
  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3 ">
        <SidebarGobal />
        <div className="w-full ">
          <PreviewProfile title="  Edit about" />

          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6">
            <form onSubmit={handleSubmitDescription}>
              {aILoader ? (
                <ReWriteLoader
                  extraStyle={{
                    border: "1px solid #00a297",
                    borderRadius: 10,
                    padding: 5,
                    height: 150,
                    margin: "5px 0 10px 0",
                    backgroundColor: "#fff",
                  }}
                />
              ) : (
                <TextareaField
                  currentLength={profileDescription?.length || 0}
                  isLength={800}
                  value={profileDescription}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChangeDescription(e.target.value)}
                  labelTop="You can write about your years of experience, industry, or skills. People also talk about their achievements or previous job experiences."
                  rows={8}
                  placeholder=""
                />
              )}

              <div className="mb-3">
                <RewriteWithAi
                  text={profileDescription}
                  setText={setProfileDescription}
                  setLoader={setAiLoader}
                  isProfile={true}
                />
              </div>

              <button
                disabled={descriptionLoader || aILoader}
                type="submit"
                className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Save and continue{" "}
                {descriptionLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutEdit;