import React, { useState, useEffect } from 'react';

import HeaderGlobal from '../../../components/HeaderDashboard/headerGlobal';
import ProfileUserDetailDashboard from '../../../components/AllDashboardPagesComponents/Profile/ProfileUserDetailDashboard';
import Availability from '../../../components/AllDashboardPagesComponents/Profile/Availability';
import EducationTraining from '../../../components/AllDashboardPagesComponents/Profile/EducationTraining';
import ServicesProfile from '../../../components/AllDashboardPagesComponents/Profile/ServicesProfile';
import HourlyRate from '../../../components/AllDashboardPagesComponents/Profile/HourlyRate';
import WorkLocation from '../../../components/AllDashboardPagesComponents/Profile/WorkLocation';
import PreferencesInterest from '../../../components/AllDashboardPagesComponents/Profile/PreferencesInterest';
import ReviewProfile from '../../../components/AllDashboardPagesComponents/Profile/ReviewProfile';
import AddressSocialDetail from '../../../components/AllDashboardPagesComponents/Profile/AddressSocialDetail';
import Aboutme from '../../../components/AllDashboardPagesComponents/Profile/Aboutme';

import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { PublicDataType, setPublicProfile, removePublicProfile } from '../../../config/Store/Reducers/userProfile';
import { toast } from 'react-toastify';
import { getRole } from '../../../config/Helpers/helpers';
import WorkHistory from '../../../components/AllDashboardPagesComponents/Profile/WorkHistory';
import PostActivity from '../../../components/AllDashboardPagesComponents/Profile/PostActivity';
import AdminHeader from '../../../components/AdminComponents/AdminHeader';
import httpRequest from '../../../config/Helpers/httpRequest';
import UnpaidModal from '../../../components/Modal/UnpaidModal';
import { RootState } from "../../../config/Store/store";
import AdminSidebar from '../../../components/AdminComponents/AdminSidebar';
interface ApiResponse {
    success: boolean;
    message: string;
    document: PublicDataType; // Use the PublicDataType interface you already defined
    documents?: PublicDataType;
}
const UserProfile: React.FC = () => {

    // let { UserRole = "provider" } = getRole();
    const decoded = getRole()
    const UserRole = decoded?.role || "provider";
    const userId = decoded?.userId
    const dispatch = useDispatch();
    const { uid } = useParams<{ uid: string }>();
    const navigate = useNavigate();
    const location = useLocation();


    const [unpaidModalShow, setUnpaidModalShow] = useState(false);
    const [error, setError] = useState<any>("");
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth)

    useEffect(() => {
        setScreenWidth(window.innerWidth)
    }, [window.innerWidth])

    const { publicData, personalData } = useSelector((store: RootState) => store.userProfile);

    const [isLoader, setIsLoader] = useState(false);

    // Functionality: API call for get public profile data for public profile
    const fetchUserData = async () => {
        setIsLoader(true);

        let path = "";
        if (UserRole === "admin" || UserRole === "superAdmin" || UserRole === "editor") {
            path = `/api/admin/user/profile/${uid}`;
        }
        else {
            path = `/api/search/view/${uid}`
        }
        const { res, err } = await httpRequest<ApiResponse>({ path: path, navigate });
        // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/search/view/${uid}`, navigate });
        if (res) {
            const profile = res?.document || res?.documents || [];
            const services = profile?.services || [];
            const connectionStatus = profile?.connectionStatus || "default";

            const clonedProfile = JSON.parse(JSON.stringify(profile)); // Deep clone the profile

            dispatch(setPublicProfile({
                profile: clonedProfile,
                services: services,
                connectionStatus: connectionStatus
            }));
        }
        else {
            if (err?.status === 402) {
                setUnpaidModalShow(true)
                setError(err?.message)

            }

            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };
    useEffect(() => {
        fetchUserData();
    }, [uid]);

    useEffect(() => {
        return () => {
            dispatch(removePublicProfile());
        };
    }, [navigate]);
    const hasTruePreference = publicData?.preferences &&
        Object.values(publicData.preferences).some((value) => value === true);

    // This will only run when publicData changes

    // useEffect(() => {
    //     if (location.state?.showUnpaidModal) {
    //         setUnpaidModalShow(true);
    //         setError(location.state.error);
    //         navigate(location.pathname, { replace: true, state: {} });
    //     } else {
    //         fetchUserData();
    //     }
    // }, [uid, location.pathname]);



    return (
        <>
            {(UserRole === "admin" || UserRole === "superAdmin" || UserRole === "editor")
                ?
                <>
                    <AdminHeader />

                </>
                :
                <HeaderGlobal />
            }

            
            <div className='container-1480 flex justify-between h-3vw'>

                <div className="w-full md:w-3/4 lg:w-3/4">
                    <ProfileUserDetailDashboard bool={false} setBool={() => { }} unpaidModalShow={unpaidModalShow} />
                    {screenWidth < 450 && (

                        <AddressSocialDetail
                            unpaidModalShow={false}
                            userId={userId}

                        />
                    )}
                    {uid === userId ?

                        (
                            <Aboutme />
                        )

                        :

                        (
                            (publicData?.profileDescription &&
                                <Aboutme />
                            )
                        )
                    }


                    {publicData?.activitySection &&
                        <PostActivity
                            uId={uid}
                        />
                    }

                    {publicData?.role === "provider"
                        ?
                        <>
                            {/* only for provider */}

                            {uid === userId ?

                                (
                                    <Availability />
                                )

                                :

                                (
                                    (publicData?.availability &&
                                        <Availability />
                                    )
                                )
                            }

                            {uid === userId ?

                                (
                                    <WorkHistory />
                                )



                                :

                                (
                                    (publicData?.experience &&
                                        <WorkHistory />)
                                )
                            }

                            {/* {publicData?.experience &&
                                <>
                                    <WorkHistory /> 
                                </>
                            } */}

                            {uid === userId ?

                                (
                                    <EducationTraining />
                                )



                                :

                                (
                                    (publicData?.education &&
                                        <EducationTraining />
                                    )
                                )
                            }


                            <ServicesProfile /> {/* only for provider */}
                            <HourlyRate />  {/* only for provider */}
                            <WorkLocation /> {/* only for provider */}
                        </>
                        : null
                    }


                    {uid === userId ?

                        (
                            <PreferencesInterest />
                        )



                        :

                        (
                            (publicData?.preferences &&
                                <PreferencesInterest />
                            )
                        )
                    }
                    {/* {
                        // hasTruePreference &&

                        <PreferencesInterest />

                    } */}
                    {/* <ReviewProfile unpaidModalShow={unpaidModalShow} /> */}
                    <ReviewProfile unpaidModalShow={unpaidModalShow} />
                </div>

                {/* <AddressSocialDetail
                    userId={uid}
                    unpaidModalShow={unpaidModalShow}
                /> */}
                {screenWidth > 450 && (

                    <AddressSocialDetail
                        unpaidModalShow={false}
                        userId={userId}

                    />
                )}
            </div>

            {unpaidModalShow && <UnpaidModal profile={true} error={error || ""} onClose={() => setUnpaidModalShow(false)} />}

        </>
    );
};

export default UserProfile;