import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faVenus,
  faClock,
  faCalendar,
  faPhone,
  faEnvelope,
  faCalendarDays,
  faX,
  faStar,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import "react-calendar/dist/Calendar.css";
import { Skeleton } from "@mui/material";
import {
  calculateAge,
  convertTo12Hour,
  getTimeDifference,
  jobStartDate,
  jobStartDateWithTime,
} from "../../config/Helpers/helpers";

import ShowNumber from "./ShowNumber"; // call modal
import AddCommentJob from "../JobsComponents/AddCommentJob";
import JobRescheduleModal from "../JobsComponents/JobRescheduleModal";
import JobRequestAndHistory from "../JobsComponents/JobRequestAndHistory";
import JobCancelButton from "../JobsComponents/JobCancelButton";
import JobCompleteRequest from "../JobsComponents/JobCompleteRequest";
import JobReviewModal from "../JobsComponents/JobReviewModal";
import StartJobDispute from "../JobsComponents/StartJobDispute";
import JobReopen from "../JobsComponents/JobReopen";
import moment from "moment";
interface ApplicantDetails {
  profileImageUrl?: string;
  firstName?: string;
  lastName?: string;
  roleCategory?: string;
  dateofbirth?: string;
  gender?: string;
}

interface JobCreatorDetails {
  preferencemale?: boolean;
  preferencefemale?: boolean;
  preferencenopet?: boolean;
  preferencenonsmoker?: boolean;
}

interface Service {
  servicename: string;
}
interface service {
  servicename: string;
  _id: string;
  [key: string]: any;
}
interface JobItem {
  _id?: string;
  createdAt?: string;
  service: service;
  applicantDetails?: ApplicantDetails;
  jobCreatorDetails?: JobCreatorDetails;
  city?: string;
  state?: string;
  postCode?: string;
  repeatCycle?: number;
  startDate?: string;
  endDate?: string;
  allServices?: Service[];
  description?: string;
  budget?: number;
  jobApplicationUpdateTime?: string;
  cancelledBy?: string;
  jobId?: string;
  [key: string]: any;
}

interface ActiveSpecificJobsProps {
  loader: boolean;
  item?: JobItem;
  activeTab: string;
  setActiveTab: (tab: string) => void;
}

const ActiveSpecificJobs: React.FC<ActiveSpecificJobsProps> = ({
  loader,
  item,
  activeTab,
  setActiveTab,
}) => {
  // integration
  const [callModal, setCallModal] = useState<boolean>(false);
  const [rescheduleModal, setRescheduleModal] = useState<boolean>(false);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [successModalMsg, setSuccessModalMsg] = useState<string>("");
  const [logUpdated, setLogUpdated] = useState<boolean>(false);
  const [commentModal, setCommentModal] = useState<boolean>(false);
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [disputeModal, setDisputeModal] = useState<boolean>(false);

  const checkPreferences = (user?: JobCreatorDetails): string => {
    if (!user) return "-"; // Return an empty string if preferences are not provided

    const {
      preferencemale,
      preferencefemale,
      preferencenopet,
      preferencenonsmoker,
    } = user;

    // Check for specific combinations and return the corresponding HTML string
    if (
      !preferencemale &&
      !preferencefemale &&
      !preferencenopet &&
      !preferencenonsmoker
    ) {
      return "-";
    } else {
      let result = "";

      if (preferencemale && preferencefemale) {
        result += "Male & Female";
      } else if (preferencemale) {
        result += "Only Male";
      } else if (preferencefemale) {
        result += "Only Female";
      }

      if (preferencenopet) {
        if (result !== "") result += ", ";
        result += "No Pets";
      }

      if (preferencenonsmoker) {
        if (result !== "") result += ", ";
        result += "Non Smoker";
      }
      return result;
    }
  };

  const handleCallResponseSuccess = (msg: string) => {
    // after the successfull call api update history log
    setLogUpdated((prev) => !prev);
  };

  const handleRescheduleResponseSuccess = (msg: string) => {
    setLogUpdated((prev) => !prev);
    setSuccessModalMsg("Job reschedule request has been sent!");
    setSuccessModal(true);
  };
  useEffect(() => {
    if (rescheduleModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto'; // Restore scrolling
    }

    // Cleanup function to ensure scrolling is restored when the component is unmounted
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [rescheduleModal]);
  return (
    <>
      <div className="admin-job-review-stat">
        {loader ? (
          <>
            <Skeleton variant="rounded" className="w-full mb-3" height={330} />
            <div className="mb-3 flex justify-between gap-1">
              <Skeleton variant="rounded" width={150} height={50} />
              <Skeleton variant="rounded" width={150} height={50} />
              <Skeleton variant="rounded" width={150} height={50} />
              <Skeleton variant="rounded" width={150} height={50} />
              <Skeleton variant="rounded" width={150} height={50} />
            </div>
          </>
        ) : (
          <>
            {item ? (
              <>
                <div className="admin-preview-head flex items-center justify-end">
                  <p className="font-Poppins-Medium font-size-12px text-[#ffffff] pr-2">
                    Posted {getTimeDifference(item?.createdAt || "")}
                  </p>
                </div>

                <div className="admin-job-preview-main ">
                  <div className="admin-preview-job">
                    <img
                      src={`${item?.applicantDetails?.profileImageUrl}`}
                      // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.applicantDetails?.profileImageUrl}`}
                      alt=""
                    />
                    <div className="text-center jobee-detail">
                      <h2 className="font-Poppins-Medium font-size-22px text-[#00443f]">
                        {item?.service?.servicename}
                        {/* {item && item?.allServices
                          ? item?.allServices[0]?.servicename
                          : "-"} */}
                      </h2>
                      <p className="font-Poppins-SemiBold font-size-15px text-[#000000]">
                        Assigned to
                      </p>
                      <p className="font-Poppins-SemiBold font-size-15px text-[#00443f] capitalize">{`${item?.applicantDetails?.firstName || ""
                        } ${item?.applicantDetails?.lastName || ""}`}</p>
                      <p className="font-Poppins-SemiBold font-size-15px text-[#000000] capitalize-first-letter">
                        {item?.applicantDetails?.roleCategory || "-"}
                      </p>
                      <div className="flex flex-col">

                        {item?.applicantDetails?.dateofbirth &&
                          <p className="font-Poppins-SemiBold font-size-15px text-[#000000]">
                            {calculateAge(item?.applicantDetails?.dateofbirth || "-")} Years old

                          </p>
                        }


                        <p className="font-Poppins-SemiBold font-size-15px text-[#000000]">{item?.applicantDetails?.gender || "-"}</p>
                      </div>
                    </div>
                  </div>

                  <div className="job-preview-content text-[#646464] flex items-center align-center ">
                    <div>
                      <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                        <FontAwesomeIcon icon={faLocationDot} />
                        <p>{`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""
                          }`}</p>
                      </div>
                      <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                        <FontAwesomeIcon icon={faVenus} />
                        <p>{checkPreferences(item?.jobCreatorDetails)}</p>
                      </div>
                      <div className="mt-5 mb-5">
                        <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                          <FontAwesomeIcon icon={faClock} />
                          <p>
                            {item?.repeatCycle == 1
                              ? "One-off"
                              : item?.repeatCycle == 2
                                ? "Daily"
                                : item?.repeatCycle == 3
                                  ? "Weekly"
                                  : item?.repeatCycle == 4
                                    ? "Monthly"
                                    : "Custom"}
                          </p>
                        </div>
                      </div>
                      <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>Start date: {moment(item?.startDate).format("DD-MM-YYYY") || ""}</p>
                      </div>
                      <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>End date: {moment(item?.endDate).format("DD-MM-YYYY") || ""}</p>
                      </div>
                      <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>Start time: {convertTo12Hour((item?.startTime || ""))}</p>
                      </div>
                      <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                        <FontAwesomeIcon icon={faCalendar} />
                        <p>End time: {convertTo12Hour((item?.endTime || ""))}</p>
                      </div>
                    </div>
                  </div>
                  <div className="theme-grey-type job-preview-content">
                    <div className="flex">
                      <div className="">
                        {/* {item?.allServices?.map((v, index) => ( */}
                        <h2
                          // key={index}
                          className="font-Poppins-Medium text-black font-size-15px capitalize"
                        >
                          {item?.service?.servicename}
                        </h2>
                        {/* ))} */}
                      </div>
                    </div>
                    <div className="">
                      <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
                        {item?.description || ""}
                      </p>
                    </div>
                    <span style={{ color: "#004540", fontSize: 16 }}>
                      {activeTab === "onboard"
                        ? `This job ${jobStartDateWithTime(item?.startDate || "", item?.startTime)}`
                        : activeTab === "completed"
                          ? `This job is completed ${getTimeDifference(item?.jobApplicationUpdateTime || "")
                          }`
                          : activeTab === "cancelled"
                            ? `This job is cancelled ${getTimeDifference(item?.jobApplicationUpdateTime || "")
                            }`
                            : ""}
                    </span>
                    <div className="flex">
                      <div className="job-pre-perhour-adminside">
                        <button className=" text-white font-Poppins-Regular font-size-14px">
                          $ {item?.budget || 0}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row justify-center items-end flex-wrap gap-2">
                  {activeTab === "onboard" ? (
                    <>
                      <button
                        onClick={() => setCallModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faPhone} />
                        Call
                      </button>

                      <button
                        onClick={() => setCommentModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                        Comment
                      </button>

                      <button
                        onClick={() => setRescheduleModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faCalendarDays} />
                        Reschedule
                      </button>

                      <JobCompleteRequest
                        jobId={item?._id}
                        completedFucntion={() => handleCallResponseSuccess}
                        btnClass={
                          "height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                        }
                      />

                      <JobCancelButton
                        jobId={item?._id}
                        setActiveTab={setActiveTab}
                        btnClass={
                          "height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                        }
                      />

                      <button
                        onClick={() => setReviewModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faStar} />
                        Review
                      </button>
                      <button
                        onClick={() => setDisputeModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-2 flex gap-4"
                      >
                        <FontAwesomeIcon
                          className="exclamation-mark"
                          icon={faExclamation}
                        />
                        Start dispute
                      </button>
                    </>
                  ) : activeTab === "completed" ? (
                    <>
                      <button
                        onClick={() => setCallModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faPhone} />
                        Call
                      </button>

                      <button
                        onClick={() => setCommentModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                        Comment
                      </button>
                      <button
                        onClick={() => setReviewModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faStar} />
                        Review
                      </button>
                    </>
                  ) : activeTab === "cancelled" ? (
                    <>
                      <button
                        onClick={() => setCallModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        {" "}
                        <FontAwesomeIcon icon={faPhone} />
                        Call
                      </button>
                      <button
                        onClick={() => setCommentModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                        Comment
                      </button>
                      <button
                        onClick={() => setReviewModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                      >
                        <FontAwesomeIcon icon={faStar} />
                        Review
                      </button>
                      <button
                        onClick={() => setDisputeModal(true)}
                        className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-2 flex gap-4"
                      >
                        <FontAwesomeIcon
                          className="exclamation-mark"
                          icon={faExclamation}
                        />
                        Start dispute
                      </button>
                      <JobReopen
                        jobId={item?._id || ""}
                        cancelledBy={item?.cancelledBy || ""}
                      />

                    </>
                  ) : (
                    ""
                  )}
                </div>
                <JobRequestAndHistory
                  opponentData={item?.applicantDetails}
                  jobId={item?._id || ""}
                  logUpdated={logUpdated}
                  setLogUpdated={setLogUpdated}
                />
              </>
            ) : (
              <p className="text-center font-size-15px font-Poppins-SemiBold theme-color-green">
                No jobs
              </p>
            )}
          </>
        )}
      </div>
      {callModal && (
        <ShowNumber
          onClose={() => setCallModal(false)}
          data={item?.applicantDetails || {}}
          jobId={item?._id || ""}
          successFunction={handleCallResponseSuccess}
        />
      )}
      {commentModal && (
        <AddCommentJob
          onClose={() => setCommentModal(false)}
          jobId={item?._id || ""}
          successFunction={handleCallResponseSuccess}
        />
      )}
      {rescheduleModal && (
        <JobRescheduleModal
          onClose={() => setRescheduleModal(false)}
          jobId={item?._id || ""}
          startDate={item?.startDate || ""}
          startTime={item?.startTime || ""}
          endDate={item?.endDate || ""}
          endTime={item?.endTime || ""}
          successFunction={handleRescheduleResponseSuccess}
        />
      )}
      {successModal && (
        <div className="modal">
          <div className="Cancellation-modal-content relative">
            <button
              onClick={() => setSuccessModal(false)}
              className="absolute job-pre-edit"
            >
              <FontAwesomeIcon
                className="x-icon-admin-opr"
                icon={faX}
                style={{ color: "#ffffff" }}
              />
            </button>
            <div className="cancellation-envolpe-main">
              <div className="cancellation-envolpe">
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
            </div>
            <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">
              {successModalMsg}
            </h2>
          </div>
        </div>
      )}
      {reviewModal && (
        <JobReviewModal
          onCLose={() => setReviewModal(false)}
          isEdit={false}
          jobId={item?._id || ""}
          user={item?.applicantDetails as ApplicantDetails} // Ensure user is of type User
          successFunction={(msg: string) => handleCallResponseSuccess(msg)} // Function with a string argument
        />
      )}
      {disputeModal && (
        <StartJobDispute
          onClose={() => setDisputeModal(false)}
          jobId={item?._id || ""}
          // successFunction={handleCallResponseSuccess}
          successFunction={(msg: string) => handleCallResponseSuccess(msg)} // Function with a string argument

        />
      )}
    </>
  );
}

export default ActiveSpecificJobs;
