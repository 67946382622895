

import React, { useEffect, useState } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal"
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisVertical,
    faPencil,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import httpRequest from "../../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import Isverified from "../../../assets/img/isMember.svg"
import PlayBtn from "../../../assets/img/playbtn.svg"
import Pencil from "../../../assets/img/pencil.svg"
import PauseBtn from "../../../assets/img/pausebtn.svg"
import EyeGreen from "../../../assets/img/eyeGreen.svg"
import ViewPostModal from "../../AdminPages/AdManager/Modals/ViewPostModal";
import PromoteModal from "../../../components/NewsFeeds/PromoteModal";
import { getRole } from "../../../config/Helpers/helpers";
import DeclineReasonImg from "../../../assets/img/declineReason.svg";
import DeclineReason from "../../../components/Modal/DeclineReason";
import Polygon from "../../../assets/img/Polygon.svg";


interface User {

    _id: string;
    firstName: string;
    lastName: string;
    email: string;

    role: string;
    verification: string;
    status: string;
    createdAt: string;
    [key: string]: any;
}
interface Paginated {
    totalItems: number;
}
interface ApiResponse {
    totalResults: number;
    paginated: Paginated;

    documents: User[];
    [key: string]: any;
}
interface StatusReponse {
    message?: string;
    [key: string]: any;
}
interface QueryParams {
    status: string;
    type: string;
    page: number;
    limit: number;
    group: string;

}

const AdsManager: React.FC = () => {
    const decoded = getRole();
    const userId = decoded?.userId;

    const [promoteModal, setPromoteModal] = useState<boolean>(false)
    const [audId, setAudId] = useState<string>("");
    const [isComponentLoad, setIsComponentLoad] = useState<boolean>(false); // for check component load or not
    const [bool, setBool] = useState<boolean>(false); // State for toggle updates

    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [trialUsers, setTrialUsers] = useState<User[]>([]); // State for trial users list

    const [temporary, setTempValue] = useState<string>(""); // State for temporary filter value
    const [totalPage, setTotalPage] = useState<number>(1); // State for total number of pages

    const [queryParams, setQueryParams] = useState<QueryParams>({
        status: "",
        type: "",
        page: 1,
        limit: 7,
        group: ""
    }); // State for query parameters used in API callsState for query parameters used in API calls
    const [DropDown, setDropDown] = useState<number | null>(null);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
    const [selectedPostId, setSelectedPostId] = useState<string>("");
    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});
    const [declineReasonModal, setDeclineReasonModal] = useState<string>("")
    const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);

    // Function to open the modal
    const handleOpenModal = () => {
        setDeclineModalOpen(true);
    };

    // Function to close the modal
    const handleCloseDeclineModal = () => {
        setDeclineModalOpen(false);
    };
    const handleViewPost = (postId: string) => {
        setSelectedPostId(postId);  // Set the post ID to the clicked post
        setIsModalOpen(true);       // Open the modal
    };
    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedPostId(""); // Reset the post ID
    };

    // Function to handle dropdown click
    // const handleDropDownClick = (index: number) => {
    //   if (DropDown === index) {
    //     setDropDown(null);
    //   } else {
    //     setDropDown(index);
    //   }
    // };
    const handleDropDownClick = (index: number, isFreeUser: boolean) => {
        if (DropDown === index) {
            setDropDown(null);
        } else {
            setDropDown(index);
        }
    };
    // Debounce function for filtering
    const handleDebounce = (value: string) => {
        if (value !== " ") {
            setTempValue(value);
        }
    };

    // Functionality: API call for get all trial user Admin
    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/promotions/my?${qs.stringify(queryParams)}` });
        // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/promotions/my` });
        if (res) {
            const count = res?.paginated?.totalItems || 0;
            // Calculate total pages based on page size and total results
            setTotalPage(Math.ceil(count / queryParams.limit));
            setTrialUsers(res?.documents); // Set trial users data from API response
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    // Effect hook to update query params when filter changes
    // useEffect(() => {
    //     if (isComponentLoad) {
    //         const debounceTimer = setTimeout(() => {
    //             setQueryParams({ ...queryParams, name: temporary, page: 1 });
    //         }, 1500);
    //         return () => clearTimeout(debounceTimer);
    //     }
    // }, [temporary]);

    // Effect hook to fetch data when query params change
    useEffect(() => {
        fetch();
        setIsComponentLoad(true);
    }, [bool, queryParams]);

    const handleChangeStatus = async (status: string, id: string) => {
        setLoadingStates(prevStates => ({ ...prevStates, [id]: true }));
        let apiPath = "";

        if (status === "active") {
            apiPath = `/api/ad/status/paused/${id}`;

        }
        else {
            apiPath = `/api/ad/status/active/${id}`;

        }
        const { res, err } = await httpRequest<ApiResponse>({
            method: 'patch',
            path: apiPath,
            params: {}
        })
        if (res) {
            setTrialUsers(prevUsers =>
                prevUsers.map(user =>
                    user._id === id ? { ...user, status: status === "active" ? "paused" : "active" } : user
                )
            );
            toast(res?.message, { type: 'success' })
        }
        else {
            toast(err?.message, { type: 'error' })

        }
        setLoadingStates(prevStates => ({ ...prevStates, [id]: false }));

    }


    return (
        <div>
            <div className="flex justify-center font-poppins-regular">
                {/* <AdminSidebar /> */}
                <HeaderGlobal />

                <div className="w-[78vw] ">
                    <div className="pt-24 Admin-main-operator-parent">
                        <div className="pb-6 pl-6 pr-10">
                            <div>
                                <h2 className="font-size-36px font-Poppins-Medium">
                                    Ads manager
                                </h2>
                                <p> <span className="font-size-16px font-Poppins-Bold">Dashboard /</span> <span className="font-Poppins-Medium"> Ads manager</span></p>
                            </div>
                            <div className=" operator-main-admin mt-6 flex justify-between items-center">
                                <div className="flex flex-row gap-4">

                                    <div className="relative w-fit">
                                        <span className="text-[#B5B5B5] font-size-13px font-Poppins-Medium custome-select-label">Promotion type</span>
                                        <img className="polygon-2" src={Polygon} alt="" />
                                        <select className="font-Poppins-Medium font-size-15px custom-select-filter-2"
                                            onChange={(e) =>
                                                setQueryParams({
                                                    ...queryParams,
                                                    type: e.target.value,
                                                    page: 1,
                                                })
                                            }
                                        >
                                            <option value="">All</option>
                                            <option value="profile">Profile promotion</option>
                                            <option value="post">Post promotion</option>
                                        </select>
                                    </div>
                                    <div className="relative w-fit">
                                        <span className="text-[#B5B5B5] font-size-13px font-Poppins-Medium custome-select-label">Promotion status</span>
                                        <img className="polygon-2" src={Polygon} alt="" />
                                        <select className="font-Poppins-Medium font-size-15px custom-select-filter-2"
                                            onChange={(e) =>
                                                setQueryParams({
                                                    ...queryParams,
                                                    status: e.target.value,
                                                    page: 1,
                                                })
                                            }
                                        >
                                            <option value="">All</option>
                                            <option value="in-review">Pending</option>
                                            <option value="paused">Paused</option>
                                            <option value="active">Active</option>
                                            <option value="completed">Completed</option>
                                            <option value="declined">Declined</option>
                                        </select>
                                    </div>
                                    <div className="relative w-fit">
                                        <span className="text-[#B5B5B5] font-size-13px font-Poppins-Medium custome-select-label">Filter by date</span>
                                        <img className="polygon-2" src={Polygon} alt="" />
                                        <select className="font-Poppins-Medium font-size-15px custom-select-filter-2"
                                            onChange={(e) =>
                                                setQueryParams({
                                                    ...queryParams,
                                                    group: e.target.value,
                                                    page: 1,
                                                })
                                            }
                                        >
                                            <option value="">All</option>
                                            <option value="today">Today</option>
                                            <option value="maximum">Maximum</option>
                                            <option value="yesterday">Yesterday</option>
                                            <option value="threedays">Last 3 days</option>
                                            <option value="sevendays">Last 7 days</option>
                                            <option value="thirtydays">Last Month</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-5  w-full overflow-y-auto ads-manager-scroll pb-2">
                                <div className="w-[140vw] ">
                                    <ul
                                        style={{
                                            gridTemplateColumns: "14.6% 7.6% 7.6% 7.6% 5.6% 5.6% 7.6% 7.6% 7.6% 7.6% 5.6% 7.6% 7.6%",
                                        }}
                                        className="outer-detail-border main-Admin-status-action Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                                    >
                                        {[
                                            "Promotion name",
                                            "Type",
                                            "Packages",
                                            "Status",
                                            "Connections",
                                            "Views",
                                            "Engagement rate",
                                            "Objective achieved",
                                            "Date created",
                                            "Date ended",
                                            "Preview",
                                            "Action",
                                            "Edit",
                                            // "No of Logins",

                                        ].map((item) => (
                                            <li className="text-white" key={item}>
                                                {item || ""}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {isLoader ? (
                                    <div className="main-Admin-status-content mt-5 text-center">
                                        <CircularProgress style={{ color: "#00443f" }} />
                                    </div>
                                ) : trialUsers && trialUsers.length > 0 ? (
                                    trialUsers.map((item, index) => (
                                        <div key={index} className=" pt-5 w-[140vw] ">
                                            <ul
                                                style={{
                                                    gridTemplateColumns: "14.6% 7.6% 7.6% 7.6% 5.6% 5.6% 7.6% 7.6% 7.6% 7.6% 5.6% 7.6% 7.6%",
                                                }}
                                                className="under-detail-border Admin-status-content-Role main-Admin-status-content text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                                            >
                                                <li className="py-2 capitalize">
                                                    {/* <Link
                                                        className="underline flex flex-row gap-2 items-center capitalize"
                                                        to={`/public-profile/${item?._id}/view`}
                                                    > */}
                                                    {item?.title}
                                                    {/* {item?.freeUser === false &&
                                                            <img className="discussion-sidebar-icon-2" src={Isverified} alt="Verified-Icon" />

                                                        }

                                                    </Link> */}
                                                </li>
                                                <li>
                                                    <span className="capitalize">
                                                        {item?.refType}

                                                    </span>
                                                </li>
                                                <li className="capitalize">
                                                    <span className="txt-turncate">  {item?.promotionTitle}</span>

                                                </li>
                                                <li className={`""`} >
                                                    <span
                                                        className={` capitalize-first-letter w-[5.8vw] rounded-full ${item?.status === "in-review" ? "pending-ads" : item?.status === "declined" ? "declined-ads" : item?.status === "active" ? "active-ads" : "paused-ads"}`}

                                                    >
                                                        {item?.status === "in-review" ? "In review" : item?.status}

                                                    </span>
                                                </li>

                                                {/* <li>{item?.totalLogins}</li> */}
                                                <li>
                                                    {item?.connects}
                                                </li>
                                                <li>
                                                    {item?.views}
                                                </li>
                                                <li>
                                                    {item?.connects !== 0 ?

                                                        ((item?.connects) / (item?.views) * 100).toFixed(2)
                                                        :
                                                        0

                                                    }%
                                                    {/* {item?.connects} */}



                                                </li>

                                                <li>
                                                    {item.objective === "views" ?
                                                        (
                                                            <span>
                                                                {((item?.views) / (item?.maxCount) * 100).toFixed(2)}%
                                                            </span>
                                                        )

                                                        :
                                                        (
                                                            <span>
                                                                {((item?.connects) / (item?.maxCount) * 100).toFixed(2)}%
                                                            </span>
                                                        )
                                                    }
                                                </li>
                                                <li>
                                                    {moment(item?.createdAt).format("DD-MM-YYYY") ||
                                                        "-"}
                                                </li>
                                                <li>
                                                    {item?.updatedAt ? moment(item?.updatedAt).format("DD-MM-YYYY") :
                                                        "-"}
                                                </li>
                                                <li className="flex justify-center">

                                                    {item?.refType === "post" ?

                                                        <button
                                                            onClick={() => handleViewPost(item?.refId)}
                                                        >
                                                            <span className="flex justify-center items-center">
                                                                <img

                                                                    src={EyeGreen} className="start-btn" alt="play-btn" />                                                            </span>
                                                            {/* <span className="font-size-15px font-Poppins-Regular text-left">View post</span> */}
                                                        </button>
                                                        :
                                                        <button
                                                            onClick={() => handleViewPost(item?.refId)}
                                                        >
                                                            <span className="flex justify-center items-center">
                                                                <img

                                                                    src={EyeGreen} className="start-btn" alt="play-btn" />                                                            </span>
                                                            {/* <span className="font-size-15px font-Poppins-Regular text-left">View Profile</span> */}
                                                        </button>


                                                    }
                                                    {/* <img

                                                        src={EyeGreen} className="start-btn" alt="play-btn" /> */}

                                                </li>
                                                <li>
                                                    {(item?.status === "active" || item?.status === "paused") ?
                                                        <>
                                                            {loadingStates[item?._id] ? (
                                                                // Show loader while the API is processing
                                                                <CircularProgress
                                                                    style={{ width: 16, height: 16, color: "#00443f" }}
                                                                />
                                                            ) : (
                                                                <>
                                                                    {item?.status === "active" ? (
                                                                        <button
                                                                            onClick={() => handleChangeStatus(item?.status, item?._id)}
                                                                        >
                                                                            <img src={PauseBtn} className="start-btn" alt="pause-btn" />
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            onClick={() => handleChangeStatus(item?.status, item?._id)}
                                                                        >
                                                                            <img src={PlayBtn} className="start-btn" alt="play-btn" />
                                                                        </button>
                                                                    )}
                                                                </>
                                                            )}


                                                        </>
                                                        :

                                                        <>
                                                            <button disabled>
                                                                <img src={PlayBtn} className="start-btn opacity-45" alt="play-btn" />
                                                            </button>
                                                            {/* 
                                                            <button disabled>
                                                                <img src={PauseBtn} className="start-btn opacity-45" alt="pause-btn" />
                                                            </button> */}
                                                        </>

                                                    }



                                                </li>
                                                <li>
                                                    {item?.status !== "declined" ?

                                                        <button
                                                            onClick={() => {
                                                                setAudId(item?.audienceId)
                                                                setPromoteModal(true)
                                                            }}
                                                        >
                                                            <img src={Pencil} className="start-btn" alt="play-btn" />
                                                        </button>

                                                        :
                                                        <button

                                                            onClick={() => {
                                                                handleOpenModal()
                                                                setDeclineReasonModal(item?.reason)
                                                            }}
                                                        >
                                                            <img src={DeclineReasonImg} className="start-btn" alt="play-btn" />
                                                        </button>


                                                    }


                                                </li>

                                                {/* <li className="relative font-size-30px">
                                                    <FontAwesomeIcon
                                                        className="cursor-pointer"
                                                        icon={faEllipsisVertical}
                                                        onClick={() => handleDropDownClick(index, item.freeUser)}
                                                    />
                                                    <div
                                                        className={`dropDownElipsis ${DropDown === index ? "DropDownActive" : ""
                                                            }`}
                                                    >
                                                        <div className="flex flex-col items-start">
                                                            <Link
                                                                to={`/public-profile/${item?._id}/view`}
                                                                className="font-size-15px text-[#2F2F31] flex items-center gap-[1px] font-Poppins-Regular pb-2"
                                                            >
                                                                <FontAwesomeIcon icon={faPencil} />
                                                                View profile
                                                            </Link>

                                                            {
                                                                !item.freeUser &&
                                                                <Link
                                                                    to={`/user-billing-detail/${item?.email}/view`}
                                                                    className="font-size-15px text-[#2F2F31] flex items-center gap-[1px] font-Poppins-Regular"
                                                                >
                                                                    <img
                                                                        className="billing-detail-invoice"
                                                                        src="https://edgie.backslashwebs.com/imgs/Billing-Detail-invoice.png"
                                                                        alt="Billing Detail"
                                                                    />
                                                                    Billing detail
                                                                </Link>
                                                            }



                                                        </div>
                                                    </div>
                                                </li> */}
                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <div className="main-Admin-status-content mt-5">
                                        <p className="text-center">No providers found</p>
                                    </div>
                                )}
                                <PaginationComponet
                                    total={totalPage}
                                    setCurrentPage={(e) =>
                                        setQueryParams({ ...queryParams, page: e })
                                    }
                                    currentPage={queryParams.page}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ViewPostModal

                isOpen={isModalOpen}
                postId={selectedPostId}
                onClose={handleCloseModal}

            />
            {promoteModal && (
                <PromoteModal refType="profile"
                    refId={userId || ""}
                    isEdit={true}
                    audId={audId}
                    onClose={() => setPromoteModal(false)} />
            )}
            {isDeclineModalOpen && (

                <DeclineReason Title="Reason" description={declineReasonModal} onClose={handleCloseDeclineModal} />
            )}
        </div>
    );
};

export default AdsManager;