/**
 * AvailabilityEdit Component
 * update the availability of user
 * File: AvailabilityEdit.jss
 * Author: Developer
 * Date: 03-5-24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import InputField from "../../../components/AllDashboardPagesComponents/InputField";

import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { getRole } from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareXmark } from "@fortawesome/free-solid-svg-icons";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";
type Availability = {
  [key: string]: string[][];
};
interface IApiResponse {
  message: string;
}
function AvailabilityEdit() {
  const decoded = getRole();
  const UserRole = decoded?.role;
  // const { UserRole } = getRole(); // get user Role

  // State for loader and availabilities
  const [availabilityLoader, setAvailabilityLoader] = useState<boolean>(false);
  const [availabilities, setAvailabilities] = useState<Availability>({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const dispatch = useDispatch();
  const { personalData } = useSelector((store: RootState) => store.userProfile); // get user data

  /**
   * Adds a new time slot for the specified day.
   * @param {boolean} switchStatus - Status of the switch (on/off).
   * @param {string} day - Day of the week.
   */
  // const handleAddSlot = (switchStatus: boolean, day: string) => {
  //   if (switchStatus) {
  //     setAvailabilities((prevSlots) => ({
  //       ...prevSlots,
  //       [day]: [...prevSlots[day], ["", ""]], // Add a new time slot with null values for start and end times
  //     }));
  //   } else {
  //     setAvailabilities((prevSlots) => ({
  //       ...prevSlots,
  //       [day]: [], // Clear all time slots for the day
  //     }));
  //   }
  // };
  const handleAddSlot = (switchStatus: boolean, day: string) => {
    setAvailabilities((prevSlots) => ({
      ...prevSlots,
      [day]: switchStatus ? [...(prevSlots[day] || []), ["", ""]] : [], // Ensure it's an array or empty it
    }));
  };


  /**
   * Removes a time slot at the specified index for the specified day.
   * @param {string} day - Day of the week.
   * @param {number} index - Index of the slot to be removed.
   */
  const handleRemoveSlot = (day: string, index: number) => {
    setAvailabilities((prevSlots) => ({
      ...prevSlots,
      [day]: prevSlots[day].filter((_, i) => i !== index), // Remove the time slot at the specified index
    }));
  };

  /**
   * Updates the time value for a specific slot.
   * @param {string} day - Day of the week.
   * @param {number} index - Index of the slot.
   * @param {number} type - Type of the time (0 for start time, 1 for end time).
   * @param {string} value - New time value.
   */
  const handleChange = (day: string, index: number, type: number, value: string) => {
    setAvailabilities((prev) => {
      const updatedSlots = (prev[day] || []).map((slot) => [...slot]); // Ensure it's an array
      updatedSlots[index][type] = value;

      const fromTime = updatedSlots[index][0];
      const toTime = updatedSlots[index][1];

      if (fromTime && toTime && fromTime >= toTime) {
        toast(`${day}: Time must be greater than previous time.`, {
          type: "error",
        });
        const prevState = (prev[day] || []).map((slot) => [...slot]); // Ensure it's an array
        prevState[index][type] = "";
        return {
          ...prev,
          [day]: prevState,
        };
      }
      return {
        ...prev,
        [day]: updatedSlots,
      };
    });
  };

  // const transformAvailabilities = (availabilities: Availability) => {
  //   const transformed: { [key: string]: string[] } = {};
  //   const weekdayKeys = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  //   weekdayKeys.forEach((day) => {
  //     transformed[day] = Array.isArray(availabilities[day])
  //       ? availabilities[day].flat()
  //       : [];
  //   });
  //   return transformed;
  // };

  // Functionality: API call for for update avaiability
  const handleAvailabilitySubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAvailabilityLoader(true);
    // const transformedAvailabilities = transformAvailabilities(availabilities);
    const payload = Object.keys(availabilities).reduce((result, key) => {
      if (key === "monday" || key === "tuesday" || key === "wednesday" || key === "thursday" || key === "friday" || key === "saturday" || key === "sunday") {
        result[key] = availabilities[key];
      }
      return result;
    }, {} as Availability);
    const { res, err } = await httpRequest<IApiResponse>({
      method: "put",
      path: `/api/provider/avalability`,
      params: payload,
    });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setAvailabilityLoader(false);
  };
  {
    /* avaiability update */
  }

  useEffect(() => {
    console.log(personalData?.availability, "personalData?.availabiltypersonalData?.availabilty")
    if (personalData) {
      setAvailabilities(personalData?.availability || {});
    }
  }, [personalData]);

  // const weekdayKeys = Object.keys(availabilities).filter(
  //   (key) =>
  //     key === "monday" ||
  //     key === "tuesday" ||
  //     key === "wednesday" ||
  //     key === "thursday" ||
  //     key === "friday" ||
  //     key === "saturday" ||
  //     key === "sunday"
  // );
  const weekdayKeys = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];

  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title="Availability" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
            <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
              Available days and times
            </h4>
            <p className="font-size-15px font-Poppins-Regular theme-color-green flex">
              Keep this up to date so you get enquiries that suit you.
            </p>
            <hr color="#707070" className="my-3" />
            <form onSubmit={handleAvailabilitySubmit}>
              {/* {weekdayKeys.map((day) => (
                <div key={day}>
                  <div>
                    <div className="flex items-center gap-3">
                      <input
                        className="individiual-checkbox individiualinput-slider"
                        type="checkbox"
                        defaultChecked={availabilities[day].length > 0}
                        onChange={(event) =>
                          handleAddSlot(event.target.checked, day)
                        }
                      />
                      <span className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                        {day}
                      </span>
                    </div>
                    {availabilities[day].map((item, index) => (
                      <div key={index} className="grid col-grid-profile">
                        <InputField
                          type="time"
                          name="email"
                          labelTop="Start time"
                          value={item[0]}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(day, index, 0, e.target.value)
                          }
                          required
                        />
                        <InputField
                          type="time"
                          name="email"
                          labelTop="End time"
                          value={item[1]}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange(day, index, 1, e.target.value)
                          }
                          required
                        />
                        {index > 0 && (
                          <div>
                            <button
                              type="button"
                              onClick={() => handleRemoveSlot(day, index)}
                              className="cross-availa"
                            >
                              <FontAwesomeIcon icon={faSquareXmark} />
                            </button>
                          </div>
                        )}
                      </div>
                    ))}

                    {availabilities[day].length > 0 && (
                      <button
                        type="button"
                        onClick={() => handleAddSlot(true, day)}
                        className="font-size-15buttonx font-Poppins-Medium theme-color-green flex mt-2"
                      >
                        + Add another time
                      </button>
                    )}
                  </div>
                  <hr color="#707070" className="my-3" />
                </div>
              ))} */}
              {weekdayKeys.map((day) => (
                <div key={day}>
                  <div className="flex items-center gap-3">
                    <input
                      className="individiual-checkbox individiualinput-slider"
                      type="checkbox"
                      checked={availabilities[day]?.length > 0}
                      onChange={(event) => handleAddSlot(event.target.checked, day)}
                    />
                    <span className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize">
                      {day}
                    </span>
                  </div>
                  {availabilities[day]?.map((item, index) => (
                    <div key={index} className="grid col-grid-profile">
                      <InputField
                        type="time"
                        name="email"
                        labelTop="Start time"
                        value={item[0]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(day, index, 0, e.target.value)
                        }
                        required
                      />
                      <InputField
                        type="time"
                        name="email"
                        labelTop="End time"
                        value={item[1]}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          handleChange(day, index, 1, e.target.value)
                        }
                        required
                      />
                      {index > 0 && (
                        <div>
                          <button
                            type="button"
                            onClick={() => handleRemoveSlot(day, index)}
                            className="cross-availa mt-[18px]"
                          >
                            <FontAwesomeIcon icon={faSquareXmark} />
                          </button>
                        </div>
                      )}
                    </div>
                  ))}

                  {availabilities[day]?.length > 0 && (
                    <button
                      type="button"
                      onClick={() => handleAddSlot(true, day)}
                      className="font-size-15buttonx font-Poppins-Medium theme-color-green flex mt-2"
                    >
                      + Add another time
                    </button>
                  )}
                  <hr color="#707070" className="my-3" />
                </div>
              ))}
              <button
                disabled={availabilityLoader}
                type="submit"
                className="mb-4 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Save and continue{" "}
                {availabilityLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default AvailabilityEdit;
