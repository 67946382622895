import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface SelectSmallProps {
  currentValue?: string;
  setAgeGroup?: (value: string) => void;
}

export default function SelectSmall({ currentValue = "", setAgeGroup = () => {} }: SelectSmallProps) {

  const handleChange = (event: SelectChangeEvent) => {
    const value = event.target.value as string;  // Cast the value to string
    setAgeGroup(value);
  };
  // const handleChange = (event) => {
  //   let value = event.target.value;
  //   if (value === 'All') {
  //     setAgeGroup("");
  //   }
  //   else {
  //     setAgeGroup(value);
  //   }
  // };

  return (
    <div className="availability">
      <FormControl sx={{ m: 1, minWidth: 120, backgoundColor: '#ffffff', fontFamily: 'Poppins' }} size="small">
        <InputLabel id="demo-select-small-label" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Age group</InputLabel>
        <Select
          labelId="demo-select-small-label"
          id="demo-select-small"
          value={currentValue}
          label="Age Group"
          onChange={handleChange}
        >
          <MenuItem value="" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>Any age group</MenuItem>
          <MenuItem value="17-21" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>17-21 years</MenuItem>
          <MenuItem value="22-59" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>22-59 years</MenuItem>
          <MenuItem value="60+" sx={{ fontFamily: 'Poppins', fontSize: '15px' }}>60+</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};