import React, { useState, useEffect, useRef, ChangeEvent } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faMagnifyingGlass,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";

import { toast } from 'react-toastify';
import { getRole } from "../../../config/Helpers/helpers";
import Skeleton from '@mui/material/Skeleton';
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import httpRequest from "../../../config/Helpers/httpRequest";
import UnpaidModal from "../../../components/Modal/UnpaidModal";
interface User {
  _id: string;
  profileImageUrl: string;
  firstName: string;
  lastName: string;
  role: string;
  address: string;
  roleCategory?: string;
}

interface CompareFactors extends User {
  JobPosted: number;
  Jobcompleted: number;
  address: string;
  firstName: string;
  jobCanceled: number;
  lastName: string;
  message: string;
  postCode: string;
  profileImageUrl: string;
  rating: number;
  smoker: boolean;
  state: string;
  suburb: string;


  [key: string]: any;
}
interface SuggestedUser extends User {
  providerCategory?: string;
}

interface ApiResponse {

  documents: SuggestedUser[];
  compareFactors: CompareFactors;
}

interface ErrorResponse {
  status?: number;
  message?: string;
}
const ParticipantCompare: React.FC = () => {

  const [addUser, setAddUser] = useState(false);
  const [unpaidModalShow, setUnpaidModalShow] = useState(false);
  const [error, setError] = useState<string>("");
  // const ROLE = getRole()?.UserRole || false;
  const decoded = getRole();
  const ROLE = decoded?.role || false;;

  const toggleAddUser = () => {
    setAddUser(!addUser);
  };

  // 22-04-24
  const providerArr = [
    { key: 'Jobcompleted', title: 'Jobs completed' },
    { key: 'jobCanceled', title: 'Jobs cancelled' },
    { key: 'services', title: 'Services offered' },
    { key: 'rating', title: 'Rating' },
    { key: 'weekdays', title: 'Hourly rate weekdays' },
    { key: 'saturday', title: 'Hourly rate saturday' },
    { key: 'sunday', title: 'Hourly rate sunday' },
    { key: 'yearsOfExperience', title: 'Years of experience' },
    { key: 'activeDaysCount', title: 'Available days' },
  ];

  const participantArr = [
    { key: 'JobPosted', title: 'Jobs posted', type: 'number' },
    { key: 'jobCanceled', title: 'Jobs cancelled', type: 'number' },
    { key: 'rating', title: 'Rating', type: 'number' },
    { key: 'smoker', title: 'Smoker', type: 'boolean' },
    // { key: 'covid', title: 'Covid Vaccinated', type: 'boolean' },
    // { key: 'flu', title: 'FLU Vaccinated', type: 'boolean' },
  ];

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

  const navigate = useNavigate();
  const location = useLocation();
  let leftUserId = location?.state?.id || false;
  let type = location?.state?.type || false;
  let JID = location?.state?.jobCompare || false;

  const [leftLoader, setLeftLoader] = useState<boolean>(false);
  const [rightLoader, setRightLoader] = useState<boolean>(false);
  const [leftSide, setLeftSide] = useState<CompareFactors | null>(null);
  const [rightSide, setRightSide] = useState<CompareFactors | null>(null);
  const [searchVal, setSearchVal] = useState<string>("");
  const [suggestedPeople, setSuggestedPeople] = useState<SuggestedUser[]>([]);
  const [suggestLoader, setSuggestLoader] = useState<boolean>(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setSearchVal(value);
    fetchSuggested(value);

    // if (value && value.length > 0 && value.trim() !== '') {
    //   if (timeoutIdRef.current) {
    //     clearTimeout(timeoutIdRef.current);
    //   }
    //   if (timeoutIdRef.current) {
    //     timeoutIdRef.current = setTimeout(() => {
    //       fetchSuggested(value);
    //     }, 500);
    //   }


    // } else {
    //   fetchSuggested("");
    //   if (timeoutIdRef.current) {
    //     clearTimeout(timeoutIdRef.current);
    //   }
    // }
  };
  // Functionality: API call to retrieve autofill/hint suggestions for comparing suggested individuals.
  const fetchSuggested = async (text: string) => {
    setSuggestLoader(true);
    const ApiPath = type === "participant" ? "/api/search/compare/participant" : "/api/search/compare/provider";
    // const { res, err } = await httpRequest<ApiResponse>({ path: `/api/search/compareUser/${type}?q=${text}&jobId=${JID}` });
    const { res, err } = await httpRequest<ApiResponse>({ path: `${ApiPath}?q=${text}` });
    if (res) {
      setSuggestedPeople(res?.documents);
    } else {
      if (err?.status === 402) {
        setUnpaidModalShow(true)
        setError(err?.message)

      }
      toast(err?.message, { type: "error" });
    }
    setSuggestLoader(false);
  };

  const handleReset = () => {
    setRightSide(null);
    setSearchVal("");
    fetchSuggested("");
  };

  const handleRightCompare = (id: string) => {
    setAddUser(false);
    fetch(id, setRightLoader, setRightSide);
  };

  // Functionality: API call to retrieve details of the user to be compared.
  const fetch = async (id: string, setLoader: React.Dispatch<React.SetStateAction<boolean>>, setData: React.Dispatch<React.SetStateAction<CompareFactors | null>>) => {
    setLoader(true);
    const ApiPath = type === "participant" ? "/api/search/compare/participant/" : "/api/search/compare/provider/";

    const { res, err } = await httpRequest<CompareFactors>({ path: `${ApiPath}${id}` });
    if (res) {
      console.log("by id search", res)
      setData(res?.compareFactors);
    } else {
      if (err?.status === 402) {
        setUnpaidModalShow(true)
        setError(err?.message)

      }
      toast(err?.message, { type: "error" });
    }
    setLoader(false);
  };

  useEffect(() => {
    if (!leftUserId || !type) {
      navigate(-1);
    }
    else {
      fetch(leftUserId, setLeftLoader, setLeftSide);
      fetchSuggested("");
    }
  }, [leftUserId]);

  return (
    <div className="">
      {
        ROLE === "provider" || ROLE === "participant"
          ?
          <HeaderGlobal />
          :
          <>
            {/* <AdminHeader /> */}
          </>
      }
      <div className="container-1480 h-3vw">
        <div className=" Admin-main-operator-parent">
          <div className="pb-6 px-0 lg:px-6 md:px-6">
            <div className="flex justify-between items-center px-2 lg:px-0">
              <div>
                <h2 className="font-size-36px font-Poppins-Medium">Compare</h2>
              </div>
              <Link to="">
                <img
                  className="profile-back-icon"
                  src={'https://edgie.backslashwebs.com/imgs%2FBeck-icon.png'}
                  alt=""
                />
              </Link>
            </div>
            <div className="participant-compare-content-parent  flex flex-col">
              <div className="compare-box-parent bg-white">
                <div className="compare-box flex items-center justify-between relative">
                  <div className="comparison-info comparison-info-gap flex flex-col items-center relative">
                    <img
                      className="comparison-info-img"
                      src={`${leftSide?.profileImageUrl}`}
                      // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${leftSide?.profileImageUrl}`}
                      alt=""
                    />
                    <h4 className="font-size-15px font-Poppins-Medium text-white leading-none capitalize">
                      {`${leftSide?.firstName || ''} ${leftSide?.lastName || ''}`}
                    </h4>
                    <h4 className="font-size-13px font-Poppins-Medium text-white leading-none capitalize-first-letter">
                      {leftSide?.role || type || ''}
                    </h4>
                    <p className="flex items-center gap-2 text-white font-Poppins-Regular font-size-13px leading-none">
                      <FontAwesomeIcon icon={faLocationDot} />
                      {`${leftSide?.address || ''}`}
                    </p>
                  </div>
                  <span className="flex flex-col circles-center-participant-compare-parent absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2">
                    <span className="theme-bg-yellow circles-center-participant-compare inline-block"></span>
                    <span className="theme-bg-yellow circles-center-participant-compare inline-block"></span>
                    <span className="theme-bg-yellow circles-center-participant-compare inline-block"></span>
                  </span>
                  <div className="add-comparison-info comparison-info-gap flex flex-col items-center relative">
                    {rightSide?.profileImageUrl &&

                      <FontAwesomeIcon onClick={handleReset} className="absolute top-0 right-0 text-white cursor-pointer" icon={faCircleXmark} style={{ zIndex: 5 }} />

                    }

                    {rightSide

                      ?
                      <div className="comparison-info comparison-info-gap flex flex-col items-center relative">
                        <img
                          className="comparison-info-img"
                          src={`${rightSide?.profileImageUrl}`}
                          // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${rightSide?.profileImageUrl}`}
                          alt=""
                        />
                        <h4 className="font-size-15px capitalize-first-letter font-Poppins-Medium text-white leading-none">
                          {`${rightSide?.firstName || ''} ${rightSide?.lastName || ''}`}
                        </h4>
                        <h4 className="font-size-13px font-Poppins-Medium text-white leading-none capitalize-first-letter">
                          {rightSide?.role || type || ''}
                        </h4>
                        <p className="flex items-center gap-2 text-white font-Poppins-Regular font-size-13px leading-none">
                          <FontAwesomeIcon icon={faLocationDot} />
                          {`${rightSide?.address || ''}`}
                        </p>
                      </div>
                      :
                      <>
                        <img
                          className="comparison-info-img"
                          src={'https://edgie.backslashwebs.com/imgs/dummy-img-user.png'}
                          alt=""
                        />
                        <button
                          onClick={toggleAddUser}
                          className="add-user-btn font-size-15px font-Poppins-SemiBold theme-color-green bg-white flex items-center justify-center"
                          type="button"
                        >
                          Add User
                        </button>
                      </>
                    }
                  </div>
                  {addUser && (
                    <div className="absolute theme-bg-cream-white adding-user-box-for-compare">
                      <div className="adding-user-box-for-compare-inner">
                        <div className="relative">
                          <FontAwesomeIcon
                            className="font-size-15px absolute top-1/2 transform -translate-y-1/2 left-3"
                            style={{ color: "#8F8787" }}
                            icon={faMagnifyingGlass}
                          />
                          <input
                            className="adding-user-box-for-compare-search-bar w-full outline-none font-Poppins-Regular font-size-15px"
                            style={{ color: "#8F8787" }}
                            type="search"
                            placeholder="Search Users"
                            value={searchVal}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="flex flex-col gap-2 py-3">
                          {suggestLoader
                            ?
                            <CircularProgress style={{ width: 26, height: 26, margin: 'auto', color: '#004540' }} />
                            :
                            suggestedPeople && suggestedPeople.length > 0 ?
                              suggestedPeople
                                .filter(v => v?._id !== leftUserId)
                                .map((item, index) => (
                                  <div onClick={() => handleRightCompare(item?._id)} key={index} className="flex items-center justify-between cursor-pointer">
                                    <div className="flex items-center gap-2">
                                      <img className="adding-user-box-for-compare-availaible-img" src={`${item?.profileImageUrl}`} alt="" />
                                      {/* <img className="adding-user-box-for-compare-availaible-img" src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`} alt="" /> */}
                                      <span className="font-Poppins-SemiBold theme-color-green font-size-15px leading-none capitalize">{`${item?.firstName || ''} ${item?.lastName || ''}`}</span>
                                    </div>
                                    <span className="font-size-10px text-black font-Poppins-Medium capitalize-first-letter">{item?.roleCategory || item?.role || type || ''}</span>
                                  </div>
                                ))
                              :
                              <p>No user</p>
                          }
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <h3
                  className="leading-none theme-color-green font-Poppins-SemiBold font-size-18px py-1 text-center"
                  style={{ borderBottom: "6px solid #00A297" }}
                >
                  Overall
                </h3>
              </div>
              {type === "provider"
                ?
                <div className="points-box-participant-compare flex flex-col bg-white">
                  {providerArr.map((item, index) => (
                    <div key={index} className="flex justify-between items-center">
                      {leftLoader ?
                        <Skeleton variant="rounded" width={40} height={30} />
                        :
                        <span
                          className="font-size-18px font-Poppins-SemiBold text-white flex items-center justify-center points-box-after-comparison-points"
                          style={
                            (item?.key === "jobCanceled" || item?.key === "weekdays" || item?.key === "saturday" || item?.key === "sunday")
                              ?
                              { backgroundColor: (leftSide?.[item?.key] as number) >= ((rightSide?.[item?.key] as number) || 0) ? "#c9c9c9" : "#004540" }
                              :
                              { backgroundColor: (leftSide?.[item?.key] as number) >= ((rightSide?.[item?.key] as number) || 0) ? "#004540" : "#c9c9c9" }
                          }
                        >
                          {(leftSide?.[item?.key] ?? "-")}
                        </span>
                      }
                      <span className="points-box-comparison-tag theme-bg-green text-white font-size-18px font-Poppins-SemiBold flex items-center justify-center">
                        {item?.title || ''}
                      </span>
                      {rightLoader ?
                        <Skeleton variant="rounded" width={40} height={30} />
                        :
                        <span
                          className="font-size-18px font-Poppins-SemiBold text-white flex items-center justify-center points-box-after-comparison-points"
                          style={
                            (item?.key === "jobCanceled" || item?.key === "weekdays" || item?.key === "saturday" || item?.key === "sunday")

                              ?
                              { backgroundColor: (rightSide?.[item?.key] as number) >= (leftSide?.[item?.key] as number) ? "#c9c9c9" : "#004540" }

                              :
                              { backgroundColor: (rightSide?.[item?.key] as number) >= (leftSide?.[item?.key] as number) ? "#004540" : "#c9c9c9" }
                          }
                        >
                          {(rightSide?.[item?.key] ?? "-")}
                        </span>
                      }
                    </div>
                  ))}
                </div>
                :
                <>
                  {/* <div className="points-box-participant-compare flex flex-col bg-white">
                    {participantArr.map((item, index) => (
                      <div key={index} className="flex justify-between items-center">
                        {leftLoader ?
                          <Skeleton variant="rounded" width={40} height={30} />
                          :
                          <span
                            className="font-size-18px font-Poppins-SemiBold text-white flex items-center justify-center points-box-after-comparison-points"
                            style={
                              item?.key === "jobCanceled"
                                ?
                                { backgroundColor: (leftSide?.[item?.key] as number) >= ((rightSide?.[item?.key] as number) || 0) ? "#c9c9c9" : "#004540" }
                                :
                                { backgroundColor: (leftSide?.[item?.key] as number) >= ((rightSide?.[item?.key] as number) || 0) ? "#004540" : "#c9c9c9" }
                            }
                          >
                            {item.type === "boolean"
                              ?
                              leftSide?.[item?.key] ? 'Yes' : 'No'
                              :
                              leftSide?.[item?.key] || "0"
                            }
                          </span>
                        }
                        <span className="points-box-comparison-tag theme-bg-green text-white font-size-18px font-Poppins-SemiBold flex items-center justify-center">
                          {item?.title || ''}
                        </span>
                        {rightLoader ?
                          <Skeleton variant="rounded" width={40} height={30} />
                          :
                          <span
                            className="font-size-18px font-Poppins-SemiBold text-white flex items-center justify-center points-box-after-comparison-points"
                            style={
                              item?.key === "jobCanceled"

                                ?
                                { backgroundColor: (rightSide?.[item?.key] as number) >= (leftSide?.[item?.key] as number) ? "#c9c9c9" : "#004540" }

                                :
                                { backgroundColor: (rightSide?.[item?.key] as number) >= (leftSide?.[item?.key] as number) ? "#004540" : "#c9c9c9" }
                            }
                          >
                            {!rightSide ? "-"
                              :
                              item.type === "boolean"
                                ?
                                rightSide[item?.key] ? 'Yes' : 'No'
                                :
                                rightSide[item?.key] || "0"
                            }
                          </span>
                        }
                      </div>
                    ))}
                  </div> */}
                  <div className="points-box-participant-compare flex flex-col bg-white">
                    {participantArr.map((item, index) => (
                      <div key={index} className="flex justify-between items-center">
                        {leftLoader ? (
                          <Skeleton variant="rounded" width={40} height={30} />
                        ) : (
                          <span
                            className="font-size-18px font-Poppins-SemiBold text-white flex items-center justify-center points-box-after-comparison-points"
                            style={
                              item.key === "smoker"
                                ? { backgroundColor: leftSide?.smoker ? "#c9c9c9" : "#004540" } // Red for Yes, Green for No
                                : item.key === "jobCanceled"
                                  ? { backgroundColor: (leftSide?.[item.key] as number) >= (rightSide?.[item.key] || 0) ? "#c9c9c9" : "#004540" }
                                  : { backgroundColor: (leftSide?.[item.key] as number) >= (rightSide?.[item.key] || 0) ? "#004540" : "#c9c9c9" }
                            }
                          >
                            {item.type === "boolean"
                              ? leftSide?.[item.key]
                                ? "Yes"
                                : "No"
                              : leftSide?.[item.key] || "0"}
                          </span>
                        )}
                        <span className="points-box-comparison-tag theme-bg-green text-white font-size-18px font-Poppins-SemiBold flex items-center justify-center">
                          {item?.title || ""}
                        </span>
                        {rightLoader ? (
                          <Skeleton variant="rounded" width={40} height={30} />
                        ) : (
                          <span
                            className="font-size-18px font-Poppins-SemiBold text-white flex items-center justify-center points-box-after-comparison-points"
                            style={
                              item.key === "smoker"
                                ? { backgroundColor: rightSide?.smoker ? "#c9c9c9" : "#004540" } // Red for Yes, Green for No
                                : item.key === "jobCanceled"
                                  ? { backgroundColor: (rightSide?.[item.key] as number) >= (leftSide?.[item.key] || 0) ? "#c9c9c9" : "#004540" }
                                  : { backgroundColor: (rightSide?.[item.key] as number) >= (leftSide?.[item.key] || 0) ? "#004540" : "#c9c9c9" }
                            }
                          >
                            {!rightSide
                              ? "-"
                              : item.type === "boolean"
                                ? rightSide?.[item.key]
                                  ? "Yes"
                                  : "No"
                                : rightSide?.[item.key] || "0"}
                          </span>
                        )}
                      </div>
                    ))}
                  </div>

                </>
              }
            </div>
          </div>
        </div>
      </div>
      {unpaidModalShow && <UnpaidModal profile={true} error={error} onClose={() => setUnpaidModalShow(false)} />}

    </div >
  );
};

export default ParticipantCompare;