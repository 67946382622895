/**
 * ApprovedModal Component
 * Modal for approval & decline actions with a confirmation and cancel button.
 * File: ApprovedModal.js
 * Author: Developer
 * Date: 03-5-24
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";


/**
 * ApprovedModal Component
 * Renders a modal with a message, two buttons, and an optional loading spinner.
 * 
 * @param {Function} onClose - Function to call when the modal is closed.
 * @param {string} messagedecline - Message to display in the modal.
 * @param {string} leftBtnText - Text for the left button.
 * @param {string} rightBtnText - Text for the right button.
 * @param {boolean} loader - Boolean to show/hide the loading spinner.
 * @param {Function} conFirmFunction - Function to call when the right button is clicked.
 */

interface ApprovedModalProps {
  onClose: React.Dispatch<React.SetStateAction<boolean>>;
  messagedecline: string;
  leftBtnText: string;
  rightBtnText: string;
  loader: boolean;
  conFirmFunction: () => void;
  status?: "decline" | "accept";
}

const ApprovedModal: React.FC<ApprovedModalProps> = ({
  onClose = () => {},
  messagedecline = "",
  leftBtnText = "",
  rightBtnText = "",
  loader = false,
  conFirmFunction = () => {},
  status = "decline",
}) => {
    return (
    <div className="modal">
      <div className="Cancellation-modal-content relative">
        <button
          onClick={() => onClose(false)}
          className="absolute job-pre-edit"
        >
          <FontAwesomeIcon
            className="x-icon-admin-opr"
            icon={faX}
            style={{ color: "#ffffff" }}
          />
        </button>
        <div className="cancellation-envolpe-main">
          <div className="cancellation-envolpe">
            <FontAwesomeIcon icon={faEnvelope} />
          </div>
        </div>
        <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31] mb-5">{messagedecline}</h2>
        <div className="flex cancellation-mdl-btn justify-center gap-2">
          <button onClick={() => onClose(false)} className={status === "decline" ? "greenOutlineBtn" : "rebBtnFilled"}>{leftBtnText}</button>
          <button onClick={conFirmFunction} disabled={loader} className={status === "decline" ? "rebBtnFilled" : "greenOutlineBtn"}>{rightBtnText} {loader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}</button>
        </div>
      </div>
    </div>
  );
};

export default ApprovedModal;