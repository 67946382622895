// import React from "react";

// const InputField = React.forwardRef(
//   (
//     {
//       labelTop = "",
//       label = "",
//       error = "",
//       type = "",
//       helperText = "",
//       icon,
//       fullWidth = true,
//       value: propsValue,
//       onChange: propsOnChange,
//       size = "small",
//       labelImageSrc = "", // Add a prop for the image source
//       ...props
//     },
//     ref
//   ) => {
//     const [stateValue, setStateValue] = React.useState("");
//     const value = propsValue !== undefined ? propsValue : stateValue;
//     const _id = `myInput`;
//     const onChange = (event) => {
//       if (propsOnChange) {
//         propsOnChange(event);
//       } else {
//         setStateValue(event.target.value);
//       }
//     };
//     const printError = () => {
//       if (error !== "") {
//         return <span style={{ color: "red", fontSize: "12px" }}>{error}</span>;
//       }
//     };
//     const printHelperText = () => {
//       if (helperText !== "") {
//         return (
//           <span style={{ color: "#6C6A6A", fontWeight: 500, fontSize: "12px" }}>
//             {helperText}
//             {icon}
//           </span>
//         );
//       }
//     };
//     return (
//       <div className={`custom-input pb-2 w-full`}>
//         {labelTop && (
//           <label htmlFor={_id}
//           className="form-control font-size-15px  font-Poppins-Medium"
//           >
//             {labelTop}
//           </label>
//         )}
//         {label && (
//           <label htmlFor={_id} className="form-control font-size-15px font-Poppins-Medium flex gap-2  ">
//            {labelImageSrc && <img src={labelImageSrc} alt="Label Image" />}   {label}{/* Include image */}
//           </label>
//         )}
//         <input
//           ref={ref}
//           id={_id}
//           className="form-control w-full mt-1"
//           type={type}
//           autoComplete="off"
//           value={value}
//           onChange={onChange}
//           {...props}
//         />
//         {printHelperText()}
//         {printError()}
//       </div>
//     );
//   }
// );

// export default InputField;
import React, { ChangeEvent, forwardRef } from "react";

interface InputFieldProps {
  labelTop?: string;
  label?: string;
  error?: string;
  type?: string;
  helperText?: string;
  icon?: React.ReactNode;
  fullWidth?: boolean;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  size?: string;
  labelImageSrc?: string; // Add a prop for the image source
  [key: string]: any;
}

const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      labelTop = "",
      label = "",
      error = "",
      type = "",
      helperText = "",
      icon,
      fullWidth = true,
      value: propsValue,
      onChange: propsOnChange,
      size = "small",
      labelImageSrc = "", // Add a prop for the image source
      ...props
    },
    ref
  ) => {
    const [stateValue, setStateValue] = React.useState<string>("");
    const value = propsValue !== undefined ? propsValue : stateValue;
    const _id = `myInput`;
    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
      if (propsOnChange) {
        propsOnChange(event);
      } else {
        setStateValue(event.target.value);
      }
    };
    const printError = () => {
      if (error !== "") {
        return <span style={{ color: "red", fontSize: "12px" }}>{error}</span>;
      }
    };
    const printHelperText = () => {
      if (helperText !== "") {
        return (
          <span style={{ color: "#6C6A6A", fontWeight: 500, fontSize: "12px" }}>
            {helperText}
            {icon}
          </span>
        );
      }
    };
    return (
      <div className={`custom-input pb-2 w-full`}>
        {labelTop && (
          <label htmlFor={_id} className="form-control font-size-15px font-Poppins-Medium">
            {labelTop}
          </label>
        )}
        {label && (
          <label htmlFor={_id} className="form-control font-size-15px font-Poppins-Medium flex gap-2">
            {labelImageSrc && <img src={labelImageSrc} alt="Label Image" />} {label}{/* Include image */}
          </label>
        )}
        <input
          ref={ref}
          id={_id}
          className={`form-control w-full mt-1 ${labelTop === "First name" ? "capitalize" : ""}`}
          type={type}
          autoComplete="off"
          value={value}
          onChange={onChange}
          {...props}
        />
        {printHelperText()}
        {printError()}
      </div>
    );
  }
);

export default InputField;
