import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import httpRequest from '../../config/Helpers/httpRequest';
import { getCookie } from '../../config/Helpers/helpers';
import { useSelector } from 'react-redux';
import axios from 'axios';
import authSlice from '../../config/Store/Reducers/authSlice';
import { RootState } from '../../config/Store/store';
interface ApiResponse {
    message: string;
    res?: any;
    err?: any;
    jobId: string;
}
interface ShortListJobButtonProps {
    id: string;
    successFunction: (tab: string) => void;
    activeTab: string;
}

const ShortListJobButton: React.FC<ShortListJobButtonProps> = ({ id, successFunction, activeTab }) => {

    const [shortListLoader, setShortListLoader] = useState(false);
    const { token } = useSelector((store: RootState) => store.auth);
    // Functionality: API call for shortlisted job and delete from shortlisted
    const handleJobShortList = async () => {
        setShortListLoader(true);
        if (activeTab === "opened") {
            const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: `/api/job/shortlist/add/`, params: { jobId: id } });
            if (res) {
                toast(res?.message || "Success", { type: "success" });
                successFunction("shortlisted");
            } else {
                toast(err?.message, { type: "error" }); // api error
            }
        }
        else {
            const response = await axios.request<ApiResponse>({
                method: 'DELETE',
                url: `${process.env.REACT_APP_BASE_URL}/api/job/shortlist/remove`,
                data: { jobId: id } as any,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            try {
                if (response) {
                    toast(response?.data?.message || "Success", { type: "success" });
                    successFunction("opened");
                }
            }
            catch (error:any) {
                toast(error?.data?.message, { type: "error" }); // api error
            }
            // if (response) {
            //     debugger
            //     toast(res?.message || "Success", { type: "success" });
            //     // successFunction("opened");
            //     successFunction("opened");
            // } else {
            //     toast(err?.message, { type: "error" }); // api error
            // }



            // const { res, err } = await httpRequest({ method: "delete", path: `/api/job/shortlist/remove`, params: { jobId: id } });
            // if (res) {
            //     toast(res?.message || "Success", { type: "success" });
            //     successFunction("opened");
            // } else {
            //     toast(err?.message, { type: "error" }); // api error
            // }
        }
        setShortListLoader(false);
    };
    return (
        <button disabled={shortListLoader} className='font-size-15px font-Poppins-Regular jobs-apply-btn active mr-2 flex flex-row items-center gap-2 ' onClick={handleJobShortList}>{activeTab === "opened" ? "Shortlist" : "Remove From Shortlist"}{shortListLoader && <CircularProgress style={{ width: 16, height: 16, color: "#fff" }} />}</button>
    );
};

export default ShortListJobButton;