import React, { useState, useEffect, ChangeEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { MultiValue } from "react-select";
import {
  faFilter,
  faCalendar,
  faLocationDot,
  faCalendarDays,
  faCheck,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import {
  getTimeDifference,
  validateNumber,
  splitMessage,
} from "../../config/Helpers/helpers";
import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import { RootState } from "../../config/Store/store";
import FilterIcon from "../../assets/img/filter-icon.svg";
import CrossIcon from "../../assets/img/cross-icon.svg";

interface ServiceOption {
  value: string;
  label: string;
}

interface ApplicantDetails {
  firstName?: string;
  lastName?: string;
}
interface service {
  servicename: string;
  _id: string;
  [key: string]: any;
}
interface Job {
  applicantDetails?: ApplicantDetails;
  allServices: Array<{ servicename: string }>;
  createdAt?: string;
  repeatCycle?: number;
  city?: string;
  state?: string;
  postCode?: string;
  startDate?: string;
  endDate?: string;
  budget?: number;
  service: service;
  jobActivityLog?: {
    logCategory?: string;
    message?: string;
    createdAt?: string;
  };
  [key: string]: any;
}

interface MangeJobsLeftBarProps {
  loader: boolean;
  data: Job[];
  activeTab: string;
  currentPage: number;
  totalPages: number;
  pageChange: (page: number) => void;
  submit: (filters: { date: string; location: string; service: string[] }) => void;
  setSelectedJobs: (job: Job) => void;
  selectedJobs: any;
  clearFilter: () => void;
}
const MangeJobsLeftBar: React.FC<MangeJobsLeftBarProps> = ({
  loader,
  data,
  activeTab,
  currentPage,
  totalPages,
  pageChange,
  submit,
  setSelectedJobs,
  selectedJobs,
  clearFilter,
}) => {
  // 05-02-24
  const { ALLSERVICES } = useSelector((store: RootState) => store.allServices);

  const [tempDate, setTempDate] = useState("");
  const [serviceOptions, setServiceOptions] = useState<ServiceOption[]>([]);
  const [filterVisibilty, setFilterVisibiltiy] = useState(false);

  const [date, setDate] = useState("");
  const [location, setLocation] = useState("");
  const [service, setService] = useState<string[]>([]);

  // for date change
  const handleDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setTempDate(value);
    const inputDate = new Date(value);
    const year = inputDate.getFullYear();
    const day = inputDate.getDate();
    const month = inputDate.getMonth() + 1;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
    setDate(formattedDate);
  };

  // for service Change
  const handleServiceChange = (event: MultiValue<ServiceOption>) => {
    const tempArr = event?.map((v) => v?.value);
    setService(tempArr);
  };

  // for location change
  const handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    if (value && validateNumber(value)) {
      if (value.length < 5) {
        setLocation(value);
      }
    } else {
      setLocation("");
    }
  };

  // for filter close
  const handleFilterClose = () => {
    setFilterVisibiltiy(false);
    setDate("");
    setTempDate("");
    setService([]);
    setLocation("");
  };

  const filterClear = () => {
    handleFilterClose();
    clearFilter();
  };

  const filter_submit = () => {
    if (!date && (!location || location.length < 4) && service.length < 1) {
      toast("Please fill at least 1 field.", { type: "error" });
      return;
    }

    const obj = { date, location, service }; // service is correctly typed as string[]
    setFilterVisibiltiy(false);
    submit(obj); // submit expects { date: string; location: string; service: string[] }
  };

  useEffect(() => {
    handleFilterClose();
  }, [activeTab]);

  useEffect(() => {
    if (ALLSERVICES) {
      let tempArr: ServiceOption[] = ALLSERVICES.flatMap((item) =>
        item?.documents
          .filter((v) => v?.servicename) // Filter out items with undefined label
          .map((v) => ({
            value: v?._id,
            label: v?.servicename as string, // Cast to string since we filtered out undefined
          }))
      );
      setServiceOptions(tempArr);
    }
  }, [ALLSERVICES]);


  return (
    <div className="acitve-jobs-main">
      {filterVisibilty ? (
        <div className="manage-job-filter">
          <div>
            <div className="flex justify-center">
              <div className="flex flex-row justify-between items-center x-icon w-[90%] mb-2">
                <h2 className="font-size-20px font-Poppins-SemiBold  text-[#00443f] ">
                  Filter
                </h2>
                {/* <FontAwesomeIcon
                  onClick={() => setFilterVisibiltiy(false)}

                  icon={faX}
                /> */}

                <img
                  onClick={() => setFilterVisibiltiy(false)}
                  src={CrossIcon}
                />
              </div>
            </div>
            <div className="filter-line"></div>
            <div className="flex justify-center flex-col filter-entry items-center">
              <div className="flex flex-col x-icon w-[90%] mt-2">
                <h2 className="font-size-20px font-Poppins-SemiBold  text-[#646464] ">
                  Date
                </h2>
                <input
                  className=" mb-3 font-size-15px font-Poppins-Light"
                  type="date"
                  name="date"
                  placeholder="Filter by Date"
                  value={tempDate}
                  onChange={handleDateChange}
                />
              </div>
              <div className="flex flex-col x-icon w-[90%] mb-2">
                <h2 className="font-size-20px font-Poppins-SemiBold  text-[#646464] ">
                  Service
                </h2>
                <Select
                  isMulti
                  value={serviceOptions.filter((option) =>
                    service.includes(option.value)
                  )}
                  onChange={handleServiceChange}
                  options={serviceOptions}
                  styles={{
                    singleValue: (provided) => ({
                      ...provided,
                      fontWeight: "300",
                      fontSize: "15px",
                    }),

                    placeholder: (provided) => ({
                      ...provided,
                      fontWeight: "300",
                      fontSize: "15px",
                    }),
                    control: (provided, state) => ({
                      ...provided,
                      border: `1px solid #00A297`,
                      borderRadius: `8px`,
                      boxShadow: state.isFocused ? `0 0 0 2px #00A297` : `none`,
                      "&:hover": {
                        border: `1px solid #00A297`,
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      border: `1px solid #00A297`,
                      borderRadius: `8px`,
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected ? `#00A297` : `white`,
                      color: state.isSelected ? `white` : `#000`,
                      "&:hover": {
                        backgroundColor: state.isSelected
                          ? `#00A297`
                          : `#e3f6f5`,
                      },
                    }),
                  }}
                />
              </div>
              <div className="flex flex-col x-icon w-[90%]">
                <h2 className="font-size-20px font-Poppins-SemiBold  text-[#646464] ">
                  Location
                </h2>
                <input
                  className=" mb-3 font-size-15px font-Poppins-Light"
                  name="location"
                  placeholder="Filter By location"
                  value={location}
                  onChange={handleLocationChange}
                />
              </div>
            </div>
          </div>
          <div>
            <div>
              <div className="filter-line"></div>
              <div className="flex justify-center flex-col filter-entry items-center">
                <div className="flex flex-row justify-between x-icon w-[90%] mb-3">
                  <button
                    onClick={filterClear}
                    className=" text-[#00443f] font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                  >
                    Clear
                  </button>

                  <button
                    disabled={loader}
                    onClick={filter_submit}
                    className="text-white theme-bg-green font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                  >
                    Apply{" "}
                    {loader && (
                      <CircularProgress
                        style={{ color: "#fff", width: 16, height: 16 }}
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="filter-results">
            <div className="flex align-center flex-row  justify-between job-filter-stat">
              <span className=" font-size-12px font-Poppins-Regular text-[#646464]">
                Showing all {data?.length || 0} jobs
              </span>
              <div className="flex flex-row gap-2 items-center">
                <span className=" font-size-14px font-Poppins-Medium  text-[#00443f]">
                  |
                </span>
                <button
                  onClick={() => setFilterVisibiltiy(true)}
                  className="flex flex-row gap-2 items-center"
                >
                  <span className=" text-[#00443f]">
                    {/* <FontAwesomeIcon icon={faFilter} /> */}
                    <img src={FilterIcon} alt="" />

                  </span>
                  <span className=" font-size-14px font-Poppins-Medium  text-[#00443f]">
                    Filter
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className="tab-upt">
            {data && data.length > 0 ? (
              data.map((item, index) => (
                <div key={index}>
                  <div className={`left-card-main ${selectedJobs === item ? 'left-card-main-border' : ''}`} >
                    <div role="button" onClick={() => setSelectedJobs(item)}>
                      <div className="flex flex-row gap-2">
                        <span className=" font-size-14px font-Poppins-Medium  text-[#00000]">
                          Assigned to{" "}
                        </span>
                        <span className="font-size-14px font-Poppins-SemiBold  text-[#00443f] capitalize">{`${item?.applicantDetails?.firstName || ""
                          } ${item?.applicantDetails?.lastName || ""}`}</span>
                      </div>
                      <div>
                        <h2 className="font-Poppins-Medium mt-2 font-size-20px">
                          {item?.service?.servicename}
                          {/* {item?.allServices[0]?.servicename || ""}
                          {item?.allServices?.length > 1 ? ", " : ""}
                          {item?.allServices[1]?.servicename || ""} */}
                          {/* <span style={{ fontSize: 10, fontWeight: "normal" }}>
                            {item?.allServices?.length > 2 ? " & others " : ""}
                          </span> */}
                        </h2>
                        <p className="theme-color-para leading-3 font-size-12px font-Poppins-Regular">
                          Posted {getTimeDifference(item?.createdAt || "")}
                        </p>
                        <div className="card-box-inner-main">
                          <div className="admin-box-weekly-cal">
                            <FontAwesomeIcon icon={faCalendar} />
                            <p className="theme-color-para font-size-14px font-Poppins-Regular">
                              {item?.repeatCycle == 1
                                ? "One-off"
                                : item?.repeatCycle == 2
                                  ? "Daily"
                                  : item?.repeatCycle == 3
                                    ? "Weekly"
                                    : item?.repeatCycle == 4
                                      ? "Monthly"
                                      : "Custom"}
                            </p>
                          </div>
                          <div className="admin-box-weekly-cal">
                            <FontAwesomeIcon icon={faLocationDot} />
                            <p className="theme-color-para font-size-14px font-Poppins-Regular">
                              {`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""
                                }`}
                            </p>
                          </div>
                          <div className="flex calen-job-req gap-8">
                            <div className="admin-box-weekly-cal admin-box-weekly-date">
                              <FontAwesomeIcon icon={faCalendarDays} />
                              <p className="theme-color-para font-size-11px font-Poppins-Regular">
                                Start date: {moment(item?.startDate).format("DD-MM-YYYY") || "-"}
                              </p>
                            </div>
                            <div className="admin-box-weekly-cal admin-box-weekly-date">
                              <FontAwesomeIcon icon={faCalendarDays} />
                              <p className="theme-color-para font-size-11px font-Poppins-Regular">
                                End date: {moment(item?.endDate).format("DD-MM-YYYY") || "-"}
                              </p>
                            </div>
                            {/* <div className="admin-box-weekly-cal admin-box-weekly-date">
                              <FontAwesomeIcon icon={faCalendar} />
                              <p>Start time: {(item?.startTime || "")}</p>
                            </div>
                            <div className="admin-box-weekly-cal admin-box-weekly-date">
                              <FontAwesomeIcon icon={faCalendar} />
                              <p>End time: {(item?.endTime || "")}</p>
                            </div> */}
                          </div>
                        </div>
                        <div className="job-req-min-height">
                          <p className="theme-color-para font-size-16px font-Poppins-Regular">
                            Rate :{" "}
                            <span className="font-Poppins-SemiBold theme-color-green">
                              ${item?.budget || ""}
                            </span>
                            <span className="font-size-10px"></span>
                          </p>
                        </div>
                      </div>
                    </div>
                    {item?.jobActivityLog && (
                      <div className="time-btn-card flex flex-col gap-2 justify-between theme-bg-green ">
                        <div className="flex flex-row gap-2 items-center">
                          <span className=" text-[#ffffff]  font-Poppins-Regular font-size-14px">
                            <FontAwesomeIcon icon={faCheck} />
                          </span>
                          <span className="font-Poppins-Regular font-size-14px text-[#ffffff]">
                            {item?.jobActivityLog?.logCategory === "dispute"
                              ? (item?.jobActivityLog?.message || "").replace(/:$/, "")
                              : item?.jobActivityLog?.logCategory === "Rating"
                                ? item?.jobActivityLog?.message?.replace(/:$/, "") ||
                                ""
                                : item?.jobActivityLog?.message?.replace(/:$/, "")}
                          </span>
                        </div>
                        <span
                          className=" text-[#ffffff] font-Poppins-Regular font-size-12px"
                          style={{ marginLeft: "auto" }}
                        >
                          {" "}
                          {/* {getTimeDifference(item?.jobActivityLog?.createdAt) || */}

                        </span>
                      </div>
                    )}
                  </div>
                  <div className="w-[85%]  st-line"></div>
                </div>
              ))
            ) : (
              <p className="font-Poppins-SemiBold font-size-14px w-[90%] mx-auto flex flex-row items-center mt-2  justify-center ">
                No{" "}
                {activeTab === "onboard"
                  ? "active"
                  : activeTab === "completed"
                    ? "completed"
                    : "cancelled"}{" "}
                jobs
              </p>
            )}
            {loader && (
              <div className="text-center">
                <CircularProgress style={{ color: "#004540" }} />
              </div>
            )}
            {!loader && totalPages > currentPage && (
              <div className="text-center mb-5">
                <button
                  onClick={() => pageChange(currentPage + 1)}
                  className="font-size-15px font-Poppins-Regular jobs-apply-btn active"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default MangeJobsLeftBar;