import React, { useState, useEffect } from "react";
import edit from "../../../assets/img/awesome-edit.svg";
import MALE from "../../../assets/img/male-gender.png";
import FEMALE from "../../../assets/img/female.png";
import SMOKER from "../../../assets/img/smoking-area.png";
import NONSMOKER from "../../../assets/img/no-smoking.png";
import PET from "../../../assets/img/pawprint.png";
import NOPET from "../../../assets/img/no-animals.png";
import COOKING from "../../../assets/img/hot-pot.png";
import LGBT from "../../../assets/img/rainbow.png";
import NOLGBT from "../../../assets/img/rainbow1.png";
import TENNIS from "../../../assets/img/tennis.png";
import MUSIC from "../../../assets/img/musical-note.png";
import { Link, useLocation } from "react-router-dom";
import { getRole } from "../../../config/Helpers/helpers";

import { useSelector } from "react-redux";
import { RootState } from "../../../config/Store/store";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
export interface IPreferences {
  male?: boolean;
  female?: boolean;
  personalitySmoker?: string | boolean | null;
  personalityPetFriendly?: boolean;
  cooking?: boolean;
  lgbtq?: boolean | null | undefined;
  sports?: boolean;
  music?: boolean;
  [key: string]: any;
}

const PreferencesInterest: React.FC = () => {
  const location = useLocation()
  // const { userId = "" } = getRole();
  const decoded = getRole();
  const userId = decoded?.userId
  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);
  const PersonalProfilePath = location.pathname.startsWith("/publicprofile")

  // useEffect(() => {
  //   if (personalData) {
  // }, [])

  const [preferences, setPreferences] = useState<IPreferences>({});

  // useEffect(() => {
  //   if (personalData) {
  //     // setPreferences(personalData?.preferences || {});

  //     // setPreferences(personalData);
  //   }
  // }, [personalData]);
  useEffect(() => {
    if (ShowData?.preferences) {
      setPreferences({
        lgbtq: ShowData?.preferences.preferencelgbtq,
        male: ShowData?.preferences.preferencemale,
        female: ShowData?.preferences.preferencefemale,
        nopets: ShowData?.preferences.preferencenopet,
        nonsmoker: ShowData?.preferences.preferencenonsmoker,
        noPreferences: ShowData?.preferences.noPreferences,
        personalityPetFriendly: ShowData?.preferences.personalityPetFriendly,
        personalitySmoker: ShowData?.preferences.personalitySmoker,
        cooking: ShowData?.preferences.hobbyCooking,
        music: ShowData?.preferences.hobbyMusic,
        sports: ShowData?.preferences.hobbySports,
      });
    }
  }, [ShowData?.preferences]);

  

  return (
    <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6  ">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
          My preferences & interests
        </h4>

        {userId === ShowData?._id && (
          <Link to="/preferences-edit" className="mt-2 edit-pro-icon ">
            <img src={edit} alt="" />
          </Link>
        )
        }
      </div>
      <div className="preferences-interest flex gap-3 flex-wrap mt-3">
        {preferences?.male && (
          <div className="preferences-box flex items-center justify-center">
            <img src={MALE} alt="" />
          </div>
        )}{" "}
        {/* male */}
        {preferences?.female && (
          <div className="preferences-box flex items-center justify-center">
            <img src={FEMALE} alt="" />
          </div>
        )}{" "}
        {/* female */}
        {preferences?.personalitySmoker === true ? (
          <div className="preferences-box flex items-center justify-center">
            <img src={SMOKER} alt="" />
          </div>
        ) : preferences?.personalitySmoker === false ? (
          <div className="preferences-box flex items-center justify-center">
            <img src={NONSMOKER} alt="" />
          </div>
        ) : null}{" "}
        {/* smoker friendly */}
        {preferences?.personalityPetFriendly === true ? (
          <div className="preferences-box flex items-center justify-center">
            <img src={PET} alt="" />
          </div>
        ) : preferences?.personalityPetFriendly === false ? (
          <div className="preferences-box flex items-center justify-center">
            <img src={NOPET} alt="" />
          </div>
        ) : null}{" "}
        {/* pet friendly */}
        {preferences?.cooking && (
          <div className="preferences-box flex items-center justify-center">
            <img src={COOKING} alt="" />
          </div>
        )}{" "}
        {/* Cooking */}
        {preferences?.lgbtq === true ? (
          <div className="preferences-box flex items-center justify-center">
            <img src={LGBT} alt="" />
          </div>
        ) : preferences?.lgbtq === false ? (
          <div className="preferences-box flex items-center justify-center">
            <img src={NOLGBT} alt="" />
          </div>
        ) : null}{" "}
        {/* Can be null, true, or false. If true, show "LGBT"; if false, show "NOLGBT"; if null, show nothing. */}
        {preferences?.sports && (
          <div className="preferences-box flex items-center justify-center">
            <img src={TENNIS} alt="" />
          </div>
        )}{" "}
        {/* sports */}
        {preferences?.music && (
          <div className="preferences-box flex items-center justify-center">
            <img src={MUSIC} alt="" />
          </div>
        )}{" "}
        {/* music */}
      </div>
    </div>
  );
}

export default PreferencesInterest;
