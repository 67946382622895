import React, { useState, useEffect } from "react";
import edit from "../../../assets/img/awesome-edit.svg";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useSelector } from "react-redux";
import { getRole } from "../../../config/Helpers/helpers";
import { convertTo12HourFormat } from "../../../config/Helpers/helpers";
import { Link } from "react-router-dom";
import { RootState } from "../../../config/Store/store";
import { useLocation } from "react-router-dom";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
interface IAvailability {
  [key: string]: [string, string][];
}
interface IUserProfileState {
  userProfile: {
    publicData: {
      _id: string;
      userData?: {
        availability?: IAvailability;
      };
    };
  };
}
const Availability: React.FC = () => {
  const location = useLocation();
  const decoded = getRole();
  const userId = decoded?.userId || "";
  // const { userId = "" } = getRole();

  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);

  const [availabilities, setAvailabilities] = useState<IAvailability>({});

  useEffect(() => {
    if (ShowData && ShowData) {
      const availability = ShowData?.availability || false;

      setAvailabilities(availability);
    }
  }, [ShowData]);

  const weekdayKeys = Object.keys(availabilities).filter(
    (key) =>
      key === "monday" ||
      key === "tuesday" ||
      key === "wednesday" ||
      key === "thursday" ||
      key === "friday" ||
      key === "saturday" ||
      key === "sunday"
  );


  return (
    <div className="shadow-lg bg-white  rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
          Availability
        </h4>
        {userId === ShowData?._id && (
          <Link to="/availability-edit" className="mt-5 edit-pro-icon ">
            <img src={edit} alt="" />
          </Link>
        )}
      </div>
      <div>
        <ul>
          {weekdayKeys.map((key) => {
            return (
              <>
                <li key={key} className="flex gap-3 pb-5">
                  {availabilities[key].length < 1 ? (
                    <span>
                      <FontAwesomeIcon icon={faXmark} color="#B10000" />
                    </span>
                  ) : (
                    <span>
                      <FontAwesomeIcon icon={faCheck} color="#004540" />
                    </span>
                  )}
                  <div>
                    <h4 className="font-size-20px font-Poppins-Medium theme-color-green capitalize">
                      {key}
                    </h4>
                    {availabilities[key].length > 0 && (
                      <p className="font-size-18px font-Poppins-Medium theme-grey-type">
                        {availabilities[key].map((v, i) => (
                          <React.Fragment key={i}>
                            <span>{convertTo12HourFormat(v[0])}</span>
                            <span> to </span>
                            <span>{convertTo12HourFormat(v[1])}</span>
                            {i !== availabilities[key].length - 1 && <>, </>}
                          </React.Fragment>
                        ))}
                      </p>
                    )}
                  </div>
                </li>
                {/* <hr /> */}
              </>
            );
          })}
          {/* {Object.keys(availabilities)?.map((key) => {
            return (
              <>
                <li key={key} className="flex gap-3  pb-5">
                  {availabilities[key].length < 1 ? (
                    <span>
                      <FontAwesomeIcon icon={faXmark} color="#B10000" />
                    </span>
                  ) : (
                    <span>
                      <FontAwesomeIcon icon={faCheck} color="#004540" />
                    </span>
                  )}
                  <div>
                    <h4 className="font-size-20px font-Poppins-Medium theme-color-green capitalize">
                      {key}
                    </h4>
                    {availabilities[key].length > 0 && (
                      <p className="font-size-18px font-Poppins-Medium theme-grey-type">
                        {availabilities[key].map((v, i) => (
                          <React.Fragment key={i}>
                            <span>{convertTo12HourFormat(v[0])}</span>
                            <span> to </span>
                            <span>{convertTo12HourFormat(v[1])}</span>
                            {i !== availabilities[key].length - 1 && <>, </>}
                          </React.Fragment>
                        ))}
                      </p>
                    )}
                  </div>
                </li>
                <hr />
              </>
            );
          })} */}
        </ul>
      </div>
    </div>
  );
}

export default Availability;
