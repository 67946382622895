/**
 * ViewPostModal Component
 *
 * This component fetches and displays the details of a specific post in the news feed.
 * It includes a header, a post detail view, and sidebars for additional content and post creation.
 *
 * File Name: ViewPostModal.jsx
 * Author: Developer
 * Date: 2024-06-22
 */

import React, { useState, useEffect } from "react";
import PostCreatorSideBar from "../../../../components/NewsFeeds/PostCreatorSideBar";
import PostDetailView from "../../../../components/NewsFeeds/PostDetailView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../../../config/Helpers/httpRequest";

/**
 * ViewPostModal Component
 *
 * Displays the details of a specific post, including related content and post creation options.
 */


interface Document {
    profileImageUrl?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    connections?: number;
    createdAt?: string;
    caption?: string;
    attachments?: { url: string }[];
    _id?: string;
    // Include any additional properties your API returns
    [key: string]: any;
}

interface ApiResponse {
    document: Document;
}
interface ViewPostModalProps {
    isOpen: boolean;
    postId: string;
    onClose: () => void;
}

const ViewPostModal: React.FC<ViewPostModalProps> = ({ isOpen, postId, onClose }) => {

    const [isLoader, setIsLoader] = useState<boolean>(false); // State for managing the loading indicator
    const [postDetail, setPostDetail] = useState<Document | undefined>(); // State for storing post details
    // Functionality: Fetches the details of the post from the API using the post ID.
    const fetchPostDetail = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({
            path: `/api/posts/details/${postId}`,
        });
        if (res) {
            // let _data = res?.documents;
            setPostDetail(res?.document);

        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };

    // Fetches post details when the component mounts or the post ID changes
    useEffect(() => {
        if (postId) {
            fetchPostDetail();
        }
    }, [postId]);

    return (
        <>

            <div className="modal" style={{ display: isOpen ? "block" : "none" }}>
                <div className="view_Post_Modal relative">
                    <button
                        onClick={onClose}
                        className="absolute job-pre-edit">
                        <FontAwesomeIcon className="x-icon-admin-opr" icon={faX} style={{ color: "#ffffff" }} />
                    </button>


                    {isLoader && (
                        <div className="full_page_loader">
                            <CircularProgress style={{ color: "#fff" }} />
                        </div>
                    )}
                    <div className="container-1480 flex justify-center text-left">
                        <div className="news-feed-post">
                            <PostDetailView data={postDetail || {}} setDate={setPostDetail} />
                        </div>
                    </div>

                </div>
            </div>
        </>
    );
}

export default ViewPostModal;
