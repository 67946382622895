import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from '@mui/material';
import Yellowbriefcase from '../../assets/img/yellowbriefcase.svg'
function AnySpecificReason({ text, onClose, onDelete, state, setState, loader, leftBtnText, rightBtnText }) {
    return (
        <div className="modal">
            <div className="Cancellation-modal-content relative">
                <button
                    disabled={loader}
                    onClick={onClose}
                    className="absolute job-pre-edit"
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className="cancellation-envolpe-main">
                    <div className="cancellation-envolpe">
                        {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                        <img src={Yellowbriefcase} alt="" />
                    </div>
                </div>
                <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31] mb-2">{text}</h2>
                <textarea className="resize-none canellation-textarea font-size-15px theme-color-para font-Poppins-Regular" value={state} onChange={(e) => setState(e.target.value)}></textarea>
                <div className="flex cancellation-mdl-btn justify-center gap-2 mt-2">
                    <button disabled={loader} onClick={onClose}
                        className="font-size-17px font-Poppins-Medium text-[#00443F]">{leftBtnText}</button>
                    <button disabled={loader} className="font-size-17px font-Poppins-Medium" onClick={onDelete}
                    >{rightBtnText} {loader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}</button>
                </div>
            </div>
        </div>
    )
}

export default AnySpecificReason;