// import React, { useState, useEffect } from 'react'
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faArrowUp,
//   faArrowDown

// } from "@fortawesome/free-solid-svg-icons";
// import FreeUSer from "../../assets/img/FreeUser.svg"
// import PreimiumUser from "../../assets/img/PremiumUser.svg"
// import jobIcon from "../../assets/img/jobicon.svg"
// import httpRequest from '../../config/Helpers/httpRequest';
// import { toast } from 'react-toastify';

// interface DashboardData {
//   currentUsers: {
//     current: number;
//     last: number;
//   };
//   paidMonthly: {
//     current: number;
//     last: number;
//   };
//   paidAnnually: {
//     current: number;
//     last: number;
//   };
//   upgradedUser: {
//     current: number;
//     last: number;
//   };
//   totalJobs: {
//     current: number;
//     last: number;
//   };
//   activeJobs: {
//     current: number;
//     last: number;
//   };
//   completedJobs: {
//     current: number;
//     last: number;
//   };
// }
// interface ApiResponse {
//   success: boolean;
//   message: string;
//   documents: DashboardData;
// }


// const UserAnalysis: React.FC = () => {
//   const [dashboardData, setDashboardData] = useState<DashboardData | null>(null); // Initialize with null
//   const [isPositive, setIsPositive] = useState<boolean>(false);


//   const calculatePercentage = (currentUsers: number, lastUsers: number) => {
//     if (!lastUsers || lastUsers === 0) {
//       // If lastUsers is undefined or 0, return 0 or a default value
//       return 0;
//     }

//     const percentageChange = ((currentUsers - lastUsers) / lastUsers) * 100;
//     console.log(percentageChange, "percentageChange");

//     return percentageChange;
//   };


//   useEffect(() => {
//     const fetchDashboardData = async () => {
//       const { res, err } = await httpRequest<ApiResponse>({
//         method: 'get',
//         path: `/api/admin/user/dashboard-data`,
//       });

//       if (res) {
//         console.log(res, "response")
//         setDashboardData(res?.documents || {});
//       } else {
//         toast(err?.message || "An error occurred", { type: "error" });
//       }
//     };

//     fetchDashboardData();
//   }, []);

//   return (
//     <>
//       <div className='mt-4 grid justify-between' style={{ gridTemplateColumns: "24% 24% 24% 24%" }}>

//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Free users</h3>
//             <div className="" >
//               {/* <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.totalUsers}</p> */}
//             </div>

//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData?.currentUsers?.current, dashboardData?.lastUsers) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>

//                 {Math.abs(calculatePercentage(dashboardData?.currentUsers, dashboardData?.lastUsers)).toFixed(1)} % {" "}

//                 {calculatePercentage(dashboardData?.currentUsers, dashboardData?.lastUsers) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>

//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={FreeUSer} alt='free-user' />
//         </div>
//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Monthly premium users</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.premiumUsersData?.[0]?.monthlyPremiumUsers}</p>
//             </div>
//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData?.premiumUsersData?.[0]?.current?.[1]?.value, dashboardData?.premiumUsersData?.[0]?.last?.[0]?.value) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>

//                 {calculatePercentage(dashboardData?.premiumUsersData?.[0]?.current?.[1]?.value, dashboardData?.premiumUsersData?.[0]?.last?.[0]?.value)} %  {" "}

//                 {calculatePercentage(dashboardData?.premiumUsersData?.[0]?.current?.[1]?.value, dashboardData?.premiumUsersData?.[0]?.last?.[0]?.value) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>

//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={PreimiumUser} alt='free-user' />
//         </div>
//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Annually premium users</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold ">{dashboardData?.premiumUsersData?.[0]?.annualPremiumUsers}</p>
//             </div>
//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold  ${calculatePercentage(dashboardData?.premiumUsersData?.[0]?.current?.[0]?.value, 0) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`} >

//                 {calculatePercentage(dashboardData?.premiumUsersData?.[0]?.current?.[0]?.value, 0).toFixed(1)} %  {" "}

//                 {calculatePercentage(dashboardData?.premiumUsersData?.[0]?.current?.[0]?.value, 0) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}





//               </p>
//               <span className='font-size-12px font-Poppins-Regular'> than last year</span>
//             </div>
//           </div>
//           <img src={PreimiumUser} alt='free-user' />
//         </div>
//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Upgraded users</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.totalUpgradedUsers}</p>
//             </div>
//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold  ${calculatePercentage(dashboardData?.currentUpgradedUsers, dashboardData?.lastUpgradedUsers) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>

//                 {/* {calculatePercentage(dashboardData?.currentUpgradedUsers, dashboardData?.lastUpgradedUsers).toFixed(1)} % */}
//                 {Math.abs(calculatePercentage(dashboardData?.currentUpgradedUsers, dashboardData?.lastUpgradedUsers)).toFixed(1)} %  {" "}
//                 {calculatePercentage(dashboardData?.currentUpgradedUsers, dashboardData?.lastUpgradedUsers) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>
//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={PreimiumUser} alt='free-user' />
//         </div>
//       </div>
//       <div className='mt-4 grid justify-between' style={{ gridTemplateColumns: "32.5% 32.5% 32.5%" }}>
//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Total jobs</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.jobsData?.[0]?.totalJobs}</p>
//             </div>

//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData?.jobsData?.[0].currentMonthJobs, dashboardData?.jobsData?.[0].lastMonthJobs) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>

//                 {Math.abs(calculatePercentage(dashboardData?.jobsData?.[0].currentMonthJobs, dashboardData?.jobsData?.[0].lastMonthJobs)).toFixed(1)} % {" "}

//                 {calculatePercentage(dashboardData?.jobsData?.[0].currentMonthJobs, dashboardData?.jobsData?.[0].lastMonthJobs) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>
//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={jobIcon} alt='free-user' />
//         </div>

//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Active jobs</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.jobsData?.[0].activeJobs}</p>
//             </div>

//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData?.jobsData?.[0]?.currentMonthActiveJobs, dashboardData?.jobsData?.[0]?.lastMonthActiveJobs) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>

//                 {Math.abs(calculatePercentage(dashboardData?.jobsData?.[0]?.currentMonthActiveJobs, dashboardData?.jobsData?.[0]?.lastMonthActiveJobs)).toFixed(1)} % {" "}

//                 {calculatePercentage(dashboardData?.jobsData?.[0]?.currentMonthActiveJobs, dashboardData?.jobsData?.[0]?.lastMonthActiveJobs) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>

//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={jobIcon} alt='free-user' />
//         </div>

//         <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Completed jobs</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.jobsData?.[0].completedJobs}</p>
//             </div>

//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData?.jobsData?.[0]?.currentMonthCompletedJobs, dashboardData?.jobsData?.[0]?.lastMonthCompletedJobs) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>

//                 {Math.abs(calculatePercentage(dashboardData?.jobsData?.[0]?.currentMonthCompletedJobs, dashboardData?.jobsData?.[0]?.lastMonthCompletedJobs)).toFixed(1)} % {" "}

//                 {calculatePercentage(dashboardData?.jobsData?.[0]?.currentMonthCompletedJobs, dashboardData?.jobsData?.[0]?.lastMonthCompletedJobs) > 0 ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>

//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={jobIcon} alt='free-user' />
//         </div>


//         {/* <div className="dashboard-data p-4" >
//           <div>
//             <h3 className="font-Poppins-Medium font-size-18px">Completed active jobs</h3>
//             <div className="" >
//               <p className="font-size-28px font-Poppins-SemiBold">{dashboardData?.totalUsers}</p>
//             </div>

//             <div className="flex flex-row items-center gap-2" >
//               <p className={`font-size-16px font-Poppins-SemiBold ${isPositive === true ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>{Math.abs(calculatePercentage(dashboardData?.currentUsers, dashboardData?.lastUsers)).toFixed(1)} % {" "}

//                 {isPositive === true ?

//                   (
//                     <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} />
//                   )
//                   :
//                   (
//                     <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />

//                   )}
//               </p>

//               <span className='font-size-12px font-Poppins-Regular'> than last month</span>
//             </div>
//           </div>
//           <img src={jobIcon} alt='free-user' />
//         </div> */}
//       </div>
//     </>
//   )
// }

// export default UserAnalysis


import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUp, faArrowDown } from "@fortawesome/free-solid-svg-icons";
import FreeUser from "../../assets/img/FreeUser.svg";
import PremiumUser from "../../assets/img/PremiumUser.svg";
import jobIcon from "../../assets/img/jobicon.svg";
import httpRequest from '../../config/Helpers/httpRequest';
import { toast } from 'react-toastify';

interface DashboardData {
  currentUsers: {
    current: number;
    last: number;
  };
  paidMonthly: {
    current: number;
    last: number;
  };
  paidAnnually: {
    current: number;
    last: number;
  };
  upgradedUser: {
    current: number;
    last: number;
  };
  totalJobs: {
    current: number;
    last: number;
  };
  activeJobs: {
    current: number;
    last: number;
  };
  completedJobs: {
    current: number;
    last: number;
  };
}

interface ApiResponse {
  success: boolean;
  message: string;
  documents: DashboardData;
}

const UserAnalysis: React.FC = () => {
  const [dashboardData, setDashboardData] = useState<DashboardData | null>(null);

  const calculatePercentage = (current: number, last: number) => {
    if (!last || last === 0) {
      return 0; // Avoid division by zero
    }
    return ((current - last) / last) * 100;
  };

  useEffect(() => {
    const fetchDashboardData = async () => {
      const { res, err } = await httpRequest<ApiResponse>({
        method: 'get',
        path: '/api/admin/user/dashboard-data',
      });

      if (res) {
        setDashboardData(res?.documents || null);
      } else {
        toast(err?.message || "An error occurred", { type: "error" });
      }
    };

    fetchDashboardData();
  }, []);

  return (
    <div>
      {dashboardData && (
        <>
          <div className='mt-4 grid justify-between' style={{ gridTemplateColumns: "24% 24% 24% 24%" }}>
            
            {/* Free Users */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Free users</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.currentUsers.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.currentUsers.current, dashboardData.currentUsers.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.currentUsers.current, dashboardData.currentUsers.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.currentUsers.current, dashboardData.currentUsers.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={FreeUser} alt='free-user' />
            </div>

            {/* Monthly Premium Users */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Monthly Premium Users</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.paidMonthly.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.paidMonthly.current, dashboardData.paidMonthly.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.paidMonthly.current, dashboardData.paidMonthly.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.paidMonthly.current, dashboardData.paidMonthly.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={PremiumUser} alt='premium-user' />
            </div>

            {/* Annually Premium Users */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Annually Premium Users</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.paidAnnually.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.paidAnnually.current, dashboardData.paidAnnually.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.paidAnnually.current, dashboardData.paidAnnually.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.paidAnnually.current, dashboardData.paidAnnually.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={PremiumUser} alt='premium-user' />
            </div>

            {/* Upgraded Users */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Upgraded Users</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.upgradedUser.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.upgradedUser.current, dashboardData.upgradedUser.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.upgradedUser.current, dashboardData.upgradedUser.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.upgradedUser.current, dashboardData.upgradedUser.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={PremiumUser} alt='upgraded-user' />
            </div>
          </div>

          {/* Jobs Section */}
          <div className='mt-4 grid justify-between' style={{ gridTemplateColumns: "32.5% 32.5% 32.5%" }}>

            {/* Total Jobs */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Total Jobs</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.totalJobs.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.totalJobs.current, dashboardData.totalJobs.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.totalJobs.current, dashboardData.totalJobs.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.totalJobs.current, dashboardData.totalJobs.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={jobIcon} alt='total-jobs' />
            </div>

            {/* Active Jobs */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Active Jobs</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.activeJobs.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.activeJobs.current, dashboardData.activeJobs.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.activeJobs.current, dashboardData.activeJobs.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.activeJobs.current, dashboardData.activeJobs.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={jobIcon} alt='active-jobs' />
            </div>

            {/* Completed Jobs */}
            <div className="dashboard-data p-4">
              <div>
                <h3 className="font-Poppins-Medium font-size-18px">Completed Jobs</h3>
                <p className="font-size-28px font-Poppins-SemiBold">{dashboardData.completedJobs.current}</p>
                <div className="flex flex-row items-center gap-2">
                  <p className={`font-size-16px font-Poppins-SemiBold ${calculatePercentage(dashboardData.completedJobs.current, dashboardData.completedJobs.last) > 0 ? "text-[#3DD598]" : "text-[#D53D3D]"}`}>
                    {Math.abs(calculatePercentage(dashboardData.completedJobs.current, dashboardData.completedJobs.last)).toFixed(1)}% 
                    {calculatePercentage(dashboardData.completedJobs.current, dashboardData.completedJobs.last) > 0 ? 
                      <FontAwesomeIcon icon={faArrowUp} style={{ color: '#3DD598' }} /> : 
                      <FontAwesomeIcon icon={faArrowDown} style={{ color: '#D53D3D' }} />}
                  </p>
                  <span className="font-size-12px font-Poppins-Regular"> than last month</span>
                </div>
              </div>
              <img src={jobIcon} alt='completed-jobs' />
            </div>

          </div>
        </>
      )}
    </div>
  );
};

export default UserAnalysis;
