/**
 * AdminActiveJob Component
 * Component to manage and display active jobs for the admin panel.
 * Provides an overview of active jobs and integrates various sub-components for job management.
 * File: AdminActiveJob.js
 * Author: Developer
 * Date: 25-04-24
 */

import React from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import ActiveJobTabs from "../../components/AdminComponents/ActiveJobsTabs";


const AdminActiveJob: React.FC = () => {
  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          <AdminHeader />
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <h2 className="font-size-36px font-Poppins-Medium">
                Active jobs
              </h2>
              <div className="flex justify-between"></div>
              <div className="mt-8">
                <div className="  ">
                  <div className="  admin-active-job-handler">
                    <ActiveJobTabs />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminActiveJob;
