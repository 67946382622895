/**
 * Setting Component
 * Renders the settings page with header, sidebar, profile preview, and setting tabs.
 * File: Setting.js
 * Author: Developer
 * Date: 28-03-24
 */

import React from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import SettingTab from "../../../components/EditProfileComponents/Tabs/SettingTab";

function Setting() {
  return (
    <div>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3 ">
        <SidebarGobal /> 
        <div className="w-full">
          <div className="remove-btn" >
            <PreviewProfile title="Settings" />
          </div>
          {/* Search Worker Input */}
          <div className="">
            <SettingTab /> {/* Settings Tabs */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Setting;