// import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faLocationDot, faSearch } from "@fortawesome/free-solid-svg-icons";
// import { validateNumber } from '../../config/Helpers/helpers';
// import Select, { MultiValue, SingleValue, ActionMeta, InputActionMeta } from "react-select";
// import { toast } from 'react-toastify';
// import httpRequest from '../../config/Helpers/httpRequest';
// interface ServiceOption {
//     value: string;
//     label: string;
// }

// interface ServicesSearchProps {
//     loader?: boolean;
//     onChange?: (selected: { serviceIds: string[], postCode: string }) => void;
// }
// interface ApiResponse {
//     services: [];
//     documents: [];

// }
// const ServicesSearch: React.FC<ServicesSearchProps> = ({ loader = false, onChange = () => { } }) => {
//     const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

//     const [serviceLoader, setServiceLoader] = useState(false);
//     const [postCode, setPostCode] = useState<string>("");
//     console.log(postCode, "postCodepostCode")
//     const [isDropDown, setIsDropDown] = useState(false);
//     const [selectedServices, setSelectedServices] = useState<MultiValue<ServiceOption>>([]);
//     const [searchVal, setSearchVal] = useState<string | undefined>(undefined);
//     const [options, setOptions] = useState<ServiceOption[]>([]);

//     const [locationOptions, setLocationOptions] = useState<ServiceOption[]>([]);
//     const [isLocationLoader, setIsLocationLoader] = useState(false);

//     const handleChange = (selectedOptions: MultiValue<ServiceOption>, actionMeta: ActionMeta<ServiceOption>) => {
//         setSelectedServices(selectedOptions);
//     };
//     const handleBlur = () => {
//         setPostCode(postCode); // Ensure the postCode state is retained on blur
//     };
//     const handlePostCode = (e: ChangeEvent<HTMLInputElement>) => {
//         let value = e.target.value;
//         if (value === "" || (value.length <= 4 && /^\d*$/.test(value))) {
//             setPostCode(value);
//         }
//     };

//     // Functionality: API call for search services HINT / auto fill
//     const handleLookingFor = async (text: string) => {
//         if (text) {
//             setSearchVal(text);
//             // Clear previous timer
//             if (timeoutIdRef.current) {
//                 clearTimeout(timeoutIdRef.current);
//             }

//             // Set new timer
//             timeoutIdRef.current = setTimeout(async () => {
//                 setServiceLoader(true);
//                 // const { res, err } = await httpRequest({ path: `/api/service/getServices?q=${text}&page=${1}&pageSize=${6}&sort=${'asc'}&type=${"service"}` });
//                 const { res, err } = await httpRequest<ApiResponse>({ path: `/api/services/all` });
//                 if (res) {
//                     console.log(res, "asdsadsadsadasdasad")
//                     const data = res?.documents || [];

//                     // Flatten the documents array
//                     const flattenedDocuments = data.flatMap((category: any) => category.documents);
//                     // Map to the desired format
//                     const opts = flattenedDocuments.map((item: any) => ({
//                         value: item?._id,
//                         label: item?.servicename
//                     }));

//                     setOptions(opts);
//                     setIsDropDown(true);
//                 } else {
//                     toast(err?.message, { type: "error" }); // api error
//                 }
//                 setServiceLoader(false);
//             }, 500);
//         }
//         else {
//             setIsDropDown(false);
//             setOptions([]);
//             setSearchVal('')
//         }
//     }
//     const handleLocationSearch = async (post: string) => {
//         if (post) {
//             setIsLocationLoader(true);
//             const { res, err } = await httpRequest<ApiResponse>({
//                 path: `/api/postcodes/all?search=${post}`,
//             });
//             if (res) {
//                 const options = res?.documents.map((doc: any) => ({
//                     value: doc._id,
//                     label: `${doc.suburb} ${doc.state} ${doc.postcode}`,
//                 }));
//                 setLocationOptions(options);
//             } else {
//                 toast(err?.message, { type: "error" });
//             }
//             setIsLocationLoader(false);
//         } else {
//             setLocationOptions([]);
//         }
//     };
//     const handleLocationChange = (selected: SingleValue<ServiceOption>) => {
//         if (selected) {
//             setPostCode(selected.label); // Set the postCode to the selected option's label
//         } else {
//             setPostCode(''); // Clear the postCode if selection is cleared
//         }
//     };
//     const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
//         if (/^\d*$/.test(newValue)) { // Only allow numeric input
//             setPostCode(newValue);
//         }
//     };

//     const handleSubmit = (event: FormEvent) => {
//         event.preventDefault();
//         if (selectedServices.length === 0 || !postCode) {
//             toast("Please select a service and enter your postal code.", { type: "error" });
//         }
//         else if (postCode.length < 4) {
//             toast("Please enter valid 4 digit postcode", { type: "error" });
//         }
//         else {
//             const serviceIds = selectedServices.map(service => service.value);

//             const obj = {
//                 serviceIds,
//                 postCode
//             }
//             onChange(obj);
//         };
//     };
//     useEffect(() => {
//         const debounceTimer = setTimeout(() => {
//             if (postCode) {
//                 handleLocationSearch(postCode);
//             }
//         }, 1500);

//         return () => clearTimeout(debounceTimer);
//     }, [postCode]);


//     return (
//         <div className="paid-provider-form">
//             <form onSubmit={handleSubmit}>
//                 <div className="flex items-center mt-5">
//                     <div className="w-full relative">
//                         <FontAwesomeIcon
//                             className="absolute paid-provider-icon"
//                             icon={faSearch}
//                         />
//                         <div className="paid-provider-cust-search font-Poppins-Regular">
//                             <Select
//                                 // noOptionsMessage={"No Service"}
//                                 placeholder="Looking For...."
//                                 value={selectedServices}
//                                 options={options}
//                                 inputValue={searchVal}
//                                 onInputChange={handleLookingFor}
//                                 onChange={handleChange}
//                                 isMulti={true}
//                                 menuIsOpen={isDropDown}
//                                 isLoading={serviceLoader}
//                                 styles={{
//                                     control: (provided) => ({
//                                         ...provided,
//                                         border: "none", // border aur outline ko hatayein
//                                         outline: "none",
//                                     }),
//                                     indicatorsContainer: (provided) => ({
//                                         ...provided,
//                                         display: 'none', // Hide dropdown caret
//                                     }),
//                                 }}
//                                 isClearable
//                             />
//                         </div>
//                     </div>
//                     <div className="relative">
//                         <FontAwesomeIcon
//                             className="absolute paid-provider-input-icon"
//                             icon={faLocationDot}
//                         />

//                         <Select
//                             options={locationOptions}
//                             inputValue={postCode}
//                             onInputChange={handleInputChange}
//                             onChange={handleLocationChange}
//                             onBlur={handleBlur} // Added onBlur to retain the postCode
//                             isLoading={isLocationLoader}
//                             placeholder="Select Location"
//                             isClearable
//                         />

//                     </div>
//                     {/* <div className="relative">
//                         <FontAwesomeIcon
//                             className="absolute paid-provider-input-icon"
//                             icon={faLocationDot}
//                         />
//                         <input
//                             className="paid-provider-input-two border-s-2 theme-color-para font-Poppins-Regular outline-none "
//                             type="text"
//                             placeholder="Postcode"
//                             value={postCode}
//                             onChange={handlePostCode}
//                         />
//                     </div> */}

//                     <button disabled={loader} className="ml-5 text-white font-size-18px font-Poppins-SemiBold theme-bg-green paid-provider-search-btn" type="submit">Search</button>
//                 </div>
//             </form>
//         </div>
//     );
// };

// export default ServicesSearch;


import React, { useState, useEffect, useRef, ChangeEvent, FormEvent } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationDot, faSearch } from "@fortawesome/free-solid-svg-icons";
import Select, { MultiValue, SingleValue, ActionMeta, InputActionMeta } from "react-select";
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
import { components } from 'react-select';

interface ServiceOption {
    value: string;
    label: string;
}

interface ServicesSearchProps {
    loader?: boolean;
    onChange?: (selected: { serviceIds: string[], postCode: string, state: string }) => void;
}
interface ApiResponse {
    services: [];
    documents: [];
}

const NumericInput = (props: any) => {
    return (
        <components.Input
            {...props}
            inputMode="numeric"
            pattern="[0-9]*"
            maxLength={4}
            onInput={(e: React.FormEvent<HTMLInputElement>) => {
                e.currentTarget.value = e.currentTarget.value.replace(/\D/g, ''); // Only allow digits
            }}
        />
    );
};

const ServicesSearch: React.FC<ServicesSearchProps> = ({ loader = false, onChange = () => { } }) => {
    const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);

    const [serviceLoader, setServiceLoader] = useState(false);
    const [postCode, setPostCode] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [isDropDown, setIsDropDown] = useState(false);
    const [selectedServices, setSelectedServices] = useState<MultiValue<ServiceOption>>([]);
    const [searchVal, setSearchVal] = useState<string | undefined>(undefined);
    const [options, setOptions] = useState<ServiceOption[]>([]);

    const [locationOptions, setLocationOptions] = useState<ServiceOption[]>([]);
    const [isLocationLoader, setIsLocationLoader] = useState(false);

    const handleChange = (selectedOptions: MultiValue<ServiceOption>, actionMeta: ActionMeta<ServiceOption>) => {
        setSelectedServices(selectedOptions);
    };

    // const handlePostCode = (e: ChangeEvent<HTMLInputElement>) => {
    //     setPostCode(e.target.value);
    // };
    const handlePostCode = (e: ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/\D/g, ''); // Remove non-numeric characters
        if (value.length <= 4) {
            setPostCode(value);
        }
    };

    const handleLocationSearch = async (post: string) => {
        if (post) {
            setIsLocationLoader(true);
            const { res, err } = await httpRequest<ApiResponse>({
                path: `/api/postcodes/all?search=${post}`,
            });
            if (res) {
                const options = res?.documents.map((doc: any) => ({
                    value: doc._id,
                    label: `${doc.suburb} ${doc.state} ${doc.postcode}`,
                }));

                setLocationOptions(options);
                if (options.length > 0) {
                    const label = options[0].label;
                    const parts = label.split(' ');

                    const postcode = parts[parts.length - 1]; // last part (postcode)
                    const state = parts[parts.length - 2]; // second-to-last part (state)

                    // Now you can set the state and suburb individually
                    setState(state);
                } 
            } else {
                toast(err?.message, { type: "error" });
            }
            setIsLocationLoader(false);
        } else {
            setLocationOptions([]);
        }
    };
    // console.log(selected, "selectedselected")
    // const handleLocationChange = (selected: SingleValue<ServiceOption>) => {
    //     if (selected) {
    //         console.log(selected, "selectedselectedselected")
    //         setPostCode(selected.label); // This should trigger useEffect
    //     }
    // };
    // console.log(selected, "selectedselected")
    // const handleLocationChange = (selected: SingleValue<ServiceOption>) => {
    //     if (selected) {
    //         console.log(selected, "selectedselectedselected")
    //         setPostCode(selected.label); // This should trigger useEffect
    //     }
    // };
    const handleLocationChange = (selected: SingleValue<ServiceOption>) => {
        if (selected) {

            // Extract the last 4 digits from the selected.label
            const postcode = selected.label.match(/\d{4}$/)?.[0];

            // Set the postcode
            if (postcode) {
                setPostCode(postcode); // This should trigger useEffect
            }
        }
    };
    // const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
    //     if (actionMeta.action === 'input-change') {
    //         setPostCode(newValue); // Update postCode as the user types
    //     }
    // };

    const handleInputChange = (newValue: string, actionMeta: InputActionMeta) => {
        if (actionMeta.action === 'input-change') {
            const numericValue = newValue.replace(/\D/g, ''); // Remove non-numeric characters
            if (numericValue.length <= 4) {
                setPostCode(numericValue); // Update postCode as the user types, only if it has 4 or fewer digits
            }
        }
    };

    const handleLookingFor = async (text: string) => {
        if (text) {
            setSearchVal(text);
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }

            timeoutIdRef.current = setTimeout(async () => {
                setServiceLoader(true);
                const { res, err } = await httpRequest<ApiResponse>({ path: `/api/services/all` });
                if (res) {
                    const data = res?.documents || [];
                    const flattenedDocuments = data.flatMap((category: any) => category.documents);
                    const opts = flattenedDocuments.map((item: any) => ({
                        value: item?._id,
                        label: item?.servicename
                    }));

                    setOptions(opts);
                    setIsDropDown(true);
                } else {
                    toast(err?.message, { type: "error" });
                }
                setServiceLoader(false);
            }, 500);
        } else {
            setIsDropDown(false);
            setOptions([]);
            setSearchVal('');
        }
    };

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (selectedServices.length === 0 || !postCode) {
            toast("Please select a service and enter your postal code.", { type: "error" });
        }

        else {
            const serviceIds = selectedServices.map(service => service.value);
            const obj = {
                serviceIds,
                postCode,
                state
            };
            onChange(obj);
        }
    };

    useEffect(() => {
        if (postCode) {
            const debounceTimer = setTimeout(() => {
                handleLocationSearch(postCode);
            }, 500); // Reduced debounce time for quicker API hit

            return () => clearTimeout(debounceTimer);
        }
    }, [postCode]);

    return (
        <div className="paid-provider-form">
            <form onSubmit={handleSubmit}>
                <div className="flex items-center mt-5 search-post-parent relative z-10">
                    <div className="w-full relative">
                        <FontAwesomeIcon
                            className="absolute paid-provider-icon"
                            icon={faSearch}
                        />
                        <div className="paid-provider-cust-search font-Poppins-Regular">
                            <Select
                                placeholder="Looking For...."
                                value={selectedServices}
                                options={options}
                                inputValue={searchVal}
                                onInputChange={handleLookingFor}
                                onChange={handleChange}
                                isMulti={true}
                                menuIsOpen={isDropDown}
                                isLoading={serviceLoader}
                                styles={{
                                    control: (provided) => ({
                                        ...provided,
                                        border: "none", // border and outline removed
                                        outline: "none",
                                    }),
                                    indicatorsContainer: (provided) => ({
                                        ...provided,
                                        display: 'none', // Hide dropdown caret
                                    }),
                                }}
                                isClearable
                            />
                        </div>
                    </div>
                    <div className="relative search-post-child">
                        <FontAwesomeIcon
                            className="absolute paid-provider-input-icon"
                            icon={faLocationDot}
                        />
                        <Select
                            options={locationOptions}
                            onInputChange={handleInputChange} // Capture user input here
                            onChange={handleLocationChange} // Update state when an option is selected
                            isLoading={isLocationLoader}
                            placeholder="Select location"
                            isClearable
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: "none", // border and outline removed
                                    outline: "none",
                                }),
                                indicatorsContainer: (provided) => ({
                                    ...provided,
                                    display: 'none', // Hide dropdown caret
                                }),
                            }}
                        />

                        {/* <Select
                            components={{ Input: NumericInput }}
                            options={locationOptions}
                            inputValue={postCode}
                            onInputChange={handleInputChange}
                            onChange={handleLocationChange}
                            isClearable
                            placeholder="Select Location"
                            isLoading={isLocationLoader}
                            styles={{
                                control: (provided) => ({
                                    ...provided,
                                    border: "none", // border and outline removed
                                    outline: "none",
                                }),
                                indicatorsContainer: (provided) => ({
                                    ...provided,
                                    display: 'none', // Hide dropdown caret
                                }),
                            }}
                        /> */}
                    </div>

                    <button disabled={loader} className="ml-5 text-white font-size-18px font-Poppins-SemiBold theme-bg-green paid-provider-search-btn" type="submit">Search</button>
                </div>
            </form>
        </div>
    );
};

export default ServicesSearch;
