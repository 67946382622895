/**
 * Connections Component
 * Displays connections with filtering by name and user type (participant & provider),
 * pagination support, and navigation to user profiles on name click.
 * File: Connections.jsx
 * Author: Developer
 * Date: 03-05-24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";

import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import { toast } from "react-toastify";
import qs from "qs";
import { CircularProgress } from "@mui/material";
import RemoveButton from "../../../components/ConnectionsComponents/RemoveButton";
import ApprovedConnection from "../../../components/ConnectionsComponents/ApprovedConnection";
import DeclineConnection from "../../../components/ConnectionsComponents/DeclineConnection";
import { Link } from "react-router-dom";
import httpRequest from "../../../config/Helpers/httpRequest";
import { getTimeDifference } from "../../../config/Helpers/helpers";

interface Connection {
  userId: string;
  profileImageUrl: string;
  firstName: string;
  lastName: string;
  userRole: string;
  role: string;
  roleCategory: string;
  profileSummary?: string;
  [key: string]: any;
}

interface QueryParams {
  search: string;
  page: number;
  limit: number;
  sortBy: string;
}
interface paginated {
  currentPage: number;
  totalPages: number;
  limit: number;
  totalItems: number;

}
interface ApiResponse {
  documents: [];
  paginated: paginated;
  [key: string]: any
}
const Connections: React.FC = () => {

  const [activeTab, setActiveTab] = useState<string>("connectList");
  const [tempValue, setTempValue] = useState<string>(""); // value only for input
  const [isLoader, setIsLoader] = useState<boolean>(false); // for loader
  const [totalPages, setTotalPages] = useState<number>(1); // for total pages pagination
  const [totalCount, setTotalCount] = useState<number>(0);
  const [allConnections, setAllConnections] = useState<Connection[]>([]);

  const [bool, setBool] = useState<boolean>(false);


  const [queryParams, setQueryParams] = useState<QueryParams>({
    search: "",
    page: 1,
    limit: 10,
    sortBy: "",
  }); // query params for API

  const handleDebounce = (value: string) => {
    if (value !== " ") {
      setTempValue(value);
      if (!value) {
        setQueryParams((prev) => ({ ...prev, search: "", page: 1 }));
      }
    }
  };

  // Functionality: API call get all connection with status (connected & pending)
  const fetchConnections = async () => {
    setIsLoader(true);
    const ApiPath = activeTab === "connectList" ? "/api/connection/all-connections" : "/api/connection/pending-connections";
    const { res, err } = await httpRequest<ApiResponse>({
      path: `${ApiPath}?${qs.stringify(queryParams)}`,
    });
    if (res) {
      setAllConnections(res?.documents || []);
      // let count = res?.totalResults[0]?.value || 0;
      setTotalCount(res?.paginated?.totalItems);
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" })
    }
    setIsLoader(false);
  };

  const requestApproved = () => {
    setActiveTab("connectList");
  };

  // if is successed removed then call the function
  const successRemovedConnection = () => {
    if (queryParams.page === 1) {
      // If queryParams.page is already 1, no need to decrement
      setBool((prev) => !prev);
    } else {
      // If queryParams.page is greater than 1, decrement page by 1
      setQueryParams({
        ...queryParams,
        page:
          allConnections.length === 1 ? queryParams.page - 1 : queryParams.page,
      });
      setBool((prev) => !prev);
    }
  };

  useEffect(() => {
    if (tempValue) {
      const debounceTimer = setTimeout(() => {
        setQueryParams((prev) => ({ ...prev, search: tempValue, page: 1 }));
      }, 1500);

      return () => clearTimeout(debounceTimer);
    }
  }, [tempValue]);

  useEffect(() => {
    fetchConnections();
  }, [activeTab, queryParams, bool]); // In componentDidMount, call api for get connections

  return (
    <div className="overflow-x-hidden">
      <HeaderGlobal />
      <div className="container-1480 h-3vw">
        <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-7 pb-3 px-6 ">
          <h2 className="font-size-36px theme-color-para font-Poppins-SemiBold theme-color-green">
            <span>{totalCount || 0}</span> Connections
          </h2>
          <div className="lg:flex block justify-between items-center">
            <div className="flex gap-4 recently-connections items-center">
              <p className="font-size-15px theme-grey-type  font-Poppins-Regular  text-center">
                Sort by:
              </p>
              <select
                className="font-Poppins-Medium font-size-15px theme-grey-type"
                value={queryParams.sortBy}
                onChange={(e) =>
                  setQueryParams({
                    ...queryParams,
                    page: 1,
                    sortBy: e.target.value,
                  })
                }
              >

                <option value="">Recently added</option>
                <option value="firstname">First name</option>
                <option value="lastname">Last name</option>
              </select>
            </div>{" "}
            {/* sortBy by first name & last name */}
            <div className="lg:flex block gap-4 items-center">
              <div className="relative w-full connections-status">
                <span className="calender-top-fields-placeholder absolute font-Poppins-Medium theme-grey-type">
                  Filter
                </span>
                <select
                  className="font-Poppins-Medium font-size-15px w-full calender-top-field height-of-select-input-fields-of-calender"
                  // placeholder="User Type"
                  value={activeTab}
                  onChange={(e) => {
                    setQueryParams({ ...queryParams, page: 1 });
                    setActiveTab(e.target.value);
                  }}
                >
                  <option value="connectList">All connections</option>
                  <option value="pendingList">Pending</option>
                </select>
              </div>{" "}
              {/* connection status pending & allconnection */}
              <div className="relative paid-provider-connection-ipt">
                <FontAwesomeIcon icon={faMagnifyingGlass} />
                <input
                  className="font-Poppins-SemiBold font-size-14px"
                  placeholder="Search"
                  type="search"
                  value={tempValue}
                  onChange={(e) => handleDebounce(e.target.value)}
                />
              </div>{" "}
              {/* search filter by name */}
            </div>
          </div>
          <hr className="my-3" />
          {isLoader ? (
            <>
              <div className="full_page_loader">
                <CircularProgress style={{ color: "#fff" }} />
              </div>
            </>
          ) : allConnections && allConnections.length > 0 ? (
            allConnections.map((item, index) => (
              <div key={index} className="flex row items-center gap-3 mb-5">
                <img
                  src={`${item?.profileImageUrl}`}
                  // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                  className="connections-img"
                  alt=""
                />
                {/* user image */}
                <div className="border-b-2 pb-3 block lg:flex item-center justify-between w-full items-center">
                  <div className="">
                    <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green flex gap-3 items-center capitalize">
                      <Link to={`/public-profile/${item?.userId}/view`}>{`${item?.firstName || ""
                        } ${item?.lastName || ""} `}</Link>
                      {/* user name */}
                      <span className="font-size-15px font-Poppins-Regular theme-grey-type">
                        {item?.roleCategory || ""}
                        {/* user role */}
                      </span>
                    </h4>
                    {activeTab === "connectList" ? (
                      <p className="font-size-15px font-Poppins-Regular theme-grey-type">
                        {/* {item?.profileSummary || ""} */}
                        Connected {getTimeDifference(item?.updatedAt)}
                        {/* user profile summary */}
                      </p>


                    )
                      :

                      (
                        <p className="font-size-15px font-Poppins-Regular theme-grey-type">
                          {/* {item?.profileSummary || ""} */}
                          Connection request recieved {getTimeDifference(item?.createdAt)}
                          {/* user profile summary */}
                        </p>
                      )
                    }

                  </div>
                  <div className="flex gap-3 lg:my-0 my-2 item-center">
                    {activeTab === "connectList" ? (
                      <>
                        <Link
                          to={`${process.env.REACT_APP_CHATAPP}/${item?.userId}/chat`}
                          className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  hover:text-white hover:bg-[#00443F]"
                        >
                          Message
                        </Link>
                        <RemoveButton
                          id={item?.userId || ""}
                          onSuccess={successRemovedConnection}
                        />
                      </>
                    ) : (
                      <>
                        <ApprovedConnection
                          id={item?.userId || ""}
                          onSuccess={requestApproved}
                        />
                        <DeclineConnection
                          id={item?.userId || ""}
                          onSuccess={successRemovedConnection}
                          btnText="Decline"
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No connection</p>
          )}
          <PaginationComponet
            currentPage={queryParams.page}
            total={totalPages}
            setCurrentPage={(e) => setQueryParams({ ...queryParams, page: e })}
          />
        </div>
      </div>
    </div >
  );
}

export default Connections;
