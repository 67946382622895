import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Announce from "../../assets/img/declineReason.svg"
import AnnounceRed from "../../assets/img/redAnnounce.svg"
import { toast } from "react-toastify";

interface AdsInfoModalProps {
    onClose: () => void;
    Image?: string;
    Title?: string;
    description?: string;
}
interface ApiResponse {
    message?: string;
    [key: string]: any;
}
const DeclineReason: React.FC<AdsInfoModalProps> = ({ onClose, Image, Title, description }) => {




    return (
        <>
            <div className="modal" >
                <div className="Cancellation-modal-content bg-declined-modal relative">
                    <div className="flex flex-col justify-center">
                        <button onClick={() => {
                            onClose();
                        }}
                            className="absolute job-pre-edit">
                            <FontAwesomeIcon className="x-icon-admin-opr" icon={faX} style={{ color: "#ffffff" }} />
                        </button>
                        {/* <div className="cancellation-envolpe-main">
                            <div className="cancellation-envolpe">
                                <img src={Announce} alt="approval-tag" />
                            </div>
                        </div> */}
                        <h2 className="font-Poppins-SemiBold font-size-30px text-[#2F2F31] ">
                            {Title}
                        </h2>
                        <div className="mt-2 border border-[#707070] rounded-md p-3 text-left">
                            <p className="font-size-15px font-Poppins-Regular">
                                {description}
                            </p>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
};

export default DeclineReason;
