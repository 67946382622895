/**
 * AllReports Component
 * 
 * Description: Displays and manages all reports with pagination and status filtering.
 * 
 * File: AllReports.jsx
 * Author: Developer
 * Date: 03-07-24
 */

import React, { useState, useEffect } from "react";
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../../components/AdminComponents/AdminSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { faEye, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import httpRequest from "../../../config/Helpers/httpRequest";
import qs from "qs";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
interface Report {
    _id: string;
    reportNumber?: string;
    reportedType?: string;
    reportStatus?: string;
    count?: number;
    reportedCategory?: string;
    [key: string]: any;
}

interface QueryParams {
    page: number;
    limit: number;
    status: string;
}

interface Parginated {
    totalPages: number;
    [key: string]: any;
}
interface ApiResponse {
    documents: Report[];
    paginated: Parginated;
    [key: string]: any;

}

function AllReports() {
    const navigate = useNavigate();

    let tabList = [
        { key: '', title: 'All reports' },
        { key: 'pending', title: 'Pending' },
        { key: 'resolved', title: 'Resolved' },
    ]; // List of tabs for different report statuses

    // State variables
    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [displayReports, setDisplayReports] = useState<Report[]>([]); // Reports to display
    const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages for pagination
    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: 1,
        limit: 10,
        status: ""
    }); // Query params for API

    const handlePageChange = (page: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: page,
        }));
    }; // Handler for changing pages in pagination

    const handleStatusChange = (status: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            status: status
        }));
    }; // Handler for changing report status filter

    const handleNavigate = (item: Report) => {
        let { reportedCategory, _id } = item;
        if (reportedCategory === "post" || item?.reportStatus === "resolved") {
            navigate(`/reports/${_id}/post?status=${item?.reportStatus}`);
            return
        }
        // if (reportedCategory === "post") {
            navigate(`/reports/${_id}/post`);
        // }
        return "";
    };

    // Functionality: API call to fetch reports from the server
    const fetchReports = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/report/all?${qs.stringify(queryParams)}` });
        if (res) {
            setDisplayReports(res?.documents || []);
            setTotalPages(res?.paginated?.totalPages || 1);
        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };

    // Fetch reports on component mount and when queryParams change
    useEffect(() => {
        fetchReports();
    }, [queryParams]);

    return (
        <div>
            <div className="flex font-poppins-regular">
                <AdminSidebar />
                <div className="w-4/5 ml-auto">
                    <AdminHeader />
                    <div className="pt-24 Admin-main-operator-parent">
                        <div className="pb-6 pl-6 pr-10">
                            <h2 className="font-size-36px font-Poppins-Medium">Report</h2>
                            <p> <span className="font-size-16px font-Poppins-Bold pb-5">Dashboard /</span> <span className="font-Poppins-Medium"> Report</span></p>
                            <div className="">
                                <div className="dispute-header my-5">
                                    {tabList.map((v, i) => (
                                        <button disabled={queryParams?.status === v.key} key={i} onClick={() => handleStatusChange(v.key)} className={queryParams?.status === v.key ? 'font-Poppins-Regular font-size-18px active' : 'font-Poppins-Regular font-size-18px '}>{v.title}</button>
                                    ))}
                                </div>
                                <div className="tab-content">
                                    <div className="main-Admin-status-action">
                                        <ul
                                            style={{ gridTemplateColumns: "20% 20% 20% 16% 12% 12%" }}
                                            className="Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                                        >
                                            <li className="text-white">Id</li>
                                            <li className="text-white">Type</li>
                                            <li className="text-white">Status</li>
                                            <li className="text-white">Count</li>
                                            <li className="text-white">Details</li>
                                            <li className="text-white">View</li>
                                        </ul>
                                    </div>
                                    {isLoader ?
                                        <div className="main-Admin-status-content mt-5 text-center">
                                            <CircularProgress style={{ color: '#00443f', width: 20, height: 20 }} />
                                        </div>
                                        :
                                        displayReports && displayReports.length > 0 ?
                                            <>
                                                {displayReports.map((item, index) => (
                                                    <div key={index} className="main-Admin-status-content mt-5">
                                                        <ul
                                                            style={{ gridTemplateColumns: "20% 20% 20% 16% 12% 12%" }}
                                                            className="Admin-status-content-Role font-Poppins-Regular font-size-18px"
                                                        >
                                                            <li className="flex justify-center">{item?.serial || ""}</li>
                                                            <li>{item?.contentType || "-"}</li>
                                                            <li>
                                                                <div className="flex items-center justify-center gap-2">
                                                                    <span className={`${item?.reportStatus === "pending" ? "pending-dispute" : "resolved-dispute"}`}>
                                                                        <span></span>
                                                                    </span>
                                                                    <span className="capitalize">{item?.reportStatus || "-"}</span>
                                                                </div>
                                                            </li>
                                                            <li className="flex flex-row items-center gap-2 justify-center">{item?.count || 1}</li>
                                                            <li>
                                                                <Link to={`/reports/${item?._id}/view`}>
                                                                    <FontAwesomeIcon className="dispute-eye" icon={faClipboardList} />
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <button onClick={() => handleNavigate(item)}>
                                                                    <FontAwesomeIcon className="dispute-eye" icon={faEye} />
                                                                </button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                ))}
                                                <PaginationComponet
                                                    total={totalPages}
                                                    setCurrentPage={handlePageChange}
                                                    currentPage={queryParams?.page}
                                                />
                                            </>
                                            :
                                            <div className="main-Admin-status-content mt-5">
                                                <p className="text-center">No Reports</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllReports;