

import React, { useState, useEffect, useRef } from "react";
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../../components/AdminComponents/AdminSidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { faEye, faClipboardList, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import httpRequest from "../../../config/Helpers/httpRequest";
import qs from "qs";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import Polygon from "../../../assets/img/Polygon.svg";
import Invoice from "../../../assets/img/invoice.svg";
import Analytic from "../../../assets/img/analytic.svg";
import DeclineReasonImg from "../../../assets/img/declineReason.svg";
import ConfirmationModal from "./Modals/ConfirmationModal";
import ViewPostModal from "./Modals/ViewPostModal";
import DeclineReason from "../../../components/Modal/DeclineReason";

interface AllAds {
    title?: string;
    status?: String;
    lastName?: string;
    firstName?: string;
    [key: string]: any;
}

interface QueryParams {
    page: number;
    limit: number;
    status: string;
    type: string;
}

interface Parginated {
    totalPages: number;
    [key: string]: any;
}
interface ApiResponse {
    documents: AllAds[];
    paginated: Parginated;
    [key: string]: any;

}

function AllAds() {
    const navigate = useNavigate();

    let tabList = [
        // { key: '', title: 'All reports' },
        { key: 'in-review', title: 'Pending' },
        // { key: 'paused', title: 'Paused' },
        { key: 'active', title: 'Active' },
        { key: 'completed', title: 'Completed' },
        { key: 'declined', title: 'Declined' },
    ]; // List of tabs for different report statuses
    const ref = useRef<HTMLDivElement>(null);


    // State variables
    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [allAds, setAllAds] = useState<AllAds[]>([]); // Reports to display
    const [totalPages, setTotalPages] = useState<number>(1); // Total number of pages for pagination

    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: 1,
        limit: 10,
        status: "in-review",
        type: ""
    }); // Query params for API
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
    const [selectedPostId, setSelectedPostId] = useState<string>(""); // State to store the selected post ID
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [modalType, setModalType] = useState<string>('approve-modal'); // Add modalType state
    const [promoId, setPromoId] = useState<string>("");
    const [openDropdownIndex, setOpenDropdownIndex] = useState<number | null>(null);
    const [isDeclineModalOpen, setDeclineModalOpen] = useState(false);
    const [declineReasonModal, setDeclineReasonModal] = useState<string>("")

    // Function to open the modal
    const handleOpenModal = () => {
        setDeclineModalOpen(true);
    };

    // Function to close the modal
    const handleCloseDeclineModal = () => {
        setDeclineModalOpen(false);
    };
    const handleViewPost = (postId: string) => {
        setOpenDropdownIndex(null);
        setSelectedPostId(postId);  // Set the post ID to the clicked post
        setIsModalOpen(true);       // Open the modal
    };


    // Handle close modal
    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedPostId(""); // Reset the post ID
    };
    const handleDropdownToggle = (index: number, id: string) => {
        setOpenDropdownIndex(openDropdownIndex === index ? null : index); // Toggle dropdown for the clicked index
    };
    // Handle open modal
    const handleOpenType = (type: string, id: string) => {
        setModalType(type);
        setPromoId(id)
        setIsOpen(true);
    };

    // Handle close modal
    const handleClose = () => {
        setIsOpen(false);
    };
    const handleTypeChange = (type: string) => {
        setQueryParams(prev => ({
            ...prev,
            type: type
        }))
    }

    const handlePageChange = (page: number) => {
        setQueryParams(prev => ({
            ...prev,
            page: page,
        }));
    }; // Handler for changing pages in pagination

    const handleStatusChange = (status: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            status: status
        }));
    }; // Handler for changing report status filter

    // const handleNavigate = (item: AllAds) => {
    //     let { reportedCategory, _id } = item;
    //     if (reportedCategory === "post" || item?.reportStatus === "resolved") {
    //         navigate(`/reports/${_id}/post?status=${item?.reportStatus}`);
    //         return
    //     }
    //     // if (reportedCategory === "post") {
    //     navigate(`/reports/${_id}/post`);
    //     // }
    //     return "";
    // };

    // Functionality: API call to fetch reports from the server
    const fetchReports = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/admin/ad/promotions?${qs.stringify(queryParams)}` });
        if (res) {
            setAllAds(res?.documents || []);
            setTotalPages(res?.paginated?.totalPages || 1);
        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };
    const handleNavigate = (id: string, type: string) => {
        // if (type === 'post') {
        navigate(`/insight/${id}/details`);
        // }
    }
    // const refreshReports = () => {
    //     fetchReports();  // Refetch reports after a successful action
    // };
    // Fetch reports on component mount and when queryParams change
    useEffect(() => {
        fetchReports();
    }, [queryParams]);

    useEffect(() => {
        const handleOverlayClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                const delay = (milliseconds: number): Promise<void> => {
                    return new Promise((resolve) => setTimeout(resolve, milliseconds));
                };

                const handleAction = async () => {
                    await delay(100);
                    setOpenDropdownIndex(null);
                };

                handleAction(); // Call handleAction to start the delay
            }
        };

        document.addEventListener("mousedown", handleOverlayClick);

        return () => {
            document.removeEventListener("mousedown", handleOverlayClick);
        };
    }, []);
    return (
        <div>
            <div className="flex font-poppins-regular">
                <AdminSidebar />
                <div className="w-4/5 ml-auto">
                    <AdminHeader />
                    <div className="pt-24 Admin-main-operator-parent">
                        <div className="pb-6 pl-6 pr-10">
                            <h2 className="font-size-36px font-Poppins-Medium ">ndisync Ads</h2>
                            <p> <span className="font-size-16px font-Poppins-Bold pb-5">Dashboard /</span> <span className="font-Poppins-Medium"> Ads manager</span></p>
                            <div className="h-[70vh] pt-5">
                                <div className="flex flex-row justify-between items-center pb-3">
                                    <div className="dispute-header dispute-header-2">
                                        {tabList.map((v, i) => (
                                            <button disabled={queryParams?.status === v.key} key={i} onClick={() => handleStatusChange(v.key)} className={queryParams?.status === v.key ? 'font-Poppins-Regular font-size-18px active' : 'font-Poppins-Regular font-size-18px '}>{v.title}</button>
                                        ))}
                                    </div>
                                    <div className="relative w-fit">
                                        <span className="text-[#B5B5B5] font-size-13px font-Poppins-Medium custome-select-label">Promotion type</span>
                                        <img className="polygon-2" src={Polygon} alt="" />
                                        <select className="font-Poppins-Medium font-size-15px custom-select-filter-2" onChange={(e) => handleTypeChange(e.target.value)}>
                                            <option value="">All</option>
                                            <option value="profile">Profile promotion</option>
                                            <option value="post">Post promotion</option>

                                        </select>
                                    </div>

                                    {/* <div className="select-inner-text relative">
                                        <span>Promotion type</span>
                                        <select
                                            className="border-2 p-3 Main-AdminUsers font-Poppins-Medium mr-10 font-size-15px"
                                            onChange={(e) => handleTypeChange(e.target.value)}
                                        >
                                            <option value="">All</option>
                                            <option value="profile">Profile promotion</option>
                                            <option value="post">Post promotion</option>
                                        </select>
                                    </div> */}

                                </div>
                                <div className="tab-content">
                                    <div className="main-Admin-status-action">
                                        <ul

                                            style={
                                                queryParams.status !== "in-review" ?

                                                    { gridTemplateColumns: "19% 19% 14% 16% 11% 12% 9%" }
                                                    :
                                                    { gridTemplateColumns: "19% 13% 8% 10% 15% 13% 22%" }

                                            }

                                            className="outer-detail-border main-Admin-status-action Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                                        >
                                            <li className="text-white">Name</li>
                                            <li className="text-white">Promotion name</li>
                                            <li className="text-white">Type</li>
                                            <li className="text-white">Packages</li>
                                            {queryParams.status !== "completed" ?

                                                (
                                                    <>
                                                        <li className="text-white">Status</li>
                                                        <li className="text-white">Date created</li>

                                                    </>
                                                )
                                                :
                                                (
                                                    <>
                                                        <li className="text-white">Date created</li>
                                                        <li className="text-white">Date ended</li>

                                                    </>
                                                )
                                            }

                                            <li className="text-white">Action</li>
                                        </ul>
                                    </div>
                                    {isLoader ?
                                        <div className="main-Admin-status-content mt-5 text-center">
                                            <CircularProgress style={{ color: '#00443f', width: 20, height: 20 }} />
                                        </div>
                                        :
                                        allAds && allAds.length > 0 ?
                                            <>
                                                {allAds.map((item, index) => (
                                                    <div key={index} className="main-Admin-status-content mt-5">
                                                        <ul
                                                            style={
                                                                queryParams.status !== "in-review" ?

                                                                    { gridTemplateColumns: "19% 19% 14% 16% 11% 12% 9%" }
                                                                    :
                                                                    { gridTemplateColumns: "19% 13% 8% 10% 15% 13% 22%" }

                                                            }
                                                            className="under-detail-border Admin-status-content-Role main-Admin-status-content text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                                                        >
                                                            <li className="grid gap-1 capitalize px-1  img-name-ads"
                                                                style={{ gridTemplateColumns: "20% 78%" }}
                                                            >
                                                                <img src={item?.profileImageUrl} />
                                                                <span>
                                                                    {item?.firstName || ""} {item?.lastName || ""}
                                                                </span>
                                                            </li>

                                                            <li className="capitalize">{item?.title || "-"}</li>

                                                            <li className="flex flex-row items-center gap-2 justify-center capitalize">{item?.refType || ""}</li>
                                                            <li className="capitalize">
                                                                <span>{item?.promotionTitle}</span>
                                                            </li>
                                                            <li>
                                                                {/* {queryParams.status === "in-review" || queryParams.status === "declined" && */}
                                                                {queryParams.status !== "completed" ?

                                                                    (
                                                                        <span 
                                                                        className={`w-[5.8vw] rounded-full ${item?.status === "in-review" ? "pending-ads" : item?.status === "declined" ? "declined-ads" : item?.status === "active" ? "active-ads" : "paused-ads"}`}
                                                                        >
                                                                            {item?.status === "in-review" ? "Pending" : item?.status === "paused" ? "Paused" : item?.status === "active" ? "Active" : "Declined"}
                                                                        </span>
                                                                    )

                                                                    :
                                                                    (
                                                                        <p>{moment(item?.createdAt).format("DD-MM-YYYY")}</p>
                                                                    )
                                                                }

                                                                {/* } */}

                                                            </li>
                                                            {queryParams.status !== "completed" ?
                                                                (
                                                                    <li>

                                                                        {moment(item?.createdAt).format("DD-MM-YYYY")}
                                                                    </li>
                                                                )
                                                                :
                                                                (
                                                                    <li>

                                                                        {moment(item?.updatedAt).format("DD-MM-YYYY")}
                                                                    </li>
                                                                )
                                                            }                                                            <li>

                                                                <div className="flex flex-row gap-2 items-center">
                                                                    {queryParams.status === "in-review" &&
                                                                        <>
                                                                            <button
                                                                                // onClick={() => setIsOpen(true)}
                                                                                onClick={() => handleOpenType("approve-modal", item?._id)}
                                                                                className="font-size-16px font-Poppins-Medium text-[#ffffff] rounded-full w-[5.5vw] approve-btn-ads py-2">
                                                                                Approve
                                                                            </button>
                                                                            <button
                                                                                onClick={() => handleOpenType("decline-modal", item?._id)}

                                                                                className="font-size-16px font-Poppins-Medium text-[#ffffff] rounded-full w-[5.5vw] decline-btn-ads py-2">

                                                                                Decline
                                                                            </button>
                                                                        </>
                                                                    }

                                                                    {item?.status === "declined" ?

                                                                        (
                                                                            <>
                                                                                <img
                                                                                    className="cancel-icon cursor-pointer"

                                                                                    onClick={() => {
                                                                                        handleOpenModal()
                                                                                        setDeclineReasonModal(item?.reason)
                                                                                    }}
                                                                                    src={DeclineReasonImg} />

                                                                            </>
                                                                        )
                                                                        :
                                                                        (
                                                                            <div className="relative">
                                                                                <button
                                                                                    onClick={() => handleDropdownToggle(index, item?._id)}
                                                                                // onClick={() => handleNavigate(item)}
                                                                                >
                                                                                    {/* <FontAwesomeIcon className="dispute-eye" icon={faEye} /> */}
                                                                                    <FontAwesomeIcon
                                                                                        className="cursor-pointer"
                                                                                        icon={faEllipsisVertical}
                                                                                    />
                                                                                </button>
                                                                                {openDropdownIndex === index && (

                                                                                    <div ref={ref} className="z-10 bg-[#FFFFFF] border border-[#E5E5E5] absolute w-[10vw] right-0 top-[2vw] py-2 ">
                                                                                        {item?.refType === "post" ?

                                                                                            <button
                                                                                                onClick={() => handleViewPost(item?.refId)}
                                                                                                className="w-full  px-2 grid gap-2 mb-1" style={{ gridTemplateColumns: "19% 75%" }}>
                                                                                                <span className="flex justify-center items-center">
                                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                                </span>
                                                                                                <span className="font-size-15px font-Poppins-Regular text-left">View post</span>
                                                                                            </button>
                                                                                            :
                                                                                            <Link
                                                                                                to={`/public-profile/${item?.refId}/view`}

                                                                                                // onClick={() => handleViewPost(item?.refId)}

                                                                                                className="w-full  px-2 grid gap-2 mb-1" style={{ gridTemplateColumns: "19% 75%" }}>
                                                                                                <span className="flex justify-center items-center">
                                                                                                    <FontAwesomeIcon icon={faEye} />
                                                                                                </span>
                                                                                                <span className="font-size-15px font-Poppins-Regular text-left">View Profile</span>
                                                                                            </Link>


                                                                                        }



                                                                                        <Link to={`/billing-detail/${item?._id}/details`} className="px-2 mb-1 grid gap-2 w-full" style={{ gridTemplateColumns: "19% 75%" }}>
                                                                                            <span className="flex justify-center items-center">
                                                                                                <img
                                                                                                    className="billing-invoice"
                                                                                                    src={Invoice}
                                                                                                    alt="Billing Detail"
                                                                                                />
                                                                                            </span>

                                                                                            <span className="font-size-15px font-Poppins-Regular text-left">Billing detail</span>
                                                                                        </Link>
                                                                                        {item?.status !== "in-review" &&
                                                                                            <button

                                                                                                onClick={() => handleNavigate(item?._id, item?.refType)}
                                                                                                className="px-2  grid gap-2 w-full" style={{ gridTemplateColumns: "19% 74%" }}
                                                                                            >
                                                                                                <span className="flex justify-center items-center">

                                                                                                    <img
                                                                                                        className="billing-invoice"
                                                                                                        src={Analytic}
                                                                                                        alt="Billing Detail"
                                                                                                    />
                                                                                                </span>
                                                                                                <span className="font-size-15px font-Poppins-Regular text-left">View insights</span>
                                                                                            </button>

                                                                                        }

                                                                                    </div>
                                                                                )}
                                                                            </div>
                                                                        )

                                                                    }

                                                                </div>


                                                            </li>
                                                        </ul>
                                                    </div>
                                                ))}
                                                <PaginationComponet
                                                    total={totalPages}
                                                    setCurrentPage={handlePageChange}
                                                    currentPage={queryParams?.page}
                                                />
                                            </>
                                            :
                                            <div className="main-Admin-status-content mt-5">
                                                <p className="text-center">No Reports</p>
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {isOpen && */}
            <ConfirmationModal
                isOpen={isOpen}
                onClose={handleClose}
                modalType={modalType}
                promoId={promoId}
                handleStatusChange={handleStatusChange}
            />
            <ViewPostModal

                isOpen={isModalOpen}
                postId={selectedPostId}
                onClose={handleCloseModal}

            />

            {isDeclineModalOpen && (

                <DeclineReason Title="Reason" description={declineReasonModal} onClose={handleCloseDeclineModal} />
            )}
            {/* } */}
        </div >
    );
};

export default AllAds;