import React, { useEffect, useState, MouseEvent } from "react";
import {
  faClockRotateLeft,
  faEnvelope,
  faLocationDot,
  faPhone,
  faEllipsis,
  faEnvelopesBulk,
  faCity,
  faGlobe,
  faUser,
  faChevronDown,
  faShareNodes,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {

  faFacebookSquare,
  faInstagram,
  faLinkedin,
  faWhatsapp,


} from "@fortawesome/free-brands-svg-icons";
import closeIcon from "../../../assets/img/closeIcon.svg";
import { useSelector } from "react-redux";
import { formatDate, getRole } from "../../../config/Helpers/helpers";
import facebookshare from "../../../assets/img/facebookshare.svg";
import whatsappshare from "../../../assets/img/whatsappshare.svg";
import linkedinshare from "../../../assets/img/linkedinshare.svg";
import twittershare from "../../../assets/img/twittershare.svg";
import instagramshare from "../../../assets/img/instagramshare.svg";
import BlockUser from "../../ConnectionsComponents/BlockUser";
import { toast } from "react-toastify";
import Skeleton from '@mui/material/Skeleton';
import { RootState } from "../../../config/Store/store";
import { useLocation } from "react-router-dom";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
import PostcodeICon from "../../../assets/img/postcode-icon.svg"
import SuburbIcon from "../../../assets/img/suburb-icon.svg"
import StateIcon from "../../../assets/img/state-icon.svg"
import EmailIcon from "../../../assets/img/email-icon.svg"
import TelephoneIcon from "../../../assets/img/telephone-icon.svg"
import LocationIcon from "../../../assets/img/location-icon.svg"

interface AddressSocialDetailProps {
  unpaidModalShow: boolean;
  userId?: string;
}
const AddressSocialDetail: React.FC<AddressSocialDetailProps> = ({
  unpaidModalShow = false, // Default value if not provided
  userId = ""
}) => {
  const decoded = getRole();
  const isAdmin = decoded?.role;
  const location = useLocation();

  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);

  const [settingmodal, setSettingModal] = useState(false);
  const [profileLink, setProfileLink] = useState("");

  const handleCopy = () => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = profileLink;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast("Copied to clipboard.", { type: "success" });
    } catch (err) {
      toast("Something went wrong.", { type: "error" });
    }
  };

  const handleURL = (url: string) => {
    const baseUrl = new URL(url); // Assuming REACT_APP_BASE_URL is set
    window.open(baseUrl.href, "_blank", "noopener,noreferrer"); // Open in a new tab
  };

  const handlesetting = (event: MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLElement).className === "ovrlayModal") {
      setSettingModal(false);
    }
  };

  // custome profiel link share

  const shareUrl = profileLink; // URL to share
  const shareTitle = "Ndisync.ai"; // Title to share
  const shareText = "profile"; // Text to share
  const shareOnFacebook = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      shareUrl
    )}&t=${encodeURIComponent(shareTitle)}`;
    window.open(facebookUrl, "_blank", "noopener,noreferrer");
  };

  const shareOnWhatsApp = () => {
    const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(
      shareTitle
    )}%20${encodeURIComponent(shareUrl)}`;
    window.open(whatsappUrl, "_blank", "noopener,noreferrer");
  };

  const shareOnLinkedIn = () => {
    const linkedinUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
      shareUrl
    )}`;
    window.open(linkedinUrl, "_blank", "noopener,noreferrer");
  };

  const shareOnTwitter = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      shareUrl
    )}&text=${encodeURIComponent(shareText)}`;
    window.open(twitterUrl, "_blank", "noopener,noreferrer");
  };

  useEffect(() => {
    setProfileLink(
      `${process.env.REACT_APP_DASHBOARD}/public-profile/${userId}/view`
    );
  }, [userId]);



  // 
  const [isVisible, setIsVisible] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 600);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 600);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleContent = () => {
    setIsVisible(!isVisible);
  };


  return (
    <div className="mb-2 lg:mb-0 md:mb-0 w-full lg:w-1/4 md:w-1/4 ">
      {isMobile && (
        <button className="toggle-button bg-white  py-3 px-6 w-full font-size-20px font-Poppins-SemiBold theme-color-green rounded-none lg:rounded-xl flex justify-center gap-2 items-center" onClick={toggleContent} >
          {isVisible ? 'Hide Details' : 'Show Details'}
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      )}

      <div className={` pl-0 lg:pl-2 md:pl-2 show-location-detail-mob ${isMobile && !isVisible ? 'hidden' : ''}`}>
        <div className="shadow-lg p-4 rounded-0 lg:rounded-lg md:rounded-lg   bg-white user-since-bar">

          {ShowData?.phonenumber && (
            <button className="border-black border w-full profile-right-side-btn user-detail-address  font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              {/* <FontAwesomeIcon className="w-10" icon={faPhone} /> */}
              <img src={TelephoneIcon} alt="TelephoneIcon" />

              {ShowData?.phonenumber}
            </button>
          )}
          {ShowData?.email && (
            <button className="border-black border w-full profile-right-side-btn user-detail-address  font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              {/* <FontAwesomeIcon className="w-10" icon={faEnvelope} /> */}
              <img src={EmailIcon} alt="EmailIcon" />

              {ShowData?.email}
            </button>
          )}
          {ShowData?.address && (
            <button className="border-black border w-full profile-right-side-btn user-detail-address  font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              {/* <FontAwesomeIcon className="w-10" icon={faLocationDot} /> */}
              <img src={LocationIcon} alt="telephone" />

              {/* <span className=""> */}
              {ShowData?.address}
              {/* </span> */}
            </button>
          )}
          {ShowData?.suburb && (
            <button className="border-black border w-full profile-right-side-btn user-detail-address font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              {/* <FontAwesomeIcon className="w-10" icon={faCity} /> */}
              <img src={SuburbIcon} alt="SuburbIcon" />

              {ShowData?.suburb}
            </button>
          )}
          {ShowData?.state && (
            <button className="border-black border w-full profile-right-side-btn user-detail-address font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              {/* <FontAwesomeIcon className="w-10" icon={faGlobe} /> */}
              <img src={StateIcon} alt="StateIcon" />

              {ShowData?.state}
            </button>
          )}
          {ShowData?.postCode && (
            <button className="border-black border w-full profile-right-side-btn user-detail-address font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              {/* <FontAwesomeIcon className="w-10" icon={faEnvelopesBulk} /> */}
              <img src={PostcodeICon} alt="postcode-icon" />
              {ShowData?.postCode}
            </button>
          )}
          {/* {ShowData?.gender && (
            <button className="border-black border w-full capitalize-first-letter profile-right-side-btn user-detail-address font-size-16px rounded-lg font-font-Montserrat-Medium mb-2 bg-white">
              <FontAwesomeIcon className="w-10" icon={faUser} />
              {ShowData?.gender}
            </button>
          )} */}
          {unpaidModalShow ?

            (
              <>
                <div className="cover-img relative">
                  <Skeleton variant="rounded" className="w-[10vw] mb-2 mt-2" style={{ borderRadius: 13 }} height={15} />
                  <Skeleton variant="rounded" className="w-[10vw] mb-2" style={{ borderRadius: 13 }} height={15} />
                  <Skeleton variant="rounded" className="w-[10vw] mb-2" style={{ borderRadius: 13 }} height={15} />
                </div>

              </>

            )
            :
            (
              <>
                <button className="capitalize-first-letter border-black border w-full profile-right-side-btn user-detail-address font-size-16px rounded-lg font-Montserrat-Medium mb-2 bg-white ">
                  <div className="flex justify-center">
                    <FontAwesomeIcon className="w-10" icon={faClockRotateLeft} />
                  </div>
                  {/* <span className="capitalize-first-letter "> */}
                  {`User since ${formatDate(ShowData?.createdAt ?? '')}`}

                  {/* </span> */}
                </button>
              </>
            )}
        </div>

        {!ShowData?.socialLinks?.linkedin &&
          !ShowData?.socialLinks?.facebook &&
          !ShowData?.socialLinks?.instagram &&
          !ShowData?.socialLinks?.whatsApp ? null : (
          <div className="flex flex-col gap-1 mt-3">
            <div
              className="flex shadow-lg rounded-lg  py-2 bg-white"
              style={{ borderRadius: "7px", justifyContent: "space-evenly" }}
            >
              {ShowData?.socialLinks?.linkedin && (
                <button
                  onClick={() => handleURL(ShowData?.socialLinks?.linkedin)}
                  className="theme-color-green w-10"
                >
                  <FontAwesomeIcon size="xl" icon={faLinkedin} />
                </button>
              )}
              {ShowData?.socialLinks?.facebook && (
                <button
                  onClick={() => handleURL(ShowData?.socialLinks?.facebook)}
                  className="theme-color-green w-10"
                >
                  <FontAwesomeIcon size="xl" icon={faFacebookSquare} />
                </button>
              )}
              {ShowData?.socialLinks?.instagram && (
                <button
                  onClick={() => handleURL(ShowData?.socialLinks?.instagram)}
                  className="theme-color-green w-10"
                >
                  <FontAwesomeIcon size="xl" icon={faInstagram} />
                </button>
              )}
              {ShowData?.socialLinks?.whatsApp && (
                <button
                  onClick={() => handleURL(ShowData?.socialLinks?.whatsApp)}
                  className="theme-color-green w-10"
                >
                  <FontAwesomeIcon size="xl" icon={faWhatsapp} />
                </button>
              )}
            </div>
          </div>
        )}
        {!unpaidModalShow && (


          <div className="flex justify-end gap-5 mt-3">
            {isAdmin !== "superAdmin" && isAdmin !== "admin" &&
              <BlockUser uid={ShowData?._id} />

            }
            <button
              onClick={() => setSettingModal(true)}
              className="flex items-center justify-center gap-1 mt-3"
            >
              <FontAwesomeIcon style={{ color: "#00443F" }} icon={faShareNodes} />
              <span
                style={{ color: "#00443F" }}
                className="font-size-15px font-Poppins-Regular"
              >
                Share
              </span>
            </button>
          </div>
        )}
        {/* {settingmodal && (
          <div className="ovrlayModal modal" onClick={handlesetting}>
            <div className="createpost-modal  absolute z-20">
              <button
                className="absolute top-0 font-size-24px "
                style={{ right: "0px" }}
                onClick={() => setSettingModal(false)}
              >
                <img src={closeIcon} alt="" width={"20px"} />
              </button>

              <div>
                <p className="font-size-35px font-Poppins-SemiBold theme-color-green text-center">
                  Share
                </p>
              </div>

              <div className="flex justify-center gap-5 social-icons-copy">
                <img
                  src={facebookshare}
                  alt="Share on Facebook"
                  onClick={shareOnFacebook}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={whatsappshare}
                  alt="Share on WhatsApp"
                  onClick={shareOnWhatsApp}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={linkedinshare}
                  alt="Share on LinkedIn"
                  onClick={shareOnLinkedIn}
                  style={{ cursor: "pointer" }}
                />
                <img
                  src={twittershare}
                  alt="Share on Twitter"
                  onClick={shareOnTwitter}
                  style={{ cursor: "pointer" }}
                />
              </div>
              <p className="font-size-20px  theme-color-green font-Poppins-Regular  text-center my-3">
                Or copy link
              </p>
              <div className="copy-socials-links pb-4">
                <input type="text" readOnly value={profileLink} />
                <button
                  onClick={handleCopy}
                  className="theme-bg-green font-Poppins-Medium  font-size-16px "
                >
                  Copy
                </button>
              </div>
            </div>
          </div>
        )} */}
      </div>
      {settingmodal && (
        <div className="ovrlayModal modal" onClick={handlesetting}>
          <div className="createpost-modal  absolute z-20">
            <button
              className="absolute top-0 font-size-24px "
              style={{ right: "0px" }}
              onClick={() => setSettingModal(false)}
            >
              <img src={closeIcon} alt="" width={"20px"} />
            </button>

            <div>
              <p className="font-size-35px font-Poppins-SemiBold theme-color-green text-center">
                Share
              </p>
            </div>

            <div className="flex justify-center gap-5 social-icons-copy">
              <img
                src={facebookshare}
                alt="Share on Facebook"
                onClick={shareOnFacebook}
                style={{ cursor: "pointer" }}
              />
              <img
                src={whatsappshare}
                alt="Share on WhatsApp"
                onClick={shareOnWhatsApp}
                style={{ cursor: "pointer" }}
              />
              <img
                src={linkedinshare}
                alt="Share on LinkedIn"
                onClick={shareOnLinkedIn}
                style={{ cursor: "pointer" }}
              />
              <img
                src={twittershare}
                alt="Share on Twitter"
                onClick={shareOnTwitter}
                style={{ cursor: "pointer" }}
              />
            </div>
            <p className="font-size-20px  theme-color-green font-Poppins-Regular  text-center my-3">
              Or copy link
            </p>
            <div className="copy-socials-links pb-4">
              <input type="text" readOnly value={profileLink} />
              <button
                onClick={handleCopy}
                className="theme-bg-green font-Poppins-Medium  font-size-16px "
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AddressSocialDetail;
