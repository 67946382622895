/**
 * Component: CreateJobsModal
 * Purpose: Modal component for creating or editing job postings.
 * Author: Developer
 * Date: 24-04-24
 */

import React, { useState, useEffect, useRef } from "react";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import Select from 'react-select';
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faX,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import {
  validateText,
  validateTextAndSpace,
  validateNumber
} from "../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import RewriteWithAi from "../AskAi/RewriteWithAi";
import ReWriteLoader from "../AskAi/ReWriteLoader";
import httpRequest from "../../config/Helpers/httpRequest";
import UnpaidModal from "../Modal/UnpaidModal";
import moment from 'moment';
import { RootState } from "../../config/Store/store";
interface Service {
  _id: string;
  servicename?: string;
}
interface IApiResponse {
  message?: string;
  documents?: [] | object | string;

  response: OptionType;

}
type OptionType = {
  value: string;
  label: string;
  suburb: string;
  state: string;
  postcode: string; // Updated to match the user state
};
interface PostJob {
  _id?: any;
  services: Service[];
  contactNumber: string;
  streetAddress: string;
  suburb: string;
  state: string;
  postCode: string;
  repeatCycle: number;
  startDate: string;
  startTime: string;
  endDate: string;
  endTime: string;
  budget: string;
  description: string;
  // customInterval: number;
}
interface ApiResponse {
  message: string;
}
interface CreateJobsModalProps {
  hideModal: (show: boolean) => void;
  isEdit: boolean;
  postJob: PostJob;
  setPostJob: React.Dispatch<React.SetStateAction<PostJob>>;
  setSuccessModal: (show: boolean) => void;
}

const CreateJobsModal: React.FC<CreateJobsModalProps> = ({
  hideModal = () => { },
  isEdit = false,
  postJob,
  setPostJob,
  setSuccessModal,
}) => {
  // OUTSIDE CLICK TO CLOSE
  const outsideRef = useRef<HTMLFormElement>(null);
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (outsideRef.current && !outsideRef.current.contains(event.target as Node)) {
        setIsExpanded(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideRef]);

  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [showSocial, setShowSocial] = useState<boolean>(false);
  const [suburbOptions, setSuburbOptions] = useState<OptionType[]>([]);
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);
  const [stateOptions, setStateOptions] = useState<OptionType[]>([]);

  const [unpaidModalShow, setUnpaidModalShow] = useState<boolean>(false);

  const [error, setError] = useState<any>("");
  const toggleIcon = () => {
    setIsExpanded(!isExpanded);
    // setIsExpanded((prevExpanded) => !prevExpanded);

    setShowSocial(false); // Hide social div when clicking admin job req inner
  };

  const toggleSocial = () => {
    setShowSocial(!showSocial);
  };

  const startDataReference = useRef(null);
  const endDataReference = useRef(null);
  const selectStyle2 = {
    padding: "13px 5px",
    borderRadius: "5px",
    border: "1px solid #00A297",
    width: "100%",
    backgroundColor: "#ffff",
    outline: "none",
    cursor: "pointer",
    margin: "0",
  };

  const { ALLSERVICES } = useSelector((store: RootState) => store.allServices);
  const [createLoader, setCreateLoader] = useState<boolean>(false);
  const [aiLoader, setAiLoader] = useState<boolean>(false);

  const handleServiceChange = (item: Service) => {
    console.log(postJob.services, "SERVICEHCANGE")
    const exists = postJob.services.some((v) => v._id === item?._id);
    if (exists) {
      // Remove the item if it exists
      const updatedServices = postJob.services.filter(
        (v) => v._id !== item?._id
      );
      setPostJob({ ...postJob, services: updatedServices });
    } else {
      // Add the item if it doesn't exist
      setPostJob({ ...postJob, services: [...postJob.services, item] }); // for multiple services select

      setPostJob({ ...postJob, services: [item] }); // for single service select
    }
  };
  const handleChangeNumber = (text: string) => {
    if (text === "") {
      setPostJob({ ...postJob, contactNumber: "" });
    } else if (validateNumber(text) && text.length < 11) {
      setPostJob({ ...postJob, contactNumber: text });
    }
  };

  const handleCity = (text: string) => {
    if (text === "") {
      setPostJob({ ...postJob, suburb: "" });
    } else if (validateText(text)) {
      setPostJob({ ...postJob, suburb: text });
    } else {
    }
  };

  // const handleState = (text: string) => {
  //   if (text === "") {
  //     setPostJob({ ...postJob, state: "" });
  //   } else if (validateTextAndSpace(text)) {
  //     setPostJob({ ...postJob, state: text });
  //   } else {
  //   }
  // };

  const handleState = (text: string) => {
    if (text.length <= 14) {  // Limit to 14 characters
      if (text === "") {
        setPostJob({ ...postJob, state: "" });
      } else if (validateTextAndSpace(text)) {
        setPostJob({ ...postJob, state: text });
      }
    }
  };
  const handlePostCode = (text: string) => {
    if (text === "") {
      setPostJob({ ...postJob, postCode: "" });
    } else if (text.length > 4 || !validateNumber(text)) {
      return;
    } else {
      setPostJob({ ...postJob, postCode: text });
    }
  };
  // debugger
  const hideModalAndReset = () => {
    hideModal(false);
    setPostJob({
      services: [],
      contactNumber: "",
      streetAddress: "",
      suburb: "",
      state: "",
      postCode: "",
      repeatCycle: 1,
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      budget: "",
      description: "",
      // customInterval: 0,
    });
  };


  // Log individual components

  // Functionality: API call for Create Job


  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    console.log("AAA", postJob.startDate, postJob.endDate)
    if (isEdit) {

    }

    let {
      services,
      contactNumber,
      streetAddress,
      suburb,
      state,
      postCode,
      startDate,
      startTime,
      endDate,
      endTime,
      budget,
      description,
    } = postJob;
    if (services.length < 1) {
      toast("Please Select a service", { type: "error" });
    } else if (!contactNumber) {
      toast("please Enter contact number", { type: "error" });
    } else if (contactNumber.length < 10) {
      toast("Please enter a valid contact number.", { type: "error" });
    } else if (!streetAddress) {
      toast("please Enter address", { type: "error" });
    } else if (!suburb) {
      toast("please Enter suburb", { type: "error" });
    } else if (!state) {
      toast("please Enter state", { type: "error" });
    } else if (!postCode) {
      toast("please Enter post code", { type: "error" });
    } else if (postCode.length < 4) {
      toast("Postal code should not be less than 4 digits.", { type: "error" });

    }
    else if (!startTime) {
      toast("Please select start time ", { type: "error" });
    } else if (!endTime) {
      toast("Please select end time", { type: "error" });
    }
    else if (!startDate) {
      toast("Please select start date", { type: "error" });
    }
    else if (!endDate) {
      toast("Please select end date", { type: "error" });
    } else if (!budget) {
      toast("please Enter budget", { type: "error" });
    } else if (!description) {
      toast("please Enter description", { type: "error" });
    } else {
      // const startDateTime = moment(`${startDate} ${startTime}`);

      // const endDateTime = moment(`${endDate} ${endTime}`);
      // const startDateTime = moment(`${startDate} ${startTime}`);
      // const endDateTime = moment(`${endDate} ${endTime}`);
      console.log("Raw Start Date:", startDate);
      console.log("Raw Start Time:", startTime);
      console.log("Raw End Date:", endDate);
      console.log("Raw End Time:", endTime);
      // if(isEdit)
      const startDateTime = moment(`${startDate} ${startTime}`, "MM-DD-YYYY HH:mm");
      const endDateTime = moment(`${endDate} ${endTime}`, "MM-DD-YYYY HH:mm");

      const currentDateTime = moment();

      // if (!startDateTime.isBefore(endDateTime)) {
      //   toast("Start date and time must be before end date and time.", { type: "error" });
      //   return;
      // }
      console.log("Start DateTime:", startDateTime.format("YYYY-MM-DD HH:mm"));
      console.log("End DateTime:", endDateTime.format("YYYY-MM-DD HH:mm"));

      console.log("Is Start Before End:", startDateTime);
      if (!isEdit && !startDateTime.isBefore(endDateTime)) {
        toast("Start date and time must be before end date and time.", { type: "error" });
        return;
      }

      const startTimeMoment = moment(startTime, 'HH:mm');
      const endTimeMoment = moment(endTime, 'HH:mm');

      const formattedStartDate = moment(startDate).format('YYYY-MM-DDT00:00:00.000[Z]');
      const formattedEndDate = moment(endDate).format('YYYY-MM-DDT00:00:00.000[Z]');

      if (startTimeMoment.isSame(endTimeMoment)) {
        toast("Start time and end time must be different.", { type: "error" });
        return;
      }

      // if (startDateTime.isBefore(currentDateTime)) {
      //   toast("Start date and time must be in the future.", { type: "error" });
      //   return;
      // }
      setCreateLoader(true);

      const payLoad = {
        serviceId: services[0]?._id, // Assuming you're selecting only one service
        contactNumber,
        streetAddress,
        suburb,
        state,
        postCode,
        repeatCycle: Number(postJob.repeatCycle),
        // startDate: startDateTime.toISOString(),
        startDate: formattedStartDate,
        // startDate: startDateTime.toISOString(),
        // endDate: endDateTime.toISOString(),
        startTime,
        // endDate: endDateTime.toISOString(),
        endDate: formattedEndDate,
        endTime,
        budget: Number(budget),
        description,
      }
      const { res, err } = await httpRequest<ApiResponse>({ method: `${isEdit ? "put" : "post"}`, path: `/api${isEdit ? `/admin/job/edit/${postJob?._id}` : "/job/create"}`, params: payLoad });
      if (res) {
        hideModal(false);
        setSuccessModal(true);
        setPostJob({
          _id: "",
          services: [],
          contactNumber: "",
          streetAddress: "",
          suburb: "",
          state: "",
          postCode: "",
          repeatCycle: 1,
          startDate: "",
          startTime: "",
          endDate: "",
          endTime: "",
          budget: "",
          description: "",
          // customInterval: 0,
        });
        if (isEdit) {
          toast(res?.message || "Success", { type: "success" });
        }
      } else {
        if (err?.status === 402) {
          setUnpaidModalShow(true)
          setError(err?.message)

        }
        toast(err?.message?.message || err?.message, { type: "error" })
      }
      setCreateLoader(false);
    }
  };

  const removeServiceFromList = (id: string) => {
    const newArr = postJob.services.filter((v) => v._id !== id);
    setPostJob({ ...postJob, services: newArr });
  };

  console.log("postJob", postJob)

  useEffect(() => {
    if (postJob) {
      if (startDataReference.current) {
        flatpickr(startDataReference.current, {
          dateFormat: "m-d-Y",
          defaultDate: isEdit ? new Date(postJob?.startDate) : new Date(),
          // Additional options can be added as needed
          onChange: function (selectedDates, dateStr, instance) {
            setPostJob((prevState) => ({
              ...prevState,
              startDate: dateStr,
            }));
          },
        });
      }

      if (endDataReference.current) {
        flatpickr(endDataReference.current, {
          dateFormat: "m-d-Y",
          defaultDate: isEdit ? new Date(postJob?.endDate) : new Date(),
          // Additional options can be added as needed
          onChange: function (selectedDates, dateStr, instance) {
            setPostJob((prevState) => ({
              ...prevState,
              endDate: dateStr,
            }));
          },
        });
      }
    }
  }, []);

  const fetchLocations = async (query: string) => {
    const queryString = `search=${postJob.postCode || ""}&suburb=${query || ""}&state=${postJob.state || ""}`;
    console.log("Fetching locations with query:", queryString);

    try {
      const response: any = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/postcodes/all?${queryString}`);
      if (response.data && Array.isArray(response.data.documents)) {
        setSuburbOptions(response.data.documents);
      } else {
        setSuburbOptions([]);
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  // Call fetchLocations when postJob.suburb changes
  useEffect(() => {
    if (postJob.suburb) {
      fetchLocations(postJob.suburb);
    }
  }, [postJob.suburb]);

  return (
    <div className="modal">
      <div className="edit-modal-content relative">
        <button onClick={hideModalAndReset} className="absolute job-pre-edit">
          <FontAwesomeIcon
            className="x-icon-admin-opr"
            icon={faX}
            style={{ color: "#ffffff" }}
          />

          {/* <FontAwesomeIcon icon={faTimesCircle} /> */}
        </button>
        <form onSubmit={handleSubmit}
          ref={outsideRef}
        >
          <div className="">
            <label
              className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
              htmlFor=""
            >
              Services required
            </label>
            <div
              className="create-job-services create-job-services-2 cursor-pointer"
              // ref={outsideRef}
              onClick={toggleIcon}
            >
              {postJob.services.length < 1 ? (
                <p className="font-size-15px font-Poppins-Medium opacity-40">
                  Select
                </p>
              ) : (



                postJob.services.map((item, index) => (
                  <p
                    key={index}
                    className="font-size-15px font-Poppins-Medium opacity-40"
                  >
                    {item?.servicename}
                    <FontAwesomeIcon
                      role="button"
                      onClick={() => removeServiceFromList(item?._id)}
                      className="service-remove"
                      icon={faX}
                    />
                  </p>
                )

                )
              )}
              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
              />
            </div>
            {isExpanded && (
              <div
                className="create-job-service-dropdown text-[#646464]"
                onClick={toggleSocial}
              >
                <div className="socail-comunity-access-jobs">
                  <div className="socail-comunity-access-content service-box-1">
                    <button
                      onClick={toggleIcon}
                      className="absolute job-pre-edit "
                      style={{ right: "10px", top: "10px" }}
                    >
                      <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                      />

                      {/* <FontAwesomeIcon icon={faTimesCircle} /> */}
                    </button>
                    {ALLSERVICES && ALLSERVICES.length > 0 ? (
                      ALLSERVICES.map((item, index) => (
                        <div key={index}>
                          <h2 className="font-size-15px font-Poppins-SemiBold mt-2 mb-1">
                            {item?._id || ""}
                          </h2>
                          {item?.documents?.map((v, i) => (
                            <div key={i} className="admin-Social-Support">
                              <input
                                type="radio"
                                name="services"
                                checked={postJob.services.some(
                                  (_v) => _v._id === v?._id
                                )}
                                onClick={() => handleServiceChange(v)}
                              />
                              <label
                                className="font-size-15px font-Poppins-Regular"
                                htmlFor=""
                              >
                                {v?.servicename || ""}
                              </label>
                            </div>
                          ))}
                          {/* <div className="admin-social-border"></div> */}
                        </div>
                      ))
                    ) : (
                      <p>No services</p>
                    )}
                  </div>
                </div>
              </div>
            )}
            <label
              className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
              htmlFor=""
            >
              Contact number
            </label>
            <input
              className="font-size-15px theme-color-para font-Poppins-Regular edit-looking-cop"
              type="text"
              placeholder="Contact number"
              value={postJob.contactNumber}
              onChange={(e) => handleChangeNumber(e.target.value)}
            />
            <label
              className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
              htmlFor=""
            >
              Street address
            </label>
            <input
              className="font-size-15px theme-color-para font-Poppins-Regular edit-looking-cop"
              placeholder="Address"
              type="text"
              value={postJob.streetAddress}
              onChange={(e) =>
                setPostJob({ ...postJob, streetAddress: e.target.value })
              }
            />
            <div>
              <div className="job-suburb">
                <label className="form-control font-size-15px font-Poppins-Medium">Suburb</label>
                <Select
                  className="global-select global-select-2 mt-1"
                  value={
                    suburbOptions
                      .filter(option => option.suburb === postJob.suburb)
                      .map(option => ({
                        value: option.suburb,
                        label: option.suburb,
                        suburb: option.suburb,
                        state: option.state,
                        postcode: option.postcode,
                      }))[0] || null
                  }
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      const selectedSuburb = suburbOptions.find(option => option.suburb === selectedOption.value);
                      if (selectedSuburb) {
                        setPostJob({
                          ...postJob,
                          suburb: selectedSuburb.suburb,
                          state: selectedSuburb.state,
                          postCode: selectedSuburb.postcode,
                        });
                      }
                    } else {
                      // Clear the fields if the user clears the selection
                      setPostJob({
                        ...postJob,
                        suburb: '',
                        state: '',
                        postCode: '',
                      });
                    }
                  }}
                  onInputChange={(inputValue) => {
                    // Fetch locations when user types in input
                    if (inputValue) {
                      fetchLocations(inputValue);
                    }
                  }}
                  isClearable={true} // Allow the user to clear the selection
                  options={suburbOptions.map((item) => ({
                    value: item.suburb,
                    label: `${item.suburb}, ${item.state}, ${item.postcode}`,
                    suburb: item.suburb,
                    state: item.state,
                    postcode: item.postcode,
                  }))}
                  placeholder="Suburb"
                  styles={{
                    container: (provided) => ({
                      ...provided,
                      width: "100%",
                    }),
                    control: (provided) => ({
                      ...provided,
                      width: "100%",
                      borderRadius: "6px",
                      borderColor: "#00a297",
                      boxShadow: "none",
                      "&:hover": {
                        borderColor: "#00a297",
                      },
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: "#004440",
                      fontWeight: "400",
                    }),
                    placeholder: (provided) => ({
                      ...provided,
                      color: "rgba(0, 69, 64, 0.44)",
                      fontWeight: "500",
                    }),
                    input: (provided) => ({
                      ...provided,
                      color: "rgba(0, 69, 64, 0.44)",
                      fontWeight: "500",
                    }),
                    menu: (provided) => ({
                      ...provided,
                      width: "100%",
                      borderRadius: "6px",
                    }),
                    menuList: (provided) => ({
                      ...provided,
                      fontSize: "12px",
                      borderRadius: "6px",
                    }),
                  }}
                />





              </div>
            </div>
            <div className="flex flex-row gap-4">

              {/* <div>
                <label
                  className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
                  htmlFor=""
                >
                  Suburb
                </label>

                <input
                  className="font-size-15px theme-color-para font-Poppins-Regular edit-looking-cop"
                  type="text"
                  value={postJob.suburb}
                  onChange={(e) => handleCity(e.target.value)}
                />
              </div> */}

              <div className="w-full">
                <label
                  className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
                  htmlFor=""
                >
                  State
                </label>
                <input
                  className="font-size-15px theme-color-para font-Poppins-Regular edit-looking-cop"
                  type="text"
                  value={postJob.state}
                  readOnly
                // onChange={(e) => handleState(e.target.value)}
                />
              </div>
              <div className="w-full">
                <label
                  className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
                  htmlFor=""
                >
                  Post code
                </label>
                <input
                  className="font-size-15px theme-color-para font-Poppins-Regular edit-looking-cop"
                  type="text"
                  value={postJob.postCode}
                  readOnly
                // onChange={(e) => handlePostCode(e.target.value)}
                />
              </div>
            </div>

            <label
              className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
              htmlFor=""
            >
              Dates
            </label>
            <div className="dates-edit-modal edit-looking-for-2">
              <div className="dates-edit-modal-recur">
                <div>
                  <div className="recur-oneoff-day">
                    <select
                      className="font-Poppins-SemiBold font-size-15px text-black"
                      style={selectStyle2}
                      value={postJob.repeatCycle}
                      // onChange={(e) =>
                      //   setPostJob({ ...postJob, repeatCycle: e.target.value })
                      // }
                      onChange={(e) =>
                        setPostJob({ ...postJob, repeatCycle: parseInt(e.target.value, 10) })
                      }
                    >
                      <option
                        value="1"
                        className="font-Poppins-Regular font-size-15px"
                      >
                        One - off
                      </option>
                      <option
                        value="2"
                        className="font-Poppins-Regular font-size-15px"
                      >
                        Daily
                      </option>
                      <option
                        value="3"
                        className="font-Poppins-Regular font-size-15px"
                      >
                        Weekly
                      </option>
                      <option
                        value="4"
                        className="font-Poppins-Regular font-size-15px"
                      >
                        Monthly
                      </option>
                      {/* <option value="5" className="font-Poppins-Regular font-size-15px">
                                                Custom
                                            </option> */}
                    </select>
                  </div>
                </div>
              </div>

              <div className="dates-edit-modal-Start flex flex-col ">
                <div className="grid-setting">
                  <div className="recur-oneoff-day recur-oneoff-day-2 start-with">
                    <input
                      className="font-Poppins-SemiBold font-size-15px text-black edit-looking-cop"
                      type="time"
                      placeholder="asdasd"
                      value={postJob.startTime}
                      onChange={(e) =>
                        setPostJob({ ...postJob, startTime: e.target.value })
                      }
                    />
                  </div>
                  <div className="recur-oneoff-day-2 ends-with">
                    <input
                      className="font-Poppins-SemiBold font-size-15px text-black end-time edit-looking-cop"
                      type="time"
                      value={postJob.endTime}
                      onChange={(e) =>
                        setPostJob({ ...postJob, endTime: e.target.value })
                      }
                    />
                  </div>
                </div>
                <div className="grid-setting">
                  <div className="recur-oneoff-day recur-oneoff-day-2 start-with">
                    <input
                      className="font-Poppins-SemiBold font-size-15px text-black edit-looking-cop"
                      type="date"
                      ref={startDataReference}
                    />
                  </div>
                  <div className="recur-oneoff-day-2 ends-with">
                    <input
                      className="font-Poppins-SemiBold font-size-15px text-black end-time edit-looking-cop"
                      type="date"
                      ref={endDataReference}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="edit-modals-Rates">
              <label
                className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
                htmlFor=""
              >
                Budget
              </label>
              <input
                className="font-size-15px theme-color-para font-Poppins-Regular edit-looking-cop"
                placeholder="$"
                type="number"
                value={postJob.budget}
                // onChange={(e) =>
                //   setPostJob({ ...postJob, budget: e.target.value })
                // }

                onChange={(e) => {
                  // Get the current value from the input
                  let value = e.target.value;

                  // Remove any negative signs from the value
                  value = value.replace(/-/g, '');

                  // Check if the value is a valid non-negative number
                  if (value === "" || /^\d*\.?\d*$/.test(value)) {
                    setPostJob({ ...postJob, budget: value });
                  }
                }}
              />
              <label
                className="font-size-15px text-[#2F2F31] font-Poppins-Medium"
                htmlFor=""
              >
                Description
              </label>
              <div style={{ position: "relative" }}>
                {aiLoader ? (
                  <>
                    <ReWriteLoader
                      extraStyle={{
                        border: "1px solid #00a297",
                        borderRadius: 10,
                        padding: 5,
                        height: 110,
                        margin: "5px 0 10px 0",
                        backgroundColor: "#fff",
                      }}
                    />
                  </>
                ) : (
                  <textarea
                    name=""
                    id=""
                    className="resize-none font-size-15px theme-color-para font-Poppins-Regular"
                    value={postJob.description}
                    onChange={(e) =>
                      setPostJob({ ...postJob, description: e.target.value })
                    }
                  ></textarea>
                )}
                <RewriteWithAi
                  text={postJob.description}
                  setText={(e) => setPostJob({ ...postJob, description: e })}
                  setLoader={setAiLoader}
                  extrastyle={{
                    position: "absolute",
                    right: 10,
                    bottom: 20,
                    zIndex: 9999,
                  }}
                />
              </div>
            </div>
            <button
              type="submit"
              className="font-size-20px edit-modl-updatebtn font-Poppins-SemiBold text-white flex flex-row items-center gap-2"
              disabled={createLoader}
            >
              {isEdit ? "Update" : "Post"}
              {createLoader ? (
                <CircularProgress
                  style={{
                    width: 16,
                    height: 16,
                    color: "#fff",
                    marginLeft: 8,
                  }}
                />
              ) : (
                ""
              )}
            </button>
          </div>
        </form>
      </div>
      {unpaidModalShow && <UnpaidModal profile={false} error={error} onClose={() => setUnpaidModalShow(false)} />}
    </div>
  );
};

export default CreateJobsModal;