/**
 * Connections Component
 * Displays connections with filtering by name and user type (participant & provider),
 * pagination support, and navigation to user profiles on name click.
 * File: Connections.jsx
 * Author: Developer
 * Date: 03-05-24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import { toast } from "react-toastify";
import httpRequest from "../../config/Helpers/httpRequest";
import DiscussionSidebar from "../Discussions/DiscussionSidebar";
import ConnectOrMessageButton from "../ConnectionsComponents/ConnectionStatusButton";
import { timeSince, getRole } from "../../config/Helpers/helpers"
import Key from "../../assets/img/key.png"
import { Link } from "react-router-dom";
import UnpaidModal from "../Modal/UnpaidModal";
import qs from "qs";

function ProfileViewer() {
    const [unPaidUserModal, setUnPaidUserModal] = useState(false)
    const [profileViewer, setProfileViewer] = useState([])
    const [totalViews, setTotalViews] = useState(0)
    const [totalPages, setTotalPages] = useState(false);
    const [queryParams, setQueryParams] = useState({
        page: 1,
        limit: 10,
        // search: "",
    }); // query params for APIs
    console.log(profileViewer, "profileViewerprofileViewer")
    const changeType = (e) => {
        // for change discussion type
        setQueryParams((prev) => ({
            ...prev,
            page: 1,
        }));
        setProfileViewer(e);
    };
    const decodedToken = getRole()
    const isSubscribed = decodedToken?.isMember;
    useEffect(() => {
        const fetchProfileViewers = async () => {
            const { res, err } = await httpRequest({
                method: "get",
                path: `/api/search/profile-insights?${qs.stringify(queryParams)}`,
            });

            console.log(res, "insight")
            if (res) {
                setProfileViewer(res?.documents)
                setTotalViews(res?.paginated?.totalItems)
                setTotalPages(res?.paginated?.totalPages || 1);
            } else {
                // toast(err?.message, { type: "error" });
                toast(err?.message, { type: "error" });

            }
        }
        fetchProfileViewers()
    }, [queryParams])


    return (
        <div className="">
            <HeaderGlobal />
            <div className="container-1480 block justify-between h-3vw lg:flex md:flex gap-3">
                <div className=" w-full" >
                    <div className=" ">
                        <div className="shadow-lg bg-white rounded-xl mb-3 pb-3 py-3 ">
                            <div className=" px-6 flex justify-between items-center">
                                <h2 className="font-size-20px font-Poppins-SemiBold theme-color-green">Who's viewed your profile</h2>
                                <Link to="/feed">
                                    <img className=" cursor-pointer notifi-back " src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png" alt="" />
                                </Link>
                            </div>
                            <hr className="my-3" />
                            {!isSubscribed ? (
                                <div className=" px-6">
                                    <Link to="/subscription" className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  theme-bg-green text-nowrap" style={{ color: " rgb(255, 255, 255)" }}>Past 90 days</Link>
                                </div>
                            )

                                :
                                (
                                    <div className=" px-6">
                                        <span to="" className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  theme-bg-green text-nowrap" style={{ color: " rgb(255, 255, 255)" }}>Past 90 days</span>
                                    </div>
                                )
                            }

                        </div>
                        <div className="shadow-lg bg-white rounded-xl mb-3 pb-3 py-3 px-6 ">
                            <div>
                                <h2 className="font-size-20px font-Poppins-SemiBold text-[#363636]">{totalViews}</h2>
                            </div>
                            <div>
                                <p className="font-size-20px font-Poppins-Regular text-[#363636]">Profile viewers in the past 90 days</p>
                            </div>
                        </div>
                        {isSubscribed === false && (
                            <div className="shadow-lg bg-white rounded-xl mb-3 pb-3 py-3 px-6 flex flex-row gap-3">

                                <div>
                                    <img className="w-[3vw]" src={Key} alt="" />
                                </div>
                                <div>
                                    <h3 className="font-size-20px font-Poppins-SemiBold text-[#363636]">Unlock all your profile viewers with premium</h3>
                                    {/* <div className="flex flex-row items-center gap-2">
                                        <div className="flex flex-row pl-1">
                                            {profileViewer && profileViewer.length > 0 ? (
                                                (profileViewer.slice(0, 3)).map((profileViewer, index) => (

                                                    <img className="unlock_profile_top" key={index} src={`${process.env.REACT_APP_BASE_URL_IMAGE}${profileViewer?.profileImageUrl}`} alt="" />

                                                ))
                                            ) : null}

                                        </div>
                                        <p className="font-size-20px font-Poppins-Regular text-[#363636]">Mark and millions of other members use premium</p>
                                    </div> */}
                                    <Link to="/subscription" className="w-fit font-size-18px theme-color-green font-Poppins-Medium feeds-btn mt-3 theme-bg-green text-nowrap" style={{ color: " rgb(255, 255, 255)" }}>Try premium for $50</Link>
                                </div>
                            </div>
                        )}
                        <div className="shadow-lg bg-white rounded-xl mb-3 pb-3 py-3 px-6 ">
                            {isSubscribed ? (
                                <h2 className="font-size-20px font-Poppins-SemiBold text-[#363636]">Viewers </h2>

                            )
                                :
                                (
                                    <div>
                                        <h2 className="font-size-20px font-Poppins-SemiBold text-[#363636]">Viewers you can browser for free</h2>
                                        <span className="font-size-20px font-Poppins-Regular text-[#363636]">Browse up to 5 viewers without premium</span>
                                    </div>
                                )
                            }

                            {profileViewer && profileViewer.length > 0 ? (
                                (isSubscribed ? profileViewer : profileViewer.slice(0, 5)).map((profileViewer, index) => (
                                    <div key={index} className="flex flex-row justify-between items-center mt-3">
                                        <div className="flex flex-row gap-2 items-center">
                                            <img className="profile-viewer" src={`${profileViewer?.profileImageUrl}`} alt="" />
                                            {/* <img className="profile-viewer" src={`${process.env.REACT_APP_BASE_URL_IMAGE}${profileViewer?.profileImageUrl}`} alt="" /> */}

                                            <div className="flex flex-col items-start">
                                                <Link to={`/public-profile/${profileViewer?.userId}/view`} className="font-size-20px font-Poppins-SemiBold text-[#00443F] capitalize">{profileViewer?.firstName + " " + profileViewer?.lastName}</Link>
                                                <span className="font-size-15px font-Poppins-Regular text-[#000000] capitalize-first-letter">{profileViewer?.roleCategory}</span>
                                                <span className="font-size-15px font-Poppins-Regular  text-[#6D6D6D]">{timeSince(profileViewer?.createdAt)}</span>

                                            </div>
                                        </div>
                                        <div className="w-fit flex flex-row gap-2 ">
                                            <ConnectOrMessageButton
                                                status={profileViewer?.connectionStatus}
                                                uId={profileViewer?.userId}
                                                showMessageButton={true}
                                            />
                                        </div>
                                    </div>
                                ))
                            )
                                :
                                (
                                    <p className="text-center font-size-15px font-Poppins-SemiBold theme-color-green">No Viewers</p>
                                )
                            }
                            {isSubscribed ? <div className="pagination-parent mt-6 pb-2">
                                <PaginationComponet
                                    currentPage={queryParams.page}
                                    total={totalPages}
                                    setCurrentPage={(e) =>
                                        setQueryParams((prev) => ({ ...prev, page: e }))
                                    }
                                />
                            </div> : null}

                        </div>
                        {isSubscribed === false && (
                            (profileViewer && profileViewer.length > 0 && (
                                <div className="shadow-lg bg-white rounded-xl mb-3 pb-3 py-3 px-6 ">
                                    <div>
                                        <h2 className="font-size-20px font-Poppins-SemiBold text-[#363636]">All other viewers</h2>
                                        <span className="font-size-20px font-Poppins-Regular text-[#363636]">Unlock this list with premium</span>
                                    </div>
                                    {profileViewer.slice(5).map((profileViewer, index) => (
                                        <div key={index} className="flex flex-row items-center gap-2 mt-3">
                                            <img className="profile-viewer unpaid_user" src={`${process.env.REACT_APP_BASE_URL}${profileViewer?.profileImageUrl}`} alt="" />
                                            <div className="flex flex-col">
                                                <span className="font-size-20px font-Poppins-SemiBold text-[#00443F] capitalize-first-letter">{profileViewer?.roleCategory}</span>
                                                <span className="font-size-15px font-Poppins-Regular text-[#000000]">{profileViewer?.suburb}</span>

                                            </div>
                                        </div>

                                    ))}

                                </div>
                            ))
                        )}

                    </div>



                </div>
                <div>
                    <DiscussionSidebar fetchLoggedInUserData={"true"} />
                </div>

            </div>
            {unPaidUserModal && <UnpaidModal onClose={() => setUnPaidUserModal(false)} />}

        </div>
    );
}

export default ProfileViewer;
