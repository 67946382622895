import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faVenus,
  faClock,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import { calculateAge, convertTo12Hour, getTimeDifference } from "../../config/Helpers/helpers";
import moment from "moment";
import { getRole } from "../../config/Helpers/helpers";
import { useLocation } from "react-router-dom";
const JobPreview = ({ item }) => {
  const location = useLocation();
  const decoded = getRole();
  const adminType = decoded?.role
  const jobsBoardLocation = location.pathname;
  console.log(jobsBoardLocation, "locationlocation")

  // const { adminType = '' } = getRole();
  const checkPreferences = (user) => {
    if (!user) return '-'; // Return an empty string if preferences are not provided

    const { preferenceMale, preferenceFemale, preferencenopet, preferencenonsmoker } = user;

    // Check for specific combinations and return the corresponding HTML string
    if (!preferenceMale && !preferenceFemale && !preferencenopet && !preferencenonsmoker) {
      return '-';
    } else {
      let result = '';

      if (preferenceMale && preferenceFemale) {
        result += 'Male & Female';
      } else if (preferenceMale) {
        result += 'Only Male';
      } else if (preferenceFemale) {
        result += 'Only Female';
      }

      if (preferencenopet) {
        if (result !== '') result += ', ';
        result += 'No Pets';
      }

      if (preferencenonsmoker) {
        if (result !== '') result += ', ';
        result += 'Non Smoker';
      }
      return result;
    }
  };
  return (
    <>
      <div className="job-preview-main">
        <div className="job-preview-head"></div>
        <div className="job-preview-user">
          <img src={`${item?.profileImageUrl}`} alt="" />
          {/* <img src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`} alt="" /> */}
        </div>
        <div className="job-preview-content">
          <div className="text-center">
            <h2 className="font-Poppins-Medium font-size-22px capitalize">{item?.serviceName || ""}</h2>
            <p className="font-Poppins-Medium font-size-15px capitalize">{`${item?.fullName || ""}`}</p>
            <div className="flex flex-col">

              {item?.dateOfbirth &&
                <p className="font-Poppins-Medium font-size-15px">{calculateAge(item?.dateOfbirth) || ""} Years old</p>

              }

              <p className="font-Poppins-Medium font-size-15px capitalize">{item?.gender || ""}</p>
            </div>
            <Link to={`/public-profile/${item?.jobCreatorId}/view`} className="font-size-11px job-preview-view-pro font-Poppins-Regular">
              View profile
            </Link>
          </div>
          <div className="job-pre-border"></div>
          <div className="theme-grey-type">
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faLocationDot} />
              <p>{`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faVenus} />
              <p>{checkPreferences(item)}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faClock} />
              <p>
                {
                  item?.repeatCycle == 1 ? "One-off"
                    : item?.repeatCycle == 2 ? "Daily"
                      : item?.repeatCycle == 3 ? "Weekly"
                        : item?.repeatCycle == 4 ? "Monthly"
                          : "Custom"
                }
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>Start date: {moment(item?.startDate).format("DD-MM-YYYY") || ""}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>End date: {moment(item?.endDate).format("DD-MM-YYYY") || ""}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>Start time: {convertTo12Hour((item?.startTime || ""))}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>End time: {convertTo12Hour((item?.endTime || ""))}</p>
            </div>
            <div className="job-pre-border"></div>
            <div className="">
              <div className="">
                <h2 className="font-Poppins-Medium text-black font-size-15px capitalize">
                  {item?.serviceName}
                </h2>
              </div>
              <div className="">
                <p className="font-Poppins-Regular font-size-12px">
                  {item?.description || ""}
                </p>
              </div>
            </div>
            <div className="job-pre-border"></div>
            <div className="flex flex-row gap-2">
              <p className="font-Poppins-Medium font-size-12px">{getTimeDifference(item?.createdAt) || ""} </p>
              {item?.applications > 0 && jobsBoardLocation === "/jobs-board" &&

                <p className="font-Poppins-Medium font-size-12px">- {item?.applications} Application </p>

              }
            </div>
            <div className="job-pre-perhour"><Link className=" text-white font-Poppins-Regular font-size-14px">$ {item?.budget || 0}</Link></div>
            {adminType === "admin" || adminType === "superAdmin" ? (
              null
            )
              :
              (
                <div className="job-pre-apply-connect-btn">
                  <Link className="font-size-14px theme-color-green font-Poppins-SemiBold w-full cursor-not-allowed">Apply</Link>
                </div>
              )
            }

          </div>
        </div>
      </div>
    </>
  );
};

export default JobPreview;
