import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faX, faCopy, faDownload, faImage } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { getCookie } from '../../config/Helpers/helpers';


interface ViewAttachmentProps {
    viewAttachClose: () => void;
    filePath: string;
}

const ViewAttachment: React.FC<ViewAttachmentProps> = ({ viewAttachClose, filePath }) => {
    // Functionality: download the attached file
    // const handleDownload = async () => {
    //     console.log("filePath", filePath)
    //     try {
    //         const response = await axios.get(filePath, { responseType: 'blob' });
    //         const url = window.URL.createObjectURL(new Blob([response.data]));
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', filePath.substring(filePath.lastIndexOf('/') + 1));
    //         document.body.appendChild(link);
    //         link.click();
    //         link.parentNode.removeChild(link);
    //     } catch (error) {
    //         console.error('Error downloading file:', error);
    //     }
    // };
    const handleDownload = async () => {
        try {
            console.log("filePath", filePath);
            const response = await axios.get(filePath, {
                responseType: 'blob',
                headers: {
                    // 'Authorization': `Bearer ${getCookie('authToken')}`  // Add auth token if necessary
                    'Authorization': `Bearer ${getCookie()}`  // Add auth token if necessary
                }
            });
    
            // Create a new Blob object
            const blob = response.data as Blob;
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
    
            // Extract filename from response headers or file path
            const contentDisposition = response.headers['content-disposition'];
            let fileName = filePath.substring(filePath.lastIndexOf('/') + 1);
    
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="(.+)"/);
                if (matches && matches[1]) fileName = matches[1];
            }
    
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
    
            // Clean up URL object after download
            window.URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error downloading file:', error);
            toast.error('Failed to download file. Please try again.');
        }
    };
    
    return (
        <div className="modal">
            <div className="Cancellation-modal-content relative">
                <button
                    onClick={viewAttachClose}
                    className="absolute job-pre-edit"
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className=''>
                    <div className='flex justify-end'>
                        <FontAwesomeIcon role="button" icon={faDownload} onClick={handleDownload} />
                    </div>
                    <div>
                        <div className='viewattach-box'>
                            {filePath ? (
                                filePath.endsWith('.pdf') ? (
                                    <embed src={filePath} type="application/pdf" width="100%" height="100%" style={{ objectFit: 'cover' }} />
                                ) : (
                                    <img src={filePath} alt="File" style={{ maxWidth: '100%', maxHeight: '100%', width: "100%", height: "100%", objectFit: 'cover' }} />
                                )
                            ) : (
                                <div className='flex justify-center items-center noattach'>
                                    <FontAwesomeIcon icon={faImage} size='2xl' shake />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewAttachment;