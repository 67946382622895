import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import AnySpecificReason from '../AllDashboardPagesComponents/AnySpecificReason';
import JobCancelModal from './JobCancelModal';
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';

interface JobCancelButtonProps {
    jobId?: string;
    setActiveTab?: (status: string) => void;
    btnClass?: string;
    extraStyle?: React.CSSProperties;
}
interface ApiResponse {
    message: string;
}
const JobCancelButton: React.FC<JobCancelButtonProps> = ({
    jobId = "",
    setActiveTab = () => { },
    btnClass = "",
    extraStyle = {},
}) => {

    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [reasonModal, setReasonModal] = useState<boolean>(false);
    const [cancelReason, setCancelReason] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);

    // Functionality: API call for Job cancelled (both creator & applicant)
    const handleCancelJob = async () => {
        if (!cancelReason) {
            toast("Please enter comment.", { type: "error" });
            return;
        }
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({
            method: "post", path: "/api/job/cancel", params: {
                jobId: jobId,
                reason: cancelReason,
            }
        });
        if (res) {
            setCancelReason("");
            setActiveTab("cancelled");
            toast(res?.message || "Success", { type: "success" });
        } else {
            toast(err?.message, { type: "error" }); // api error
        }
        setIsLoader(false);
    }
    return (
        <>
            <button style={extraStyle} onClick={() => setCancelModal(true)} className={btnClass}><FontAwesomeIcon icon={faXmark} />Cancel</button>
            {cancelModal &&
                <JobCancelModal
                    onClose={() => setCancelModal(false)}
                    confirmCancel={() => { setCancelModal(false); setReasonModal(true) }}
                    status="cancel"
                    title="Are you sure you want to cancel this job?"
                    loader={false}
                />
            }
            {reasonModal &&
                <AnySpecificReason
                    text="Please specify the reason of cancellation"
                    onClose={() => setReasonModal(false)}
                    onDelete={handleCancelJob}
                    state={cancelReason}
                    setState={setCancelReason}
                    loader={isLoader}
                    leftBtnText="Go back"
                    rightBtnText="Cancel"
                />
            }
        </>
    );
};

export default JobCancelButton;