import React from 'react'
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faX, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';

function ApplyJobModal({ hideModal, setApplyId, loader, applyMessage, setApplyMessage, applyFiles, setApplyFiles, submit }) {

    const handleFileUpload = (e) => {
        const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
        const uploadedFiles = Array.from(e.target.files); // Convert FileList to array
        let totalSize = 0;

        uploadedFiles.forEach((file) => {
            totalSize += file.size;
        });

        if (totalSize > maxSize) {
            toast("Total file size exceeds the maximum allowed size (5 MB). Please choose smaller files.", { type: "error" });
            setApplyFiles([]);
            return;
        }

        setApplyFiles(uploadedFiles);

        uploadedFiles.forEach((file) => {
            const reader = new FileReader();
            reader.onloadend = (ee) => {
                const fileData = ee.target.result;
                // Handle file data as needed
            };
            reader.readAsDataURL(file);
        });
    };

    return (
        <div className="modal">
            <div className="viewapp-modal relative">
                <button
                    onClick={
                        () => {
                            hideModal(false);
                            setApplyId(false);
                        }
                    }
                    className="absolute job-pre-edit"
                >

                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className="greyline-view-app"></div>

                <div className="relative">
                    <h2 className="font-Poppins-Medium font-size-30px text-[#464646] text-center mb-2">Apply for a job</h2>
                </div>

                <div className='flex justify-start flex-col mt-4'>
                    <label className="font-Poppins-Regular font-size-14px text-[#646464] text-start mb-1">Message</label>
                    <textarea value={applyMessage} onChange={(e) => setApplyMessage(e.target.value)} className='view-app-detail p-3'> </textarea>
                </div>
                <div className='mt-2 mb-2'>
                    <button className='viewapp-file font-Poppins-SemiBold font-size-15px relative'>
                        <FontAwesomeIcon icon={faPaperclip} />
                        Attach files
                        <input className='multiple-files job-apply' type="file" accept=".jpg, .jpeg, .png, .pdf" multiple onChange={handleFileUpload} />
                    </button>
                </div>
                <div className='flex flex-row gap-2 items-center flex-wrap uploaded-app-files'>
                    {applyFiles && applyFiles.length > 0 ?
                        applyFiles.map((item, index) => (
                            <div key={index} className='flex flex-row gap-2 items-center'>
                                <FontAwesomeIcon icon={faFile} /> {item?.name || ''}
                            </div>
                        )) : []
                    }
                </div>
                <div className="flex cancellation-mdl-btn view-app-btn justify-center gap-2 mt-4">
                    <button className="text-white font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4 justify-center back-btn-view" onClick={
                        () => {
                            hideModal(false);
                            setApplyId(false);
                        }
                    }>Go back</button>
                    <button disabled={loader} onClick={submit} className="text-white theme-bg-green font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4 justify-center items-center green-btn">Apply  {loader &&<CircularProgress style={{ width: 20, height: 20, color: "#fff",display:"flex",justifyContent:"center",alignItems:"center" }}  />} </button>
                </div>
            </div>

        </div>
    )
}

export default ApplyJobModal;