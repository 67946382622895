import React, { useState, useEffect } from "react";

import { Link, useNavigate } from "react-router-dom";
import url from "../../config/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Pagination from "@mui/material/Pagination";
import PaginationItem from "@mui/material/PaginationItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import SortBySearch from "./SortBySearch";
import { useDispatch, useSelector } from "react-redux";
import {
  setSort,
  setCurrentPage,
  runTimeUpdate,
} from "../../config/Store/Reducers/HeaderSearchSlice";
import Skeleton from "@mui/material/Skeleton";
import { getRole } from "../../config/Helpers/helpers";
import Isverified from "../../assets/img/isMember.svg"
import IsFivityVerfied from "../../assets/img/50member.svg"

import ConnectionStatusButton from "../ConnectionsComponents/ConnectionStatusButton";
import HeaderGlobal from "../HeaderDashboard/headerGlobal";

interface UserSearchState {
  numberOfResults: number;
  data: Array<UserData>;
  isLoader: boolean;
  currentPage: number;
  perPage: number;
  sort: string;
}

interface UserData {
  _id: string;
  firstName?: string;
  lastName?: string;
  providerCategory?: string;
  role?: string;
  profileSummary?: string;
  profileImageUrl?: string;
  totalRatings?: number;
  totalReviews?: number;
  completedJobs?: number;
  cancelledJobs?: number;
  connectionStatus?: string;
  roleCategory?: string;
  connections?: string;
  [key: string]: any;
}

// import PaginationCom from "./PaginationCom"
const UserSearch: React.FC = () => {
  // const UserRole = getRole()?.UserRole || false;
  const decoded = getRole()
  const UserRole = decoded?.role || false;
  const userId = decoded?.userId || false;
  const dispatch = useDispatch();
  const { numberOfResults, data, isLoader, currentPage, perPage, sort } =
    useSelector((store: { header: UserSearchState }) => store.header);
  const [totalPage, setTotalPage] = useState(1);
  const navigate = useNavigate();
  const calculateIndex = () => {
    return `${(currentPage - 1) * perPage + 1} to ${currentPage * perPage < numberOfResults
      ? currentPage * perPage
      : numberOfResults
      }`;
  };

  const customTheme = createTheme({
    palette: {
      primary: {
        main: "#00443F",
      },
    },
  });

  const setSortting = (e: string) => {
    dispatch(setSort(e));
  };

  const handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
    dispatch(setCurrentPage(page));
  };

  const successConnection = () => {
    dispatch(runTimeUpdate());
  };

  const calculateRating = (totalRatings: number, totalReviews: number) => {
    try {
      return totalRatings / totalReviews;
    } catch {
      return 0;
    }
  };

  const cancellationRate = (cancelledJobs: number, completedJobs: number) => {
    try {
      let totalJobs = cancelledJobs + completedJobs;
      let per = (cancelledJobs / totalJobs) * 100;
      return parseInt(per.toString(), 10);
    } catch {
      return 0;
    }
  };

  useEffect(() => {
    if (numberOfResults) {
      setTotalPage(Math.ceil(numberOfResults / perPage));
    } else {
      setTotalPage(1);
    }
  }, [numberOfResults]);
  const backicon = "imgs/Beck-icon.png";
  return (
    <div className="">
      <HeaderGlobal />
      <div className="container-1480 h-3vw">
        <div className="Admin-main-operator-parent">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="font-size-36px font-Poppins-Medium">Search</h2>
            </div>
            <button
              // to={-1}
              onClick={() => navigate(-1)}
            >
              <img className="profile-back-icon" src={url.awsPath + backicon} />
            </button>
          </div>
          <div className="provider-search-content-parent">
            <h3 className=" font-Montserrat-Bold font-size-30px text-black mt-2">
              Find all users
            </h3>


            <div className="flex flex-col gap-4 mt-4">
              {isLoader ? (
                <>
                  <Skeleton
                    variant="rounded"
                    className="w-full mb-3"
                    height={100}
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-full mb-3"
                    height={100}
                  />
                  <Skeleton
                    variant="rounded"
                    className="w-full mb-3"
                    height={100}
                  />
                </>
              ) : (
                <>
                  {data && data.length > 0 ? (
                    data.map((item, index) => (
                      <div
                        key={index}
                        className="flex flex-col lg:flex-row md:flex-row justify-between items-center shadow-lg p-4 bg-white rounded-xl "
                      >
                        <div className="flex flex-row gap-6">
                          <img
                            className="EditProfileUserImg"
                            src={`${item?.profileImageUrl}`}
                            // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                            alt=""
                          />
                          <div className=" items-center lg:items-start md:items-start  px-3 flex flex-col w-[40vw]">
                            <button className="flex flex-row gap-2 items-center">
                              <h3 className="font-size-24px theme-color-green capitalize font-Montserrat-SemiBold flex items-center gap-2 capitalize">
                                <Link
                                  to={`/public-profile/${item?._id}/view`}
                                >{`${item?.firstName || ""} ${item?.lastName || ""
                                  }`}</Link>
                              </h3>
                              {!item?.freeUser &&
                                <img src={item?.isFounder ? IsFivityVerfied : Isverified} alt="crown" className="" />

                              }
                            </button>
                            <span className="font-Poppins-Medium font-size-17px capitalize">
                              {item?.roleCategory || item?.role || ""}
                            </span>
                            <span className=" font-Poppins-Regular font-size-15px capitalize-first-letter">
                              {item?.connections || ""} Connections
                            </span>
                            <p className="pt-3 font-Poppins-Regular font-size-15px text-gray-500">
                              {item?.profileSummary || ""}
                            </p>

                          </div>
                        </div>
                        <div className="w-full flex justify-center lg:justify-end md:justify-end text-end lg:w-2/5 md:w-2/5 ">
                          <div className="flex justify-between flex-col w-full">
                            <div className="flex items-center gap-2 justify-between lg:justify-end md:justify-end">
                              <div className="text-yellow-400">
                                {[1, 2, 3, 4, 5].map((v) => (
                                  <FontAwesomeIcon
                                    key={v}
                                    fontSize="20px"
                                    icon={faStar}
                                    className={
                                      calculateRating(
                                        item?.totalRatings || 0,
                                        item?.totalReviews || 0
                                      ) >= v
                                        ? "active"
                                        : "nonActiveStar"
                                    }
                                  />
                                ))}
                              </div>
                              <span className="text-black font-Poppins-Medium font-size-17px">
                                {" "}
                                {item?.totalReviews || 0} Reviews
                              </span>
                            </div>
                            <div className="flex items-center gap-1 justify-between lg:justify-end md:justify-end">
                              <span className="font-Poppins-SemiBold font-size-17px theme-color-green">
                                {item?.completedJobs || 0}
                              </span>
                              <span className="font-Poppins-Medium font-size-17px text-black">
                                Jobs completed
                              </span>
                            </div>
                            <div className="flex items-center gap-1 justify-between lg:justify-end md:justify-end">
                              <span className="font-Poppins-SemiBold font-size-17px theme-color-green">
                                {cancellationRate(
                                  item?.cancelledJobs || 0,
                                  item?.completedJobs || 0
                                ) || 0}
                                %
                              </span>
                              <span className="font-Poppins-Medium font-size-17px text-black">
                                Cancellation rate
                              </span>
                            </div>
                            <div className="flex items-end justify-between gap-2 lg:justify-end md:justify-end">
                              {userId !== item?._id ? (
                                <>
                                  <ConnectionStatusButton
                                    status={item?.connectionStatus}
                                    uId={item?._id}
                                  />
                                </>
                              ) : null}
                              <Link
                                to={`/public-profile/${item?._id}/view`}
                                className="feeds-btn flex items-center justify-center mt-3 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                                style={{ color: "white" }}
                              >
                                View profile
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <p>No search results</p>
                  )}
                </>
              )}
            </div>
            {/* <p className="text-black font-Montserrat-Medium font-size-15px  mt-4">
              Showing results {calculateIndex()} of {numberOfResults} service
              providers. select page:
            </p> */}
            <div>{/* <PaginationCom/> */}</div>
            {totalPage > 1 && (
              <div className="pagination-parent mt-6 pb-2">
                <ThemeProvider theme={customTheme}>
                  <Pagination
                    page={currentPage}
                    count={totalPage}
                    onChange={handlePageChange}
                    hidePrevButton={currentPage === 1} // Hide the previous button when on the first page
                    hideNextButton={currentPage === totalPage}
                    color="primary"
                    renderItem={(item) => {
                      // Disable the current page button
                      const isCurrentPage = item.page === currentPage;
                      return (
                        <PaginationItem
                          {...item}
                          disabled={isCurrentPage} // Disable the current page button
                        />
                      );
                    }}
                  />
                </ThemeProvider>
              </div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearch;
