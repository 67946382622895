import React from "react";
import { Link } from "react-router-dom";
import ManageJobsTabs from "../../components/AllDashboardPagesComponents/ManageJobsTabs";

import { getRole } from "../../config/Helpers/helpers";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";

const ManageJobs: React.FC = () => {
  const decoded = getRole();
  const ROLE = decoded?.role || false ;

  return (
    <div className="">
      <HeaderGlobal />
      <div className="container-1480 h-3vw">
        <div className=" Admin-main-operator-parent">
          <div className="lg:px-0 px-4">
            <h2 className="font-size-36px font-Poppins-Medium">Manage bookings</h2>
            {ROLE === "provider" && (
              <div className="flex flex-col  items-end lg:flex-row justify-end gap-2">
                <button
                  disabled
                  className="font-size-15px inactive-job-tab-res font-Poppins-Regular jobs-apply-btn active  h-[4.9vh] flex justify-center items-center"
                >
                  Posted jobs
                </button>
                <Link
                  to="/applied-jobs"
                  className="inactive-job-tab inactive-job-tab-res font-size-15px font-Poppins-Regular  h-[4.9vh] flex justify-center items-center"
                >
                  Applied jobs
                </Link>
              </div>
            )}
            <div className="mt-8">
              <div className="  ">
                <div className="  admin-active-job-handler">
                  <ManageJobsTabs />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageJobs;
