/**
 * ServicesEdit Component
 * Allows users to edit and manage their selected services by checking checkboxes
 * for each service category and saving the selections.
 *
 * File: ServicesEdit.jsx
 * Author: Developer
 * Date: 06/04/24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";

import { useSelector, useDispatch } from "react-redux";
import { getRole } from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { setAllServices } from "../../../config/Store/Reducers/allServicesSlice";
import { CircularProgress } from "@mui/material";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import httpRequest from "../../../config/Helpers/httpRequest";
interface Service {
  _id: string;
  servicename?: string;
  documents?: Service[];
}

interface RootState {
  allServices: { ALLSERVICES: Category[] };
  userProfile: { personalServices: Service[] };
}
interface ApiResponse {
  message?: string;
  documents?: Category[];
}
interface Category {
  _id: string;
  documents: Service[];
}
function ServicesEdit() {
  const { ALLSERVICES } = useSelector((state: RootState) => state.allServices || { ALLSERVICES: [] });

  const { personalServices } = useSelector((state: RootState) => state.userProfile);
  const dispatch = useDispatch();

  // const { UserRole } = getRole(); // get user Role
  const decoded = getRole();
  const UserRole = decoded?.role;

  const [allServiceLoader, setAllServiceLoader] = useState<boolean>(false); // loader for fetch all services
  const [isLoader, setIsLoader] = useState<boolean>(false); // loader for update services
  const [selectedServices, setSelectedServices] = useState<string[]>([]);

  const handleChange = (item: Service) => {
    const itemId = item._id; // Get the _id from the Service object
    const exists = selectedServices.includes(itemId);

    if (exists) {
      // Remove the _id if it exists
      const updatedServices = selectedServices.filter((id) => id !== itemId);
      setSelectedServices(updatedServices);
    } else {
      // Add the _id if it doesn't exist
      setSelectedServices([...selectedServices, itemId]);
    }
  };
  // Functionality: API call for for update services
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoader(true);
    const payload = {
      userServices: selectedServices.map((id) => ({ _id: id })),
    };
    const { res, err } = await httpRequest<ApiResponse>({
      method: "put",
      path: `/api/provider/update-services`,
      params: payload,
    });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  // Functionality: API call to get all services
  useEffect(() => {
    (async () => {
      setAllServiceLoader(true);
      const { res, err }: { res?: ApiResponse; err?: string } = await httpRequest({
        path: "/api/services/all",
      });

      console.log("API Response:", res); // Log the API response

      if (res && res.documents) {
        dispatch(setAllServices(res.documents)); // Directly dispatch documents without flattening for now
      } else {
        toast(err || "An error occurred", { type: "error" });
      }

      setAllServiceLoader(false);
    })();
  }, [dispatch]);



  // Set selected services when personalServices changes
  useEffect(() => {
    if (personalServices) {
      console.log(personalServices, "personalServicespersonalServices")
      // Map personalServices to extract only the _id values
      const serviceIds = personalServices.map(service => service._id);
      setSelectedServices(serviceIds); // Set the state with an array of _id strings
    }
  }, [personalServices]);

  return (
    <>
      {allServiceLoader && (
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      )}
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex ">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title=" Services" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">


            <form onSubmit={handleSubmit}>
              {ALLSERVICES && ALLSERVICES.length > 0 ? (
                ALLSERVICES.map((category, index) => (
                  <div key={index}>
                    <div className="my-2">
                      <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
                        {category?._id}
                      </h4>
                      <div className="">
                        {category?.documents?.length ? (
                          category.documents.map((v, i) => (
                            <div key={i} className="flex gap-2 services-checkbox">
                              <input
                                type="checkbox"
                                name="fav_language"
                                value="CSS"
                                id={v?._id || "-"}
                                checked={selectedServices.includes(v?._id)} // Check if the _id exists in selectedServices
                                onChange={() => handleChange(v)}
                              />


                              <label
                                htmlFor={v?._id || "-"}
                                className="font-size-15px font-Poppins-Regular theme-color-green flex"
                              >
                                {v?.servicename || ""}
                              </label>
                            </div>
                          ))
                        ) : (
                          <p>No item</p>
                        )}
                      </div>
                    </div>
                    <hr />
                  </div>
                ))
              ) : (
                <p>No services</p>
              )}
              <button
                disabled={isLoader}
                type="submit"
                className="mt-5 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                style={{ color: "white" }}
              >
                Save and continue{" "}
                {isLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServicesEdit;
