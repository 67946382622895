import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faX } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import httpRequest from "../../config/Helpers/httpRequest";
interface ApiResponse {
  message: string;
}
interface User {
  _id?: string;
  profileImageUrl?: string;
  firstName?: string;
  lastName?: string;
  roleCategory?: string;
  role?: string;
}
interface isEdit {
  rating: number;
  message: string;
}
interface JobReviewModalProps {
  onCLose: () => void;
  isEdit: boolean | { rating: number; message: string }; // Updated to handle both types
  jobId: string;
  user: User;
  successFunction: (msg: string) => void; // Function that takes a string argument
}

const JobReviewModal: React.FC<JobReviewModalProps> = ({
  onCLose = () => { },
  isEdit = false,
  jobId = "",
  user = {},
  successFunction = () => { },
}) => {

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [rating, setRating] = useState<number>(0);
  const [reviewMessage, setReviewMessage] = useState<string>("");

  // Functionality: API call for Job review create & edit
  const handleReview = async () => {
    if (!reviewMessage) {
      toast("Please enter review.", { type: "error" });
      return;
    }
    if (!rating) {
      toast("Please select rating.", { type: "error" });
      return;
    }
    setIsLoader(true);
    let createBody = {
      reviewedUserId: user?._id,
      jobId: jobId,
      jobRating: rating,
      reviewMessage: reviewMessage,
    };
    let editBody = {
      jobId: jobId,
      jobRating: rating,
      reviewMessage: reviewMessage,
    };
    const { res, err } = await httpRequest<ApiResponse>({ method: `${isEdit ? "put" : "post"}`, path: `/api/review/${isEdit ? "update" : "create"}`, params: isEdit ? editBody : createBody });
    if (res) {
      toast(res?.message || "Success", { type: "success" });
      setRating(0);
      setReviewMessage("");
      successFunction("");
      onCLose();
    } else {
      console.log(err, "asdasdadassdadasdas")

      toast(err?.message, { type: "error" }); // api error
      if (err.status === 400) {
        toast(err?.message?.message, { type: "error" }); //is ko dekhna ha
      }
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (typeof isEdit !== 'boolean' && isEdit) { // Check if isEdit is an object
      const { rating = 0, message = "" } = isEdit;
      setRating(rating);
      setReviewMessage(message);
    }
  }, [isEdit]);
  return (
    <div className="modal">
      <div className="Cancellation-modal-content review-modal-outer relative">
        <button onClick={onCLose} className="absolute job-pre-edit">
          <FontAwesomeIcon
            className="x-icon-admin-opr"
            icon={faX}
            style={{ color: "#ffffff" }}
          />
        </button>
        <div>
          <h2 className="font-Poppins-SemiBold font-size-40px text-[#464646]">
            Write a review
          </h2>

          <div className=" review-modal-main flex   gap-4 mt-4 mb-4 items-center">
            <div>
              <img
                src={`${user?.profileImageUrl}`}
                // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${user?.profileImageUrl}`}
              />
            </div>
            <div className="review-modal">
              <h2 className="font-Poppins-SemiBold font-size-25px text-[#00443f] capitalize">{`${user?.firstName || ""
                } ${user?.lastName || ""}`}</h2>
              <h3 className="font-Poppins-SemiBold capitalize-first-letter font-size-17px text-[#202020] w-fit">
                {user?.roleCategory
                  ? user?.roleCategory
                  : user?.role || "-"}
              </h3>

              <div className="text-yellow-400 flex flex-row mt-2 reviewstars">
                {[1, 2, 3, 4, 5].map((item) => (
                  <FontAwesomeIcon
                    className={rating >= item ? "active" : `nonActiveStar`}
                    key={item}
                    role="button"
                    onClick={() => setRating(item)}
                    fontSize="20px"
                    icon={faStar}
                  />
                ))}
              </div>
            </div>
          </div>
          <div>
            <textarea
              className="review-area h-[21vh] font-size-15px theme-color-para font-Poppins-Regular p-3 "
              placeholder={`Describe your experience with this ${user?.roleCategory !== "participant" ? "provider" : "participant"}`}
              value={reviewMessage}
              onChange={(e) => setReviewMessage(e.target.value)}
            ></textarea>
          </div>
        </div>

        <div className="flex cancel-job justify-center gap-2 mt-4">
          <button
            onClick={handleReview}
            className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max  flex gap-4 "
          >
            {isEdit ? "Edit" : "Post"}{" "}
            {isLoader && (
              <CircularProgress
                style={{ width: 16, height: 16, color: "#fff" }}
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default JobReviewModal;