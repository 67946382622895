import React from 'react';
import { Link } from 'react-router-dom';
import JobCompIcon from '../../assets/img/completejob-icon.svg';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from '@mui/material';
function JobCancelModal({ onClose, confirmCancel, status, title, loader }) {
    return (
        <div className="modal">
            <div className="Cancellation-modal-content relative">
                <button
                    onClick={onClose}
                    className="absolute job-pre-edit"
                >

                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <div className="cancellation-envolpe-main">
                    <div className="cancellation-envolpe">
                        {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                        <img src={JobCompIcon} alt="complete-icon" />
                    </div>
                </div>
                <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">{title}</h2>
                <div className="flex cancel-job justify-center gap-2 mt-4 ">
                    <button onClick={onClose}
                        className={`font-size-17px font-Poppins-Medium job-no-btn ${status === "cancel" ? "" : ""}`}

                    >No</button>
                    <button disabled={loader} onClick={confirmCancel}

                        className={`font-size-17px font-Poppins-Medium ${status === "success" ? "success-cond" : ""}`}

                    >

                        Yes {loader && <CircularProgress style={{ width: 16, height: 16, color: "#fff" }} />}</button>
                </div>
            </div>
        </div>
    );
};

export default JobCancelModal;