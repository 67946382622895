import React, { useState, useEffect, FormEvent } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import InputField from "../../../components/AllDashboardPagesComponents/InputField";

import { useSelector, useDispatch } from "react-redux";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import { getRole } from "../../../config/Helpers/helpers";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";

interface IApiResponse {
  message: string;
}
interface Rates {
  weekday: number | string | null;
  saturday: number | string | null;
  sunday: number | string | null;
}
interface DisplayRates {
  weekday: string;
  saturday: string;
  sunday: string;
}
function HourlyRatesEdit() {
  const dispatch = useDispatch();
  const { personalData } = useSelector((store: RootState) => store.userProfile); // get user data
  const decoded = getRole();
  const UserRole = decoded?.role;
  // const { UserRole } = getRole(); // get user Role

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [rates, setRates] = useState<Rates>({
    weekday: null,
    saturday: null,
    sunday: null,
  });
  const [displayRates, setDisplayRates] = useState<DisplayRates>({
    weekday: "",
    saturday: "",
    sunday: "",
  });
  const handleChange = (key: keyof Rates, value: string) => {
    let cleanedString = value.replace("$ ", "");
    const regex = /^[0-9.]+$/;
    if (cleanedString === "" || !regex.test(cleanedString)) {
      setDisplayRates({ ...displayRates, [key]: "" });
      setRates({ ...rates, [key]: null });
    } else {
      setDisplayRates({ ...displayRates, [key]: `$ ${cleanedString}` });
      setRates({ ...rates, [key]: parseFloat(cleanedString) });
    }
  };

  // Functionality: API call for for update hourly rate
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const ratesToSend = {
      weekday: rates.weekday !== null && rates.weekday !== "" ? rates.weekday : null,
      saturday: rates.saturday !== null && rates.saturday !== "" ? rates.saturday : null,
      sunday: rates.sunday !== null && rates.sunday !== "" ? rates.sunday : null,
    };
    setIsLoader(true);
    const { res, err } = await httpRequest<IApiResponse>({ method: "put", path: `/api/provider/rates`, params: ratesToSend });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message || "ERROR", { type: "error" }); // api error
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (personalData) {
      const { rates } = personalData;
      setRates({
        weekday: rates?.weekday || "",
        saturday: rates?.saturday || "",
        sunday: rates?.sunday || "",
      });
    }
  }, [personalData]);
  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title=" Hourly rates" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
            <div>
              <p className="font-size-15px font-Poppins-Regular theme-color-green flex mb-4">
                Hourly rates display on your profile as a guide for potential
                clients. The actual rates you agree on and include in a service
                agreement may differ slightly, based on a client`s individual
                service requirements.
              </p>
              {/* <div
                className="mb-4 p-3 rounded-xl"
                style={{ backgroundColor: "rgb(112 112 112 / 12%)" }}
              >
                <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                  Not sure how much to charge?
                </h4>
                <p className="font-size-15px font-Poppins-Regular theme-color-green flex mt-3">
                  Check out ndisync's rate calculator for insights into the
                  range of rates charged by the majority of support workers via
                  Mable in the last 6 months, filtered by state, service type
                  and day of the week.
                </p>
                <button
                  className="mt-3 theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                  style={{ color: "white" }}
                >
                  Rate calculator
                </button>
              </div> */}
              <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                Your hourly rates
              </h4>
              <p className="font-size-15px font-Poppins-Regular theme-color-green flex mb-3">
                Take into consideration your experience, qualifications, skills
                and service types. For NDIS-funded clients, hourly rates must
                not exceed NDIS price limits.
              </p>
              <form onSubmit={handleSubmit}>
                <InputField
                  type="text"
                  name="email"
                  labelTop="Weekdays"
                  value={`$ ${rates?.weekday || ""}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange("weekday", e.target.value)}
                  required
                />
                <InputField
                  type="text"
                  name="email"
                  labelTop="Saturday"
                  value={`$ ${rates?.saturday || ""}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange("saturday", e.target.value)}
                  required
                />
                <InputField
                  type="text"
                  name="email"
                  labelTop="Sunday"
                  value={`$ ${rates?.sunday || ""}`}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange("sunday", e.target.value)}
                  required
                />
                <button
                  disabled={isLoader}
                  type="submit"
                  className="my-3 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                  style={{ color: "white" }}
                >
                  Save and continue{" "}
                  {isLoader && (
                    <CircularProgress
                      style={{ width: 14, height: 14, color: "#fff" }}
                    />
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HourlyRatesEdit;