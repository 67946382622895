import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";

import { useSelector, useDispatch } from "react-redux";
import { getRole } from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";
import { IPreferences } from "../../../components/AllDashboardPagesComponents/Profile/PreferencesInterest";

interface IApiResponse {
  message?: string;
}
const PreferencesEdit: React.FC = () => {
  const dispatch = useDispatch();
  // const { UserRole } = getRole(); // get user Role
  const decoded = getRole();
  const UserRole = decoded?.role;
  const { personalData } = useSelector((store: RootState) => store.userProfile); // get user data

  const [isLoader, setIsLoader] = useState(false);
  const [preferences, setPreferences] = useState<IPreferences>({});
  // handle change
  const handleChange = (key: keyof IPreferences, value: boolean) => {
    if (key === "male" || key === "female") {
      setPreferences({
        ...preferences,
        [key]: value,
        noPreferences: false,
      });
      return;
    }
    setPreferences({
      ...preferences,
      [key]: value,
    });
  };

  // handle no preference change
  const handleNoPreferences = (value: boolean) => {
    if (value) {
      setPreferences({
        ...preferences,
        noPreferences: value,
        male: false,
        female: false,
        nonsmoker: false,
        nopets: false,
      });
    } else {
      setPreferences({
        ...preferences,
        noPreferences: value,
      });
    }
  };

  // Functionality: API call for for update preferences
  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    setIsLoader(true);
    const { res, err } = await httpRequest<IApiResponse>({
      method: "put", path: `/api/user/update-prefrences`, params: {
        preferencelgbtq: preferences?.lgbtq ?? null,
        preferencemale: preferences?.male ?? null,
        preferencefemale: preferences?.female ?? null,
        preferencenopet: preferences?.nopets ?? null,
        preferencenonsmoker: preferences?.nonsmoker ?? null,
        noPreferences: preferences?.noPreferences ?? null,
        personalityPetFriendly: preferences?.personalityPetFriendly ?? null,
        personalitySmoker: preferences?.personalitySmoker ?? null,
        hobbyCooking: preferences?.cooking ?? null,
        hobbyMusic: preferences?.music ?? null,
        hobbySports: preferences?.sports ?? null,
      }
    });
    if (res) {
      dispatch(updateProfile());
      toast(res?.message || "SUCCESS", { type: "success" }); // api message
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (personalData) {
      setPreferences({
        lgbtq: personalData?.preferences?.preferencelgbtq,
        male: personalData?.preferences?.preferencemale,
        female: personalData?.preferences?.preferencefemale,
        nopets: personalData?.preferences?.preferencenopet,
        nonsmoker: personalData?.preferences?.preferencenonsmoker,
        noPreferences: personalData?.preferences?.noPreferences,
        personalityPetFriendly: personalData?.preferences?.personalityPetFriendly,
        personalitySmoker: personalData?.preferences?.personalitySmoker,
        cooking: personalData?.preferences?.hobbyCooking,
        music: personalData?.preferences?.hobbyMusic,
        sports: personalData?.preferences?.hobbySports,
      });
    }
  }, [personalData]);

  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title=" My preferences & interests" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 services-checkbox">
            <form onSubmit={handleSubmit}>
              <div className="mb-2 " >
                <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                  LGBTQIA+
                </h4>
                <p className="font-size-15px font-Poppins-Regular theme-color-green flex">
                  Would you like to add a badge to your profile that shows your
                  support for people in the LGBTQIA+ community?
                </p>
                <div className="my-[18px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="YES_LGBT"
                      name="lgbt"
                      checked={preferences?.lgbtq === true}
                      onChange={() => handleChange("lgbtq", true)}
                    />
                    <label
                      htmlFor="YES_LGBT"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="NO_LGBT"
                      name="lgbt"
                      checked={preferences?.lgbtq === false}
                      onChange={() => handleChange("lgbtq", false)}
                    />
                    <label
                      htmlFor="NO_LGBT"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>

              <hr />
              <div className="my-2">
                <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                  My preferences
                </h4>
                <p className="font-size-15px font-Poppins-Regular theme-color-green flex">
                  Who do you prefer to work with?
                </p>
                <div className="my-[18px]">
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="female"
                      checked={preferences?.female === true}
                      onChange={(e) => handleChange("female", e.target.checked)}
                    />
                    <label
                      htmlFor="female"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Female only
                    </label>
                  </div>

                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="male"
                      checked={preferences?.male === true}
                      onChange={(e) => handleChange("male", e.target.checked)}
                    />
                    <label
                      htmlFor="male"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Male only
                    </label>
                  </div>
                  {/* // <div className="flex gap-2">
                  //   <input
                  //     type="checkbox"
                  //     id="nonsmoker"
                  //     checked={preferences?.nonsmoker === true}
                  //     onChange={(e) => handleChange("nonsmoker", e.target.checked)}
                  //   />
                  //   <label
                  //     htmlFor="nonsmoker"
                  //     className="font-size-15px font-Poppins-Regular theme-color-green flex"
                  //   >
                  //     Non-Smoker
                  //   </label>
                  // </div>
                  // <div className="flex gap-2">
                  //   <input
                  //     type="checkbox"
                  //     id="nopets"
                  //     checked={preferences?.nopets === true}
                  //     onChange={(e) => handleChange("nopets", e.target.checked)}
                  //   />
                  //   <label
                  //     htmlFor="nopets"
                  //     className="font-size-15px font-Poppins-Regular theme-color-green flex"
                  //   >
                  //     No Pets
                  //   </label>
                  // </div> */}



                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="noPreferences"
                      checked={preferences?.noPreferences === true}
                      onChange={(e) => handleNoPreferences(e.target.checked)}
                    />
                    <label
                      htmlFor="noPreferences"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      No preferences
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="my-2">
                <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                  Hobbies
                </h4>

                <div className="my-[18px]">
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="cooking"
                      checked={preferences?.cooking === true}
                      onChange={(e) =>
                        handleChange("cooking", e.target.checked)
                      }
                    />
                    <label
                      htmlFor="cooking"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Cooking
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="music"
                      checked={preferences?.music === true}
                      onChange={(e) => handleChange("music", e.target.checked)}
                    />
                    <label
                      htmlFor="music"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Music
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="checkbox"
                      id="sports"
                      checked={preferences?.sports === true}
                      onChange={(e) => handleChange("sports", e.target.checked)}
                    />
                    <label
                      htmlFor="sports"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Sports
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="my-2">
                <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                  Are you a smoker?
                </h4>
                <div className="my-[18px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="smoker_friendly"
                      name="are_you_non_smoker"
                      checked={preferences?.personalitySmoker === true}
                      onChange={() => handleChange("personalitySmoker", true)}
                    />
                    <label
                      htmlFor="smoker_friendly"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="non_smoker_friendly"
                      name="are_you_non_smoker"
                      checked={preferences?.personalitySmoker === false}
                      onChange={() => handleChange("personalitySmoker", false)}
                    />
                    <label
                      htmlFor="non_smoker_friendly"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <hr />
              <div className="my-2">
                <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
                  Are you pet friendly?
                </h4>
                <div className="my-[18px]">
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="pet_friendly"
                      name="are_you_pet_friendly"
                      checked={preferences?.personalityPetFriendly === true}
                      onChange={() =>
                        handleChange("personalityPetFriendly", true)
                      }
                    />
                    <label
                      htmlFor="pet_friendly"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      Yes
                    </label>
                  </div>
                  <div className="flex gap-2">
                    <input
                      type="radio"
                      id="no_pet_friendly"
                      name="are_you_pet_friendly"
                      checked={preferences?.personalityPetFriendly === false}
                      onChange={() =>
                        handleChange("personalityPetFriendly", false)
                      }
                    />
                    <label
                      htmlFor="no_pet_friendly"
                      className="font-size-15px font-Poppins-Regular theme-color-green flex"
                    >
                      No
                    </label>
                  </div>
                </div>
              </div>
              <button
                disabled={isLoader}
                type="submit"
                className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn my-2"
                style={{ color: "white" }}
              >
                Save and continue{" "}
                {isLoader && (
                  <CircularProgress
                    style={{ width: 14, height: 14, color: "#fff" }}
                  />
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PreferencesEdit;