// // all services slice 24-04-24
// import { createSlice } from '@reduxjs/toolkit';

// const allServicesSlice = createSlice({
//     name: 'GET_ALL_SERVICES',
//     initialState: {
//         ALLSERVICES: [],
//     },
//     reducers: {
//         setAllServices: ((state, action) => {
//             action.payload.sort((a, b) => {
//                 if (a._id < b._id) {
//                     return -1;
//                 }
//                 if (a._id > b._id) {
//                     return 1;
//                 }
//                 return 0;
//             });
//             state.ALLSERVICES = action.payload;
//         }),
//         resetAllServices: ((state, action) => {
//             state.ALLSERVICES = [];
//         }),
//     },
// });

// export const { setAllServices, resetAllServices } = allServicesSlice.actions;
// export default allServicesSlice.reducer;

// all services slice 24-04-24
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Service {
  _id: string;
  servicename?: string;
  documents?: Service[];
}

interface Category {
  _id: string;
  documents: Service[];
}

interface AllServicesState {
  ALLSERVICES: Category[];
}

const initialState: AllServicesState = {
  ALLSERVICES: [],
};

const allServicesSlice = createSlice({
  name: 'allServices',
  initialState,
  reducers: {
    setAllServices: (state, action: PayloadAction<Category[]>) => {
      state.ALLSERVICES = action.payload;
    },
    resetAllServices: (state) => {
      state.ALLSERVICES = [];
    },
  },
});

export const { setAllServices, resetAllServices } = allServicesSlice.actions;
export default allServicesSlice.reducer;