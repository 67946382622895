import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import httpRequest from "../../config/Helpers/httpRequest";
import newone from "../../assets/img/new.svg";
import VerficationSheild from "../../assets/img/verified-shield.png"
import DefaultCoverImage from "../../assets/img/welcome-hudson.png";
import Skeleton from '@mui/material/Skeleton';
import { updateProfileCompletionPercentage, setPersonalProfile } from "../../config/Store/Reducers/userProfile";
import ndisyncblacklogo from "../../assets/img/ndisync-black-logo.png";
import {
  calculateRating,
  CalculateCancellationRate,
  truncateString,
  getRole,
  isNotEmpty,
} from "../../config/Helpers/helpers";
import { toast } from "react-toastify";
import Isverified from "../../assets/img/isMember.svg"
import IsFivityVerfied from "../../assets/img/50member.svg"
import qs from "qs";
import { RootState } from "../../config/Store/store";

interface IApiResponse {
  totalResults: number;
  percentage: number;
  paginated: paginated;
}
interface paginated {
  [key: string]: any;

}
function SidebarGobal() {
  const dispatch = useDispatch()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  // let UserRole = getRole()?.UserRole || "";
  const { pathname } = useLocation();
  const { personalData, profileCompletionPercentage } = useSelector(
    (store: RootState) => store.userProfile
  );
  const coverImageUrl = personalData?.coverImageUrl
    ? `${personalData.coverImageUrl}`
    // ? `${process.env.REACT_APP_BASE_URL_IMAGE}${personalData.coverImageUrl}`
    : DefaultCoverImage;
  const [totalViews, setTotalViews] = useState(0)
  useEffect(() => {
    if (isNotEmpty(personalData)) {
      setIsLoading(false)
    }
  }, [personalData])
  useEffect(() => {
    const fetchProfileViewers = async () => {
      const { res, err } = await httpRequest<IApiResponse>({
        method: "get",
        path: `/api/search/profile-insights`,
      });

      if (res) {
        setTotalViews(res?.paginated?.totalItems)
      } else {
        // toast(err?.message, { type: "error" });
      }
    }
    const fetchProfileCompletionStatus = async () => {
      const { res, err } = await httpRequest<IApiResponse>({
        method: "get",
        path: `/api/user/completion-status`,
      });

      if (res) {
        dispatch(updateProfileCompletionPercentage(res.percentage));
      }
      else {
        console.log(err, { type: "error" });
      }

    }
    fetchProfileCompletionStatus()
    fetchProfileViewers()
  }, [])
  return (
    <div className="sidebar-gobal mb-2">
      <div className="sidebar-gobal-user-details pb-2">
        {isLoading ?
          (
            <Skeleton variant="rounded" className="w-full mb-2 skeleton-sidebar-global" style={{ borderRadius: 6 }} />

          )
          :
          (
            <div
              className="sidebar-gobal-user-img"
              style={{
                backgroundImage: `url(${coverImageUrl}`,
              }}
            >
              <img
                src={`${personalData?.profileImageUrl}?t=${Date.now()}`}
                // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${personalData?.profileImageUrl}?t=${Date.now()}`}
                onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                alt="user"
              />
            </div>
          )

        }



        {/* image */}
        <div className="sidebar-gobal-user-rating flex justify-center items-center">
          {isLoading ?
            (
              <div className="w-[80%]">
                <Skeleton className="w-full  " height={15} />
              </div>
            )
            :
            (
              <div className="text-yellow-400 flex gap-1 ">
                {[1, 2, 3, 4, 5].map((v) => (
                  <FontAwesomeIcon
                    key={v}
                    fontSize="20px"
                    icon={faStar}
                    color="#FF9B29"
                    className={
                      calculateRating(
                        personalData?.totalRatings,
                        personalData?.totalReviews
                      ) >= v
                        ? "active"
                        : "nonActiveStar"
                    }
                  />
                ))}
              </div>
            )
          }
        </div>{" "}
        {/* rating star */}
        <div className="sidebar-gobal-user-name text-center">

          {isLoading ?
            (
              <div className=" flex items-center flex-col justify-center">
                <Skeleton className="w-[80%]  " height={15} />
                <Skeleton className="w-[80%]  mt-2 " height={15} />
                <Skeleton className="w-[80%]   mt-2" height={15} />
              </div>
            )
            :
            (
              <>
                <div className="flex flex-row gap-2 flex-nowrap justify-center">
                  <Link
                    // to={`/public-profile/${personalData?._id}/view`}
                    to={`/publicprofile`}
                    className="font-size-20px font-Poppins-SemiBold theme-color-green text-center   on-hover-underline capitalize"
                  >
                    {`${personalData?.firstName || ""} ${personalData?.lastName || ""
                      }`}

                  </Link>
                  {personalData?.freeUser === false && (

                    <>
                      <img className="discussion-sidebar-icon" src={personalData?.isFounder ? IsFivityVerfied : Isverified} alt="Verified-Icon" />
                    </>

                  )}
                </div>
                <div className="flex justify-center items-center">
                  {!personalData?.profileVerified ?
                    (
                      <div className="w-max">
                        <Link to="http://vetting.com/" target="_blank" className="font-size-15px font-Poppins-Medium verify-btn b-1-[#004182] text-[#004182] flex flex-row gap-2 items-center verify-btn">
                          <img className="verify-icon-shield" src={VerficationSheild} /> Verify now
                        </Link>
                      </div>
                    )
                    :
                    personalData?.profileVerified ? (
                      <div className="w-max">
                        <button className="font-size-15px font-Poppins-Medium verified-btn bg-[#B2D8D5] text-[#007E76] flex flex-row gap-2 items-center">
                          Verified
                        </button>
                      </div>

                    ) : null
                  }
                </div>
                {/* name */}
                <p className="font-size-15px  theme-color-green font-Poppins-Regular text-center capitalize-first-letter">
                  {personalData?.roleCategory ||
                    personalData?.role ||
                    ""}
                </p>
              </>
            )}

          {/* role */}
          <p className="font-size-15px theme-grey-type  font-Poppins-Regular  text-center">
            {truncateString(personalData?.profileSummary, 50)}
          </p>{" "}
          {/* profile summary */}
        </div>
        <hr className="my-3" />
        {isLoading ?
          (
            <div className=" flex items-center flex-col justify-center">
              <Skeleton className="w-[80%]  " height={15} />
            </div>
          )
          :
          (
            <Link to="/profile-viewers" className="flex justify-between items-center px-2">
              <p className="font-size-15px theme-grey-type  font-Poppins-Medium  on-hover-underline">
                Profile viewers
              </p>
              <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                {totalViews || 0}
              </p>
            </Link>
          )}

        <hr className="my-3" />
        {isLoading ?
          (
            <div className=" flex items-center flex-col justify-center">
              <Skeleton className="w-[80%]  " height={15} />
              <Skeleton className="w-[80%]  mt-2 " height={15} />
              <Skeleton className="w-[80%]   mt-2" height={15} />
              <Skeleton className="w-[80%]   mt-2" height={15} />
            </div>
          )
          :
          (
            <>
              <div className="sidebar-gobal-user-jobs-status px-2">
                <div className="flex justify-between items-center">
                  <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
                    Jobs completed
                  </p>
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                    {personalData?.completedJobs || 0}
                  </p>
                </div>{" "}
                {/* completed jobs */}
                <div className="flex justify-between items-center">
                  <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
                    Connections
                  </p>
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                    {personalData?.connections || 0}
                  </p>
                </div>{" "}
                {/* connection length */}
                <div className="flex justify-between items-center">
                  <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
                    Cancellation rate
                  </p>
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                    {CalculateCancellationRate(
                      personalData?.cancelledJobs,
                      personalData?.completedJobs
                    )}
                  </p>
                </div>{" "}
                {/* cancellation rate */}
                <div className="flex justify-between items-center">
                  <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
                    Reviews
                  </p>
                  <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                    {personalData?.totalReviews || 0}
                  </p>
                </div>{" "}
                {/* reviews */}
              </div>
            </>
          )
        }

        <hr className="my-3" />
        {isLoading ?
          (
            <div className=" flex items-center flex-col justify-center">
              <Skeleton className="w-[80%]  " height={10} />
              <Skeleton className="w-[80%]  mt-2 " height={10} />
              <Skeleton className="w-[80%]   mt-2" height={10} />
            </div>
          )
          :
          (
            <div className="px-2">
              <div className="flex justify-between items-center ">
                <p className="font-size-15px theme-grey-type  font-Poppins-Medium  ">
                  Your profile is
                </p>
                <p className="font-size-15px font-Poppins-SemiBold theme-color-green">
                  {`${profileCompletionPercentage || 0}%`}
                </p>
              </div>{" "}
              {/* completion percentage */}
              <div className="profilebar-feed w-full  relative ">
                <span
                  className="theme-bg-green absolute left-0  rounded-full	"
                  style={{
                    width: `${profileCompletionPercentage || 0}%`,
                    height: "100%",
                  }}
                ></span>
              </div>{" "}
              {/* completion percentage */}
              <Link
                to="/publicprofile"
                className="font-size-13px font-Poppins-SemiBold theme-color-green on-hover-underline"
              >
                Resume account setup
              </Link>
            </div>
          )}
      </div>
      {/* <div className="sidebar-gobal-user-details mt-2 py-2 px-2 on-hover-underline mobile-only mb-2">
        <Link
          to=""
          className="font-size-13px font-Poppins-SemiBold theme-color-green flex gap-1 items-center"
        >
          <span className="menus-gloabal-icon">
            <img src={newone} alt="" />
          </span>
          Get the <img src={ndisyncblacklogo} alt="" style={{ width: "20%" }} />{" "}
          mobile app
        </Link>
      </div> */}
    </div>
  );
}

export default SidebarGobal;
