import React, { useState } from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan } from "@fortawesome/free-solid-svg-icons";
import { getRole } from '../../config/Helpers/helpers';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import httpRequest from '../../config/Helpers/httpRequest';
interface BlockUserProps {
    uid?: string;
}
interface ApiResponse {
    message: string;
}
const BlockUser: React.FC<BlockUserProps> = ({ uid = "" }) => {
    const navigate = useNavigate();
    // const { userId } = getRole();
    const decoded = getRole();
    const userId = decoded?.userId;

    const [isLoader, setIsLoader] = useState<boolean>(false);

    // Functionality: API call for user blocked
    const blockUser = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/block/add", params: { blockedUserId: uid } });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            navigate(-1);
        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };
    return (
        <>
            {!(userId === uid) &&
                <button onClick={blockUser} disabled={isLoader} className="flex items-center justify-center gap-1 mt-3">
                    <FontAwesomeIcon style={{ color: "#00443F" }} icon={faBan} />
                    <span
                        style={{ color: "#00443F" }}
                        className="font-size-15px font-Poppins-Regular"
                    >
                        Block
                    </span>
                </button>
            }
        </>
    );
};

export default BlockUser;