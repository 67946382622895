/**
 * ManageReviews Component
 * 
 * Component for managing user reviews, displaying either "My Reviews" or "Published Reviews".
 * Allows users to view, edit, and delete reviews, with pagination support.
 * 
 * @file ManageReviews.jsx
 * @component ManageReviews
 * @description Renders user reviews based on selected review side (My Reviews or Published Reviews).
 *              Provides functionality to fetch reviews, handle review edits and deletions, and display
 *              user details, ratings, and review messages.
 * 
 * 
 * @author Developer
 * @date 10-06-24
 */

import React, { useState, useEffect } from "react";
import SidebarGobal from "../SidebarGobal";
import HeaderGlobal from "../../HeaderDashboard/headerGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

import { getRole, getTimeDifference } from "../../../config/Helpers/helpers";
import qs from "qs";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import PaginationComponet from "../../PaginationComponet/PaginationComponet";
import DeleteReview from "../../JobReviewComponents/DeleteReview";
import EditReview from "../../JobReviewComponents/EditReview";
import httpRequest from "../../../config/Helpers/httpRequest";


interface Review {
  _id: string;
  jobRating: number;
  reviewMessage: string;
  createdAt: string;
  firstName: string;
  lastName: string;
  fullName: string;
  role: string;
  roleCategory: string;
  profileImageUrl: string;
  [key: string]: any;
}

interface PaginatedData {
  currentPage: number;
  totalPages: number;
  limit: number;
  totalItems: number;
}

interface ApiResponse {
  documents: Review[];
  paginated: PaginatedData;
}

interface QueryParams {
  page: number;
  limit: number;
}
const ManageReviews: React.FC = () => {
  // let { userId = "" } = getRole();
  const decoded = getRole();
  const userId = decoded?.userId || "";

  const [reviewSide, setReviewSide] = useState<string>("my");
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [allReview, setAllReview] = useState<Review[]>([]);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    page: 1,
    limit: 10,
  });
  const handleSideChange = (e: string) => {
    setReviewSide(e);
    setQueryParams({
      ...queryParams,
      page: 1,
    });
  };

  // Functionality: API call for get review of user
  const fetchReviews = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({ path: `/api/review/${userId}?reviewSide=${reviewSide}&${qs.stringify(queryParams)}` });
    if (res) {
      setAllReview(res?.documents || []);
      // let count = res?.paginated?.totalPages || 1;
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  const handleReviewDeleted = () => {
    if (queryParams.page === 1) {
      fetchReviews();
    } else {
      // If queryParams.page is greater than 1, decrement page by 1
      setQueryParams({
        ...queryParams,
        page: allReview.length === 1 ? queryParams.page - 1 : queryParams.page
      });
    };
  }; // edit review success

  const reviewEditSuccess = () => {
    fetchReviews();
  }; // review edit success

  useEffect(() => {
    fetchReviews();
  }, [queryParams]);
  console.log(allReview, "allReviewallReviewallReview")
  return (
    <div>
      <HeaderGlobal />
      {isLoader &&
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      }
      <div className="container-1480 block lg:flex justify-between h-3vw gap-3">
        <SidebarGobal />
        <div className="w-full">
          <div className=" flex flex-row lg:justify-end justify-center gap-2">
            <button onClick={() => handleSideChange("my")} className={reviewSide === "my" ? "font-size-15px font-Poppins-Medium jobs-apply-btn active w-[11vw] manage-reviews-btn" : "inactive-job-tab  manage-reviews-btn font-size-15px font-Poppins-Medium  w-[11vw]"}>My reviews</button>
            <button onClick={() => handleSideChange("published")} className={reviewSide === "published" ? "font-size-15px font-Poppins-Medium jobs-apply-btn active w-[11vw] manage-reviews-btn" : "inactive-job-tab manage-reviews-btn  font-size-15px font-Poppins-Medium  w-[11vw]"}>Published reviews</button>
          </div>
          <div className="shadow-lg bg-white rounded-xl mb-2 py-3 px-6 mt-4">
            <h4 className="font-size-20px capitalize-first-letter font-Poppins-SemiBold theme-color-green mb-3">
              {`${reviewSide} reviews`}
            </h4>

            {allReview?.map((item, index) => (
              <div key={index}>
                <div className="flex shadow-lg  rounded-xl flex items-center justify-center reviews-manage" style={{ backgroundColor: "#FFFCF1" }}>
                  <div className=" p-5 rounded-l-2xl  text-center w-1/4">
                    <img
                      className="review-profile-img rounded-full mx-auto object-cover"
                      src={`${item?.profileImageUrl}`}
                      // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                      alt=""
                    /> {/* profile image */}
                    <h3 className="font-size-17px font-Poppins-Medium theme-color-green capitalize">
                      {`${item?.firstName || ""} ${item?.lastName || ""}`}
                    </h3> {/* user name */}
                    <p className="font-size-15px font-Poppins-Regular theme-grey-type capitalize">
                      {item?.roleCategory || ""}
                    </p> {/* user role */}
                  </div>
                  <div className=" w-3/4 p-5 rounded-r-2xl ">
                    <div className="flex justify-between items-center mb-5">
                      <div className="text-yellow-500">
                        {[1, 2, 3, 4, 5].map(v =>
                          <FontAwesomeIcon key={v} fontSize="20px" icon={faStar} style={{ color: item?.jobRating >= v ? "#FF9B29" : "#b8b8b8" }} />
                        )}
                      </div>
                    </div> {/* rating */}
                    <div className="flex justify-between items-center">
                      <p className="font-size-15px font-Poppins-Regular theme-grey-type">{item?.reviewMessage || ""}</p>
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 flex-col py-3 px-3 h-full items-end text-nowrap">
                    <span className="font-size-13px font-Poppins-Regular theme-grey-type">
                      {getTimeDifference(item?.createdAt)}
                    </span> {/* time differences */}
                    {reviewSide === "my" &&
                      <div className="flex gap-2">
                        {/* <EditReview
                          buttonclassName="theme-bg-green w-24  font-Poppins-Medium font-size-16px px-5 py-1 inline-table rounded-3xl"
                          extraStyle={{ color: "white", width: "5vw" }}
                          userData={item}
                          rating={item.jobRating}
                          message={item?.reviewMessage || ""}
                          jobId={item?.jobId}
                          onSuccessFunctions={reviewEditSuccess}
                        />
                        <DeleteReview
                          onSuccessFunctions={handleReviewDeleted}
                          uId={item?.UserId || ""}
                          jobId={item?.jobId || ""}
                          buttonclassName="font-Poppins-Medium w-24  font-size-16px px-5 py-1 inline-table rounded-3xl"
                          extraStyle={{ color: "white", backgroundColor: "red", width: "5vw" }}
                        /> */}
                        <EditReview
                          buttonClass="theme-bg-green w-24 font-Poppins-SemiBold font-size-12px px-5 py-1 inline-table rounded-3xl"
                          extraStyle={{ color: "white", width: "5vw" }}
                          userData={item}
                          rating={item.jobRating}
                          message={item.reviewMessage}
                          jobId={item.jobId}
                          onSuccessFunctions={reviewEditSuccess}
                        />
                        <DeleteReview
                          onSuccessFunctions={handleReviewDeleted}
                          uId={item._id}
                          jobId={item.jobId}
                          buttonClass="font-Poppins-SemiBold w-24 font-size-12px px-5 py-1 inline-table rounded-3xl"
                          extraStyle={{
                            color: "white",
                            backgroundColor: "red",
                            width: "5vw",
                          }}
                        />
                      </div>
                    }
                  </div>
                </div>
                <hr className="my-3" />
              </div>
            ))}
            <PaginationComponet
              currentPage={queryParams.page}
              total={totalPages}
              setCurrentPage={(e) => setQueryParams({ ...queryParams, page: e })}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageReviews;