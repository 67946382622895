import React, { useState, useEffect } from 'react';
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { premiumPlans, setUpgradeUser } from '../../config/Store/Reducers/authSlice';
import { RootState } from '../../config/Store/store';
import Paymentform from '../../pages/LoginPages/paymentform';
interface Plan {
    _id: string;
    title: string;
    offerAmount: number;
    priceId: string;
    details: string[];
    [key: string]: any;
}
interface ApiResponse {
    documents: Plan[];
    promotions: Plan[];
    [key: string]: any;
}
interface PremiumPlansProps {
    freeText: () => void;  // Define the freeText prop as a function with no return value
}
const PremiumPlans: React.FC<PremiumPlansProps> = ({ freeText }) => {

    const { personalData } = useSelector((store: RootState) => store.userProfile);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [allPlans, setAllPlans] = useState<Plan[]>([]);
    const [promotionPlan, setPromotionPlan] = useState<Plan[]>([]);
    const [isLoader, setIsLoader] = useState(false);
    const [promotionalStatus, setPromotionalStatus] = useState<boolean>(false)
    const [StripeComponent, setStripeComponent] = useState<boolean>(false);
    const handleGoBack = () => {
        if (StripeComponent) {
            setStripeComponent(false);
        }
        else {
            navigate(-1);
        }
    }
    const handleNavigate = (item: Plan) => {

        let data = {
            email: personalData?.email,
            firstName: personalData?.firstName,
            lastName: personalData?.lastName,
            address: personalData?.address || "",
            suburb: personalData?.suburb || "",
            state: personalData?.state || "",
            postCode: personalData?.postCode || "",
            phonenumber: personalData?.phonenumber || "",
            role: personalData?.role || "",
            password: personalData?.password || ""
        };
        const obj = {
            id: item._id,
            subscriptionName: item.title,
            price: item.offerAmount,
            save: item.title === 'annually' ? calculateSave() : '',
        };
        freeText()
        dispatch(setUpgradeUser(data));
        dispatch(premiumPlans(obj));
        console.log('Dispatching premiumPlans:', obj); // Log the data being dispatched
        setStripeComponent(true);
        // navigate("/payment-form");
    }

    const calculateSave = () => {
        if (allPlans) {
            const monthlyPlan = allPlans.find(plan => plan?.title === "monthly");
            const annuallyPlan = allPlans.find(plan => plan?.title === "yearly");
            if (monthlyPlan && annuallyPlan) {
                const savingsPercentage = (1 - (annuallyPlan.offerAmount / (12 * monthlyPlan.offerAmount))) * 100 || 0;
                return savingsPercentage.toFixed(1);
            }
        }
        return 0; // Return 0 if allPlans is falsy or if either monthly or annually plan is not found
    };

    useEffect(() => {
        (async () => {
            setIsLoader(true);
            try {
                const response = await axios.get<ApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/subscription/offer`);
                setAllPlans(response.data?.documents);
                setPromotionPlan(response.data?.promotions);
                setPromotionalStatus(response?.data?.promotionalStatus)
            }
            catch (err: any) {
                toast(err?.response?.data || err?.response?.message || "ERROR", { type: "error" });
            }
            setIsLoader(false);
        })();
    }, []);

    return (
        <>

            <div className="  relative z-20  gap-2 justify-between grid pb-2"
                style={{
                    gridTemplateColumns: StripeComponent ? "100%" : "50% 50%"
                }}
            >
                {!StripeComponent ?

                    (
                        <>
                            {allPlans?.map((item, index) => (
                                <div key={index} className={`access-plan-box-login-paid relative bg-white flex flex-col overflow-clip ${promotionalStatus ? "opacity-50" : ""}`}

                                >
                                    {/* {item?.title === "yearly"
                                    ?
                                    <span className="font-Montserrat-Medium font-size-login-25px leading-none w-full text-center text-white access-planbox-save-text absolute">
                                        Save {calculateSave()}%
                                    </span>
                                    : ''
                                } */}
                                    <div className="flex flex-col gap-2">
                                        <div className='flex items-baseline justify-between'>
                                            <h3 className="font-Montserrat-Bold font-size-login-28px theme-color-green leading-none mb-5">
                                                {item?.title === "monthly" ? " Monthly" : 'Annually'}
                                                {/* Monthly */}
                                            </h3>
                                            <div className="flex items-center gap-3">
                                                {item?.title === "yearly"
                                                    ?
                                                    <span className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-light-seagreen access-plan-box-line-throughed">
                                                        ${((allPlans?.find(v => v?.title === "monthly")?.offerAmount ?? 0) * 12).toFixed(2)}
                                                    </span>
                                                    : ''
                                                }
                                                <span className="leading-none font-Montserrat-Bold font-size-login-32px theme-color-green">
                                                    ${item?.offerAmount || 0}
                                                </span>
                                                <span className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-light-seagreen">
                                                    / per {item?.title === "monthly" ? "month" : "year"}
                                                </span>
                                            </div>
                                        </div>
                                        <h3 className="font-Montserrat-Bold font-size-login-25px theme-color-green leading-none">
                                            All access pass
                                        </h3>
                                        <h3 className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-green">
                                            Everything you need, all in one place.
                                        </h3>
                                    </div>

                                    <button
                                        disabled={promotionalStatus}
                                        className="access-plan-box-membership-btn theme-bg-green text-white font-Poppins-SemiBold font-size-login-23px  w-full flex items-center justify-center "
                                        onClick={() => handleNavigate(item)}
                                    >
                                        Join our membership
                                    </button>
                                </div>
                            ))}

                            {promotionPlan?.map((item, index) => (
                                <div key={index} className={`access-plan-box-login-paid relative bg-white flex flex-col overflow-clip
                                ${!item?.status ? "opacity-50" : ""}
                                `}>
                                    {/* {item?.title === "yearly"
                                    ?
                                    <span className="font-Montserrat-Medium font-size-login-25px leading-none w-full text-center text-white access-planbox-save-text absolute">
                                        Save {calculateSave()}%
                                    </span>
                                    : ''
                                } */}
                                    <div className="flex flex-col gap-2">
                                        <div className='flex items-baseline justify-between'>
                                            <h3 className="font-Montserrat-Bold font-size-login-28px theme-color-green leading-none mb-5">
                                                {item?.title}
                                                {/* Monthly */}
                                            </h3>
                                            <div className="flex items-center gap-3">
                                                {/* {item?.title === "yearly"
                                                    ?
                                                    <span className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-light-seagreen access-plan-box-line-throughed">
                                                        ${((allPlans?.find(v => v?.title === "monthly")?.offerAmount ?? 0) * 12).toFixed(2)}
                                                    </span>
                                                    : ''
                                                } */}
                                                <span className="leading-none font-Montserrat-Bold font-size-login-32px theme-color-green">
                                                    ${item?.offerAmount || 0}
                                                </span>
                                                <span className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-light-seagreen">
                                                    / per {item?.title === "monthly" ? "month" : "year"}
                                                </span>
                                            </div>
                                        </div>
                                        {/* <h3 className="font-Montserrat-Bold font-size-login-25px theme-color-green leading-none">
                                            All access pass
                                        </h3>
                                        <h3 className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-green">
                                            Everything you need, all in one place.
                                        </h3> */}
                                        {/* {item?.details?.map((detail, index) => {
                                            const parts = detail.split(","); // Split the detail by commas into an array
                                            return (
                                                <div key={index}>
                                                    {parts.map((part, i) => (
                                                        <>
                                                            <h3 key={i} className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-green">
                                                                {part.trim()}
                                                            </h3>
                                                        </>
                                                    ))}
                                                </div>
                                            );
                                        })} */}
                                        {item?.details?.map((detail, index) => {
                                            const parts = detail.split(","); // Split the detail by commas into an array
                                            return (
                                                <div key={index}>
                                                    <h3 className="leading-none font-Montserrat-Medium font-size-20px theme-color-green">
                                                        {parts[0].trim()} {/* First part goes into h3 */}
                                                    </h3>
                                                    {parts.length > 1 && (
                                                        <p className="leading-none font-Montserrat-Medium font-size-15px theme-color-green">
                                                            {parts.slice(1).join(",").trim()} {/* Remaining parts go into p */}
                                                        </p>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <div className='flex flex-row justify-between'>
                                        <button
                                            disabled={!item?.status}
                                            className="access-plan-box-membership-btn theme-bg-green text-white font-Poppins-SemiBold font-size-login-23px  w-full flex items-center justify-center "
                                            onClick={() => handleNavigate(item)}
                                        >
                                            {/* Join our membership */}
                                            Claim now
                                        </button>
                                        <div className='total-user-left text-[#009e92]'>
                                            <span className='font-size-15px font-Poppins-Regular'>
                                                {item?.maxCount - item?.count}
                                            </span>
                                            <span className='font-size-15px font-Poppins-Regular'>/</span>
                                            <span className='font-size-15px font-Poppins-Regular'>
                                                {item?.maxCount} left
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ))}


                        </>
                    )
                    :
                    (
                        <Paymentform />

                    )
                }



            </div>

        </>
    );
};

export default PremiumPlans;