import React, { useState, ChangeEvent, FormEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faX, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import httpRequest from "../../config/Helpers/httpRequest";
interface StartJobDisputeProps {
  onClose: () => void;
  jobId: string | boolean;
  successFunction: (msg: string) => void; // Function that takes a string argument
}
interface ApiResponse {
  message: string;
  [key: string]: any;
}
const StartJobDispute: React.FC<StartJobDisputeProps> = ({
  onClose,
  jobId,
  successFunction,
}) => {
  const [disputeFiles, setDisputeFiles] = useState<File[]>([]);
  const [subject, setSubject] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
    // const maxSize = 5 * 1024 * 1024; // 5 MB in bytes
    const uploadedFiles = Array.from(e.target.files || []); // Convert FileList to array
    let totalSize = 0;

    uploadedFiles.forEach((file) => {
      totalSize += file.size;
    });

    // if (totalSize > maxSize) {
    //     toast("Total file size exceeds the maximum allowed size (5 MB). Please choose smaller files.", { type: "error" });
    //     setDisputeFiles([]);
    //     return;
    // }

    setDisputeFiles(uploadedFiles);

    uploadedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = (ee) => {
        const fileData = ee.target?.result;
        // Handle file data as needed
      };
      reader.readAsDataURL(file);
    });
  };

  // Functionality: API call for dispute create
  const handleDispute = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!subject) {
      toast("Please type subject", { type: "error" });
    } else if (!description) {
      toast("Please type description", { type: "error" });
    } else if (disputeFiles.length < 1) {
      toast("Please select atleast 1 file", { type: "error" });
    } else {
      setIsLoader(true);
      const formData = new FormData();
      if (typeof jobId === "string") {
        formData.append("jobId", jobId);
      }
      formData.append("subject", subject);
      formData.append("description", description);
      disputeFiles.forEach((file) => {
        formData.append("files", file);
      });
      const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/job/create-dispute", params: formData, header: { "Content-Type": "multipart/form-data" } });
      if (res) {
        setDescription("");
        setSubject("");
        setDisputeFiles([]);
        onClose();
        toast(res?.message || "Success", { type: "success" });
        successFunction(res?.message || "Success");
      } else {
        toast(err?.message, { type: "error" }); // api error
      }
      setIsLoader(false);
    }
  };

  return (
    <div className="modal">
      <div className="viewapp-modal relative">
        <button onClick={onClose} className="absolute job-pre-edit">
          <FontAwesomeIcon
            className="x-icon-admin-opr"
            icon={faX}
            style={{ color: "#ffffff" }}
          />
        </button>
        <div className="relative">
          <h2 className="font-Poppins-Medium font-size-40px text-[#464646] text-center">
            Start dispute
          </h2>
        </div>
        <form onSubmit={handleDispute}>
          <div className="flex justify-start flex-col mt-4">
            <div className="flex flex-col mb-2">
              <label className="font-Poppins-Regular font-size-14px text-[#00443F] text-start mb-1">
                Subject
              </label>
              <input
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
                className="font-Poppins-Regular font-size-14px text-start mb-1  p-3 view-app-detail-2 h-[2.139vw]"
                type="text"
              />
            </div>
            <label className="font-Poppins-Regular font-size-14px text-[#00443F] text-start mb-1 ">
              Description
            </label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="view-app-detail-3 h-[21vh] p-3"
            >
              {" "}
            </textarea>
          </div>
          <div className="mt-2 mb-2">
            <button className="viewapp-file font-Poppins-SemiBold font-size-15px relative">
              <FontAwesomeIcon icon={faPaperclip} />
              Attach files
              <input
                className="multiple-files"
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                multiple
                onChange={handleFileUpload}
              />
            </button>
          </div>
          <div className="flex flex-row gap-2 items-center flex-wrap uploaded-app-files">
            {disputeFiles && disputeFiles.length > 0
              ? disputeFiles.map((item, index) => (
                <div key={index} className="flex flex-row gap-2 items-center font-size-15px font-Poppins-Medium">
                  <FontAwesomeIcon icon={faFile} /> {item?.name || ""}
                </div>
              ))
              : []}
          </div>
          <div className="flex  view-app-btn-2 justify-center gap-2 mt-4">
            <button
              type="submit"
              disabled={isLoader}
              className="text-white bg-darkgreen theme-bg-green font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4 justify-center text-nowrap flex-nowrap"
            >
              Start dispute
              {isLoader && (
                <CircularProgress
                  style={{ width: 16, height: 16, color: "#fff" }}
                />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default StartJobDispute;