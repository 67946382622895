import React, { useState, useEffect } from "react";
import PreviewProfile from "./PreviewProfile";
import SidebarGobal from "../SidebarGobal";
import HeaderGlobal from "../../HeaderDashboard/headerGlobal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { getTimeDifference } from "../../../config/Helpers/helpers";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";

import qs from "qs";
import PaginationComponet from "../../PaginationComponet/PaginationComponet";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../../config/Helpers/httpRequest";

function AllReview() {
  const { uid = false } = useParams(); // search user id
  const [isLoader, setIsLoader] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  const [allReview, setAllReview] = useState([]);
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    reviewSide:"published"
  }); // query params for APIs

  // Functionality: API call for get review of user
  useEffect(() => {
    (async () => {
      setIsLoader(true);
      const { res, err } = await httpRequest({ path: `/api/review/${uid}?${qs.stringify(queryParams)}` });
      if (res) {
        setAllReview(res?.documents || []);
        let count = res?.paginated?.totalPages || 1;
        setTotalPages(count);
      } else {
            toast(err?.message, { type: "error" });
      }
      setIsLoader(false);
    })();
  }, [queryParams]);
  return (
    <div>
      <HeaderGlobal />
      {isLoader && (
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      )}
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title="Reviews" />
          <div className="shadow-lg bg-white rounded-xl mb-2 py-3 px-6  ">
            {/* <h4 class="font-size-20px font-Poppins-SemiBold theme-color-green mb-3 ">
              Reviews
            </h4> */}
            {allReview?.length === 0 &&
              <ul>
                <li>No reviews</li>
              </ul>}

            {allReview?.map((item, index) => (
              <div key={index}>
                <div
                  className="flex shadow-lg  rounded-xl flex items-center justify-center"
                  style={{ backgroundColor: "#FFFCF1" }}
                >
                  <div className=" p-5 rounded-l-2xl  text-center w-1/4">
                    <img
                      className="review-profile-img rounded-full mx-auto object-cover"
                      src={`${item?.profileImageUrl}`}
                      // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                      alt=""
                    />
                    <h3 className="font-size-17px font-Poppins-Medium theme-color-green capitalize">
                      {`${item?.firstName || ""} ${item?.lastName || ""
                        }`}
                    </h3>
                    <p className="font-size-15px font-Poppins-Regular theme-grey-type capitalize">
                      {item?.role || ""}
                    </p>
                  </div>
                  <div className=" w-3/4 p-5 rounded-r-2xl ">
                    <div className="flex justify-between items-center mb-5">
                      <div className="text-yellow-500">
                        {[1, 2, 3, 4, 5].map((v) => (
                          <FontAwesomeIcon
                            key={v}
                            fontSize="20px"
                            icon={faStar}
                            style={{
                              color:
                                item?.jobRating >= v ? "#FF9B29" : "#b8b8b8",
                            }}
                          />
                        ))}
                      </div>
                      <span className="font-size-13px font-Poppins-Regular theme-grey-type">
                        {getTimeDifference(item?.createdAt)}
                      </span>
                    </div>
                    <p className="font-size-15px font-Poppins-Regular theme-grey-type">
                      {item?.reviewMessage || ""}
                    </p>
                  </div>
                </div>
                <hr className="my-3" />
              </div>
            ))}
            <PaginationComponet
              currentPage={queryParams.page}
              total={totalPages}
              setCurrentPage={(e) =>
                setQueryParams({ ...queryParams, page: e })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllReview;