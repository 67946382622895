import React from "react";
import edit from "../../../assets/img/awesome-edit.svg";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getRole } from "../../../config/Helpers/helpers";
import { useLocation } from "react-router-dom";

import { get } from "axios";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
import { RootState } from "../../../config/Store/store";
interface Service {
  _id: string;
  servicename?: string;
  documents?: Service[];
}

// interface RootState {
//   allServices: { ALLSERVICES: Category[] };
//   userProfile: {
//     personalServices: Service[];
//     publicServices: Service[]; // Add this line
//   };
// }
interface ApiResponse {
  message?: string;
  documents?: Category[];
}
interface Category {
  _id: string;
  documents: Service[];
}
function ServicesProfile() {
  const location = useLocation()
  const decoded = getRole();
  const userId = decoded?.userId;
  // const { userId = "" } = getRole();

  const { personalServices, publicServices } = useSelector((store: RootState) => store.userProfile);
  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile);
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicServices as PublicDataType) : (personalServices as PublicDataType);
  const ServiceData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);
 
  // const { publicServices, personalData } = useSelector((store: RootState) => ({
  //   publicServices: store.userProfile.publicServices as Service[], // Ensure this is typed as Service[]
  //   personalData: store.userProfile.personalData as personalData,
  // }));

  return (
    <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
          Services
        </h4>
        {userId === ServiceData?._id && (
          <Link to="/services-edit" className="mt-2 edit-pro-icon ">
            <img src={edit} alt="" />
          </Link>
        )}
      </div>
      <div className="flex gap-3 flex-wrap mt-4">
        {ShowData && ShowData.length > 0 ? (
          ShowData.map((item: Service, index: number) => (
            <div
              key={index}
              className="flex gap-3 items-center font-Poppins-light font-size-19px services-profile-btn justify-center"
            >
              {item?.servicename}
            </div>
          ))
        ) : (
          <p>No services</p>
        )}
      </div>
    </div>
  );
}

export default ServicesProfile;
