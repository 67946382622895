/**
 * DeclineConnection Component
 * 
 * This component renders a button that declines a connection request when clicked.
 * It uses React hooks for state management and displays a loader while the request is being processed.
 * 
 * File Name: DeclineConnection.js
 * Author: Developer
 * Date: 2024-04-12
 */

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CircularProgress } from '@mui/material';
import httpRequest from '../../config/Helpers/httpRequest';
interface DeclineConnectionProps {
    id: string;
    onSuccess: (nextStatus: string) => void;
    btnText: string;
}

interface ApiResponse {
    message: string;
    nextStatus: string;
    status: string;
}
/**
 * DeclineConnection Component
 * 
 * @param {string} id - The ID of the user to decline the connection request for.
 * @param {function} onSuccess - Callback function to be called upon a successful decline.
 * @param {string} btnText - Text to display on the decline button.
 */
const DeclineConnection: React.FC<DeclineConnectionProps> = ({ id = "", onSuccess = () => { }, btnText = "" }) => {
    const [isLoader, setIsLoader] = useState<string>("");

    // Functionality: API call for connection request rejected
    const handleDecline = async (uId: string) => {
        setIsLoader(uId);
        const { res, err } = await httpRequest<ApiResponse>({
            method: "post",
            path: btnText === "Cancel" ? "/api/connection/cancel" : "/api/connection/reject",  // Conditional path
            params: { otherId: uId }
        });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            onSuccess(res?.nextStatus || "");
        } else {
            toast(err?.message, { type: "error" })
        }
        setIsLoader("");
    };
    return (
        <button
            onClick={() => handleDecline(id)}
            disabled={isLoader === id}
            className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn bg-[#AE0303] text-white"
            style={{ color: "white" }}
        >
            {btnText}
            {isLoader === id && <CircularProgress style={{ color: '#fff', width: 16, height: 16, marginLeft: 4 }} />}
        </button>
    );
};

export default DeclineConnection;