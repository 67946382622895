import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import Announce from "../../../../assets/img/announce.svg"
import AnnounceRed from "../../../../assets/img/redAnnounce.svg"
import httpRequest from "../../../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
interface ConfirmationModalProps {
    isOpen: boolean;
    modalType: string;
    onClose: () => void;
    promoId?: string;
    handleStatusChange: (status: string) => void;
}
interface ApiResponse {
    message?: string;
    [key: string]: any;
}
const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ isOpen, onClose, modalType, promoId, handleStatusChange }) => {
    const [isDeclined, setIsDeclined] = useState<boolean>(false)
    const [declineReason, setDeclineReason] = useState<string>("")
    const [loader, setLoader] = useState<boolean>(false)
    useEffect(() => {
        setIsDeclined(false);
    }, [])
    const handleSubmit = async () => {
        setLoader(true)
        if (!promoId) {
            toast("Promotion ID is missing.", { type: "error" });
            return; // Prevent the request if promoId is null or undefined
        }
        const requestBody = modalType === "decline-modal" ? { decision: declineReason } : {};

        const { res, err } = await httpRequest<ApiResponse>({
            method: 'patch',
            path: `/api/admin/ad/status/${modalType === "approve-modal" ? "active" : "decline"}/${promoId}`,
            params: requestBody
        })

        if (res) {
            toast(res?.message || "Success", { type: "success" });
            if (modalType === "approve-modal") {
                onClose()
                handleStatusChange("active")
            }
            else {
                onClose()
                handleStatusChange("declined")
                setDeclineReason("")
            }
        }
        else {
            toast(err?.message || "Error", { type: "error" });
            setLoader(false)
        }
        setLoader(false)
    }

    if (!isOpen) return null;
    console.log(modalType, "modaltype")
    return (
        <div className="modal" style={{ display: isOpen ? "block" : "none" }}>
            <div className="Cancellation-modal-content relative">
                <button onClick={() => {
                    onClose();
                    setIsDeclined(false)
                }} className="absolute job-pre-edit">
                    <FontAwesomeIcon className="x-icon-admin-opr" icon={faX} style={{ color: "#ffffff" }} />
                </button>
                <div className="cancellation-envolpe-main">
                    <div className="cancellation-envolpe">
                        <img src={modalType === "approve-modal" ? Announce : AnnounceRed} alt="approval-tag" />
                    </div>
                </div>
                {!isDeclined ? (
                    <>
                        {modalType === "approve-modal" ?

                            (
                                <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">
                                    Are you sure you want to approve
                                    this promotion?
                                </h2>
                            )
                            :
                            (
                                <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">
                                    Are you sure you want to decline
                                    this promotion?
                                </h2>
                            )
                        }

                        <div className="flex cancel-job justify-center gap-2 mt-4">
                            <button onClick={onClose} className={`font-size-17px font-Poppins-Medium  cancel-cond ${modalType === "approve-modal" ? "cancel-cond" : "success-cond"}`}>
                                No
                            </button>
                            <button
                                onClick={modalType === "approve-modal" ? handleSubmit : () => setIsDeclined(true)}
                                className={`"flex flex-row items-center gap-3 font-size-17px font-Poppins-Medium  ${modalType === "approve-modal" ? "success-cond" : "cancel-cond"}`}>
                                Yes
                                {loader &&
                                    <CircularProgress
                                        style={{ width: 16, height: 16, color: "#ffffff" }}
                                    />
                                }
                            </button>
                        </div>
                    </>
                )

                    :
                    (
                        <>

                            <h2 className="font-Poppins-Regular font-size-30px text-[#2F2F31]">
                                please specify the reason
                            </h2>

                            <textarea
                                name=""
                                id=""
                                value={declineReason}
                                onChange={(e) => setDeclineReason(e.target.value)}
                                className="resize-none canellation-textarea font-size-15px theme-color-para font-Poppins-Regular"
                            ></textarea>
                            <div className="flex cancel-job justify-center gap-2 mt-4">
                                <button
                                    onClick={() => {
                                        onClose();  // Call the onClose function
                                        setIsDeclined(false);  // Set the decline state
                                    }}
                                    className={`font-size-17px font-Poppins-Medium  cancel-cond ${modalType === "approve-modal" ? "cancel-cond" : "success-cond"}`}>
                                    Go back
                                </button>
                                <button
                                    onClick={handleSubmit}
                                    className={`" flex flex-row gap-3 items-center font-size-17px font-Poppins-Medium  ${modalType === "approve-modal" ? "success-cond" : "cancel-cond"}`}>
                                    submit
                                    {loader &&
                                    <CircularProgress
                                        style={{ width: 16, height: 16, color: "#ffffff" }}
                                    />
                                }
                                </button>
                            </div>
                        </>
                    )

                }

            </div>
        </div >
    );
};

export default ConfirmationModal;
