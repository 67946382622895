import React from "react";
import PrivacyTab from "../../../components/EditProfileComponents/Tabs/PrivacyTab";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";

function Privacy() {
  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 lg:flex md:flex gap-3 ">
        <SidebarGobal />
        <div className="w-full">
          <div className="remove-btn">
            <PreviewProfile title="Privacy settings" />
          </div>
          <div className="">
            <PrivacyTab />
          </div>
        </div>
      </div>
    </>
  );
};

export default Privacy;