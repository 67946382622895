import Cookies from 'js-cookie';
import { jwtDecode, JwtPayload } from 'jwt-decode';
import {
  removeToken,
  removeTwoFactor,
  resetPremiumPlans,
  removeUpgradeUser,
  logOutUser,
} from '../Store/Reducers/authSlice';
import {
  setValue,
  setCurrentPage,
  setNumber,
  setData,
} from '../Store/Reducers/HeaderSearchSlice';
import { resetAllNotifications } from '../Store/Reducers/notificationsSlice';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import { toast } from 'react-toastify';
// import { resetActivityPosts } from '../Store/Reducers/postActivitySlice';
import { userLogOut } from '../Store/Reducers/userProfile';
import { store } from '../Store/store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';
import { resetActivityPosts } from '../Store/Reducers/postActivitySlice';
export const randomColors = ['#948DFF', '#e12d75', '#30d536', '#00dafa'];

export const handleCopyLinkPost = (id: string | boolean): void => {
  const link = `${process.env.REACT_APP_DASHBOARD}/feed-detail/${id}/view`;
  try {
    const textarea = document.createElement('textarea');
    textarea.value = link;
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand('copy');
    document.body.removeChild(textarea);
    toast('Copied to clipboard.', { type: 'info' });
  } catch (err) {
    toast('Something went wrong.', { type: 'error' });
  }
};

export const chatMessageDecrypt = (encryptedMessage: string = ''): string => {
  const decryptedBytes = CryptoJS.AES.decrypt(
    encryptedMessage,
    process.env.REACT_APP_CHAT_SECRET_KEY!
  );
  const decryptedMessage = decryptedBytes.toString(CryptoJS.enc.Utf8);
  return decryptedMessage;
}; // for chat message decrypt

export const postTimeDifference = (data: string): string => {
  const targetTime = moment(data);

  if (!targetTime.isValid()) {
    return '0';
  }
  const now = moment();
  const diffSeconds = now.diff(targetTime, 'seconds');
  if (diffSeconds < 0) {
    return 'a few seconds ago'; // or any other message you prefer
  }
  const minutesDiff = now.diff(targetTime, 'minutes');
  const hoursDiff = now.diff(targetTime, 'hours');
  const daysDiff = now.diff(targetTime, 'days');
  const weeksDiff = now.diff(targetTime, 'weeks');
  const yearsDiff = now.diff(targetTime, 'years');

  if (minutesDiff < 60) {
    return `${minutesDiff}min`;
  } else if (hoursDiff < 24) {
    return `${hoursDiff}h`;
  } else if (daysDiff < 7) {
    return `${daysDiff}d`;
  } else if (weeksDiff < 52) {
    return `${weeksDiff}w`;
  } else {
    return `${yearsDiff}y`;
  }
}; // for post time differences

export const convertTo12HourFormat = (time: string = '00:00'): string => {
  try {
    const [hours, minutes] = time.split(':');
    const date = new Date();
    date.setHours(parseInt(hours), parseInt(minutes));

    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  } catch (err) {
    return '00:00';
  }
};

export const monthYear = (dateString: string | Date = new Date()): string => {
  if (!dateString) return '-'; // Return "-" if dateString is undefined
  const date = new Date(dateString);
  // Check if the date is valid
  if (isNaN(date.getTime())) return '-'; // Return "-" if dateString is invalid
  const options = { year: 'numeric', month: 'long' } as const;
  return date.toLocaleString('en-US', options);
};

export const truncateString = (str: string = '', maxLength: number = 10): string => {
  if (str?.length > maxLength) {
    return str?.substring(0, maxLength) + '...';
  }
  return str;
};

export const CalculateCancellationRate = (
  cancelledJobs: number = 0,
  completedJobs: number = 0
): string => {
  try {
    let totalJobs = cancelledJobs + completedJobs;
    let per = (cancelledJobs / totalJobs) * 100 || 0;
    return `${parseInt(per.toString())}%` || `0%`;
  } catch {
    return `0%`;
  }
};

export const calculateRating = (totalRatings: number = 0, totalReviews: number = 0): number => {
  try {
    return totalRatings / totalReviews;
  } catch {
    return 0;
  }
};

export const jobStartDate = (date: string | Date): string => {
  const startDate = new Date(date);
  const currentDate = new Date();
  const differenceMs = startDate.getTime() - currentDate.getTime();

  if (differenceMs < 0) {
    console.log(startDate, "startDatestartDate")
    console.log(currentDate, "currentDatecurrentDate")
    return 'has been started';
  }

  if (
    startDate.getDate() === currentDate.getDate() &&
    startDate.getMonth() === currentDate.getMonth() &&
    startDate.getFullYear() === currentDate.getFullYear()
  ) {
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));
    return `will starts in ${hours} hours ${minutes} minutes`;
  }

  const differenceDays = Math.ceil(differenceMs / (1000 * 60 * 60 * 24));
  return `will starts in ${differenceDays} days`;
};
export const jobStartDateWithTime = (startDateStr: string | Date, startTimeStr: string): string => {
  // Combine start date and time
  const [startHours, startMinutes] = startTimeStr.split(':').map(Number); // Convert time string (HH:mm) to hours and minutes
  const startDate = new Date(startDateStr);

  // Set the start date to include the start time
  startDate.setHours(startHours);
  startDate.setMinutes(startMinutes);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const currentDate = new Date();

  const differenceMs = startDate.getTime() - currentDate.getTime();

  // If the current time is equal to the start time
  if (differenceMs <= 0) {
    console.log(startDate, "startDate (Job Start Time)");
    console.log(currentDate, "currentDate (Current Time)");
    return 'has been started';
  }

  // Check if the job starts today
  if (
    startDate.getDate() === currentDate.getDate() &&
    startDate.getMonth() === currentDate.getMonth() &&
    startDate.getFullYear() === currentDate.getFullYear()
  ) {
    const hours = Math.floor(differenceMs / (1000 * 60 * 60));
    const minutes = Math.floor((differenceMs % (1000 * 60 * 60)) / (1000 * 60));

    if (hours === 0 && minutes > 0) {
      return `will start in ${minutes} minutes`;
    } else if (hours > 0) {
      return `will start in ${hours} hours and ${minutes} minutes`;
    } else {
      return 'will start soon';
    }
  }

  // Calculate the difference in days
  const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
  return `will start in ${differenceDays} days`;
};




export const YEAR_MONTH_DATE = (date: Date): string => {
  if (date) {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2); // Month is 0-based
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  } else {
    return '';
  }
};

export const splitMessage = (data: string): string => {
  try {
    let _data = JSON.parse(data);
    return _data?.message;
  } catch (err) {
    return '';
  }
};

export const timeSplit = (time: string): string => {
  if (time) {
    const date = new Date(time);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  } else {
    return '-';
  }
};

export const dateSplit = (time: string): string => {
  if (time) {
    const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];

    const date = new Date(time);
    const dayOfWeek = weekdays[date.getDay()];
    const dayOfMonth = date.getDate();
    const month = months[date.getMonth()];

    return `${dayOfWeek} ${dayOfMonth} ${month}`;
  } else {
    return '-';
  }
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options = { year: 'numeric', month: 'short', day: 'numeric' } as const;
  // Check if the parsed date is valid
  if (isNaN(date.getTime())) {
    return '-';
  } else {
    return date.toLocaleDateString('en-US', options);
  }
};

export const calculateAge = (dateOfBirth: string): number | string => {
  if (dateOfBirth) {
    const dob = new Date(dateOfBirth);
    const now = new Date();

    let age = now.getFullYear() - dob.getFullYear();
    const monthDiff = now.getMonth() - dob.getMonth();

    // Adjust if the birthday hasn't occurred yet this year
    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
      age--;
    }

    return age;
  } else {
    return '';
  }
};
// export const calculateAge = (dateOfBirth: string): number | string => {
//   if (dateOfBirth) {
//     const dob = new Date(dateOfBirth);
//     const now = new Date();

//     // Check if the date of birth is in the future
//     if (dob > now) {
//       return 'Invalid date of birth';
//     }

//     let age = now.getFullYear() - dob.getFullYear();
//     const monthDiff = now.getMonth() - dob.getMonth();

//     // Adjust if the birthday hasn't occurred yet this year
//     if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < dob.getDate())) {
//       age--;
//     }

//     return age;
//   } else {
//     return '';
//   }
// };
export const getTimeDifference = (dateString: string): string => {
  if (dateString) {
    const date = new Date(dateString);
    const now = new Date();

    const diffInMilliseconds = now.getTime() - date.getTime();
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    if (diffInSeconds < 0) {
      return "a few seconds ago";
    }
    else if (diffInSeconds < 60) {
      return `${diffInSeconds} second${diffInSeconds === 1 ? '' : 's'} ago`;
    } else if (diffInSeconds < 3600) {
      const minutes = Math.floor(diffInSeconds / 60);
      return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else if (diffInSeconds < 86400) {
      const hours = Math.floor(diffInSeconds / 3600);
      const minutes = Math.floor((diffInSeconds % 3600) / 60);
      return `${hours} hour${hours === 1 ? '' : 's'} ${minutes} minute${minutes === 1 ? '' : 's'} ago`;
    } else {
      const days = Math.floor(diffInSeconds / 86400);
      return `${days} day${days === 1 ? '' : 's'} ago`;
    }
  } else {
    return '';
  }
};

export const validateEmail = (email: string): boolean => {
  // email regex for validate email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateText = (text: string): boolean => {
  const textRegex = /^[a-zA-Z]+$/; // This regex allows only alphabets
  return textRegex.test(text);
};

export const validateNumber = (number: string): boolean => {
  const numberRegex = /^[0-9]+$/; // This regex allows only numbers
  return numberRegex.test(number);
};

export const validatePassword = (password: string): boolean => {
  const passwordRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d!@#$%^&*()_+]{8,}$/;
  return passwordRegex.test(password);
};

export const validateTextAndSpace = (text: string): boolean => {
  const reggex = /^[a-zA-Z\s]*$/;
  return reggex.test(text);
};

// Function to set a cookie with token and expiration time
export const setCookie = (token: string): void => {
  const decodedToken = jwtDecode(token) as JwtPayload;
  // Get the expiration time from the token's 'exp' claim
  const expirationTime = decodedToken.exp! * 1000; // Convert to milliseconds

  const cookieOptionsCross = {
    expires: new Date(expirationTime),
    path: '/',
    domain: process.env.REACT_APP_DOMAIN!, // Add domain here
  };

  Cookies.set('_ndisync_token', token, cookieOptionsCross);
};

// Function to get a cookie value token
export const getCookie = (): string | undefined => {
  return Cookies.get('_ndisync_token');
};

export const processHashtags = (text: string): string => {
  return text.replace(/(#\w+)/g, '<span class="hashtag">$1</span>');
};

// Function to process and display the text
export const displayPost = (post: { description: string }): string => {
  const processedText = processHashtags(post.description);
  return processedText;
};

export const isNotEmpty = (data: any): boolean => {
  if (Array.isArray(data)) {
    return data.length > 0;
  } else if (typeof data === 'object' && data !== null) {
    return Object.keys(data).length > 0;
  }
  return !!data; // If it's a string, check if it's non-empty
}

export const timeSince = (date: string): string => {
  const createdAt = new Date(date);
  const currentDate = new Date();
  const seconds = Math.floor((currentDate.getTime() - createdAt.getTime()) / 1000);

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return `${interval} years ago`;
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return `${interval} months ago`;
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return `${interval} days ago`;
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return `${interval} hours ago`;
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return `${interval} minutes ago`;
  }
  return `${Math.floor(seconds)} seconds ago`;
};

// Function to remove a cookie
// export const removeCookie = async (): Promise<void> => {
//   const dispatch = store.dispatch;
//   try {
//     const _token = getCookie();
//     if (_token) {
//       const response = await dispatch(logOutUser(_token));
//       if (response.payload && 'success' in response.payload && response.payload.success) {
//         Cookies.remove('_ndisync_token', { path: '/', domain: process.env.REACT_APP_DOMAIN! });
//         dispatch(userLogOut());
//         dispatch(removeToken());
//         dispatch(removeTwoFactor());
//         dispatch(resetPremiumPlans());
//         dispatch(removeUpgradeUser());
//         // dispatch(setValue(''));
//         // dispatch(setCurrentPage(1));
//         // dispatch(setNumber(0));
//         // dispatch(setData([]));
//         // dispatch(resetAllNotifications());
//         // dispatch(resetActivityPosts());
//       }
//     }
//   } catch (err) {
//     console.log('ERROR', err);
//   }
// };

type AppDispatch = ThunkDispatch<any, any, AnyAction>;

export const removeCookie = async (dispatch: AppDispatch = store.dispatch): Promise<boolean> => {
  try {
    const _token = getCookie();
    if (_token) {
      const response = await dispatch(logOutUser(_token));
      if (response.payload && 'success' in response.payload && response.payload.success) {
        Cookies.remove('_ndisync_token', { path: '/', domain: process.env.REACT_APP_DOMAIN! });
        dispatch(userLogOut());
        dispatch(removeToken());
        dispatch(removeTwoFactor());
        dispatch(resetPremiumPlans());
        dispatch(removeUpgradeUser());
        // Reset other states if needed
        dispatch(resetAllNotifications());
        dispatch(resetActivityPosts());
        return true;
      }
    }
  } catch (err) {
    console.log('ERROR', err);
  }
  return false;
};
export const logOutWithOutApi = (): void => {
  const dispatch = store.dispatch;
  Cookies.remove('_ndisync_token', { path: '/', domain: process.env.REACT_APP_DOMAIN! });
  dispatch(userLogOut());
  dispatch(removeToken());
  dispatch(removeTwoFactor());
  dispatch(resetPremiumPlans());
  dispatch(removeUpgradeUser());
  dispatch(setValue(''));
  dispatch(setCurrentPage(1));
  dispatch(setNumber(0));
  dispatch(setData([]));
  dispatch(resetAllNotifications());
  dispatch(resetActivityPosts());
};
interface DecodedToken extends JwtPayload {
  role?: string;
  userId: string;
}
// Function to decode a JWT token
export const getRole = (): DecodedToken | null => {
  try {
    const token = getCookie();
    if (token) {
      return jwtDecode<DecodedToken>(token);
    }
    return null;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};
export function capitalizeFirstLetter(string: string): string {
  if (!string) return '';
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function toSentenceCase(text: string) {
  let lowerCaseText = text.toLowerCase();
  let sentenceCaseText = lowerCaseText.replace(/(^\w)|(\.\s+\w)/g, letter => letter.toUpperCase());
  sentenceCaseText = sentenceCaseText.replace(/(\.\s+)i(\s)/g, '$1I$2');
  return sentenceCaseText;
}

export function convertTo12Hour(time: any) {
  let [hours, minutes] = time.split(":").map(Number);
  let period = hours >= 12 ? "PM" : "AM";
  hours = hours % 12 || 12; // Convert '0' or '12' to '12'
  return `${hours}:${minutes < 10 ? '0' + minutes : minutes} ${period}`;
}
