/**
 * Loginwelcomeback Component
 * 
 * This component handles password reset functionality.
 * Users enter a new password and confirm it to reset their password.
 * 
 * File Name: Loginwelcomeback.js
 * Author: Developer
 * Date: 04/04/24
 */

import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import "../../assets/css/loginpages.css";
import "../../assets/css/loginpagesresponsive.css";
import Header from "../../components/Header";

import { CircularProgress } from "@mui/material";
import { validatePassword } from "../../config/Helpers/helpers";
// import SuccessModal from "../../components/Modal/SuccessModal";
import httpRequest from "../../config/Helpers/httpRequest";
import { toast } from "react-toastify";

/**
 * Loginwelcomeback Component
 * 
 * Renders the password reset form for users who need to reset their password.
 */
function Loginwelcomeback() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);

  // Toggle password visibility for password input field
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle password visibility for confirm password input field
  const togglePasswordVisibilityTwo = () => {
    setShowPasswordTwo(!showPasswordTwo);
  };

  const [goToLogin, setGoToLogin] = useState(false);
  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [successMsg, setSuccessMsg] = useState<string | boolean>(false);
  const [isError, setIsError] = useState<string | boolean>(false);
  const [isLoader, setIsLoader] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const { state } = useLocation() || false;
  const navigate = useNavigate();

  if (goToLogin && !isSuccessModal) {
    navigate("/login-web");
  }

  // Functionality: API call reset password
  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!password) {
      setIsError("Please enter password.");
    } else if (!validatePassword(password)) {
      setIsError(
        "password should be atleast 8 characters long, 1 uppercase, 1 lowercase, 1 digits and 1 special character."
      );
    } else if (password !== confirmPassword) {
      setIsError("Password & confirm password not matched.");
    } else {
      setIsLoader(true); // show loader
      const { res, err } = await httpRequest({
        method: "post", path: "/api/user/reset-password", params: {
          email: state?.email,
          newPassword: password,
          otp: state?.otp,
        }
      });
      if (res) {
        setGoToLogin(true);
        setIsSuccessModal(true);
        setSuccessMsg("Password updated successfully.");
        toast("Password updated successfully.", { type: "success" })
        navigate("/login-web");
      } else {
        toast(err?.message, { type: "error" })
      }
      setIsLoader(false); // hide loader
    }
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }, [isError]); // error clear in after 5 seconds.

  // Redirect back if no state (email and OTP) is available
  useEffect(() => {
    if (!state) {
      navigate(-1);
    }
  }, []);

  return (
    <div>
      {/* {isSuccessModal && (
        <SuccessModal
          message={successMsg}
          setIsModal={setIsSuccessModal}
          setMsg={setSuccessMsg}
        />
      )} */}
      <section className="login-page-parent new-reg-parent relative h-screen  overflow-clip">
        <Header />
        <img
          decoding="async"
          className="w-full h-full absolute top-0 left-0 z-10  bg-texture-banner"
          src={"https://ndisync.backslashwebs.com/new-register.jpg"}

          alt=""
        />
        <div className="container-custom h-full relative z-20 flex flex-col justify-center items-center gap-14 reg-comp">
          <div className="w-full flex justify-center flex-col items-center gap-4">

            <div className="lg-welcome">
              <h4 className="font-Poppins-Medium font-size-login-36px text-white leading-none text-center lg-main-head ">
                Welcome back
              </h4>
              <p className="text-[#FFE175] font-Poppins-Regular font-size-login-29px  leading-none text-center ">
                Please change your password
              </p>
            </div>


            <div className="login-box-body-parent login-box-body-parent-2 flex flex-col">
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col gap-3 mb-3">

                  <div className="relative">
                    <input
                      className="home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px  outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      className="input-field-eye absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  <div className="relative">
                    <input
                      className="home-banner-input input-placeholder-green input-btns-height-60px w-full border-radius-12px  outline-none font-Poppins-SemiBold font-size-login-18px theme-color-green"
                      placeholder="Confirm password"
                      type={showPasswordTwo ? "text" : "password"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      icon={showPasswordTwo ? faEye : faEyeSlash}
                      className="input-field-eye absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibilityTwo}
                    />
                  </div>
                  {isError && <p style={{ color: "red" }}>{isError}</p>}
                </div>
                <div className="login-btn-home-banner-parent flex items-center justify-center">
                  <button
                    type="submit"
                    className="mt-5 w-full text-white font-size-login-18px font-Poppins-SemiBold login-page-btn flex items-center justify-center gap-2 border-radius-20px theme-bg-green-light"
                  >
                    Done            <FontAwesomeIcon size="lg" icon={faArrowRight} />
                    {isLoader && (
                      <CircularProgress
                        style={{ color: "#fff" }}
                        className="btn__loader"
                      />
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Loginwelcomeback;