import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowsRotate } from "@fortawesome/free-solid-svg-icons";
import JobCancelModal from './JobCancelModal';
import { getCookie, getRole } from "../../config/Helpers/helpers";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

interface JobReopenProps {
    jobId: string | undefined;
    cancelledBy: string | undefined;
}

const JobReopen: React.FC<JobReopenProps> = ({ jobId, cancelledBy }) => {

    let UID = getRole()?.userId;
    console.log("cancelledBy", cancelledBy, UID)
    const navigate = useNavigate();
    const token = getCookie();

    // api header
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const [isModal, setIsModal] = useState<boolean>(false); // for confirm modal open
    const [isLoader, setIsLoader] = useState<boolean>(false); // for loader

    // Functionality: API call for Job Re-open
    const handleReOpen = async () => {
        setIsLoader(true);

        try {
            // const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/manage/repostJob/${jobId}`, {}, config);
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/job/repost`, { jobId: jobId }, config);
            if (response.status == 201) {
                setIsModal(false);
                navigate("/jobs-board");
            }
        }
        catch (err: any) {
            // error handling
            toast(
                err.response?.data?.message || err.response?.data?.error || "Error",
                { type: "error" });
        }
        setIsLoader(false);
    };
    return (
        <>
            {
                (cancelledBy !== UID && cancelledBy) &&
                <button onClick={() => setIsModal(true)} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4 height-btn"><FontAwesomeIcon icon={faArrowsRotate} />Re-open job</button>
            }

            {isModal &&
                <JobCancelModal
                    onClose={() => setIsModal(false)}
                    confirmCancel={handleReOpen}
                    status="success"
                    title="Are you sure you want to Re-open this job?"
                    loader={isLoader}
                />
            }
        </>
    );
};

export default JobReopen;