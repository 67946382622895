/**
 * DisputesJob Component
 * 
 * This component is responsible for displaying and managing dispute jobs in the admin panel.
 * It allows the admin to view disputes, filter them by various criteria, and change their status.
 * 
 * @file DisputesJob.js
 * @component DisputesJob
 * @description Renders the DisputesJob component with filters, tabs, and a list of disputes.
 *              Provides functionality to fetch dispute jobs, filter by year, month, and status,
 *              and change the status of a dispute job.
 * 
 * @dependencies 
 * - React: Library for building user interfaces.
 * - react-router-dom: DOM bindings for React Router.
 * - @fortawesome/react-fontawesome: FontAwesome icons for React.
 * - @mui/material: Material-UI components.
 * - react-toastify: Notification library.
 * - qs: Query string parsing and stringifying library.
 * 
 * @author Developer
 * @date 05-03-24
 */

import React, { useState, useEffect } from "react";
import AdminHeader from "../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../components/AdminComponents/AdminSidebar";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import httpRequest from "../../config/Helpers/httpRequest";
import qs from "qs";
import moment from "moment";

interface Dispute {
  _id: string;
  firstName?: string;
  lastName?: string;
  role?: string;
  subject?: string;
  status?: string;
  createdAt?: string;
  jobId?: string;
}

interface QueryParams {
  limit: number;
  page: number;
  sort: string;
  year: string;
  month: string;
  status: string;
}

const DisputesJob: React.FC = () => {
  const navigate = useNavigate();

  let tabList = [
    { key: "", title: "All Disputes" },
    { key: "pending", title: "Pending" },
    { key: "ongoing", title: "Ongoing" },
    { key: "resolved", title: "Resolved" },
  ];

  let YEARS = [2020, 2021, 2022, 2023, 2024];

  const [totalPages, setTotalPages] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<QueryParams>({
    limit: 10,
    page: 1,
    sort: "desc",
    year: "",
    month: "",
    status: "",
  }); // query params for APIs

  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [displayDisputeJobs, setDisplayDisputeJobs] = useState<Dispute[]>([]);
  const [statusChangeLoader, setStatusChangeLoader] = useState<string | false>(false);

  // Functionality: API call for get dispute job with status (all, pending, ongoing & resolved)
  const fetchDisputes = async () => {
    setIsLoader(true);

    const { res, err } = await httpRequest<{ documents: Dispute[],paginated: {totalPages: number} }>({
      path: `/api/admin/job/dispute?${qs.stringify(queryParams)}`,
    });

    // const { res, err } = await httpRequest({ path: `/api/admin/job/dispute` });
    if (res) {
      console.log(res, "asdasdadasd")
      setDisplayDisputeJobs(res?.documents);
      // let count = res?.paginated?.totalPages || 0;
      setTotalPages(res?.paginated?.totalPages);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  const handleChangeParams = (key: keyof QueryParams, value: string | number) => {
    setQueryParams(prev => ({
      ...prev,
      page: 1,
      [key]: value
    }));
  };

  // Functionality: API call change status of dipute Job
  const handleStatusChange = async (disputeId: string, status: string) => {
    setStatusChangeLoader(disputeId);
    const payload = {
      status: status,
      disputeId: disputeId
    }
    const { res, err } = await httpRequest({ method: "put", path: `/api/admin/job/dispute/update-status `, params: { status, disputeId } });
    if (res) {
      handleChangeParams("status", status)
    } else {
      toast(err?.message, { type: "error" });
    }
    setStatusChangeLoader(false);
  };

  useEffect(() => {
    fetchDisputes();
  }, [queryParams]);

  return (
    <div>
      <div className="flex font-poppins-regular">
        <AdminSidebar />
        <div className="w-4/5 ml-auto">
          <AdminHeader />
          <div className="pt-24 Admin-main-operator-parent">
            <div className="pb-6 pl-6 pr-10">
              <h2 className="font-size-36px font-Poppins-Medium">Disputes</h2>
              <p> <span className="font-size-16px font-Poppins-Bold pb-5">Dashboard /</span> <span className="font-Poppins-Medium"> Disputes</span></p>

              {/* Main-AdminUsers */}
              <div className=" operator-main-admin my-6 flex justify-between items-center">
              <div className="dispute-header">
              {tabList.map((v, i) => (
                <button
                  key={i}
                  onClick={() => handleChangeParams("status", v.key)}
                  className={
                    queryParams.status === v.key
                      ? "font-Poppins-Regular font-size-18px active"
                      : "font-Poppins-Regular font-size-18px "
                  }
                >
                  {v.title}
                </button>
              ))}
            </div>
                <div className="flex flex-row gap-4 flex-end  justify-end w-[100%]">
                  <div className="dispute-search-main dispute-search-main-2">
                    <select
                      value={queryParams.year}
                      onChange={(e) => handleChangeParams("year", e.target.value)}
                    >
                      <option value="" selected>
                        Years
                      </option>
                      {YEARS.map((item) => (
                        <option key={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="dispute-search-main  dispute-search-main-2">
                    <select
                      value={queryParams.month}
                      onChange={(e) => handleChangeParams("month", e.target.value)}
                    >
                      <option value="" selected>
                        Months
                      </option>
                      <option value="1">January</option>
                      <option value="2">February</option>
                      <option value="3">March</option>
                      <option value="4">April</option>
                      <option value="5">May</option>
                      <option value="6">June</option>
                      <option value="7">July</option>
                      <option value="8">August</option>
                      <option value="9">September</option>
                      <option value="10">October</option>
                      <option value="11">November</option>
                      <option value="12">December</option>
                    </select>
                  </div>
                  <div className="dispute-search-main  dispute-search-main-2">
                    <select
                      value={queryParams.sort}
                      onChange={(e) => handleChangeParams("sort", e.target.value)}
                    >
                      <option value="desc">Newest to oldest</option>
                      <option value="asc">Oldest to newest</option>
                    </select>
                  </div>
                </div>
          
              </div>
              
              <div className="">
        
                <div className="tab-content">
                  <div className="main-Admin-status-action">
                    <ul
                      style={{ gridTemplateColumns: "16% 15% 24% 13% 16% 16%" }}
                      className="Admin-status-content-Role font-Poppins-SemiBold font-size-18px under-detail-border"
                    >
                      <li className="text-white">Name</li>
                      <li className="text-white">Role</li>
                      <li className="text-white">Subject</li>
                      <li className="text-white">Status</li>

                      <li className="text-white">Created date</li>
                      <li className="text-white">Action</li>
                    </ul>
                  </div>
                  {isLoader ? (
                    <div className="main-Admin-status-content mt-5 text-center">
                      <CircularProgress style={{ color: "#00443f" }} />
                    </div>
                  ) : displayDisputeJobs && displayDisputeJobs.length > 0 ? (
                    <>
                      {displayDisputeJobs.map((item, index) => (
                        <div
                          key={index}
                          className="main-Admin-status-content mt-5"
                        >
                          <ul
                            style={{
                              gridTemplateColumns: "16% 15% 24% 13% 16% 16%",
                            }}
                            className="Admin-status-content-Role font-Poppins-Regular font-size-18px under-detail-border"
                          >
                            <li className="flex justify-center">
                              <span className="capitalize">{`${item?.firstName || ""
                                } ${item?.lastName || ""}`}</span>
                            </li>
                            <li
                           
                            >
                            <span    className={`${item?.role == "participant"
                              ? "Admin-Operators-green"
                              : "Admin-support-blue-editor"
                              } op-roles font-Poppins-SemiBold`}>
                              {item?.role || "-"}</span>
                            </li>

                            <li>{item?.subject || "-"}</li>
                            <li className="flex flex-row items-center gap-2 justify-center">
                              <span
                                className={`${item?.status === "pending"
                                  ? "pending-dispute"
                                  : item?.status === "ongoing"
                                    ? "ongoing-dispute"
                                    : "resolved-dispute"
                                  }`}
                              >
                                <span></span>
                              </span>

                              <span className="capitalize">
                                {item?.status || "-"}
                              </span>
                            </li>
                            <li>{moment(item?.createdAt).format("DD-MM-YYYY") || "-"}</li>
                            <li className="relative font-size-17px flex flex-row items-center gap-2 justify-center">
                              <button
                                onClick={() =>
                                  navigate("/disputes-job-detail", {
                                    state: { jobId: item?.jobId },
                                  })
                                }
                              >
                                <FontAwesomeIcon
                                  className="dispute-eye"
                                  icon={faEye}
                                />
                              </button>
                              <div className="dispute-search-main">
                                {statusChangeLoader === item?._id ? (
                                  <CircularProgress
                                    style={{
                                      width: 24,
                                      height: 24,
                                      color: "#00443f",
                                    }}
                                  />
                                ) : (
                                  <select
                                    value={item?.status}
                                    onChange={(e) =>
                                      handleStatusChange(
                                        item?._id,
                                        e.target.value
                                      )
                                    }
                                  >
                                    <option value="pending">Pending</option>
                                    <option value="ongoing">Ongoing</option>
                                    <option value="resolved">Resolved</option>
                                  </select>
                                )}
                              </div>
                            </li>
                          </ul>
                        </div>
                      ))}
                      <PaginationComponet
                        total={totalPages}
                        setCurrentPage={(e) => handleChangeParams("page", e)}
                        currentPage={queryParams.page}
                      />
                    </>
                  ) : (
                    <div className="main-Admin-status-content mt-5">
                      <p className="text-center">No operator</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DisputesJob;
