import React, { useEffect, useRef, useState } from "react";
import { MenuItem, Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { resetAllPosts } from "../../config/Store/Reducers/newsFeedSlice";
import PostContainer from "./PostContainer";
import PostSkeleton from "../Skeleton/PostSkeleton";
import { RootState } from "../../config/Store/store";
interface QueryParams {
  page: number;
  limit: number;
  sort: string;
}

interface FeedPostViewProps {
  loader: boolean;
  queryParams: QueryParams;
  setQueryParams: React.Dispatch<React.SetStateAction<QueryParams>>;
  totalPages: number;
}

const FeedPostView: React.FC<FeedPostViewProps> = ({
  loader,
  queryParams,
  setQueryParams,
  totalPages,
}) => {

  const dispatch = useDispatch();
  const { allPosts } = useSelector((store: RootState) => store.feed);
  const controlMenuRef = useRef<HTMLDivElement>(null);
  const [feedControlMenu, setFeedControlMenu] = useState(false); // for feed control menu

  const LoaderComponents = () => {
    // full page loader
    return <PostSkeleton size={queryParams.limit} />;
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Close or hide the feed menu when clicking outside of the menu
    if (
      controlMenuRef.current &&
      !controlMenuRef.current.contains(event.target as Node)
    ) {
      setFeedControlMenu(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    if (feedControlMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [feedControlMenu]); // Add showEmojiPicker to the dependency array

  return (
    <div>
      <div className="flex justify-between items-center">
        <span className="w-full border-b-2  border-[#70707054]"></span>
        <div className="flex gap-1 recently-connections items-center py-2">
          <p
            className="font-size-15px theme-grey-type font-Poppins-Regular text-center"
            style={{ width: "4rem" }}
          >
            Sort by:
          </p>
          <div className="sortby-select">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={queryParams.sort}
            onChange={(e) => {
              dispatch(resetAllPosts());
              setQueryParams({ ...queryParams, page: 1, sort: e.target.value });
            }}
            
          >
            <MenuItem value="top">Top </MenuItem>
            <MenuItem value="recent">Recent</MenuItem>
          </Select>
          </div>
        </div>{" "}
        {/* top or recent */}
      </div>{" "}
      <InfiniteScroll
        dataLength={allPosts.length}
        next={() =>
          setQueryParams((prev) => ({ ...prev, page: prev.page + 1 }))
        }
        hasMore={queryParams.page < totalPages}
        loader={loader && <LoaderComponents />}
        endMessage={
          <p style={{ textAlign: "center" }}>No more posts to show</p>
        }
      >
        {allPosts && allPosts.length > 0
          ? allPosts.map((item: object, index: number) => (
            <PostContainer key={index} item={item} index={index} />
          ))
          : null}
      </InfiniteScroll>
      {loader && <LoaderComponents />}
    </div>
  );
};

export default FeedPostView;
