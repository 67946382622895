import React, { useState, useEffect } from 'react';

import AddComment from '../../components/PostCommentSection/AddComment';
import DisplayComments from '../../components/PostCommentSection/DisplayComments';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMessage } from "@fortawesome/free-solid-svg-icons";
import comment from "../../assets/img/comment.svg";

function ActivityCommentSection({ item = {}, data = [], setData = () => { }, category = "posts" }) {
    const [allComments, setAllComments] = useState([]); // for comment section
    const [commentInputFocus, setCommentInputFocus] = useState(false);
    const [showCommentBox, setShowCommentBox] = useState(false);

    const handleAddNewComment = (newComment) => {
        setAllComments(prev => ([newComment, ...prev]));
        updateCounter(newComment); // for update comment counter
    };

    const updateCounter = (newComment) => {
        let newList = data.map(newObj => {
            if (newObj?._id === newComment?.refId) {
                return {
                    ...newObj,
                    allComments: newObj?.allComments + 1,
                }
            }
            return newObj;
        })
        setData(newList);
    };

    const showComment = () => {
        if (category === "comments") {
            setCommentInputFocus(true);
        } else {
            setShowCommentBox(true);
        }
    };
console.log(item,"Asdassadsahdshjdasjhadsjdhas")
    useEffect(() => { // set First comment show
        if (item && item?.allComments && category === "comments") {
            setAllComments([item?.comments]);
        }
        console.log("item", item);
    }, [item?.comments]);

    return (
        <>
            <ul className="flex gap-2 py-2">
                <li className="total-like flex gap-1 items-center">
                    <FontAwesomeIcon icon={faMessage} color="#707070" />
                    <div className="font-size-15px theme-grey-type  font-Poppins-Medium" style={{ cursor: "context-menu" }}>
                        {item?.allComments || 0} comments
                    </div> {/* post comment count */}
                </li>
            </ul>
            <hr />
            <div className="flex justify-center gap-10 mt-3">
                <button onClick={showComment} className="flex items-center gap-2 cursor-pointer">
                    <img src={comment} alt="" />
                    <span className="font-size-15px font-Poppins-Medium theme-grey-type">
                        Comment
                    </span>
                </button>
            </div>

            <AddComment
                refId={item?._id}
                successFunction={handleAddNewComment}
                feedType="discussion"
                isFocus={commentInputFocus}
                setIsFocus={setCommentInputFocus}
            />
            <DisplayComments
                refId={item?._id}
                feedType="discussion"
                allComments={allComments}
                setAllComments={setAllComments}
                activityPage={true}
                totalComments={item?.allComments}
                updateActivityCounter={updateCounter}
                isShow={showCommentBox}
            />
        </>
    );
};

export default ActivityCommentSection;