import React, { useState } from 'react';

import JobReviewModal from "../JobsComponents/JobReviewModal";
interface EditReviewProps {
    extraStyle?: React.CSSProperties;
    buttonClass?: string;
    userData?: any; // Replace `any` with the appropriate type if known
    rating: number; // Define rating as a number
    message: string;
    jobId: string;
    onSuccessFunctions?: () => void;
  }
  
  const EditReview: React.FC<EditReviewProps> = ({
    extraStyle = {},
    buttonClass = "",
    userData = {},
    rating = 0, // Default value of rating is set to 0
    message = "",
    jobId = "",
    onSuccessFunctions = () => { }
  }) => {
// function EditReview({ extraStyle = {}, buttonClass = "", userData = {}, rating = "", message = "", jobId = "", onSuccessFunctions = () => { } }) {
    const [editReview, setEditReview] = useState<{ rating: number; message: string } | null>(null);

    const handleEdit = () => {
        let obj = { rating, message };
        setEditReview(obj);
    }
    return (
        <>
            <button
                style={extraStyle}
                className={buttonClass}
                onClick={handleEdit}
            >Edit</button>

            {editReview &&
                <JobReviewModal
                    onCLose={() => setEditReview(null)}
                    isEdit={editReview}
                    user={userData}
                    jobId={jobId}
                    successFunction={onSuccessFunctions}
                />
            }
        </>
    );
};

export default EditReview;