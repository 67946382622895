import React from 'react'
import url from "../config/url";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// import ndisailogo from "../assets/img/ndisailogo.svg";
import ndisailogo from "../assets/img/ndis-logo-new.png";
function Header() {
  const location = useLocation();
  const { pathname } = location;
  const ndisheaderlogo = "imgs/ndisheaderlogo.png"
  return (

    <header className="head-control ">

      <div className="container-custom ">
        <div className={`header-main justify-center ${pathname !== "/free-register" && pathname !== "/login-web" && pathname !== "/free-register" && pathname !== "/recovery-password" && pathname !== "/recovery-verification" && pathname !== "/confirm-password" && pathname !== "/admin-login" ? "theme-bg-green" : ""}`}>
          <ul>
            <li>
              <Link to={`${process.env.REACT_APP_BASE_WEBSITE}`}>
                <img
                  className="ndis-logo"
                  src={ndisailogo}
                  alt=""
                />
              </Link>
            </li>
          </ul>
          {/* <ul className="inner-header-detail">
            <li className="font-AltoneTrial-Regular font-size-18px">About</li>
            <li className="font-AltoneTrial-Regular font-size-18px">
              How it works
            </li>
            <li className="font-AltoneTrial-Regular font-size-18px">
              Features
            </li>
            <li className="font-AltoneTrial-Regular font-size-18px">
              Pricing
            </li>
          </ul>
          <div className="sign-btn-handler">
            <Link to="/login-web" className="sign-btn font-size-18px font-Poppins-SemiBold opacity-0 ">
              Sign In
            </Link>
          </div> */}
        </div>
      </div>
    </header>
  )
}

export default Header


