// import React, { useState } from "react";
// import { usePopper } from 'react-popper';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   jobStartDate,
//   calculateAge,
//   formatDate,
//   getTimeDifference,
// } from "../../config/Helpers/helpers";
// import {
//   faCalendar,
//   faLocationDot,
//   faBriefcase,
//   faMagnifyingGlass,
//   faVenus,
//   faClock,
//   faBars,
// } from "@fortawesome/free-solid-svg-icons";
// function AdminSchedularBody({
//   view,
//   weeks,
//   value,
//   onChange,
//   data,
//   jobSide,
//   start,
//   end,
// }) {
//   const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
//   const [isHovered, setIsHovered] = useState(false);

//   const handleHover = (value) => {
//     setIsHovered(value);
//   };

//   const checkPreferences = (user) => {
//     if (!user) return "-"; // Return an empty string if preferences are not provided

//     const {
//       preferencemale,
//       preferencefemale,
//       preferencenopet,
//       preferencenonsmoker,
//     } = user;

//     // Check for specific combinations and return the corresponding HTML string
//     if (
//       !preferencemale &&
//       !preferencefemale &&
//       !preferencenopet &&
//       !preferencenonsmoker
//     ) {
//       return "-";
//     } else {
//       let result = "";

//       if (preferencemale && preferencefemale) {
//         result += "Male & Female";
//       } else if (preferencemale) {
//         result += "Only Male";
//       } else if (preferencefemale) {
//         result += "Only Female";
//       }

//       if (preferencenopet) {
//         if (result !== "") result += ", ";
//         result += "No Pets";
//       }

//       if (preferencenonsmoker) {
//         if (result !== "") result += ", ";
//         result += "Non Smoker";
//       }
//       return result;
//     }
//   };

//   const createColumns = (items) => {
//     const columns = [];
//     let currentDate = new Date(start); // Start date
//     const endDate = new Date(end); // End date

//     // Loop until currentDate is less than or equal to endDate
//     while (currentDate <= endDate) {
//       // Find the first item where currentDate matches the startDate
//       const matchingItem = items.find((item) => {
//         const startDate = new Date(item.startDate);
//         return (
//           currentDate.getDate() === startDate.getDate() &&
//           currentDate.getMonth() === startDate.getMonth() &&
//           currentDate.getFullYear() === startDate.getFullYear()
//         );
//       });

//       // If a matching item is found, include it in the columns
//       if (matchingItem) {
//         columns.push(
//           <td key={`td-${currentDate.getTime()}`}>
//             <div
//               className={`event ${
//                 matchingItem?.status === "cancelled"
//                   ? "cancelled-Box"
//                   : matchingItem?.status === "onboard"
//                   ? "Active-Box"
//                   : "Complete-Box"
//               } ${isHovered ? "hovered-class" : ""}`}
//               onMouseEnter={() => handleHover(true)}
//               onMouseLeave={() => handleHover(false)}
//             >
//               <div className="Shedular-main">
//                 <div className="UserName-calen">
//                   <div>
//                     <h6 className="capitalize">
//                       {jobSide === "creator" ? (
//                         <>
//                           {matchingItem?.applicantDetails?.firstName || ""}{" "}
//                           {matchingItem?.applicantDetails?.lastName || ""}
//                         </>
//                       ) : (
//                         <>
//                           {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
//                           {matchingItem?.jobCreatorDetails?.lastName || ""}
//                         </>
//                       )}
//                     </h6>
//                   </div>
//                   <div>
//                     <span className="job__status">
//                       {matchingItem?.status === "onboard"
//                         ? "Active"
//                         : matchingItem?.status}
//                     </span>
//                   </div>
//                 </div>
//                 <p>{`${matchingItem?.startTime || ""} : ${
//                   matchingItem?.endTime || ""
//                 }`}</p>
//                 <div className="UserName-calen2">
//                   <p>{matchingItem?.allServices[0]?.servicename || ""}</p>
//                   <p>${matchingItem?.budget || 0}</p>
//                 </div>
//               </div>
//               <div className="onhover-schd onhover-schd-clen">
//                 <div className="admin-job-preview-main-calender ">
//                   <div className="upper-schd-box">
//                     <div className="og-job-date flex items-center gap-2">
//                       <FontAwesomeIcon icon={faBriefcase} />
//                       <span className="font-Poppins-SemiBold font-size-16px">{` ${
//                         jobStartDate(matchingItem?.startDate) || ""
//                       }`}</span>
//                     </div>
//                     <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
//                       Use Filters to widen or narrow your Search Result
//                     </p>
//                   </div>
//                   <div className="admin-preview-job mt-2">
//                     <div className="">
//                       <h2 className="font-Poppins-SemiBold font-size-18px text-[#00443f]">
//                         {matchingItem?.allServices[0]?.servicename || ""}
//                       </h2>
//                       <p className="font-Poppins-Medium font-size-15px text-[#646464]">
//                         {jobSide === "contractor" ? (
//                           <>
//                             {matchingItem?.applicantDetails?.firstName || ""}{" "}
//                             {matchingItem?.applicantDetails?.lastName || ""}
//                           </>
//                         ) : (
//                           <>
//                             {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
//                             {matchingItem?.jobCreatorDetails?.lastName || ""}
//                           </>
//                         )}
//                       </p>
//                       <p className="font-Poppins-Medium font-size-15px text-[#646464]">
//                         {jobSide === "contractor"
//                           ? calculateAge(
//                               matchingItem?.applicantDetails?.dateofbirth
//                             )
//                           : calculateAge(
//                               matchingItem?.jobCreatorDetails?.dateofbirth
//                             )}
//                         Year old
//                       </p>
//                       <p className="font-Poppins-Medium font-size-15px text-[#646464] capitalize">
//                         {jobSide === "contractor"
//                           ? matchingItem?.applicantDetails?.gender
//                           : matchingItem?.jobCreatorDetails?.gender}
//                       </p>
//                     </div>
//                   </div>
//                   <div className="underline-gray-1"></div>

//                   <div className="  text-[#646464] sch-box font-awesome-color">
//                     <div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faLocationDot} />
//                         <p>{`${matchingitem?.suburb || ""}, ${
//                           matchingItem?.state || ""
//                         }, ${matchingItem?.postCode || ""}`}</p>
//                       </div>
//                       {/* <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                                                 <FontAwesomeIcon icon={faBars} />
//                                                 <p>Light housework</p>
//                                             </div> */}
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon className="" icon={faVenus} />
//                         <p>
//                           {jobSide === "contractor"
//                             ? checkPreferences(matchingItem?.applicantDetails)
//                             : checkPreferences(matchingItem?.jobCreatorDetails)}
//                         </p>
//                       </div>
//                     </div>

//                     <div className="font-awesome-color">
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faClock} />
//                         <p>
//                           {matchingItem?.repeatCycle == 1
//                             ? "One-off"
//                             : matchingItem?.repeatCycle == 2
//                             ? "Daily"
//                             : matchingItem?.repeatCycle == 3
//                             ? "Weekly"
//                             : matchingItem?.repeatCycle == 4
//                             ? "Monthly"
//                             : "Custom"}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faCalendar} />
//                         <p>
//                           Start date:{" "}
//                           {formatDate(matchingItem?.startDate) || ""}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <FontAwesomeIcon icon={faCalendar} />
//                         <p>
//                           End date: {formatDate(matchingItem?.endDate) || ""}
//                         </p>
//                       </div>
//                     </div>
//                   </div>
//                   <div className="underline-gray-1"></div>

//                   <div className="  text-[#646464]  sch-box">
//                     <div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           Posted by{" "}
//                           <span className="font-Poppins-SemiBold font-size-12px text-[#00443f]">
//                             {" "}
//                             {`${
//                               matchingItem?.jobCreatorDetails?.firstName || ""
//                             } ${
//                               matchingItem?.jobCreatorDetails?.lastName || ""
//                             }`}
//                           </span>
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           {matchingItem?.jobCreatorRole || ""}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           {getTimeDifference(matchingItem?.createdAt) || ""}
//                         </p>
//                       </div>
//                     </div>

//                     <div className="">
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           Assigned to{" "}
//                         </p>
//                         <span className="font-Poppins-SemiBold font-size-12px text-[#00443f]">
//                           {" "}
//                           {`${
//                             matchingItem?.applicantDetails?.firstName || ""
//                           } day${
//                             matchingItem?.applicantDetails?.lastName || ""
//                           }`}
//                         </span>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         <p className="font-Poppins-Medium font-size-15px">
//                           {matchingItem?.applicantDetails?.roleCategory ||
//                             ""}
//                         </p>
//                       </div>
//                       <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
//                         {/* <p className="font-Poppins-Medium font-size-15px">5 days ago</p> */}
//                       </div>
//                     </div>
//                   </div>

//                   <div className="theme-grey-type ">
//                     <div className="flex mt-2">
//                       <div className=" " style={{ backgroundColor: "#ffffff" }}>
//                         <h2 className="font-Poppins-SemiBold text-[#0c0c0c] font-size-15px">
//                           Service details
//                         </h2>
//                         {/* <div className="flex items-center gap-2 font-Poppins-Regular font-size-16px p-4">
//                                                     <FontAwesomeIcon size="2xl" icon={faBars} />
//                                                     <p>Light housework</p>
//                                                 </div> */}
//                       </div>
//                     </div>
//                     <div className="">
//                       <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
//                         {matchingItem?.description || ""}
//                       </p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </td>
//         );
//       } else {
//         // If no matching item is found, render an empty <td>
//         columns.push(<td key={`td-${currentDate.getTime()}`}></td>);
//       }

//       // Increment currentDate by 1 day
//       currentDate.setDate(currentDate.getDate() + 1);
//     }
//     return columns;
//   };

//   return (
//     <>
//       <div className="calendar">
//         <table>
//           <thead>
//             <tr>
//               <th>
//                 <div className="flex flex-row gap-2 items-center justify-center text-center calender-search">
//                   <FontAwesomeIcon
//                     icon={faMagnifyingGlass}
//                     className="clender-search"
//                   />
//                   <input
//                     type="search"
//                     placeholder="Search by name"
//                     className="clender-search font-size-12px font-Poppins-SemiBold"
//                     value={value}
//                     onChange={(e) => onChange(e.target.value)}
//                   />
//                 </div>
//               </th>
//               {view === "monthly" &&
//                 weeks[0].events.map((day, dayIndex) => (
//                   <th key={dayIndex}>{day.date.getDate()}</th>
//                 ))}
//               {view === "weekly" &&
//                 weeks[1].events.map((day, index) => (
//                   <th key={index}>
//                     {dayNames[day.date.getDay()] + " " + day.date.getDate()}
//                   </th>
//                 ))}
//               {view === "daily" && (
//                 <React.Fragment>
//                   {weeks.hoursInDay.map((hour, index) => (
//                     <th key={index} style={{ width: "60px" }}>
//                       {hour.toString().padStart(2, "0")}:00
//                     </th>
//                   ))}
//                 </React.Fragment>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {view !== "daily" &&
//               data.map((item, index) => (
//                 <tr key={index}>
//                   <td>
//                     <div className="flex flex-row items-center gap-2">
//                       <div>
//                         <img
//                           className="cl-img"
//                           src={
//                             jobSide === "contractor"
//                               ? `${process.env.REACT_APP_BASE_URL}${item[0]?.applicantDetails?.profileImageUrl}`
//                               : `${process.env.REACT_APP_BASE_URL}${item[0]?.jobCreatorDetails?.profileImageUrl}`
//                           }
//                         />
//                       </div>
//                       <div className="flex flex-col">
//                         <span className="font-size-15px font-Poppins-SemiBold cl-main-name capitalize">
//                           {jobSide === "contractor" ? (
//                             <>
//                               {item[0]?.applicantDetails?.firstName || ""}{" "}
//                               {item[0]?.applicantDetails?.lastName || ""}
//                             </>
//                           ) : (
//                             <>
//                               {item[0]?.jobCreatorDetails?.firstName || ""}{" "}
//                               {item[0]?.jobCreatorDetails?.lastName || ""}
//                             </>
//                           )}
//                         </span>
//                         <span className="font-size-9px font-Poppins-Regular capitalize">
//                           {jobSide === "contractor"
//                             ? item[0]?.applicantDetails?.roleCategory
//                             : item[0]?.jobCreatorRole}
//                         </span>
//                       </div>
//                     </div>
//                   </td>
//                   {createColumns(item)}
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>
//     </>
//   );
// }

// export default AdminSchedularBody;
import React, { useState } from "react";
import { usePopper } from 'react-popper';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  jobStartDate,
  calculateAge,
  formatDate,
  getTimeDifference,
  convertTo12Hour,
} from "../../config/Helpers/helpers";
import {
  faCalendar,
  faLocationDot,
  faBriefcase,
  faMagnifyingGlass,
  faVenus,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

function AdminSchedularBody({
  view,
  weeks,
  value,
  onChange,
  data,
  jobSide,
  start,
  end,
}) {
  const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const [tooltip, setTooltip] = useState({ show: false, content: null, reference: null });

  const handleMouseEnter = (e, matchingItem) => {
    setTooltip({ show: true, content: matchingItem, reference: e.currentTarget });
  };

  const handleMouseLeave = () => {
    setTooltip({ show: false, content: null, reference: null });
  };

  const checkPreferences = (user) => {
    if (!user) return "-";

    const {
      preferencemale,
      preferencefemale,
      preferencenopet,
      preferencenonsmoker,
    } = user;

    if (
      !preferencemale &&
      !preferencefemale &&
      !preferencenopet &&
      !preferencenonsmoker
    ) {
      return "-";
    } else {
      let result = "";

      if (preferencemale && preferencefemale) {
        result += "Male & Female";
      } else if (preferencemale) {
        result += "Only Male";
      } else if (preferencefemale) {
        result += "Only Female";
      }

      if (preferencenopet) {
        if (result !== "") result += ", ";
        result += "No Pets";
      }

      if (preferencenonsmoker) {
        if (result !== "") result += ", ";
        result += "Non Smoker";
      }
      return result;
    }
  };

  const createColumns = (items) => {
    const columns = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      const matchingItem = items.find((item) => {
        const startDate = new Date(item.startDate);
        return (
          currentDate.getDate() === startDate.getDate() &&
          currentDate.getMonth() === startDate.getMonth() &&
          currentDate.getFullYear() === startDate.getFullYear()
        );
      });

      if (matchingItem) {
        columns.push(
          <td key={`td-${currentDate.getTime()}`}>
            <div
              // className={`event ${matchingItem?.status === "cancelled" ? "cancelled-Box" : matchingItem?.status === "onboard" ? "Active-Box" : "Complete-Box"}`}
              className={`event ${matchingItem?.status === "cancelled"
                ? "cancelled-Box"
                : matchingItem?.status === "onboard"
                  ? "Active-Box"
                  : matchingItem?.status === "completed"
                    ? "Compelete-Box"
                    : matchingItem?.status === "disputed"
                      ? "Disputed-Box"
                      : ""}`}


              onMouseEnter={(e) => handleMouseEnter(e, matchingItem)}
              onMouseLeave={handleMouseLeave}
            >
              <div className="Shedular-main">
                <div className="UserName-calen">
                  <div>
                    <h6 className="capitalize">
                      {jobSide === "creator" ? (
                        <>
                          {matchingItem?.applicantDetails?.firstName || ""}{" "}
                          {matchingItem?.applicantDetails?.lastName || ""}
                        </>
                      ) : (
                        <>
                          {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
                          {matchingItem?.jobCreatorDetails?.lastName || ""}
                        </>
                      )}
                    </h6>
                  </div>
                  <div>
                    <span className="job__status">
                      {matchingItem?.status === "onboard"
                        ? "Active"
                        : matchingItem?.status}
                    </span>
                  </div>
                </div>
                <p>{`${convertTo12Hour(matchingItem?.startTime) || ""} : ${convertTo12Hour(matchingItem?.endTime) || ""}`}</p>
                <div className="UserName-calen2">
                  <p>{matchingItem?.service?.servicename || ""}</p>
                  <p>${matchingItem?.budget || 0}</p>
                </div>
              </div>
              {tooltip.show && tooltip.reference === tooltip.reference && (
                <TooltipPopper
                  referenceElement={tooltip.reference}
                  matchingItem={tooltip.content}
                  jobSide={jobSide}
                />
              )}
            </div>
          </td>
        );
      } else {
        columns.push(<td key={`td-${currentDate.getTime()}`}></td>);
      }

      currentDate.setDate(currentDate.getDate() + 1);
    }
    return columns;
  };

  return (
    <>
      <div className="calendar">
        <table>
          <thead>
            <tr>
              <th>
                <div className="flex flex-row gap-2 items-center justify-center text-center calender-search">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="clender-search"
                  />
                  <input
                    type="search"
                    placeholder="Search by name"
                    className="clender-search font-size-12px font-Poppins-SemiBold"
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                  />
                </div>
              </th>
              {view === "monthly" &&
                weeks[0].events.map((day, dayIndex) => (
                  <th key={dayIndex}>{day.date.getDate()}</th>
                ))}
              {view === "weekly" &&
                weeks[1].events.map((day, index) => (
                  <th key={index}>
                    {dayNames[day.date.getDay()] + " " + day.date.getDate()}
                  </th>
                ))}
              {view === "daily" && (
                <React.Fragment>
                  {weeks.hoursInDay.map((hour, index) => (
                    <th key={index} style={{ width: "60px" }}>
                      {hour.toString().padStart(2, "0")}:00
                    </th>
                  ))}
                </React.Fragment>
              )}
            </tr>
          </thead>
          <tbody>
            {view !== "daily" &&
              data.map((item, index) => (
                <tr key={index}>
                  <td>
                    <div className="flex flex-row items-center gap-2">
                      <div>
                        <img
                          className="cl-img"
                          src={
                            jobSide === "contractor"
                              ? `${item?.applicantDetails?.profileImageUrl}`
                              // ? `${process.env.REACT_APP_BASE_URL_IMAGE}${item?.applicantDetails?.profileImageUrl}`
                              : `${item?.jobCreatorDetails?.profileImageUrl}`
                            // : `${process.env.REACT_APP_BASE_URL_IMAGE}${item?.jobCreatorDetails?.profileImageUrl}`
                          }
                        />
                      </div>
                      <div className="flex flex-col">
                        <span className="font-size-15px font-Poppins-SemiBold cl-main-name capitalize">
                          {jobSide === "contractor" ? (
                            <>
                              {item?.applicantDetails?.firstName || ""}{" "}
                              {item?.applicantDetails?.lastName || ""}
                            </>
                          ) : (
                            <>
                              {item?.jobCreatorDetails?.firstName || ""}{" "}
                              {item?.jobCreatorDetails?.lastName || ""}
                            </>
                          )}
                        </span>
                        <span className="font-size-9px font-Poppins-Regular capitalize-first-letter">
                          {jobSide === "contractor"
                            ? item?.applicantDetails?.roleCategory
                            : item?.jobCreatorRole}
                        </span>
                      </div>
                    </div>
                  </td>
                  {createColumns(item)}
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default AdminSchedularBody;

const TooltipPopper = ({ referenceElement, matchingItem, jobSide }) => {
  const [popperElement, setPopperElement] = useState(null);
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'auto',
    modifiers: [
      {
        name: 'flip',
        options: {
          fallbackPlacements: ['top', 'right', 'bottom', 'left'],
        },
      },
      {
        name: 'preventOverflow',
        options: {
          boundary: 'viewport',
          padding: 10,
        },
      },
      {
        name: 'offset',
        options: {
          offset: [220, 0],
        },
      },
    ],
  });

  const checkPreferences = (user) => {
    if (!user) return "-";

    const {
      preferencemale,
      preferencefemale,
      preferencenopet,
      preferencenonsmoker,
    } = user;

    if (
      !preferencemale &&
      !preferencefemale &&
      !preferencenopet &&
      !preferencenonsmoker
    ) {
      return "-";
    } else {
      let result = "";

      if (preferencemale && preferencefemale) {
        result += "Male & Female";
      } else if (preferencemale) {
        result += "Only Male";
      } else if (preferencefemale) {
        result += "Only Female";
      }

      if (preferencenopet) {
        if (result !== "") result += ", ";
        result += "No Pets";
      }

      if (preferencenonsmoker) {
        if (result !== "") result += ", ";
        result += "Non Smoker";
      }
      return result;
    }
  };

  return (
    <div
      ref={setPopperElement}
      style={{ ...styles.popper, zIndex: 9999 }}
      {...attributes.popper}
      className="onhover-schd onhover-schd-clen"
    >
      <div className="admin-job-preview-main-calender ">
        <div className="upper-schd-box">
          <div className="og-job-date flex items-center gap-2">
            <FontAwesomeIcon icon={faBriefcase} />
            <span className="font-Poppins-SemiBold font-size-16px">{`This job ${jobStartDate(matchingItem?.startDate) || ""
              }`}</span>
          </div>
          <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
            Use filters to widen or narrow your Search Result
          </p>
        </div>
        <div className="admin-preview-job mt-2">
          <div className="">
            <h2 className="font-Poppins-SemiBold font-size-18px text-[#00443f] text-left">
              {matchingItem?.service?.servicename || ""}
            </h2>
            <p className="font-Poppins-Medium font-size-15px text-[#646464] capitalize">
              {jobSide === "contractor" ? (
                <>
                  {matchingItem?.applicantDetails?.firstName || ""}{" "}
                  {matchingItem?.applicantDetails?.lastName || ""}
                </>
              ) : (
                <>
                  {matchingItem?.jobCreatorDetails?.firstName || ""}{" "}
                  {matchingItem?.jobCreatorDetails?.lastName || ""}
                </>
              )}
            </p>
            <p className="font-Poppins-Medium font-size-15px text-[#646464]">
              {jobSide === "contractor"
                ? calculateAge(
                  matchingItem?.applicantDetails?.dateofbirth
                )
                : calculateAge(
                  matchingItem?.jobCreatorDetails?.dateofbirth
                )} Year old

            </p>
            <p className="font-Poppins-Medium font-size-15px text-[#646464] capitalize">
              {jobSide === "contractor"
                ? matchingItem?.applicantDetails?.gender
                : matchingItem?.jobCreatorDetails?.gender}
            </p>
          </div>
        </div>
        <div className="underline-gray-1"></div>

        <div className="  text-[#646464] sch-box font-awesome-color">
          <div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faLocationDot} />
              <p>{`${matchingItem?.suburb || ""}, ${matchingItem?.state || ""
                }, ${matchingItem?.postCode || ""}`}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon className="" icon={faVenus} />
              <p>
                {jobSide === "contractor"
                  ? checkPreferences(matchingItem?.applicantDetails)
                  : checkPreferences(matchingItem?.jobCreatorDetails)}
              </p>
            </div>
          </div>

          <div className="font-awesome-color">
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faClock} />
              <p>
                {matchingItem?.repeatCycle == 1
                  ? "One-off"
                  : matchingItem?.repeatCycle == 2
                    ? "Daily"
                    : matchingItem?.repeatCycle == 3
                      ? "Weekly"
                      : matchingItem?.repeatCycle == 4
                        ? "Monthly"
                        : "Custom"}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>
                Start date:{" "}
                {formatDate(matchingItem?.startDate) || ""}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>
                End date: {formatDate(matchingItem?.endDate) || ""}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>Start time: {convertTo12Hour((matchingItem?.startTime || ""))}</p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <FontAwesomeIcon icon={faCalendar} />
              <p>End time: {convertTo12Hour((matchingItem?.endTime || ""))}</p>
            </div>
          </div>
        </div>
        <div className="underline-gray-1"></div>

        <div className="  text-[#646464]  sch-box">
          <div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px">
                Posted by{" "}
                <span className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">
                  {" "}
                  {`${matchingItem?.jobCreatorDetails?.firstName || ""
                    } ${matchingItem?.jobCreatorDetails?.lastName || ""
                    }`}
                </span>
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px">
                {matchingItem?.jobCreatorRole || ""}
              </p>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px">
                {getTimeDifference(matchingItem?.createdAt) || ""}
              </p>
            </div>
          </div>

          <div className="">
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px">
                Assigned to{" "}
              </p>
              <span className="font-Poppins-SemiBold font-size-12px text-[#00443f] capitalize">
                {" "}
                {`${matchingItem?.applicantDetails?.firstName || ""
                  } ${matchingItem?.applicantDetails?.lastName || ""
                  }`}
              </span>
            </div>
            <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
              <p className="font-Poppins-Medium font-size-15px capitalize-first-letter">
                {matchingItem?.applicantDetails?.roleCategory ||
                  ""}
              </p>
            </div>
          </div>
        </div>

        <div className="theme-grey-type ">
          <div className="flex mt-2">
            <div className=" " style={{ backgroundColor: "#ffffff" }}>
              <h2 className="font-Poppins-SemiBold text-[#0c0c0c] font-size-15px">
                Service details
              </h2>
            </div>
          </div>
          <div className="">
            <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
              {matchingItem?.description || ""}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
