import React, { useState,useEffect } from 'react';
import ApplyJobModal from './ApplyJobModal';
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
import UnpaidModal from '../Modal/UnpaidModal';
interface ApplyJobsButtonProps {
    id: string;
    successFunction: () => void;
}
interface ApiResponse {
    message: string;
    [key: string]: any
}
const ApplyJobsButton: React.FC<ApplyJobsButtonProps> = ({ id, successFunction }) => {

    const [applyId, setApplyId] = useState<string | null>(null);
    const [applyModal, setApplyModal] = useState<boolean>(false);
    const [applyLoader, setApplyLoader] = useState<boolean>(false);
    const [applyMessage, setApplyMessage] = useState<string>("");
    const [applyFiles, setApplyFiles] = useState<File[]>([]);
    const [unpaidModalShow, setUnpaidModalShow] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const setJobApplyId = (_id: string) => {
        setApplyModal(true);
        setApplyId(_id);
    };

    // Functionality: API call for Apply jobs (files & messages)
    const handleJobApply = async () => {
        if (!applyMessage) {
            toast("Please type message", { type: "error" });
        }
        // else if (applyFiles.length < 1) {
        //     toast("Please select atleast 1 file", { type: "error" });
        // }
        else {
            setApplyLoader(true);
            const formData = new FormData();
            // formData.append('jobId', applyId);
            formData.append('message', applyMessage);
            applyFiles.forEach(file => {
                formData.append('files', file);
            });
            const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: `/api/job/apply/${applyId}`, params: formData, header: { "Content-Type": "multipart/form-data" } });
            if (res) {
                setApplyId(null);
                setApplyModal(false);
                setApplyMessage("");
                setApplyFiles([]);
                toast(res?.message || "Success", { type: "success" });
                successFunction();
            } else {

                if (err?.status === 402) {
                    setUnpaidModalShow(true)
                    setError(err?.message)

                }
                toast(err?.message, { type: "error" }); // api error
            }
            setApplyLoader(false);
        }
    };



    return (
        <>
            <button className='font-size-15px font-Poppins-Regular jobs-apply-btn active mr-2 flex flex-row items-center gap-2' onClick={() => setJobApplyId(id)}>Apply</button >
            {applyModal &&
                <ApplyJobModal
                    loader={applyLoader}
                    applyMessage={applyMessage}
                    setApplyMessage={setApplyMessage}
                    applyFiles={applyFiles}
                    setApplyFiles={setApplyFiles}
                    hideModal={setApplyModal}
                    setApplyId={() => { }}
                    submit={handleJobApply}
                />
            }
            {unpaidModalShow && <UnpaidModal  profile={false} error={error || ""} onClose={() => setUnpaidModalShow(false)} />}

        </>
    );
};

export default ApplyJobsButton;