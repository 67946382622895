import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../components/AllDashboardPagesComponents/SidebarGobal";
import RightSidebarFeed from "../../components/NewsFeeds/RightSidebarFeed";
import FeedHeader from "../../components/NewsFeeds/FeedHeader";
import FeedPostView from "../../components/NewsFeeds/FeedPostView";

import { toast } from "react-toastify";
import qs from "qs";
import { useDispatch } from "react-redux";
import {
  resetAllPosts,
  setAllPosts,
} from "../../config/Store/Reducers/newsFeedSlice";
import httpRequest from "../../config/Helpers/httpRequest";
interface Attachment {
  type: string;
  url: string;
}

interface Document {
  _id: string;
  attachments: Attachment[];
  caption: string;
  privacy: string;
  userId: string;
  reportId: string | null;
  status: string;
  createdAt: string;
  updatedAt: string;
  promotionId?: string;

  __v: number;
  [key: string]: any;
}
interface paginated {
  totalPages: number;
}

interface ApiResponse {
  documents: Document[];
  promotions: Document[];
  paginated: paginated;
}
// This is the main component for the News Feed page. It fetches and displays posts from the API.
const NewsFeed: React.FC = () => {
  // Get the dispatch function from Redux
  const dispatch = useDispatch();

  // State variables to manage the loading state of the page and pagination
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [totalPages, setTotalPages] = useState<number>(1);

  // State variable to manage the query parameters for the API calls
  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10,
    sort: "top",
  });

  // Functionality: API call to get the news feed posts
  const fetchNewsFeed = async () => {
    setIsLoader(true); // Set the loading state to true
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/posts/all?${qs.stringify(queryParams)}`,
    });
    if (res) {
      // dispatch(setAllPosts(res?.documents || []));
      let combinedPosts = [...(res?.documents || []), ...(res?.promotions || [])];
      combinedPosts = combinedPosts.sort((a, b) => {
        if (a.promotionId && !b.promotionId) return -1;
        if (!a.promotionId && b.promotionId) return 1;
        return 0;
      });

      // Dispatch the sorted posts
      dispatch(setAllPosts(combinedPosts));
      // Update the total pages for pagination or infinite scrolling
      setTotalPages(res?.paginated?.totalPages || 1);
    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false); // Set the loading state to false
  };

  // Fetch the news feed when the query parameters change
  useEffect(() => {
    fetchNewsFeed();
  }, [queryParams]);

  // Reset the Redux store when the component mounts
  useEffect(() => {
    dispatch(resetAllPosts());
  }, []);

  return (
    <div className="NewsFeed-sec">
      {/* Render the header */}
      <HeaderGlobal />
      <div className="container-1480 flex justify-between h-3vw sm-container ">
        {/* Render the sidebar */}
        <SidebarGobal />
        <div className="news-feed-post">
          {/* Render the header for creating a new post */}
          <FeedHeader />
          {/* Render the component to display the posts */}

          <FeedPostView
            loader={isLoader}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            totalPages={totalPages}
          />
        </div>
        {/* Render the right sidebar */}
        <RightSidebarFeed />
      </div>
    </div>
  );
};

export default NewsFeed;