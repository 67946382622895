import React, { useState, useEffect, useRef } from "react";

import {
  Elements,
  useStripe,
  useElements,
  CardCvcElement,
  CardNumberElement,
  CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { getCookie, removeCookie } from "../../config/Helpers/helpers";
import { useSelector, useDispatch } from "react-redux";
import { removeUpgradeUser } from "../../config/Store/Reducers/authSlice";
import { RootState } from "../../config/Store/store";
import PymentSuccess from "../../components/PaymentFormComponents/Paymentsuccess";
import { setPersonalProfile } from "../../config/Store/Reducers/userProfile";

// const stripePromise = loadStripe(process.env.REACT_APP_VITE_STRIPE_PK);

let stripePromise: Promise<Stripe | null> | null = null;
if (navigator.onLine) {
  stripePromise = loadStripe(process.env.REACT_APP_VITE_STRIPE_PK);
} else {
  stripePromise = null; // No stripe if offline
}


interface StripecheckProps {
  secretKey: string;
  nextStep: () => void;
  subscriptionId?: string;
  setSubscriptionId: (id: string) => void;
}

const Stripecheck: React.FC<StripecheckProps> = ({
  secretKey,
  nextStep,
  subscriptionId = "",
  setSubscriptionId,
}) => {
  return (
    <div>

      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm
            secretKey={secretKey}
            nextStep={nextStep}
            subscriptionId={subscriptionId}
            setSubscriptionId={setSubscriptionId}
          />
        </Elements>
      )}
    </div>
  );
}

interface CheckoutFormProps {
  secretKey: string;
  nextStep: () => void;
  subscriptionId: string;
  setSubscriptionId: (id: string) => void;
}
interface ApiResponse {
  clientSecret: string;
  subscriptionId: string;
  [key: string]: any; // Allow additional properties
}

const CheckoutForm: React.FC<CheckoutFormProps> = ({
  secretKey,
  nextStep,
  subscriptionId,
  setSubscriptionId,
}) => {
  const dispatch = useDispatch();
  const { premium, upgrade } = useSelector((store: RootState) => store.auth);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [fullPageLoader, setFullPageLoader] = useState<boolean>(false);
  const [isError, setIsError] = useState<string | null>(null);
  const [logedInSecret, setLogedInSecret] = useState<string | boolean>(false);
  const [paymentSuccessModal, setPaymentSuccessModal] = useState<boolean>(false)
  const hasFetched = useRef(false);  // Persistent flag
  const navigate = useNavigate();

  const [hasInternet, setHasInternet] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnlineStatus = () => setHasInternet(navigator.onLine);

    window.addEventListener("online", handleOnlineStatus);
    window.addEventListener("offline", handleOnlineStatus);

    return () => {
      window.removeEventListener("online", handleOnlineStatus);
      window.removeEventListener("offline", handleOnlineStatus);
    };
  }, []);
  // Functionality: API call for after succesfully payment create paid user
  // const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
  //   event.preventDefault();
  //   setIsLoader(true);
  //   if (!stripe || !elements) {
  //     return;
  //   }
  //   try {
  //     let _key = logedInSecret || secretKey;
  //     let _endPoint = logedInSecret ? "change" : "new";
  //     const cardNumberElement = elements.getElement(CardNumberElement);

  //     if (!cardNumberElement) {
  //       setIsError("Card number element is not available");
  //       setIsLoader(false);
  //       return;
  //     }
  //     const paymentRes = await stripe.confirmCardPayment(_key as string, {
  //       payment_method: {
  //         card: cardNumberElement, // Corrected: Pass the element name as a string
  //         billing_details: {},
  //       },
  //     });

  //     if (paymentRes) {
  //       let _id = paymentRes?.paymentIntent?.id;
  //       try {
  //         const response = await axios.post(
  //           `${process.env.REACT_APP_BASE_URL}/api/subscription/complete`,
  //           subscriptionId
  //         );
  //         console.log("response", response?.data);
  //         if (response.status) {
  //           dispatch(removeUpgradeUser());
  //           nextStep();
  //           setTimeout(() => {
  //             removeCookie(dispatch);
  //           }, 1000);
  //         }
  //       }
  //       catch (err: any) {
  //         console.log(err, "errrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr")
  //         setIsError(
  //           err.response?.data?.message || err.response?.data?.error || "ERROR"
  //         );
  //       }
  //     }
  //     // setIsError(
  //     //   // paymentRes?.error?.message || paymentRes.error?.data || "ERROR"
  //     //   err.response?.data?.message || err.response?.data?.error || "ERROR"

  //     // );
  //     // Handle payment response
  //   } catch (err: any) {
  //     setIsError(
  //       err.response?.data?.message || err.response?.data?.error || "ERROR"
  //     );
  //   }
  //   setIsLoader(false);
  // };
  console.log(logedInSecret, "secretKeysecretKey")
  const [hasCompletedApiCall, setHasCompletedApiCall] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setIsLoader(true);
    if (!logedInSecret && hasCompletedApiCall) {
      console.log("Already completed, skipping API call.");
      return;  // Return early to prevent hitting the /complete API again
    }

    setIsLoader(true);

    if (!logedInSecret) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/subscription/complete`,
          { subscriptionId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 201) {
          setPaymentSuccessModal(true);
          setHasCompletedApiCall(true);  // Mark that the API call has been completed
          // Optionally remove cookies, update the state, or dispatch necessary actions
        } else {
          setIsError("API request failed");
        }
      } catch (err: any) {
        setIsError(
          err.response?.data?.message || err.response?.data?.error || "API request failed"
        );
      }
      setIsLoader(false);
      return;
    }
    // if (!logedInSecret) {
    //   try {
    //     const response = await axios.post(
    //       `${process.env.REACT_APP_BASE_URL}/api/subscription/complete`,
    //       { subscriptionId },
    //       {
    //         headers: {
    //           Authorization: `Bearer ${token}`,
    //         },
    //       }
    //     );

    //     if (response.status === 201) {
    //       setPaymentSuccessModal(true);
    //       // Optionally remove cookies, update the state or dispatch necessary actions
    //     } else {
    //       setIsError("API request failed");
    //     }
    //   } catch (err: any) {
    //     setIsError(
    //       err.response?.data?.message || err.response?.data?.error || "API request failed"
    //     );
    //   }
    //   setIsLoader(false);
    //   return;
    // }

    if (!stripe || !elements) {
      setIsError("Stripe.js or Elements not loaded.");
      setIsLoader(false);
      return;
    }
    try {
      let _key = logedInSecret || secretKey;
      let _endPoint = logedInSecret ? "change" : "new";
      const cardNumberElement = elements.getElement(CardNumberElement);
      if (!cardNumberElement) {
        setIsError("Card number element is not available");
        setIsLoader(false);
        return;
      }

      const paymentRes = await stripe.confirmCardPayment(_key as string, {
        payment_method: {
          card: cardNumberElement,
          billing_details: {},
        },
      });

      console.log("Payment Response:", paymentRes);

      if (paymentRes.error) {
        setIsError(paymentRes.error.message || "Payment failed");
        setIsLoader(false);
        return;
      }

      let _id = paymentRes?.paymentIntent?.id;

      if (!_id) {
        setIsError("Payment intent ID is missing");
        setIsLoader(false);
        return;
      }

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/subscription/complete`,
          { subscriptionId },
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        );

        if (response.status === 201) {
          // dispatch(setPersonalProfile)
          // dispatch(removeUpgradeUser());
          // nextStep();
          setPaymentSuccessModal(true)          // navigate("/subscription")
          // setTimeout(() => {
          //   removeCookie(dispatch);
          // }, 1000);

        } else {
          setIsError("API request failed");
        }
      } catch (err: any) {
        console.log("API Error:", err);
        setIsError(
          err.response?.data?.message || err.response?.data?.error || "API request failed"
        );
      }
    } catch (err: any) {
      console.log("Payment Error:", err);
      setIsError(
        err.response?.data?.message || err.response?.data?.error || "Payment processing failed"
      );
    }
    setIsLoader(false);
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(null);
      }, 5000);
    }
  }, [isError]); // error clear in after 5 seconds.

  let token = getCookie();
  // Functionality: API call for change subscription & upgrade account

  useEffect(() => {
    if (hasFetched.current) return;  // If it has already run, don't run again

    if (token || upgrade) {
      hasFetched.current = true;  // Set the flag to true
      setFullPageLoader(true);

      (async () => {
        try {
          const responseUpgrade = await axios.post<ApiResponse>(
            `${process.env.REACT_APP_BASE_URL}/api/subscription/create-intent`,
            { offerId: premium?.id },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setLogedInSecret(responseUpgrade.data?.clientSecret || "");
          setSubscriptionId(responseUpgrade?.data?.subscriptionId || "");
        } catch (err: any) {
          console.log(err, "======>ERROR");
          setIsError(
            err.response?.data?.message || err.response?.data?.error || "ERROR"
          );
        }
        setFullPageLoader(false);
      })();
    }
  }, [token, upgrade, premium?.id, setSubscriptionId]);

  return (
    <>
      {fullPageLoader && (
        <>
          <div className="full_page_loader">
            <CircularProgress style={{ color: "#fff" }} />
          </div>
        </>
      )}
      <form onSubmit={handleSubmit} className="w-full mb-2 py-3 px-6">
        <div className="stripe-des" style={{ marginBottom: "15px" }}>
          <label className="font-Montserrat-SemiBold font-size-login-18px ">
            Card number
          </label>
          <label className="font-Montserrat-SemiBold font-size-login-18px text-white">
            Card number
            <CardNumberElement
              className="stripe-des"
              id="cardNumber"
              options={{
                style: {
                  base: {
                    // border: "1px solid #ced4da",
                    // borderRadius: "0.25rem",
                    padding: "0.375rem 0.75rem",

                    fontSize: "18px",
                  },
                },
              }}
            />
          </label>
        </div>
        <div className="date-cvc" style={{ marginBottom: "25px" }}>
          <div>
            <label className="font-Montserrat-SemiBold font-size-login-18px ">
              Expiry date
            </label>
            <label className="font-Montserrat-SemiBold font-size-login-18px text-white">
              Expiry date
              <CardExpiryElement
                options={{
                  style: {
                    base: {
                      // color: "#fff",
                      fontSize: "18px",
                      padding: "0.375rem 0.75rem",
                      // borderRadius: "0.25rem",
                      // border: "1px solid #ced4da",
                    },
                  },
                }}
              />
            </label>
          </div>
          <div>
            <label className="font-Montserrat-SemiBold font-size-login-18px ">
              CVC
            </label>
            <label className="font-Montserrat-SemiBold font-size-login-18px text-white">
              CVC
              <CardCvcElement
                options={{
                  style: {
                    base: {
                      // color: "#fff",
                      fontSize: "18px",
                      padding: "0.375rem 0.75rem",
                      // borderRadius: "0.25rem",
                      // border: "1px solid #ced4da",
                    },
                  },
                }}
              />
            </label>
          </div>
        </div>
        {isError && <div style={{ color: "red" }}>{isError}</div>}
        <button
          className="theme-bg-green  font-Poppins-Medium  font-size-16px px-5 py-1 inline-table rounded-3xl text-white btn-w feeds-btn gap-2"
          type="submit"
          disabled={!stripe || isLoader}
        >
          Pay
          {isLoader && (
            <CircularProgress
              style={{ width: 20, height: 20, color: "#ffff" }}
            />
          )}
        </button>


        {paymentSuccessModal &&
          <div className="full_page_loader full-page-2">
            <PymentSuccess />
          </div>
        }
      </form>
    </>
  );
}
export default Stripecheck;
