import React, { useState, useRef, useEffect, MouseEvent } from "react";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { Link } from "react-router-dom";
import {
  handleCopyLinkPost,
  postTimeDifference,
} from "../../config/Helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEarthAmericas,
  faEllipsis,
  faLink,
  faFlagCheckered,
} from "@fortawesome/free-solid-svg-icons";
import PostCommentSection from "./PostCommentSection";
import MyconnectionIcon from "../../assets/img/myConnections.svg"
interface PostDetailViewProps {
  data: {
    profileImageUrl?: string;
    userId?: string;
    firstName?: string;
    lastName?: string;
    connections?: number;
    createdAt?: string;
    caption?: string;
    attachments?: { url: string }[];
    _id?: string;
    [key: string]: any;
  };
  setDate: (data: any) => void;
}

const PostDetailView: React.FC<PostDetailViewProps> = ({ data = {}, setDate = () => { } }) => {

  const [feedControlMenu, setFeedControlMenu] = useState<boolean>(false); // for post menu

  const postMenus = useRef<HTMLDivElement | null>(null); // for post menu reference

  const handleClickOutside = (event: Event) => {
    if (postMenus.current && !postMenus.current.contains(event.target as Node)) {
      setFeedControlMenu(false);
    }
  };
  console.log(data,"datatadsad")

  //  click the outside of post menu, menu box is hide

  useEffect(() => {
    // Add event listener to detect clicks outside the div
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // for the refercen of post menu

  return (
    <div className="shadow-lg bg-white rounded-xl mb-2 py-3 relative">
      <div className="flex gap-2 items-center justify-between px-2 px-3">
        <div className="flex gap-2 items-center">
          <div className="crete-feed-user-img flex ">
            <img
              width={100}
              loading="lazy"
              src={`${data?.profileImageUrl}`}
              // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${data?.profileImageUrl}`}
              onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
              alt="user"
              className="rounded-full"
            />
          </div>{" "}
          {/* post creator image */}
          <div className="sidebar-gobal-user-name ">
            <Link
              to={`/public-profile/${data?.userId}/view`}
              className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize"
            >
              {" "}
              {`${data?.firstName || ""} ${data?.lastName || ""}`}
            </Link>{" "}
            {/* post creator name */}
            <p className="font-size-15px  theme-color-green font-Poppins-Regular  capitalize">
              {data?.connections || 0} Connections
            </p>{" "}
            {/* post creator connection count */}
            <p className="font-size-15px theme-grey-type  font-Poppins-Regular flex flex-row gap-1 items-center ">
              {postTimeDifference(data?.createdAt || "")}{" "}
              {data?.privacy === "myConnections" ? <img src={MyconnectionIcon} className="con-only-img" alt="my-connections" /> : <FontAwesomeIcon icon={faEarthAmericas} />}

            </p>{" "}
            {/* post time differnce */}
          </div>
        </div>
        <div>
          <FontAwesomeIcon
            className="cursor-pointer"
            icon={faEllipsis}
            onClick={() => setFeedControlMenu((prev) => !prev)}
          />
          {feedControlMenu && (
            <div
              className="shadow-lg bg-white rounded-xl absolute right-0 top-10"
              ref={postMenus}
            >
              <ul className=" p-3">
                <li className="font-size-15px theme-grey-type font-Poppins-SemiBold">
                  <button
                    className="mb-1 flex gap-1 items-center"
                    onClick={() => handleCopyLinkPost(data?._id ?? "")}  // Use nullish coalescing to default to an empty string
                  >
                    <FontAwesomeIcon icon={faLink} /> Copy link to post
                  </button>
                </li>

                {/* <li className="font-size-15px theme-grey-type font-Poppins-SemiBold">
                                    <button className="flex gap-1 items-center">
                                        <FontAwesomeIcon icon={faFlagCheckered} />  Report post
                                    </button>
                                </li> */}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div
        className="px-3 pt-3 grid items-end see-less-funct"
        style={{ gridTemplateColumns: "36vw 6vw" }}
      >
        <p className="font-size-17px theme-grey-type font-Poppins-Regular">
          {data?.caption || ""}
        </p>
      </div>
      {data?.attachments && data.attachments.length > 0 && (
        <div className=" pt-3">
          <img
            src={`${data?.attachments[0]?.url}`}
            // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${data?.attachments[0]?.url}`}
            className="w-full"
            alt=""
          />
        </div>
      )}
      {/* image post */}

      <div className="relative  px-2">
        <PostCommentSection
          commentData={data}
          setCommentData={setDate}
          detailPage={true}
        />
      </div>
    </div>
  );
}

export default PostDetailView;
