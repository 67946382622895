import React, { useState, useEffect } from "react";
import like from "../../assets/img/like.svg";
import like2 from "../../assets/img/like2.svg";
import comment from "../../assets/img/comment.svg";
import { faMessage, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {
  toogleLikedPost,
  updateCommentCounter,
} from "../../config/Store/Reducers/newsFeedSlice";
import AddComment from "../PostCommentSection/AddComment";
import DisplayComments from "../PostCommentSection/DisplayComments";
import AllLikesModal from "./AllLikesModal";
import {
  toogleActivityLikedPost,
  updateActivityCommentCounter,
} from "../../config/Store/Reducers/postActivitySlice";
import httpRequest from "../../config/Helpers/httpRequest";
import { Category } from "@mui/icons-material";
import { getRole } from "../../config/Helpers/helpers";


interface PostCommentSectionProps {
  commentData: {
    _id?: string;
    reactionStatus?: boolean;
    allReacts?: number;
    allComments?: number;
    comments?: any[];
    [key: string]: any;
  };
  setCommentData?: React.Dispatch<React.SetStateAction<any>>; // Make it optional by adding ?
  activityPage?: boolean;
  detailPage?: boolean;
}
interface ApiResponse {
  likeStatus: boolean;
}

const PostCommentSection: React.FC<PostCommentSectionProps> = ({
  commentData = {},
  setCommentData = () => { },
  activityPage = false,
  detailPage = false,
}) => {

  const dispatch = useDispatch();
  const decoded = getRole();
  const UserRole = decoded?.role;

  const [reactionLoader, setReactioLoader] = useState<boolean>(false);
  const [allComments, setAllComments] = useState<any[]>([]); // Assuming comments are of any type
  const [commentInputFocus, setCommentInputFocus] = useState<boolean>(false);
  const [likeModal, setLikeModal] = useState<boolean | { id: string; total: number }>(false);

  const handleAddNewComment = (event: any) => {
    setAllComments((prev) => [event, ...prev]);
    if (activityPage) {
      dispatch(updateActivityCommentCounter(event?.refId));
    } else if (detailPage) {
      setCommentData((prev: any) => ({
        ...prev,
        allComments: prev?.allComments + 1,
      }));
    } else {
      dispatch(updateCommentCounter(event?.refId));
    }
  };

  // Functionality: API call for reactions on post like & dislike
  const handleReactOnPost = async () => {
    setReactioLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: `/api/social/like`, params: { refId: commentData?._id, reactionType: "like", feedType: "post" } });
    if (res) {
      console.log(res, "like post api")

      let obj = {
        status: res?.likeStatus,
        postId: commentData?._id,
      };
      if (activityPage) {
        dispatch(toogleActivityLikedPost(obj));
      } else if (detailPage) {

        setCommentData((prev: any) => ({
          ...prev,
          reactionStatus: res?.likeStatus,
          allReacts: res?.likeStatus
            ? prev?.allReacts + 1
            : prev?.allReacts - 1,
        }));

      } else {
        dispatch(toogleLikedPost(obj));
      }
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setReactioLoader(false);
  };

  useEffect(() => {
    // set First comment show
    if (commentData && commentData?.allComments) {
      setAllComments([commentData?.comments]);
    }

  }, [commentData?.comments]);


  return (
    <>
      <AllLikesModal show={likeModal} setShow={setLikeModal} />
      <div className="broder-t-1 ">
        <ul className="flex gap-2 py-2">
          <li className="total-like flex gap-1  items-center">
            <span
              role="button"
              onClick={() =>
                setLikeModal({
                  id: commentData?._id || "",
                  total: typeof commentData?.allReacts === 'number' ? commentData.allReacts : 0, // Ensuring total is always a number
                })
              }
              style={{
                backgroundColor:
                  commentData?.allReacts && commentData?.allReacts > 0 ? "#0073b1" : "#707070",
              }}
            >
              <FontAwesomeIcon
                color="white"
                icon={faThumbsUp}
                flip="horizontal"
              />
            </span>{" "}
            {/* if post reacts is exist then active color apply */}
            <button className="font-size-15px theme-grey-type  font-Poppins-Medium">
              {commentData?.allReacts || commentData?.reactions || 0}{" "}
            </button>{" "}
            {/* post reacts count */}
          </li>
          <li className="total-like flex gap-1  items-center">
            <FontAwesomeIcon icon={faMessage} color="#707070" />
            <div
              className="font-size-15px theme-grey-type font-Poppins-Medium"
              style={{ cursor: "context-menu" }}
            >
              {commentData?.allComments || 0} comments
            </div>{" "}
            {/* post comment count */}
          </li>
        </ul>
      </div>
      {(UserRole === "participant" || UserRole === "provider") && (

        <>
          <hr />

          <div className="flex justify-center gap-10 ">
            <button
              disabled={reactionLoader}
              onClick={handleReactOnPost}
              className="flex items-center gap-2 on-hover"
            >
              <img src={commentData?.reactionStatus ? like2 : like} alt="" />{" "}
              {/* user liked or not */}
              <span className="font-size-15px font-Poppins-Medium theme-grey-type">
                Like
              </span>
            </button>
            <button
              onClick={() => setCommentInputFocus(true)}
              className="flex items-center gap-2 cursor-pointer on-hover"
            >
              <img src={comment} alt="" />
              <span className="font-size-15px font-Poppins-Medium theme-grey-type">
                Comment
              </span>
            </button>
          </div>



          <AddComment
            refId={commentData?._id}
            successFunction={handleAddNewComment}
            feedType="post"
            isFocus={commentInputFocus}
            setIsFocus={setCommentInputFocus}
          />
        </>
      )}
      <DisplayComments
        refId={commentData?._id}
        feedType="post"
        allComments={allComments}
        setAllComments={setAllComments}
        activityPage={activityPage}
        totalComments={commentData?.allComments}
        detailPage={detailPage}
      />
    </>
  );
};

export default PostCommentSection;