import React, { useRef, useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios';
import { getCookie } from '../../config/Helpers/helpers';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
interface JobRescheduleModalProps {
    onClose: () => void;
    jobId: string;
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
    successFunction: (comment: string) => void;
}

// Define the type for the reschedule object state
interface RescheduleObject {
    startDate: string;
    startTime: string;
    endDate: string;
    endTime: string;
}
interface ApiResponse {
    comment: string;
    [key: string]: any;
}
const JobRescheduleModal: React.FC<JobRescheduleModalProps> = ({ onClose, jobId, startDate, startTime, endDate, endTime, successFunction }) => {
    const startDateCalender = useRef<HTMLInputElement>(null);
    const endDateCalender = useRef<HTMLInputElement>(null);

    const token = getCookie();
    let config = {
        headers: {
            Authorization: `Bearer ${token}`
        },
    };

    const [rescheduleObject, setRescheduleObject] = useState<RescheduleObject>({
        startDate: startDate,
        startTime: startTime,
        endDate: endDate,
        endTime: endTime
    });

    const [reason, setReason] = useState<string>("");
    const [isLoader, setIsLoader] = useState<boolean>(false);


    // Functionality: API call for Job rescheduler Request
    const handleRescheduleApi = async () => {
        const isRescheduleObjectUnchanged =
            rescheduleObject.startDate === startDate &&
            rescheduleObject.startTime === startTime &&
            rescheduleObject.endDate === endDate &&
            rescheduleObject.endTime === endTime;

        // If no changes are detected in the rescheduleObject, prevent submission
        if (isRescheduleObjectUnchanged) {
            toast("Please change the reschedule dates or times to submit.", { type: "error" });
            return;
        }

        // if (!rescheduleObject.startDate || !rescheduleObject.startTime || !rescheduleObject.endDate || !rescheduleObject.endTime || !reason) {
        //     toast("All fields are required.", { type: "error" });
        //     return;
        // }
        const startDateTime = moment(`${rescheduleObject.startDate} ${rescheduleObject.startTime}`);
        const endDateTime = moment(`${rescheduleObject.endDate} ${rescheduleObject.endTime}`);
        const currentDate = new Date(); // Get the current date and time

        if (endDateTime.isBefore(startDateTime)) {
            toast("Start date and time must be before end date and time.", { type: "error" });
            return;
        }
        if (moment(rescheduleObject.startDate).isBefore(moment(currentDate))) {
            toast("Start date cannot be before the current date.", { type: "error" });
            return;
        }
        if (moment(rescheduleObject.endDate).isBefore(moment(rescheduleObject.startDate))) {
            toast("End date cannot be before the start date.", { type: "error" });
            return;
        }

        if (rescheduleObject.startTime === rescheduleObject.endTime) {
            toast("Start time and end time cannot be the same.", { type: "error" });
            return;
        }
        if (!reason) {
            toast("Reason is required.", { type: "error" });
            return;
        }

        setIsLoader(true);
        try {
            const response = await axios.post<ApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/job/request/reschedule`,
                {
                    jobId: jobId,
                    userMessage: reason,
                    rescheduleObject: rescheduleObject
                }, config);
            if (response.status == 201) {
                onClose();
                setReason("");
                successFunction(response?.data?.comment);
                toast(response?.data?.message || "Success", { type: "success" });
            }
        }
        catch (err: any) {
            toast(
                err.response?.data?.message || err.response?.data?.error || "Error",
                { type: "error" });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (startDateCalender.current) {

            flatpickr(startDateCalender.current, {
                dateFormat: "m-d-Y",
                defaultDate: new Date(startDate),
                onChange: function (selectedDates, dateStr, instance) {
                    setRescheduleObject(prevState => ({
                        ...prevState,
                        startDate: dateStr
                    }));
                }
            });
        }
        if (endDateCalender.current) {

            flatpickr(endDateCalender.current, {
                dateFormat: "m-d-Y",
                defaultDate: new Date(endDate),
                onChange: function (selectedDates, dateStr, instance) {
                    setRescheduleObject(prevState => ({
                        ...prevState,
                        endDate: dateStr
                    }));
                }
            });
        }
    }, []);
 
    return (
        <div className="modal">
            <div className="Cancellation-modal-content relative">
                <button
                    onClick={onClose}
                    className="absolute job-pre-edit"
                >
                    <FontAwesomeIcon
                        className="x-icon-admin-opr"
                        icon={faX}
                        style={{ color: "#ffffff" }}
                    />
                </button>
                <h2 className="font-Poppins-SemiBold font-size-30px text-[#464646] mb-2">Reschedule</h2>
                <div className="edit-looking-for-2 activejob-date-modal">
                    <div className="flex flex-row justify-between gap-2">
                        <div className="dates-edit-modal-recur">
                            <div>
                                <div className='ends-on-date'>
                                    <input
                                        ref={startDateCalender}
                                        className="font-Poppins-SemiBold font-size-15px text-black edit-looking-cop"
                                        placeholder="Start date"
                                        type="text"
                                        style={{ color: "#red" }}
                                    />
                                </div>
                                <div className="recur-oneoff-day-2 start-with">
                                    <input
                                        className="font-Poppins-SemiBold font-size-15px text-black end-time edit-looking-cop"
                                        type="time"
                                        value={rescheduleObject.startTime}
                                        onChange={(e) => setRescheduleObject({ ...rescheduleObject, startTime: e.target.value })}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="dates-edit-modal-recur">
                            <div className='ends-on-date'>
                                <input
                                    ref={endDateCalender}
                                    className="font-Poppins-SemiBold font-size-15px text-black edit-looking-cop"
                                    placeholder="End date"
                                    type="text"
                                    style={{ color: "#red" }}
                                />
                            </div>
                            <div className="recur-oneoff-day-2 ends-with">
                                <input
                                    className="font-Poppins-SemiBold font-size-15px text-black end-time edit-looking-cop"
                                    type="time"
                                    value={rescheduleObject.endTime}
                                    onChange={(e) => setRescheduleObject({ ...rescheduleObject, endTime: e.target.value })}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                        <h2 className="font-Poppins-SemiBold font-size-18px text-[#464646]">Reason</h2>
                        <textarea className="font-Poppins-Regular font-size-15px p-3 date-text-detail-active" value={reason} onChange={(e) => setReason(e.target.value)} placeholder="Write a reason" />
                    </div>
                    <div className="flex cancellation-mdl-btn justify-center gap-2 mt-4">
                        <button disabled={isLoader} onClick={handleRescheduleApi} className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-0 flex gap-4">Send request {isLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobRescheduleModal;