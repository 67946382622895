import React, { useState, useRef, useEffect } from "react";
import DefaultProfileImage from "../../assets/img/default_profile_image.png";
import { Link } from "react-router-dom";
import {
  faEllipsis,
  faLink,
  faCircleXmark,
  faFlagCheckered,
} from "@fortawesome/free-solid-svg-icons";
import {
  getRole,
  handleCopyLinkPost,
  postTimeDifference,
  truncateString,
} from "../../config/Helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEarthAmericas } from "@fortawesome/free-solid-svg-icons";
import PostCommentSection from "./PostCommentSection";
import ReportOptions from "../ReportComponents/ReportOptions";
import PostModal from "./PostModal";
import reactStringReplace from 'react-string-replace';
import { displayPost } from "../../config/Helpers/helpers";
import PostDelete from "../ReportComponents/PostDelete";
import PromoteIcon from "../../assets/img/promoteIcon.svg"
import ReportICon from "../../assets/img/report-icon.svg"
import MyconnectionIcon from "../../assets/img/myConnections.svg"
import PromoteModal from "./PromoteModal";
import ConnectionStatusButton from "../ConnectionsComponents/ConnectionStatusButton";

interface PostContainerProps {
  item: any; // Replace 'any' with a specific type if you have a type definition for item
  index?: number;
  activityPage?: boolean;
  category?: string;
}
interface CaptionComponentProps {
  text: string;
}

const PostContainer: React.FC<PostContainerProps> = ({
  item = {},
  index = 0,
  activityPage = false,
  category = "posts",
}) => {
  const [promoteModal, setPromoteModal] = useState<boolean>(false)
  const CaptionComponent = ({ text = "" }) => {

    // post caption component
    const [isFullText, setIsFullText] = useState(false);
    const parseTextWithHashtags = (text: string) => {
      return reactStringReplace(text, /#(\w+)/g, (match, i) => (
        <span
          key={i}
          style={{ color: '#4545e9', textDecoration: 'underline', cursor: 'pointer' }}
          onMouseOver={(e) => (e.target as HTMLElement).style.textDecoration = 'underline'}
          onMouseOut={(e) => (e.target as HTMLElement).style.textDecoration = 'none'}
        >
          #{match}
        </span>
      ));
    };
    return (
      <div
        className="px-3 pt-3 grid items-end see-less-funct"
        style={{ gridTemplateColumns: "36vw 6vw" }}
      >
        {isFullText ? (
          <p className="font-size-17px theme-grey-type font-Poppins-Regular"
          >
            {/* {text || ""} */}
            {parseTextWithHashtags(text || "")}


          </p>
        ) : (
          <p className="font-size-17px theme-grey-type font-Poppins-Regular">
            {/* {truncateString(text, 240)} */}
            {parseTextWithHashtags(truncateString(text, 240))}

          </p>

        )}
        {text?.length > 240 && (
          <div className="flex justify-center">
            <button
              onClick={() => setIsFullText(!isFullText)}
              className="font-size-15px theme-grey-type  font-Poppins-Regular"
            >
              {isFullText ? "See less" : "See more"}
            </button>
          </div>
        )}
      </div>
    );
  };

  const postMenus = useRef<HTMLDivElement | null>(null); // Reference for post menu
  const [feedControlMenu, setFeedControlMenu] = useState<string>(""); // State for controlling post menu
  const [selectedPost, setSelectedPost] = useState<any>({});
  const handleClickOutside = (event: MouseEvent) => {

    if (postMenus.current && !postMenus?.current?.contains(event.target as Node)) {
      setFeedControlMenu("");
    }
  }; // click the outside of post menu, menu box is hide

  useEffect(() => {
    // Add event listener to detect clicks outside the div
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []); // for the refercen of post menu

  const [reportModal, setReportModal] = useState<string | false>(false);
  const [deletePost, setDeletePost] = useState<string | false>(false);
  const docoded = getRole();
  const userId = docoded?.userId;
  const UserRole = docoded?.role;


  const handleReportOpen = (id: string) => {
    setReportModal(id);
  };
  const handleDeletePost = (id: string) => {
    setDeletePost(id);
  };
  // useEffect(() => {
  //   if (selectedPost?._id === item?._id) {
  //     document.body.classList.add('modal-open');
  //   } else {
  //     document.body.classList.remove('modal-open');
  //   }

  //   // Cleanup function to remove the class if the component unmounts
  //   return () => {
  //     document.body.classList.remove('modal-open');
  //   };
  // }, [selectedPost, item]);

  useEffect(() => {
    const globalHeader = document.querySelector(".globalheader");

    if (selectedPost?._id === item?._id) {
      document.body.classList.add("modal-open");
      if (globalHeader) {
        globalHeader.classList.add("modal-open");
      }
    } else {
      document.body.classList.remove("modal-open");
      if (globalHeader) {
        globalHeader.classList.remove("modal-open");
      }
    }

    // Cleanup function to remove the class if the component unmounts
    return () => {
      document.body.classList.remove("modal-open");
      if (globalHeader) {
        globalHeader.classList.remove("modal-open");
      }
    };
  }, [selectedPost, item]);

  return (
    <>
      {selectedPost?._id === item?._id && (
        <div
          className="relative z-10"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <PostModal item={selectedPost} onClose={() => setSelectedPost({})} />
        </div>
      )}
      <div
        key={index}
        className="shadow-lg bg-white rounded-xl mb-2 py-3 relative"
      >
        {/* {activityPage && category === "comments" && (
          <>
            <div className="flex gap-2 items-center border-bottom commeted-show px-3 pb-2">
              <div className="crete-feed-user-img flex ">
                <img
                  width={100}
                  loading="lazy"
                  src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.comments?.profileImageUrl}`}
                  onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                  alt="user"
                  className="rounded-full"
                />
              </div>{" "}
              <Link
                to={`/public-profile/${item?.userId}/view`}
                className="font-size-15px font-Poppins-SemiBold theme-color-green capitalize"
              >
                {" "}
                {`${item?.comments?.firstName || ""} ${item?.comments?.lastName || ""
                  }`}
              </Link>{" "}
              <p className="font-size-12px theme-grey-type  font-Poppins-Medium">
                commented on this
              </p>
            </div>
            <hr />
          </>
        )} */}

        <div className=" items-center px-2 px-3">
          <div className="flex gap-2 items-center">
            <div className="crete-feed-user-img flex ">
              <img
                width={100}
                loading="lazy"
                src={`${item?.profileImageUrl}`}
                // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.profileImageUrl}`}
                onError={(e) => (e.currentTarget.src = DefaultProfileImage)}
                alt="user"
                className="rounded-full"
              />
            </div>{" "}
            {/* post creator image */}
            <div className="sidebar-gobal-user-name w-full">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row gap-2">
                  <Link
                    to={`/public-profile/${item?.userId}/view`}
                    className="font-size-20px font-Poppins-SemiBold theme-color-green capitalize on-hover-underline"
                  >
                    {" "}
                    {`${item?.firstName || ""} ${item?.lastName || ""}`}
                  </Link>{" "}
                  {(item?.promotionId && item?.connectionStatus === "default") ?
                    (
                      <>
                        {/* <ConnectionStatusButton
                          key={Math.random()}
                          status={item?.connectionStatus}
                          uId={item?.userId}
                          showMessageButton={false}
                        /> */}
                      </>
                    )
                    :
                    (
                      null
                    )
                  }
                </div>
                {(UserRole === "participant" || UserRole === "provider") && (


                  <FontAwesomeIcon
                    icon={faEllipsis}
                    onClick={() => setFeedControlMenu(item?._id)}
                  />
                )}
              </div>

              {/* post creator name */}
              <p className="font-size-15px  theme-color-green font-Poppins-Regular  capitalize">
                {item?.connections || 0} Connections
              </p>{" "}
              {/* post creator connection count */}
              <p className="font-size-15px theme-grey-type  font-Poppins-Regular  flex flex-row gap-1 items-center">
                {postTimeDifference(item?.createdAt)}
                {/* <FontAwesomeIcon icon={faEarthAmericas}  */}
                {/* /> */}
                {item?.privacy === "myConnections" ? <img src={MyconnectionIcon} className="con-only-img" alt="my-connections" /> : <FontAwesomeIcon icon={faEarthAmericas} />}



              </p>{" "}
              {/* post time differnce */}
            </div>
          </div>
          <div>
            {feedControlMenu === item?._id && (
              <div
                className="shadow-lg bg-white rounded-xl absolute right-3 top-10 w-80"
                ref={postMenus}
              >
                <ul className="py-3 ">
                  <li className="font-size-15px theme-grey-type  font-Poppins-SemiBold  mb-1 report-post-feed on-hover-underline px-3 py-1 hover-dash-btn">
                    <button onClick={() => handleCopyLinkPost(item?._id)} className="flex gap-1 items-center">
                      <FontAwesomeIcon icon={faLink} />

                      <span>Copy link to post</span>      </button>
                  </li>
                  {item?.userId !== userId &&
                    <li className="font-size-15px theme-grey-type  font-Poppins-SemiBold report-post-feed on-hover-underline px-3 py-1  hover-dash-btn">
                      <button onClick={() => handleReportOpen(item?._id)} className="flex gap-1 items-center">
                        {/* <FontAwesomeIcon icon={faFlagCheckered} /> */}
                        <img src={ReportICon} alt="report-icon" />

                        <span>Report post</span> </button>
                    </li>
                  }
                  {item?.userId === userId &&
                    <li className="mt-1 font-size-15px theme-grey-type  font-Poppins-SemiBold report-post-feed on-hover-underline px-3 py-1  hover-dash-btn">
                      <button
                        onClick={() => handleDeletePost(item?._id)}
                        className="flex gap-1 items-center"
                      >
                        {/* <FontAwesomeIcon icon={faFlagCheckered} /> */}
                        <img src={ReportICon} alt="report-icon" />
                        <span>Delete post</span>     </button>

                    </li>
                  }
                </ul>
              </div>
            )}
          </div>
        </div>

        <CaptionComponent text={item?.caption} />

        {/* image post */}
        {item?.attachments && (
          <div className=" pt-3 image-control-height">

            <img
              src={`${item?.attachments[0]?.url}`}
              // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.attachments[0]?.url}`}
              className="w-full cursor-pointer"
              onClick={() => setSelectedPost(item)}
              alt=""
            />
          </div>
        )}

        {/* image post */}

        {item?.userId === userId &&
          <div>
            <div className="flex flex-end justify-end px-2 mt-2">
              {item?.promotionId ?
                <div className="flex flex-row justify-between w-full">
                  <Link
                    to="/ads-manager"
                    // onClick={() => setPromoteModal(true)} 
                    className="theme-bg-green  font-Poppins-Medium  font-size-18px px-5 py-1 rounded-3xl  feeds-btn text-[#ffffff] flex flex-row gap-2 items-center">
                    View insight
                  </Link>
                  <button
                    // onClick={() => setPromoteModal(true)} 
                    className="bg-[#007e7661] no-border font-Poppins-Medium  font-size-18px px-5 py-1 rounded-3xl  feeds-btn text-[#007E76] flex flex-row gap-2 items-center">
                    Promoted
                  </button>

                </div>
                :
                <button onClick={() => setPromoteModal(true)} className="theme-bg-green  font-Poppins-Medium  font-size-18px px-5 py-1 rounded-3xl  feeds-btn text-[#ffffff] flex flex-row gap-2 items-center">
                  <img src={PromoteIcon} alt="promote" />
                  Promote this post
                </button>

              }
            </div>
            <hr className="w-full px-2 mt-1" />
          </div>
        }

        <div className="relative  px-2">
          <PostCommentSection
            commentData={item}
            key={item?._id}
            activityPage={activityPage}
          />
        </div>
        {reportModal && (
          <ReportOptions
            id={reportModal}  // Convert to boolean
            onClose={() => setReportModal(false)}
            type="post"
            category="post"
          />
        )}
        {deletePost && (
          <PostDelete
            id={deletePost}  // Convert to boolean
            onClose={() => setDeletePost(false)}
            type="post"
            category="post"
          />
        )}
        {promoteModal && (
          <PromoteModal refType="post" refId={item?._id || ""} onClose={() => setPromoteModal(false)} />
        )}

      </div>
    </>
  );
}

export default PostContainer;
