/**
 * FeedDetail Component
 *
 * This component fetches and displays the details of a specific post in the news feed.
 * It includes a header, a post detail view, and sidebars for additional content and post creation.
 *
 * File Name: FeedDetail.jsx
 * Author: Developer
 * Date: 2024-06-22
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import RightSidebarFeed from "../../components/NewsFeeds/RightSidebarFeed";
import PostCreatorSideBar from "../../components/NewsFeeds/PostCreatorSideBar";
import PostDetailView from "../../components/NewsFeeds/PostDetailView";

import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { CircularProgress } from "@mui/material";
import httpRequest from "../../config/Helpers/httpRequest";
import SidebarGobal from "../../components/AllDashboardPagesComponents/SidebarGobal";

/**
 * FeedDetail Component
 *
 * Displays the details of a specific post, including related content and post creation options.
 */


interface Document {
  profileImageUrl?: string;
  userId?: string;
  firstName?: string;
  lastName?: string;
  connections?: number;
  createdAt?: string;
  caption?: string;
  attachments?: { url: string }[];
  _id?: string;
  // Include any additional properties your API returns
  [key: string]: any;
}

interface ApiResponse {
  document: Document;
}
const FeedDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>(); // Extracts the post ID from the URL parameters

  const [isLoader, setIsLoader] = useState<boolean>(false); // State for managing the loading indicator
  const [postDetail, setPostDetail] = useState<Document | undefined>(); // State for storing post details
  // Functionality: Fetches the details of the post from the API using the post ID.
  const fetchPostDetail = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/posts/details/${id}`,
    });
    if (res) {
      // let _data = res?.documents;
      setPostDetail(res?.document);

    } else {
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  // Fetches post details when the component mounts or the post ID changes
  useEffect(() => {
    if (id) {
      // Fetch post details if the ID exists
      fetchPostDetail();
    }
  }, [id]);
  return (
    <>
      <HeaderGlobal />
      {isLoader && (
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      )}
      <div className="container-1480 flex justify-between h-3vw">
        {/* <PostCreatorSideBar data={postDetail} /> */}
        <SidebarGobal />

        <div className="news-feed-post">
          <PostDetailView data={postDetail || {}} setDate={setPostDetail} />
        </div>
        <RightSidebarFeed jobSectionHide={true} />
      </div>
    </>
  );
}

export default FeedDetail;
