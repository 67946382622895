// create new file 13-june-2024 for discussion detail page
import { createSlice } from "@reduxjs/toolkit";

const DiscussionSlice = createSlice({
    name: "DISCUSSION",
    initialState: {
        data: {},
        
    },
    reducers: {
        setDiscussion: (state, { payload }) => {
            state.data = payload; // set discussion by id
        },
        resetDiscussion: (state) => {
            state.data = {}; // set discussion by id
        },
        addNewComment: (state) => { // for counter add
            state.data = { ...state.data, allComments: state.data?.allComments + 1 };
        }
    },
});

export const { setDiscussion, resetDiscussion, addNewComment } = DiscussionSlice.actions;
export default DiscussionSlice.reducer;