

import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import url from "../../config/url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CircularProgress } from "@mui/material";
import axios from 'axios';
import {
  faArrowRight,
  faCircleCheck,
  faUser,
  faUsers,
  faEyeSlash,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import BannerVideoComponent from "./BannerVideoComponent";
import EmailSent from "./EmailSent";
import {
  validateText,
  validateNumber,
  validateTextAndSpace,
  validateEmail,
  validatePassword,
} from "../../config/Helpers/helpers";
import Header from "../Header";
import RegisterBg from "../../assets/img/new-register.jpg";
import Recaptcha from "../../assets/img/recaptcha.png"
import { SingleValue } from 'react-select';

// import ReCAPTCHA from "react-google-recaptcha";
import Select from 'react-select';
interface ApiResponse {
  documents?: [] | object | string;
  response: OptionType;
}
type OptionType = {
  value: string;
  label: string;
  suburb: string;
  state: string;
  postcode: string; // Updated to match the user state
};
interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
  };
}
const LoginComponent = () => {
  const [isEmailSentScreenDisplay, setIsEmailSentScreenDisplay] =
    useState(false);

  //  input password field state
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  //  input password field state
  const BannerBgTexture = "imgs/banner-bg-texture.png";
  const BannerTopLogo = "imgs/banner-logo.png";

  const [isError, setIsError] = useState<string | boolean>(false);
  const [isLoader, setIsLoader] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState<boolean | string>(true);
  // const [option,setOption]=useState("");
  const [showSelect, setShowSelect] = useState(false);
  const [user, setUser] = useState({
    // form state
    firstName: "",
    lastName: "",
    address: "",
    suburb: "",
    state: "",
    postCode: "",
    email: "",
    phonenumber: "",
    role: "participant",
    password: "",
    roleCategory: "",

  });
  useEffect(() => {
    if (user.role === 'participant' && !user.roleCategory) {
      setUser(prevUser => ({ ...prevUser, roleCategory: 'participant' }));
    }
  }, [user.role, user.roleCategory]);
  const recaptchaRef = useRef(null);
  // Define options for the Select component

  // Define state for the selected option with proper type
  const [selectedOption, setSelectedOption] = useState<OptionType | null>(null);

  // Function to handle change
  const handleChange = (option: OptionType | null) => {
    setSelectedOption(option);
  };

  const handleName = (key: string, value: string) => {
    if (value === "") {
      setUser({ ...user, [key]: "" });
      shakeField(key, "false"); // Add shake effect for empty field
    } else if (validateText(value)) {
      setUser({ ...user, [key]: value });
      shakeField(key, "true"); // Remove shake effect when field is filled correctly
    } else {
      shakeField(key, "false"); // Add shake effect for invalid input
    }
  };
  // const handleName = (key: string, value: string) => {
  //   // Convert the input value to lowercase
  //   const lowercaseValue = value.toLowerCase();

  //   if (lowercaseValue === "") {
  //     setUser({ ...user, [key]: "" });
  //     shakeField(key, "false"); // Add shake effect for empty field
  //   } else if (validateText(lowercaseValue)) {
  //     setUser({ ...user, [key]: lowercaseValue });
  //     shakeField(key, "true"); // Remove shake effect when field is filled correctly
  //   } else {
  //     shakeField(key, "false"); // Add shake effect for invalid input
  //   }
  // };

  const handleCategory = (key: string, value: string) => {
    setUser({ ...user, [key]: value });
  };

  const handleState = (key: string, value: string) => {
    // change first & last name
    if (value === "") {
      setUser({ ...user, [key]: "" });
      shakeField(key, "false"); // Add shake effect for empty field
    } else if (validateTextAndSpace(value)) {
      setUser({ ...user, [key]: value });
      shakeField(key, "true"); // Remove shake effect when field is filled correctly
    } else {
      shakeField(key, "false"); // Add shake effect for invalid input
    }
  };

  const handleChangePostCode = (key: string, value: string) => {
    if (value === "") {
      setUser({ ...user, [key]: "" });
    } else if (value.length > 4 || !validateNumber(value)) {
      return;
    } else {
      setUser({ ...user, [key]: value });
    }
  };

  // const handleChanges = (key: string, value: string) => {
  //   const updatedUser = { ...user, [key]: value };

  //   if (key === 'role') {
  //     if (value === 'participant') {
  //       updatedUser.roleCategory = 'participant';
  //       setShowSelect(false);
  //     } else if (value === 'provider') {
  //       setShowSelect(true);
  //     }
  //   }

  //   setUser(updatedUser);
  // };
  const handleChanges = (key: string, value: string) => {
    const updatedUser = { ...user, [key]: value };
    if (key === 'role') {
      if (value === 'participant') {
        updatedUser.roleCategory = 'participant';
        setShowSelect(false);
      } else if (value === 'provider') {
        updatedUser.roleCategory = '';
        setShowSelect(true);
      }
    }
    setUser(updatedUser);
    console.log('Updated User:', updatedUser); // Add this to log the updated user object
  };
  const handlePhone = (value: string) => {
    if (value === "") {
      setUser({ ...user, phonenumber: "" });
    } else if (validateNumber(value) && value.length < 11) {
      setUser({ ...user, phonenumber: value });
    }
  };

  // Newly added shake
  const shakeField = (fieldName: string | number | boolean, isValid: string | number | boolean) => {
    const field = document.querySelector(`input[name=${fieldName}]`);
    if (field) {
      if (!isValid) {
        field.classList.add("error", "shake");
        setTimeout(() => {
          field.classList.remove("shake");
        }, 500); // Remove the shake effect after 500 milliseconds
        // setTimeout(() => {
        //   field.classList.remove('error');
        // }, 2500); // Remove the red border after 2500 milliseconds
      } else {
        field.classList.remove("error");
      }
    }
  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoader(true);
    const payload = {
      ...user,
      firstName: user?.firstName.toLowerCase(),
      lastName: user?.lastName.toLowerCase(),

    }
    if (
      !user.firstName ||
      !user.lastName ||
      !user.address ||
      !user.suburb ||
      !user.state ||
      !user.postCode ||
      user.postCode?.length < 4 ||
      !user.email ||
      !validateEmail(user.email) ||
      user.phonenumber.length < 10 ||
      !validatePassword(user.password) ||
      (user.role === 'provider' && !user.roleCategory)
    ) {

      if (!user.firstName) shakeField("firstname", false);
      if (!user.lastName) shakeField("lastname", false);
      if (!user.address) shakeField("address", false);
      if (!user.suburb) shakeField("suburb", false);
      if (!user.state) shakeField("state", false);
      if (!user.postCode) shakeField("postCode", false);
      shakeField("suburb", !!user.suburb);
      shakeField("state", !!user.state);
      shakeField("postCode", !!user.postCode);
      // shakeField("suburb", !!user.suburb);
      // shakeField("state", !!user.state);
      // shakeField("postCode", !!user.postCode);
      if (!user.email || !validateEmail(user.email)) shakeField("email", false);
      if (user.phonenumber.length < 10) shakeField("phonenumber", false);
      if (user.postCode.length < 4) shakeField("postCode", false);
      if (!validatePassword(user.password)) shakeField("password", false);
      if (!user.role) shakeField("role", false);
      if (!user.roleCategory) {
        console.log(user.roleCategory, "user.roleCategoryuser.roleCategoryuser.roleCategory")
        shakeField("roleCategory", false)
        setIsError("Please select a category");
        setIsLoader(false);
        return
      };

      setIsLoader(false);
    } else {
      // if (!recaptchaValue) {
      //   setIsError("Please complete the reCAPTCHA.");
      //   setIsLoader(false);
      //   return;
      // }
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/api/user/createUser`,
          payload
          // { ...user, recaptchaValue }
        );
        if (response.status) {
          setIsEmailSentScreenDisplay(true);
        }
      } catch (err) {
        const axiosError = err as ErrorResponse;
        setIsError(axiosError?.response?.data?.message || "ERROR");

      }
      setIsLoader(false);
    }
  };

  const onReCAPTCHAChange = (value: string | boolean) => {
    setRecaptchaValue(value);
    setIsError(false); // Clear error when captcha is successfully filled
  };

  useEffect(() => {
    if (isError) {
      setTimeout(() => {
        setIsError(false);
      }, 5000);
    }
  }, [isError]); // error clear in after 5 seconds.

  const playbutton = "imgs/play-button-video.png";
  const videosec = "https://ndisync.backslashwebs.com/NDISYNCDEMO2.mp4";
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  };

  const handleImageClick = () => {
    const image = document.querySelector(".play-img-home-about") as HTMLElement | null;
    if (image) {
      image.style.display = "none";
    }
  };

  const handleVideo = () => {
    const video = videoRef.current;
    if (video) {
      if (!video.paused) {
        const image = document.querySelector(".play-img-home-about") as HTMLElement | null;
        if (image) {
          image.style.display = "block";
        }
        video.pause();
      } else {
        video.play();
        const image = document.querySelector(".play-img-home-about") as HTMLElement | null;
        if (image) {
          image.style.display = "none";
        }
      }
    }
  };

  const [suburbOptions, setSuburbOptions] = useState<OptionType[]>([]);
  const [stateOptions, setStateOptions] = useState<OptionType[]>([]);
  const [postCodeOptions, setPostCodeOptions] = useState<OptionType[]>([]);

  const [option, setOption] = useState("")
  const handlePostCodeChange = (selectedOption: SingleValue<OptionType>) => {
    setUser({
      ...user,
      postCode: selectedOption ? selectedOption.value : "",
    });
  };

  // Handle change for Suburb
  const handleSuburbChange = (selectedOption: SingleValue<OptionType>) => {
    setUser({
      ...user,
      suburb: selectedOption ? selectedOption.value : "",
    });
  };

  // Handle change for State
  const handleStateChange = (selectedOption: SingleValue<OptionType>) => {
    setUser({
      ...user,
      state: selectedOption ? selectedOption.value : "",
    });
  };
  // Updated fetchLocations to handle different options
  const fetchLocations = async (query: String) => {
    const queryString = `search=${user.postCode || ""}&suburb=${query || ""}&state=${user.state || ""}`;

    try {
      const response = await axios.get<ApiResponse>(`${process.env.REACT_APP_BASE_URL}/api/postcodes/all?${queryString}`);

      if (response.data) {
        // Assuming response.data.documents could be an array or a single object
        if (Array.isArray(response.data.documents)) {
          // Handle case when documents is an array
          setSuburbOptions(response.data.documents);
          setStateOptions(response.data.documents);

          if (response.data.documents.length > 0) {
            const firstOption = response.data.documents[0];
            setSelectedOption(firstOption);
          } else {
            setSelectedOption(null); // Handle empty array
          }
        } else if (typeof response.data.documents === 'object' && response.data.documents !== null) {
          // Handle case when documents is a single object
          setSelectedOption(response.data.documents as OptionType);
        } else {
          setSelectedOption(null); // Handle other cases (e.g., string)
        }
      }
    } catch (error) {
      console.error("Error fetching locations:", error);
      setSelectedOption(null); // Handle error case
    }
  };





  // useEffect(() => {
  //   fetchLocations();
  // }, [user.postCode, user.suburb, user.state]);



  return (
    <section className="login-page-parent new-reg-parent relative h-screen  overflow-clip">
      <div className={`relative`}>
        <Header />
      </div>
      <img
        decoding="async"
        className="w-full h-full absolute top-0 left-0 z-10  bg-texture-banner"
        src={"https://ndisync.backslashwebs.com/new-register.jpg"}

        alt=""
      />
      <div className={`container-custom h-full relative z-20 flex flex-col justify-center items-center gap-14 reg-comp`}>


        {isEmailSentScreenDisplay ? (
          <EmailSent email={user?.email} />
        ) : (
          <>

            <div className={`w-full flex flex-col -justify-center items-center gap-4  ${user?.role === "provider" ? "mt-20 mb-4" : ""}`}>
              <div className="reg-upper-detail flex flex-col justify-center items-center">
                <h2 className="font-Poppins-Medium text-[#FFFCF1] font-size-30px">Create a new account</h2>
                <div>
                  {/* <span className="text-[#FFE175] font-Poppins-Medium font-size-25px">or sign in to your account </span> */}
                  <Link to="/login-web" className="text-[#FFE175] font-Poppins-Medium font-size-25px underline">or sign in to your account</Link>
                </div>
              </div>
              <div className="login-box-body-parent login-box-body-parent-2 flex flex-col">
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col login-right-inputs-parent"
                >
                  <div className="two-login-fields-parent">
                    <div>
                      <input
                        name="firstname"
                        className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.firstName && validateText(user.firstName)
                          ? "valid"
                          : ""
                          }`}
                        type="text"
                        placeholder="First name"
                        value={user.firstName}
                        onChange={(e) =>
                          handleName("firstName", e.target.value)
                        }
                        onBlur={(e) => {
                          if (
                            !e.target.value ||
                            !validateText(e.target.value)
                          ) {
                            shakeField("firstname", false);
                          }
                        }}
                      />
                    </div>

                    <div>
                      <input
                        name="lastname"
                        className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.lastName && validateText(user.lastName)
                          ? "valid"
                          : ""
                          }`}
                        type="text"
                        placeholder="Last name"
                        value={user.lastName}
                        onChange={(e) =>
                          handleName("lastName", e.target.value)
                        }
                        onBlur={(e) => {
                          if (
                            !e.target.value ||
                            !validateText(e.target.value)
                          ) {
                            shakeField("lastname", false);
                          }
                        }}
                      />
                    </div>
                  </div>

                  <input
                    name="email"
                    className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.email && validateEmail(user.email) ? "valid" : ""
                      }`}
                    type="text"
                    placeholder="Email"
                    value={user.email}
                    onChange={(e) => handleChanges("email", e.target.value)}
                    onBlur={(e) => {
                      if (
                        !e.target.value ||
                        !validateEmail(e.target.value)
                      ) {
                        shakeField("email", false);
                      }
                    }}
                  />

                  <input
                    name="phonenumber"
                    className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.phonenumber.length >= 10 ? "valid" : ""
                      }`}
                    type="text"
                    placeholder="Phone"
                    value={user.phonenumber}
                    onChange={(e) => handlePhone(e.target.value)}
                  />

                  <input
                    name="address"
                    className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.address ? "valid" : ""
                      }`}
                    type="text"
                    placeholder="Address"
                    value={user.address}
                    onChange={(e) =>
                      handleChanges("address", e.target.value)
                    }
                    onBlur={(e) => {
                      if (!e.target.value) {
                        shakeField("address", false);
                      }
                    }}
                  />



                  <div>
                    <Select
                      name="suburb"
                      className="global-select"
                      value={
                        suburbOptions
                          .filter(option => option.suburb === user.suburb)
                          .map(option => ({
                            value: option.suburb, // Use suburb as the unique value key
                            label: option.suburb, // Display only the suburb in the input after selection
                            suburb: option.suburb,
                            state: option.state,
                            postcode: option.postcode,
                          }))[0] || null // Select the first matching suburb or null
                      }
                      onChange={(selectedOption) => {
                        if (selectedOption) {
                          const selectedSuburb = suburbOptions.find(option => option.suburb === selectedOption.value);
                          if (selectedSuburb) {
                            setUser({
                              ...user,
                              suburb: selectedSuburb.suburb,
                              state: selectedSuburb.state,
                              postCode: selectedSuburb.postcode,
                            });
                          }
                        } else {
                          // Clear the fields if the user clears the selection
                          setUser({
                            ...user,
                            suburb: '',
                            state: '',
                            postCode: '',
                          });
                        }
                      }}
                      onInputChange={(inputValue) => {
                        // Call API to fetch suburbs based on the input value
                        fetchLocations(inputValue);
                      }}
                      isClearable={true} // Allow the user to clear the selection
                      options={suburbOptions.map((item) => ({
                        value: item.suburb, // Use suburb as the unique value key for dropdown
                        label: `${item.suburb}, ${item.state}, ${item.postcode}`, // Display suburb, state, and postcode in dropdown options
                        suburb: item.suburb,
                        state: item.state,
                        postcode: item.postcode,
                      }))}
                      placeholder="Suburb"
                      styles={{
                        container: (provided) => ({
                          ...provided,
                          width: '100%', // Set the width to 100%

                        }),
                        control: (provided) => ({
                          ...provided,
                          width: '100%', // Ensure control width is also 100%
                          borderRadius: '12px', // Set the border radius to 12px
                          borderColor: 'rgba(0, 69, 64, 0.44)', // Set the border color if needed
                          boxShadow: 'none', // Remove default shadow if needed
                          '&:hover': {
                            borderColor: 'rgba(0, 69, 64, 0.44)', // Set border color on hover
                          }
                        }),
                        singleValue: (provided) => ({
                          ...provided,

                          color: '#004440"', // Set the text color for selected option
                          fontWeight: '600' // Make the text bold for selected option

                        }),
                        placeholder: (provided) => ({
                          ...provided,
                          color: 'rgba(0, 69, 64, 0.44)', // Set the text color for placeholder
                          fontWeight: '600' // Make the placeholder text bold
                        }),
                        input: (provided) => ({
                          ...provided,
                          color: 'rgba(0, 69, 64, 0.44)', // Set the text color for input text
                          fontWeight: '600' // Make the input text bold
                        }),
                        menu: (provided) => ({
                          ...provided,
                          width: '100%', // Ensure menu width is also 100%
                          borderRadius: '12px' // Set the border radius for the menu
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          borderRadius: '12px' // Ensure menu items have the same border radius
                        })
                      }}
                    />




                  </div>
                  <div className="grid justify-between" style={{ gridTemplateColumns: "49% 49%" }}>

                    <div>
                      <input
                        name="state"
                        className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.address ? "valid" : ""
                          }`}
                        type="text"
                        placeholder="State"
                        value={user?.state}
                        readOnly

                      />
                    </div>
                    <div>
                      <input
                        name="postCode"
                        className={`w-full input-btns-height-60px input-placeholder-green theme-color-green font-Poppins-SemiBold font-size-login-18px login-right-input border-radius-12px login-right-input-box-shadow outline-none ${user.address ? "valid" : ""
                          }`}
                        type="text"
                        placeholder="Postal code"
                        value={user?.postCode}
                        readOnly

                      />
                    </div>
                  </div>

                  <div className="relative">
                    <input
                      name="password"
                      className={`w-full home-banner-input input-placeholder-green input-btns-height-60px border-radius-12px bg-white outline-none font-Poppins-SemiBold login-right-input-box-shadow font-size-login-18px theme-color-green `}
                      placeholder="Password"
                      type={showPassword ? "text" : "password"}
                      value={user.password}
                      onChange={(e) =>
                        handleChanges("password", e.target.value)
                      }
                      onBlur={(e) => {
                        if (!validatePassword(e.target.value)) {
                          shakeField("password", false);
                        }
                      }}
                    />
                    <FontAwesomeIcon
                      icon={showPassword ? faEye : faEyeSlash}
                      className="input-field-eye absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer"
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  {isError && <p style={{ color: "red" }}>{isError}</p>}
                  <div>
                    <div className="flex flex-col items-start gap-3 ">
                      <h4 className="leading-none font-size-login-15px font-Poppins-SemiBold text-white text-center mt-1">
                        Choose your account type
                      </h4>


                      <div className="switches-container-parent">
                        <div className="switches-container">
                          <input
                            type="radio"
                            id="switchMonthly"
                            name="role"
                            value="participant"
                            checked={user?.role === "participant"}
                            onChange={(e) => handleChanges("role", e.target.value)}
                          />
                          <label htmlFor="switchMonthly">Participant</label>

                          <input
                            type="radio"
                            id="switchYearly"
                            name="role"
                            value="provider"
                            checked={user?.role === "provider"}
                            onChange={(e) => handleChanges("role", e.target.value)}
                          />
                          <label htmlFor="switchYearly">Provider</label>

                          <div className="switch-wrapper">
                            <div className="switch">
                              <div className="text-[#004540]">Participant</div>
                              <div className="text-[#004540]">Provider</div>
                            </div>
                          </div>
                        </div>

                      </div>
                      {showSelect && (
                        <>
                          <h4 className="leading-none font-size-login-15px font-Poppins-SemiBold text-white text-center">
                            What describes you best ?
                          </h4>
                          <select
                            name="roleCategory"
                            onChange={(e) => handleCategory("roleCategory", e.target.value)}
                            id="countries" className=" w-full home-banner-input input-placeholder-green input-btns-height-60px border-radius-12px bg-white outline-none font-Poppins-SemiBold login-right-input-box-shadow font-size-login-18px" style={{ color: "#00454070" }}>
                            <option selected value="">Category</option>
                            <option value="support worker">Support worker</option>
                            <option value="plan manager">Plan manager</option>
                            <option value="support coordinator">Support coordinator</option>
                            <option value="allied health">Allied health</option>
                          </select>
                        </>
                      )}
                    </div>
                  </div>
                  {/* <span className="font-Poppins-Regular text-center">
                  Recaptcha
                </span> */}
                  <div className="flex justify-center">
                    {/* <img className="w-[5vw] " src={Recaptcha} /> */}
                    {/* <ReCAPTCHA
                    ref={recaptchaRef}
                    size="normal"
                    sitekey="6LeWeRkqAAAAAID40ptGMJJVTNnjPzeDNH412Wdw"
                    onChange={onReCAPTCHAChange}
                  /> */}
                  </div>
                  <button
                    type="submit"
                    className=" text-white  font-size-login-18px font-Poppins-SemiBold login-page-btn flex items-center justify-center gap-2 border-radius-20px theme-bg-green-light"
                    disabled={isLoader}

                  >
                    Create account
                    <FontAwesomeIcon size="lg" icon={faArrowRight} />
                    {isLoader && (
                      <CircularProgress
                        style={{ color: "#fff" }}
                        className="btn__loader"
                      />
                    )}
                  </button>
                </form>
              </div>
            </div>
          </>
        )}


      </div>
    </section>
  );
};

export default LoginComponent;
