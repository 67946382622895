// import axios from 'axios';
// import { getCookie, removeCookie } from "./helpers";
// import { toast } from 'react-toastify';

// const _baseUrl = process.env.REACT_APP_BASE_URL as string;

// interface HttpRequestParams {
//     header?: { [key: string]: string };
//     method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
//     path?: string;
//     params?: any;
//     baseUrl?: string;
//     token?: string;
//     navigate?: Function;
// }

// interface HttpResponse {
//     res?: ApiResponse;
//     err?: any;
// }

// interface ApiResponse {
//     token: boolean;
//     documents: string | object; // Replace `any` with the appropriate type
//     paginated: string | { totalPages: number };
// }

// export default async function httpRequest({
//     header = { 'Content-Type': 'application/json' },
//     method = 'get',
//     path = '',
//     params = null,
//     baseUrl = _baseUrl,
//     token = getCookie(),
//     navigate,
// }: HttpRequestParams): Promise<HttpResponse> {
//     const headers = {
//         ...header,
//         ...(token && { Authorization: `Bearer ${token}` }),
//     };

//     try {
//         let response;
//         if (['post', 'put', 'patch'].includes(method)) {
//             response = await axios({ method, url: baseUrl + path, data: params, headers });
//         } else {
//             response = await axios({ method, url: baseUrl + path, headers });
//         }
//         // Ensure the response is of the correct type
//         const apiResponse: ApiResponse = response?.data;
//         return { res: apiResponse };
//     } catch (error: any) {
//         if (error?.response?.status === 440) {
//             toast("Session Expired", { type: 'error' });
//             removeCookie();
//         } else if (error?.response?.status === 403) {
//             return { err: 403 };
//         } else if (error?.response?.status === 402) {
//             return { err: { status: 402, message: error?.response?.data?.message || error?.response?.data || "Error" } };
//         } else if (error?.response?.status === 406) {
//             return { err: { status: 406, message: error?.response?.data?.message || error?.response?.data || "Error" } };
//         }
//         return { err: error?.response?.data?.message || error?.response?.data || "Error" };
//     }
// }
import axios from 'axios';
import { getCookie, logOutWithOutApi, removeCookie } from "./helpers";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UseDispatch } from 'react-redux';

const _baseUrl = process.env.REACT_APP_BASE_URL as string;
let hasDeactivationToastShown = false;

interface HttpRequestParams {
    header?: { [key: string]: string };
    method?: 'get' | 'post' | 'put' | 'patch' | 'delete';
    path?: string;
    params?: any;
    baseUrl?: string;
    token?: string;
    navigate?: Function;
    dispatch?: Function; // Add dispatch as an optional parameter

}

interface HttpResponse<T> {
    res?: T;
    err?: any;
}

interface ApiResponse {
    totalResults: number
    timeExpires: string | number;
    token: boolean;
    documents: string | object; // Replace `any` with the appropriate type
    paginated: string | { totalPages: number };
}

export default async function httpRequest<T>({
    header = { 'Content-Type': 'application/json' },
    method = 'get',
    path = '',
    params = null,
    baseUrl = _baseUrl,
    token = getCookie(),
    navigate,
    dispatch,

}: HttpRequestParams): Promise<HttpResponse<T>> {
    const headers = {
        ...header,
        ...(token && { Authorization: `Bearer ${token}` }),
    };

    try {
        let response;
        if (['post', 'put', 'patch'].includes(method)) {
            response = await axios({ method, url: baseUrl + path, data: params, headers });
        } else {
            response = await axios({ method, url: baseUrl + path, headers });
        }

        // Ensure the response is of the correct type
        const apiResponse = response.data as T;
        return { res: apiResponse };
    } catch (error: any) {
        // Handle errors without axios-specific handling
        if (error.response) {
            const errResponse = error.response.data as { message?: string };
            const status = error.response.status;

            if (error?.response?.status === 440) {
                removeCookie();
                if (dispatch) {
                    dispatch(logOutWithOutApi());
                }

                toast("Session Expired", { type: 'error' });
                await logOutWithOutApi();
                if (navigate) {
                    navigate("/login-web");
                }
            }
            if (error?.response?.status === 444) {

                // toast("Account deactivated", { type: 'error' });
                // await logOutWithOutApi();
                // if (navigate) {
                //     navigate("/login-web");
                // }
                if (!hasDeactivationToastShown) {
                    toast("Account deactivated", { type: 'error' });
                    hasDeactivationToastShown = true; // Set flag to prevent multiple toasts
                    setTimeout(() => { hasDeactivationToastShown = false; }, 5000); // Reset after 5 seconds (optional)

                    await logOutWithOutApi();
                    if (navigate) {
                        navigate("/login-web");
                    }
                }
            }
            if (error?.response?.status === 415) {


                if (!hasDeactivationToastShown) {
                    toast("Account deleted", { type: 'error' });
                    hasDeactivationToastShown = true; // Set flag to prevent multiple toasts
                    setTimeout(() => { hasDeactivationToastShown = false; }, 5000); // Reset after 5 seconds (optional)

                    await logOutWithOutApi();
                    if (navigate) {
                        console.log(1)
                        navigate("/admin-login");
                        console.log(2)
                        debugger
                    }

                }
            }
            else if (error?.response?.status === 403) {
                return { err: { status: 403, message: error?.response?.data?.message || error?.response?.data || "Error" } };
            }

            else if (error?.response?.status === 400) {

                return { err: { status: 400, message: error?.response?.data?.message?.message || error?.response?.data || "Error" } };
            }
            else if (error?.response?.status === 401) {

                return { err: { status: 401, message: error?.response?.data?.message || error?.response?.data || "Error" } };
            }
            else if (error?.response?.status === 402) {
                return { err: { status: 402, message: error?.response?.data?.message || error?.response?.data || "Error" } };
            }
            else if (error?.response?.status === 406) {
                return { err: { status: 406, message: error?.response?.data?.message || error?.response?.data || "Error" } };
            }
            else if (error?.response?.status === 404) {
                return { err: { status: 404, message: error?.response?.data?.message || error?.response?.data || "Error" } };
            }
            else if (error?.response?.status === 422) {
                return { err: { status: 422, message: error?.response?.data?.message?.error?.email || error?.response?.data || "Error" } };
            }
            else if (error?.response?.status === 409) {
                return { err: { status: 409, message: error?.response?.data?.message || error?.response?.data || "Error" } };
            }
        }
        return { err: error.message || "An unexpected error occurred" };

    }
}
