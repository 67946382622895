import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import Discussionpost from "../../components/Discussions/Discussionpost";

import { useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { toast } from "react-toastify";
import DiscussionSidebar from "../../components/Discussions/DiscussionSidebar";
import { useDispatch } from "react-redux";
import {
  resetDiscussion,
  setDiscussion,
} from "../../config/Store/Reducers/DiscussionSlice";
import httpRequest from "../../config/Helpers/httpRequest";
interface Documents {
  title: string;
  description: string;
  [key: string]: any;
}
interface ApiResponse {
  documents: Documents[];
  message: string;
  [key: string]: any;

}

function DiscussionView() {
  const dispatch = useDispatch();
  let { dId } = useParams(); // discussion id

  const [isLoader, setIsLoader] = useState(false); // for API loader

  // Functionality: API call for discussions detail API by discussion ID
  const fetchDetail = async () => {
    setIsLoader(true); // show loader
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/discussion/details/${dId}`,
    });
    if (res) {
      dispatch(setDiscussion(res?.documents[0] || {})); // set in redux slice
    } else {
      toast(err?.message, { type: "error" }); // if error then display the error
    }
    setIsLoader(false); // hide loader
  };

  useEffect(() => {
    fetchDetail();

    return () => {
      dispatch(resetDiscussion());
    };
  }, []);

  return (
    <div>
      <HeaderGlobal />
      {isLoader && (
        <div className="full_page_loader">
          <CircularProgress style={{ color: "#fff" }} />
        </div>
      )}
      <div className="container-1480 block justify-between h-3vw lg:flex md:flex gap-3">
        <div className="w-full">
          <Discussionpost dId={dId} />
        </div>
        <DiscussionSidebar fetchLoggedInUserData={null} />
      </div>
    </div>
  );
}

export default DiscussionView;
