/**
 * ReportOptions Component
  * Handles displaying a modal for reporting posts with various options and
 * makes an API call to submit the report.
 * File: ReportOptions.js
 * Author: Developer
 * Date: 04-07-24
 */

import React, { useEffect, useState } from 'react';
import closeIcon from "../../assets/img/closeIcon.svg";
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
import { CircularProgress } from '@mui/material';
import { removePostById } from '../../config/Store/Reducers/newsFeedSlice';
import { useDispatch, useSelector } from "react-redux"; // Redux hooks for state management

interface ReportOptionsProps {
    id?: string;  // `id` should be a string if provided
    onClose?: () => void;  // `onClose` is a function that can be called when closing the modal
    type?: string;  // `type` should be a string if provided
    category?: string;  // `category` should be a string if provided
}
interface ApiResponse {
    message: string;
    [key: string]: any;
}
// Define the state type for the component
interface ReportState {
    reportedId: string;
    subject: string;
    reportedCategory: string;
}

const ReportOptions: React.FC<ReportOptionsProps> = ({
    id = '',
    onClose = () => { },
    type = "",
    category = ""
}) => {

    const dispatch = useDispatch()
    const activeClass = {
        backgroundColor: "#004540",
        color: "#fff"
    }; // Styles for active state of selected options

    let options = ['Harassment', 'Fraud or scam', 'Spam', 'Misinformation', 'Hateful speech', 'Threats or violence', 'Self-harm', 'Graphic content', 'Sexual content', 'Fake account', 'Hacked account', 'Child exploitation', 'Illegal services', 'Infringement'
    ]; // Array of options corresponding to backend enum for reporting reasons

    const [isLoader, setIsLoader] = useState<boolean>(false); // State for loader indication during API requests
    const [state, setState] = useState<ReportState>({
        reportedId: '',
        subject: '',
        reportedCategory: ''
    }); // State object representing data for API request body


    const handleClose = () => {
        setState({
            reportedId: '',
            // reportedType: '',
            subject: '',
            reportedCategory: ''
        });
        onClose();
    }; // Function to reset state and close the report modal

    // Functionality: Handles API call to submit report
    const handleSubmit = async () => {
        const payload = {
            ...state,
            subject: state?.subject?.toLowerCase() // Convert to lowercase
        }
        if (!state?.subject) {
            toast("Please select reason", { type: "error" });
        } else {
            setIsLoader(true);
            const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/report/content", params: payload });
            if (res) {
                toast(res?.message || "Success", { type: "success" });
                handleClose();
                dispatch(removePostById(state.reportedId));
            } else if (err) {
                toast(err?.message, { type: "error" });
            } else { }
            setIsLoader(false);
        };
    };

    useEffect(() => {
        if (id) {
            setState(prev => ({
                ...prev,
                reportedId: id,
                contentType: type,
                reportedCategory: category
            }));
        };
    }, [id]); // Effect to update state on component mount or when id changes



    return (
        <div className="ovrlayModal modal">
            <div className="createpost-modal absolute z-20">
                <button
                    className="absolute -top-5 font-size-24px"
                    style={{ right: "-10px" }}
                    onClick={handleClose}
                >
                    <img src={closeIcon} alt="" />
                </button>{" "}
                {/* close post modal */}
                <div className="mb-3">
                    <h1 className="font-size-20px font-Poppins-SemiBold theme-color-green ">
                        Report this {type === "comment" ? "comment" : "post"}
                    </h1>
                    <hr className="mt-3" />
                </div>
                <p className="font-size-16px font-Poppins-Medium theme-color-green">
                    Select a reason to report this {type === "comment" ? "comment" : "post"}
                </p>
                <div className="flex gap-2 mt-3 flex-wrap">
                    {options.map(v => (
                        <button onClick={() => setState(prev => ({ ...prev, subject: v }))} key={v} className={`font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn `} style={state?.subject === v ? activeClass : {}}>
                            {v}
                        </button>
                    ))}
                </div>
                <hr className="mt-3" />
                <div className="flex justify-end gap-2 mt-3">
                    <button
                        className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                        style={{ color: "#fff" }}
                        onClick={handleClose}
                    >
                        Back
                    </button>
                    <button
                        className="theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                        style={{ color: "#fff" }}
                        onClick={handleSubmit}
                        disabled={isLoader}
                    >
                        Submit report {isLoader && <CircularProgress style={{ width: 14, height: 14, color: "#fff" }} />}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportOptions;