import React from 'react';
import Skeleton from '@mui/material/Skeleton';

function ReWriteLoader({ extraStyle = {} }) {
    return (
        <div style={extraStyle}>
            <Skeleton variant="rectangular" className="mb-1" height={10} />
            <Skeleton variant="rectangular" className="mb-1" height={10} />
            <Skeleton variant="rectangular" className="mb-1" height={10} />
            <Skeleton variant="rectangular" height={10} width={120} />
        </div>
    );
};

export default ReWriteLoader;