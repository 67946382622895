/**
 * AdminShowNumbers Component
 * 
 * This component displays phone numbers for a job poster and an applicant,
 * allowing the admin to perform actions such as copying the number to the clipboard and
 * logging calls with predefined messages.
 * 
 * File Name: AdminShowNumbers.js
 * Author: Developer
 * Date: 2024-04-18
 */

import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faX, faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import httpRequest from "../../config/Helpers/httpRequest";
import wetalked from "../../assets/img/wetalked.svg";
import voicemail from "../../assets/img/voicemail.svg";
import noanswer from "../../assets/img/no-answer.svg";

function AdminShowNumbers({
  creator,
  applicant,
  onClose,
  jobId,
  successFunction,
}) {
  const dummyArr = [
    {
      message: "No answer",
      // icon: "https://solidificatiosolution.backslashwebs.com/noanswer-icon.png",
      icon: noanswer,
    },
    {
      message: "left voicemail",
      // icon: "https://solidificatiosolution.backslashwebs.com/leftvoicemail-icon.png",
      icon: voicemail,
    },
    {
      message: "We talked",
      // icon: "https://solidificatiosolution.backslashwebs.com/wetalked-icon.png",
      icon: wetalked,
    },
  ];

  const handleCopyNumber = async (text) => {
    try {
      const textarea = document.createElement("textarea");
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand("copy");
      document.body.removeChild(textarea);
      toast("Copied to clipboard.", { type: "success" });
    } catch (err) {
      toast("Something went wrong.", { type: "error" });
    }
  };

  // Functionality: API call "CALL" for job history log
  const handleCall = async (message, user) => {
    let { firstName, lastName } = user;
    const { res, err } = await httpRequest({
      method: "post",
      path: "/api/job/add-comment",
      params: {
        jobId: jobId,
        title: "call",
        message: `Admin call ${firstName} ${lastName} ${message}`,
      }
    })
    if (res) {
      onClose();
      successFunction(res?.comment);
      toast(res?.message || "Success", { type: "success" });
    } else {
      toast(err?.message, { type: "error" })
    }
  };
  return (
    <>
      <div className="modal">
        <div className="Cancellation-modal-content relative">
          <button onClick={onClose} className="absolute job-pre-edit">
            <FontAwesomeIcon
              className="x-icon-admin-opr"
              icon={faX}
              style={{ color: "#ffffff" }}
            />
          </button>
          <div className="admin-shownum">
            <div>
              <h2 className="font-Poppins-SemiBold font-size-30px  text-[#464646] capitalize">
                Job Poster
              </h2>
              <div className="flex flex-row items-center justify-center gap-3 mt-3 mb-3">
                <span className="font-Poppins-SemiBold  text-[#00443f]">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <span className="font-Poppins-SemiBold font-size-20px text-[#00443f]">
                  {creator?.phonenumber}
                </span>
                <span
                  role="button"
                  onClick={() => handleCopyNumber(`${creator?.phonenumber}`)}
                  className="font-Poppins-SemiBold  text-[#00443f]"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              </div>
              <span className="font-Poppins-Medium font-size-16px text-[#202020] mt-2 mb-2">
                Update your progress
              </span>

              <div className="cancellation-envolpe-main cancellation-envolpe-main-2 gap-3  mt-3 mb-3">
                {dummyArr.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleCall(item?.message, creator)}
                    role="button"
                    className="flex flex-col justify-center items-center"
                  >
                    <img className="admin-see-num" src={item?.icon} />
                    <span className="font-Poppins-Medium font-size-15px  text-[#ef6277] admin-see-detail-num">
                      {item?.message}
                    </span>
                  </div>
                ))}
              </div>
            </div>
            <div className="applicant-num"></div>
            <div className="">
              <h2 className="font-Poppins-SemiBold font-size-30px  text-[#464646] capitalize">
                Applicant
              </h2>
              <div className="flex flex-row items-center justify-center gap-3 mt-3 mb-3">
                <span className="font-Poppins-SemiBold  text-[#00443f]">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <span className="font-Poppins-SemiBold font-size-20px text-[#00443f]">
                  {applicant?.phonenumber}
                </span>
                <span
                  role="button"
                  onClick={() => handleCopyNumber(`${applicant?.phonenumber}`)}
                  className="font-Poppins-SemiBold  text-[#00443f]"
                >
                  <FontAwesomeIcon icon={faCopy} />
                </span>
              </div>
              <span className="font-Poppins-Medium font-size-16px text-[#202020] mt-2 mb-2">
                Update your progress
              </span>

              <div className="cancellation-envolpe-main cancellation-envolpe-main-2 gap-3  mt-3 mb-3">
                {dummyArr.map((item, index) => (
                  <div
                    key={index}
                    onClick={() => handleCall(item?.message, applicant)}
                    role="button"
                    className="flex flex-col justify-center items-center"
                  >
                    <img className="admin-see-num" src={item?.icon} />
                    <span className="font-Poppins-Medium font-size-15px  text-[#ef6277] admin-see-detail-num">
                      {item?.message}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminShowNumbers;