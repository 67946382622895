/**
 * RemoveButton Component
 * 
 * This component renders a button that removes a connection when clicked.
 * It uses React hooks for state management and displays a loader while the request is being processed.
 * 
 * File Name: RemoveButton.jsx
 * Author: Developer
 * Date: 2024-04-12
 */

import React, { useState } from 'react';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
interface RemoveButtonProps {
    id: string;
    onSuccess: (nextStatus: string) => void;
}

interface ApiResponse {
    message: string;
    nextStatus: string;
    status: string;
}
/**
 * RemoveButton Component
 * 
 * @param {string} id - The ID of the user to remove the connection for.
 * @param {function} onSuccess - Callback function to be called upon a successful removal.
 */
const RemoveButton: React.FC<RemoveButtonProps> = ({ id = "", onSuccess = () => { } }) => {
    const [isLoader, setIsLoader] = useState<string>("");

    // Functionality: API call for connection removed
    const sendConnectionRequest = async () => {
        setIsLoader(id);
        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/connection/remove", params: { recieverId: id } });
        if (res) {
            toast(res?.message || "Success", { type: "success" });
            onSuccess(res?.nextStatus || "");
        } else {
            toast(err?.message, { type: "error" })
        }
        setIsLoader("");
    }
    return (
        <button onClick={sendConnectionRequest} className="font-size-18px theme-color-green  font-Poppins-Medium feeds-btn  hover:text-white hover:bg-[#00443F]" type="button">
            Remove connection
            {isLoader === id && <CircularProgress style={{ color: '#fff', width: 14, height: 14, marginLeft: 6 }} />}
        </button>
    );
};

export default RemoveButton;