/**
 * JobDetails Component
 *
 * Manages job details including fetching job data, handling modals for
 * job actions (call, comment, review, reschedule, cancel, dispute), and
 * displaying job-related information.
 *
 * File: JobDetails.jsx
 * Author: Developer
 * Date: 04-07-24
 */

import React, { useEffect, useState } from "react";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import { useParams, useNavigate } from "react-router-dom";
import { convertTo12Hour, getRole } from "../../config/Helpers/helpers";
import httpRequest from "../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import {
  calculateAge,
  getTimeDifference,
  formatDate,
} from "../../config/Helpers/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faVenus,
  faClock,
  faCalendar,
  faPhone,
  faEnvelope,
  faCalendarDays,
  faStar,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import JobRequestAndHistory from "../../components/JobsComponents/JobRequestAndHistory";
import ShowNumber from "../../components/AllDashboardPagesComponents/ShowNumber";
import AddCommentJob from "../../components/JobsComponents/AddCommentJob";
import JobReviewModal from "../../components/JobsComponents/JobReviewModal";
import JobCompleteRequest from "../../components/JobsComponents/JobCompleteRequest";
import JobCancelButton from "../../components/JobsComponents/JobCancelButton";
import JobRescheduleModal from "../../components/JobsComponents/JobRescheduleModal";
import StartJobDispute from "../../components/JobsComponents/StartJobDispute";
import JobReopen from "../../components/JobsComponents/JobReopen";

interface UserPreferences {
  preferencemale?: boolean;
  preferencefemale?: boolean;
  preferencenopet?: boolean;
  preferencenonsmoker?: boolean;
  [key: string]: any;
}

interface JobDetailsType {
  jobCreatorDetails?: {
    profileImageUrl?: string;
    firstName?: string;
    lastName?: string;
    role?: string;
    roleCategory?: string;
    dateofbirth?: string;
    gender?: string;
    _id?: string;
    [key: string]: any;
  };
  applicantDetails?: {
    profileImageUrl?: string;
    firstName?: string;
    lastName?: string;
    roleCategory?: string;
    dateofbirth?: string;
    gender?: string;
    _id?: string;
    [key: string]: any;
  };
  city?: string;
  state?: string;
  postCode?: string;
  repeatCycle?: number;
  startDate?: string;
  endDate?: string;
  description?: string;
  budget?: number;
  status?: string;
  service?: {
    servicename?: string;
  };
  createdAt?: string;
  _id?: string;
  [key: string]: any;
}
interface ApiResponse {
  document: [];
}
const JobDetails: React.FC = () => {
  const navigate = useNavigate();
  const { jid } = useParams();
  // let { userId } = getRole();
  const decoded = getRole();
  const userId = decoded?.userId;


  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [item, setItem] = useState<JobDetailsType>({});
  const [logUpdated, setLogUpdated] = useState<boolean>(false);
  const [callModal, setCallModal] = useState<boolean>(false);
  const [commentModal, setCommentModal] = useState<boolean>(false);
  const [reviewModal, setReviewModal] = useState<boolean>(false);
  const [rescheduleModal, setRescheduleModal] = useState<boolean>(false);
  const [disputeModal, setDisputeModal] = useState<boolean>(false);

  // Checks and formats user preferences for display based on provided user data
  const checkPreferences = (user: UserPreferences | undefined) => {
    if (!user) return "-"; // Return an empty string if preferences are not provided

    const {
      preferencemale,
      preferencefemale,
      preferencenopet,
      preferencenonsmoker,
    } = user;

    // Check for specific combinations and return the corresponding HTML string
    if (
      !preferencemale &&
      !preferencefemale &&
      !preferencenopet &&
      !preferencenonsmoker
    ) {
      return "-";
    } else {
      let result = "";

      if (preferencemale && preferencefemale) {
        result += "Male & Female";
      } else if (preferencemale) {
        result += "Only Male";
      } else if (preferencefemale) {
        result += "Only Female";
      }

      if (preferencenopet) {
        if (result !== "") result += ", ";
        result += "No Pets";
      }

      if (preferencenonsmoker) {
        if (result !== "") result += ", ";
        result += "Non Smoker";
      }
      return result;
    }
  };

  // Updates state to reflect successful API call response
  const handleCallResponseSuccess = (msg: string) => {
    setLogUpdated((prev) => !prev);
  };

  // Updates state after successfully rescheduling a job and shows a success toast message
  const handleRescheduleResponseSuccess = (msg: string) => {
    setLogUpdated((prev) => !prev);
    toast("Job reschedule request has been sent!", { type: "success" });
  };

  // Functionality: Fetches job details from the API based on the job ID in URL parameters
  const fetchJobDetails = async () => {
    setIsLoader(true);
    const { res, err } = await httpRequest<ApiResponse>({
      path: `/api/job/overview/${jid}`,
    });
    if (res) {
      setItem(res?.document || {});
    } else {
      if (err === 403) {
        navigate(-1);
        toast("You are not authorized to access this page.", { type: "error" });
        return;
      }
      toast(err?.message, { type: "error" });
    }
    setIsLoader(false);
  };

  useEffect(() => {
    fetchJobDetails();
  }, [jid]);

  return (
    <>
      <div className="">
        <HeaderGlobal />
        <div className="container-1480 h-3vw">
          <div className="admin-job-review-stat">
            <div className="admin-preview-head flex items-center justify-end">
              <p className="font-Poppins-Medium font-size-12px text-[#ffffff] pr-2">
                Posted {getTimeDifference(item?.createdAt || "")}
              </p>
            </div>
            <div className="job__details__main p-4">
              <div className="creater__content">
                <img
                  src={`${item?.jobCreatorDetails?.profileImageUrl}`}
                  // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.jobCreatorDetails?.profileImageUrl}`}
                  alt=""
                  className="profile__image"
                />
                <div className="mt-4">
                  <p className="font-Poppins-SemiBold font-size-12px text-[#000000]">
                    Posted by
                  </p>
                  <p className="font-Poppins-SemiBold font-size-12px text-[#00443f] mt-1 capitalize">{`${item?.jobCreatorDetails?.firstName || ""
                    } ${item?.jobCreatorDetails?.lastName || ""}`}</p>
                  <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize-first-letter mt-1">
                    {item?.jobCreatorDetails?.role === "provider"
                      ? item?.jobCreatorDetails?.roleCategory
                      : item?.jobCreatorRole}
                  </p>
                  {item?.jobCreatorDetails?.dateofbirth &&
                    <p className="font-Poppins-SemiBold font-size-12px text-[#000000] mt-1">
                      {calculateAge(item?.jobCreatorDetails?.dateofbirth || "-")} Years old

                    </p>
                  }

                  <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize">
                    {item?.jobCreatorDetails?.gender || "-"}
                  </p>
                </div>
              </div>
              <div className="job__detail__location text-[#646464]">
                <div className="px-3">
                  <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                    <FontAwesomeIcon icon={faLocationDot} />
                    <p>{`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""
                      }`}</p>
                  </div>
                  <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                    <FontAwesomeIcon icon={faVenus} />
                    <p>{checkPreferences(item?.jobCreatorDetails)}</p>
                  </div>
                  <div className="mt-5 mb-5">
                    <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                      <FontAwesomeIcon icon={faClock} />
                      <p>
                        {item?.repeatCycle == 1
                          ? "One-off"
                          : item?.repeatCycle == 2
                            ? "Daily"
                            : item?.repeatCycle == 3
                              ? "Weekly"
                              : item?.repeatCycle == 4
                                ? "Monthly"
                                : "Custom"}
                      </p>
                    </div>
                  </div>
                  <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>Start date: {formatDate(item?.startDate || "")}</p>
                  </div>
                  <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>End date: {formatDate(item?.endDate || "")}</p>
                  </div>
                  <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>Start time: {convertTo12Hour((item?.startTime || ""))}</p>
                  </div>
                  <div className="job-pre-mountain font-Poppins-Regular font-size-15px">
                    <FontAwesomeIcon icon={faCalendar} />
                    <p>End time: {convertTo12Hour((item?.endTime || ""))}</p>
                  </div>
                </div>
              </div>
              <div className="job__detail__location text-[#646464]">
                <div className="px-3 items__between">
                  <div>
                    <h2 className="font-Poppins-Medium text-black font-size-22px capitalize">
                      {item?.service?.servicename || "-"}
                    </h2>
                    <p className="font-Poppins-Regular font-size-12px mt-2 mb-2">
                      {item?.description || ""}
                    </p>
                  </div>
                  <div className="flex">
                    <div className="job-pre-perhour-adminside">
                      <span className=" text-white font-Poppins-Regular font-size-14px">
                        $ {item?.budget || 0}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="applicant__content">
                <img
                  src={`${item?.applicantDetails?.profileImageUrl}`}
                  // src={`${process.env.REACT_APP_BASE_URL_IMAGE}${item?.applicantDetails?.profileImageUrl}`}
                  alt=""
                  className="profile__image"
                />
                <div className="mt-4">
                  <p className="font-Poppins-SemiBold font-size-12px text-[#000000]">
                    Assigned to
                  </p>
                  <p className="font-Poppins-SemiBold font-size-12px text-[#00443f] mt-1 capitalize">{`${item?.applicantDetails?.firstName || ""
                    } ${item?.applicantDetails?.lastName || ""}`}</p>
                  <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize-first-letter mt-1">
                    {item?.jobCreatorDetails?.role === "provider"
                      ? item?.applicantDetails?.roleCategory
                      : item?.jobCreatorRole}
                  </p>
                  {item?.applicantDetails?.dateofbirth &&

                    <p className="font-Poppins-SemiBold font-size-12px text-[#000000] mt-1">
                      {calculateAge(item?.applicantDetails?.dateofbirth || "-")} Years old

                    </p>
                  }

                  <p className="font-Poppins-SemiBold font-size-12px text-[#000000] capitalize">
                    {item?.applicantDetails?.gender || "-"}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-row justify-center gap-4 flex-wrap">
              <button
                onClick={() => setCallModal(true)}
                className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
              >
                {" "}
                <FontAwesomeIcon icon={faPhone} />
                Call
              </button>

              <button
                onClick={() => setCommentModal(true)}
                className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
              >
                <FontAwesomeIcon icon={faEnvelope} />
                Comment
              </button>
              <button
                onClick={() => setReviewModal(true)}
                className="text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
              >
                <FontAwesomeIcon icon={faStar} />
                Review
              </button>
              {item?.status === "cancelled" &&
                < button
                  onClick={() => setDisputeModal(true)}
                  className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                >
                  <FontAwesomeIcon
                    className="exclamation-mark"
                    icon={faExclamation}
                  />
                  Start dispute
                </button>

              }
              {item?.status === "cancelled" && userId === item?.jobCreatorDetails?._id &&
                <JobReopen
                  jobId={item?._id || ""}
                  cancelledBy={item?.cancelledBy || ""}
                />

              }

              {item?.status === "onboard" && (
                <>
                  <button
                    onClick={() => setRescheduleModal(true)}
                    className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                  >
                    <FontAwesomeIcon icon={faCalendarDays} />
                    Reschedule
                  </button>

                  <JobCompleteRequest
                    jobId={item?._id}
                    // completedFucntion={handleCallResponseSuccess}
                    completedFucntion={() => handleCallResponseSuccess}

                    btnClass={
                      "height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                    }
                  />

                  <JobCancelButton
                    jobId={item?._id}
                    setActiveTab={(e) =>
                      setItem((prev) => ({ ...prev, status: e }))
                    }
                    btnClass={
                      "height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                    }
                  />
                  <button
                    onClick={() => setDisputeModal(true)}
                    className="height-btn text-white theme-bg-green font-size-16px font-Poppins-Regular border-radius-6px profile-page-btn w-max mt-4 flex gap-4"
                  >
                    <FontAwesomeIcon
                      className="exclamation-mark"
                      icon={faExclamation}
                    />
                    Start dispute
                  </button>
                </>
              )}
            </div>

            <JobRequestAndHistory
              opponentData={
                userId === item?.applicantDetails?._id
                  ? item?.jobCreatorDetails
                  : item?.applicantDetails
              }
              jobId={jid || ""}
              logUpdated={logUpdated}
              setLogUpdated={setLogUpdated}
            />
          </div>
        </div>
      </div >

      {callModal && (
        <ShowNumber
          onClose={() => setCallModal(false)}
          data={item?.jobCreatorDetails || {}}
          jobId={item?._id || ""}
          successFunction={handleCallResponseSuccess}
        />
      )
      }
      {
        commentModal && (
          <AddCommentJob
            onClose={() => setCommentModal(false)}
            jobId={item?._id || ""}
            successFunction={handleCallResponseSuccess}
          />
        )
      }
      {
        reviewModal && (
          <JobReviewModal
            onCLose={() => setReviewModal(false)}
            isEdit={false}
            jobId={item?._id || ""}
            user={userId === item?.applicantDetails?._id ? item?.jobCreatorDetails || {} as any : item?.applicantDetails || {} as any}  // Ensure a User object is always passed
            successFunction={handleCallResponseSuccess}
          />
        )
      }

      {
        rescheduleModal && (
          <JobRescheduleModal
            onClose={() => setRescheduleModal(false)}
            jobId={item?._id || ""}
            startDate={item?.startDate || ""}
            startTime={item?.startTime || ""}
            endDate={item?.endDate || ""}
            endTime={item?.endTime || ""}
            successFunction={handleRescheduleResponseSuccess}
          />
        )
      }
      {
        disputeModal && (
          <StartJobDispute
            onClose={() => setDisputeModal(false)}
            jobId={item?._id || ""}
            successFunction={handleCallResponseSuccess}
          />
        )
      }
    </>
  );
}

export default JobDetails;
