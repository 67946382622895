
import React, { useState, useEffect } from 'react';
import { PieChart as MuiPieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import httpRequest from '../../config/Helpers/httpRequest';

interface IApiResponse {
    documents: {
        _id: string;
        roleCount: number;
        [key: string]: any;

    }[];
    totalUsers: number;
    [key: string]: any;

}

function ProvidersChart() {
    const [data, setData] = useState<{ label: string; value: number; color: string }[]>([]);
    const [totalUser, setTotalUser] = useState<number>(0);
    const [query, setQuery] = useState<string>("week"); // Default to "week"
    const [state, setState] = useState<string>("");

    const handleCheckboxChange = (value: string) => {
        setQuery(value);
    };


    const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = event.target.value;
        setState(selectedValue);
    };

    const fetchProviderGraph = async () => {
        const { res, err } = await httpRequest<IApiResponse>({
            method: "get",
            path: `/api/admin/user/provider-graph?groupBy=${query}&state=${state}`
        });

        if (res) {
            // Map the API response to match the PieChart data format
            const transformedData = res.documents.map(doc => {
                return {
                    
                    label: doc?._id, // The role name (e.g., "support worker")
                    value: doc?.roleCount, // The count of users for that role
                    color: getColorForRole(doc?._id) // Assign color based on the role name
                };
            });

            setData(transformedData);
            setTotalUser(res?.totalUsers ?? 0); // Default to 0 if undefined
        } else {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchProviderGraph();
    }, [query, state]); // Re-fetch data when 'query' or 'state' changes

    const calculatePercentage = (value: number, total: number) => {
        return ((value / total) * 100).toFixed(1);
    };

    const getColorForRole = (role: string): string => {
        // Assign colors based on the role name
        switch (role) {
            case 'support worker': return '#015958';
            case 'support coordinator': return '#008f8c';
            case 'plan manager': return '#0caba8';
            case 'allied health': return '#0fc2c0';
            default: return '#cccccc'; // Default color if role is unknown
        }
    };

    const totalValue = data.reduce((sum, item) => sum + item.value, 0);

    return (
        <>
            <div className="hidelabelGraph" style={styles.textContainer}>
                {/* <MuiPieChart
                    series={[
                        {
                            arcLabel: (item) => {
                                const percentage = calculatePercentage(item.value, totalValue);
                                return `${percentage}%`;
                            },
                            arcLabelMinAngle: 45,
                            data,
                        },
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: '#FFFFFF', // Custom text color
                            fontWeight: 'bold',
                            fontSize: '16px', // Custom text size
                        },
                    }}
                /> */}
                <MuiPieChart
                    series={[
                        {
                            arcLabel: (item) => {
                                const percentage = calculatePercentage(item.value, totalValue);
                                return `${percentage}%`;
                            },
                            arcLabelMinAngle: 45,
                            data,
                        },
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                            fill: '#FFFFFF',
                            fontWeight: 'bold',
                            fontSize: '16px',
                        },
                    }}
                />
                <div className="pie-cart-contaent">
                    <div className="total-providers-count font-Poppins-Regular font-size-14px">
                        Total providers: <strong className="font-Poppins-SemiBold text-[#003E3A] font-size-16px">{totalUser}</strong>
                    </div>
                    <div className="flex gap-4 my-2">
                        <label className='text-nowrap flex gap-1 font-size-13px font-Poppins-Regular'>
                            <input
                                type="checkbox"
                                checked={query === "week"}
                                onChange={() => handleCheckboxChange("week")}
                            />
                            This week
                        </label>
                        <label className='text-nowrap flex gap-1 font-size-13px font-Poppins-Regular'>
                            <input
                                type="checkbox"
                                checked={query === "month"}
                                onChange={() => handleCheckboxChange("month")}
                            /> This month
                        </label>
                        <label className='text-nowrap flex gap-1 font-size-13px font-Poppins-Regular'  >
                            <input
                                type="checkbox"
                                checked={query === "year"}
                                onChange={() => handleCheckboxChange("year")}
                            /> This year
                        </label>
                    </div>
                    <div className="Daily-Living-and-Access">
                        {data.map((entry, index) => (
                            <div key={`legend-${index}`} className="flex gap-2  font-Poppins-Medium font-size-13px items-center capitalize">
                                <div
                                    className="legend-color"
                                    style={{ backgroundColor: entry.color }}
                                ></div>
                                {entry.label}
                            </div>
                        ))}
                    </div>
                    <select
                        id="filter"
                        className="select-option-amdin font-Poppins-Medium font-size-13px"
                        onChange={handleStateChange}
                    >
                        <option className="font-Poppins-Medium font-size-13px" value="">All states</option>
                        <option className="font-Poppins-Medium font-size-13px" value="QLD">Queensland</option>
                        <option className="font-Poppins-Medium font-size-13px" value="TAS">Tasmania</option>
                        <option className="font-Poppins-Medium font-size-13px" value="NSW">New South Wales</option>
                        <option className="font-Poppins-Medium font-size-13px" value="VIC">Victoria</option>
                        <option className="font-Poppins-Medium font-size-13px" value="WA">Western Australia</option>
                        <option className="font-Poppins-Medium font-size-13px" value="SA">South Australia</option>
                        <option className="font-Poppins-Medium font-size-13px" value="NT">Northern Territory</option>
                        <option className="font-Poppins-Medium font-size-13px" value="ACT">Australian Capital Territory</option>
                    </select>
                </div>
            </div>
        </>
    );
}

export default ProvidersChart;

const styles: { [key: string]: React.CSSProperties } = {
    textContainer: {
        display: "flex",
        justifyContent: "space-between",
        height: "100%",
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '20px',
        border: '1px solid #e0e0e0',
        borderRadius: '10px',
        backgroundColor: '#e8f8f5',
    },
    totalProviders: {
        fontSize: '18px',
        marginBottom: '10px',
    },
    legend: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    legendItem: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: '5px',
    },
    legendColor: {
        width: '15px',
        height: '20px',
        marginRight: '5px',
        borderRadius: '50%',
    },
};
