import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faX,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import {
  formatDate,
  getRole,
} from "../../../config/Helpers/helpers";
import Skeleton from "@mui/material/Skeleton";
import { Button, CircularProgress } from "@mui/material";
import SuccessModal from "../../Modal/SuccessModal";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import ConfirmationModal from "../../Modal/ConfirmationModal";
import { toast } from "react-toastify";
import { updateSubscription } from "../../../config/Store/Reducers/userProfile";
import PremiumPlans from "../../PaymentFormComponents/PremiumPlans";
import httpRequest from "../../../config/Helpers/httpRequest";
import { RootState } from "../../../config/Store/store";
import PreviewProfile from "../../AllDashboardPagesComponents/Profile/PreviewProfile";
import FreemuimPremuim from "../../AllDashboardPagesComponents/Subscription/FreemuimPremuim";

interface SubscriptionDetails {
  cancel_at?: number;

  current_period_end?: number | undefined;
  metadata?: {
    price?: string;
    [key: string]: any;
  };


}


interface Invoice {
  pdfUrl: string;
  pdfName: string;
  [key: string]: any;

}
interface InvoiceItem {
  pdfUrl: string;
  pdfName: string;
  amount_paid: number;
  created: number;
  number: string;
  invoice_pdf: string;
  [key: string]: any; // If there are other properties you're not specifically typing
}

interface Invoices {
  invoices: {
    data: InvoiceItem[];
  };
}
interface Plan {
  _id: string;
  title: string;
  offerAmount: number;
  priceId: string;
  details: string[];
}
interface ApiResponse {
  documents: Plan[] | [];
  invoice: [];
  subscriptionDetails: SubscriptionDetails;

}
interface Plan {
  _id: string;
  title: string;
  offerAmount: number;
  priceId: string;
  details: string[];
  [key: string]: any;

}
interface ApiResponse2 {
  documents: Plan[];
  data: any
}
interface SubscriptionPlan {
  _id: string;
  title: string;
  offerAmount: number;
  [key: string]: any;

}

interface SubscriptionDetails {
  [key: string]: any;
}
interface ApiResponse {

}
const SubscriptionTab: React.FC = () => {



  const { subscriptionDetails, personalData } = useSelector((store: any) => store.userProfile);
  const [planChangeModal, setPlanChangeModal] = useState(false);
  const [invoiceDisplayModal, setInvoiceDisplayModal] = useState(false); // for invoice display modal show & hide
  const [ThirdModal, setThirdModal] = useState(false);
  const [ForthModal, setForthModal] = useState(false);
  const [showCancelRenewal, setShowCancelRenewal] = useState(false);
  const [step, setStep] = useState(1);
  const [changeLoader, setChangeLoader] = useState(false);
  const [freemiumPlanCheck, setFreemiumPlanCheck] = useState(true)
  const handleNext = () => {
    setStep(step + 1);
  };
  const handleNextDouble = () => {
    setStep(step + 2);
  };

  const handlePrevious = () => {
    setStep(step - 1);
  };
  const handlePreviousDouble = () => {
    setStep(step - 2);
  };

  // 15-04-24
  const complainList = [
    {
      label: "Customer service was less than expected",
      value: "customer_service",
    },
    { label: "Quality was less than expected", value: "low_quality" },
    { label: "Some features are missing", value: "missing_features" },
    {
      label: `I'm switching to a different service`,
      value: "switched_service",
    },
    { label: "Ease of use was less than expected", value: "too_complex" },
    { label: `It's too expensive`, value: "too_expensive" },
    { label: `I don't use the service enough`, value: "unused" },
    { label: "Other reason", value: "other" },
  ];

  const dispatch = useDispatch();

  const [isSuccessModal, setIsSuccessModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isLoader, setIsLoader] = useState(false); // for loader
  const [isError, setIsError] = useState(false); // for success
  const [isSuccess, setIsSuccess] = useState(false); // for error
  const [billingLoader, setBillingLoader] = useState(false); // get all invoices loader
  const [allInvoices, setAllInvoices] = useState<Invoice[]>([]);
  const [cancelError, setCancelError] = useState<string | boolean>(false);
  const [changePlanLoader, setChangePlanLoader] = useState(false);
  const [upComingRecord, setUpComingRecord] = useState<any>({}); // for upcoming record
  console.log(upComingRecord, "upcoming")
  const [freemiumDetail, setFreemiumDetail] = useState<boolean>(false)
  const [comment, setComment] = useState(""); // for comment box
  const [feedback, setFeedback] = useState(""); // for complainList check
  const [confirmModal, setConfirmModal] = useState(false); // for confirmation auto renewal modal
  const [autoRenewalLoader, setAutoRenewalLoader] = useState(false); // for auto renewal loader

  const [allPlans, setAllPlans] = useState<SubscriptionPlan[]>([]);
  const [bool, setBool] = useState(false);

  const decoded = getRole();
  const ROLE = decoded?.role;
  const isMember = !personalData?.freeUser

  {/*asdad*/ }
  // let ROLE = getRole();

  // let isMember = ROLE?.isMember || false;

  // Functionality: API call for auto renewal payment cancel
  // Example handling where err might be an object
  const handleCancelRenewal = async () => {
    if (!feedback) {
      return setCancelError("Please select an option.");
    }
    else if (!comment) {
      return setCancelError("Please write the reason.");

    }

    setIsLoader(true);
    const { res, err } = await httpRequest<{ subscription: SubscriptionDetails }>({
      method: "post",
      path: `/api/subscription/cancel`,
      params: { comment, feedback }
    });
    if (res) {
      setIsSuccessModal(true);
      dispatch(updateSubscription(res?.subscription));
      setSuccessMessage("Auto-renewal cancelled successfully.");
      setShowCancelRenewal(false);
      setComment("");
      setFeedback("");
    } else {
      const errorMessage = typeof err === 'object' ? err.message : err;
      setCancelError(errorMessage || "ERROR"); // api error
    }
    setIsLoader(false);
  };


  // Functionality: API call for plan change from monthly to annually
  const confirmPay = async () => {

    setChangeLoader(true);


    let item = allPlans.find((v) => v.title === "yearly");

    if (!item) {
      console.error("No plan found with title 'annually'.", allPlans);
      setChangeLoader(false);
      toast("Annual plan not found.", { type: "error" });
      return;
    }


    const { res, err } = await httpRequest<{ subscription: SubscriptionDetails }>({
      method: "post",
      path: `/api/subscription/upgrade`,
      params: { offerId: item._id }
    });

    if (res) {
      dispatch(updateSubscription(res?.subscription));
      setBool((prev) => !prev);
      setPlanChangeModal(false);
      toast("Plan Upgraded successfully", { type: "success" });
    } else {
      console.error("Error upgrading plan:", err);
      toast(err?.message, { type: "error" });
    }

    setChangeLoader(false);
  };


  // Functionality: API call for get all Invoices of loged in user
  const getBillingHistory = async () => {
    setBillingLoader(true);
    setInvoiceDisplayModal(true);
    const { res, err } = await httpRequest<Invoices>({ path: "/api/subscription/invoices" });
    if (res) {
      setAllInvoices(res?.invoices?.data || []);
    } else {
      setIsError(err || "ERROR");
    }
    setBillingLoader(false);
  };

  // download invoice
  const downloadInvoice = async (pdfUrl: string, pdfName: string) => {
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.setAttribute("download", `${pdfName}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Functionality: API call for get all subscriptions plan
  const changePlan = async () => {
    setChangePlanLoader(true);
    setPlanChangeModal(true);

    try {
      const { res, err } = await httpRequest<ApiResponse2>({ path: "/api/subscription/offer" });

      if (res && res.documents) {
        setAllPlans(res.documents); // Access documents within data
      } else {
        // Handle the case where data or documents is undefined
        console.error("No documents found in the response.");
        // setIsError("No plans available at the moment.");
      }
    } catch (error) {
      console.error("An error occurred while fetching plans:", error);
      // setIsError("An unexpected error occurred. Please try again later.");
    } finally {
      setChangePlanLoader(false);
    }
  };

  // Functionality: API call for resume auto renewal
  const handleResumeAutoRenewal = async () => {
    setAutoRenewalLoader(true);
    const { res, err } = await httpRequest<{ subscription: SubscriptionDetails }>({ method: "post", path: "/api/subscription/reactivate", params: {} });
    if (res) {
      setBool((prev) => !prev);
      setConfirmModal(false); // modal close
      dispatch(updateSubscription(res?.subscription));
    } else {
      toast(err?.message, { type: "error" }); // api error
    }
    setAutoRenewalLoader(false);
  };

  useEffect(() => {
    if (isError || isSuccess || cancelError) {
      setTimeout(() => {
        setIsError(false);
        setIsSuccess(false);
        setCancelError(false);
      }, 5000);
    }
  }, [isError, isSuccess, cancelError]); // error clear in after 5 seconds.

  // Functionality: API call for my current plan
  useEffect(() => {
    if (isMember) {
      (async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/subscription/upcoming` });
        if (res) {
          setUpComingRecord(res?.invoice || {});
          if (res?.subscriptionDetails) {

            dispatch(updateSubscription(res?.subscriptionDetails));

          }
        } else {
          setIsError(err || "ERROR"); // api error
        }
        setIsLoader(false);
      })();
    }
  }, [bool]);


  return (

    <div className="">
      <div className="remove-btn">
        <PreviewProfile title={`${showCancelRenewal ? "Cancel renewal" : "My subscription"}`} />
      </div>

      <ConfirmationModal
        title="Are you sure you want to active auto renewal?"
        isOpen={confirmModal}
        onClose={() => setConfirmModal(false)}
        confirmed={handleResumeAutoRenewal}
        loader={autoRenewalLoader}
      />

      {isSuccessModal && (
        <SuccessModal
          message={successMessage}
          setIsModal={setIsSuccessModal}
          setMsg={setSuccessMessage}
        />
      )}
      {isMember ? (
        !showCancelRenewal ? (
          <div className="flex flex-col gap-3">
            {/* {isError && <p style={{ color: "red" }}>{isError}</p>}
            {isSuccess && <p style={{ color: "green" }}>{isSucces s}</p>} */}
            {subscriptionDetails?.cancel_at_period_end ? (
              <div className="bg-white rounded flex p-5  justify-between items-center gap-2 ">
                <div>
                  <div className="flex items-center justify-between">
                    <p className="font-size-18px font-Poppins-Medium text-black">
                      ndisync{" "}
                      <em className="font-Poppins-SemiBold theme-color-green">
                        <span className="capitalize">
                          {upComingRecord?.plan_name === "monthly"
                            ? "Monthly"
                            : upComingRecord?.plan_name === "yearly"
                              ? "Annualy"
                              : ""}
                        </span>{" "}
                        Plan
                      </em>
                    </p>
                  </div>
                  <h3 className="font-Poppins-Medium font-size-20px theme-color-green">
                    Active renewal
                  </h3>
                  <div className="flex items-center justify-between">
                    <p className="font-size-18px font-Poppins-Medium text-black">
                      {`Payment $${subscriptionDetails?.metadata?.price || 0
                        } on ${moment
                          .unix(subscriptionDetails?.current_period_end ?? 0)  // Provide a default value of 0 if undefined
                          .format("MMM DD, YYYY") || "-"
                        }`}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 items-end">
                  {upComingRecord?.plan_name !== "year" && (
                    <button
                      onClick={changePlan}
                      className="font-size-18px font-Poppins-Medium text-black"
                    >
                      Change
                      <FontAwesomeIcon
                        className="ml-2"
                        icon={faChevronRight}
                      />
                    </button>
                  )}
                  <button
                    onClick={() => setConfirmModal(true)}
                    className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn w-fit"
                    style={{ color: "white" }}
                  >
                    Active renewal
                  </button>
                </div>
              </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="bg-white rounded flex p-5 flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <p className="font-size-18px font-Poppins-Medium text-black">
                      ndisync{" "}
                      <em className="font-Poppins-SemiBold theme-color-green">
                        <span className="capitalize">
                          {upComingRecord?.plan_name === "month"
                            ? "Monthly"
                            : upComingRecord?.plan_name === "year"
                              ? "Annually"
                              : ""}
                        </span>{" "}
                        plan
                      </em>
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="font-size-18px font-Poppins-Medium text-black">
                      Next payment ${upComingRecord?.amount_due || 0} on{" "}
                      {formatDate(upComingRecord?.billing_date) || "-"}.
                    </p>
                    {upComingRecord?.plan_name !== "year" && (
                      <button
                        onClick={changePlan}
                        className="font-size-18px font-Poppins-Medium text-black"
                      >
                        Change
                        <FontAwesomeIcon
                          className="ml-2"
                          icon={faChevronRight}
                        />
                      </button>
                    )}
                  </div>
                  <div className="flex items-center justify-between">
                    <p className="font-size-18px font-Poppins-Medium text-black">
                      {`Automatic renewal every ${upComingRecord?.plan_name || ""
                        }`}
                    </p>
                    {upComingRecord?.amount_due !== "0" &&
                      <button
                        onClick={() => setShowCancelRenewal(true)}

                        className="font-size-18px font-Poppins-Medium text-black"
                      >
                        Cancel renewal
                        <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                      </button>
                    }


                  </div>
                </div>
              </div>
            )}

            <div className="bg-white rounded flex p-5 flex-col gap-2">
              <h3 className="font-Poppins-Medium font-size-20px theme-color-green">
                Billing information
              </h3>
              <div className="flex items-center justify-between">
                <p className="font-size-18px font-Poppins-Medium text-black">
                  {upComingRecord?.email || ""}
                </p>
                <button
                  onClick={getBillingHistory}
                  className="font-size-18px font-Poppins-Medium text-black"
                >
                  Billing history
                  <FontAwesomeIcon className="ml-2" icon={faChevronRight} />
                </button>
              </div>
              <div className="flex items-center justify-between">
                <p className="font-size-18px font-Poppins-Medium text-black">
                  <em className="font-Poppins-SemiBold theme-color-green capitalize ">
                    {upComingRecord?.customer_name || ""}
                  </em>
                </p>
                {/* <button
                  // onClick={() => setThirdModal(true)}
                  className="font-size-18px font-Poppins-Medium text-black"
                >
                  Modify Billing Information
                  <FontAwesomeIcon
                    className="ml-2"
                    icon={faChevronRight}
                  />
                </button> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-col gap-3 p-5 shadow-lg bg-white rounded-lg">
            <div className="flex flex-col gap-2 relative text-center">
              <img
                className="mb-2 cursor-pointer back-btn-privacy"
                onClick={() => setShowCancelRenewal(false)}
                src={"https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"}
                alt=""
                style={{ transform: "translateY(-20px)" }}
              />
              <h3 className="theme-color-green font-Poppins-SemiBold font-size-25px">
                You’re about to cancel your subscription renewal
              </h3>
              <h3 className="text-black font-Poppins-Regular font-size-18px">
                You can continue enjoying your plan benefits until the end of
                the billing period.
              </h3>
            </div>
            <div className="flex  justify-between items-end gap-4">
              <div className="flex flex-col gap-3 ">
                <p className="font-size-18px font-Poppins-Medium text-black">
                  Please, tell us why you’re canceling your subscription
                  renewal. Select more than one option if needed.
                </p>
                <div className="flex flex-col gap-2">
                  {complainList.map((item, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-2  theme-bg-lightblue  border-radius-6px py-3 px-4"
                    >
                      <label className="checkbox-container flex items-center cursor-pointer">
                        <input
                          type="radio"
                          name="cancel-reason"
                          className="checkbox-input-subscription hidden"
                          onChange={() => setFeedback(item?.value)}
                        />
                        <span className="checkbox-input-subscription relative"></span>
                      </label>
                      <span className="font-size-18px font-Poppins-Medium text-black">
                        {item?.label}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
              <div className="w-[100%]">
                <div className="flex flex-col gap-3 ">
                  {/* <h3 className="font-Poppins-Medium font-size-20px text-black">
                    Is there something else you want to share with us?
                  </h3> */}
                  <textarea
                    className="font-size-15px font-Poppins-Regular subcrip-text-area border-radius-6px outline-none"
                    placeholder="Is there something else you want to share with us?
"
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                  ></textarea>
                  {cancelError && typeof cancelError === 'string' && <p style={{ color: "red" }}>{cancelError}</p>}
                  <div className="flex flex-row justify-end gap-3 w-[70%] ">
                    <button
                      className="
                      w-[49%] font-Poppins-Regular font-size-18px text-white theme-bg-green border-radius-20px subcrip-btn
                      "
                      type="button"
                      onClick={() => setShowCancelRenewal(false)}
                    >
                      Keep subscription
                    </button>

                    <button
                      className="w-[49%] font-Poppins-Regular font-size-18px theme-color-green border-radius-20px subcrip-btn"
                      type="button"
                      onClick={handleCancelRenewal}
                      disabled={isLoader}
                    >
                      Cancel renewal
                      {isLoader && (
                        <CircularProgress
                          style={{ color: "#fff", width: 16, height: 16 }}
                        />
                      )}
                    </button>

                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <>
          {/* {personalData?.freeUser && */}

          <>
            {freemiumDetail &&
              <div className="flex justify-end relative">
                <img
                  onClick={() => setFreemiumDetail(false)}
                  className="mb-2 cursor-pointer back-btn-privacy"
                  src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
                  alt=""
                />
              </div>
            }
            {freemiumPlanCheck &&
              <div className="shadow-lg bg-white rounded-none lg:rounded-lg mb-2 py-3 px-6 flex justify-between items-center">
                <p className="font-size-18px font-Poppins-Medium text-black">You are currently using the Freemium plan. Upgrade to Premium to unlock more benefits! </p>
                <button
                  onClick={() => setFreemiumDetail(prev => !prev)}

                  className="theme-bg-green  font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn w-fit" style={{ color: "white" }} > What's included </button>
              </div>
            }
            {freemiumDetail &&

              <FreemuimPremuim onClose={() => setFreemiumDetail(false)} />

            }
          </>
          {/* } */}

          {!freemiumDetail &&
            <PremiumPlans freeText={() => setFreemiumPlanCheck(false)} />

          }

        </>
      )}

      {planChangeModal && (
        <div className="overlay">
          <div className="all-dashChangesfModal absolute z-20">
            <div className="relative">
              <button
                className="absolute change-mdl-x-mark paid-provider-add-operator"
                onClick={() => setPlanChangeModal(false)}
              >
                <FontAwesomeIcon
                  className="x-icon-admin-opr"
                  icon={faX}
                  style={{ color: "#ffffff" }}
                />
              </button>
              <h2 className="font-size-25px text-[#00443F] text-center font-Poppins-Medium mb-5">
                <span className="theme-color-para font-Poppins-Medium">
                  Change to
                </span>{" "}
                annual plan
              </h2>
              <div style={{ border: "1px solid #00443F" }}></div>
              <div className="Change-annual-plan-modl">
                {changePlanLoader ? (
                  <>
                    <div className="text-center">
                      <CircularProgress style={{ color: "#00443f" }} />
                    </div>
                  </>
                ) : (
                  <>
                    {allPlans?.map((item, index) => (
                      <div
                        className={`access-plan-box-login-paid relative bg-white flex flex-col overflow-clip mb-3 ${item?.title === "monthly"
                          ? "disabled__box"
                          : "primary__border"
                          }`}
                        key={index}
                        style={{ display: "flex" }}
                      >
                        {item?.title === "monthly" ? (
                          <span className="font-Montserrat-Medium font-size-login-25px leading-none w-full text-center text-white access-planbox-save-text absolute">
                            Active
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="flex flex-col gap-2">
                          <h3 className="font-Montserrat-Bold font-size-login-28px theme-color-green leading-none">
                            All access pass
                          </h3>
                          <h3 className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-green">
                            Everything you need, all in one place.
                          </h3>
                        </div>
                        <div className="flex items-center gap-3">
                          <span className="leading-none font-Montserrat-Bold font-size-login-72px theme-color-green">
                            ${item?.offerAmount || 0}
                          </span>
                          <span className="leading-none font-Montserrat-Medium font-size-login-25px theme-color-light-seagreen">
                            / per{" "}
                            {item?.title === "monthly"
                              ? "month"
                              : item?.title === "yearly"
                                ? "year"
                                : ""}
                          </span>
                        </div>
                      </div>
                    ))}
                    <div className="change-mdl-Confirm-pay">
                      <button
                        disabled={changeLoader}
                        onClick={confirmPay}
                        className="font-Poppins-SemiBold font-size-15px w-full text-white py-3 loader-btn justify-center"
                      >
                        Change plan{" "}
                        {changeLoader && (
                          <CircularProgress
                            style={{ width: 14, height: 14, color: "#fff" }}
                          />
                        )}
                      </button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {invoiceDisplayModal && (
        <div className="overlay">
          <div className="all-dashChangesfModal absolute z-20">
            <div className="relative">
              <button
                className="absolute change-mdl-x-mark change-invoice-mdl-x-mark paid-provider-add-operator"
                onClick={() => setInvoiceDisplayModal(false)}
              >
                <FontAwesomeIcon
                  className="x-icon-admin-opr"
                  icon={faX}
                  style={{ color: "#ffffff" }}
                />
              </button>
              <h2 className="font-size-25px text-center theme-color-para font-Poppins-Medium mb-3">
                Invoices
              </h2>
              <div style={{ border: "1px solid #00443F" }}></div>
              <div className="Invoice-content">
                <ul
                  style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
                  className="Invoice-content-head font-size-18px font-Poppins-SemiBold"
                >
                  <li className="text-center">Product</li>
                  <li className="text-center">Invoice #</li>
                  <li className="text-center">Date</li>
                  <li className="text-center">Amount</li>
                  <li className="text-center">Download</li>
                </ul>
                {billingLoader ? (
                  <>
                    <Skeleton
                      variant="rectangular"
                      className="w-full mt-1"
                      height={50}
                    />
                    <Skeleton
                      variant="rectangular"
                      className="w-full mt-1"
                      height={50}
                    />
                    <Skeleton
                      variant="rectangular"
                      className="w-full mt-1"
                      height={50}
                    />
                  </>
                ) : (
                  <>

                    {allInvoices && allInvoices.length > 0 ? (
                      allInvoices.map((item, index) => (
                        <ul
                          key={index}
                          // id={item?.period?.start}
                          style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
                          className="Invoice-content-body bg-[#F5F5F5] font-size-16px font-Poppins-Regular"
                        >
                          <li className="flex items-center justify-center text-center">
                            ndisync <br />{" "}
                            {item?.subscription_details?.metadata?.title === "monthly"
                              ? "Monthly"
                              : "Annually"}
                            <br /> Plan
                          </li>
                          <li className="flex items-center justify-center text-center">
                            {item?.number || "-"}
                          </li>
                          <li className="flex items-center justify-center text-center">
                            {moment
                              .unix(item?.created)
                              .format("MMM DD, YYYY") || "-"}
                          </li>
                          <li className="flex items-center justify-center text-center">
                            ${item?.amount_paid / 100 || "0"}
                          </li>
                          <li className="flex items-center justify-center text-center">
                            <button
                              onClick={() =>
                                downloadInvoice(item?.invoice_pdf, item?.number)
                              }
                            >
                              <img
                                src={
                                  "https://edgie.backslashwebs.com/imgs/Invoice-downlaod-icon.png"
                                }
                                alt=""
                              />
                            </button>
                          </li>
                        </ul>
                      ))
                    ) : (
                      <ul
                        style={{ gridTemplateColumns: "20% 20% 20% 20% 20%" }}
                        className="Invoice-content-body bg-[#F5F5F5] font-size-16px font-Poppins-Regular"
                      >
                        <li className="flex items-center justify-center text-center">
                          No Invoices
                        </li>
                      </ul>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
      {ThirdModal && (
        <div className="overlay">
          <div className="all-dashChangesfModal absolute z-20">
            <div className="relative">
              <button
                className="absolute billing-information-md change-mdl-x-mark paid-provider-add-operator"
                onClick={() => setThirdModal(false)}
              >
                <FontAwesomeIcon
                  className="x-icon-admin-opr"
                  icon={faX}
                  style={{ color: "#ffffff" }}
                />

              </button>
              <h2 className="font-size-25px text-center theme-color-para font-Poppins-Medium mb-5">
                Billing information
              </h2>
              <div style={{ border: "1px solid #00443F" }}></div>
              <form>
                <div className="Biilling-information-mdl">
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      First name
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      Last name
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      Email
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      Address
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      Country
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      City
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      Postal code
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="">
                    <label
                      className="font-Poppins-Medium font-size-15px theme-color-green"
                      htmlFor=""
                    >
                      ABN Number
                    </label>
                    <input
                      className="font-Poppins-Regular"
                      type="text"
                      name=""
                      id=""
                    />
                  </div>
                  <div className="edit-profile-save-change">
                    <Button className="font-size-15px text-white theme-bg-green font-Poppins-SemiBold">
                      Save changes
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}



      {/* {ForthModal && (
        <div className="overlay">
          <div className="update-payment-Modal absolute z-20">
            <div className="relative">
              <button
                className="absolute billing-information-md change-invoice-mdl-x-mark change-mdl-x-mark paid-provider-add-operator"
                onClick={() => setForthModal(false)}
              >
                <FontAwesomeIcon
                  className="x-icon-admin-opr"
                  icon={faX}
                  style={{ color: "#ffffff" }}
                />

              </button>
              <form action="">
                {step === 1 && (
                  <div className="customer-subcribtion-modal2">
                    <div className="customer-Subcribtion-modl2">
                      <h2 className="customer-subcribtion-heading-modl4 font-Poppins-Medium font-size-25px">
                        Update Payment Details
                      </h2>
                    </div>
                    <div className="customer-payment-detail-modl4">
                      <h3 className="font-Poppins-Medium font-size-15px text-center theme-color-green">
                        Select a payment method
                      </h3>
                      <div className="customer-form-detail-modl4">
                        <img
                          className="customer-form-detail-modl4-img1"
                          src={
                            "https://edgie.backslashwebs.com/imgs/customer-subcribtion-credit-card.png"
                          }
                          alt=""
                        />
                        <img
                          className="customer-form-detail-modl4-img2"
                          src={
                            "https://edgie.backslashwebs.com/imgs/customer-subcribtion-visa.png"
                          }
                          alt=""
                        />
                        <img
                          className="customer-form-detail-modl4-img3"
                          src={
                            "https://edgie.backslashwebs.com/imgs/Customer-master-card-logo.png"
                          }
                          alt=""
                        />
                        <img
                          className="customer-form-detail-modl4-img4"
                          src={
                            "https://edgie.backslashwebs.com/imgs/curtomer-subcribtion-amex.png"
                          }
                          alt=""
                        />
                        <input
                          className="font-size-12px font-Poppins-Regular"
                          type="text"
                          // onClick={handleNextDouble}
                          placeholder="Credit / Debit Card"
                          name=""
                          id=""
                        />
                      </div>

                      <button onClick={handleNext}>
                        <div className="customer-strip-btn-modl4">
                          <img
                            src={
                              "https://edgie.backslashwebs.com/imgs/customer-subcribtion-stripe.png"
                            }
                            alt=""
                          />
                        </div>
                      </button>
                    </div>
                  </div>
                )}

                {step === 2 && (
                  <div className="customer-subcribtion-modal2">
                    <div className="customer-Subcribtion-modl2">
                      <h2 className="customer-subcribtion-heading-modl4 font-Poppins-Medium font-size-25px">
                        Pay with Stripe
                      </h2>
                    </div>
                    <div className="">
                      <div className="customer-pay-withstripe">
                        <div
                          style={{ width: "max-content", cursor: "pointer" }}
                          className="customer-pay-withstripe-inner"
                          onClick={handlePrevious}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                          <span>Back</span>
                        </div>
                        <p className="font-Poppins-Medium font-size-15px">
                          After clicking on “Continue to Stripe”, you will be
                          redirected to the Stripe website to complete your
                          payment securely.
                        </p>
                        <div className="continue-tostrip">
                          <button
                            className="font-Poppins-SemiBold font-size-15px"
                            onClick={() => setForthModal(false)}
                          >
                            Continue to Stripe
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {step === 3 && (
                  <div className="customer-subcribtion-modal2">
                    <div className="customer-Subcribtion-modl2">
                      <h2 className="customer-subcribtion-heading-modl4 font-Poppins-Medium font-size-25px">
                        Pay with Stripe
                      </h2>
                    </div>
                    <div className="">
                      <div className="customer-pay-withstripe">
                        <div
                          style={{ width: "max-content", cursor: "pointer" }}
                          className="customer-pay-withstripe-inner"
                          onClick={handlePreviousDouble}
                        >
                          <FontAwesomeIcon icon={faArrowLeft} />
                          <span>Back</span>
                        </div>
                        <div className="customer-card-subcribtion">
                          <img
                            className="customer-card-subcribtion-debitcard"
                            src={
                              "https://edgie.backslashwebs.com/imgs/customer-subcribtion-credit-card.png"
                            }
                            alt=""
                          />
                          <input
                            className="customer-card-subcribtion-inp1  font-size-12px font-Poppins-Regular"
                            placeholder="Credit Card Number"
                            type="text"
                            name=""
                            id=""
                          />
                          <input
                            className="customer-card-subcribtion-inp2  font-size-12px font-Poppins-Regular"
                            placeholder="MM/YY"
                            type="text"
                            name=""
                            id=""
                          />
                          <input
                            className="customer-card-subcribtion-inp3 font-size-12px font-Poppins-Regular"
                            type="text"
                            placeholder="CVV"
                            name=""
                            id=""
                          />
                        </div>
                        <div className="continue-tostrip">
                          <button
                            className="font-Poppins-SemiBold font-size-15px"
                            onClick={() => setForthModal(false)}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default SubscriptionTab;