import React, { useEffect, useRef, useState } from "react";
import ndisailogo from "../../assets/img/ndisailogo.svg";

interface DemoVideoProps {
  isModalVedioRender: boolean; // Define the type of the prop
}

// const DemoVideo: React.FC = () => {
  const DemoVideo: React.FC<DemoVideoProps> = ({ isModalVedioRender }) => {


    console.log(isModalVedioRender,"isModalVedioRender")

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const videoRef = useRef<HTMLVideoElement | null>(null);

  const handlePlayPause = () => {
    const video = videoRef.current;
    if (video) {
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    }
  };

  const handleImageClick = () => {
    const image = document.querySelector<HTMLImageElement>(".play-img-home-about");
    if (image) {
      image.style.display = "none";
    }
  };

  const handleVideo = () => {
    const video = videoRef.current;
    if (video) {
      if (!video.paused) {
        const image = document.querySelector<HTMLImageElement>(".play-img-home-about");
        if (image) {
          image.style.display = "block";

        }
        video.pause();
      } else {
        video.play();
        const image = document.querySelector<HTMLImageElement>(".play-img-home-about");
        if (image) {
          image.style.display = "none";

        }
      }
    }


  };

  return (
    <>
      <div className="video-main-box video-main-box-mob">
      { isModalVedioRender &&   <h3 style={{ height: "40px" }} className="h-5	 uppercase font-Poppins-SemiBold font-size-login-36px text-white leading-none text-center lg-main-head  flex  gap-4 justify-center  items-center   welcome-ndis-res  ">
          Watch <img src={ndisailogo} alt="" /> in Action!
        </h3>}
        {!isPlaying && (
          <div
            className="play_button_video_home z-10"
          >
            <img
              className="cursor-pointer play-img-home-about"
              onClick={() => {
                handlePlayPause();
                handleImageClick();
              }}
              src="https://edgie.backslashwebs.com/imgs/play-button-video.png"
              alt="Play Button"
            />
          </div>
        )}
        <video
          onClick={() => { handleVideo(); handlePlayPause() }}
          ref={videoRef}
          // className="home-nids-video-border-radius-2"
          className={`${isModalVedioRender ? " home-nids-video-border-radius-2":" "}`}
          loop
          muted
          controls
          
          playsInline
          width="100%"
        >
          <source
            src="https://ndisync.backslashwebs.com/NDISYNCDEMO2.mp4"
            type="video/mp4"
          />
        </video>
      </div>
    </>
  );
};

export default DemoVideo;