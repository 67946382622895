import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import JobCancelModal from './JobCancelModal';
import { toast } from 'react-toastify';
import httpRequest from '../../config/Helpers/httpRequest';
interface JobCompleteRequestProps {
    jobId?: string;
    completedFucntion?: () => void;
    btnClass?: string;
    extraStyle?: React.CSSProperties;
}
interface ApiResponse {
    message: string;
}
const JobCompleteRequest: React.FC<JobCompleteRequestProps> = ({
    jobId = "",
    completedFucntion = () => { },
    btnClass = "",
    extraStyle = {}
}) => {
    const [isModal, setIsModal] = useState<boolean>(false);
    const [isLoader, setIsLoader] = useState<boolean>(false);
    // Functionality: API call for send Job complete request
    const handleRequestComplete = async () => {
        setIsLoader(true);




        const { res, err } = await httpRequest<ApiResponse>({ method: "post", path: "/api/job/request/complete", params: { jobId: jobId } });
        if (res) {
            // setIsModal(true);
            completedFucntion();
            toast("Success", { type: "success" });
            setIsModal(false)
        } else {
            if (err.status=400) {
                toast(err?.message?.message, { type: "error" });
            }
            console.log(err, "asdsada")
            toast(err?.message, { type: "error" }); // api error
        }
        setIsLoader(false);
    }
    return (
        <>
            <button onClick={() => setIsModal(true)} className={btnClass} style={extraStyle}><FontAwesomeIcon icon={faCheck} />Complete</button>
            {isModal &&
                <JobCancelModal
                    onClose={() => setIsModal(false)}
                    confirmCancel={handleRequestComplete}
                    status="success"
                    title="Are you sure you want to complete this job?"
                    loader={isLoader}
                />
            }
        </>
    );
};

export default JobCompleteRequest;