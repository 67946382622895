import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { MultiValue } from 'react-select';

import {
    faFilter,
    faCalendar,
    faLocationDot,
    faCalendarDays,
    faCheck,
    faX
} from "@fortawesome/free-solid-svg-icons";
import { getTimeDifference } from '../../config/Helpers/helpers';
import { CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { validateNumber } from "../../config/Helpers/helpers"
import ApplyJobsButton from "../JobsComponents/ApplyJobsButton";
import { toast } from 'react-toastify';
import moment from 'moment';
import { RootState } from "../../config/Store/store";
import FilterIcon from "../../assets/img/filter-icon.svg";
import CrossIcon from "../../assets/img/cross-icon.svg";

interface ServiceOption {
    value: string;
    label: string;
}
interface creatorDetails {
    fullName?: string;

}

interface Job {
    _id: string;
    creatorDetails: creatorDetails;
    fullName?: string;
    serviceName?: string;
    createdAt: string;
    repeatCycle: number;
    suburb?: string;
    state?: string;
    postCode?: string;
    startDate?: string;
    endDate?: string;
    budget?: string;
    shortlistTime?: string;
    applicationTime?: string;
}

interface JobCurrentStatusProps {
    jobs: Job[];
    totalPages: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    loader: boolean;
    setSelectedJobs: (job: Job) => void;
    selectedJobs:any;
    activeTab: string | number;
    setActiveTab: (tab: string) => void;
    clearFilter: () => void;
    submit: (filters: { date: string; location: string; service: string[] }) => void;
}
// 26-04-24

const JobCurrentStatus: React.FC<JobCurrentStatusProps> = ({
    jobs,
    totalPages,
    currentPage,
    setCurrentPage,
    loader,
    setSelectedJobs,
    selectedJobs,
    activeTab,
    setActiveTab,
    clearFilter,
    submit,
}) => {

    const { ALLSERVICES } = useSelector((store: RootState) => store.allServices);

    const [tempDate, setTempDate] = useState<string | undefined>();
    const [serviceOptions, setServiceOptions] = useState<ServiceOption[]>([]);
    const [filterVisibilty, setFilterVisibiltiy] = useState<boolean>(false);

    const [date, setDate] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [service, setService] = useState<string[]>([]);

    // for date change
    const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        setTempDate(value)
        const inputDate = new Date(value);
        const year = inputDate.getFullYear();
        const day = inputDate.getDate();
        const month = inputDate.getMonth() + 1;
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDate = `${year}-${formattedMonth}-${formattedDay}`;
        setDate(formattedDate);
    };

    // for service Change
    const handleServiceChange = (event: MultiValue<ServiceOption>) => {
        let tempArr = event?.map(v => v?.value);
        setService(tempArr);
    };

    // for location change
    const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value;
        if (value && validateNumber(value)) {
            if (value.length < 5) {
                setLocation(value);
            }
        }
        else {
            setLocation("");
        }
    };

    // for filter close
    const handleFilterClose = () => {
        setFilterVisibiltiy(false);
        setDate("");
        setTempDate("");
        setService([]);
        setLocation("");
    };

    const filterClear = () => {
        handleFilterClose();
        clearFilter();
    };

    const filter_submit = () => {
        if (!date && (!location || location.length < 4) && service.length < 1) {
            toast("Please fill atleast 1 field.", { type: "error" });
            return;
        }
        let obj = { date, location, service };
        setFilterVisibiltiy(false);
        submit(obj);
    };

    useEffect(() => {
        handleFilterClose();
    }, [activeTab]);

    useEffect(() => {
        if (ALLSERVICES) {
            let tempArr = ALLSERVICES.flatMap(item =>
                item?.documents
                    .filter(v => v?.servicename) // Filter out undefined servicename
                    .map(v => ({
                        value: v?._id || '',
                        label: v?.servicename as string // Type assertion since we filtered out undefined
                    }))
            );
            setServiceOptions(tempArr);
        }
    }, [ALLSERVICES]);

    return (
        <div className="acitve-jobs-main">
            {filterVisibilty ? (
                <div className="manage-job-filter">
                    <div>
                        <div className='flex justify-center'>
                            <div className='flex flex-row justify-between items-center x-icon w-[90%] mb-2'>
                                <h2 className='font-size-20px font-Poppins-SemiBold  text-[#00443f] '>Filter</h2>
                                {/* <FontAwesomeIcon onClick={() => setFilterVisibiltiy(false)} className="" icon={faX} /> */}
                                <img onClick={() => setFilterVisibiltiy(false)}  src={CrossIcon} />
                            </div>
                        </div>
                        <div className='filter-line'></div>

                        <div className='flex justify-center flex-col filter-entry items-center'>
                            <div className='flex flex-col x-icon w-[90%] mt-2'>
                                <h2 className='font-size-20px font-Poppins-SemiBold  text-[#646464] '>Date</h2>
                                <input className=" mb-3 font-size-15px font-Poppins-Light" type='date' name="date" placeholder='Filter by Date' value={tempDate} onChange={handleDateChange} />
                            </div>
                            <div className='flex flex-col x-icon w-[90%]'>
                                <h2 className='font-size-20px font-Poppins-SemiBold  text-[#646464] '>Service</h2>
                                <Select
                                    isMulti
                                    value={serviceOptions.filter(option => service.includes(option.value))}
                                    placeholder="Select services"
                                    onChange={handleServiceChange}
                                    options={serviceOptions}
                                    styles={{
                                        singleValue: (provided) => ({
                                            ...provided,
                                            fontWeight: "300",
                                            fontSize: '15px',
                                        }),
                                        placeholder: (provided) => ({
                                            ...provided,
                                            fontWeight: "300",
                                            fontSize: '15px',
                                        }),
                                        control: (provided, state) => ({
                                            ...provided,
                                            border: `1px solid #00A297`,
                                            borderRadius: `8px`,
                                            boxShadow: state.isFocused ? `0 0 0 2px #00A297` : `none`,
                                            "&:hover": {
                                                border: `1px solid #00A297`
                                            }
                                        }),
                                        menu: (provided) => ({
                                            ...provided,
                                            border: `1px solid #00A297`,
                                            borderRadius: `8px`,
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected ? `#00A297` : `white`,
                                            color: state.isSelected ? `white` : `#000`,
                                            "&:hover": {
                                                backgroundColor: state.isSelected ? `#00A297` : `#e3f6f5`,
                                            }
                                        })
                                    }}
                                />
                            </div>
                            <div className='flex flex-col x-icon w-[90%]'>
                                <h2 className='font-size-20px font-Poppins-SemiBold  text-[#646464] '>Location</h2>
                                <input className=" mb-3 font-size-15px font-Poppins-Light" name="location" placeholder='Filter by location' value={location} onChange={handleLocationChange} />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div>
                            <div className='filter-line'></div>
                            <div className='flex justify-center flex-col filter-entry items-center'>
                                <div className='flex flex-row justify-between x-icon w-[90%] mb-3'>
                                    <button onClick={filterClear} className=" text-[#00443f] font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Clear</button>

                                    <button disabled={loader} onClick={filter_submit} className="text-white theme-bg-green font-size-16px font-Poppins-SemiBold border-radius-6px profile-page-btn w-max mt-4 flex gap-4">Apply {loader && <CircularProgress style={{ color: "#fff", width: 16, height: 16 }} />}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <div className="filter-results">
                        <div className='flex align-center flex-row  justify-between job-filter-stat'>
                            <span className=' font-size-12px font-Poppins-Regular text-[#646464]'>Showing all {jobs?.length || 0} jobs</span>
                            <div className='flex flex-row gap-2 items-center'>
                                <span className=' font-size-14px font-Poppins-Medium  text-[#00443f]'>|</span>
                                <button onClick={() => setFilterVisibiltiy(true)}
                                    className='flex flex-row gap-2 items-center' >
                                    <span className=" text-[#00443f]">
                                        {/* <FontAwesomeIcon icon={faFilter} /> */}
                                        <img src={FilterIcon} alt="" />
                                    </span>
                                    <span className=' font-size-14px font-Poppins-Medium  text-[#00443f]'>Filter</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="tab-upt">
                        {jobs && jobs.length > 0
                            ?
                            jobs.map((item, index) => (
                                <div key={index}>
                                    <div className={`left-card-main ${selectedJobs === item ? 'left-card-main-border' : ''}`} >
                                    <div role="button" onClick={() => setSelectedJobs(item)}>
                                            <div className='flex flex-row gap-2'>
                                                <span className=" font-size-14px font-Poppins-Medium  text-[#00000] capitalize">Posted by </span><span className="font-size-14px font-Poppins-SemiBold  text-[#00443f] capitalize">{`${item?.creatorDetails.fullName || ""} `}</span>
                                            </div>
                                            <div>
                                                <h2 className="font-Poppins-Medium mt-2 font-size-20px">
                                                    {item?.serviceName || ""}
                                                    {/* {item?.allServices?.length > 1 ? ", " : ""} */}
                                                    {/* {item?.allServices[1]?.servicename || ""} */}
                                                    {/* <span style={{ fontSize: 10, fontWeight: 'normal' }}> */}
                                                    {/* {item?.allServices?.length > 2 ? " & others " : ""} */}
                                                    {/* </span> */}
                                                </h2>
                                                <p className="theme-color-para leading-3 font-size-12px font-Poppins-Regular">
                                                    {getTimeDifference(item?.createdAt) || ""}
                                                </p>
                                                <div className="card-box-inner-main">
                                                    <div className="admin-box-weekly-cal">
                                                        <FontAwesomeIcon icon={faCalendar} />
                                                        <p className="theme-color-para font-size-14px font-Poppins-Regular">
                                                            {
                                                                item?.repeatCycle == 1 ? "One-off"
                                                                    : item?.repeatCycle == 2 ? "Daily"
                                                                        : item?.repeatCycle == 3 ? "Weekly"
                                                                            : item?.repeatCycle == 4 ? "Monthly"
                                                                                : "Custom"
                                                            }
                                                        </p>
                                                    </div>
                                                    <div className="admin-box-weekly-cal">
                                                        <FontAwesomeIcon icon={faLocationDot} />
                                                        <p className="theme-color-para font-size-14px font-Poppins-Regular">
                                                            {`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}
                                                        </p>
                                                    </div>
                                                    <div className="flex calen-job-req gap-8">
                                                        <div className="admin-box-weekly-cal admin-box-weekly-date">
                                                            <FontAwesomeIcon icon={faCalendarDays} />
                                                            <p className="theme-color-para font-size-11px font-Poppins-Regular">
                                                                Start date: {moment(item?.startDate).format("DD-MM-YYYY") || "-"}
                                                            </p>
                                                        </div>
                                                        <div className="admin-box-weekly-cal admin-box-weekly-date">
                                                            <FontAwesomeIcon icon={faCalendarDays} />
                                                            <p className="theme-color-para font-size-11px font-Poppins-Regular">
                                                                End date: {moment(item?.endDate).format("DD-MM-YYYY") || "-"}
                                                            </p>
                                                        </div>
                                                        {/* <div className="heme-color-para font-size-11px font-Poppins-Regular">
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                            <p>Start time: {(item?.startTime || "")}</p>
                                                        </div>
                                                        <div className="heme-color-para font-size-11px font-Poppins-Regular">
                                                            <FontAwesomeIcon icon={faCalendar} />
                                                            <p>End time: {(item?.endTime || "")}</p>
                                                        </div> */}
                                                    </div>
                                                </div>
                                                <div className="job-req-min-height">
                                                    <p className="theme-color-para font-size-16px font-Poppins-Regular">
                                                        Rate :{" "}
                                                        <span className="font-Poppins-SemiBold theme-color-green">
                                                            ${item?.budget || ""}
                                                        </span>
                                                        <span className="font-size-10px"></span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            activeTab === "opened" ?
                                                <ApplyJobsButton
                                                    id={item?._id}
                                                    successFunction={() => setActiveTab("applied")}
                                                />
                                                : activeTab === "shortlisted" ?
                                                    <div className="time-btn-card flex flex-col gap-2 justify-between theme-bg-green ">
                                                        <div className="flex flex-row gap-2 items-center">
                                                            <span className=' text-[#ffffff]'><FontAwesomeIcon icon={faCheck} /></span>
                                                            <span
                                                                className="font-Poppins-Regular font-size-14px text-[#ffffff]"

                                                            >
                                                                You shortlisted this job post.
                                                            </span>
                                                        </div>
                                                        {/* <span className=' text-[#ffffff]  font-Poppins-Regular font-size-12px ' style={{ marginLeft: "auto" }}>{getTimeDifference(item?.shortlistTime) || ""}</span> */}
                                                    </div>
                                                    :
                                                    <div className="time-btn-card flex flex-col gap-2 justify-between theme-bg-green ">
                                                        <div className="flex flex-row gap-2 items-center">
                                                            <span className=' text-[#ffffff]'><FontAwesomeIcon icon={faCheck} /></span>
                                                            <span
                                                                className="font-Poppins-Regular font-size-14px text-[#ffffff]"
                                                            >
                                                                You applied for this job.
                                                            </span>
                                                        </div>
                                                        {/* <span className=' text-[#ffffff]  font-Poppins-Regular font-size-12px'>{getTimeDifference(item?.applicationTime) || ""}
                                                        </span> */}
                                                    </div>
                                        }
                                    </div>
                                    <div className="w-[85%]  st-line"></div>
                                </div>
                            ))
                            :
                            <p className="text-center font-size-15px font-Poppins-SemiBold theme-color-green">No {
                                activeTab === 1 ? "opened"
                                    : activeTab === 2 ? "shortlisted"
                                        : "applied"
                            } jobs</p>
                        }
                        {loader &&
                            <div className="text-center">
                                <CircularProgress style={{ color: "#004540" }} />
                            </div>
                        }
                        {!loader && (totalPages > currentPage) &&
                            <div className="text-center mb-5">
                                <button onClick={() => setCurrentPage(currentPage + 1)} className="font-size-15px font-Poppins-Regular jobs-apply-btn active">Load more</button>
                            </div>
                        }
                    </div>
                </>
            )}
        </div>
    )
}

export default JobCurrentStatus