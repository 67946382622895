/**
 * WorkHistoryEdit Component
 * Allows users to edit and manage their work history by adding, removing,
 * and saving entries for job roles, companies, start dates, and end dates.
 *
 * File: WorkHistoryEdit.jsx
 * Author: Developer
 * Date: 06/04/24
 */

import React, { useState, useEffect } from "react";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import SidebarGobal from "../../../components/AllDashboardPagesComponents/SidebarGobal";
import PreviewProfile from "../../../components/AllDashboardPagesComponents/Profile/PreviewProfile";
import InputField from "../../../components/AllDashboardPagesComponents/InputField";

import { CircularProgress } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateProfile } from "../../../config/Store/Reducers/userProfile";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getRole } from "../../../config/Helpers/helpers";
import httpRequest from "../../../config/Helpers/httpRequest";
import moment from "moment";
import { RootState } from "../../../config/Store/store";


interface Experience {
  company: string;
  role: string;
  startDate: string;
  endDate: string;
  _id?: string;
  [key: string]: any;
}

interface IApiResponse {
  message: string;
}
const WorkHistoryEdit: React.FC = () => {
  // const { UserRole } = getRole(); // get user Role
  const decoded = getRole();
  const UserRole = decoded?.role;
  const dispatch = useDispatch();
  const { personalData } = useSelector((store: RootState) => store.userProfile); // get user data
  const [dateOfBirth, setDateOfBirth] = useState<string>(""); // work experience validation
  const [originalExperiences, setOriginalExperiences] = useState<Experience[]>([]);

  let emptyObj = {
    company: "",
    role: "",
    startDate: "",
    endDate: "",
  };

  const [isLoader, setIsLoader] = useState<boolean>(false); // loader for update experience
  const [myExperiences, setMyExperiences] = useState<Experience[]>([]);

  // // change function input
  // const handleChange = (key, index, value) => {
  //   const updatedExperiences = myExperiences.map((exp, i) => {
  //     if (i === index) {
  //       return {
  //         ...exp, // Copy the existing properties of the ex object
  //         [key]: value, // Update the specified key with the new value
  //       };
  //     }
  //     // Otherwise, return the original object
  //     return exp;
  //   });

  //   // Update the state with the new array
  //   setMyExperiences(updatedExperiences);
  // };
  const handleChange = (key: keyof Experience, index: number, value: string) => {
    const updatedExperiences = myExperiences.map((exp, i) => {
      if (i === index) {
        const updatedExp = { ...exp, [key]: value };
        if (key === "startDate" && moment(value).isBefore(moment(dateOfBirth))) {
          toast("Start date cannot be before your date of birth", { type: "error" });
          return exp; // Return the original exp without updating
        }
        return updatedExp;
      }
      return exp;
    });
    setMyExperiences(updatedExperiences);
  };

  // add entry / slots
  const addEntry = () => {
    setMyExperiences([...myExperiences, emptyObj]);
  };

  // remove entry / slots
  const removeEntry = (index: number) => {
    const removeEntry = [...myExperiences];
    removeEntry.splice(index, 1);
    setMyExperiences(removeEntry);
  };

  // Functionality: API call for for update work history
  // Functionality: API call for for update work history
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const currentDate = moment(); // Get the current date once for comparison

    for (const exp of myExperiences) {
      if (moment(exp.startDate).isBefore(moment(dateOfBirth))) {
        toast("Work history dates cannot be before your date of birth", { type: "error" });
        return;
      }

      // Check if startDate is greater than endDate
      if (moment(exp.startDate).isAfter(moment(exp.endDate))) {
        toast("Start date cannot be greater than end date", { type: "error" });
        return;
      }

      // Check if endDate is greater than current date
      if (moment(exp.endDate).isAfter(currentDate)) {
        toast("End date cannot be greater than the current date", { type: "error" });
        return;
      }
      if (moment(exp.startDate).isSame(moment(exp.endDate))) {
        toast("Start date and end date cannot be the same.", { type: "error" });
        return;
      }
    }

    setIsLoader(true);
    const filteredExperiences = myExperiences.map(({ company, role, startDate, endDate }) => ({
      company,
      role,
      startDate,
      endDate,
    }));
    // Filter out unnecessary fields like _id from experiences

    const { res, err } = await httpRequest<IApiResponse>({
      method: "put",
      path: `/api/provider/experience`,
      params: { experiences: filteredExperiences }, // Send filtered experiences
    });

    if (res) {
      dispatch(updateProfile());
      toast(res.message, { type: "success" });
    } else {
      toast(err?.message, { type: "error" });
    }

    setIsLoader(false);
  };



  // useEffect(() => {
  //   if (personalData) {
  //     let experience = personalData?.experience || [];
  //     setDateOfBirth(personalData?.dateofbirth);
  //     if (experience && experience.length === 0) {
  //       setMyExperiences([emptyObj]);
  //     } else {
  //       setMyExperiences(experience);
  //     }
  //   }
  // }, [personalData]);

  useEffect(() => {
    if (personalData) {
      let experience = personalData?.experience || [];
      setDateOfBirth(personalData?.dateofbirth);
      if (experience.length === 0) {
        setMyExperiences([emptyObj]); // Initialize with an empty entry if no data exists
      } else {
        setMyExperiences(experience);
        setOriginalExperiences(experience); // Store the original experiences
      }
    }
  }, [personalData]);


  return (
    <>
      <HeaderGlobal />
      <div className="container-1480 block justify-between h-3vw gap-3 g:flex md:flex gap-3">
        <SidebarGobal />
        <div className="w-full">
          <PreviewProfile title="Work history" />
          <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
            <div>
              <div>
                <p className="font-size-15px font-Poppins-Regular theme-color-green flex mb-3">
                  Enter your work history and experience.
                </p>
                <form onSubmit={handleSubmit}>
                  {myExperiences && myExperiences.length > 0 ? (
                    myExperiences.map((item, index) => (
                      <div key={index}>
                        <InputField
                          type="text"
                          name="email"
                          labelTop="Job title/role"
                          value={item?.role || ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange("role", index, e.target.value)
                          }
                          required
                        />
                        <InputField
                          type="text"
                          name="email"
                          labelTop="Company"
                          value={item?.company || ""}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            handleChange("company", index, e.target.value)
                          }
                          required
                        />
                        <div className="grid half-grid">
                          <InputField
                            type="date"
                            name="email"
                            labelTop="Start date"
                            value={item?.startDate?.split("T")[0] || ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleChange("startDate", index, e.target.value)
                            }
                            required
                          />
                          <InputField
                            type="date"
                            name="email"
                            labelTop="End date"
                            value={item?.endDate?.split("T")[0] || ""}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              handleChange("endDate", index, e.target.value)
                            }
                            required
                          />
                        </div>

                        <div className="flex justify-end my-2">
                          <button
                            type="button"
                            onClick={() => removeEntry(index)}
                            className="flex gap-2 items-center font-size-15px font-Poppins-Medium theme-color-green flex "
                          >
                            <FontAwesomeIcon icon={faTrash} color="" /> Delete
                            work history{" "}
                          </button>
                        </div>
                        <hr color="#707070" className="my-3" />
                      </div>
                    ))
                  ) : (
                    <p>No experince</p>
                  )}
                  <button
                    disabled={isLoader}
                    type="submit"
                    className="mt-3 theme-bg-green font-Poppins-Medium border-2 border-lime-500 font-size-16px px-5 py-1 inline-table rounded-3xl deleteModal-btn loader-btn"
                    style={{ color: "white" }}
                  >
                    Save and continue{" "}
                    {isLoader && (
                      <CircularProgress
                        style={{ width: 14, height: 14, color: "#fff" }}
                      />
                    )}
                  </button>
                </form>
                <button
                  className="font-size-15px font-Poppins-Medium theme-color-green flex mt-5"
                  onClick={addEntry}
                >
                  {" "}
                  + Add another work history
                </button>
              </div>
              <hr color="#707070" className="my-3" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WorkHistoryEdit;
