import React, { useEffect, useState } from "react";
import edit from "../../../assets/img/awesome-edit.svg";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import { getRole } from "../../../config/Helpers/helpers";

import { useSelector } from "react-redux";
import { RootState } from "../../../config/Store/store";
import { PublicDataType } from "../../../config/Store/Reducers/userProfile";
interface WorkLocationProps {
  workLocation: string[];
}
function WorkLocation() {
  const location = useLocation();

  // const { userId = "" } = getRole();
  const decoded = getRole();
  const userId = decoded?.userId || ""

  const { personalData, publicData } = useSelector((store: RootState) => store.userProfile); // get user data
  const ShowData = location.pathname.startsWith("/public-profile") ? (publicData as PublicDataType) : (personalData as PublicDataType);

  const [allLocations, setAllLocations] = useState([]);

  useEffect(() => {
    if (ShowData) {
      setAllLocations(ShowData?.workLocation || []);
    }
  }, [ShowData]);

  return (
    <div className="shadow-lg bg-white rounded-none lg:rounded-xl mb-2 py-3 px-6 ">
      <div className="flex justify-between items-center">
        <h4 className="font-size-20px font-Poppins-SemiBold theme-color-green">
          Work locations
        </h4>
        {userId === ShowData?._id && (
          <Link to="/work-location-edit" className="mt-2 edit-pro-icon ">
            <img src={edit} alt="" />
          </Link>
        )}
      </div>
      <div className="flex gap-3 flex-wrap">
        {allLocations && allLocations.length > 0 ? (
          allLocations.map((item, index) => (
            <p
              key={index}
              className="feeds-btn font-size-20px font-Poppins-Medium theme-grey-type flex gap-2 items-center justify-center capitalize"
              style={{
                backgroundColor: "rgb(112 112 112 / 23%)",
                color: "#464646",
                border: "none",
              }}
            >
              <FontAwesomeIcon icon={faLocationDot} color="#464646" />
              {item}
            </p>
          ))
        ) : (
          <p>No location</p>
        )}
      </div>
    </div>
  );
}

export default WorkLocation;
