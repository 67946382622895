import React, { useState, useEffect } from 'react';
import {
    Elements,
    useStripe,
    useElements,
    CardCvcElement,
    CardNumberElement,
    CardExpiryElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import httpRequest from '../../config/Helpers/httpRequest';
import { toast } from 'react-toastify';
import { getCookie } from '../../config/Helpers/helpers';
import axios from 'axios';
import { CircularProgress } from '@mui/material';
import AdsInfoModal from '../Modal/AdsInfoModal';
import Announce from "../../assets/img/adsubmit.svg"

const stripePromise = loadStripe(process.env.REACT_APP_VITE_STRIPE_PK!);

interface StripecheckProps {
    secretKey: string;
    promotionId: string;
    audienceById: string;
    refId: string;
    refType: string;
}
interface ApiResponse {
    clientSecret: string;
    subscriptionId: string;
    [key: string]: any; // Allow additional properties
}
interface CheckoutFormProps {
    secretKey: string;
    promotionId: string;
    audienceById: string;
    refId: string;
    refType: string;
    // setCurentStep:number;
}

const Stripecheck: React.FC<StripecheckProps & { onClose: () => void, setCurrentStep: (step: number) => void }> = ({
    secretKey,
    promotionId,
    audienceById,
    refId,
    refType,
    onClose,
    setCurrentStep
}) => {
    return (
        <div>
            {/* Wrap with Elements and pass all required props */}
            <Elements stripe={stripePromise}>
                <PromotionStripe
                    secretKey={secretKey}
                    promotionId={promotionId}
                    audienceById={audienceById}
                    refId={refId}
                    refType={refType}
                    onClose={onClose} // Pass onClose to PromotionStripe
                    setCurrentStep={setCurrentStep}
                // setCurentStep={3}
                />
            </Elements>
        </div>
    );
};

const PromotionStripe: React.FC<CheckoutFormProps & { onClose: () => void, setCurrentStep: (step: number) => void }> = ({
    secretKey,
    promotionId,
    audienceById,
    refId,
    refType,
    onClose,
    setCurrentStep
}) => {
    const token = getCookie();
    const stripe = useStripe();
    const elements = useElements();
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [clientSecret, setClientSecret] = useState(secretKey); // store client secret
    const [stripeLoader, setstripeLoader] = useState(false);
    const [successMessage, setSuccessMessage] = useState<boolean>(false);
    // const handleCreateIntent = async (e: React.FormEvent<HTMLFormElement>) => {
    //     e.preventDefault();

    //     let payload = {
    //         promotionOfferId: promotionId,
    //         audienceId: audienceById,
    //         refId: refId,
    //         refType: refType
    //     }
    //     const { res, err } = await httpRequest<ApiResponse>({
    //         method: 'post',
    //         path: '/api/promotions/create-intent',
    //         params: payload

    //     })
    //     if (res) {
    //         console.log(res, "secret")
    //         // setSecretKey(res?.clientSecret)
    //         // setCurrentStep(3)
    //     }
    //     else {

    //     }
    // }

    useEffect(() => {
        if (!promotionId) return;

        const createPaymentIntent = async () => {
            setIsLoading(true);
            let payload = {
                promotionOfferId: promotionId,
                audienceId: audienceById,
                refId: refId,
                refType: refType,
            };

            try {
                const { res, err } = await httpRequest<ApiResponse>({
                    method: 'post',
                    path: '/api/promotions/create-intent',
                    params: payload,
                });

                if (res) {
                    setClientSecret(res.clientSecret); // Store client secret for payment confirmation
                    console.log("Client secret received:", res.clientSecret);
                } else {
                    toast.error(err?.message || "Error creating payment intent");
                }
            } catch (error) {
                toast.error('Something went wrong when creating payment intent');
            } finally {
                setIsLoading(false);
            }
        };

        createPaymentIntent();
    }, [promotionId, audienceById, refId, refType]); // Trigger when promotionId or other necessary data changes

    const handleSubmitPayment = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();


        if (!stripe || !elements || !clientSecret) {
            // Stripe.js has not yet loaded or no clientSecret
            return;
        }

        setstripeLoader(true);

        const cardElement = elements.getElement(CardNumberElement);

        try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
                payment_method: {
                    card: cardElement!,
                    billing_details: {
                        // Add optional billing details here, e.g., name, email
                    },
                },
            });

            if (error) {
                console.error('Payment confirmation error:', error);
                setErrorMessage(error.message || 'Payment confirmation failed');
                setstripeLoader(false);
            } else if (paymentIntent?.status === 'succeeded') {
                // Payment was successful
                toast.success('Payment succeeded!');
                console.log('Payment succeeded:', paymentIntent);

                // Extract the paymentIntentId and call the complete subscription API
                const paymentIntentId = paymentIntent.id; // This is the paymentIntentId
                console.log('Payment Intent ID:', paymentIntentId);

                // Call the complete subscription API with paymentIntentId
                await handleCompleteSubscription(paymentIntentId);

            } else {
                // Handle other payment statuses if needed
                console.log('Payment intent status:', paymentIntent?.status);
            }
        } catch (error) {
            console.error('Error confirming payment:', error);
            setErrorMessage('An error occurred while confirming the payment');
        } finally {
            setstripeLoader(false);
        }
    };

    const handleCompleteSubscription = async (paymentIntentId: string) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_BASE_URL}/api/promotions/complete`,
                { paymentIntentId }, // The subscription ID to complete the subscription
                {
                    headers: {
                        Authorization: `Bearer ${token}`, // Pass the token for authentication
                    },
                }
            );

            if (response) {
                // onClose();
                // toast.success('Subscription completed successfully!');

                console.log('Subscription completed:', response.data);

                setSuccessMessage(true);

            }
        } catch (error) {
            console.error('Error completing subscription:', error);
            toast.error('An error occurred while completing the subscription');
        }
    };
    return (
        <>
            {!successMessage ?

                <div>

                    <div className=''>
                        <h2 className='font-Poppins-SemiBold font-size-25px text-[#0D0D0D]'>Advertisement Payment Method</h2>
                    </div>
                    <div>
                        <p className='font-size-15px font-Poppins-Regular mt-1'>Please enter your details below to complete your purchase</p>
                    </div>
                    <form className="w-full mb-2 py-3 px-6" onSubmit={handleSubmitPayment}>

                        <div className="form-upper-details  overflow-y-auto  adv-packages grid items-end p-10">

                            <div className="stripe-des" style={{ marginBottom: "15px" }}>
                                <label className="font-Montserrat-SemiBold font-size-login-18px ">
                                    Card number
                                </label>
                                <label className="font-Montserrat-SemiBold font-size-login-18px text-white">
                                    Card number
                                    <CardNumberElement
                                        className="stripe-des"
                                        id="cardNumber"
                                        options={{
                                            style: {
                                                base: {
                                                    padding: "0.375rem 0.75rem",
                                                    fontSize: "18px",
                                                },
                                            },
                                        }}
                                    />
                                </label>
                            </div>
                            <div className="date-cvc" style={{ marginBottom: "25px" }}>
                                <div>
                                    <label className="font-Montserrat-SemiBold font-size-login-18px">
                                        Expiry date
                                    </label>
                                    <label className="font-Montserrat-SemiBold font-size-login-18px text-white">
                                        Expiry date
                                        <CardExpiryElement
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: "18px",
                                                        padding: "0.375rem 0.75rem",
                                                    },
                                                },
                                            }}
                                        />
                                    </label>
                                </div>
                                <div>
                                    <label className="font-Montserrat-SemiBold font-size-login-18px">
                                        CVC
                                    </label>
                                    <label className="font-Montserrat-SemiBold font-size-login-18px text-white">
                                        CVC
                                        <CardCvcElement
                                            options={{
                                                style: {
                                                    base: {
                                                        fontSize: "18px",
                                                        padding: "0.375rem 0.75rem",
                                                    },
                                                },
                                            }}
                                        />
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div className='flex flex-row gap-2 justify-end'>
                            <button
                                disabled={stripeLoader}

                                onClick={() => setCurrentStep(2)}
                                className="theme-bg-green font-Poppins-Medium font-size-16px px-5 py-1 inline-table rounded-3xl text-white btn-w feeds-btn gap-2">

                                Back
                            </button>
                            <button
                                disabled={stripeLoader}
                                className="theme-bg-green font-Poppins-Medium font-size-16px px-5 py-1 inline-table rounded-3xl text-white btn-w feeds-btn gap-2" type="submit" >
                                publish

                                {stripeLoader &&
                                    <CircularProgress
                                        style={{ width: 16, height: 16, color: "#ffffff" }}
                                    />
                                }
                            </button>
                        </div>
                    </form>

                </div>

                :
                <>

                    {/* <AdsInfoModal onClose={onClose} /> */}
                    <div className='flex flex-col justify-center items-center p-8'>

                        <div className="cancellation-envolpe-main">
                            <div className="w-[4vw]">
                                <img src={Announce} alt="approval-tag" />
                            </div>
                        </div>
                        <h2 className="font-Poppins-Regular font-size-25px text-[#2F2F31] ">
                            Promotion request has been sent
                        </h2>
                        <div className="text-center flex justify-center">
                            <p className="font-size-15px font-Poppins-Regular ">
                                Your request to promote your profile has been sent for the review,
                                it will reflect in your account in 24 - 48 hours once admin

                                approve this request.

                            </p>

                        </div>
                    </div>

                </>

            }




        </>
    );
};

export default Stripecheck;
