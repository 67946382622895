// SecondaryProtectedRoutes.js
import React, { useEffect, useState } from "react";
import { Outlet, Navigate } from "react-router-dom";
import { getCookie } from "../Helpers/helpers";

interface ProviderProtectedRoutesProps {
    isProvider: Boolean;
    setIsProvider: React.Dispatch<React.SetStateAction<boolean>>;
    token: string;
}

const ProviderProtectedRoutes: React.FC<ProviderProtectedRoutesProps> = ({ isProvider, setIsProvider, token }) => {
    const [isLoader, setIsLoader] = useState(true);
    useEffect(() => {
        const checkAuthentication = async () => {
            let value = getCookie();
            if (value) {
                const payload = value.split(".");
                const payloadValue = JSON.parse(atob(payload[1]));

                if (payloadValue.role === "provider") {
                    setIsProvider(true);
                }
                else {
                    setIsProvider(false);
                }
            }
            else {
                setIsProvider(false); // Ensure isProvider is false if there's no token
            }
            setIsLoader(false);
        };
        checkAuthentication();
    }, []);

    if (isLoader) {
        return <div></div>;
    }

    return (token && isProvider) ? (
        <Outlet />
    ) : (
        <Navigate to="/login-web" />
    );
}

export default ProviderProtectedRoutes;