/**
 * JobsApplication Component
 * Manages job applicants for a specific job ID, including filtering by application status
 * (applied, shortlisted). Displays applicant cards, supports pagination, and handles API
 * interactions for fetching applicant data.
 * File: JobsApplication.js
 * Author: Developer
 * Date: 24-04-24
 */

import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from 'react-toastify';
import AllApplicantsCard from "../../components/AllDashboardPagesComponents/AllApplicantsCard";
import { Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PaginationComponet from "../../components/PaginationComponet/PaginationComponet";
import HeaderGlobal from "../../components/HeaderDashboard/headerGlobal";
import qs from "qs";
import httpRequest from "../../config/Helpers/httpRequest";
import Polygon from "../../assets/img/Polygon.svg";
interface Applicant {
    // Define the structure of an applicant object
    [key: string]: any;
}
interface paginated {
    totalPages: number;
    [key: string]: any;
}
interface ApiResponse {
    documents: [];
    paginated: paginated;
    applications: {
        data: Applicant[];
        metadata: { total: number }[];
    }[];
}

interface QueryParams {
    page: number;
    limit: number;
    sort: string;
    status: string;
    group: string;
}

const JobsApplication: React.FC = () => {
    const navigate = useNavigate()
    const { jid } = useParams<{ jid: string }>();

    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [bool, setBool] = useState<boolean>(false);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [allApplicant, setAllApplicant] = useState<Applicant[]>([]);

    const [queryParams, setQueryParams] = useState<QueryParams>({
        page: 1,
        limit: 6,
        sort: "desc",
        status: "applied",
        group: ""
    });

    const handleChangeStatus = (status: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            status
        }));
    }; // for status change
    const handleFilterChange = (group: string) => {
        setQueryParams(prev => ({
            ...prev,
            page: 1,
            group: group
        }));
    }

    // Functionality: API call to fetch applicants for a specific job ID
    const fetchApplication = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/job/applications/${jid}?${qs.stringify(queryParams)}` });
        if (res) {

            setAllApplicant(res?.documents || []);
            setTotalPages(res?.paginated?.totalPages || 1);

            // let count = res?.applications[0]?.metadata[0]?.total || 0;
        } else {
            toast(err?.message, { type: "error" });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        fetchApplication();
    }, [jid, queryParams, bool]);

    return (
        <div>
            <div className="">
                <HeaderGlobal />
                <div className="container-1480 h-3vw">
                    <div className=" Admin-main-operator-parent">
                        <div className="">
                            <div className="flex flex-row justify-between items-center">
                                <h2 className="font-size-36px font-Poppins-Medium">
                                    {/* Job board */}
                                    Applications
                                </h2>
                                <div className="flex justify-end relative">
                                    <img
                                        onClick={() => navigate(-1)}
                                        className=" cursor-pointer h-[2vw]"
                                        src="https://edgie.backslashwebs.com/imgs%2FBeck-icon.png"
                                        alt=""
                                    />
                                </div>
                            </div>
                            <div className="relative w-fit">
                                <img className="polygon" src={Polygon} alt="" />
                                <select className="font-Poppins-Medium font-size-15px custom-select-filter" onChange={(e) => handleFilterChange(e.target.value)}>
                                    <option value="">All</option>
                                    <option value="today">Today</option>
                                    <option value="yesterday">Yesterday</option>
                                    <option value="threedays">Last 3 days</option>
                                    <option value="sevendays">Last 7 days</option>
                                    <option value="month">Last Month</option>
                                </select>
                            </div>
                            <div className="mt-8">
                                <div className="admin-job-req flex justify-end">
                                    <div className="">
                                        <div className=" applicants-tabs">
                                            <button className={queryParams?.status === "applied" ? 'font-Poppins-SemiBold font-size-14px active' : 'font-Poppins-SemiBold font-size-14px'} onClick={() => handleChangeStatus("applied")}><span className="dot-act"></span> All</button>
                                            <button className={queryParams?.status === "shortlisted" ? 'font-Poppins-SemiBold font-size-14px active' : 'font-Poppins-SemiBold font-size-14px'} onClick={() => handleChangeStatus("shortlisted")}><span className="dot-comp"></span> Shortlisted</button>
                                            <button className={queryParams?.status === "declined" ? 'font-Poppins-SemiBold font-size-14px active' : 'font-Poppins-SemiBold font-size-14px'} onClick={() => handleChangeStatus("declined")}><span className="dot-comp"></span> Declined</button>
                                        </div>
                                    </div>
                                </div>
                                <div className="theme-bg-lightblue applicant-box-1">
                                    <div className='flex flex-row flex-wrap gap-3'>
                                        {isLoader ?
                                            <>
                                                {Array.from({ length: 6 }, (_, index) => (
                                                    <Skeleton key={index} variant="rounded" width={215} height={225} />
                                                ))}
                                            </>
                                            :
                                            allApplicant && allApplicant.length > 0 ?
                                                allApplicant.map((item, index) => (
                                                    <AllApplicantsCard
                                                        key={index}

                                                        item={item}
                                                        index={index}
                                                        activeTab={queryParams?.status}
                                                        setActiveTab={() => handleChangeStatus(queryParams.status)} // Removed the event parameter
                                                        setBool={() => setBool(prev => !prev)} //  check it later
                                                    />
                                                ))
                                                :
                                                <p>No applicant</p>
                                        }
                                    </div>
                                    <div className="pb-2">
                                        <PaginationComponet

                                            currentPage={queryParams?.page}
                                            total={totalPages}
                                            setCurrentPage={(e) => setQueryParams(prev => ({ ...prev, page: e }))}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobsApplication;