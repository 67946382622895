/**
 * AdminTrialUsers Component
 * Displays trial users with filtering by name and user type (participant & provider), pagination support, and navigation to user profiles on name click.
 * File: AdminTrialUsers.js
 * Author: Developer
 * Date: 03-04-24
 */

import React, { useEffect, useState } from "react";
import AdminHeader from "../../../components/AdminComponents/AdminHeader";
import AdminSidebar from "../../../components/AdminComponents/AdminSidebar";
import { Link, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import PaginationComponet from "../../../components/PaginationComponet/PaginationComponet";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faEllipsisVertical,
    faPencil,
    faEye
} from "@fortawesome/free-solid-svg-icons";
import qs from "qs";
import httpRequest from "../../../config/Helpers/httpRequest";
import { toast } from "react-toastify";
import Isverified from "../../../assets/img/isMember.svg"
import { useNavigate } from "react-router-dom";
import arrowback from "../../../assets/img/arrowback.svg";
import DownloadIcon from "../../../assets/img/downloadIcon.svg";
import { getCookie, getRole } from "../../../config/Helpers/helpers";
import HeaderGlobal from "../../../components/HeaderDashboard/headerGlobal";
import axios from "axios";

interface User {

    _id: string;
    firstName: string;
    lastName: string;
    email: string;

    role: string;
    verification: string;
    status: string;
    createdAt: string;
    [key: string]: any;
}
interface Paginated {
    totalItems: number;
    totalPages: number;
    [key: string]: any;
}
interface ApiResponse {
    totalResults: number;
    paginated: Paginated;

    documents: User[];
}
interface QueryParams {
    member: string;
    role: string;
    name: string;
    page: number;
    pageSize: number;
    sort: string;
}
interface HttpRequestParams {
    // doosri properties
    responseType?: string;
}
const BillingDetails: React.FC = () => {
    // const { insightId } = useParams<{ insightId: string }>();
    const token =getCookie()
    const decoded = getRole()
    const insightId = decoded?.userId
    const navigate = useNavigate();

    const [isComponentLoad, setIsComponentLoad] = useState<boolean>(false); // for check component load or not
    const [bool, setBool] = useState<boolean>(false); // State for toggle updates

    const [isLoader, setIsLoader] = useState<boolean>(false); // Loader state
    const [trialUsers, setTrialUsers] = useState<User[]>([]); // State for trial users list
    console.log(trialUsers, "trialUserstrialUsers")
    const [temporary, setTempValue] = useState<string>(""); // State for temporary filter value
    const [totalPage, setTotalPage] = useState<number>(1); // State for total number of pages
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // Modal state
    const [selectedPostId, setSelectedPostId] = useState<string>("");
    const [queryParams, setQueryParams] = useState<QueryParams>({
        member: "",
        role: "provider",
        name: "",
        page: 1,
        pageSize: 10,
        sort: "desc",
    }); // State for query parameters used in API callsState for query parameters used in API calls
    const handleViewPost = (postId: string) => {
        setSelectedPostId(postId);  // Set the post ID to the clicked post
        setIsModalOpen(true);       // Open the modal
    };

    // Handle close modal
    const handleCloseModal = () => {
        setIsModalOpen(false); // Close the modal
        setSelectedPostId(""); // Reset the post ID
    };

    const fetch = async () => {
        setIsLoader(true);
        const { res, err } = await httpRequest<ApiResponse>({ path: `/api/promotions/payments?promotionId=${insightId}&page=${queryParams.page}&limit=${queryParams.pageSize}`, params: { promotionId: insightId } });
        if (res) {

            setTrialUsers(res?.documents); // Set trial users data from API response
            setTotalPage(res?.paginated?.totalPages);
        } else {
            toast(err, { type: 'error' });
        }
        setIsLoader(false);
    };

    useEffect(() => {
        if (isComponentLoad) {
            const debounceTimer = setTimeout(() => {
                setQueryParams({ ...queryParams, name: temporary, page: 1 });
            }, 1500);
            return () => clearTimeout(debounceTimer);
        }
    }, [temporary]);

    // Effect hook to fetch data when query params change
    useEffect(() => {
        fetch();
        setIsComponentLoad(true);
    }, [queryParams.page]);
    // const handleDownloadInvoice = async (invoiceId: string) => {
    //     try {
    //         // Axios GET request with responseType 'blob'
    //         const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/promotions/invoice/${invoiceId}`, {
    //             responseType: 'blob',  headers: {
    //                 Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    //             },
    //         });
    //         // Explicitly cast res.data to Blob type
    //         const blob = new Blob([res.data as BlobPart]);
    //         const url = window.URL.createObjectURL(blob);
    //         const link = document.createElement('a');
    //         link.href = url;
    //         link.setAttribute('download', `invoice_${invoiceId}.pdf`); // Set the file name for download
    //         document.body.appendChild(link);
    //         link.click(); // Programmatically click the link to trigger download
    //         document.body.removeChild(link); // Clean up after download
    //     } catch (error) {
    //         console.error('Error downloading invoice:', error);
    //         toast.error('Error occurred while downloading invoice');
    //     }
    // };

    const handleDownloadInvoice = async (invoiceId: string,invoiceNo:string) => {
        try {
    
            // Fetch API request with the Authorization header and response as blob
            const response = await window.fetch(`${process.env.REACT_APP_BASE_URL}/api/promotions/invoice/${invoiceId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`, 
                },
            });
    
            if (response.ok) {
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `${invoiceNo}.pdf`); // Set the download file name
                document.body.appendChild(link);
                link.click(); // Programmatically click to trigger the download
                document.body.removeChild(link); // Clean up after download
                window.URL.revokeObjectURL(url); // Revoke the object URL to release memory
            } else {
                // Handle any non-OK responses
                const errorMessage = await response.text();
                console.error('Error downloading invoice:', errorMessage);
                toast.error('Error occurred while downloading invoice');
            }
        } catch (error) {
            // Handle any errors in the try block
            console.error('Error downloading invoice:', error);
            toast.error('Error occurred while downloading invoice');
        }
    };
    

    return (
        <div>
            <div className="flex justify-center font-poppins-regular">
                {/* <AdminSidebar /> */}
                {/* <div className="w-4/5 ml-auto"> */}
                <HeaderGlobal />
                <div className="w-[78vw] ">

                    <div className="pt-24 Admin-main-operator-parent">
                        <div className="pb-6 pl-6 pr-10">
                            <div className="flex flex-row justify-between items-center">
                                <div>
                                    <h2 className="font-size-36px font-Poppins-Medium">
                                        Billing details
                                    </h2>
                                    <p> <span className="font-size-16px font-Poppins-Bold">Dashboard /</span> <span className="font-Poppins-Medium">  Billing details</span></p>
                                </div>
                                <img onClick={() => navigate(-1)} className="cursor-pointer notifi-back h-[3vh]" src={arrowback} alt="" width={25} />
                            </div>
                            <div className="Main-AdminUsers operator-main-admin mt-6 flex justify-between items-center">

                            </div>
                            <div className="main-admin-Assessment theme-bg-lightblue">
                                <div className="">
                                    <ul
                                        style={{
                                            gridTemplateColumns: "20% 11% 13% 17% 12% 13% 14%",
                                        }}
                                        className="outer-detail-border main-Admin-status-action Admin-status-content-Role font-Poppins-SemiBold font-size-18px"
                                    >
                                        {[
                                            "Promotion name",
                                            "Type",
                                            "Packages",
                                            "Invoice #",
                                            "Amount",
                                            "Payment date",
                                            "Action",

                                            // "No of Logins",

                                        ].map((item) => (
                                            <li className="text-white" key={item}>
                                                {item || ""}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                {isLoader ? (
                                    <div className="main-Admin-status-content mt-5 text-center">
                                        <CircularProgress style={{ color: "#00443f" }} />
                                    </div>
                                ) : trialUsers && trialUsers.length > 0 ? (
                                    trialUsers.map((item, index) => (
                                        <div key={index} className=" pt-5">
                                            <ul
                                                style={{
                                                    gridTemplateColumns: "20% 11% 13% 17% 12% 13% 14%",
                                                }}
                                                className="under-detail-border Admin-status-content-Role main-Admin-status-content text-[#444444] theme-para font-Poppins-Regular font-size-18px"
                                            >
                                                <li className="capitalize">

                                                    {item?.title}

                                                </li>
                                                <li>
                                                    <span>
                                                        {item?.refType}

                                                    </span>
                                                </li>
                                                <li >
                                                    <span className="txt-turncate capitalize">  {item?.package}</span>

                                                </li>
                                                <li className="" >
                                                    {item?.invoiceNumber}
                                                </li>
                                                {/* <li>{item?.totalLogins}</li> */}
                                                <li>
                                                    ${item?.amount}

                                                </li>
                                                <li>
                                                    {moment(item?.createdAt).format("DD-MM-YYYY")}

                                                </li>
                                                <li>
                                                    <img
                                                        onClick={() => handleDownloadInvoice(item?._id,item?.invoiceNumber)}
                                                        className="start-btn cursor-pointer"
                                                        src={DownloadIcon}
                                                        alt="download"
                                                    />
                                                </li>

                                            </ul>
                                        </div>
                                    ))
                                ) : (
                                    <div className="main-Admin-status-content mt-5">
                                        <p className="text-center">No insights found</p>
                                    </div>
                                )}
                                <PaginationComponet
                                    total={totalPage}
                                    setCurrentPage={(e) =>
                                        setQueryParams({ ...queryParams, page: e })
                                    }
                                    currentPage={queryParams.page}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        // </div >
    );
};

export default BillingDetails;