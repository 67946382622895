/**
 * InReviewCards Component
 * Displays individual job request cards with details like service names, job status, location,
 * dates, budget, and applicant count. Supports selection of job cards for detailed view.
 * File: InReviewCards.js
 * Author: Developer
 * Date: 6-05-24
 */

import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCalendar,
    faLocationDot,
    faCalendarDays
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { getTimeDifference } from '../../config/Helpers/helpers';
import moment from 'moment';

interface Service {
    serviceName: string;
}

interface Job {
    _id: string;
    status: string;
    allServices: Service[];
    createdAt: string;
    repeatCycle: number;
    suburb: string;
    state: string;
    postCode: string;
    startDate: string;
    endDate: string;
    budget: number;
    applications: number;
    serviceName: string;
}

interface InReviewCardsProps {
    item: Job;
    index: number;
    setSelectedJobs: (job: Job) => void;
}

const InReviewCards: React.FC<InReviewCardsProps> = ({ item, index, setSelectedJobs }) => {

    return (
        <div key={index} className="admin-Cleaning-box1 flex flex-col justify-between">
            <div>
                <div role="button" onClick={() => setSelectedJobs(item)}>
                    <div className="admin-box-aprroved-btn">
                        <span className="font-Poppins-Medium font-size-14px">
                            {item?.status === "pending" ? "In review" : "Opened"}
                        </span>
                    </div>
                    <h2 className="font-Poppins-Medium mt-2 font-size-20px job-card-name">
                        {item?.serviceName || ""}
                        {/* {item?.allServices?.length > 1 ? ", " : ""} */}
                        {/* {item?.allServices[1]?.servicename || ""} */}
                        {/* <span style={{ fontSize: 10, fontWeight: 'normal' }}>
                            {item?.allServices?.length > 2 ? " & others " : ""}
                        </span> */}
                    </h2>
                    <p className="theme-color-para leading-3 font-size-12px font-Poppins-Regular">
                        {getTimeDifference(item?.createdAt) || ""}
                    </p>
                    <div className="admin-box-inner-main">
                        <div className="admin-box-weekly-cal">
                            <FontAwesomeIcon icon={faCalendar} />
                            <p className="theme-color-para font-size-14px font-Poppins-Regular">
                                {
                                    item?.repeatCycle == 1 ? "One-off"
                                        : item?.repeatCycle == 2 ? "Daily"
                                            : item?.repeatCycle == 3 ? "Weekly"
                                                : item?.repeatCycle == 4 ? "Monthly"
                                                    : "Custom"
                                }
                            </p>
                        </div>
                        <div className="admin-box-weekly-cal">
                            <FontAwesomeIcon icon={faLocationDot} />
                            <p className="theme-color-para font-size-14px font-Poppins-Regular">
                                {`${item?.suburb || ""}, ${item?.state || ""}, ${item?.postCode || ""}`}
                            </p>
                        </div>
                        <div className="flex justify-between calen-job-req">
                            <div className="admin-box-weekly-cal admin-box-weekly-date">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <p className="theme-color-para font-size-11px font-Poppins-Regular">
                                    Start date: {moment(item?.startDate).format("DD-MM-YYYY") || "-"}
                                </p>
                            </div>
                            <div className="admin-box-weekly-cal admin-box-weekly-date">
                                <FontAwesomeIcon icon={faCalendarDays} />
                                <p className="theme-color-para font-size-11px font-Poppins-Regular">
                                    End date: {moment(item?.endDate).format("DD-MM-YYYY") || "-"}
                                </p>
                            </div>

                        </div>
                    </div>
                </div>

                {item?.applications ?
                    <Link to={`/jobs-application/${item?._id}/view-applicant`} className="flex flex-row gap-2 mb-3 underline">
                        <span className="font-size-14px font-Poppins-Regular text-[#de0000]">{item?.applications || 0}</span>
                        <span className="font-size-14px font-Poppins-Regular text-[#de0000]">{`Application${item?.applications > 1 ? 's' : ''}`}</span>
                    </Link>
                    :
                    <div className="flex flex-row gap-2 mb-3">
                        <span className={`font-size-14px font-Poppins-Regular text-[#00443f] ${item?.status === "opened" ? "text-[#de0000]" : ""}`}>{item?.applications || 0}</span>
                        <span className="font-size-14px font-Poppins-Regular text-[#00443f]">Application</span>
                    </div>
                }
            </div>
            <div>
                <div className="job-req-min-height">
                    <p className="theme-color-para font-size-16px font-Poppins-Regular">
                        Budget :{" "}
                        <span className="font-Poppins-SemiBold theme-color-green">
                            ${item?.budget || ""}
                        </span>
                        <span className="font-size-10px"></span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default InReviewCards;