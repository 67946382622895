// import React, { useEffect, useState } from 'react';

// import ApprovedConnection from './ApprovedConnection';
// import DeclineConnection from './DeclineConnection';
// import RemoveButton from './RemoveButton';
// import ConnectButton from './ConnectButton';
// import { useSelector } from 'react-redux';
// import { Link } from 'react-router-dom';
// import { RootState } from "../../config/Store/store";
// /**
//  * ConnectionStatusButton Component
//  * Renders different buttons based on the user's connection status.
//  * Manages user status changes and renders appropriate buttons accordingly.
//  * 
//  * @param {string} status - Current status of the connection (default, cancel, pending, connected).
//  * @param {string} uId - User ID related to the connection.
//  * @param {boolean} showMessageButton - Flag to show the message button.
//  */


// interface ConnectionStatusButtonProps {
//     status?: string;
//     uId?: string;
//     showMessageButton?: boolean;
// }

// const ConnectionStatusButton: React.FC<ConnectionStatusButtonProps> = ({
//     status = "",
//     uId = "",
//     showMessageButton = false,
// }) => {
//     const { data } = useSelector((store: RootState) => store.connection);
//     const [userStatus, setUserStatus] = useState<string>("");

//     /**
//      * Handles status change requests.
//      * @param {string} e - New status value.
//      */
//     const requestChange = (e: string = "") => {
//         setUserStatus(e);
//     }; // this function is request status change function 

//     useEffect(() => {
//         setUserStatus(status);
//         if (data?.userId === uId) {
//             setUserStatus(data?.nextStatus);
//         }
//     }, [status, data]);

//     return (
//         <>
//             {
//                 userStatus === "default" ?
//                     <ConnectButton id={uId || ""} onSuccess={requestChange} />
//                     : userStatus === "cancel" ?
//                         <>
//                             <ApprovedConnection id={uId || ""} onSuccess={requestChange} />
//                             <DeclineConnection id={uId || ""} onSuccess={requestChange} btnText="Decline" />
//                         </>
//                         : userStatus === "pending" ?
//                             <DeclineConnection id={uId || ""} onSuccess={requestChange} btnText="Cancel" />
//                             : userStatus === "connected" ?
//                                 <RemoveButton id={uId || ""} onSuccess={requestChange} />
//                                 :
//                                 ""
//             }
//             {(userStatus === "connected" && showMessageButton) &&
//                 <Link to={`${process.env.REACT_APP_CHATAPP}/${uId}/chat`} className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  hover:text-white hover:bg-[#00443F]">Message</Link>
//             }

//         </>
//     );
// };

// export default ConnectionStatusButton;
import React, { useEffect, useState } from 'react';

import ApprovedConnection from './ApprovedConnection';
import DeclineConnection from './DeclineConnection';
import RemoveButton from './RemoveButton';
import ConnectButton from './ConnectButton';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from "../../config/Store/store";
/**
 * ConnectionStatusButton Component
 * Renders different buttons based on the user's connection status.
 * Manages user status changes and renders appropriate buttons accordingly.
 * 
 * @param {string} status - Current status of the connection (default, cancel, pending, connected).
 * @param {string} uId - User ID related to the connection.
 * @param {boolean} showMessageButton - Flag to show the message button.
 */


interface ConnectionStatusButtonProps {
    status?: string;
    uId?: string;
    showMessageButton?: boolean;
}

const ConnectionStatusButton: React.FC<ConnectionStatusButtonProps> = ({
    status = "",
    uId = "",
    showMessageButton = false,
}) => {
    const { data } = useSelector((store: RootState) => store.connection);
    const [userStatus, setUserStatus] = useState<string>("");

    /**
     * Handles status change requests.
     * @param {string} e - New status value.
     */
    const requestChange = (e: string = "") => {
        setUserStatus(e);
    }; // this function is request status change function 

    useEffect(() => {
        setUserStatus(status);
        if (data?.userId === uId) {
            setUserStatus(data?.nextStatus);
            console.log(data?.userId === uId,"data?.userId === uId")
            console.log(data?.nextStatus,"data?.nextStatusdata?.nextStatus")
        }
    }, [status, data]);
    const chatAppUrl = process.env.REACT_APP_CHATAPP;
    const chatUrl = `${chatAppUrl}/${uId}/chat`;

    const handleClick = () => {
        if (chatUrl) {
            window.location.href = chatUrl;
        } else {
            console.error('Chat app URL is not defined.');
        }
    };

    return (
        <>
            {
                userStatus === "default" ?
                    <ConnectButton id={uId || ""} onSuccess={requestChange} />
                    : userStatus === "pending" ?
                        <>
                            <ApprovedConnection id={uId || ""} onSuccess={requestChange} />
                            <DeclineConnection id={uId || ""} onSuccess={requestChange} btnText="Decline" />
                        </>
                        : userStatus === "cancel" ?
                            <DeclineConnection id={uId || ""} onSuccess={requestChange} btnText="Cancel" />
                            : userStatus === "connected" ?
                                <RemoveButton id={uId || ""} onSuccess={requestChange} />
                                :
                                ""
            }
            {(userStatus === "connected" && showMessageButton) &&
                // <Link to={`${process.env.REACT_APP_CHATAPP}/${uId}/chat`} className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  hover:text-white hover:bg-[#00443F]">Message</Link>
                <a
                    href={`${chatUrl}`}

                    // onClick={handleClick} 

                    className="btn-w font-size-18px theme-color-green font-Poppins-Medium feeds-btn  hover:text-white hover:bg-[#00443F]">Message</a>
            }

        </>
    );
};

export default ConnectionStatusButton;